import React, { useEffect, useState } from "react";
import Select from 'react-select'

//reducers
import { useStateValue } from "../../../../context/context";
import { actionTypes } from "../../../../context/reducer";
// import { ColoseButton } from "../../../../components/CustomButtons";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { InputLabel, TextField, ThemeProvider } from "@material-ui/core";
import PageTitle from "../../HelperComponent/PageTitle";
import { currentDate, currentDate1, currentTime, } from "views/Pages/HelperComponent/utils";
import {
    appDefaultColor,
    appSecondColor,
    whiteColor,
    reactSelectStyles,
    appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import MuiTable from "../../../Components/MuITable";


//Model window
import MasterModel from "views/Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";
//Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CircleAddBtn } from "views/Components/CustomButton";
import Button from "components/CustomButtons/Button.js";
import { CustomCard } from "views/Components/CustomCard";
import { Paper } from "@mui/material";
import CustomButton from "views/Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Grid } from "@material-ui/core";

//service
import { IMG_API } from "../../../../services/webApi/webApi";
import { getLoadingDetails, postFileUpload, postLoading, getAllVehicleByOil, getAllLoading, getAllLocation, getAllMaterial, getAdvanceVehicle, getAllEmployee } from "services/loadingService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAllVehicleByoil } from "services/loadingService";
import { useHistory, useLocation } from "react-router-dom";

import { getMaterialType } from "services/loadingService";
import { IstTime } from "views/Pages/HelperComponent/utils";
import { dateFormate } from "views/Pages/HelperComponent/utils";
import { ColoseButton } from "views/Components/CustomButton";

const useStyles = makeStyles((theme) => ({
    root: {
        switchBtn: {
            width: 180,
            height: "100%",
        },
    },
    dateField: {
        [theme.breakpoints.up("md")]: {
            marginTop: "25px",
        },
    },

    searchBar: {
        padding: "10px",
    },
    searchbtnRight: {
        float: "right",
        display: "flex",
        alignItems: "center",
        marginTop: "-54px",
    },
    activeText: {
        fontSize: "15px",
        color: appSecondColor,
        fontWeight: 400,
    },
    input: {
        height: 40,
        lineLight: 40,
        padding: "0 10px",
        marginBottom: "20px",
    },
    floatAddBtn: {
        position: "fixed",
        top: 90,
        right: 40,
        zIndex: 1000,
    },
    pageTitleBox: {
        color: appDefaultColor,
        // color: appSecondColor,
        fontSize: "14px",
        fontWeight: appFontWeight,
        paddingLeft: "20px",
    },
    topHeaderTitle: {
        backgroundColor: whiteColor,
        height: "auto",
        padding: "5px 20px",
        margin: "-20px -30px 10px -30px",
        boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
    },
    customSelect: {
        marginBottom: 15,
    },
    ddlError: {
        textAlign: "right",
        color: "#f44336",
        fontSize: "12.6px",
        marginRight: 15,
        marginTop: -15,
        fontWeight: 400,
    },

}));

const LoadingViewPage = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();

    const [globalState, dispatch] = useStateValue();
    const [successModal, setSuccessModal] = useState(false);
    const [collapsible, setCollapsible] = useState(true)
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [vehicle, setAllVehicle] = React.useState([]);
    const [allLoading, setAllLoading] = React.useState([]);
    const [addSearch, setAddSearch] = useState({
        txt_vehicle_no: '',
        txt_from_date: currentDate1(),
        txt_to_date: currentDate(),
    })


    const [allMaterial, setAllMaterial] = React.useState([]);
    const [materialType, setMaterialType] = React.useState([]);

    const [allEmployee, setAllEmployee] = React.useState([]);
    const [collapsibleloading, setCollapsibleloading] = useState(true)
    const [collapsiblechallan, setCollapsiblechallan] = useState(true)
    const [collapsibleImage, setCollapsibleImage] = useState(true)
    const [fileLoading, setFileLoading] = React.useState(false);


    const [classicModel, setClassicModel] = useState(false);
    const [LoadingsDetails, setLoadingsDetails] = useState([]);


    const Measurement = [
        { value: "L", label: "Lorry wise" },
        { value: "T", label: "Ton wise" },
        { value: "C", label: "CFT wise" },
    ];

    React.useEffect(() => {
        const id = location.loading_id
        console.log(id, "sen22102022")
        getLoadingDetails(
            id,
            (r) => {
                setLoadingsDetails(r)
            },
            (err) => {
                dispatch({
                    type: actionTypes.SET_OPEN_MSG,
                    payload: { msg: err, msgType: "error" },
                });
            }
        )


    }, [refresh]);

    const onClickCollapsImage = () => {
        console.log("Reached23")
        collapsibleImage ?
            setCollapsibleImage(false)
            :
            setCollapsibleImage(true)
    }

    //onChange
    const onChange = (event) => {
        console.log(event.target)
        const { value, name } = event.target;

        if (name === "challan_image_path") {
            setFileLoading(true);
            const file = event.target.files[0];
            console.log("sen20102022", file)
            if (file.size < 500000) {
                // const reader = new FileReader();
                // var url = reader.readAsDataURL(file);
                postFileUpload(
                    file,
                    (r) => {
                        setLoadingsDetails({
                            ...LoadingsDetails,
                            challan_image_path: r.fileName,
                            challan_image_name: r.originalFileName,
                        });
                        setFileLoading(false);
                    },
                    (err) => {
                        dispatch({
                            type: actionTypes.SET_OPEN_MSG,
                            payload: { msg: err, msgType: "error" },
                        });
                        setFileLoading(false);

                    }
                );
            } else {
                dispatch({
                    type: actionTypes.SET_OPEN_MSG,
                    payload: {
                        msg: "File should be less then 500kb",
                        msgType: "warning",
                    },
                });
            }

        }
        else {
            setLoadingsDetails({ ...LoadingsDetails, [name]: value })

        }
    }
    //onSelect
    const onSelect = (event, value) => {
        switch (value.name) {
            case "ddl_vehicle":
                setLoadingsDetails({
                    ...LoadingsDetails,
                    ddl_vehicle_no_id: event.value,
                    ddl_vehicle_no: event.label,
                    transaction_no: event.transaction_no,
                    advance_id: event.advance_id,
                    advance_order_no: event.advance_order_no,

                });
                break;
            case "ddl_driver":
                setLoadingsDetails({
                    ...LoadingsDetails,
                    ddl_driver_id: event.value,
                    ddl_driver_name: event.label,
                    whatsapp_no: event.whatsapp_no
                });
                break;
            case "ddl_Material":
                console.log(event, "sen29/ddl_Material_type")
                getMaterialType(
                    event.value,
                    (type) => {
                        setMaterialType(type)
                    },
                    (err) => {
                        dispatch({
                            type: actionTypes.SET_OPEN_MSG,
                            payload: { msg: "Material Type Not Found", msgType: "error" },
                        });
                    }
                )
                setLoadingsDetails({
                    ...LoadingsDetails,
                    ddl_Material_id: event.value,
                    ddl_Material: event.label,
                    gst: Number(event.gst),

                });
                break;
            case "ddl_material_type":

                setLoadingsDetails({
                    ...LoadingsDetails,
                    ddl_Material_type_id: event.value,
                    ddl_Material_type: event.label,
                });
                break;

            case "ddl_measurement":

                setLoadingsDetails({
                    ...LoadingsDetails,
                    ddl_measurement_id: event.value,
                    ddl_measurement: event.label,
                });
                break;
            case "ddl_loading_location":
                setLoadingsDetails({
                    ...LoadingsDetails,
                    ddl_loading_location_id: event.value,
                    ddl_loading_location: event.label,
                });
                break;
        }
    }
    //onSubmit
    const onSubmitModel = (event) => {
        event.preventDefault()

        // console.log(LoadingsDetails,"sen22102022")
        postLoading(
            LoadingsDetails,
            globalState?.user?.serial_id,
            (r) => {
                setLoadingsDetails({ loading_order_No: r.loading_order_No })
                setSuccessModal(true)
                setClassicModel(false)
                dispatch({
                    type: actionTypes.SET_OPEN_MSG,
                    payload: {
                        msg: "Loading Added Successfully",
                        msgType: "success",
                    },
                });
            },
            (err) => {
                dispatch({
                    type: actionTypes.SET_OPEN_MSG,
                    payload: { msg: err, msgType: "error" },
                });
            }
        )
    }

    const onClickCollaps = () => {
        console.log("Reached23")
        collapsible ?
            setCollapsible(false)
            :
            setCollapsible(true)
    }

    const onClickCollapsloading = () => {
        console.log("Reached23")
        collapsibleloading ?
            setCollapsibleloading(false)
            :
            setCollapsibleloading(true)
    }

    const onClickCollapschallan = () => {
        console.log("Reached23")
        collapsiblechallan ?
            setCollapsiblechallan(false)
            :
            setCollapsiblechallan(true)
    }
    const onCloseModel = () => {
       
      
        setLoadingsDetails({
            ...LoadingsDetails,
            switch_active_status: false,
           

        });
        history.push({
            pathname: "/admin/tranportation/loading",
        });
    };



    return (
        <ThemeProvider theme={theme}>
            <PageTitle
                title="Tranportation > Loadings View"
            // btnToolTip="Add Loadings"
            // onClickAddBtn={onClickAddButton}
            />

            <GridContainer className={classes.root}>
                <GridItem xs="12">
                    <CustomCard
                        cdTitle="Loading View"
                        filterIcon
                        onClickFilter={() => { }}
                    >
                        <GridContainer
                            style={{ padding: "10px" }}
                            justify="space-between"
                            alignItems="center"
                        >
                            <CustomCard
                                cdTitle="Truck Details"
                                btnToolTip={collapsible ? "Collaps" : "Expand"}
                                onClickCollapsible={onClickCollaps}
                                buttonAction={collapsible}
                                filterIcon
                            >
                                {collapsible ?
                                    <GridContainer
                                        justify="space-between"
                                        alignItems="center">

                                        <GridItem xs={2}>
                                            <InputLabel required={true} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}
                                            >Truck No.</InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.vehicle_no}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />

                                        </GridItem>
                                        <GridItem xs={2}>
                                            <InputLabel required={true} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}
                                            >Ghat In Time</InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={IstTime(LoadingsDetails.ghat_in_time)}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />

                                        </GridItem>

                                        <GridItem xs={2}>
                                            <InputLabel id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}
                                            >Driver</InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.driver_name}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />

                                        </GridItem>
                                        <GridItem xs={2}>
                                            <InputLabel required={true} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}
                                            >Driver No</InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.driver_no}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>

                                        <GridItem xs={2}>
                                            <InputLabel required={true} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}
                                            >Material</InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.material}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />

                                        </GridItem>
                                        <GridItem xs={2}>
                                            <InputLabel required={true} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}
                                            >Material Type</InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.material_type}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />

                                        </GridItem>

                                        <GridItem xs={2}>
                                            <InputLabel required={true} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}
                                            >Measurement</InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.measurement}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    : ""}
                            </CustomCard>
                            <CustomCard
                                cdTitle=" Loading Details"
                                btnToolTip={collapsibleloading ? "Collaps" : "Expand"}
                                onClickCollapsible={onClickCollapsloading}
                                buttonAction={collapsibleloading}
                                filterIcon
                            >
                                {collapsibleloading ?
                                    <GridContainer
                                        justify="space-between"
                                        alignItems="center">

                                        <GridItem xs={3}>
                                            <InputLabel required={true} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}
                                            >Loading Location</InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.loading_location}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />

                                        </GridItem>
                                        <GridItem xs={3}>
                                            <InputLabel id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}
                                            >Loading Quantity (unit: cft)</InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.loading_quantity}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>
                                        <GridItem xs={3}>
                                            <InputLabel required={true} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}
                                            >Loading Start Time</InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={IstTime(LoadingsDetails.loading_start_time)}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>
                                        <GridItem xs={3}>
                                            <InputLabel required={true} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}
                                            >loading End Time"</InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={IstTime(LoadingsDetails.loading_end_time)}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />

                                        </GridItem>



                                    </GridContainer>
                                    : ""}

                            </CustomCard>

                            <CustomCard
                                cdTitle="challan Details"
                                btnToolTip={collapsiblechallan ? "Collaps" : "Expand"}
                                onClickCollapsible={onClickCollapschallan}
                                buttonAction={collapsiblechallan}
                                filterIcon
                            >
                                {collapsiblechallan ?
                                    <GridContainer
                                        justify="space-between"
                                        alignItems="center">
                                        <GridItem xs="2">
                                            <InputLabel required={false} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}>
                                                Challan No.
                                            </InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.challan_no}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>

                                        <GridItem xs="2">
                                            <InputLabel required={false} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}>
                                                Challan Date
                                            </InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={dateFormate(LoadingsDetails.challan_date)}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>

                                        <GridItem xs="2">
                                            <InputLabel required={false} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}>
                                                Challan Create Time"
                                            </InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={IstTime(LoadingsDetails.challan_time)}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>

                                        <GridItem xs="2">
                                            <InputLabel required={false} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}>
                                                Challan CFT.
                                            </InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.challan_cft}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>


                                        <GridItem xs="2">
                                            <InputLabel required={false} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}>
                                                Challan Validity Time"
                                            </InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={IstTime(LoadingsDetails.challan_validity_time)}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>
                                        <GridItem xs="2">
                                            <InputLabel required={false} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}>
                                                Ghat Exit Time
                                            </InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={IstTime(LoadingsDetails.ghat_exit_time)}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>
                                        <GridItem xs="2">
                                            <InputLabel required={false} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}>
                                                Weight Measurement
                                            </InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.weight_measurement}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>
                                        <GridItem xs="2">
                                            <InputLabel required={false} id="label"
                                            style={{ fontWeight: "bold", textAlign: "center" }}>
                                                Remarks
                                            </InputLabel>
                                            <TextField
                                                size="small"
                                                fullWidth={true}
                                                value={LoadingsDetails.remarks}
                                                disabled
                                                inputProps={{ style: { color: "green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                                            />
                                        </GridItem>
                                        <GridItem xs="8"></GridItem>
                                    </GridContainer>
                                    : ""}
                            </CustomCard>
                            <CustomCard
                                cdTitle="Images"
                                btnToolTip={collapsibleImage ? "Collaps" : "Expand"}
                                onClickCollapsible={onClickCollapsImage}
                                buttonAction={collapsibleImage}
                                filterIcon
                            >
                                {
                                    collapsibleImage ?
                                        <GridContainer
                                            style={{ padding: 25 }}
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            {/* Challan image */}
                                            <GridItem xs={4}>
                                                <InputLabel id="label">
                                                    <h4>Challan Image</h4>
                                                </InputLabel>
                                            </GridItem>
                                            <GridItem xs={8} >
                                                <GridContainer>
                                                    <GridItem xs="6">
                                                        <Paper elevation={0}>
                                                            {fileLoading ? (
                                                                <CircularProgress />
                                                            ) : (
                                                                <img
                                                                    id="meter_image_path"
                                                                    // className={classes.itemImg}
                                                                    style={{ width: "25%" }}
                                                                    src={
                                                                        LoadingsDetails.challan_image_path
                                                                            ? IMG_API + LoadingsDetails.challan_image_path
                                                                            : "https://via.placeholder.com/160"
                                                                    }
                                                                    height="100%"
                                                                    alt="image"
                                                                />
                                                            )}
                                                        </Paper>
                                                    </GridItem>
                                                    <GridItem xs="6" style={{ marginTop: 25 }}>
                                                        <input
                                                            accept="image/*"
                                                            className={classes.input}
                                                            style={{ display: "none" }}
                                                            name="challan_image_path"
                                                            onChange={onChange}
                                                            id="challan_image"
                                                            type="file"
                                                        />
                                                        {/* <label htmlFor="challan_image">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                component="span"
                                                                fullWidth={true}
                                                                className={classes.browseBtn}
                                                            >
                                                                Upload Img
                                                            </Button>
                                                        </label> */}
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>
                                        </GridContainer>
                                        :
                                        ''
                                }

                            </CustomCard>
                            <div style={{ width: "100%" }} className={classes.actionbtns}>
                                {/* <CustomButton
                                    style={{ float: "right", width: "10%", marginRight: "10px", border: "none" }}
                                    onClick={onSubmitModel}
                                >
                                    Submit
                                </CustomButton>
                                <CustomButton
                                    style={{ float: "right", width: "10%", marginRight: "10px", background: 'red', border: "none" }}
                                // onClick={onClickRefresh}
                                >
                                    <RotateLeftIcon />
                                </CustomButton> */}
                                <ColoseButton
                                                 style={{ backgroundColor: "red", color: "white", float: "right", width: "10%", marginRight: "10px", border: "none" }}
                                                onClick={onCloseModel}
                                            >
                                                close
                                            </ColoseButton>
                            </div>
                        </GridContainer>
                    </CustomCard>
                </GridItem>
            </GridContainer>

        </ThemeProvider>
    )
}

export default LoadingViewPage;