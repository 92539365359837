import React, { useEffect, useState } from "react";
import Select from "react-select";

//reducers
import { useStateValue } from "../../../../context/context";
import { actionTypes } from "../../../../context/reducer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { Box, InputLabel, TextField, ThemeProvider } from "@material-ui/core";
import PageTitle from "../../../Pages/HelperComponent/PageTitle";

import {
  currentDate,
  currentDate1,
  currentTime,
} from "views/Pages/HelperComponent/utils";
// import {
//     appDefaultColor,
//     appSecondColor,
//     whiteColor,
//     reactSelectStyles,
//     appFontWeight,
// } from "assets/jss/material-dashboard-pro-react";
// import MuiTable from "../../../Components/MuITable";
import { activeText } from "assets/jss/material-dashboard-pro-react";

//Model window
import MasterModel from "views/Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";
//Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CircleAddBtn } from "views/Components/CustomButton";
import Button from "components/CustomButtons/Button.js";
import { CustomCard } from "views/Components/CustomCard";
import { Paper } from "@mui/material";
import CustomButton from "views/Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//service
import {
  getDispatchedVehicle,
  getAllExpenses,
  getAllAdvance,
} from "services/destinationService";
import {
  postDestination,
  getAllDestination,
  getSearchDestination,
} from "services/destinationService";
import { postExtraCharges, postFileUpload } from "services/extraChargesService";
import { ColoseButton } from "views/Components/CustomButton";
import PoliceStationPage from "views/Pages/SettingsPages/PoliceStationPage";
import {
  getListpolice,
  getAllEmployee,
  getListParking,
  getListMvl,
  getListlab,
  getListCustomers,
  getListkata,
  getAllVehicleById,
  getAllTransaction,
  getAllRoutes,
} from "services/extraChargesService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IMG_API } from "../../../../services/webApi/webApi";
import { withStyles } from "@material-ui/core/styles";
import { InputAdornment, IconButton, OutlinedInput } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { CircleAddBtnAnim } from "../../../Components/CustomButton";

import {
  appFontWeightThin,
  appDefaultColor,
  appSecondColor,
  whiteColor,
  appDefaultFamily,
  appFontWeight,
  tblBodyHoverColor,
  appScrollBar,
} from "assets/jss/material-dashboard-pro-react";
import { Place } from "@material-ui/icons";
import { viewextraChargesDetails } from "services/extraChargesService";
import { useLocation } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "6px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "0px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  customSelect: {
    marginBottom: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
    "&:hover": {
      backgroundColor: "rgba(43,43, 43, 0.03)",
    },
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);
const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  floatAddBtn: {
    position: "fixed",
    top: 90,
    right: 40,
    zIndex: 1000,
  },
  pageTitleBox: {
    color: appDefaultColor,
    // color: appSecondColor,
    fontSize: "14px",
    fontWeight: appFontWeight,
    paddingLeft: "20px",
  },
  topHeaderTitle: {
    backgroundColor: whiteColor,
    height: "auto",
    padding: "5px 20px",
    margin: "-20px -30px 10px -30px",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },
}));
const useStyles1 = makeStyles((styles) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const options = [
  {
    label: "Police",
    value: 1,
  },
  {
    label: "Parking",
    value: 2,
  },
  {
    label: "Mvi",
    value: 3,
  },
  {
    label: "Lab",
    value: 4,
  },
  {
    label: "Kata",
    value: 5,
  },
];

const ExtraChargesView = () => {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();
  const classs = useStyles1();
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [globalState, dispatch] = useStateValue();
  const [successModal, setSuccessModal] = useState(false);
  const [classicModel, setClassicModel] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ExtraChargesDetails, setExtraChargesDetails] = React.useState({
    edit: false,
    inFlow: false,
    ddl_vehicle_id: "",
    ddl_vehicle_no: "",
    ddl_extra_charges_type_id: "",
    ddl_extra_charges_type_name: "select",
    ddl_place_id: "",
    ddl_place_name: "select",
    time: currentTime(),
    date: currentDate(),
    txt_amount: "",
    txt_remarks: "",
    ddl_manager_id: "",
    ddl_manager_name: "select",
    image_path: "",
    image_name: "",
    ExtraCharges_order_no: "",
    ddl_transaction_id: "",
    ddl_transaction_no: "",
    routes_name: "",
    net_amount: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState({
    vehicle_id: false,
  });
  const [collapsible, setCollapsible] = useState(true);
  const [collapsibleloading, setCollapsibleloading] = useState(true);
  const [collapsibleRespon, setCollapsibleRespon] = useState(true);
  const [collapsibleDestination, setCollapsibleDestination] = useState(true);
  const [collapsibleOrder, setCollapsibleOrder] = useState(true);
  const [fileLoading, setFileLoading] = React.useState(false);
  const [police, setAllPolice] = useState([]);
  const [allEmployee, setAllEmployee] = React.useState([]);
  const [allParkings, setAllParking] = React.useState([]);
  const [allMvl, setAllMvl] = React.useState([]);
  const [AllLab, setAllLab] = React.useState([]);
  const [allCustomer, setAllCustomer] = React.useState([]);
  const [allKata, setAllKata] = React.useState([]);
  const [place, setAllPlace] = React.useState([]);
  const [anim, setAnim] = React.useState(false);
  const [extraChargesList, setExtraChargesList] = React.useState([]);
  const [vehicle, setAllVehicle] = React.useState([]);
  const [transaction, setAllTransaction] = React.useState([]);
  const [routes, setAllRoutes] = React.useState([]);
  const [visible, setAllVisiable] = React.useState(false);
  const [extraCharges, setExtraCharges] = React.useState([]);




  React.useEffect(() => {
    setLoading(true)

    // console.log(location,"sank060223/location")
    viewextraChargesDetails(
        
        location.extraCharges_id,
        (r) => {
    console.log(r,"sank060223/r")

            setExtraCharges(r)
        },
        (err) => {
            dispatch({
                type: actionTypes.SET_OPEN_MSG,
                payload: { msg: err, msgType: "error" },
            });
        }
    )
  }, [location]);

  const onCloseModel = () => {
    setClassicModel(false);
  
    history.push({
      pathname: "/admin/tranportation/ExtraCharges",
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Transportation > View Extra Charges"
        // btnToolTip="Add ExtraCharges"
        // onClickAddBtn={onClickAddDestination}
      />

      {/* On Click Model Window Open */}
      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <CustomCard
            cdTitle="Extra Charges"
            filterIcon
            onClickFilter={() => {}}
          >
            <GridContainer
              style={{ padding: "10px" }}
              justify="space-between"
              alignItems="center"
            >
              <GridItem xs={3}>
                <InputLabel required={true} id="label">
                  Truck no
                </InputLabel>
                <Select
                //   options={vehicle}
                  name="ddl_vehicle"
                  placeholder="truck no"
                //   formatGroupLabel={(d) => d.label}
                  className={classes.customSelect}
                  value={{
                    value: extraCharges.vehicle_id,
                    label: extraCharges.vehicle_no,
                  }}
                  
                //   menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </GridItem>

              <GridItem xs={3}>
                <InputLabel required={true} id="label">
                  Transaction no
                </InputLabel>
                <Select
                  options={transaction}
                  name="ddl_transaction"
                  placeholder="trasaction no"
                  formatGroupLabel={(d) => d.label}
                  className={classes.customSelect}
                  value={{
                    value: extraCharges.ddl_transaction_id,
                    label: extraCharges.transaction_no,
                  }}
                 
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </GridItem>
              <GridItem xs="4">
                <InputLabel id="label">Routes</InputLabel>
                <TextField
                  size="small"
                  type="string"
                  name="routes_name"
                  value={extraCharges.routes_name}
                  fullWidth={true}
                  inputProps={{
                    style: { textAlign: "center", color: "green" },
                  }}
                  disabled
                />
              </GridItem>
            </GridContainer>
          </CustomCard>
      
                    <CustomCard
                      cdTitle="Extra Charges Details List"
                      filterIcon
                      onClickFilter={() => {}}
                    >
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">
                                #
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Extra Charges Type
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                Type Name
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Time
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Date
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Amount
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Approve By
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Remarks
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Action
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                           {console.log(extraCharges.extraCharges,"sankhextraCharges")}
                            {extraCharges.extraCharges &&
                              extraCharges.extraCharges.map((row, i) => (
                                <StyledTableRow>
                                  <StyledTableCell
                                    align="center"
                                    className={classes.id}
                                  >
                                    {row.length != 0 ? i + 1 : ""}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {row.ddl_extra_charges_type_name}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className={classes.ledger}
                                  >
                                    {row.ddl_place_name}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="right"
                                    className={classes.debit}
                                  >
                                    {row.time}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="right"
                                    className={classes.credit}
                                  >
                                    {row.date}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="right"
                                    className={classes.credit}
                                  >
                                    {row.txt_amount}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="right"
                                    className={classes.credit}
                                  >
                                    {row.ddl_manager_name}
                                  </StyledTableCell>

                                  <StyledTableCell align="right">
                                    {row.txt_remarks}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="right"
                                    className={classes.credit}
                                  >
                                    <IconButton
                                    //   onClick={(e) => onDelete(row, i)}
                                      aria-label="delete"
                                      className={classes.credit}
                                    >
                                      <DeleteIcon
                                        fontSize="small"
                                        style={{ color: "#f44336" }}
                                      />
                                    </IconButton>
                                  </StyledTableCell>
                                </StyledTableRow>
                            ))} 
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TableContainer>
                        <TableRow>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell xs="3" align="lefy"></TableCell>

                          <TableCell xs={4} align="right">
                            Total
                          </TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell xs="3" align="right"></TableCell>
                          <TableCell align="right"></TableCell>

                          <TableCell xs="6" textAlign="left">
                            {extraCharges?.extraCharges?.length
                              ? extraCharges?.extraCharges.reduce(
                                  (sum, li) =>
                                    Number(sum) + Number(li.txt_amount),
                                  0
                                )
                              : ""}
                          </TableCell>
                        </TableRow>
                      </TableContainer>
                    </CustomCard>
                 
    
          <div style={{ width: "100%" }} className={classes.actionbtns}>

            <ColoseButton
              style={{
                backgroundColor: "red",
                color: "white",
                float: "right",
                width: "10%",
                marginRight: "10px",
                border: "none",
              }}
              onClick={onCloseModel}
            >
              close
            </ColoseButton>
          </div>
        </GridItem>
      </GridContainer>
  
    </ThemeProvider>
  );
};

export default ExtraChargesView;
