import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "views/Pages/HelperComponent/utils";


export const postVehicles = async (
  info,
  inserted_by_id,
  onSuccess,
  onFailure
) => {
  try {
    console.log(info, "info");
    const res = await webApi.post("/master/vehicle/insert", {
      vehicle_no: info.txt_vehicle,
      vehicle_type: info.ddl_vehicle_type_label,
      vehicle_type_id: info.ddl_vehicle_type_id,

      vehicle_brand: info.ddl_vehicle_brand_label,
      vehicle_brand_id: info.ddl_vehicle_brand_id,

      no_of_wheels: info.ddl_no_of_wheels_label,
      no_of_wheels_id: info.ddl_no_of_wheels_id,
      engine_number: info.txt_engine_number,
      chassis_number: info.txt_chassis_number,
      vehicle_specification: info.txt_vehicle_specification,
      vehicle_purchase_date: info.txt_purchase_date,
      vehicle_ownership: info.vehicle_ownership,
      ownerName: info.txt_Owners,
      km_start: Number(info.txt_km_start),
      mileage: Number(info.txt_mileage),
      insurance_company: info.txt_insurance_company,
      vehicle_charges: info.vehicle_charges,
      insurance_number: info.txt_insurance_file,
      pollution_number: info.txt_pollution_file,
      permit_number: info.txt_permit_file,
      tax_number: info.txt_tax_file,
      bluebook_number: info.txt_bluebook_file,
      fitness_number: info.txt_fitness_number,
      loan_number: info.txt_loan_file,
      bank_noc_number: info.txt_bank_noc_file,
      insurance_expire_date: timestamp(info.txt_insurance_date),
      pollution_expiry_date: timestamp(info.txt_pollution_date),
      permit_expiry_date: timestamp(info.txt_permit_date),


      tax_expire_date: timestamp(info.txt_tax_date),

      fitness_number: info.txt_fitness_number,

      fitness_expiry_date: timestamp(info.txt_fitness_date),
      // bluebook_expiry_date: timestamp(info.txt_bluebook_date),
      loan_letter: info.txt_loan_letter,

      // loan_date: timestamp(info.txt_loan_date),
      bank_noc: info.txt_bank_noc,

      // bank_noc_date: timestamp(info.txt_bank_noc_date),
      active_status: info.switch_active_status ? "Y" : "N",
      inserted_by_id: inserted_by_id,
      insurance_image_path: info.insurance_image_path,
      insurance_image_name: info.insurance_image_name,
      pollution_image_path: info.pollution_image_path,
      pollution_image_name: info.pollution_image_name,
      permit_image_path: info.permit_image_path,
      permit_image_name: info.permit_image_name,
      tax_image_path: info.tax_image_path,
      tax_image_name: info.tax_image_name,
      bluebook_image_path: info.bluebook_image_path,
      bluebook_image_name: info.bluebook_image_name,
      fitness_image_path: info.fitness_image_path,
      fitness_image_name: info.fitness_image_name,
      loan_image_path: info.loan_image_path,
      loan_image_name: info.loan_image_name,
      bank_image_path: info.bank_image_path,
      bank_image_name: info.bank_image_name,
      gps_image_path:info.gps_image_path,
      gps_image_name:info.gps_image_name,
      gps_date:timestamp(info.txt_gps_date),
    });

    if (res.status === 200) {
      const r = res.data;
      console.log(r, "r form service");
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    // onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllVehicle = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/vehicle/list", {
      keyword_pharse: q?.txt_keyword_pharse,
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "list");
      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({
          id: i + 1,
          vehicle_id: r.vehicle_id,
          vehicle_type: r.vehicle_type,
          vehicle_brand: r.vehicle_brand,
          tyre_brand: r.tyre_brand,
          ownerName: r.ownerName,
          vehicle_purchase_date: r.vehicle_purchase_date,
          vehicle_no: r.vehicle_no,
          contact_person: r.contact_person,
          contact_no: r.contact_no,
          no_of_wheels: r.no_of_wheels,
          vehicle_ownership: r.vehicle_ownership,
          insurance_expire_date:dateFormate(r.insurance_expire_date),
          permit_expiry_date:dateFormate(r.permit_expiry_date),
          pollution_expiry_date:dateFormate(r.pollution_expiry_date),
          fitness_expiry_date:dateFormate(r.fitness_expiry_date),
          gps_date:dateFormate(r.gps_date),
          tax_expiry_date:dateFormate(r.tax_expire_date),
          status: r.active_status,
          action_items: r.action_items,
          action: "action",
        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//dispatch Vehicle by id
export const getVehicleById = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vehicle/list", {
      group_id: 26,
    });
    if (res.status === 200) {
      const r = res.data;
      let allVehicle = [];
      r.map((r, i) => {
        allVehicle.push({
          value: r.vehicle_id,
          label: r.vehicle_no,
          no_of_wheels: r.no_of_wheels,
          km_start: r.km_start,

        });
      });

      return onSuccess(allVehicle);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllTyreBrand = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/tyre_brand/list", {});
    if (res.status === 200) {
      const r = res.data;
      let all_tyreBrand = [];
      r.map((r, i) => {
        all_tyreBrand.push({
          value: r.tyre_brand_id,
          label: r.tyre_brand,
        });
      });

      return onSuccess(all_tyreBrand);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const deleteVehicle = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vehicle/delete", {
      vehicle_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updateVehicle = async (info, edited_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vehicle/update", {
      vehicle_id: info.vehicle_id,
      vehicle_no: info.txt_vehicle,
      vehicle_type: info.ddl_vehicle_type_label,
      vehicle_type_id: info.ddl_vehicle_type_id,
      rejected_count:0,
      vehicle_brand: info.ddl_vehicle_brand_label,
      vehicle_brand_id: info.ddl_vehicle_brand_id,

      no_of_wheels: info.ddl_no_of_wheels_label,
      no_of_wheels_id: info.ddl_no_of_wheels_id,
      engine_number: info.txt_engine_number,
      chassis_number: info.txt_chassis_number,
      vehicle_specification: info.txt_vehicle_specification,
      vehicle_purchase_date: info.txt_purchase_date,
      vehicle_ownership: info.vehicle_ownership,
      ownerName: info.txt_Owners,
      km_start: Number(info.txt_km_start),
      mileage: Number(info.txt_mileage),
      insurance_company: info.txt_insurance_company,
      vehicle_charges: info.vehicle_charges,
      tyre_details_status:info.tyre_details_status,

      insurance_number: info.txt_insurance_file,
      pollution_number: info.txt_pollution_file,
      permit_number: info.txt_permit_file,
      tax_number: info.txt_tax_file,
      bluebook_number: info.txt_bluebook_file,
      fitness_number: info.txt_fitness_number,
      loan_number: info.txt_loan_file,
      bank_noc_number: info.txt_bank_noc_file,



      insurance_expire_date: timestamp(info.txt_insurance_date),
      pollution_expiry_date: timestamp(info.txt_pollution_date),
      permit_expiry_date: timestamp(info.txt_permit_date),
      tax_expire_date: timestamp(info.txt_tax_date),
      // bluebook_expiry_date: timestamp(info.txt_bluebook_date),
      fitness_expiry_date: timestamp(info.txt_fitness_date),

      loan_letter: info.txt_loan_letter,
      gps_date:timestamp(info.txt_gps_date),

      // loan_date: timestamp(info.txt_loan_date),
      bank_noc: info.txt_bank_noc,

      // bank_noc_date: timestamp(info.txt_bank_noc_date),
      active_status: info.switch_active_status ? "Y" : "N",
      edited_by_id: edited_by_id,
      insurance_image_path: info.insurance_image_path,
      insurance_image_name: info.insurance_image_name,
      pollution_image_path: info.pollution_image_path,
      pollution_image_name: info.pollution_image_name,
      permit_image_path: info.permit_image_path,
      permit_image_name: info.permit_image_name,
      tax_image_path: info.tax_image_path,
      tax_image_name: info.tax_image_name,
      bluebook_image_path: info.bluebook_image_path,
      bluebook_image_name: info.bluebook_image_name,
      fitness_image_path: info.fitness_image_path,
      fitness_image_name: info.fitness_image_name,
      loan_image_path: info.loan_image_path,
      loan_image_name: info.loan_image_name,
      bank_image_path: info.bank_image_path,
      bank_image_name: info.bank_image_name,
      gps_image_path:info.gps_image_path,
      gps_image_name:info.gps_image_name,
      
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllVehicleTypeId = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/vehicle_type/list", {
      keyword_pharse: q?.txt_keyword_pharse,
    });
    if (res.status === 200) {
      const r = res.data;

      console.log(r, "sen1108/r");
      let all_vehiclesType = [];
      r.map((r, i) => {
        all_vehiclesType.push({
          id: i + 1,
          value: r.vehicle_type_id,
          label: r.vehicle_type,
        });
      });
      return onSuccess(all_vehiclesType);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllVehicleBrandId = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/vehicle_brand/list", {
      keyword_pharse: q?.txt_keyword_pharse,
    });
    if (res.status === 200) {
      const r = res.data;

      console.log(r, "sen1108/r");
      let all_vehiclesBrand = [];
      r.map((r, i) => {
        all_vehiclesBrand.push({
          value: r.vehicle_brand_id,
          label: r.vehicle_brand,
        });
      });
      return onSuccess(all_vehiclesBrand);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllVehicleView = async (id, vehicle_no, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vehicle/list", {
      vehicle_id: id,
      vehicle_no: vehicle_no,
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "list");

      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({
          id: i + 1,
          vehicle_id: r.vehicle_id,
          vehicle_type: r.vehicle_type,
          vehicle_brand: r.vehicle_brand,
          tyre_brand: r.tyre_brand,
          vehicle_specification: r.vehicle_specification,
          vehicle_purchase_date: r.vehicle_purchase_date,
          vehicle_no: r.vehicle_no,
          contact_person: r.contact_person,
          contact_no: r.contact_no,
          no_of_wheels: r.no_of_wheels,
          vehicle_ownership: r.vehicle_ownership,
          attachment: r.attachment,

          insurance_number:r.insurance_number ? r.insurance_number :0 ,
          bluebook_number:r.bluebook_number ? r.bluebook_number :0,
          fitness_number:r.fitness_number ? r.fitness_number:0,
          loan_number:r.loan_number ? r.loan_number:0,
          bank_noc_number:r.bank_noc_number ? r.bank_noc_number: 0,
          permit_number:r.permit_number ?r.permit_number:0 ,
          pollution_number:r.pollution_number ? r.pollution_number : 0,
          tax_number:r.tax_number ? r.tax_number:0,

          pollution_expiry_date:r.pollution_expiry_date,
          permit_expiry_date:r.permit_expiry_date,
          fitness_expiry_date:r.fitness_expiry_date,
          loan_date:r.loan_date,
          bank_noc_date:r.bank_noc_date,
          insurance_expire_date:r.insurance_expire_date,
          tax_expire_date:r.tax_expire_date,
          gps_date:r.gps_date,

          status: r.active_status,
          action_items: r.action_items,
          action: "action",
        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getVehicle = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vehicle/list", {
      vehicle_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      // console.log(r, "list");

      // let all_vehicles = [];
      // r.map((r, i) => {
      //   all_vehicles.push({
      //     id: i + 1,
      //     vehicle_id: r.vehicle_id,
      //     vehicle_type: r.vehicle_type,
      //     vehicle_brand: r.vehicle_brand,
      //     tyre_brand: r.tyre_brand,
      //     vehicle_specification: r.vehicle_specification,
      //     vehicle_purchase_date: r.vehicle_purchase_date,
      //     vehicle_no: r.vehicle_no,
      //     contact_person: r.contact_person,
      //     contact_no: r.contact_no,
      //     no_of_wheels: r.no_of_wheels,
      //     vehicle_ownership: r.vehicle_ownership,
      //     attachment: r.attachment,
      //     status: r.active_status,
      //     action_items: r.action_items,
      //     action: "action",
      //   });
      // });
      return onSuccess(r[0]);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postFileUpload = async (file, onSuccess, onFailure) => {
  let formData = new FormData();
  formData.set("file", file);
  try {
    const res = await webApi.post(
      // "/files/upload?uploadpath=undefined",
      // "../assets/img",
      "https://api.gstspl.com/files/upload?uploadpath=undefined",
      formData
    );
    if (res.status === 200) {
      const r = res.data;
      if (r.success) {
        onSuccess(r);
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

// export const postFileUpload = async (file, onSuccess, onFailure) => {
//   let formData = new FormData();
//   formData.append("file", file);
//   try {
//     const res = await webApi.post("files/upload", formData);
//     if (res.status === 200) {
//       const data = res.data;
//       if (data.success) {
//         onSuccess(data);
//       } else {
//         onFailure("Upload failed: " + data.message);
//       }
//     } else {
//       onFailure("Failed to upload file: " + res.statusText);
//     }
//   } catch (error) {
//     onFailure("Something went wrong: " + error.message);
//   }
// };


