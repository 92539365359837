import React, { useEffect, useState } from "react";
import Select from "react-select";

//reducers
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { Box, InputLabel, TextField, ThemeProvider } from "@material-ui/core";
import PageTitle from "../../Pages/HelperComponent/PageTitle";

import {
  currentDate,
  currentDate1,
  currentTime,
  dateFormate,
  dateFormateField,
} from "../../Pages/HelperComponent/utils";
// import {
//     appDefaultColor,
//     appSecondColor,
//     whiteColor,
//     reactSelectStyles,
//     appFontWeight,
// } from "assets/jss/material-dashboard-pro-react";
// import MuiTable from "../../../Components/MuITable";
import { activeText } from "assets/jss/material-dashboard-pro-react"; 
//Model window
import MasterModel from "views/Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";
//Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CircleAddBtn } from "views/Components/CustomButton";
import Button from "components/CustomButtons/Button.js";
import { CustomCard } from "views/Components/CustomCard";
import { Paper } from "@mui/material";
import CustomButton from "../../Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { useLocation } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
//service
import {
  getDispatchedVehicle,
  getAllExpenses,
  getAllAdvance,
} from "services/destinationService";
import {
  postDestination,
  getAllDestination,
  getSearchDestination,
} from "services/destinationService";
import { ColoseButton } from "views/Components/CustomButton";
import PoliceStationPage from "views/Pages/SettingsPages/PoliceStationPage";
import { 
  getAllVehicleById,
  getBreakDownForView,
  postBreakDown,
  postFileUpload
} from "../../../services/breakDownService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IMG_API } from "../../../services/webApi/webApi";
import { withStyles } from "@material-ui/core/styles";
import { InputAdornment, IconButton, OutlinedInput } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { CircleAddBtnAnim } from "../../Components/CustomButton";
import {
  appFontWeightThin,
  appDefaultColor,
  appSecondColor,
  whiteColor,
  appDefaultFamily,
  appFontWeight,
  tblBodyHoverColor,
  appScrollBar,
} from "assets/jss/material-dashboard-pro-react";
import { Place } from "@material-ui/icons";
import { getExtraCharges } from "services/extraChargesService";
import { updateExtraCharges } from "services/extraChargesService";


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "6px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "0px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  customSelect: {
    marginBottom: 15,
  },
  customSelect1: {
    floatLeft: "-2%"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
    "&:hover": {
      backgroundColor: "rgba(43,43, 43, 0.03)",
    },
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);
const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  floatAddBtn: {
    position: "fixed",
    top: 90,
    right: 40,
    zIndex: 1000,
  },
  pageTitleBox: {
    color: appDefaultColor,
    // color: appSecondColor,
    fontSize: "14px",
    fontWeight: appFontWeight,
    paddingLeft: "20px",
  },
  topHeaderTitle: {
    backgroundColor: whiteColor,
    height: "auto",
    padding: "5px 20px",
    margin: "-20px -30px 10px -30px",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },
}));
const useStyles1 = makeStyles((styles) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));



const BreakDownListView = () => {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();
  const classs = useStyles1();

  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = useState(false);
  const [breakDown, setbreakDown] = React.useState({
    edit: false,
    break_down:false,
    inFlow: false,
    ddl_vehicle_id: "",
    ddl_vehicle_no: "",
    txt_Driver:"",
    txt_place:"",
    txt_time:currentTime(),
    brakDown_date:currentDate(),
    txt_dec:"",
    image_path:"",
    image_name:"",  
  }); 
  const [fileLoading, setFileLoading] = React.useState(false); 
  const [breakDownView, setbreakDownView] = React.useState([]);
  const [state, setState] = React.useState({
    edit: false,
  });


    React.useEffect(() => {
        // console.log(location, "sanklocationsales");
        const id = location?.breakDown_id;
        getBreakDownForView(
          id,
          (r) => {
    
            setbreakDownView(r[0]);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
      }, [refresh]);


  const onCloseModel = () => {   
   
    setbreakDown({
      ...breakDown,
      edit: false,
      inFlow: false,
      ddl_vehicle_id: "",
      ddl_vehicle_no: "",
      txt_Driver:"",
      txt_place:"",
      txt_time:currentTime(),
      brakDown_date:currentDate(),
      txt_dec:"",
      image_path:"",
      image:"", 
     
    });
    history.push({
      pathname: "/admin/maintence/breakDown",
    });
  };


  

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
      title={
        state.edit ? "Maintance >  Edit" : "Add BreakDown Details"
      }
        // title="Transportation > Extra Charges"
        btnToolTip="Add BreakDown"
        // onClickAddBtn={onClickAddDestination}
      />

      {/* On Click Model Window Open */}
      <GridContainer className={classes.root}>
    
          <CustomCard
            cdTitle="Break Down"
            filterIcon
            onClickFilter={() => {}}
          >
            <GridContainer
              style={{ padding: "10px" }}
              justify="space-between"
              alignItems="center"
            >
              <GridItem xs={3}>
                <InputLabel required={true} id="label">
                  Truck no
                </InputLabel>
                <Select
                //   options={vehicle}
                  name="ddl_vehicle"
                  placeholder="truck no"
                  formatGroupLabel={(d) => d.label}
                  className={classes.customSelect}
                  value={{
                    value: breakDownView.vehicle_id,
                    label: breakDownView.vehicle_no,
                  }}
                //   onChange={onSelectVehicle}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  disabled
                />
              </GridItem>
              
              <GridItem xs={3} style={{marginLeft:"-2%"}}>
                      <InputLabel required={true} id="label">
                        Driver Name
                      </InputLabel>
                      <TextField
                         size="small"
                         type="String"
                        name="txt_Driver"
                        id="outlined-basic"
                        placeholder="Driver name"
                        variant="outlined"
                        formatGroupLabel={(d) => d.label}
                        className={classes.customSelect1}  
                        value={breakDownView.driver_name}                   
                       
                        // onChange={onChange}
                        disabled
                      />
                    </GridItem>

           
               
                    
                    <GridItem xs={3} style={{marginLeft:"-6%"}}>
                      <InputLabel required={true} id="label">
                        Location
                      </InputLabel>
                      <TextField
                         size="small"
                         type="String"
                        name="txt_place"
                        id="outlined-basic"
                        placeholder="place name"
                        variant="outlined"
                        formatGroupLabel={(d) => d.label}
                        // className={classes.customSelect1}                       
                        // onChange={onChange}
                        value={breakDownView.Place }                   

                        disabled
                      />
                    </GridItem>

                    <GridItem xs={2} style={{marginLeft:"-6%"}}>
                      <InputLabel required={true} id="label">
                        {" "}
                        Time
                      </InputLabel>
                      <TextField
                        size="small"
                        type="time"
                        name="txt_time"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        // className={classes.customSelect1}                       
                        value={breakDownView.breakDownTime }                 

                        variant="outlined"
                        disabled
                      />
                    </GridItem>
{console.log(dateFormate(breakDownView.breakDownDate),"snakdata")}
                    <GridItem xs={2}>
                      <InputLabel required={false} id="label">
                        Date
                      </InputLabel>
                      <TextField
                        size="small"
                        type="text"
                        name="brakDown_date"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                     
                        value={dateFormate(breakDownView.breakDownDate)}             

                        FormHelperTextProps={{
                          style: { textAlign: "right" },
                        }}
                        variant="outlined"
                        disabled
                      />
                    </GridItem>

                    <GridItem xs={6}>
                      <InputLabel required={true} id="label">
                        Description
                      </InputLabel>
                      <TextField
                        size="small"
                        type="string"
                        name="txt_dec"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={breakDownView.desecription}
                        variant="outlined"
                        disabled
                      />
                    </GridItem>

                  
                 
                    <>
                      <GridItem
                        xs={7}
                        style={{ marginTop: 25, marginLeft: 25 }}
                      >
                        <GridContainer>
                          <GridItem xs="6">
                            <Paper elevation={0}>
                              {fileLoading ? (
                                <CircularProgress />
                              ) : (
                                <img
                                  id="image_path"
                                  // className={classes.itemImg}
                                  style={{ width: "30%" }}
                                  src={
                                    breakDown.image_path
                                      ? IMG_API + breakDown.image_path
                                      : "https://via.placeholder.com/160"
                                  }
                                  height="100%"
                                  alt="image"
                                />
                              )}
                            </Paper>
                          </GridItem>
                          <GridItem xs="3" style={{ marginTop: 25 }}>
                            <input
                              accept="image/*"
                              className={classes.input}
                              style={{ display: "none" }}
                              name={
                                "Break Down" 
                              }
                            //   onChange={onChange}
                              id="image"
                              type="file"
                            />
                            <label htmlFor="image">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth={true}
                                className={classes.browseBtn}
                              >
                                Upload Img
                              </Button>
                            </label>
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                  
                    </>

            
            
            </GridContainer>
          </CustomCard>

      

        <div style={{ width: "100%" }} className={classes.actionbtns}>
             
            
                <ColoseButton
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    float: "right",
                    width: "10%",
                    marginRight: "10px",
                    border: "none",
                  }}
                  onClick={onCloseModel}
                >
                  close
                </ColoseButton>
              </div>
      </GridContainer>
    
    </ThemeProvider>
  );
};

export default BreakDownListView;
