import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";
import { currentDate } from "../views/Pages/HelperComponent/utils";

export const getTrackingVehicle = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/tracking/tracking_vehicle_list", {
      tracking_status: false,

    });
    console.log(res,"sen01")

    if
      (res.status === 200) {
      const r = res.data;

      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({

          //for ddl
          value: r.vehicle_id,
          label: r.vehicle_no,
          transaction_no:r.transaction_no,
          salesCumDispatch_no:r.salesCumDispatch_no,
          salse_dispatch_id:r.salse_dispatch_id,
          trip_no:r.trip_no,
        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postTracking = async (TrackingDetails, insertedById, onSuccess, onFailure) => {
  console.log(TrackingDetails,"sen1709/")
  try {
    const res = await webApi.post("/master/tracking/insert", {
      vehicle_id: TrackingDetails.vehicle_id,
      vehicle_no: TrackingDetails.vehicle_no,
      transaction_no:TrackingDetails.transaction_no,
      inserted_by_id: insertedById,
      challan_date:TrackingDetails.challan_date ,
        oil_in_time:TrackingDetails.oil_in_time,
        driver_expenses_time:TrackingDetails.driver_expenses_time,
        exit_time:TrackingDetails.exit_time,
        approved_time:TrackingDetails.approved_time,
        actual_time:TrackingDetails.actual_time,
        excess_time:TrackingDetails.excess_time,
        txt_remarks:TrackingDetails.txt_remarks,

        office_exit_time:TrackingDetails.office_exit_time,
        in_time:TrackingDetails.in_time,
        approve_to_time:TrackingDetails.approve_to_time,
        actual_to_time:TrackingDetails.actual_to_time,
        excess_to_time:TrackingDetails.excess_to_time,
        txt_to_remarks:TrackingDetails.txt_to_remarks,

        ghat_in_time:TrackingDetails.ghat_in_time,
        ghat_exit_time:TrackingDetails.ghat_exit_time,
        last_approve_time:TrackingDetails.last_approve_time,
        last_actual_time:TrackingDetails.last_actual_time,
        last_excess_time:TrackingDetails.last_excess_time,
        txt_last_remarks:TrackingDetails.txt_last_remarks,

        desi_ghat_exit_time:TrackingDetails.desi_ghat_exit_time,
        holding_time:TrackingDetails.holding_time,
        desi_approve_time:TrackingDetails.desi_approve_time,
        desi_actual_time:TrackingDetails.desi_actual_time,
        desi_excess_time:TrackingDetails.desi_excess_time,
        // txt_desi_remarks:TrackingDetails_txt_desi_remarks,

        order_holding_time:TrackingDetails.order_holding_time,
        holding_exit_time:TrackingDetails.holding_exit_time,
        order_approve_time:TrackingDetails.order_approve_time,
        order_actual_time:TrackingDetails.order_actual_time,
        order_excess_time:TrackingDetails.order_excess_time,
        txt_order_remarks:TrackingDetails.txt_order_remarks,

        unload_holding_exit_time:TrackingDetails.unload_holding_exit_time,
        return_time:TrackingDetails.return_time,
        unload_approve_time:TrackingDetails.unload_approve_time,
        unload_actual_time:TrackingDetails.unload_actual_time,
        unload_excess_time:TrackingDetails.unload_excess_time,
        txt_unload_remarks:TrackingDetails.txt_unload_remarks,
      received_date: TrackingDetails.received_date,
      inserted_by_date: timestamp(currentDate()),
    
      salesCumDispatch_no:TrackingDetails.salesCumDispatch_no,
      salse_dispatch_id:TrackingDetails.salse_dispatch_id,
      extra_expenses_amount:Number(TrackingDetails.extra_expenses_amount),
      advance_expense_amount:Number(TrackingDetails.advance_expense_amount),
    })
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
      const res2 = await webApi.post("/master/salesCumDispatch/update", {
        salse_dispatch_id:TrackingDetails.salse_dispatch_id,
        tracking_status:true,
      });
      const Vehicleres = await webApi.post("/master/vehicle/update", {
        vehicle_id: TrackingDetails.vehicle_id,
        inTransit:false,
        trip_no:TrackingDetails.trip_no + 1
      });
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
}

export const getAllTracking = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/tracking/list", {
    });
    if
      (res.status === 200) {
      const r = res.data;


      let all_advance = [];
      let count = 0;
      r.map((r, i) => {
        console.log(r,"sen25012023")
        r._id ?
          all_advance.push({
            id: count + 1,
            vehicle_id: r.vehicle_id,
            vehicle_no: r.vehicle_no,
            received_time: r.received_time,
            received_date: r.received_date,
            unloading_time: r.unloading_time,
            action: "action",
          })

          : ''
      
  
        });

return onSuccess(all_advance);
      } else {
  onFailure("Something Wrong! Please Try again later" + res.data);
}
    } catch (error) {
  onFailure("Something Wrong! Please Try again later" + error);
}
  };

export const getSearchTracking = async (addSearch, onSuccess, onFailure) => {
  console.log(addSearch, "sankh25081")
  try {

    const res = await webApi.post("/master/tracking/list", {
      vehicle_no: addSearch.txt_vehicle_no,
      fromDate: timestamp(addSearch.txt_from_date),
      toDate: timestamp(addSearch.txt_to_date),

    });
    if
      (res.status === 200) {
      const r = res.data;

      let tracking = [];
      r.map((r, i) => {

        tracking.push({
            id: i + 1,
            vehicle_id: r.vehicle_id,
            vehicle_no: r.vehicle_no?r.vehicle_no:"~",
            tracking_date: dateFormate(r.inserted_by_date),
            tracking_order_no: r.tracking_order_no,
            transaction_no: r.transaction_no ? r.transaction_no : "~",
            // action: "action",
          })


      });

      return onSuccess(tracking);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllAdvance = async (transaction_no,onSuccess, onFailure) => {
  try {

    const res = await webApi.post("/master/advance/list", {
      transaction_no:transaction_no,
    });
    if 
    (res.status === 200)
     {
      const r = res.data;
      let all_advance = [];
      r.map((r, i) => {
        all_advance.push({
          extra_expense_amount:r.extra_expense_amount,
        });
      });
      
      return onSuccess(all_advance[0]);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//expenses
export const getAllExpenses = async (onSuccess, onFailure,q) => {
  try {

    const res = await webApi.post("/master/expenses/list", {
      expenses_type_id: "E",

    });
    if 
    (res.status === 200)
     {
      const r = res.data;
       

      let all_expenses = [];
      r.map((r, i) => {
          all_expenses.push({
          value:r.expenses_id,
          label:r.expenses,
        });
      });
      return onSuccess(all_expenses);
      console.log(all_expenses,"sen1609")
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//vehicle tracking data
export const vehicleTracking = async(transactionNo,onSuccess,onFailure)=>{
  try {
    const trackingData = await webApi.post("/master/tracking/tracking_transaction",{
      transaction_no:transactionNo
    })

    if(trackingData.status === 200){
      onSuccess(trackingData.data[0])
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);

  }
}