import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";

 export const postLocation= async (info,inserted_by_id, onSuccess, onFailure) => {

  console.log(info,"location_info");
    try {
        
      const res = await webApi.post("/master/location/insert", {
        location_id: info.location_id,
        location: info.txt_location,
        amount: info.txt_amount,
        effective_from_date: timestamp(info.txt_from_date),
        effective_to_date:timestamp(info.txt_to_amount),
        details: info.txt_details,
        location_type_id: info.ddl_location_type,
        location_type:info.ddl_location_type_label,
        active_status: info.switch_active_status ? "Y" : "N",
        inserted_by_id:inserted_by_id,
      });
  
      if (res.status === 200) {
        const r = res.data;
      
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      // onFailure("Something Wrong! Please Try again later " + error);
    }
  };




export const getAllLocation = async (onSuccess, onFailure,q) => {
    try {
  
      const res = await webApi.post("/master/location/list", {
        keyword_pharse: q?.txt_keyword_phrase,
  
      });
      if 
      (res.status === 200)
       {
        const r = res.data;
         
  
        let all_location = [];
        r.map((r, i) => {
            all_location.push({
            id: i + 1,
            location_id:r.location_id,
            location:r.location,
            location_type:r.location_type,
            details: r.details,
            status: r.active_status,
            amount: r.amount,
            action_items: r.action_items,
            action: "action",
          });
        });
        if(all_location.length>0){
          return onSuccess(all_location)
        }else{
          onFailure("Location Not Found" + res.data);
        }
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };



  //dispatch Vehicle by id
  export const getVehicleById = async (onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/vehicle/list", {
        group_id: 26
      });
      if (res.status === 200) {
        const r = res.data;
        let allVehicle = [];
        r.map((r, i) => {
          allVehicle.push({
  
            value: r.vehicle_id,
            label: r.vehicle_no,
          });
        });
        
        return onSuccess(allVehicle);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };



  export const deleteVehicle = async (id, onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/vehicle/delete", {
        vehicle_id: id,
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };

  

  export const updateLocation = async (info, onSuccess, onFailure) => {
    
    try {
      const res = await webApi.post("/master/location/update", {
        location_id: info.location_id,
        location: info.txt_location,
        location_type: info.ddl_location_type_label,
        details: info.txt_details,
        amount: info.txt_amount,
        effective_from_date: timestamp(info.txt_from_date),
        effective_to_date:timestamp(info.txt_to_amount),
        active_status: info.switch_active_status ? "Y" : "N",
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };