import React, { useEffect, useState } from "react";

// // @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// // core components
import { StepContent, ThemeProvider } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
// import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import { StatsCard } from "views/Components/CustomCard";
import {
  Balance,
  CommentBank,
  CurrencyRupee,
  LocalShippingOutlined,
  ShoppingBag,
  Today,
} from "@mui/icons-material";
import { getTotalStats } from "services/totalStats";
import { currencyFormate } from "views/Pages/HelperComponent/utils";
import { StatsCardNew } from "views/Components/CustomCard";
import { AccountBalance, RemoveRedEyeOutlined } from "@material-ui/icons";
import DashboardModel from "views/Components/DashboardModel";
import { getSalesStat } from "services/totalStats";

import { useStateValue } from "../../context/context";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "react-select";
import { StepLabel } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { getAllVehicleForDashboard } from "services/DashboardService";
import { getAllDashboardTracking } from "services/DashboardService";
import {
  CustomCard,
  StatsCardBreakDown,
  StatsCardCash,
  StatsCardDashBoard,
  StatsCardLoad,
  StatsCardLoadNew,
} from "../Components/CustomCard";
import { actionTypes } from "../../context/reducer";
import { ProgressBar } from "react-loader-spinner";
import { getTotalCash } from "services/totalStats";
import { getOutStanding } from "services/totalStats";

import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined";
import BikeScooterOutlinedIcon from "@mui/icons-material/BikeScooterOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import TimeToLeaveOutlinedIcon from "@mui/icons-material/TimeToLeaveOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import { getLoading } from "services/DashboardService";
import { getLoadingTotal } from "services/DashboardService";
import { getUnLoadingTotal } from "services/DashboardService";
import { getReceivedToday } from "services/DashboardService";
import { getReceivedTotal } from "services/DashboardService";
import { totalOiled } from "services/DashboardService";
import { getTotalCashInHand, getTotalBank } from "services/DashboardService";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { getTotalSalesStat } from "services/totalStats";
import { currentDate } from "views/Pages/HelperComponent/utils";
import { currentDate1 } from "views/Pages/HelperComponent/utils";
import { getInTransit } from "services/DashboardService";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import clxs from "classnames";
import TimelineIcon from "@material-ui/icons/Timeline";
import { AppointmentCard, DailyFeedCard } from "../Components/CustomCard";
import { getMaintanceToday } from "services/DashboardService";
import { getMaintanceTotal } from "services/DashboardService";
import { getLedgerAccount } from "services/accountLedgerService";
import { getBreakDown } from "services/DashboardService";
import { getExpensesToday } from "services/DashboardService";
import { getExpensesTotal } from "services/DashboardService";
import Typography from "views/Components/Typography";
import { getTodayExtraPayment } from "services/DashboardService";
import { getTotalExtraPayment } from "services/DashboardService";

const useStyles = makeStyles(styles);

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();

  const [totalStats, setTotalStats] = useState([]);
  const [salesStats, setSalesStats] = useState([]);
  const [totalSalesStats, setTotalSalesStats] = useState([]);

  const [salesTotal, setSalesTotal] = useState([]);
  const [totalcash, setTotalcash] = useState([]);
  const [loadingBankTotal, setLoadingBankTotal] = useState([]);

  const [outStanding, setOutstanding] = useState([]);
  const [bank, setBank] = useState([]);
  const [classicModal, setClassicModal] = useState(false);

  const [globalState, dispatch] = useStateValue();
  const [vehicle, setAllVehicle] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [loadingOutstanding, setLoadingOutstanding] = React.useState(false);
  const [loadingBank, setLoadingBank] = React.useState(false);

  const [feedToShow, setFeedsToShow] = React.useState([]);
  const messagesEndRef = React.useRef(null);
  const [extraPayment, setExtraPayment] = React.useState([]);

  //animation

  const [currentValue, setCurrentValue] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const targetValue = totalStats?.saleToday?.data || 0; // set the target value here

  const [isAnimatingTotal, setIsAnimatingTotal] = useState(false);
  const [totalValue, settotalValue] = useState(0);
  const totalCashValue =
    salesTotal[0]?.journalbalance +
      salesTotal[1]?.receiptpaybalance -
      salesTotal[0]?.ledger_opening || 0;

  const [currentOutStandingValue, setCurrentOutStandingValue] = useState(0);
  const [isAnimatingOutStanding, setIsAnimatingOutStanding] = useState(false);
  const targetOutStandingValue = outStanding[0]?.outstanding || 0;

  const [currentBankValue, setCurrentBankValue] = useState(0);
  const [isAnimatingBank, setIsAnimatingBank] = useState(false);
  const targetBankValue = bank?.data?.totalCollectionBank?.data || 0;

  const [loadingToday, setLoadingToday] = useState("");
  const [loadingLoadingToday, setLoadingLoadingToday] = React.useState(false);
  const [isAnimatingLoading, setIsAnimatingLoading] = useState(false);

  const [loadingTotal, setLoadingTotal] = useState("");
  const [loadingLoadingTotal, setLoadingLoadingTotal] = React.useState(false);

  const [UnloadingTotal, setUnLoadingTotal] = useState("");
  const [UnloadingloadingTotal, setUnLoadingloadingTotal] = React.useState(
    false
  );

  const [transit, setTransit] = useState("");
  const [transitTotal, setTransitTotal] = React.useState(false);

  const [expensesToday, setExpensesToday] = useState([]);
  const [receiveToday, setReceiveToday] = useState([]);

  const [loadingExpensesToday, setloadingExpensesToday] = React.useState(false);
  const [loadingReceivedToday, setloadingReceivedToday] = React.useState(false);

  setloadingReceivedToday;
  const [expensesTotal, setExpensesTotal] = useState("");
  const [loadingExpensesTotal, setloadingExpensesTotal] = React.useState(false);

  const [receiveTotal, setReceiveTotal] = useState("");
  const [loadingreceiveTotal, setloadingreceiveTotal] = React.useState(false);

  const [totalTrip, setTotalTrip] = useState("");
  const [loadingTotalTrip, setloadingTotalTrip] = React.useState(false);

  const [MaintanceToday, setMaintanceToday] = useState("");
  const [loadingmaintanceToday, setloadingmaintanceToday] = React.useState(false);

 const [MaintanceTotal, setMaintanceTotal] = useState("");
  const [loadingmaintanceTotal, setloadingmaintanceTotal] = React.useState(false);
  const [openingBalance, setOpeningBalance] = React.useState({});
  const [addSearch, setAddSearch] = React.useState({
    ddl_ledger_account: "",
  
  });

  const [breakdown, setBreakdown] = useState("");
  const [loadingBreakDown, setloadingBreakDown] = React.useState(false);
  const [loadingExtra, setloadingExtra] = React.useState(false);
  const [loadingExtraTotal, setloadingExtraTotal] = React.useState(false);
  const [extraPaymentTotal, setExtraPaymentTotal] = React.useState([]);



  const steps = ["Oil", "Advance", "Loading", "Sales/Dispatch", "Tracking"];

  let totalDr = 0;
  let totalCr = 0;

  const balanceSheet = () => {
    totalcash.map((row, i) => {

      console.log("xx", openingBalance.opening_balance, totalDr, row.dr_cr);
      console.log("sen2505/row", row);

      if (row.dr_cr === 1) {
        totalDr = parseFloat(row.amount) + parseFloat(totalDr);
      } else {
        totalCr = parseFloat(row.amount) + parseFloat(totalCr);
      }
    });


    console.log("xx", openingBalance.opening_balance, totalDr);

    if (openingBalance.dr_cr_status === "Dr") {
      totalDr = openingBalance.opening_balance + Number(totalDr);
      // total = closingBalance + (totalCr - totalDr);
      // if(closingBalance > 0) closing_balance_dr_cr = "Dr";
      // else {
      //   closing_balance_dr_cr = "Cr";
      //   closingBalance = -closingBalance;
      // }
      console.log(totalDr, "xxx");
    }
    else {

      totalCr = openingBalance.opening_balance + (totalCr);


    }

    console.log(totalDr, "dr total");
    console.log(totalCr, "cr total");
  };

  const balanceSheet1 = () => {
    loadingBankTotal.map((row, i) => {

      console.log("xx", openingBalance.opening_balance, totalDr, row.dr_cr);
      console.log("sen2505/row", row);

      if (row.dr_cr === 1) {
        totalDr = parseFloat(row.amount) + parseFloat(totalDr);
      } else {
        totalCr = parseFloat(row.amount) + parseFloat(totalCr);
      }
    });


    console.log("xx", openingBalance.opening_balance, totalDr);

    if (openingBalance.dr_cr_status === "Dr") {
      totalDr = openingBalance.opening_balance + Number(totalDr);
      // total = closingBalance + (totalCr - totalDr);
      // if(closingBalance > 0) closing_balance_dr_cr = "Dr";
      // else {
      //   closing_balance_dr_cr = "Cr";
      //   closingBalance = -closingBalance;
      // }
      console.log(totalDr, "xxx");
    }
    else {

      totalCr = openingBalance.opening_balance + (totalCr);


    }

    console.log(totalDr, "dr total");
    console.log(totalCr, "cr total");
  };

  const balanceSheet2 = () => {
    extraPayment.map((row, i) => {

      console.log("xx", openingBalance.opening_balance, totalDr, row.dr_cr);
      console.log("sen2505/row", row);

      if (row.dr_cr === 1) {
        totalDr = parseFloat(row.amount) + parseFloat(totalDr);
      } else {
        totalCr = parseFloat(row.amount) + parseFloat(totalCr);
      }
    });


    console.log("xx", openingBalance.opening_balance, totalDr);

    if (openingBalance.dr_cr_status === "Dr") {
      totalDr = openingBalance.opening_balance + Number(totalDr);
      // total = closingBalance + (totalCr - totalDr);
      // if(closingBalance > 0) closing_balance_dr_cr = "Dr";
      // else {
      //   closing_balance_dr_cr = "Cr";
      //   closingBalance = -closingBalance;
      // }
      console.log(totalDr, "xxx");
    }
    else {

      totalCr = openingBalance.opening_balance + (totalCr);


    }

    console.log(totalDr, "dr total");
    console.log(totalCr, "cr total");
  };

  const balanceSheet3 = () => {
    extraPaymentTotal.map((row, i) => {

      console.log("xx", openingBalance.opening_balance, totalDr, row.dr_cr);
      console.log("sen2505/row", row);

      if (row.dr_cr === 1) {
        totalDr = parseFloat(row.amount) + parseFloat(totalDr);
      } else {
        totalCr = parseFloat(row.amount) + parseFloat(totalCr);
      }
    });


    console.log("xx", openingBalance.opening_balance, totalDr);

    if (openingBalance.dr_cr_status === "Dr") {
      totalDr = openingBalance.opening_balance + Number(totalDr);
      // total = closingBalance + (totalCr - totalDr);
      // if(closingBalance > 0) closing_balance_dr_cr = "Dr";
      // else {
      //   closing_balance_dr_cr = "Cr";
      //   closingBalance = -closingBalance;
      // }
      console.log(totalDr, "xxx");
    }
    else {

      totalCr = openingBalance.opening_balance + (totalCr);


    }

    console.log(totalDr, "dr total");
    console.log(totalCr, "cr total");
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return "Oil Filling Needed";
      case 1:
        return " Oil Complete But Advance Pending";
      case 2:
        return "Advance Complete But Loading Car Pending";
      case 3:
        return "Loading Complete But Sales And Dispatch Pending";
      case 4:
        return "Sales And Dispatch Complete";
      case 5:
        return "Car Tracking Complete And Return Pump";
      default:
        return "Garage";
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // const onSelect = (event, value) => {
  //   console.log(value.name, "sen11102022", event.value);
  //   switch (value.name) {
  //     case "ddl_vehicle_no":
  //       getAllDashboardTracking(
  //         event.value,
  //         (r) => {
  //           console.log(r, "sank080223");
  //           if (r.inTransit === false) {
  //             if (r.trip_no > 0) {
  //               setActiveStep(5);
  //             } else setActiveStep(0);
  //           } else if (r.inTransit === true && r.oil_Status === false) {
  //             setActiveStep(1);
  //           } else if (
  //             r.inTransit === true &&
  //             r.oil_Status === true &&
  //             r.Advance_Status === false
  //           ) {
  //             setActiveStep(2);
  //           } else if (
  //             r.inTransit === true &&
  //             r.oil_Status === true &&
  //             r.Advance_Status === true &&
  //             r.loading_Status === false
  //           ) {
  //             setActiveStep(3);
  //           } else if (
  //             r.inTransit === true &&
  //             r.oil_Status === true &&
  //             r.Advance_Status === true &&
  //             r.loading_Status === true &&
  //             r.Sales_cum_dispatches_Status === false
  //           ) {
  //             setActiveStep(4);
  //           }
  //         },
  //         (err) => {
  //           dispatch({
  //             type: actionTypes.SET_OPEN_MSG,
  //             payload: { msg: err, msgType: "error" },
  //           });
  //         }
  //       );
  //       break;
  //   }
  // };

  const onSelect = (event, value) => {
    console.log(value.name, "sen11102022", event.value);
    switch (value.name) {
      case "ddl_vehicle_no":
        getAllDashboardTracking(
          event.value,
          (r) => {
            console.log(r, "sank080223");
            if (
              r.inTransit === true &&
              r.oil_Status === true &&
              r.Advance_Status === true &&
              r.loading_Status === true &&
              r.Sales_cum_dispatches_Status === true
            ) {
              setActiveStep(5);
            } else if (
              r.inTransit === true &&
              r.oil_Status === true &&
              r.Advance_Status === true &&
              r.loading_Status === true &&
              r.Sales_cum_dispatches_Status === false
            ) {
              setActiveStep(4);
            } else if (
              r.inTransit === true &&
              r.oil_Status === true &&
              r.Advance_Status === true &&
              r.loading_Status === false 
              
            ) {
              setActiveStep(3);
            } else if (
              r.inTransit === true &&
              r.oil_Status === true &&
              r.Advance_Status === false 
            
            ) {
              setActiveStep(2);
            } else if (
              r.inTransit === false 
              
             
            ) {
              setActiveStep(0);
            } else {
              setActiveStep(0);
            }
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
        break;
    }
};


  useEffect(() => {
    if (isAnimating) {
      console.log(targetValue, "sankint");
      const intervalId = setInterval(() => {
        setCurrentValue(
          (prevValue) => prevValue + Math.ceil((targetValue - prevValue) / 2)
        );
      }, 100);
      return () => clearInterval(intervalId);
    }
  }, [targetValue, isAnimating]);

  useEffect(() => {

    getLedgerAccount(
      addSearch.ddl_ledger_account,
      (res) => {
        setOpeningBalance(res)
      },
      (err) => {
      })

    // if (isAnimatingTotal) {
    //   const intervalId = setInterval(() => {
    //     console.log(totalCashValue,"sank002")
    //     settotalValue((prevValue) => prevValue + Math.ceil((totalCashValue - prevValue) / 2));
    //   }, 100);
    //   return () => clearInterval(intervalId);
    // }

    if (isAnimatingOutStanding) {
      const intervalId = setInterval(() => {
        setCurrentOutStandingValue(
          (prevValue) =>
            Number(prevValue) +
            Math.ceil((targetOutStandingValue - Number(prevValue)) / 2)
        );
      }, 100);
      return () => clearInterval(intervalId);
    }

    if (isAnimatingBank) {
      const intervalId = setInterval(() => {
        setCurrentBankValue(
          (prevValue) =>
            prevValue + Math.ceil((targetBankValue - prevValue) / 2)
        );
      }, 100);
      return () => clearInterval(intervalId);
    }
  }, [
    isAnimatingTotal,
    targetOutStandingValue,
    isAnimatingOutStanding,
    targetBankValue,
    isAnimatingBank,
  ]);

  const onClickTruckStatus = () => {
    setClassicModal(true);
    getAllVehicleForDashboard(
      (r) => {
        console.log(r, "sankdash");
        setAllVehicle(r);
        setLoading(false);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const Click = () => {
    setIsAnimating(true);
    getSalesStat(
      (res) => {
        setSalesStats(res[0]);
        console.log(res, "sen22022023/2");
      },
      (err) => {
        console.log(err, "sen22022023/2");
      }
    );
  };

  const ClickSalesTotal = () => {
    setIsAnimating(true);
    getTotalSalesStat(
      (res) => {
        setTotalSalesStats(res[0]);
        console.log(res, "sen22022023/2");
      },
      (err) => {
        console.log(err, "sen22022023/2");
      }
    );
  };


  balanceSheet();
  const ClickTotal = () => {
    setIsAnimatingTotal(true);
    setLoading(true);
    getTotalCashInHand(
      (res) => {
        console.log(res, "sanktotal");
        setTotalcash(res);
        setLoading(false);
      },
      (err) => {}
    );
  };
  const DoubleClickTotalCash = () => {
    history.push({
      pathname: "/admin/account/ledger",

      // state: {
      //   dash: true,
      //   ledger_account_id: 2,
      //   to_date: currentDate(),
      //   from_date: currentDate1()

      // },
    });
  };
  const ClickOutStandingTotal = () => {
    setIsAnimatingOutStanding(true);
    setLoadingOutstanding(true);
    getOutStanding(
      (res) => {
        console.log(res, "sanktotaloutsta");
        setOutstanding(res);
        setLoadingOutstanding(false);
      },
      (err) => {}
    );
  };

  balanceSheet1();
  const ClickBank = () => {
    setIsAnimatingBank(true);
    setLoadingBank(true);
    getTotalBank(
      (res) => {
        // console.log(res[0], "sa");
        setLoadingBankTotal(res);
        setLoadingBank(false);
      },
      (err) => {
        setLoadingBank(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const ClickLoadingToday = () => {
    // setIsAnimatingLoading(true);
    setLoadingLoadingToday(true);
    getLoading(
      (res) => {
        console.log(res[0], "sankload");
        if (res.length > 0) {
          setLoadingToday(res[0]);
        } else {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: "Today Not Loading", msgType: "error" },
          });
        }
        setLoadingLoadingToday(false);
      },
      (err) => {
        setLoadingLoadingToday(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const ClickLoadingTotol = () => {
    // setIsAnimatingLoading(true);
    setLoadingLoadingTotal(true);
    getLoadingTotal(
      (res) => {
        console.log(res[0], "sankload");

        setLoadingTotal(res[0]);
        setLoadingLoadingTotal(false);
      },
      (err) => {}
    );
  };

  const DoubleClickLoadingTotal = () => {
    history.push({
      pathname: "/admin/tranportation/loading",
    });
  };

  const ClickUnloading = () => {
    // setIsAnimatingLoading(true);
    setUnLoadingloadingTotal(true);
    getUnLoadingTotal(
      (res) => {
        if (res.length > 0) {
          setUnLoadingTotal(res[0]);
          setUnLoadingloadingTotal(false);
        } else {
          setUnLoadingloadingTotal(false);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: "Unloading not found", msgType: "error" },
          });
        }
      },
      (err) => {}
    );
  };

  const ClicTotalTrip = () => {
    // setIsAnimatingLoading(true);
    setloadingTotalTrip(true);
    totalOiled(
      (res) => {
        // console.log(res.oil_no, "sankloadTrip");

        setTotalTrip(res);
        setloadingTotalTrip(false);
      },
      (err) => {}
    );
  };

  const ClickReceiveTotal = () => {
    // setIsAnimatingLoading(true);
    setloadingreceiveTotal(true);
    getReceivedTotal(
      (res) => {
       

        setReceiveTotal(res);
        setloadingreceiveTotal(false);
      },
      (err) => {}
    );
  };

  const ClickExpensesToday = () => {
    // setIsAnimatingLoading(true);
    setloadingExpensesToday(true);
    getExpensesToday(
      (res) => {
        console.log(res,"sankhares")
        
          setExpensesToday(res);
      
        setloadingExpensesToday(false);
      },
      (err) => {
        setloadingExpensesToday(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const ClickReceivedToday = () => {
    // setIsAnimatingLoading(true);
    setloadingReceivedToday(true);
    getReceivedToday(
      (res) => {
        console.log(res, "sankload123");
      
          setReceiveToday(res);
      
        setloadingReceivedToday(false);
      },
      (err) => {
        setloadingReceivedToday(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const ClickExpensesTotal = () => {
    // setIsAnimatingLoading(true);
    setloadingExpensesTotal(true);
    getExpensesTotal(
      (res) => {
       
       
          setExpensesTotal(res);
      
        setloadingExpensesTotal(false);
      },
      (err) => {
        setloadingExpensesTotal(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const ClickTransit = () => {
    // setIsAnimatingLoading(true);
    setTransitTotal(true);
    getInTransit(
      (res) => {
        if (res.length > 0) {
          setTransit(res[0]);
          setTransitTotal(false);
        } else {
          setTransitTotal(false);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: "Transit not found", msgType: "error" },
          });
        }
      },
      (err) => {}
    );
  };

  const DoubleClickTotalInTransit = () => {
    history.push({
      pathname: "/admin/report/inTransitReport",

    });
  }

  const ClickMaintanceToday = () => {
    // setIsAnimatingLoading(true);
    setloadingmaintanceToday(true);
    getMaintanceToday(
      (res) => {
        console.log(res[0], "sankload");
        if (res.length > 0) {
          setMaintanceToday(res[0]);
        } else {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: "Today Maintance not done", msgType: "error" },
          });
        }
        setloadingmaintanceToday(false);
      },
      (err) => {}
    );
  };

  const ClickMaintanceTotal = () => {
    // setIsAnimatingLoading(true);
    setloadingmaintanceTotal(true);
    getMaintanceTotal(
      (res) => {
        console.log(res[0], "sankload");
        if (res.length > 0) {
          setMaintanceTotal(res[0]);
        } else {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: " Maintance not done", msgType: "error" },
          });
        }
        setloadingmaintanceTotal(false);
      },
      (err) => {}
    );
  };


  const ClickBreakDownTotal = () => {
    // setIsAnimatingLoading(true);
    setloadingBreakDown(true);
    getBreakDown(
      (res) => {
        
        console.log(res[0], "sankBreakDown");
        if(res.length > 0){
        setBreakdown(res[0]);
        } else {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: "Vehicle Not Break Down Yet", msgType: "error" },
          });
        }
        setloadingBreakDown(false);
      },
      (err) => {}
    );
  };

  balanceSheet2();
  const ClickExtraPayment = () => {
    // setIsAnimatingLoading(true);
    setloadingExtra(true);
    getTodayExtraPayment(
      (res) => {
       
          setExtraPayment(res);
       
        setloadingExtra(false);
      },
      (err) => {}
    );
  };


  balanceSheet3();
  const ClickExtraPaymentTotal = () => {
    // setIsAnimatingLoading(true);
    setloadingExtraTotal(true);
    getTotalExtraPayment(
      (res) => {
       
          setExtraPaymentTotal(res);
       
          setloadingExtraTotal(false);
      },
      (err) => {}
    );
  };

  

  const DoubleClickBreakDown = () => {
    history.push({
      pathname: "/admin/maintence/breakDown",
    });
  };

  // console.log(salesStats, "24022023")
  return (
    <ThemeProvider theme={theme}>
      <div style={{ width: "100%" }}>
        <div className={classes.statCards}>
          {/* sales Today */}
          <StatsCardDashBoard
            style={{ border: "solid" }}
            icon={<ShoppingBag fontSize="large" style={{ color: "#3699ff" }} />}
            value={
              salesStats?.totalAmount > 0
                ? salesStats?.totalAmount
                : salesStats?.totalAmount === 0
                ? currencyFormate(salesStats?.totalAmount)
                : ""
            }
            label="Sales Today"
            onClicks={Click}
          />

          {/* Total Sales */}

          <StatsCardDashBoard
            style={{ border: "solid" }}
            icon={<ShoppingBag fontSize="large" style={{ color: "#3699ff" }} />}
            value={
              totalSalesStats?.totalAmount > 0
                ? totalSalesStats?.totalAmount
                : totalSalesStats?.totalAmount === 0
                ? currencyFormate(totalSalesStats?.totalAmount)
                : ""
            }
            label="Sales Total"
            onClicks={ClickSalesTotal}
            check={
              totalSalesStats?.totalAmount > 0
                ? totalSalesStats?.totalAmount
                : totalSalesStats?.totalAmount === 0
                ? currencyFormate(totalSalesStats?.totalAmount)
                : ""
            }
          />

          {/* Today Expenses */}
        {/* {  console.log(expensesToday, "sankload123")} */}

          <StatsCardDashBoard
            icon={<Balance fontSize="large" style={{ color: "#3699ff" }} />}
            value={
              loadingExpensesToday ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : expensesToday > 0 ? (
                expensesToday
              ) : (
                ""
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Today Expenses"
            onClicks={ClickExpensesToday}
            check={
              expensesToday > 0
                ? expensesToday
                : ""
            }
          />

          {/* Total Expenses */}

          <StatsCardDashBoard
            icon={
              <Balance
                fontSize="large"
                style={{ color: "#3699ff", paddingLeft: "5px" }}
              />
            }
            value={
              loadingExpensesTotal ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : expensesTotal > 0 ? (
                expensesTotal
              ) : (
                ""
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Total Expenses"
            onClicks={ClickExpensesTotal}
            check={
              expensesTotal > 0
                ? expensesTotal
                : ""
            }
          />
          {/* Today Received */}
          <StatsCardDashBoard
            icon={
              <ReceiptLongOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            value={
              loadingReceivedToday ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : receiveToday > 0 ? (
                receiveToday
              ) : (
                ""
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Today Received"
            onClicks={ClickReceivedToday}
            check={
              receiveToday > 0
                ? receiveToday
                : ""
            }
          />

          {/* Total Received */}
          <StatsCardDashBoard
            icon={
              <ReceiptLongOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            value={
              loadingreceiveTotal ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : receiveTotal > 0 ? (
                receiveTotal
              ) : (
                ""
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Total Received"
            onClicks={ClickReceiveTotal}
            check={
              receiveTotal > 0
                ? receiveTotal
                : ""
            }
          />

          {/* Total Trip */}

          <StatsCardLoad
            icon={
              <TimeToLeaveOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            value={
              loadingTotalTrip ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : totalTrip.oil_no > 0 ? (
                totalTrip.oil_no
              ) : (
                ""
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Total Trip"
            onClicks={ClicTotalTrip}
          />

          {/* Cash IN Hand */}

          <StatsCardCash
            icon={
              <CurrencyRupee fontSize="large" style={{ color: "#3699ff" }} />
            }
            value={
              loading ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : Math.abs(
                totalcash[0]?.closing_balance
                ) > 0 ? (
                Math.abs(
                  totalcash[0]?.closing_balance
                    
                )
              ) : (
                ""
              )
            }
            label="Cash In Hand"
            onClicks={ClickTotal}
            onDoubleClicks={DoubleClickTotalCash}
            check="Double Click to Check More"
          />
{console.log(loadingBankTotal,"sankhaloadingBankTotal")}
          {/* Bank Balance */}
          <StatsCardDashBoard
            icon={
              <AccountBalance fontSize="large" style={{ color: "#3699ff" }} />
            }
            value={
              loadingBank ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : Math.abs(loadingBankTotal[0]?.closing_balance > 0) ? (
               Math.abs( loadingBankTotal[0]?.closing_balance)
              ) : (
                ""
              )
            }
            // value={totalStats?.totalCollectionBank?.data || 0}
            label="Account Balance"
            onClicks={ClickBank}
            check={
              loadingBankTotal.closing_balance > 0
                ? loadingBankTotal.closing_balance
                : ""
            }
          />

          <StatsCardDashBoard
            icon={<Balance fontSize="large" style={{ color: "#3699ff" }} />}
            value={
              loadingOutstanding ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : currentOutStandingValue > 0 ? (
                currentOutStandingValue
              ) : (
                ""
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Total Outsatnding"
            onClicks={ClickOutStandingTotal}
          />

          <StatsCardNew
            onClicks={onClickTruckStatus}
            icon={
              <LocalShippingOutlined
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            label="Truck Status"
          />

          <StatsCardLoad
            icon={
              <HourglassBottomOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            value={
              loadingLoadingToday ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : loadingToday.totalTripCount > 0 ? (
                loadingToday.totalTripCount
              ) : (
                ""
              )
            }
            // value={loadingToday?.outstandingInfo?.data || 0}
            label="Loading Today"
            onClicks={ClickLoadingToday}
          />
          {/* Loading Total */}
          <StatsCardLoadNew
            icon={
              <HourglassBottomOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            value={
              loadingLoadingTotal ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : loadingTotal.totalTripCount > 0 ? (
                loadingTotal.totalTripCount
              ) : (
                ""
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Total Loading"
            // check="check double Click"
            onClicks={ClickLoadingTotol}
            onDoubleClicks={DoubleClickLoadingTotal}
            check="Double Click to Check More"
          />

          <StatsCardLoad
            icon={
              <BikeScooterOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            value={
              UnloadingloadingTotal ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : UnloadingTotal.totalTripCount > 0 ? (
                UnloadingTotal.totalTripCount
              ) : (
                ""
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Unloading Pending"
            onClicks={ClickUnloading}
          />
          <StatsCardBreakDown
            icon={
              <EngineeringOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            // value={
            //   loadingBreakDown ? (
            //     <ProgressBar
            //       height="20"
            //       width="50"
            //       align="center"
            //       ariaLabel="progress-bar-loading"
            //       wrapperClass="progress-bar-wrapper"
            //       borderColor="#F4442E"
            //       barColor="#51E5FF"
            //     />
            //   ) : breakdown.totalTripCount > 0 ? (
            //     breakdown.totalTripCount
            //   ) : (
            //    ""
            //   )
            // }
            value={breakdown?.totalTripCount }
            label="Break Down"
            onClicks={ClickBreakDownTotal}
            onDoubleClicks={DoubleClickBreakDown}
            check="Double Click to Check Details"

          />

          <StatsCardDashBoard
            icon={
              <EngineeringOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            value={
              loadingmaintanceToday ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : MaintanceToday.Labour_today + MaintanceToday.amount_today                         
               > 0 ? (
                MaintanceToday.Labour_today + MaintanceToday.amount_today
              ) : (
               ""
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Today Maintainance"
            onClicks={ClickMaintanceToday}
            check={
              MaintanceToday.Today_Maintance > 0
                ? MaintanceToday.Today_Maintance
                : ""
            }
          />

          <StatsCardDashBoard
            icon={
              <EngineeringOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            value={
              loadingmaintanceTotal ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : Math.abs(MaintanceTotal.closing_balance)  > 0 ? (
                Math.abs(MaintanceTotal.closing_balance) 
              ) : (
              ""
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Total Maintainance"
            onClicks={ClickMaintanceTotal}
          />

          <StatsCardDashBoard
            icon={
              <CurrencyRupeeOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            value={
              loadingExtra ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : Math.abs(extraPayment[0]?.closing_balance >= 0)  ? (
                Math.abs( extraPayment[0]?.closing_balance)
              ) : (
                ''
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Today Extra Payment"
            onClicks={ClickExtraPayment}
          />

          <StatsCardDashBoard
            icon={
              <CurrencyRupeeOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            value={
              loadingExtraTotal ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : Math.abs(extraPaymentTotal[0]?.closing_balance > 0) ? (
                Math.abs( extraPaymentTotal[0]?.closing_balance)
              ) : (
                <RemoveRedEyeOutlined
                  fontSize="small"
                  style={{ color: "#3699ff" }}
                />
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="Total Extra Payment"
            onClicks={ClickExtraPaymentTotal}
          />

          <StatsCardLoad
            icon={
              <BikeScooterOutlinedIcon
                fontSize="large"
                style={{ color: "#3699ff" }}
              />
            }
            value={
              transitTotal ? (
                <ProgressBar
                  height="20"
                  width="50"
                  align="center"
                  ariaLabel="progress-bar-loading"
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              ) : transit.totalTripCount > 0 ? (
                transit.totalTripCount
              ) : (
                ""
              )
            }
            // value={totalStats?.outstandingInfo?.data || 0}
            label="InTransit"
            onClicks={ClickTransit}
            onDoubleClicks={DoubleClickTotalInTransit}
            check="Double Click to Check More"
          />
        </div>

        {/* <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <CustomCard cdTitle="Today's Activities" todayLabel height="380px">
              {console.log("ddd", feedToShow)}
              {feedToShow.map((d, i) => (

                <DailyFeedCard
                  key={i}
                  id={i}
                  style={{ width: '50%' }}

                  //avatarUrl={d.company_name}
                  avatarName={` ${d.flag === 1 ? 'Customer' : 'Vendor'} added :  ${d.customer_name}`}

                  avatar={` ${d.flag === 1 ? '' : ''} by :  ${d.inserted_by}`}


                  ///date={`Today ${d.date}`}
                  whenText={`${d.time} to ago`}
                //  statusLabel={d.status}
                />

              ))}
             
              <div ref={messagesEndRef} />
            </CustomCard>
          </GridItem>
         

          
        </GridContainer> */}

        <DashboardModel
          classicModal={classicModal}
          onCloseModel={() => setClassicModal(false)}
          width={850}
          height="auto"
          modelName="Truck Status"
          align="center"
        >
          <div style={{ padding: "20px 10px", width: "100%" }}>
            <GridContainer>
              <GridItem xs={12}>
                <GridItem xs="4">
                  <InputLabel id="label">Truck No.</InputLabel>
                  {console.log("====>>>>>>>>>>>", vehicle)}
                  <Select
                    options={vehicle}
                    formatGroupLabel={(d) => d.label}
                    placeholder="Select Module"
                    name="ddl_vehicle_no"
                    onChange={onSelect}
                    id="outlined-basic"
                    fullWidth={true}
                    variant="outlined"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </GridItem>
                <div>
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    style={{ paddingTop: "40px" }}
                  >
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        {/* <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <div className={classes.actionsContainer}>
                                  <div>
                                    <Button
                                      disabled={activeStep === 0}
                                      onClick={handleBack}
                                      className={classes.button}
                                    >
                                      Back
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={handleNext}
                                      className={classes.button}
                                    >
                                      {activeStep === steps.length - 1
                                        ? "Finish"
                                        : "Next"}
                                    </Button>
                                  </div>
                                </div>
                              </StepContent> */}
                      </Step>
                    ))}
                  </Stepper>
                </div>
                <CustomCard>
                  <div
                    style={
                      activeStep === 0
                        ? { color: "red", textAlign: "center" }
                        : activeStep === 1
                        ? { color: "#A20D5A", textAlign: "center" }
                        : activeStep === 2
                        ? { color: "#3E16EA", textAlign: "center" }
                        : activeStep === 3
                        ? { color: "#16A1EA", textAlign: "center" }
                        : activeStep === 4
                        ? { color: "#EA167A", textAlign: "center" }
                        : activeStep === 5
                        ? { color: "#56EA16", textAlign: "center" }
                        : { color: "#088A2C", textAlign: "center" }
                    }
                  >
                    {activeStep === steps.length ? (
                      <div>All steps completed</div>
                    ) : (
                      <div>{getStepContent(activeStep)}</div>
                    )}
                  </div>
                </CustomCard>

                {/* <div ref={messagesEndRef} /> */}
              </GridItem>
            </GridContainer>
          </div>
        </DashboardModel>
      </div>
    </ThemeProvider>
  );
};

export default Dashboard;
