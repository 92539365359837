import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import {
  dateFormate,
  currencyFormate,
} from "../views/Pages/HelperComponent/utils";

function toTitleCase(str) {
  if(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  else {
    return "";
  }
  
}

// *********************** For Dropdown ****************************//

export const getAllVehicles = async(onSuccess, onFailure) => {
    try {
        const res = await webApi.post("/master/vehicle/list", {});
        if(res.status==200)
        {
            const r = res.data;
            let allVehicles = [{
              id: 0,
              value: 0,
              label: 'Select', 
          }];
            console.log("Roo:",r);
            r.map((r, i) => {
                allVehicles.push({
                    id: i + 1,
                    value: r.vehicle_id,
                    label: r.vehicle_no, 
                });
            });
            return onSuccess(allVehicles);
        } 
        else 
        {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } 
    catch (error) 
    {
        onFailure("Something Wrong! Please Try again later" + error);
    }
};

//*********************************** For Listing ************************************//

export const getSearchVehicle = async(q, onSuccess, onFailure) => {
    try {
        const res = await webApi.post("master/vehicle/totalVehicleDistanceMonthWise", {
            vehicle_id:q.ddl_vehicle_id.value?q.ddl_vehicle_id.value:0,
            month:q.ddl_month.value?q.ddl_month.value:0,
        });

        
        if(res.status==200)
        {
            const vehicleData = res.data
            let uniqueVehicleNos = new Set(vehicleData.map((item) => item.vehicle_no));
            const arrayValues = [...uniqueVehicleNos];
          
            let returnArr=[];
            
            if(arrayValues)
            { 
              for(let iCtr=0; iCtr <arrayValues.length; iCtr++)
              {
                let monthVehicleNoDataArray=[];
                let totalDistance = 0;
                
                vehicleData.map((mothWiseData,index)=>
                {
                  if(arrayValues[iCtr]===mothWiseData.vehicle_no)
                  {
                    let valid_data = {
                      vecTotalOil: mothWiseData.purchase_oil,
                      vecTotalDistance: (mothWiseData.distance_cover).toFixed(2),
                      vecMonth: mothWiseData.fule_bill_month
                    }
                    totalDistance+= mothWiseData.distance_cover;
                   
                    monthVehicleNoDataArray.push(valid_data);
                  }
                })
                let vechileDetails = {
                  vehicle_no:arrayValues[iCtr],
                  totalDistance:totalDistance.toFixed(2),
                  monthWiseData:monthVehicleNoDataArray
                }
  
                returnArr.push(vechileDetails);
              }
            }
              
            return onSuccess(returnArr);     
        }
    }
 catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
     
    
}