import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";


export const posttyreDetails = async (addTyreDetails, details, inserted_by_id, onSuccess, onFailure) => {
  try {

    const res = await webApi.post("/master/tyre_details/insert", {
      vehicle_id: addTyreDetails.ddl_vehicle_no_id,
      vehicle_no: addTyreDetails.ddl_vehicle_no_label,
      tyre_details: details,
      vehicle_status:addTyreDetails.chk_vehicle_status,

      active_status: addTyreDetails.switch_active_status ? "Y" : "N",
      inserted_by_id: inserted_by_id,
    });

    if (res.status === 200) {
      const r = res.data;
      const Vehicleres = await webApi.post("/master/vehicle/update", {
        vehicle_id: Number(addTyreDetails.ddl_vehicle_no_id),
        tyre_details_status: true,
      });
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    // onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getTyreDetails =  async(onSuccess,onFailure)=>{
  try {
    const res = await webApi.post("/master/tyre_details/list",{})

    if (res.status === 200){
      const r =  res.data;
      let allData = []
      r.map((r,i)=>{
        let tyre_details_length = r.tyre_details.length;
        let count = 0;
      
        if (Array.isArray(r.rejected)) {
          r.rejected.forEach((rejectedItem) => {
            if (rejectedItem === true) {
            
              count++;
            }
          });
        }
        r.tyre_details_length = Math.max(tyre_details_length - count, 0);

        allData.push({
          id:i+1,
          tyre_details_id:r.tyre_details_id,
          vehicle_no:r.vehicle_no,
          vehicle_id:r.vehicle_id,
          vehicle_status:r.vehicle_status,
          tyre_details:r.tyre_details,
          rejected:r.rejected,
          wheel_details:r.tyre_details_length,
          action_items: r.action_items,
          action: "action",
        })
      })

      onSuccess(allData);
    }
    else{
      onFailure("Something Wrong! Please Try again later " + res.data)
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error)
    
  }
}

export const getVehicleById = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vehicle/list", {
      tyre_details_status:false 
    });
    if (res.status === 200) {
      const r = res.data;
      let allVehicle = [];
      r.map((r, i) => {
        allVehicle.push({
          value: r.vehicle_id,
          label: r.vehicle_no,
          no_of_wheels: r.no_of_wheels,
          km_start: r.km_start,

        });
      });

      return onSuccess(allVehicle);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getVehicle = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vehicle/list", {});
    
    if (res.status === 200) {
      const r = res.data;
      let allVehicle = [];
      r.map((r, i) => {
        allVehicle.push({
          value: r.vehicle_id,
          label: r.vehicle_no,
          no_of_wheels: r.no_of_wheels,
          km_start: r.km_start,

        });
      });

      return onSuccess(allVehicle);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    // onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updatetyreDetails = async (addTyreDetails, details, inserted_by_id, onSuccess, onFailure ) => {
  try{
    const res = await webApi.post("/master/tyre_details/update",{
      tyre_details_id:addTyreDetails.tyre_details_id,
      vehicle_no:addTyreDetails.ddl_vehicle_no_label,
      vehicle_id:addTyreDetails.ddl_vehicle_no_id,
      vehicle_status:addTyreDetails.chk_vehicle_status,
      tyre_details:details,
      edited_by_id:inserted_by_id,
    })
    if (res.status === 200) {
      const r = res.data;
      const Vehicleres = await webApi.post("/master/vehicle/update", {
        vehicle_id: addTyreDetails.ddl_vehicle_no_id,
        tyre_details_status: true,
      });
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};