import webApi from "./webApi/webApi";

export const postVehicleType = async (info, inserted_by_id, onSuccess, onFailure) => {
  try {

    const res = await webApi.post("/master/vehicle_type/insert", {
      vehicle_type: info.txt_vehicle_type,
      details: info.txt_details,
      active_status: info.switch_active_status ? "Y" : "N",
      // inserted_by_id:inserted_by_id,
    });

    if (res.status === 200) {
      const r = res.data;

      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    // onFailure("Something Wrong! Please Try again later " + error);
  }
};




export const getAllVehicleType = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/vehicle_type/list", {
      keyword_pharse: q?.txt_keyword_pharse,

    });
    if
      (res.status === 200) {
      const r = res.data;

      console.log(r, "sen1108/r")
      let all_vehiclesType = [];
      r.map((r, i) => {
        all_vehiclesType.push({
          id: i + 1,
          vehicle_type_id: r.vehicle_type_id,
          vehicle_type: r.vehicle_type,
          details: r.details,
          status: r.active_status,
          action_items: r.action_items,
          action: "action",
        });
      });
      return onSuccess(all_vehiclesType);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getVehicleType = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/vehicle_type/list", {
      keyword_pharse: q?.txt_keyword_pharse,

    });
    if
      (res.status === 200) {
      const r = res.data;

      // console.log(r, "sen1108/r")
      let all_vehiclesType = [];
      r.map((r, i) => {
        all_vehiclesType.push({
          id: i + 1,
          value: r.vehicle_type_id,
          label: r.vehicle_type,

        });
      });
      return onSuccess(all_vehiclesType);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//dispatch Vehicle by id
export const getVehicleById = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vehicle/list", {
      group_id: 26
    });
    if (res.status === 200) {
      const r = res.data;
      let allVehicle = [];
      r.map((r, i) => {
        allVehicle.push({

          value: r.vehicle_id,
          label: r.vehicle_no,
        });
      });

      return onSuccess(allVehicle);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};



export const deleteVehicle = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vehicle/delete", {
      vehicle_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};



export const updateVehicleType = async (info, onSuccess, onFailure) => {
  console.log(info, "from v service")
  try {
    const res = await webApi.post("/master/vehicle_type/update", {
      vehicle_type_id: info.vehicle_type_id,
      vehicle_type: info.txt_vehicle_type,
      details: info.txt_details,
      active_status: info.switch_active_status ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};



export const deleteVehicleType = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vehicle_Type/delete", {
      vehicle_type_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};