import { InferencePriority } from "typescript";
import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate, timeToUnix, IstTime, currentDate } from "../views/Pages/HelperComponent/utils";
import moment from "moment";

export const postAdvance = async (advance, journal_details, inserted_by_id, onSuccess, onFailure,) => {
  try {
    console.log(advance, "sen30")
    const res = await webApi.post("/master/advance/insert", {
      vehicle_id: advance.ddl_vehicle_no_id,
      vehicle_no: advance.ddl_vehicle_no_label,
      trip_no: advance.txt_trip_no,
      expense: advance.txt_Expense,
      actual_expense: Number(advance.txt_actual_expense),
      Excess_Pay_Oil: advance.txt_Excess_Pay_Oil,
      Actual_Pay: advance.txt_Actual_Pay,
      remarks: advance.txt_Remarks,
      advance_office_id: advance.ddl_office_id,
      advance_office_label: advance.ddl_office_label,
      inserted_by_id: inserted_by_id,
      transaction_no: advance.transaction_no,
      oil_order_no: advance.oil_order_no,
      oil_id: advance.oil_id,
      paymode_id: advance.ddl_paymode_id,
      paymode: advance.ddl_paymode_label,
      account_id: advance.ddl_account_id,
      account_name: advance.ddl_account_label,
      route_amount: advance.txt_Expense,
      route_name: advance.routes_name,
      route: advance.routes,
      cheque_no: advance.txt_cheque_no,
      driver_id: advance.ddl_driver_id,
      driver_name: advance.ddl_driver_label,
      advance_time: timeToUnix(advance.txt_advance_time),
      advance_date: timestamp(advance.txt_advance_date),
    });
    if (res.status === 200) {

      const r = res.data;

      const res2 = await webApi.post("/master/oil/update", {
        oil_id: advance.oil_id,
        advance_status: true,
      });

      const ledgerUpdate = await webApi.post("/master/ledger_account/updateLedgerClosing", {
        ledger_account_id: advance.ddl_account_id,
        amount: -Number(advance.txt_actual_expense)
      })

      const journalData = await webApi.post("/master/journal/insert", {
        journal_type: "J",
        transaction_id: r?.transaction_no,
        transaction_type: "Advance",
        transaction_type_id: r?.advance_order_no,
        narration: `Being Advance Given To Driver ${advance.ddl_driver_label} as on Date ${dateFormate(timestamp(currentDate()))}`,
        voucher_amount: Number(advance.txt_actual_expense),
        voucher_date: timestamp(advance.txt_advance_date),
        journal_details: journal_details,
        // active_status: journal.switch_active_btn ? "Y" : "N",
      });

      onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


export const updateAdvance = async (advance, updatedJournalDetails, inserted_by_id,journal, onSuccess, onFailure) => {
  try {
    console.log(journal, "sen30")
    const res = await webApi.post("/master/advance/update", {
      advance_id: advance.advance_id,
      vehicle_id: advance.ddl_vehicle_no_id,
      vehicle_no: advance.ddl_vehicle_no_label,
      trip_no: advance.txt_trip_no,
      route: advance.routes,
      route_name: advance.routes_name,
      expense: advance.txt_Expense,
      actual_expense: advance.txt_actual_expense,
      advance_office_id: advance.ddl_office_id,
      advance_office_label: advance.ddl_office_label,
      remarks: advance.txt_Remarks,
      driver_id: advance.ddl_driver_id,
      driver_name: advance.ddl_driver_label,
      paymode_id: advance.ddl_paymode_id,
      paymode: advance.ddl_paymode_label,
      advance_date: timestamp(advance.txt_advance_date),
    });

    if (res.status === 200) {
      const r = res.data;
console.log(journal,"sankhar=data")
      const journalData = await webApi.post("/master/journal/update", {
        journal_id: journal[0]?.journal_id,
        journal_type: "J",
        transaction_id: r?.transaction_no,
        transaction_type: "Advance",
        transaction_type_id: r?.advance_order_no,
        narration: `Being Advance Given To Driver ${advance.ddl_driver_label} as on Date ${dateFormate(timestamp(currentDate()))}`,
        voucher_amount: Number(advance.txt_actual_expense),
        voucher_date: timestamp(advance.txt_advance_date),
        journal_details: updatedJournalDetails,
      });

      if (journalData.status === 200) {
        onSuccess(r);
      } else {
        onFailure("Failed to update journal entry");
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


// export const updateAdvance = async (advance, journal_details, inserted_by_id, onSuccess, onFailure,) => {
//   try {
//     console.log(advance, "sen30")
//     const res = await webApi.post("/master/advance/update", {
//       advance_id:advance.advance_id,
//       vehicle_id: advance.ddl_vehicle_no_id,
//       vehicle_no:advance.ddl_vehicle_no_label,
//       trip_no:advance.txt_trip_no,
//       route:advance.routes,
//      route_name: advance.routes_name,
//       expense:advance.txt_Expense,
//       actual_expense:advance.txt_actual_expense,
//       advance_office_id:advance.ddl_office_id,
//      advance_office_label: advance.ddl_office_label,
//      remarks: advance.txt_Remarks,
//       driver_id:advance.ddl_driver_id,
//      driver_name :advance.ddl_driver_label,
//       paymode_id :advance.ddl_paymode_id,
//       paymode :advance.ddl_paymode_label,
//       advance_date:timestamp(advance.txt_advance_date),

//     });
//     if (res.status === 200) {

//       const r = res.data;

//       // const res2 = await webApi.post("/master/oil/update", {
//       //   oil_id: advance.oil_id,
//       //   advance_status: true,
//       // });

//       // const ledgerUpdate = await webApi.post("/master/ledger_account/updateLedgerClosing", {
//       //   ledger_account_id: advance.ddl_account_id,
//       //   amount: -Number(advance.txt_actual_expense)
//       // })

//       const journalData = await webApi.post("/master/journal/update", {
//         journal_id:r?.journal_id,
//         journal_type: "J",
//         transaction_id: r?.transaction_no,
//         transaction_type: "Advance",
//         transaction_type_id: r?.advance_order_no,
//         narration: `Being Advance Given To Driver ${advance.ddl_driver_label} as on Date ${dateFormate(timestamp(currentDate()))}`,
//         voucher_amount: Number(advance.txt_actual_expense),
//         voucher_date: timestamp(advance.txt_advance_date),
//         journal_details: journal_details,
//         // active_status: journal.switch_active_btn ? "Y" : "N",
//       });

//       onSuccess(r);

//     } else {
//       onFailure("Something Wrong! Please Try again later " + res.data);
//     }
//   } catch (error) {
//     onFailure("Something Wrong! Please Try again later " + error);
//   }
// };

export const getAllAdvance = async (addSearch, onSuccess, onFailure) => {
  try {

    const res = await webApi.post("/master/advance/list", {
      vehicle_no: addSearch.txt_vehicle_no,
      fromDate: moment(addSearch.txt_from_date,"YYYY-MM-DD").startOf('day').unix(),
      toDate: moment(addSearch.txt_to_date,"YYYY-MM-DD").endOf('day').unix()//timestamp(addSearch.txt_to_date),

    });
    if
      (res.status === 200) {
      const r = res.data;


      let all_advance = [];
      r.map((r, i) => {
        all_advance.push({
          id: i + 1,
          advance_id: r.advance_id,
          vehicle_id: r.vehicle_id,
          vehicle_no: r.vehicle_no,
          vehicle_trip_no: r.trip_no,
          location: r.location_name,
          Expence: r.expense,
          actual_expense: r.actual_expense,
          remarks: r.remarks,
          adv_office: r.advance_office_label,
          extra_expence: r.extra_expense_amount,
          transaction_no: r.transaction_no,
          oil_order_no: r.oil_order_no,
          advance_order_no:r.advance_order_no,
          action_items: r.action_items,
          advance_time:dateFormate(r.advance_date),
          action: "action",
        });


      });

      return onSuccess(all_advance);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllUnloadingLocation = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/location/list", {
      short_data: true,
      location_type: "Unloading"
    });
    if
      (res.status === 200) {
      const r = res.data;
      let all_location = [];
      r.map((r, i) => {
        all_location.push
          ({
            value: r.location_id,
            label: r.location,
            location_type_id: r.location_type_id,
            amount: r.amount,
          });
      });
      return onSuccess(all_location);
    }
    else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  }
  catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllAdvanceLocation = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/location/list", {
      short_data: true,
      location_type: "Advance"
    });
    if
      (res.status === 200) {
      const r = res.data;

      let all_location = [];
      r.map((r, i) => {
        all_location.push
          ({
            value: r.location_id,
            label: r.location,
            location_type_id: r.location_type_id,
          });
      });
      return onSuccess(all_location);
    }
    else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  }
  catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//Oiled Vehicles
export const getOiledVehicle = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/oil/oiled_vehicle_list", {
      advance_status: false
    })
    if (res.status === 200) {
      const r = res.data
      let vehicle_list = []
      r.map((r, i) => {
        console.log(r, "sen2309")
        vehicle_list.push({
          value: r.vehicle_id,
          label: r.vehicle_no,
          transaction_no: r.transaction_no,
          oil_order_no: r.oil_order_no,
          oil_id: r.oil_id,
          trip_no: r.trip_no,
          routes_name: r.routes_name,
          routes: r.routes,
          route_amount: r.route_amount,
          difference_Amount: r.difference_Amount,

        })
      })
      onSuccess(vehicle_list)
    } else {
      onFailure("Something Wrong! Please Try again later" + r);

    }

  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);

  }
}

//expenses
export const getAllExpenses = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/expenses/list", {
      expenses_type_id: "E",

    });
    if
      (res.status === 200) {
      const r = res.data;


      let all_expenses = [];
      r.map((r, i) => {
        all_expenses.push({
          value: r.expenses_id,
          label: r.expenses,
        });
      });
      return onSuccess(all_expenses);
      console.log(all_expenses, "sen1609")
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//view
export const ViewAdvanceDetails = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/advance/viewAdvanceDetails", {
      advance_id: id
    })

    console.log(id, "sen2110")
    if (res.status === 200) {
      const r = res.data
      return onSuccess(r[0])

    }
    else {
      onFailure("Something Wrong! Please Try again later");

    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);

  }
}

export const getBankAccount = async (onSuccess, onFailure) => {

  try {
    const res = await webApi.post("/master/ledger_account/viewBankLedgerAccount", {})

    if (res.status === 200) {
      const r = res.data
      let allData = []
      r.map((r, i) => {
        allData.push({
          label: r.ledger_account,
          value: r.ledger_account_id,
        })
      })

      return onSuccess(allData)
    }
    console.log(res.data, "sen2911")

  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
}

export const getAllEmployee = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/employee/list", {
      post: "3"
    });

    if (res.status === 200) {
      const r = res.data;
      let allEmployee = [];
      r.map((v, i) => {

        allEmployee.push({
          id: i + 1,
          value: v.employee_id,
          label: v.first_name,
          post: v.post,
          whatsapp_no: v.whatsapp_no

        });
      });

      if (allEmployee.length) {
        return onSuccess(allEmployee);
      }
      else {
        onFailure("Employee not found ");
      }
    }
    else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }




};

export const getLedgerAccount = async (type_id, onSuccess, onFailure) => {

  try {
    const res = await webApi.post("/master/ledger_account/search", {
      typeId: type_id,
    })

    if (res.status === 200) {
      const r = res.data
      console.log(r, "sen01012")
      onSuccess(r[0])
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
}


export const getAllAdvanceEdit = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/advance/viewAdvanceDetails", {  
      advance_id: id,
    });

    console.log(res,"sankres")
    if (res.status === 200) {
      const r = res.data;
    
     
      console.log(r[0],"sanksuccess")
      return onSuccess(r[0]);
     
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};






export const getJournal = async (q,onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/journal_id/list", {
      advance_order_no:q
    });
    if (res.status === 200) {
      const r = res.data;
    
      
      return onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};



