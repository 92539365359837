import webApi from "./webApi/webApi";


export const postTyreModel = async (info,inserted_by_id, onSuccess, onFailure) => {
    try {
        
      const res = await webApi.post("/master/tyreModel/insert", {
        tyre_model: info.txt_tyre_model,
        details: info.txt_details,
        active_status: info.switch_active_status ? "Y" : "N",
        inserted_by_id:inserted_by_id,
      });
  
      if (res.status === 200) {
        const r = res.data;
      
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      // onFailure("Something Wrong! Please Try again later " + error);
    }
  };


  export const updateTyreModel = async (info, onSuccess, onFailure) => {
    console.log(info,"from v service")
    try {
      const res = await webApi.post("/master/tyreModel/update", {
        tyre_model_id: info.tyre_model_id,
        tyre_model: info.txt_tyre_model,
        details: info.txt_details,
        active_status: info.switch_active_status ? "Y" : "N",
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };


  export const deleteTyreModel = async (id, onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/tyreModel/delete", {
        tyre_model_id: id,
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };


  export const getAllTyreModel = async (onSuccess, onFailure,q) => {
    try {
  
      const res = await webApi.post("/master/tyreModel/list", {
        keyword_pharse: q?.txt_keyword_pharse,
  
      });
      if 
      (res.status === 200)
       {
        const r = res.data;
        let all_tyreBrand = [];
        r.map((r, i) => {
            console.log(r,"sank1213")
          all_tyreBrand.push({
            id: i + 1,
            tyre_model_id:r.tyre_model_id,
            tyre_model:r.tyre_model,
            details: r.details,
            status: r.active_status,
            action_items: r.action_items,
            action: "action",
          });
        });
        return onSuccess(all_tyreBrand);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };