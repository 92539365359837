import { dateFormate, timeToUnix, IstTime } from "views/Pages/HelperComponent/utils";
import { timestamp } from "views/Pages/HelperComponent/utils";
import webApi from "./webApi/webApi";

export const postSalesCumDispatch = async (info, journal_details, inserted_by_id, onSuccess, onFailure) => {
  try {
    console.log(info, "info")
    const res = await webApi.post("/master/salesCumDispatch/insert", {
      vehicle: info.ddl_loaded_vehicle_label,
      vehicle_id: info.ddl_loaded_vehicle_id,
      customer: info.ddl_customer_label,
      customer_id: info.ddl_customer_id,
      order_date: timestamp(info.order_date),
      sales_date: timestamp(info.sale_date),
      discount: Number(info.discount),
      discount_per: Number(info.discount_per),
      discountedRate: Number(info.discountedRate),
      quantity: Number(info.quantity),
      rate: Number(info.rate),
      gst: Number(info.gst),
      gst_value: Number(info.gst_value),
      gst_status: info.check_gst,
      actual_amount: Number(info.actual_amount),
      total_amount: Number(info.total),
      active_status: info.switch_active_status ? "Y" : "N",
      inserted_by_id: inserted_by_id,
      transaction_no: info.transaction_no,
      amount: Number(info.txt_total),
      loaded_material_type: info.loaded_material_type,
      loaded_material_type_id: info.loaded_material_type_id,
      loaded_material: info.loaded_material,
      loaded_material_id: info.loaded_material_id,
      dispatch_time: timeToUnix(info.dispatch_time),
      unloading_time: timeToUnix(info.unloading_time),
      credit_limit: Number(info.credit_limit),
      quantity_type_id: info.ddl_qty_type_id,
      quantity_type: info.ddl_qty_type_label,
      width: Number(info.width),
      length: Number(info.length),
      height: Number(info.height),
      totalCft: Number(info.totalCft),
      grossWeight: Number(info.grossWeight),
      lorryWeight: Number(info.lorryWeight),
      waterLess: Number(info.waterLess),
      netWeight: Number(info.netWeight),
      mobile_no: info.whatsapp_no,
      km: info.txt_km,
      remarks: info.txt_remark,
      distance_covered: info.distance_covered,
      customer_km: info.customer_km,
      salesman_id: info.ddl_salesman_id,
      salesman_name:info.ddl_salesman_label,
    });

    const res1 = await webApi.post("/master/daily/sales", {
      vehicle_no: info.ddl_loaded_vehicle_label,
      vehicle_id: info.ddl_loaded_vehicle_id,
      sales_date: timestamp(info.sale_date),
      transaction_no: info.transaction_no,
     
   
    });

    const ledgerUpdateParty = await webApi.post("/master/ledger_account/updateLedgerClosing", {
      customerId: Number(info.ddl_customer_id),
      amount: Number(info.total),
    })

    if (res.status === 200) {
      const r = res.data;
      console.log(r, "check")
      const res2 = await webApi.post("/master/loading/update", {
        loading_id: info.loading_id,
        dispatch_status: true,
      });

      const journalData = await webApi.post("/master/journal/insert", {
        journal_type: "J",
        transaction_id: r?.transaction_no,
        transaction_type: "Sales/Dispatch",
        transaction_type_id: r?.salesCumDispatch_no,
        narration: `Being Goods Sold To M/S ${journal_details[0].ledger
          } as on Date ${dateFormate(timestamp(info.sale_date))}`,
        voucher_amount: Number(info.total),
        voucher_date: timestamp(info.sale_date),
        journal_details: journal_details,
      });

      const Vehicleres = await webApi.post("/master/vehicle/update", {
        vehicle_id: info.ddl_loaded_vehicle_id,
        km_end: Number(info.txt_km),
      });
      onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    // onFailure("Something Wrong! Please Try again later " + error);
  }
};


// export const getAllSalesCumDispatch = async (onSuccess, onFailure, q) => {
//   console.log(q, "query sales")
//   try {

//     const res = await webApi.post("/master/salesCumDispatch/list", {
//       keyword_pharse: q?.txt_keyword_pharse,
//       from_date: timestamp(q?.txt_from_date),
//       to_date: timestamp(q?.txt_to_date),
//     });
//     if
//       (res.status === 200) {
//       const r = res.data;
//       console.log(r, "sen06")

//       let all_SalesCumDispatch = [];
//       r.map((r, i) => {
//         all_SalesCumDispatch.push({
//           id: i + 1,
//           sales_cum_customer_name: r.customer_name,
//           sales_cum_customer_id: r.customer_id,
//           sales_cum_loaded_vehicle: r.vehicle,
//           sales_cum_loaded_vehicle_id: r.vehicle_id,
//           sales_cum_quantity_type: r.quantity_type,
//           sales_cum_quantity_type_id: r.quantity_type_id,
//           sales_cum_rate: r.rate,
//           sales_cum_quantity: r.quantity,
//           order_date: dateFormate(r.order_date),
//           sales_date: dateFormate(r.sales_date),
//           challan_date: dateFormate(r.challan_date),
//           remarks:r.remarks,
//           status: r.active_status,        
//           salse_dispatch_id: r.salse_dispatch_id,
//           transaction_no: r.transaction_no,
//           salesman_id:r.salesman_id,
//           salesman_name:r.salesman_name,
//           action: "action",
//           action_items: r.action_items,
//           sales_againest_vehicle_status:r.sales_againest_vehicle_status
          
      
//         });
//       });
//       return onSuccess(all_SalesCumDispatch);
//     } else {
//       onFailure("Something Wrong! Please Try again later" + res.data);
//     }
//   } catch (error) {
//     onFailure("Something Wrong! Please Try again later" + error);
//   }
// };

export const getAllSalesCumDispatch = async (onSuccess, onFailure, q) => {
  console.log(q, "query sales");
  try {
    const res = await webApi.post("/master/salesCumDispatch/list", {
      keyword_pharse: q?.txt_keyword_pharse,
      from_date: timestamp(q?.txt_from_date),
      to_date: timestamp(q?.txt_to_date),
    });

    if (res.status === 200) {
      const r = res.data;
      console.log(r, "sen06");

      let all_SalesCumDispatch = [];
      r.forEach((item, i) => {
        // Determine can_edit based on sales_againest_vehicle_status
        const can_edit = !item.sales_againest_vehicle_status; // true means can_edit is false

        all_SalesCumDispatch.push({
          id: i + 1,
          sales_cum_customer_name: item.customer_name,
          sales_cum_customer_id: item.customer_id,
          sales_cum_loaded_vehicle: item.vehicle,
          sales_cum_loaded_vehicle_id: item.vehicle_id,
          sales_cum_quantity_type: item.quantity_type,
          sales_cum_quantity_type_id: item.quantity_type_id,
          sales_cum_rate: item.rate,
          sales_cum_quantity: item.quantity,
          order_date: dateFormate(item.order_date),
          sales_date: dateFormate(item.sales_date),
          challan_date: dateFormate(item.challan_date),
          remarks: item.remarks,
          status: item.active_status,
          salse_dispatch_id: item.salse_dispatch_id,
          transaction_no: item.transaction_no,
          salesman_id: item.salesman_id,
          salesman_name: item.salesman_name,
          action: "action",
          action_items: {
            ...item.action_items,
            can_edit: can_edit,
          },
          sales_againest_vehicle_status: item.sales_againest_vehicle_status,
        });
      });

      
      return onSuccess(all_SalesCumDispatch);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};


export const viewSalesDispatch = async (id, onSuccess, onFailure) => {

  try {

    const res = await webApi.post("/master/salesCumDispatch/view", {
      salesDispatchId: id,
    });
    if (res.status === 200) {
      const r = res.data;
      return onSuccess(r[0]);

    }

    else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};


//for edit

export const getSalesDispatchEdit = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/salesCumDispatch/edit", {
      salse_dispatch_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
     
      return onSuccess(r[0]);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//for customer dropdown
export const getAllCustomers = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/customer/list", {
      // source_id: q?.ddl_source.value,

    });
    if (res.status === 200) {
      const r = res.data;
      let allCusts = [];
      console.log(r, "sen09122022")
      r.map((c, i) => {
        allCusts.push({
          id: i + 1,
          // customer_id: c.customer_id,
          // company: c.company_name,
          value: c.customer_id,
          label: c.company_name,

          whatsapp_no: c.whatsapp
        });
      });
      if (allCusts.length) {
        return onSuccess(allCusts);
      } else {
        onFailure("Customer not Found ");
      }
    } else {
      onFailure("Something Wrong! Please Try again laterwww " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again latersss " + error);
  }
};

//Loaded Vehicles
export const getLoadedVehicle = async (onSuccess, onFailure) => {
  try {

    const res = await webApi.post("/master/salesCumDispatch/loaded_vehicle_list", {
      dispatch_status: false
    })
    if (res.status === 200) {
      const r = res.data
      let vehicle_list = []
      r.map((r, i) => {
        vehicle_list.push({
          value: r.vehicle_id,
          label: r.vehicle_no,
          loading_quantity: r.loading_quantity,
          gst: r.gst,
          loading_order_No: r.loading_order_No,
          loading_id: r.loading_id,
          transaction_no: r.transaction_no,
          material: r.material,
          km_start: r.km_start,
          material_id: r.material_id,
          material_type_id: r.material_type_id,
          material_type: r.material_type,
          material_type_rate: r.material_type_rate,
          vehicle_charges: r.vehicle_charges,
          challan_date:r.challan_date,
        })
      })
      onSuccess(vehicle_list)
    } else {
      onFailure("Something Wrong! Please Try again later" + r);

    }

  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);

  }
}

export const getLedgerAccount = async (type_id, onSuccess, onFailure) => {

  try {
    const res = await webApi.post("/master/ledger_account/search", {
      typeId: type_id,
    })

    if (res.status === 200) {
      const r = res.data

      console.log(r, "sen0112022")

      onSuccess(r[0])
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
}

export const getAllEmployee = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/employee/list", {
      post: "5"
    });

    if (res.status === 200) {
      const r = res.data;
      let allEmployee = [];
      r.map((v, i) => {

        allEmployee.push({
          id: i + 1,
          value: v.employee_id,
          label: v.first_name,
          post: v.post,
          whatsapp_no: v.whatsapp_no

        });
      });

      if (allEmployee.length) {
        return onSuccess(allEmployee);
      }
      else {
        onFailure("Employee not found ");
      }
    }
    else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }




};

export const updateSalesCumDispatch = async (info, edited_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/salesCumDispatch/update", {
      salse_dispatch_id:info.salse_dispatch_id,
      vehicle: info.ddl_loaded_vehicle_label,
      vehicle_id: info.ddl_loaded_vehicle_id,
      customer: info.ddl_customer_label,
      customer_id: info.ddl_customer_id,
      order_date: timestamp(info.order_date),
      sales_date: timestamp(info.sale_date),
      discount: Number(info.discount),
      discount_per: Number(info.discount_per),
      discountedRate: Number(info.discountedRate),
      quantity: Number(info.quantity),
      rate: Number(info.rate),
      gst: Number(info.gst),
      gst_value: Number(info.gst_value),
      gst_status: info.check_gst,
      actual_amount: Number(info.actual_amount),
      total_amount: Number(info.total),
      active_status: info.switch_active_status ? "Y" : "N",
     
      transaction_no: info.transaction_no,
      amount: Number(info.txt_total),
      loaded_material_type: info.loaded_material_type,
      loaded_material_type_id: info.loaded_material_type_id,
      loaded_material: info.loaded_material,
      loaded_material_id: info.loaded_material_id,
      dispatch_time: timeToUnix(info.dispatch_time),
      unloading_time: timeToUnix(info.unloading_time),
      credit_limit: Number(info.credit_limit),
      quantity_type_id: info.ddl_qty_type_id,
      quantity_type: info.ddl_qty_type_label,
      width: Number(info.width),
      length: Number(info.length),
      height: Number(info.height),
      totalCft: Number(info.totalCft),
      grossWeight: Number(info.grossWeight),
      lorryWeight: Number(info.lorryWeight),
      waterLess: Number(info.waterLess),
      netWeight: Number(info.netWeight),
      mobile_no: info.whatsapp_no,
      km: info.txt_km,
      remarks: info.txt_remark,
      distance_covered: info.distance_covered,
      customer_km: info.customer_km,
      edited_by_id:edited_by_id
    });

    

    if (res.status === 200) {
      const r = res.data;
      console.log(r, "check")
    
   
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


//print

export const getSalesCumDispatchPrint = async (salse_dispatch_id,onSuccess, onFailure) => {
 
  try {

    const res = await webApi.post("/master/salesCumDispatch/print", {
      salse_dispatch_id:salse_dispatch_id,
   
    });
    if
      (res.status === 200) {
      const r = res.data;
      console.log(r, "sen06")

      let all_SalesCumDispatch = [];
      r.map((r, i) => {
        all_SalesCumDispatch.push({
          id: i + 1,
          salse_dispatch_id:r.salse_dispatch_id,
          sales_cum_customer_name: r.customer,
          sales_cum_customer_id: r.customer_id,
          sales_cum_loaded_vehicle: r.vehicle,
          sales_cum_loaded_vehicle_id: r.vehicle_id,
          sales_cum_quantity_type: r.quantity_type,
          sales_cum_quantity_type_id: r.quantity_type_id,
          loaded_material:r.loaded_material,
          salesCumDispatch_no:r.salesCumDispatch_no,
          sales_cum_rate: r.rate,
          discount_per:r.discount_per,
          gst_value:r.gst_value,
          discount:r.discount,
          actual_amount:r.actual_amount,
          sales_cum_quantity: r.quantity,
          order_date: dateFormate(r.order_date),
          sales_date: dateFormate(r.sales_date),
          challan_date: dateFormate(r.challan_date),
          remarks:r.remarks,
          status: r.active_status,        
          salse_dispatch_id: r.salse_dispatch_id,
          transaction_no: r.transaction_no,
          salesman_id:r.salesman_id,
          salesman_name:r.salesman_name,
          action: "action",
          action_items: r.action_items,
          
      
        });
      });
      return onSuccess(all_SalesCumDispatch);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};