import Dashboard from "views/Dashboard/Dashboard";

import LoginPage from "views/Pages/LoginPage.js";
import BankPage from "views/Pages/SettingsPages/BankPage";

// import SelectLocationPage from "views/Pages/SelectLocationPage";

//Master Pages
import VehiclePage from "views/Pages/SettingsPages/VehiclePage";
import vehicleBrandPage from "views/Pages/SettingsPages/vehicleBrandPage";
import tyreBrandPage from "views/Pages/SettingsPages/tyreBrandPage";
import tyreDetailsPage from "views/Pages/SettingsPages/tyreDetailsPage";
import TyreDetailsform from "views/Pages/SettingsPages/tyreDetailsForm";
import materialPage from "views/Pages/SettingsPages/materialPage";
import materialTypePage from "views/Pages/SettingsPages/materialTypePage";
import expensesPage from "views/Pages/SettingsPages/expensesPage"
import vehicleTypePage from "views/Pages/SettingsPages/vehicleTypePage";
import CustomerListPage from "views/Pages/CustomerPage/CustomerListPage";
import AddCustomerPage from "views/Pages/CustomerPage/CustomerPage";
import CustomerViewPage from "views/Pages/CustomerPage/CustomerViewPage";
import RolePage from "views/Pages/SettingsPages/RolePage";
import EmployeeTypeForm from "views/Pages/EmployeePage/EmployeeTypeForm";
import EmployeeViewPage from "views/Pages/EmployeePage/EmployeeViewPage";
import EmployeeList from "views/Pages/EmployeePage/EmployeeList";
import locationPage from "views/Pages/SettingsPages/locationPage";
import routePage from "views/Pages/SettingsPages/routePage";
import petrolPumpPage from "views/Pages/SettingsPages/petrolPumpPage";
import PrimaryGroupPage from "views/Pages/MasterAccounts/PrimaryGroupPage";
import LedgerGroupPage from "views/Pages/MasterAccounts/LedgerGroupPage";
import LedgerAccountPage from "views/Pages/MasterAccounts/LedgerAccountPage";

//Tranportaion Pages
import Oil from './views/Pages/Tranportation/Oil/Oil'
import OilViewPage from "views/Pages/Tranportation/Oil/OilViewPage";
import Loadings from './views/Pages/Tranportation/Loading/Loadings'
import salesCumDispatchPage from "views/Pages/Tranportation/Sale_cum_dispatch/salesCumDispatchPage"
// import Destination from "views/Pages/Tranportation/Destination/Destination";
import MaintenanceExpenses from "views/Pages/Tranportation/MaintenanceExpances/MaintenanceExpenses";
import Tracking from "views/Pages/Tranportation/Tracking/Tracking";
import TrackingForm from "views/Pages/Tranportation/Tracking/TrackingForm";
// import TrackingViewPage from "views/Pages/Tranportation/Tracking/TrackingViewPage";
//Accounts
import ReceiptListPage from "views/Pages/Accounts/ReceiptListPage";
import PaymentPage from "views/Pages/Accounts/PaymentPage";
import JournalPage from "views/Pages/Accounts/JournalPage";
import AddJournalPage from "views/Pages/Accounts/AddJournalPage";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Associate from "views/Pages/Associate";
import ShowroomWarehousePage from "views/Pages/SettingsPages/ShowroomWarehousePage";
// Icons
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Advance from "views/Pages/Tranportation/Advance/Advance";
import AdvanceForm from "views/Pages/Tranportation/Advance/AdvanceForm";
import AdvanceView from "views/Pages/Tranportation/Advance/AdvanceView";

import OilForm from "views/Pages/Tranportation/Oil/OilForm";
import LoadingForm from "views/Pages/Tranportation/Loading/loadingForm";
import salesCumDispatchForm from "views/Pages/Tranportation/Sale_cum_dispatch/SalesCumDispatchForm";
// import TrackingForm from "views/Pages/Tranportation/Destination/TrackingForm";
import VehicleForm from "views/Pages/SettingsPages/VehicleForm";
import LoadingViewPage from "views/Pages/Tranportation/Loading/LoadingViewPage";
import salesDispatchView from "views/Pages/Tranportation/Sale_cum_dispatch/salesDispatchView";
import RouteForm from "views/Pages/SettingsPages/routeFormPage";
import PoliceStationPage from "views/Pages/SettingsPages/PoliceStationPage";
import ParkingPage from "views/Pages/SettingsPages/parkingPage";
import MotorLegislation from "views/Pages/SettingsPages/MotorLegislation";
import labPage from "views/Pages/SettingsPages/LabPage";
import KataPage from "views/Pages/SettingsPages/KataPage";
import ExtraCharges from "views/Pages/Tranportation/Extra_charges/ExtraCharges";
import ExtraChargesForm from "views/Pages/Tranportation/Extra_charges/ExtraChargesForm";
import LedgerMisPage from "views/Pages/MisReportPage/LedgerMisPage";
import TyreFittingPage from "views/Pages/MaintancePage/TyreFitting"
import TyreFittingForm from "views/Pages/MaintancePage/TyreFittingForm";
import RouteFormEdit from "views/Pages/SettingsPages/routeFormEdit";
import VendorListPage from "views/Pages/VendorPage/VendorList";
import VendorTypeForm from "views/Pages/VendorPage/VedorTypeForm";
import RejectedTyrePage from "views/Pages/MaintancePage/RejectedTyrePage";
import RejectedTyreForm from "views/Pages/MaintancePage/RejectedTyreForm";
import RejectedTyreSalesPage from "views/Pages/MaintancePage/RejectedTyreSalesPage";
import RejectedTyreSalesForm from "views/Pages/MaintancePage/RejectedTyreSalesForm";
import CashBookPage from "views/Pages/Accounts/CashBookPage";
import TyreModelPage from "views/Pages/SettingsPages/TyreModelPage";
import VendorViewPage from "views/Pages/VendorPage/VendorViewPage";
import MaintenanceExpensesView from "views/Pages/Tranportation/MaintenanceExpances/MaintenanceExpensesView";
import RejectedTyreView from "views/Pages/MaintancePage/RejectedTyreViewPage";
import TyreFittingView from "views/Pages/MaintancePage/TyreFittingViewPage";
import RejectedTyreSalesView from "views/Pages/MaintancePage/RejectedTyreSalesViewPage";
import ExtraChargesView from "views/Pages/Tranportation/Extra_charges/ExtraChargesView";
import Outstanding from "views/Pages/MisReportPage/Outstanding";
import MaintancePage from "views/Pages/MaintancePage/MaintancePage";
import MaintanceForm from "views/Pages/MaintancePage/MaintanceFormPage";
import ItemPage from "views/Pages/SettingsPages/ItemPage";
import ItemFormPage from "views/Pages/SettingsPages/ItemFormPage";
import BrandPage from "views/Pages/SettingsPages/BrandPage";
import Category from "views/Pages/SettingsPages/Category";
import ExpiryDocPage from "views/Pages/MisReportPage/VehicleExpiryDocPage";
import TotalDistanceCovered from "views/Pages/MisReportPage/TotalDistanceCovered";
import ReceiptSalesVehiclePage from "views/Pages/Accounts/ReceiptSalesVehicle";
import ReceiptSalesFormPage from "views/Pages/Accounts/RceciptSalesFormPage";
import ServicePage from "views/Pages/SettingsPages/ServicePage";
import TyreTypePage from "views/Pages/SettingsPages/tyreTypePage";
import DailyReportSummary from "views/Pages/MisReportPage/DailyReportSummary";
import TyreDetailsView from "views/Pages/SettingsPages/tyreDetailsView";
import DistanceCoveredManualPage from "views/Pages/Accounts/distansCoveredManual";
import PurchaseReturnTyrePage from "views/Pages/MaintancePage/PurchaseReturnTyrePage";
import PurchaseReturnTyreForm from "views/Pages/MaintancePage/PurchaseReturnTyreForm";
import PurchaseReturnTyreView from "views/Pages/MaintancePage/PurchaseReturnTyreView";
import PurchaseReturnTyreEdit from "views/Pages/MaintancePage/PurchaseReturnTyreEdit";
import DirectPurchasePage from "views/Pages/Tranportation/ProcurementPages/DirectPurchasePages";
import DirectPurchaseForm from "views/Pages/Tranportation/ProcurementPages/DirectPurchaseForm";
import BrakeDownPage from "views/Pages/MaintancePage/BrakeDownPage";
import BreakDownForm from "views/Pages/MaintancePage/BrakeDownFormPage";
import BreakDownListView from "views/Pages/MaintancePage/BreakDownListView";
import DailyTruckReport from "views/Pages/MisReportPage/DailyTruckReport";
import UnitMesurementPage from "views/Pages/SettingsPages/UomPage";
import ServiceForm from "views/Pages/MaintancePage/ServiceFormPage";
import ServicesPage from "views/Pages/MaintancePage/ServicePage";
import MaintanceReport from "views/Pages/MisReportPage/MaintanceReport";
import InTransitReport from "views/Pages/MisReportPage/inTransitReport";
import MaintanceView from "views/Pages/MaintancePage/MaintanceViewPage";
import OutstandingDetails from "views/Pages/MisReportPage/OutstandingDetails";






export const DotIcon = () => {
  return <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />;
};

var dashRoutes = [
  {
    taskOn: "1.Dashboard",
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Transportation",
    taskOn: "2.Transportation",
    icon: Apps,
    state: "transportaionCollapse",
    views: [
      {
        path: "/tranportation/oil",
        component: Oil,
        layout: "/admin",
        name: "Oil",
        taskOn: "2.1.Oil",
        mini: <DotIcon />,
      },
      {
        path: "/tranportation/oilForm",
        taskOn: "2.1.Oil",
        component: OilForm,
        layout: "/admin",
      },
      {
        path: "/tranportation/oilView",
        taskOn: "2.1.Oil",
        component: OilViewPage,
        layout: "/admin",
      },
      {

        path: "/tranportation/Advance",
        name: "Advance",
        component: Advance,
        layout: "/admin",
        taskOn: "2.2.Advance",
        mini: <DotIcon />,
      
      },
      {
        path: "/tranportation/AdvanceForm",      
        component: AdvanceForm,
        taskOn: "2.2.Advance",
        layout: "/admin",
      },
      {
        path: "/tranportation/AdvanceView",      
        component: AdvanceView,
        taskOn: "2.2.Advance",
        layout: "/admin",
      },
      {


        path: "/tranportation/loading",
        name: "Loading",
        component: Loadings,
        layout: "/admin",
        mini: <DotIcon />,
        taskOn: "2.3.loading",
      },
      {
        path: "/tranportation/loadingForm",      
        component: LoadingForm,
        taskOn: "2.3.loading",
        layout: "/admin",
      },
      {
        path: "/tranportation/loadingView",      
        component: LoadingViewPage,
        taskOn: "2.3.loading",
        layout: "/admin",
      },
      {


        path: "/tranportation/sales-cum-dispatch",
        name: "Sales/Dispatch" ,
        component: salesCumDispatchPage,
        layout: "/admin",
        mini: <DotIcon />,
        taskOn: "2.4.Salescumdispatch",
      },
      {
        
        path:"/tranportation/sales-cum-dispatchFrom",
        component:salesCumDispatchForm,
        taskOn: "2.4.Salescumdispatch",
    
        layout:"/admin",
      },
      {
        
        path:"/tranportation/salesDispatchView",
        component:salesDispatchView,
        taskOn: "2.4.Sales-cum-dispatch",
        layout:"/admin",
      },
      {


        path: "/tranportation/tracking",
        name: "Tracking",
        component: Tracking,
        layout: "/admin",
        mini: <DotIcon />,
        taskOn: "2.5.Tracking",
      },
      {
        path: "/tranportation/TrackingForm",
        component:TrackingForm,
        taskOn: "2.5.Tracking",
        layout:"/admin",
      },
      // {
      //   path: "/tranportation/trackingView",
      //   component: TrackingViewPage,
      //   layout: "/admin"
      // },

      {
        path: "/tranportation/ExtraCharges",
        name: "Extra Charges",
        component: ExtraCharges,
        layout: "/admin",
        taskOn:"2.6.ExtraCharges",
        mini: <DotIcon />,
        
      },
      {
        path: "/tranportation/ExtraChargesForm",
        component:ExtraChargesForm,
        taskOn:"2.6.ExtraCharges",
        layout:"/admin",
      },
      {
        path: "/tranportation/ExtraChargesView",
        component:ExtraChargesView,
        taskOn:"2.6.ExtraCharges",
        layout:"/admin",
      },
      {
    
        
        path: "/tranportation/maintenance-expenses",
        name: "Maintenance Expenses",
        component: MaintenanceExpenses,
        layout: "/admin",
        mini: <DotIcon />,
        taskOn: "2.7.Expenses",
      },
      {
        path: "/tranportation/maintenanceView",
        component:MaintenanceExpensesView,
        taskOn: "2.7.Expenses",
        layout:"/admin",
      },
    ]
  },
  {
    collapse: true,
    name: "Maintenance",  
    taskOn: "3.Maintenance",  
    icon: Apps,
    state: "maintanceCollapse",
    views: [
      {
        path: "/maintence/rejectedTyre",
        component: RejectedTyrePage,
        layout: "/admin",
        name: "Rejected Tyre",
        taskOn:"3.1.Rejected Tyre",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/maintence/rejectedtyreView",
        component: RejectedTyreView,
        taskOn:"3.1.Rejected Tyre",
        layout:"/admin",
      },
      {
        path: "/maintence/rejectedtyreForm",
        component: RejectedTyreForm,
        taskOn:"3.1.Rejected Tyre",
        layout:"/admin",
      },
      {
        path: "/maintence/rejectedTyreSales",
        component: RejectedTyreSalesPage,
        layout: "/admin",
        name: "Rejected Tyre Sales & Repair",
        taskOn: "3.2.Rejected Tyre Sales & Repair",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/maintence/rejectedtyreSalesForm",
        component: RejectedTyreSalesForm,
        taskOn: "3.2.Rejected Tyre Sales & Repair",
        layout:"/admin",
      },
      {
        path: "/maintence/rejectedtyreSalesView",
        component: RejectedTyreSalesView,
        taskOn: "3.2.Rejected Tyre Sales & Repair",
        layout:"/admin",
      },

      {
        path: "/maintence/purchaseReturnTyre",
        component: PurchaseReturnTyrePage,
        layout: "/admin",
        name: "Purchase & Return Tyre",
        taskOn:"3.3.Purchase & Return Tyre",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/maintence/purchaseReturnTyreForm",
        component: PurchaseReturnTyreForm,
        taskOn:"3.3.Purchase & Return Tyre",
        layout:"/admin",
      },

      {
        path: "/maintence/purchaseReturnTyreView",
        component: PurchaseReturnTyreView,
        taskOn:"3.3.Purchase & Return Tyre",
        layout:"/admin",
      },

      {
        path: "/maintence/purchaseReturnTyreEdit",
        component: PurchaseReturnTyreEdit,
        taskOn:"3.3.Purchase & Return Tyre",
        layout:"/admin",
      },
      

      {
        path: "/maintence/tyreFitting",
        component: TyreFittingPage,
        layout: "/admin",
        name: "TyreFitting",
        taskOn: "3.4.TyreFitting",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/maintence/tyreFittingView",
        component: TyreFittingView,
        taskOn: "3.4.TyreFitting",
        layout:"/admin",
      },
      {
        path: "/maintence/tyreFittingForm",
        component: TyreFittingForm,
        taskOn: "3.4.TyreFitting",
        layout:"/admin",
      },
      

      {
        path: "/maintence/maintence_Page",
        component: MaintancePage,
        layout: "/admin",
        name: "Maintance",
        taskOn:"3.5.Maintance",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/maintence/maintanceForm",
        component: MaintanceForm,
        taskOn:"3.5.Maintance",
        layout:"/admin",
      },
      {
        path: "/maintence/maintanceView",
        component: MaintanceView,
        taskOn:"3.5.Maintance",
        layout:"/admin",
      },

      {
        path: "/maintence/service",
        component: ServicesPage,
        layout: "/admin",
        name: "Service Page",
        taskOn:"3.6.Service Page",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/maintence/serviceForm",
        component: ServiceForm,
        taskOn:"3.6.Service Page",
        layout:"/admin",
      },

      {
        path: "/maintence/breakDown",
        component: BrakeDownPage,
        layout: "/admin",
        name: "Break Down",
        taskOn:"3.7.Break Down",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/maintence/breakDownForm",
        component: BreakDownForm,
        taskOn:"3.7.Break Down",
        layout:"/admin",
      },
      {
        path: "/maintence/breakDownListView",
        component: BreakDownListView,
        taskOn:"3.7.Break Down",
        layout:"/admin",
      },


    
     
    ]
  },
  {
    collapse: true,
    name: "Procurement",
    taskOn: "4.Procurement",
    icon: Apps,
    state: "procrumentCollapse",
    views: [
      {
        path: "/procurement/direct-purchase",
        component: DirectPurchasePage,
        layout: "/admin",
        name: "Direct Purchase",
        taskOn: "4.1.Direct Purchase",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
     
      {
        path: "/procurement/add-direct-purchase",
        taskOn:"4.1.Direct Purchase",
        component: DirectPurchaseForm,
        layout: "/admin",
      },
     
     
      // {
      //   path: "/procurement/Edit-direct-purchase",
      //   taskOn:"3.1.Direct Purchase",
      //   component: EditDirectPurchaseForm,
      //   layout: "/admin",
      // },
      // {
      //   path: "/procurement/direct-purchase-view",
      //   taskOn:"3.1.Direct Purchase",
      //   component: DirectPurchaseView,
      //   layout: "/admin",
      // },
    
     
     
     
    ],
  },
  {
    collapse: true,
    name: "Accounts",
    taskOn: "5.Account",
    icon: Apps,
    state: "accountsCollapse",
    views: [
      {
        path: "/account/receipt",
        component: ReceiptListPage,
        layout: "/admin",
        name: "Receipt",
        taskOn: "5.1.Receipt",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },

      {
        path: "/account/payment",
        component: PaymentPage,
        layout: "/admin",
        name: "Payment",
        taskOn: "5.2.Payment",

        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/account/journal",
        component: JournalPage,
        layout: "/admin",
        name: "Journal",
        taskOn: "5.3.Journal",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/account/add-journal",
        taskOn: "5.3.Journal",
        component: AddJournalPage,
        layout: "/admin",
      },
      {
        path: "/account/ledger",
        component: LedgerMisPage,
        layout: "/admin",
        name: "Ledger",
        taskOn: "5.4.Ledger",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/account/cashBook",
        component: CashBookPage,
        layout: "/admin",
        name: "Cash Book",
        taskOn: "5.5.Cash Book",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/account/receiptSalesVehicle",
        component: ReceiptSalesVehiclePage,
        layout: "/admin",
        name: "Receipt Against Sales",
        taskOn: "5.6.Receipt Sales Vehicle",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },

      {
        path: "/account/receiptSalesForm",      
        component: ReceiptSalesFormPage,
        taskOn: "5.6.Receipt Sales Vehicle",
        layout: "/admin",
      },
      {
        path: "/account/distanceCoverManual",
        component: DistanceCoveredManualPage,
        layout: "/admin",
        name: "Distance Covered Manual",
        taskOn: "5.7.Distance Covered Manual",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },

      {
        path: "/account/distanceCoverManualForm",      
        component: ReceiptSalesFormPage,
        taskOn: "5.7.Distance Covered Manual",
        layout: "/admin",
      },
    ]
  },
  {
    collapse: true,
    name: "MIS Report",
    taskOn: "6.MIS Report",
    icon: Apps,
    state: "misreportCollapse",
    views: [
      {
        path: "/report/outstanding",
        component: Outstanding,
        layout: "/admin",
        name: "Outstanding",
        taskOn: "6.1.Outstanding",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/report/outstandingDetails",
        component: OutstandingDetails,
        layout: "/admin",
        name: "Outstanding Details",
        taskOn: "6.2.Outstanding Details",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/report/expiryDocument",
        component: ExpiryDocPage,
        layout: "/admin",
        name: "Expiry Document",
        taskOn: "6.3.Expiry Document",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),
      },
      {
        path: "/report/totalDistanceCovered",
        component: TotalDistanceCovered,
        layout:"/admin",
        name: "Total Distance Covered",
        taskOn: "6.4.Total Distance Covered",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),

      },
      {
        path: "/report/dailyReportSummary",
        component: DailyReportSummary,
        layout:"/admin",
        name: "Daily Report Summary",
        taskOn: "6.5.Daily Report Summary",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),

      },
      {
        path: "/report/dailyTruckReport",
        component: DailyTruckReport,
        layout:"/admin",
        name: "Daily Truck Report",
        taskOn: "6.6.Daily Truck Report",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),

      },
      {
        path: "/report/maintanceReport",
        component: MaintanceReport,
        layout:"/admin",
        name: "Daily Maintance Report",
        taskOn: "6.7.Daily Maintance Report",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),

      },
      {
        path: "/report/inTransitReport",
        component: InTransitReport,
        layout:"/admin",
        name: "InTransit Report",
        taskOn: "6.8.InTransit Report",
        mini: (
          <FiberManualRecordIcon style={{ fontSize: ".8rem", marginTop: 4 }} />
        ),

      }

    ]
  },
  {
    collapse: true,
    name: "Master",
    taskOn: "7.Master",
    icon: Apps,
    state: "masterCollapse",
    views: [
      {
        state: "itemCollapse",
        menuName: "Account",
        taskOn: "7.1.Account",
        mini: <DotIcon />,
        layout: "/admin",
        views: [
          {
            path: "/master/account/primary-group",
            component: PrimaryGroupPage,
            layout: "/admin",
            name: "Primary Group",
            // taskOn: "Primary Group",
        taskOn: "7.1.Account",


          },
          {
            path: "/master/account/ledger-group",
            component: LedgerGroupPage,
            layout: "/admin",
            name: "Ledger Group",
            // taskOn: "Ledger Group",
        taskOn: "7.1.Account",

            mini: "LG",
          },
          {
            path: "/master/account/ledger-account",
            name: "Ledger Account",
            // taskOn: "Ledger Account",
        taskOn: "7.1.Account",

            component: LedgerAccountPage,
            mini: "LA",
            layout: "/admin",
          },
        ],
      },
      {
        state: "vehicleCollapse",
        menuName: "Vehicle",
        taskOn: "7.2.Vehicle",
        mini: <DotIcon />,
        layout: "/admin",
        views:[

          {
            path: "/master/vehicle",
            component: VehiclePage,
            layout: "/admin",
            name: "Vehicle",
            taskOn: "7.2.Vehicle",
            mini: <DotIcon />,
          },
         
          {
            path: "/master/vehicle-brand",
            component: vehicleBrandPage,
            layout: "/admin",
            name: "Vehicle Brand",
            taskOn: "7.2.Vehicle",
            mini: <DotIcon />,
          },
          {
            path: "/master/vehicle-type",
            component: vehicleTypePage,
            layout: "/admin",
            name: "Vehicle Type",
            taskOn: "7.2.Vehicle",
            mini: <DotIcon />,
          },

        ]

      },
      {
        state: "tyreCollapse",
        menuName: "Tyre",
        taskOn: "7.3.Tyre",
        mini: <DotIcon />,
        layout: "/admin",
        views:[
          {
            path: "/master/tyreModelPage",
            component: TyreModelPage,
            layout: "/admin", 
            name: "Tyre Model",
            taskOn: "7.3.Tyre",
            mini: <DotIcon />,
          },
          {
            path: "/master/tyreBrand",
            component: tyreBrandPage,
            layout: "/admin",
            name: "Tyre Brand",
            taskOn: "7.3.Tyre",
            mini: <DotIcon />,
          },
          {
            path: "/master/tyreDetails",
            component: tyreDetailsPage,
            layout: "/admin",
            name: "Tyre Details",
            taskOn: "7.3.Tyre",
            mini: <DotIcon />,
          },
         

        ]
      },
      {
        path: "/master/tyreModelPage",
        component: TyreModelPage,
        taskOn: "7.3.Tyre",

        layout: "/admin", 
     
      },
      {
        path: "/master/tyreBrand",
        component: tyreBrandPage,
        taskOn: "7.3.Tyre",

        layout: "/admin",    
      },
      {
        path: "/master/tyreDetails",
        component: tyreDetailsPage,
        taskOn: "7.3.Tyre",

        layout: "/admin",
       
      },


      {
        path: "/master/account/primary-group",
        component: PrimaryGroupPage,
        taskOn: "7.1.Account",
        layout: "/admin",
      },
      {
        path: "/master/account/ledger-group",
        taskOn: "7.1.Account",
        component: LedgerGroupPage,
        layout: "/admin",
      },
      {
        path: "/master/account/ledger-account",
        taskOn: "7.1.Account",
        component: LedgerAccountPage,
        layout: "/admin",
      },
      {
        path: "/master/associate",
        component: Associate,
        layout: "/admin",
        name: "Users",
        taskOn: "7.4.Users",
        mini: <DotIcon />,
      },
      {
        path: "/master/role",
        component: RolePage,
        layout: "/admin",
        name: "Role",
        taskOn: "7.5.Role",
        mini: <DotIcon />,
      },
      
      {
        path:"/master/vehicleForm",
        component: VehicleForm,
        taskOn: "7.2.Vehicle",
        layout:"/admin"
      },
      {
        path: "/master/vehicle",
        taskOn: "7.2.Vehicle",

        component: VehiclePage,
        layout: "/admin",
      },
      {
        path: "/master/vehicle-brand",
        component: vehicleBrandPage,
        taskOn: "7.2.Vehicle",

        layout: "/admin",
      
      },
      {
        path: "/master/vehicle-type",
        component: vehicleTypePage,
        taskOn: "7.2.Vehicle",

        layout: "/admin",
      
      },

      {
        path: "/master/customer",
        name: "Customer",
        taskOn: "Customer",

        mini: <DotIcon />,
        component: CustomerListPage,
        taskOn: "7.6.Customer",
        layout: "/admin",
      },
      {
        path: "/master/customer-add",
        taskOn: "7.6.Customer",

        component: AddCustomerPage,
        layout: "/admin",
      },
      {
        path: "/master/customer-view",
        taskOn: "7.6.Customer",

        component: CustomerViewPage,
        layout: "/admin",
      },
      {
        path: "/master/vendor",
        name: "Vendor",
        taskOn: "7.7.Vendor",
        mini: <DotIcon />,
        component:VendorListPage,       
        layout: "/admin",
      },
      {
        path: "/master/add-vendor",
        component: VendorTypeForm,
        taskOn: "7.7.Vendor",
        layout: "/admin",
      },
      {
        path: "/master/view-vendor",
        component: VendorViewPage,
        taskOn: "7.7.Vendor",
        layout: "/admin",
      },
      {
        path: "/master/role",
        component: RolePage,
        layout: "/admin",
      },
      {
        path: "/tranportation/sales-cum-dispatch",
        component: salesCumDispatchPage,
        taskOn: "2.4.Salescumdispatch",
        layout: "/admin",
      },
      {
        path: "/master/add-employee",
        component: EmployeeTypeForm,
        taskOn: "7.8.Employee",

        layout: "/admin",
      },
      {
        path: "/master/vendor/view",
        component: EmployeeViewPage,
        taskOn: "7.8.Employee",

        layout: "/admin",
      },
      // {
      //   path: "/master/service",
      //   component: ServicePage,
      //   layout: "/admin",
      // },

      {
        path: "/master/employee",
        name: "Employee",
        taskOn: "7.8.Employee",
        mini: <DotIcon />,
        component: EmployeeList,
        layout: "/admin",
      },

      {
        path: "/master/material",
        component: materialPage,
        layout: "/admin",
        name: "Material",
        taskOn: "7.9Material",
        mini: <DotIcon />,
      },
      {
        path: "/master/material_type",
        component: materialTypePage,
        layout: "/admin",
        name: "Material Type",
        taskOn: "7.10.Material Type",
        mini: <DotIcon />,
      },
      
     

      {
        path: "/master/TyreDetailsform",
        component: TyreDetailsform,
        taskOn: "7.12.Setting",
        layout: "/admin",
        // name: "Tyre Details",
        // taskOn: "TyreDetails",
        // mini: <DotIcon />,
      },
     
      {
        path: "/master/TyreDetailsView",
        component: TyreDetailsView,
        taskOn: "7.12.Setting",
        layout: "/admin",
        // name: "Tyre Details",
        // taskOn: "TyreDetails",
        // mini: <DotIcon />,
      },
      


      
      {
        path: "/master/routeForm",
        component: RouteForm,
        taskOn: "7.12.Setting",
        layout: "/admin",
      },
      {
        path: "/master/routeFormEdit",
        component: RouteFormEdit,
        taskOn: "7.12.Setting",
        layout: "/admin",
      },
      
      {
        path: "/master/Item",
        component: ItemPage,
        layout: "/admin",
        name: "Item",
        taskOn:"7.11.Item",
        mini: <DotIcon />,
      },
      {
        path: "/master/ItemForm",
        component: ItemFormPage,
        taskOn:"7.11.Item",
        layout: "/admin",
      },
      {
        state: "settingCollapse",
        menuName: "Setting",
        taskOn: "7.12.Setting",
        mini: <DotIcon />,
        layout: "/admin",
        views:[

          {
            path: "/master/location",
            component: locationPage,
            layout: "/admin",
            name: "Location",
            taskOn: "7.12.Setting",
            mini: <DotIcon />,
          },
          {
            path: "/master/routePage",
            component: routePage,
            layout: "/admin",
            name: "Routes",
            taskOn: "7.12.Setting",
            mini: <DotIcon />,
          },
          {
            path: "/master/petrol-pump",
            component: petrolPumpPage,
            layout: "/admin",
            name: "Petrol Pump",
            taskOn: "7.12.Setting",
            mini: <DotIcon />,
          },
          {
            path: "/master/expenses",
            component: expensesPage,
            layout: "/admin",
            name: "Expenses",
            taskOn: "7.12.Setting",
            mini: <DotIcon />,
          },
          {
            path: "/master/Police",
            component: PoliceStationPage,
            layout: "/admin",
            name: "Police Station",
            taskOn:"7.12.Setting",
    
            mini: <DotIcon />,
          },
          {
            path: "/master/Parking",
            component: ParkingPage,
            layout: "/admin",
            name: "Parking",
            taskOn:"7.12.Setting",
            mini: <DotIcon />,
          },
          {
            path: "/master/mvl",
            component: MotorLegislation,
            layout: "/admin",
            name: "Motor Vehicle Legislation",
            taskOn:"7.12.Setting",
            mini: <DotIcon />,
          },
          
          {
            path: "/master/lab",
            component: labPage,
            layout: "/admin",
            name: "Lab",
            taskOn:"7.12.Setting",
            mini: <DotIcon />,
          },
          {
            path: "/master/tyreType",
            component: TyreTypePage,
            layout: "/admin",
            name: "Tyre Type",
    taskOn:"7.12.Setting",
            mini: <DotIcon />,
          },
          {
            path: "/master/kata",
            component: KataPage,
            layout: "/admin",
            name: "Kata",
            taskOn:"7.12.Setting",
            mini: <DotIcon />,
          },
          {
            path: "/master/Brand",
            component: BrandPage,
            layout: "/admin",
            name: "Brand",
            taskOn:"7.12.Setting",
            mini: <DotIcon />,
          },
          {
            path: "/master/Category",
            component: Category,
            layout: "/admin",
            name: "Category",
            taskOn:"7.12.Setting",
    
            mini: <DotIcon />,
          },
          {
            path: "/master/uom",
            component: UnitMesurementPage,
            layout: "/admin",
            name: "uom",
            taskOn:"7.12.Setting",
    
            mini: <DotIcon />,
          },


        ]
      },
      {
        path: "/master/location",
        component: locationPage,
        taskOn: "7.12.Setting",
        layout: "/admin",
     
      },
      {
        path: "/master/routePage",
        component: routePage,
        taskOn: "7.12.Setting",
        layout: "/admin",
   
      },
      {
        path: "/master/petrol-pump",
        component: petrolPumpPage,
        taskOn: "7.12.Setting",
        layout: "/admin",
        
      },
      {
        path: "/master/expenses",
        component: expensesPage,
        taskOn: "7.12.Setting",
        layout: "/admin",
        
      },
      {
        path: "/master/Police",
        component: PoliceStationPage,
        taskOn: "7.12.Setting",
        layout: "/admin",
      
      },
      {
        path: "/master/Parking",
        component: ParkingPage,
        taskOn: "7.12.Setting",
        layout: "/admin",
      
      },
      {
        path: "/master/mvl",
        component: MotorLegislation,
        taskOn: "7.12.Setting",
        layout: "/admin",
      
      },
      
      {
        path: "/master/lab",
        component: labPage,
        taskOn: "7.12.Setting",
        layout: "/admin",
       
      },
      {
        path: "/master/tyreType",
        component: TyreTypePage,
        taskOn: "7.12.Setting",
        layout: "/admin",
      
      },
      {
        path: "/master/kata",
        component: KataPage,
        taskOn: "7.12.Setting",
        layout: "/admin",
    
      },
      {
        path: "/master/Brand",
        component: BrandPage,
        taskOn: "7.12.Setting",
        layout: "/admin",
      
      },
      {
        path: "/master/Category",
        component: Category,
        taskOn: "7.12.Setting",
        layout: "/admin",
       
      },
      {
        path: "/master/uom",
        component: UnitMesurementPage,
        taskOn: "7.12.Setting",
        layout: "/admin",
       
      },

      // {
      //   path: "/master/service",
      //   component: ServicePage,
      //   layout: "/admin",
      //   name: "Services",
      //   taskOn:"Services",
      //   mini: <DotIcon />,
      // },
     
      
      
      // {
      //   path: "/master/bank",
      //   component: BankPage,
      //   name: "Bank",
      //   taskOn: "Bank",
      //   layout: "/admin",
      //   mini: <DotIcon />,
      // },
    ],
  },
  {
    path: "/login-page",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
  },
];
export default dashRoutes;
