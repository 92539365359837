
import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";


export const postTyreFitting = async (
  TrackingDetails,
  inserted_by_id,
  onSuccess,
  onFailure
) => {
  try {
    console.log(TrackingDetails, "info");
    const res = await webApi.post("/master/tyreFitting/insert", {

      vehicle_id: TrackingDetails.vehicle_id,
      vehicle_no: TrackingDetails.vehicle_no,
      brand: TrackingDetails.brand_name,
      model: TrackingDetails.model_name,
      position_no: TrackingDetails.position_no,
      barcode_type_id: TrackingDetails.ddl_barcode_type_id,
      barcode_type_label: TrackingDetails.ddl_barcode_type_label,
      fitting_km: Number(TrackingDetails.txt_fitting_km),
      barcode_id: TrackingDetails.ddl_barcode_id,
      barcode_label: TrackingDetails.ddl_barcode_label,
      rejected_barcode_id: TrackingDetails.ddl_rejected_barcode_id,
      rejected_barcode_label: TrackingDetails.ddl_rejected_barcode_label,
      tyre_type_id: TrackingDetails.ddl_barcode_type_id,
      tyre_type_label: TrackingDetails.ddl_barcode_type_label,
      remarks: TrackingDetails.txt_remarks,
      fitting_date: timestamp(TrackingDetails.tyre_date), 
      tyre_condition:TrackingDetails.tyre_condition,
      amount:TrackingDetails.amount ? TrackingDetails.amount : 0,
      vehicle_From_id:TrackingDetails.ddl_vehicle_From_id,
      vehicle_From_no:TrackingDetails.ddl_vehicle_From_label,
      vehicle_To_id:TrackingDetails.ddl_vehicle_To_id,
      vehicle_To_no:TrackingDetails.ddl_vehicle_To_label,
      barcode_From_id:TrackingDetails.ddl_barcode_From_id,
      barcode_From_no:TrackingDetails.ddl_barcode_From_label,
      barcode_To_id:TrackingDetails.ddl_barcode_To_id,
      barcode_To_no:TrackingDetails.ddl_barcode_To_label,
      inserted_by_id: inserted_by_id,
    });
    
    const rejectRes = await webApi.post(
      "/master/rejectedTyre/rejectedTyreDetailsUpdate",
      {
        vehicle_id: TrackingDetails.ddl_vehicle_From_id,
        barcode: TrackingDetails.ddl_barcode_From_label,
        // rejected_no: res.data.rejected_tyre_no,
        // rejected_id: res.data.rejected_tyre_id,
      }
    );
    const rejectRes1 = await webApi.post(
      "/master/rejectedTyre/rejectedTyreDetailsUpdate",
      {
        vehicle_id: TrackingDetails.ddl_vehicle_To_id,
        barcode: TrackingDetails.ddl_barcode_To_label,
        // rejected_no: res.data.rejected_tyre_no,
        // rejected_id: res.data.rejected_tyre_id,
      }
    );
    const tyreDetailsUpdate0 = await webApi.post("/master/tyreFitting/tyreFittingDetailsUpdate", {
      vehicle_id: TrackingDetails.vehicle_id,
      data:{
        ddl_vehicle_no_id:TrackingDetails.vehicle_id,
        ddl_vehicle_no_label:TrackingDetails.vehicle_no,       
        ddl_tyrecondition_no_label:TrackingDetails.tyre_condition,
        txt_barcode: TrackingDetails.ddl_barcode_label,
        tyre_date:timestamp(TrackingDetails.tyre_date),        
        ddl_brand_label:TrackingDetails.brand_name,       
        ddl_model_label:TrackingDetails.model_name,       
        ddl_position_label:TrackingDetails.position_no,
        txt_amount:TrackingDetails.amount,
        txt_fitting_km:Number(TrackingDetails.txt_fitting_km),
        rejected:false,
      }
    })

    const tyreDetailsUpdate = await webApi.post("/master/tyreFitting/tyreFittingDetailsUpdate", {
      vehicle_id: TrackingDetails.ddl_vehicle_From_id,
      data:{
        ddl_vehicle_no_id:TrackingDetails.ddl_vehicle_From_id ,
        ddl_vehicle_no_label:TrackingDetails.ddl_vehicle_From_label,       
        ddl_tyrecondition_no_label:TrackingDetails.Tyre_condi_To,
        txt_barcode: TrackingDetails.ddl_barcode_To_label ,
        tyre_date:timestamp(TrackingDetails.tyre_date),        
        ddl_brand_label:TrackingDetails.brand_name_To,       
        ddl_model_label:TrackingDetails.model_name_To,       
        ddl_position_label:TrackingDetails.position_no,
        txt_amount:TrackingDetails.amount_To,
        txt_fitting_km:Number(TrackingDetails.txt_fitting_km_To),       
        rejected:false,
        interchange:true,
        Intechange_id:TrackingDetails.ddl_barcode_From_id ,

      },
   
    })
    const tyreDetailsUpdate1 = await webApi.post("/master/tyreFitting/tyreFittingDetailsUpdate", {
      vehicle_id: TrackingDetails.ddl_vehicle_To_id,
      data:{
        ddl_vehicle_no_id:TrackingDetails.ddl_vehicle_To_id ,
        ddl_vehicle_no_label:TrackingDetails.ddl_vehicle_To_label,       
        ddl_tyrecondition_no_label:TrackingDetails.Tyre_condi_From,
        txt_barcode: TrackingDetails.ddl_barcode_From_label ,
        tyre_date:timestamp(TrackingDetails.tyre_date),        
        ddl_brand_label:TrackingDetails.brand_name_From,       
        ddl_model_label:TrackingDetails.model_name_From,       
        ddl_position_label:TrackingDetails.position_no,
        txt_amount:TrackingDetails.amount_From,
        txt_fitting_km:Number(TrackingDetails.txt_fitting_km_From),       
        rejected:false,
        interchange:true,
        Intechange_id:TrackingDetails.ddl_barcode_To_id ,

      },   
    })
   
    const vehicleUpdate2 = await webApi.post("/master/vehicle/updateRejected",{
      vehicle_id: TrackingDetails.vehicle_id,
    })
    const rejectedTyreUpdate = await webApi.post("/master/rejectedTyre/update",{
      // rejected_tyre_id:TrackingDetails.rejected_tyre_id,
      rejected_tyre:true,
   
    })     
    if (res.status === 200) {
      const r = res.data;        
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    // onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllVehicle = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/vehicle/list", {

    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "list");

      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({

          value: r.vehicle_id,
          label: r.vehicle_no,
          km_start: r.km_start,


        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};
export const getRejectedTyreVehicle = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/tyreFitting/vehicle", {});
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "list");

      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({
          value: r.vehicle_id,
          label: r.vehicle_no,
          km_start: r.txt_fitting_km,
          rejected_count: r.rejected_count,
        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllVehicleBrand = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/vehicle_brand/list", {

    });
    if
      (res.status === 200) {
      const r = res.data;

      let all_vehiclesBrand = [];
      r.map((r, i) => {
        all_vehiclesBrand.push({

          value: r.vehicle_brand_id,
          label: r.vehicle_brand,

        });
      });
      return onSuccess(all_vehiclesBrand);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};



export const getMaintenanceExpenses = async (addSearch, onSuccess, onFailure) => {

  try {
    const res = await webApi.post("/master/tyreFitting/list", {
      // vehicle:addSearch.txt_vehicle_no,
      // fromDate:timestamp(addSearch.txt_from_date),
      // toDate:timestamp(addSearch.txt_to_date),
      // maintenance_expenses_no:addSearch.txt_maintenance_expenses_no,
    })


    if (res.status === 200) {
      const r = res.data;
      let allData = [];
      r.map((r, i) => {
        allData.push({
          id: i + 1,
          TyreFitting_id:r.TyreFitting_id,
          date: r.tracking_date,
          vehicle_no: r.vehicle_no,
          vehicle_brand: r.vehicle_brand_label,
          txt_fitting_km: r.fitting_km,
          txt_model_name: r.model_name,
          ddl_position_no_label: r.position_no_label,
          ddl_tyrecondition_no_label: r.tyrecondition_no_label,
          txt_barcode: r.barcode,
          txt_remarks: r.remarks,
          txt_amount: r.amount,
          action_items: r.action_items,

          action: "action",



        })
      })

      onSuccess(allData);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);

  }
}

export const getTyreFittingDetails = async (
  addSearch,
  onSuccess,
  onFailure
) => {
  try {
    const res = await webApi.post("/master/tyreFitting/list", {
      vehicle_no:addSearch.txt_vehicle_no,
      from_date:timestamp(addSearch.txt_from_date),
      to_date:timestamp(addSearch.txt_to_date) + 86399,
      // maintenance_expenses_no:addSearch.txt_maintenance_expenses_no,
    });

    if (res.status === 200) {
      const r = res.data;
      let allData = [];
      r.map((r, i) => {
        allData.push({
          id: i + 1,
          date: 1,
          TyreFitting_id:r.TyreFitting_id,
          vehicle_no: r.vehicle_no,
          vehicle_brand_id:r.vehicle_brand_id,
          vehicle_brand: r.brand_label,
          txt_fitting_km: r.fitting_km,
          txt_model_name: r.model_name,
          ddl_position_no_label: r.position_no_label,
          ddl_tyrecondition_no_label: r.tyrecondition_no_label,
          txt_barcode: r.barcode,
          txt_remarks: r.remarks,
          txt_amount: r.amount,
          date: dateFormate(r.fitting_date),
          action_items:r.action_items,
          action: "action",

        });
      });

      onSuccess(allData);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};



export const getAllBarcode = async (q, onSuccess, onFailure) => {
  try {
    console.log(q, "sank20121")

    const res = await webApi.post("/master/rejectedTyre/barcode/list", {
      vehicle_id: q
    });
    if
      (res.status === 200) {
      const r = res.data;
      console.log(r, "sank2012")
      let all_barcode = [];
      r.map((r, i) => {
        all_barcode.push({

          value: r.barcode_id,
          label: r.barcode,
          position: r.position


        });
      });
      return onSuccess(all_barcode);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};




export const getTyreFitting = async (id, onSuccess, onFailure) => {
  try {

    const res = await webApi.post("/master/tyreFitting/details", {
      
      TyreFitting_id: id,
      
    });
    console.log(res,"sankres")

    if
      (res.status === 200) {
      const r = res.data;

     

      return onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};


export const updateTyreFitting = async (info, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/tyreFitting/update", {
      TyreFitting_id: info.TyreFitting_id,
      vehicle_id: info.vehicle_id,
      vehicle_no: info.vehicle_no,
      tracking_date:info.tyre_date,
      vehicle_brand_id:info.vehicle_brand_id,
      vehicle_brand_label:info.vehicle_brand_label,
      model_name:info.ddl_model_label,
      remove_km: info.txt_remove_km,
      barcode: info.txt_barcode,
      fitting_km: info.txt_fitting_km,     
      amount: info.txt_amount,      
      position_no_id:info.ddl_position_no_id,
      position_no_label:info.ddl_position_no_label,
      tyrecondition_no_id:info.ddl_tyrecondition_no_id,
      tyrecondition_no_label:info.ddl_tyrecondition_no_label
      
    });

    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};





export const getTypeId = async ( onSuccess, onFailure) => {
  try {

    const res = await webApi.post("/master/tyreFitting/barcodeType", {
      
    });
    if
      (res.status === 200) {
      const r = res.data;
      console.log(r, "sank2012")
      let all_type = [];
      r.map((r, i) => {
        all_type.push({

          value: r.service_type_id,
          label: r.service_type_label,
          


        });
      });
      return onSuccess(all_type);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};



export const getBarcodeTyreFitting = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/tyreFitting/barcode/list", {});
    if
    (res.status === 200) {
    const r = res.data;
    console.log(r, "sank0610")
    let all_type = [];
    r.map((r, i) => {
      all_type.push({

        value: i,
        label: r.barcode,
        tyre_Type_id:r.tyre_Type_id,
        tyre_Type_name:r.tyre_Type_name,
        brand_id:r.brand_id,      
        brand_name:r.brand_name,
        model_id:r.model_id,
        model_name:r.model_name,
        tyrecondition_no_name:r.tyrecondition_no_name,
        amount:r.amount
        


      });
    });
    return onSuccess(all_type);
  } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};



export const getAllBarcodeId = async (q, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/drop_down_barcode/list", {
      vehicle_id: q,
    });
    if (res.status === 200) {
      const r = res.data;
      let all_barcode = [];
      r.map((r, i) => {
        console.log(r, "sank2012");

        all_barcode.push({
          value: i,
          label: r.barcode,
          TyreFitting: r.TyreFitting_km,
          amount: r.amount,
          company_name: r.company_name,
          condition: r.condition,
          model: r.model,
          position: r.position,
        });
      });
      return onSuccess(all_barcode);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};
