import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";
import {
  ledgerRowData,
  getSearchAllJournalList,
  getLedgerAccount,
  getSearchLedgerFromDashBoard,
} from "../../../services/accountLedgerService";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";


import ReactSelect from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Autocomplete } from "@material-ui/lab";
import { IconButton, OutlinedInput } from "@material-ui/core";
import { currencyFormate } from "views/Pages/HelperComponent/utils";
import { useLocation } from "react-router-dom";
//Tables
import {
  appFontWeightThin,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";


// import InvoiceViewForLedger from "./InvoiceViewForLedger";
// import ItemReceivedViewPageForLedger from './ItemReceivedViewPageForLedger'
// import DirectPurchaseViewForLedger from "./DirectPurchaseViewForLedger";
// import MasterModelForLedgerInvoiceView from "../../Components/MasterModelForLedgerInvoiceView";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "6px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "0px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  customSelect: {
    marginBottom: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
    "&:hover": {
      backgroundColor: "rgba(43,43, 43, 0.03)",
    },
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);
//Theme
import theme from "../../../theme/theme";
//Services

// import React from "react";
import {
  appDefaultColor,
  appSecondColor,
  reactSelectStyles,
} from "assets/jss/material-dashboard-pro-react";
import { appDefaultFamily } from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import React, { useState } from "react";
import PageTitle from "../HelperComponent/PageTitle";
import { currentDate, currentDate1, currentDate1Pdf, currentDatePdf } from "../HelperComponent/utils";
//SERVICE
import { getAllLedgerGroup } from "../../../services/LedgerAccountService";

import { getSearchLedgerVouchers, getLedgerClosingBalance } from "../../../services/accountLedgerService";

import { getListLedgerAccount } from "../../../services/LedgerAccountService";
import { getListGroup } from "../../../services/settingGroupService";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { getListCustomers } from "services/customerListService";
import { getOutstanding } from "../../../services/OutstandingService";
const useStyles1 = makeStyles(styles);

//PDF
// import pdfIcon from "../../../assets/img/pdf-icon.png"
// import { jsPDF } from "jspdf";
const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  narraction: {
    width: 155,
  },
  date: {
    width: 88
  },
  voucher: {
    width: 190
  },
  particular: {
    width: 110
  },

  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },

}));

const headerData = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "company_name",
    label: "Company Name",
  },
  {
    id: "opening_balance",
    label: "Opening Balance",
    align: "right",
  },
  {
    id: "sumSalesAmount",
    label: "Sales",
    align: "right",

  },
  {
    id: "receipt_paymentAmount",
    label: "Received",
    align: "right",

  },
  {
    id: "closing_balance",
    label: "Closing Balance",
    align: "right",

  },

];

const onClickSubmit = () => {
  setClassicModal(false);
};
const onChange = (e) => {
  const { value, name } = e.target;
  setAddBrand({ ...allUnits, [name]: value });
};
const Outstanding = () => {
  const classes1 = useStyles1();
  const location = useLocation();
  const history = useHistory();
  const [classicModal, setClassicModal] = React.useState(false);
  const [allUnits, setAllUnits] = React.useState([]);
  const [globalState, dispatch] = useStateValue();
  const [Customer, setCustomer] = React.useState([]);
  const [addSearch, setAddSearch] = React.useState({
    ddl_customer: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });

  const [refresh, setRefresh] = React.useState(false);

  const [openingBalance, setOpeningBalance] = React.useState({});
  const [ledgerList, setAllledgerList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [collapsible, setCollapsible] = React.useState(true)
  const [outstanding, setOutstanding] = useState([])


  const fetchData = () => {

    getListCustomers((r) => {
      setCustomer(r)
    })

  };

  React.useEffect(() => {
    // if(location.state.dash === true){

    //   getSearchLedgerFromDashBoard(
    //     (enquery) => {
    //       if (enquery.length) {
    //         setAllledgerList(enquery);
    //         setViewRes("visible");
    //         setLoading(false);
    //       } else {
    //         setViewRes("visible");
    //         setLoading(false);
    //         dispatch({
    //           type: actionTypes.SET_OPEN_MSG,
    //           payload: { msg: err, "Journal not found": "info" },
    //         });
    //       }
    //     },
    //     (err) => {
    //       setAllledgerList([]);
    //       setViewRes("visible");
    //       dispatch({
    //         type: actionTypes.SET_OPEN_MSG,
    //         payload: { msg: err, msgType: "error" },
    //       });
    //       setLoading(false);
    //     },
    //     {ledger_account_id: 2,to_date:currentDate(),from_date:currentDate1()},


    //   );
    // } 
    fetchData();
    setAllUnits(ledgerRowData);
  }, [refresh]);

  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddSearch((prv) => ({ ...prv, [name]: value }));
  };

  const onSelect = (info, v) => {
    setAddSearch({
      ...addSearch,
      [info.name]: v,
    });
  };


  const onSearchOutstanding = () => {

    // if (addSearch.ddl_customer) {
      setLoading(true);
      console.log("====>>>>", addSearch)
      getOutstanding(
        addSearch,
        (r) => {
          setOutstanding(r)
        },
        (err) => {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "success" },
          });
        }
      )
    // } else {
      // dispatch({
      //   type: actionTypes.SET_OPEN_MSG,
      //   payload: { msg: "Select Customer", msgType: "info" },
      // });
    // }

  };

  const classes = useStyles();

  const onClickCollaps = () => {
    collapsible ?
      setCollapsible(false)
      :
      setCollapsible(true)
  }



  return (
    <ThemeProvider theme={theme}>
      <PageTitle title="MIS Report > Outstanding" />

      <GridContainer>
        <GridItem xs="12">
          <CustomCard cdTitle="Search Outstanding"
            btnToolTip={collapsible ? "Collaps" : "Expand"}
            onClickCollapsible={onClickCollaps}
            buttonAction={collapsible}
            filterIcon>
            {
              collapsible ?
                <Paper elevation="0" className={classes.searchBar}>
                  <GridContainer justifyContent="flex-start" alignItems="center">
                    <GridItem xs="8">
                      <InputLabel id="label">Customer</InputLabel>
                      <ReactSelect
                        options={Customer}
                        getOptionLabel={(option) => option.label}
                        placeholder="Select"
                        formatGroupLabel={(d) => d.label}
                        menuPortalTarget={document.body}
                        className={classes.customSelect}
                        styles={reactSelectStyles}
                        name="ddl_customer"
                        onChange={(v, info) => onSelect(info, v)}
                        value={addSearch.ddl_customer}
                      />
                    </GridItem>

                    <GridItem xs="2">
                      <InputLabel id="label">Date Between</InputLabel>
                      <TextField
                        name="txt_from_date"
                        size="small"
                        id="date"
                        variant="outlined"
                        type="date"
                        fullWidth={true}
                        value={addSearch.txt_from_date}
                        onChange={onAddSearch}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>

                    <GridItem xs="2">

                      <TextField
                        name="txt_to_date"
                        size="small"
                        id="date"
                        variant="outlined"

                        type="date"
                        fullWidth={true}
                        defaultValue={currentDate()}
                        onChange={onAddSearch}
                        value={addSearch.txt_to_date}

                        className={classes.dateField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>

                    {console.log("opening balance", openingBalance)}

                    <GridItem xs="12">
                      <div
                        style={{
                          float: "right",
                          display: "flex",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <CustomButton
                          style={{ marginRight: "10px" }}
                          onClick={onSearchOutstanding}
                        >
                          <SearchIcon />
                        </CustomButton>
                        <CustomButton>
                          <RotateLeftIcon />
                        </CustomButton>
                      </div>
                    </GridItem>
                  </GridContainer>
                </Paper>
                : ''}
          </CustomCard>
        </GridItem>

        <GridItem xs="12">
          <CustomCard cdTitle="Outstanding Details">
            <CardBody
              style={{ height: "auto", maxHeight: 480, padding: 10 }}
              className={clxs(classes.customScroolBar)}
            >
              <MuiTable
                columns={headerData}
                rows={outstanding}
              />
            </CardBody>
          </CustomCard>
        </GridItem>
      </GridContainer>

    </ThemeProvider>
  );
};

export default Outstanding;
