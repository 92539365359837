//Service
import webApi from "./webApi/webApi";
export const getAllService = async (onSuccess, onFailure) => {
    try {
        const res = await webApi.post("/master/services/list", {});
        if(res.status === 200) {
            const r = res.data;
            let allServices = [];
            r.map((r,i) => {
                allServices.push({
                    servicee_id: i + 1,
                    service_id: r.service_id,
                    service_service: r.service,
                    service_details: r.details,
                    service_status: r.active_status,
                    action_items:r.action_items,
                    action:"action",
                });
            });
            return onSuccess(allServices);
        } else {
            onFailure("Something Wrong!!! Please try again later" + res.data);
        }
    } catch (error) {
        onFailure("Something Wrong!!! Please try again later" + res.data);
    }
};

export const getAllServiceById = async (onSuccess, onFailure) => {
    try {
        const res = await webApi.post("/master/services/list", {});
        if(res.status === 200) {
            const r = res.data;
            let allServices = [];
            r.map((r,i) => {
                allServices.push({
                id: i + 1,
                    value: r.services_id,
                    label: r.service,
                   
                });
            });
            return onSuccess(allServices);
        } else {
            onFailure("Something Wrong!!! Please try again later" + res.data);
        }
    } catch (error) {
        onFailure("Something Wrong!!! Please try again later" + res.data);
    }
};

export const getAllServiceDetails = async (service_id, onSuccess, onFailure) => {
    try {
        const res = await webApi.post("/master/services/list", {
            service_id:service_id
        });
        if(res.status === 200) {
            const r = res.data;
            let all_services = [];
            r.map((r,i) => {
                all_services.push({
                    id: i +1,
                    value: r.services_id,
                    label: r.service,
                  
                });
            });
            return onSuccess(all_services);
        } else {
            onFailure("Something Wrong!!! Please Try again later" + res.data);
        }
    } catch (error) {
        onFailure("Something Wrong!!! Please Try again later" + error);
    }
};

export const postService = async (service, inserted_by_id, onSuccess, onFailure) => {
    try {
        const res = await webApi.post("/master/services/insert", {
            service: service.txt_service,
            details: service.txt_details,
            active_status: service.switch_active_btn ? "Y" : "N",
            inserted_by_id:inserted_by_id,
        });
        if(res.status === 200)
        {
            const r = res.data;
            onSuccess(r);
        }
        else
        {
            onFailure("Something Wrong!!! Please try again later" + res.data);
        }
    } catch (error) {
        onFailure("Something Wrong!!! Please try again later"+ error);
    }
};

export const updateService = async (service, onSuccess, onFailure) => {
    try {
        const res = await webApi.post("/master/services/update", {
            service_id: service.service_id,
            service: service.txt_service,
            details: service.txt_details,
            active_status: service.switch_active_btn ? "Y" : "N",
        });
        if(res.status === 200)
        {
            const r = res.data;
            console.log(res.data,"aritra9876");
            onSuccess(r);
        }
        else
        {
            onFailure("Something Wrong!!! Please try again later"+res.data);
        }
    } catch (error) {
        onFailure("Something Wrong!!! Please try again later"+ error);
    }
};

export const deleteService = async(id, onSuccess, onFailure) => {
    try {
        const res = await webApi.post("/master/services/delete", {
            service_id: id,
        });
        if(res.status === 200)
        {
            const r = res.data;
            onSuccess(r);
        } else {
            onFailure("Something Wrong!!! Please try again later"+ res.data);
        }
    } catch (error) {
        onFailure("Something Wrong!!! Please try again later"+error);
    }
};

export const getListService = async(onSuccess, onFailure) => {
    try {
        const res = await webApi.post("/master/services/list", {});
        if(res.status === 200) 
        {
            const r = res.data;
            let arr = [];
            r.map((r,i) => {
                arr.push({
                    value: r.service_id,
                    label: r.service
                });
            });
            return onSuccess(arr);
        }
        else
        {
            onFailure("Something Wrong!!! Please try again later"+ res.data);
        }
    } catch (error) {
        onFailure("Something Wrong!!! Please try again later"+ error);
    }
};