import React, { useEffect, useState } from "react";
import Select from "react-select";

//reducers
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { InputLabel, TextField, ThemeProvider } from "@material-ui/core";
import PageTitle from "../HelperComponent/PageTitle";
import {
  currentDate,
  currentDate1,
  currentTime,
} from "views/Pages/HelperComponent/utils";
import {
  appDefaultColor,
  appSecondColor,
  whiteColor,
  reactSelectStyles,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import MuiTable from "../../Components/MuITable";
import { activeText } from "assets/jss/material-dashboard-pro-react";

//Model window
import MasterModel from "views/Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";
//Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CircleAddBtn } from "views/Components/CustomButton";
import Button from "components/CustomButtons/Button.js";
import { CustomCard } from "views/Components/CustomCard";
import { Paper } from "@mui/material";
import CustomButton from "views/Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Grid } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { getMaintenanceExpenses } from "../../../services/tyreFittingService";
import { getRejectedDetails } from "services/RejectedTyreService";
import { IconButton, OutlinedInput } from "@material-ui/core";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { useHistory } from "react-router-dom";

import { getBreakDownList } from "services/breakDownService";
import { getAllBreakDownList } from "services/breakDownService";
// import * as ExcelJS from 'exceljs';
import ExcelJS from 'exceljs';

const useStyles2 = makeStyles(styles);
// const ExcelJS = require("exceljs");

//service
// import { getAllExpenses, getAllAdvance } from "services/trackingService";
// import { postTracking, getAllDestination, getSearchDestination ,getSearchTracking} from "services/trackingService";

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  floatAddBtn: {
    position: "fixed",
    top: 90,
    right: 40,
    zIndex: 1000,
  },
  pageTitleBox: {
    color: appDefaultColor,
    // color: appSecondColor,
    fontSize: "14px",
    fontWeight: appFontWeight,
    paddingLeft: "20px",
  },
  topHeaderTitle: {
    backgroundColor: whiteColor,
    height: "auto",
    padding: "5px 20px",
    margin: "-20px -30px 10px -30px",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },
}));
const useStyles1 = makeStyles((styles) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const BrakeDownPage = () => {
  const classes1 = useStyles2();
  const history = useHistory();
  const classes = useStyles();
  const [globalState, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);

  const [collapsible, setCollapsible] = useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [addSearch, setAddSearch] = useState({
    vehicle_no: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });

  const [breakDownList, setbreakDownList] = useState([]);

  // list header data
  const headerData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "breakDownDate",
      label: "Break Down Date",
      align: "center",
    },
    {
      id: "breakDownTime",
      label: "Break Down Time",
      align: "center",
    },
    {
      id: "vehicle_no",
      label: "Vehicle Name",
      align: "left",
    },
    {
      id: "Place",
      label: "Break Down Location",
      align: "left",
    },

    {
      id: "desecription",
      label: "Description",
      align: "left",
    },

    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];

  React.useEffect(() => {
    getAllBreakDownList(
      (r) => {
        setbreakDownList(r);
        setLoading(false);
      },
      (err) => {
        setLoading(false);

        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [refresh]);

  const onAddSearch = (event) => {
    const { name, value } = event.target;
    setAddSearch((prev) => ({ ...prev, [name]: value }));
  };
  const onClickCollaps = () => {
    console.log("Reached23");
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };
  const onSearch = (e) => {
    e.preventDefault();

    getBreakDownList(
      addSearch,
      (res) => {
        console.log(res, "sankres=>");
        if (res.length) {
          setbreakDownList(res);
        } else {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: {
              msg: "Break Down Details Not Found",
              msgType: "error",
            },
          });
        }
      },
      (err) => {
        setbreakDownList([]);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddSearch({
      txt_vehicle_no: "",
      txt_to_date: currentDate(),
      txt_from_date: currentDate1(),
    });
  };

  const onUpdateRejectedTyre = (row) => {
    console.log("sankedit", row);
    history.push({
      pathname: "/admin/maintence/breakDownListEdit",
      // search: '?query=abc',
      state: {
        edit: true,
        row: row,
        breakDownList_id: row.breakDownList_id,
      },
    });
  };

  const onClickView = (row) => {
    console.log("sankView", row);
    history.push({
      pathname: "/admin/maintence/breakDownListView",
      view: true,
      row: row,
      breakDown_id: row.breakDown_id,
    });
  };


  const onhandleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('data');
  
    const titleRow = worksheet.addRow(['Break Down']);
    const periodRow = worksheet.addRow(['Period:']);
    worksheet.addRow([]);
  
    const titleCell = titleRow.getCell(1);
    const periodCell = periodRow.getCell(1);
    titleCell.alignment = { vertical: 'middle', horizontal: 'center', bold: true };
    periodCell.alignment = { vertical: 'middle', horizontal: 'center', bold: true };
  
    worksheet.mergeCells(`A${titleRow.number}:H${titleRow.number}`);
    worksheet.mergeCells(`A${periodRow.number}:H${periodRow.number}`);
  
    const headers = [
         "Sl_No",
      "Break Down Date",
      "Break Down Time",
      "Vehicle No",
      "Location",
      "Description",
    ];
  
    // Add headers as a row
    worksheet.addRow(headers);
  
    const BreakDownTable = breakDownList.map((reject) => {
      return {
        Sl_No:reject.id,
        Break_Down_Date: reject.breakDownDate,
        Break_Down_Time:reject.breakDownTime,
        Vehicle_No:reject.vehicle_no,
        Location:reject.Place,
        Description:reject.desecription,
      };
    });
  
    const dataStartRow = 4;
  
    const headerRow = worksheet.getRow(dataStartRow);
    headerRow.height = 20;
  
    headers.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1);
      column.width = header.length + 5;
    });
  
    BreakDownTable.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });
  
    headerRow.font = { bold: true };
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '6dc8da' } };
    });
  
    worksheet.eachRow({ startingRow: dataStartRow + 1 }, (row, rowNumber) => {});
  
    worksheet.autoFilter = {
      from: {
        row: dataStartRow,
        column: 1,
      },
      to: {
        row: dataStartRow,
        column: headers.length,
      },
    };
    
  
    const fromDate = addSearch?.txt_from_date;
    const toDate = addSearch?.txt_to_date;
    const chosenPeriod = ` ${fromDate}  To  ${toDate}`;
    periodCell.value = `Period:  ${chosenPeriod}`;
  
    const buffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(data, 'BreakDownTable.xlsx');
  };

 
  // const onhandleExportToExcel = async () => {
  //   const workbook = XLSX.utils.book_new();
  //   const worksheet = XLSX.utils.aoa_to_sheet([]);
  
  //   const BreakDownTable = breakDownList.map((reject) => [
  //     reject.id,
  //     reject.breakDownDate,
  //     reject.breakDownTime,
  //     reject.vehicle_no,
  //     reject.Place,
  //     reject.desecription,
  //   ]);
  
  //   // Title
  //   const title = ["Break Down"];
  //   XLSX.utils.sheet_add_aoa(worksheet, [title], { origin: "A3" });
  
  //   // Apply title styles
  //   const titleStyle = { font: { bold: true, size: 16, color: { rgb: "000000" } }, alignment: { horizontal: "center" } };
  //   const titleCell = XLSX.utils.encode_cell({ c: 0, r: 2 });
  //   Object.keys(titleStyle).forEach((key) => {
  //     worksheet[titleCell][key] = titleStyle[key];
  //   });
  
  //   // Headers
  //   const headers = [
  //     "Sl_No",
  //     "Break Down Date",
  //     "Break Down Time",
  //     "Vehicle No",
  //     "Location",
  //     "Description",
  //   ];
  //   XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A4" });
  
  //   // Apply header styles
  //   const headerStyle = { font: { italic: true, color: { rgb: "FF0000" } }, alignment: { horizontal: "center" } };
  //   headers.forEach((header, colIndex) => {
  //     const cellAddress = XLSX.utils.encode_cell({ c: colIndex, r: 3 });
  //     Object.keys(headerStyle).forEach((key) => {
  //       worksheet[cellAddress][key] = headerStyle[key];
  //     });
  //   });
  
  //   // Data
  //   XLSX.utils.sheet_add_aoa(worksheet, BreakDownTable, { origin: "A5" });
  
  //   // Merging title cells
  //   const titleRange = { s: { c: 0, r: 2 }, e: { c: headers.length - 1, r: 2 } };
  //   worksheet["!merges"] = [titleRange];
  
  //   worksheet["!autofilter"] = {
  //     ref: XLSX.utils.encode_range({
  //       s: { c: 0, r: 3 },
  //       e: { c: headers.length - 1, r: 3 },
  //     }),
  //   };
  
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "data");
  
  //   const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
  //   const data = new Blob([buffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });
  
  //   saveAs(data, "BreakDown.xlsx");
  // };
  
  
  
  
  
  
  
  

  // export to excel
 

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Maintenance > Brake Down"
        btnToolTip="Add Break Down Vehicle"
        // onClickAddBtn={onClickAddButton}
        addBtnLink="/admin/maintence/breakDownForm"
      />

      {/* search section */}
      <GridContainer>
        <GridItem xs="12">
          <CustomCard
            cdTitle="Search Break Down Details"
            btnToolTip={collapsible ? "Collaps" : "Expand"}
            onClickCollapsible={onClickCollaps}
            buttonAction={collapsible}
            filterIcon
          >
            {collapsible ? (
              <Paper elevation="0" className={classes.searchBar}>
                <GridContainer
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <GridItem xs="3" textAlign="left">
                    <InputLabel id="label">Vehicle</InputLabel>
                    <TextField
                      size="small"
                      placeholder="Vehicle"
                      name="vehicle_no"
                      onChange={onAddSearch}
                      type="search"
                      id="outlined-basic"
                      fullWidth={true}
                      value={addSearch.vehicle_no}
                      variant="outlined"
                      onClick={(e) => {
                        e.target.select();
                      }}
                      inputProps={{ style: { textAlign: "left" } }}
                      FormHelperTextProps={{ style: { textAlign: "left" } }}
                    />
                  </GridItem>

                  {/* <GridItem xs={2}>
                    <InputLabel id="label">Date Between</InputLabel>
                    <TextField
                      size="small"
                      name="txt_from_date"
                      id="date"
                      variant="outlined"
                      type="date"
                      fullWidth={true}
                      inputProps={{ style: { textAlign: "left" } }}
                      FormHelperTextProps={{ style: { textAlign: "left" } }}
                      value={addSearch.txt_from_date}
                      onChange={onAddSearch}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={(e) => {
                        e.target.select();
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2}>
                    <TextField
                      name="txt_to_date"
                      size="small"
                      id="date"
                      variant="outlined"
                      type="date"
                      fullWidth={true}
                      value={addSearch.txt_to_date}
                      onChange={onAddSearch}
                      className={classes.dateField}
                      inputProps={{ style: { textAlign: "left" } }}
                      FormHelperTextProps={{ style: { textAlign: "left" } }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </GridItem> */}
                  <GridItem>
                    <div className={classes.searchbtnRight}>
                      <CustomButton
                        type="submit"
                        onClick={onSearch}
                        name="btn_refresh"
                        style={{ marginRight: "10px" }}
                      >
                        <SearchIcon />
                      </CustomButton>
                      <CustomButton onClick={onClickRefresh}>
                        <RotateLeftIcon />
                      </CustomButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </Paper>
            ) : (
              ""
            )}
          </CustomCard>
        </GridItem>
      </GridContainer>
      {/* all list display */}
      {breakDownList.length > 0 ? (
        <GridContainer>
          <GridItem xs="12">
            <Card className={classes1.headerCard}>
              <CardHeader
                className={classes1.TbheaderCdhd}
                style={{ height: 60 }}
              >
                <GridContainer
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <GridItem>
                    <h4 className={classes1.headerCdTitle}>
                      Break Down Details List
                    </h4>
                  </GridItem>
                  <GridItem style={{ cursor: "pointer" }}>
                    <IconButton
                      variant="text"
                      onClick={() => onhandleExportToExcel(breakDownList)}
                    >
                      <Tooltip title="Export to Excel">
                        <img
                          src={require("../../../assets/img/excel.png").default}
                        />
                      </Tooltip>
                    </IconButton>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody
                style={{ height: "auto", maxHeight: 480, padding: 10 }}
                className={clxs(classes.customScroolBar)}
              >
                {/* <CustomCard cdTitle="Rejected Details"
                        > */}
                <MuiTable
                  onClickViewOne={onClickView}
                  //   onClickEdit={onUpdateRejectedTyre}
                  columns={headerData}
                  rows={breakDownList}
                />
                {/* </CustomCard> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
    </ThemeProvider>
  );
};

export default BrakeDownPage;
