import React, { useEffect, useState } from "react";
import Select from "react-select";

//reducers
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import {
  InputLabel,
  Switch,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import PageTitle from "../HelperComponent/PageTitle";
import {
  currentDate,
  currentDate1,
  currentTime,
} from "views/Pages/HelperComponent/utils";
import {
  appDefaultColor,
  appSecondColor,
  whiteColor,
  reactSelectStyles,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import MuiTable from "../../Components/MuITable";
import { activeText } from "assets/jss/material-dashboard-pro-react";

//Model window
import MasterModel from "views/Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";
//Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CircleAddBtn } from "views/Components/CustomButton";
import Button from "components/CustomButtons/Button.js";
import { CustomCard } from "views/Components/CustomCard";
import { Paper } from "@mui/material";
import CustomButton from "views/Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

//service
// import { getDispatchedVehicle, getAllExpenses, getAllAdvance } from "services/destinationService";
// import { postDestination, getAllDestination, getSearchDestination } from "services/destinationService";
import { postTracking, getTrackingVehicle } from "services/trackingService";
import { ColoseButton } from "views/Components/CustomButton";
import { vehicleTracking } from "services/trackingService";
import { dateFormate, time, timeDiff } from "views/Pages/HelperComponent/utils";
import { timeToUnix } from "views/Pages/HelperComponent/utils";
import { IstTime } from "views/Pages/HelperComponent/utils";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import {
  excessTime,
  timeChevronCheck,
} from "views/Pages/HelperComponent/utils";

import {
  getAllVehicleBrand,
  postTyreFitting,
} from "services/RejectedTyreService";
import { getAllBarcode } from "services/RejectedTyreService";
import { postRejectedTyre } from "services/RejectedTyreService";
import { useLocation } from "react-router-dom";
import { getRejectedTyre } from "services/RejectedTyreService";
import { updateRejectedTyre } from "services/RejectedTyreService";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import { InputAdornment, IconButton, OutlinedInput } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import { CircleAddBtnAnim } from "../../Components/CustomButton";
import {
  appDefaultFamily,
  appFontWeightThin,
} from "assets/jss/material-dashboard-pro-react";
import { getAllVehicleDetails } from "services/maintanceService";
import { getAllVehicle } from "services/maintanceService";
import { getAllCategory } from "services/maintanceService";
import { getAllItemById } from "services/maintanceService";
import { getAllService } from "services/serviceService";
import { getAllServiceById } from "services/serviceService";
import AddNewCategory from "../SettingsPages/Category";
import { Input, Box } from "@material-ui/core";
import AddItemPage from "../SettingsPages/ItemFormPage";
import { postMaintance } from "services/maintanceService";
import { getAllUom } from "services/uomService";
import { getAllUomById } from "services/maintanceService";
import { ViewMaintanceDetails } from "services/maintanceService";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "6px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "0px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  customSelect: {
    marginBottom: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
    "&:hover": {
      backgroundColor: "rgba(43,43, 43, 0.03)",
    },
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  floatAddBtn: {
    position: "fixed",
    top: 90,
    right: 40,
    zIndex: 1000,
  },
  pageTitleBox: {
    color: appDefaultColor,
    // color: appSecondColor,
    fontSize: "14px",
    fontWeight: appFontWeight,
    paddingLeft: "20px",
  },
  topHeaderTitle: {
    backgroundColor: whiteColor,
    height: "auto",
    padding: "5px 20px",
    margin: "-20px -30px 10px -30px",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },
  ddlError1: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -12,
    fontWeight: 300,
  },
}));
const useStyles1 = makeStyles((styles) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const serviceType = [
  {
    label: "INTERIM CAR SERVICE",
    value: 1,
  },
  {
    label: "FULL CAR SERVICE",
    value: 2,
  },
  {
    label: "MAJOR CAR SERVICE",
    value: 3,
  },
];

const condition = [
  {
    label: "10%",
    value: 1,
  },
  {
    label: "20%",
    value: 2,
  },
  {
    label: "30%",
    value: 3,
  },
  {
    label: "40%",
    value: 4,
  },
  {
    label: "50%",
    value: 5,
  },
  {
    label: "60%",
    value: 6,
  },
  {
    label: "70%",
    value: 7,
  },
  {
    label: "80%",
    value: 8,
  },
  {
    label: "90%",
    value: 9,
  },
  {
    label: "100%",
    value: 10,
  },
];

const MaintanceView = () => {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();
  const classs = useStyles1();
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [globalState, dispatch] = useStateValue();
  const [successModal, setSuccessModal] = useState(false);
  const [classicModel, setClassicModel] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [maintanceDetailes, setMaintanceDetailes] = useState({
    edit: false,
    inFlow: false,
    vehicle_no: "",
    vehicle_id: "",
    vehicle_brand: "",
    engine_number: "",
    chassis_number: "",
    no_of_wheels: "",
    vehicle_ownership: "",
    maintainance_date: currentDate(),
    maintance_order_no: "",
  });

  const [spartsParts, setSpartsParts] = useState({
    ddl_category: "",
    ddl_category_label: "",
    ddl_item: "",
    ddl_item_label: "",
    txt_qty: "",
    ddl_unit_id: "",
    ddl_unit_label: "",
    txt_rate: "",
    txt_amount: 0,
    txt_remarks: "",
    txt_maintance_location: "",
    txt_labour_charges: 0,
  });

  const [servicesDetails, setServicesDetails] = useState({
    edit: false,
    inFlow: false,
    active_status: "",
    txt_service: "",
    txt_service_amount: 0,
    txt_service_remarks: "",
    active_status: "",
  });

  const [loading, setLoading] = React.useState(false);
  const [addSearch, setAddSearch] = useState({
    txt_vehicle_no: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });
  const [vehicle, setVehicle] = useState([]);
  const [category, setCategory] = useState([]);
  const [item, setAllItem] = useState([]);

  const [error, setError] = React.useState({
    vehicle_id: false,
    ddl_barcode_id: false,
    txt_amount: false,
    txt_model_name: false,
  });
  const [services, setServices] = React.useState([]);

  const [uom, setUom] = React.useState([]);

  const [collapsible, setCollapsible] = useState(true);
  const [collapsibleunloading, setCollapsibleunloading] = useState(true);
  const [selectedExpense, setSelectedExpense] = useState([]);
  const [transactionData, setTransactionData] = useState();
  const onClickCollaps = () => {
    console.log("Reached23");
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };
  const [maintanceDetails, setMaintanceDetails] = useState([]);
  const [state, setState] = React.useState({
    edit: false,
  });
  const [check, setCheck] = useState(false);
  const [checkSpare, setCheckSpare] = useState(false);
  const [spareList, setSpareList] = React.useState([]);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [vehicleDetails, setAllVehicleDetails] = React.useState([]);
  const [serviceList, setServiceList] = React.useState([]);
  const [openCategoryModel, setOpenCategoryModel] = React.useState(false);
  const [openModes, setOpenModel] = React.useState({
    newItem: false,
    // newSource: false,
  });
  const [totalServiceAmount, setTotalServiceAmount] = useState(0);
  const [totalSpareAmount, setTotalSpareAmount] = useState(0);
  const [totalLabourCharges, setTotalLabourCharges] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  let menuPortal = true;

  React.useEffect(() => {
    const totalService = serviceList.reduce(
      (total, item) => total + parseFloat(item.txt_service_amount || 0),
      0
    );
    const totalSpare = spareList.reduce(
      (total, item) => total + parseFloat(item.txt_amount || 0),
      0
    );
    const totalLabour = spareList.reduce(
      (total, item) => total + parseFloat(item.txt_labour_charges || 0),
      0
    );

    setTotalServiceAmount(totalService);
    setTotalSpareAmount(totalSpare);
    setTotalLabourCharges(totalLabour);
    setGrandTotal(totalService + totalSpare + totalLabour);
  }, [serviceList, spareList]);

  React.useEffect(() => {
    console.log(location, "sanklocation");
    // const id = location?.oil_id

    ViewMaintanceDetails(
      location?.maintenance_id,
      (r) => {
        console.log(r, "sanklocation1");

        setMaintanceDetails(r);
        setServiceList(r.service_List);
        setSpareList(r.spare_Parts)
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [location]);

  const onCloseModel = () => {
    history.push({
      pathname: "/admin/maintence/maintence_Page",
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title={state.edit ? "Transportation >  Edit" : "Add Maintance"}
        btnToolTip="Add Maintance"
        // onClickAddBtn={onClickAddDestination}
      />

      {/* On Click Model Window Open */}

      <GridContainer
        style={{ padding: "10px" }}
        justify="space-between"
        alignItems="center"
      >
        <CustomCard cdTitle="Maintance" filterIcon>
          <GridContainer justify="space-between" alignItems="center">
            <GridItem xs="3">
              <InputLabel id="label">Maintance No.</InputLabel>
              <TextField
                size="small"
                placeholder="Auto Generated"
                type="String"
                name="txt_Maintance_no"
                // onChange={onChange}
                id="outlined-basic"
                fullWidth={true}
                value={maintanceDetails.maintenance_order_no}
                variant="outlined"
              />
            </GridItem>
            {/* {console.log(dateFormate(maintanceDetails.maintainance_date),"view")} */}
            <GridItem xs={2}>
              <InputLabel required={false} id="label">
                Date
              </InputLabel>
              <TextField
                size="small"
                // type="Date"
                name="maintainance_date"
                // onChange={onChangeDate}
                id="outlined-basic"
                fullWidth={true}
                placeholder="date"
                value={dateFormate(maintanceDetails.maintainance_date)}
                FormHelperTextProps={{
                  style: { textAlign: "right" },
                }}
                variant="outlined"
              />
            </GridItem>
          </GridContainer>
        </CustomCard>
      </GridContainer>
      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <CustomCard cdTitle="" filterIcon onClickFilter={() => {}}>
            <GridContainer
              style={{ padding: "10px" }}
              justify="space-between"
              alignItems="center"
            >
              <CustomCard cdTitle="Truck Details" filterIcon>
                <GridContainer
                  // style={{ padding: "10px" }}
                  justify="space-between"
                  alignItems="center"
                >
                  <GridItem xs="3">
                    <InputLabel id="label">Truck No.</InputLabel>
                    <Select
                      //   options={vehicle}
                      size="small"
                      formatGroupLabel={(d) => d.label}
                      // required={true}
                      placeholder="Select Module"
                      name="ddl_vehicle"
                      //   onChange={onSelectVehicle}
                      id="outlined-basic"
                      fullWidth={true}
                      variant="outlined"
                      menuPortalTarget={document.body}
                      value={{
                        value: maintanceDetails.vehicle_id,
                        label: maintanceDetails.vehicle,
                      }}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs="2" style={{ marginTop: "5px" }}>
                    <InputLabel required={false} id="label">
                      Brand
                    </InputLabel>
                    <TextField
                      size="small"
                      type="String"
                      name="vehicle_brand"
                      id="outlined-basic"
                      fullWidth={true}
                      placeholder=" Brand"
                      value={maintanceDetails.vehicle_brand}
                      inputProps={{
                        style: {
                          color: "blue",
                          border: "none",
                          textAlign: "left",
                        },
                      }}
                      disabled
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label">
                      Model No
                    </InputLabel>
                    <TextField
                      size="small"
                      type="String"
                      name="engine_number"
                      id="outlined-basic"
                      fullWidth={true}
                      placeholder="engine_number"
                      value={maintanceDetails.engine_number}
                      inputProps={{
                        style: {
                          color: "green",
                          border: "none",
                          textAlign: "left",
                        },
                      }}
                      disabled
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label">
                      Chassis No.
                    </InputLabel>
                    <TextField
                      size="small"
                      type="String"
                      name="chassis_number"
                      id="outlined-basic"
                      fullWidth={true}
                      inputProps={{
                        style: {
                          color: "green",
                          border: "none",
                          textAlign: "left",
                        },
                      }}
                      placeholder=" Chassis No."
                      value={maintanceDetails.chassis_number}
                      disabled
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs="1">
                    <InputLabel required={false} id="label">
                      Wheels
                    </InputLabel>
                    <TextField
                      size="small"
                      type="number"
                      name="no_of_wheels"
                      id="outlined-basic"
                      fullWidth={true}
                      placeholder="Wheels"
                      inputProps={{
                        style: {
                          color: "green",
                          border: "none",
                          textAlign: "right",
                        },
                      }}
                      value={maintanceDetails.no_of_wheels}
                      disabled
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label">
                      Ownership
                    </InputLabel>
                    {/* {console.log(oilDetails.km_end_reading, "5555")} */}
                    <TextField
                      size="small"
                      type="String"
                      name="vehicle_ownership"
                      id="outlined-basic"
                      fullWidth={true}
                      // variant="outlined"
                      placeholder="Ownership"
                      value={
                        maintanceDetails.vehicle_ownership
                      }
                      inputProps={{
                        style: { textAlign: "left", color: "green" },
                      }}
                      variant="outlined"
                      disabled
                      // disabled={kmEnd}
                    />
                  </GridItem>

                  <GridItem xs="3" style={{ margin: "5px" }}></GridItem>
                  <GridItem xs="3" style={{ margin: "5px" }}></GridItem>
                </GridContainer>
                {/* : ''
                                } */}
              </CustomCard>
              <CustomCard cdTitle="Others" filterIcon onClickFilter={() => {}}>
                <GridContainer>
                  <GridItem xs={3}>
                    <InputLabel required={true} id="label">
                      Service
                    </InputLabel>
                    <TextField
                      size="small"
                      type="string"
                      placeholder="service"
                      name="txt_service"
                      //   onChange={onChangeService}
                      value={servicesDetails.txt_service}
                      id="outlined-basic"
                      FormHelperTextProps={{
                        style: { textAlign: "right" },
                      }}
                      variant="outlined"
                      disabled
                    />
                  </GridItem>

                  <GridItem xs={2}>
                    <InputLabel required={true} id="label">
                      Amount
                    </InputLabel>
                    <TextField
                      size="small"
                      type="Number"
                      placeholder="Amount"
                      name="txt_service_amount"
                      //   onChange={onChangeService}
                      id="outlined-basic"
                      textAlign="right"
                      fullWidth={true}
                      value={servicesDetails.txt_service_amount}
                      variant="outlined"
                      disabled
                    />
                  </GridItem>

                  <GridItem xs={6}>
                    <InputLabel id="label">Remarks</InputLabel>
                    <TextField
                      size="small"
                      type="string"
                      placeholder="Remarks"
                      name="txt_service_remarks"
                      //   onChange={onChangeService}
                      value={servicesDetails.txt_service_remarks}
                      id="outlined-basic"
                      FormHelperTextProps={{
                        style: { textAlign: "right" },
                      }}
                      fullWidth={true}
                      variant="outlined"
                      disabled
                    />
                  </GridItem>
                  <GridItem xs={1} align="right">
                    <CircleAddBtnAnim
                      title="Add"
                      //   onClick={onClickAddService}
                    />
                  </GridItem>

                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                       
                          <TableRow>
                            <TableCell align="center">#</TableCell>
                            {/* <TableCell align="center">Service Type</TableCell> */}
                            <TableCell align="left">Service</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="right">Remarks</TableCell>
                            <TableCell align="right">Action</TableCell>
                          </TableRow>
                        
                      </TableHead>
                      <TableBody>
                      
                         {serviceList && serviceList.map((row, i) => {
                          
                            return (
                              <TableRow key={i}>
                                <TableCell
                                  align="center"
                                  className={classes.id}
                                >
                                  {i + 1}
                                </TableCell>
                                {/* <TableCell align="center">{row.ddl_service_type_label}</TableCell> */}
                                <TableCell
                                  align="left"
                                  className={classes.ledger}
                                >
                                  {row.txt_service}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.debit}
                                >
                                  {row.txt_service_amount}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.credit}
                                >
                                  {row.txt_service_remarks}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.credit}
                                >
                                  <IconButton
                                    // onClick={(e) => onDeleteService(row, i)}
                                    aria-label="delete"
                                    className={classes.credit}
                                  >
                                    <DeleteIcon
                                      fontSize="small"
                                      style={{ color: "#f44336" }}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <TableRow></TableRow>
                  </TableContainer>
                </GridContainer>
              </CustomCard>

              <CustomCard
                cdTitle="Spare Parts"
                filterIcon
                onClickFilter={() => {}}
              >
                <GridContainer>
                  <GridItem xs={3}>
                    <InputLabel required={true} id="label">
                      Category
                    </InputLabel>
                    <Select
                      options={category}
                      placeholder="Category Type"
                      name="ddl_category"
                      formatGroupLabel={(d) => d.label}
                      className={classes.customSelect}
                      menuPortalTarget={menuPortal && document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      value={{
                        value: spartsParts.ddl_category_id,
                        label: spartsParts.ddl_category_label,
                      }}
                      //   onChange={onSelect}
                      disabled
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <InputLabel required={true} id="label">
                      Item
                    </InputLabel>
                    <Select
                      options={item}
                      name="ddl_item"
                      placeholder="Item"
                      formatGroupLabel={(d) => d.label}
                      className={classes.customSelect}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      value={{
                        value: spartsParts.ddl_item_id,
                        label: spartsParts.ddl_item_label,
                      }}
                      //   onChange={onSelect}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs={2}>
                    <InputLabel required={true} id="label">
                      Qty
                    </InputLabel>
                    <TextField
                      size="small"
                      type="Number"
                      placeholder="Qty"
                      name="txt_qty"
                      textAlign={"right"}
                      //   onChange={onChange}
                      id="outlined-basic"
                      fullWidth={true}
                      value={spartsParts.txt_qty}
                      variant="outlined"
                      disabled
                    />
                  </GridItem>
                  <GridItem xs={2}>
                    <InputLabel required={true} id="label">
                      Unit
                    </InputLabel>
                    <Select
                      options={uom}
                      formatGroupLabel={(d) => d.label}
                      placeholder="Unit"
                      name="ddl_unit"
                      //   onChange={onSelect}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs={2}>
                    <InputLabel required={true} id="label">
                      Rate
                    </InputLabel>
                    <TextField
                      size="small"
                      type="Number"
                      name="txt_rate"
                      placeholder="Rate"
                      //   onChange={onChange}
                      id="outlined-basic"
                      fullWidth={true}
                      value={spartsParts.txt_rate}
                      variant="outlined"
                      disabled
                    />
                  </GridItem>
                  <GridItem xs={2}>
                    <InputLabel required={true} id="label">
                      Amount
                    </InputLabel>
                    <TextField
                      size="small"
                      type="Number"
                      name="txt_amount"
                      placeholder="Amount"
                      inputProps={{
                        style: {
                          color: "green",
                          border: "none",
                          textAlign: "right",
                        },
                      }}
                      //   onChange={onChange}
                      id="outlined-basic"
                      fullWidth={true}
                      variant="outlined"
                      value={spartsParts.txt_amount}
                      disabled
                    />
                  </GridItem>

                  <GridItem xs={6}>
                    <InputLabel id="label">Remarks</InputLabel>
                    <TextField
                      size="small"
                      type="string"
                      placeholder="remarks"
                      name="txt_remarks"
                      fullWidth={true}
                      //   onChange={onChange}
                      value={maintanceDetailes.txt_remarks}
                      id="outlined-basic"
                      variant="outlined"
                      disabled
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <InputLabel id="label">Maintance Location</InputLabel>
                    <TextField
                      size="small"
                      type="string"
                      placeholder="Maintance Location"
                      name="txt_maintance_location"
                      fullWidth={true}
                      //   onChange={onChange}
                      value={maintanceDetailes.txt_maintance_location}
                      id="outlined-basic"
                      variant="outlined"
                      disabled
                    />
                  </GridItem>
                  <GridItem xs={2}>
                    <InputLabel id="label">Status</InputLabel>
                    <Switch
                      size="small"
                      name="switch_active_status"
                      //   checked={isSwitchOn}
                      //   onChange={handleSwitchChange}
                    />
                  </GridItem>
                  {/* {isSwitchOn && ( */}
                  <GridItem xs={4}>
                    <InputLabel id="label">Labour Charges</InputLabel>
                    <TextField
                      size="small"
                      type="Number"
                      placeholder="Labour Charges"
                      name="txt_labour_charges"
                      fullWidth={true}
                      // onChange={onChange}
                      value={spartsParts.txt_labour_charges}
                      id="outlined-basic"
                      variant="outlined"
                      disabled
                    />
                  </GridItem>
                  {/* )} */}
                  <GridItem xs={1} align="right">
                    <CircleAddBtnAnim
                      title="Add"
                      //   onClick={onClickAddSpare}
                      //  anim={anim}
                    />
                  </GridItem>

                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        
                          <TableRow>
                            <StyledTableCell align="center">#</StyledTableCell>
                            <StyledTableCell align="center">
                              Category
                            </StyledTableCell>

                            <StyledTableCell align="left">Item</StyledTableCell>
                            <StyledTableCell align="right">Qty</StyledTableCell>

                            <StyledTableCell align="right">
                              Unit
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              Rate
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              Amount
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              Maintance Location
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              Labour Charges
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              Action
                            </StyledTableCell>
                          </TableRow>
                        
                      </TableHead>
                      <TableBody>
                        {/* {console.log("sank24061", extraChargesList)}*/}
                        {spareList &&
                          spareList.map((row, i) => (
                            <StyledTableRow key={i}>
                              <StyledTableCell
                                align="center"
                                className={classes.id}
                              >
                                {row.length != 0 ? i + 1 : ""}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.ddl_category_label}
                              </StyledTableCell>

                              <StyledTableCell
                                align="left"
                                className={classes.ledger}
                              >
                                {row.ddl_item_label}
                              </StyledTableCell>

                              <StyledTableCell
                                align="right"
                                className={classes.debit}
                              >
                                {row.txt_qty}
                              </StyledTableCell>

                              <StyledTableCell
                                align="right"
                                className={classes.credit}
                              >
                                {row.txt_unit}
                              </StyledTableCell>

                              <StyledTableCell
                                align="right"
                                className={classes.credit}
                              >
                                {row.txt_rate}
                              </StyledTableCell>
                              <StyledTableCell
                                align="right"
                                className={classes.credit}
                              >
                                {row.txt_amount}
                              </StyledTableCell>
                              <StyledTableCell
                                align="right"
                                className={classes.credit}
                              >
                                {row.txt_maintance_location}
                              </StyledTableCell>
                              <StyledTableCell
                                align="right"
                                className={classes.credit}
                              >
                                {row.txt_labour_charges
                                  ? row.txt_labour_charges
                                  : 0}
                              </StyledTableCell>

                              <StyledTableCell
                                align="right"
                                className={classes.credit}
                              >
                                <IconButton
                                  //   onClick={(e) => onDelete(row, i)}
                                  aria-label="delete"
                                  className={classes.credit}
                                >
                                  <DeleteIcon
                                    fontSize="small"
                                    style={{ color: "#f44336" }}
                                  />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </GridContainer>
              </CustomCard>
              <CustomCard cdTitle="Total" filterIcon onClickFilter={() => {}}>
                <GridContainer>
                  <GridItem xs={12}>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <StyledTableCell
                              align="right"
                              colSpan={3}
                              style={{ borderBottom: "none", fontSize: "13px" }}
                            >
                              Total Service Amount
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              style={{ borderBottom: "none", fontSize: "13px" }}
                            >
                              {totalServiceAmount}
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              align="right"
                              colSpan={3}
                              style={{ borderBottom: "none", fontSize: "13px" }}
                            >
                              Total Spare Parts Amount
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              style={{ borderBottom: "none", fontSize: "13px" }}
                            >
                              {totalSpareAmount}
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              align="right"
                              colSpan={3}
                              style={{ borderBottom: "none", fontSize: "13px" }}
                            >
                              Total Labour Charges
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              style={{ borderBottom: "none", fontSize: "13px" }}
                            >
                              {totalLabourCharges}
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              align="right"
                              colSpan={3}
                              style={{ borderBottom: "none", fontSize: "16px" }}
                            >
                              <strong>Grand Total</strong>
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              style={{ borderBottom: "none", fontSize: "16px" }}
                            >
                              <strong>{grandTotal}</strong>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                </GridContainer>
              </CustomCard>

              <div style={{ width: "100%" }} className={classes.actionbtns}>
                <ColoseButton
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    float: "right",
                    width: "10%",
                    marginRight: "10px",
                    border: "none",
                  }}
                  onClick={onCloseModel}
                >
                  close
                </ColoseButton>
              </div>
            </GridContainer>
          </CustomCard>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
};

export default MaintanceView;
