import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";
import { currentDate } from "../views/Pages/HelperComponent/utils";


export const getDispatchedVehicle = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/destination/dispatched_vehicle_list", {
      destination_status: false,

    });
    if
      (res.status === 200) {
      const r = res.data;

      console.log(r,"sen01")
      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({

          //for ddl
          value: r.vehicle_id,
          label: r.vehicle_no,
          transaction_no:r.transaction_no,
          salesCumDispatch_no:r.salesCumDispatch_no,
          salse_dispatch_id:r.salse_dispatch_id,
          trip_no:r.trip_no,
        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postDestination = async (DestinationDetails, insertedById, onSuccess, onFailure) => {
  console.log(DestinationDetails,"sen1709/")
  try {
    const res = await webApi.post("/master/destination/insert", {
      vehicle_id: DestinationDetails.vehicle_id,
      inserted_by_id: insertedById,
      received_time: DestinationDetails.received_time,
      unloading_time: DestinationDetails.unloading_time,
      received_date: DestinationDetails.received_date,
      inserted_by_date: timestamp(currentDate()),
      transaction_no:DestinationDetails.transaction_no,
      salesCumDispatch_no:DestinationDetails.salesCumDispatch_no,
      salse_dispatch_id:DestinationDetails.salse_dispatch_id,
      extra_expenses_amount:Number(DestinationDetails.extra_expenses_amount),
      advance_expense_amount:Number(DestinationDetails.advance_expense_amount),
    })
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
      const res2 = await webApi.post("/master/salesCumDispatch/update", {
        salse_dispatch_id:DestinationDetails.salse_dispatch_id,
        destination_status:true,
      });
      const Vehicleres = await webApi.post("/master/vehicle/update", {
        vehicle_id: DestinationDetails.vehicle_id,
        inTransit:false,
        trip_no:DestinationDetails.trip_no + 1
      });
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
}

export const getAllDestination = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/destination/list", {
    });
    if
      (res.status === 200) {
      const r = res.data;


      let all_advance = [];
      let count = 0;
      r.map((r, i) => {
        r._id ?
          all_advance.push({
            id: count + 1,
            vehicle_id: r.vehicle_id,
            vehicle_no: r.vehicle_no,
            received_time: r.received_time,
            received_date: r.received_date,
            unloading_time: r.unloading_time,
            action: "action",
          })

          : ''
      
  
        });

return onSuccess(all_advance);
      } else {
  onFailure("Something Wrong! Please Try again later" + res.data);
}
    } catch (error) {
  onFailure("Something Wrong! Please Try again later" + error);
}
  };

export const getSearchDestination = async (addSearch, onSuccess, onFailure) => {
  console.log(addSearch, "sankh25081")
  try {

    const res = await webApi.post("/master/destination/list", {
      vehicle_no: addSearch.txt_vehicle_no,
      // txt_from_date: timestamp(addSearch.txt_from_date),
      // txt_to_date: timestamp(addSearch.txt_to_date),

    });
    if
      (res.status === 200) {
      const r = res.data;

      let all_advance = [];
      r.map((r, i) => {
        console.log(r, "sen25")

        r._id ?
          all_advance.push({
            id: i + 1,
            vehicle_id: r.vehicle_id,
            vehicle_no: r.vehicle_no,
            received_time: r.received_time,
            received_date: r.received_date,
            unloading_time: r.unloading_time,
            action: "action",
          })
          : ''


      });

      return onSuccess(all_advance);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllAdvance = async (transaction_no,onSuccess, onFailure) => {
  try {

    const res = await webApi.post("/master/advance/list", {
      transaction_no:transaction_no,
    });
    if 
    (res.status === 200)
     {
      const r = res.data;
      let all_advance = [];
      r.map((r, i) => {
        all_advance.push({
          extra_expense_amount:r.extra_expense_amount,
        });
      });
      
      return onSuccess(all_advance[0]);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//expenses
export const getAllExpenses = async (onSuccess, onFailure,q) => {
  try {

    const res = await webApi.post("/master/expenses/list", {
      expenses_type_id: "E",

    });
    if 
    (res.status === 200)
     {
      const r = res.data;
       

      let all_expenses = [];
      r.map((r, i) => {
          all_expenses.push({
          value:r.expenses_id,
          label:r.expenses,
        });
      });
      return onSuccess(all_expenses);
      console.log(all_expenses,"sen1609")
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};