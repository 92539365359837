import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Select from "react-select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Box } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import clx from "classnames";
import theme from "../../../theme/theme";
import {
  deleteLedgerAccount,
  getAllLedgerAccount,
  postLedgerAccount,
  updateLedgerAccount,
  getAllLedgerGroup,
  getListLedgerAccount,
  getsearchAllLedgerAccount,
  getListLedgerAccountByGroupId,
  getAllLedgerGroupUsingFilter,
  getAllLedgerAccountChecking
} from "../../../services/LedgerAccountService";

import React from "react";
import {
  appDefaultColor,
  appSecondColor,
} from "assets/jss/material-dashboard-pro-react";
import { appDefaultFamily } from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { appFontWeight } from "assets/jss/material-dashboard-pro-react";
import { appLabelFont } from "assets/jss/material-dashboard-pro-react";
import { OutlinedInput } from "@material-ui/core";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PageTitle from "../HelperComponent/PageTitle";
import {

  reactSelectStyles,
} from "assets/jss/material-dashboard-pro-react";
import {
  currentDate,
  dateFormate,
  dateFormateField,
} from "../HelperComponent/utils";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactSelect from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import { Paper } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
// import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from '@material-ui/core/Button';
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import  FileSaver from 'file-saver';
import XLSX from 'xlsx'
const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  activeText: {
    ...activeText,
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "22px",
    },
  },

  helperText: {
    textAlign: "right",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const LedgerAccountPage = () => {
  const classes1 = useStyles1()
  const history = useHistory();
  const classes = useStyles();
  const [globalState, dispatch] = useStateValue();
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
 


  const [classicModal, setClassicModal] = React.useState(false);
  const [ledgerAccount, setAllLedgerAccount] = React.useState([]);
  const [ledgerGroup, setAllLedgerGroup] = React.useState([]);
  const [ledgerGroupFilter, setAllLedgerGroupFilter] = React.useState([]);

  const [openingBalance, setOpeningBalance] = React.useState({});
  const [viewRes, setViewRes] = React.useState("hidden");
  const [addSearch, setAddSearch] = React.useState({
    ddl_ledger_account: "",
    ddl_ledger_group: "",

  });
  const option = [
    { value: 1, label: "Dr" },
    { value: 2, label: "Cr" },
  ];
  const [collapsible, setCollapsible] = React.useState(true)
  const userRole = globalState?.user?.user_role;

  const [value, setValue] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);
  const [addLedgerAccount, setAddLedgerAccount] = React.useState({
    edit: false,
    ledger_account_id: "",
    switch_active_status: false,
    txt_ledger_account: "",
    txt_alias: "",
    txt_credit_limit: "",
    ddl_ledger_group: "",
    ddl_ledger_group_label: "Select",
    txt_opening_balance: "",
    ddl_drcr: "",
    ddl_drcr_label: "",
    dtp_date: currentDate(),
  });
  // Error handler state
  const [error, setError] = React.useState({
    txt_ledger_account: false,
  });

  const user_id = globalState.user?.serial_id;

  const formData = {
    formName: "Ledger Account",
    fields: [
      {
        name: "txt_ledger_account",
        label: "Ledger Account",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 12,
        md: 12,
        lg: 12,
      },
      {
        name: "txt_alias",
        label: "Alias",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 12,
        md: 12,
        lg: 12,
      },
      {
        name: "ddl_ledger_group",
        label: "Ledger Group",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "select",
        options: ledgerGroup,
        xs: 12,
        md: 12,
        lg: 12,
      },

      {
        name: "txt_opening_balance",
        label: "Opening Balance",
        hidden: false,
        required: false,
        data_type: "number",
        align: "right",
        html_element: "TextField",
        error: false,
        xs: 12,
        md: 4,
        lg: 4,
      },
      {
        name: "ddl_drcr",
        label: "Dr/Cr",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "select",
        options: option,

        xs: 6,
        md: 6,
        lg: 3,
      },
      {
        name: "dtp_date",
        label: "Date",
        hidden: false,
        required: false,
        data_type: "string",
        defaultValue: currentDate(),
        html_element: "date",
        xs: 6,
        md: 6,
        lg: 5,
      },
      {
        name: "txt_credit_limit",
        label: "Credit Limit",
        hidden: false,
        required: false,
        data_type: "number",
        align: "right",
        html_element: "TextField",
        error: false,
        xs: 12,
        md: 6,
        lg: 5,
      },
      {
        name: "switch_active_status",
        label: "Active Status",
        defaultValue: false,
        required: false,
        data_type: "string",
        html_element: "switch",
        error: false,
        xs: 12,
        md: 12,
        lg: 12,
      },
    ],
  };

  const onSelect = (info, v) => {
    console.log(info, "sen19/ifo");
    let abs = []
    setAddSearch({
      ...addSearch,
      [info.name]: v,
      ...(info.name === "ddl_ledger_group" ? 
      
      // { ddl_ledger_account: "" } 
      //ledger acc by g id
        getListLedgerAccountByGroupId(
          (r) => {
            console.log(r, "r for led acc")
            setAllLedgerAccount(r);
            setLoading(false);
          },
          (err) => {
            setLoading(false);
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          },v.value
        )
      : {}),
    });
    switch (info) {
      case "ddl_ledger_group":
        setAddLedgerAccount({
          ...addLedgerAccount,
          ddl_ledger_group: v.value,
          ddl_ledger_group_label: v.label,
        });
        console.log(v.value, "sen19/idl")
        
        break;
      default:
        break;
      case "ddl_drcr":
        setAddLedgerAccount({
          ...addLedgerAccount,
          ddl_drcr: v.label,
          ddl_drcr_label: v.label,
        });
        break;

    }
  };

  React.useEffect(() => {
    setLoading(true);

    // getsearchAllLedgerAccount
    // (
    //   (enquery) => {
    //     if (enquery.length) {
    //       setAllLedgerAccount(enquery);
    //       setViewRes("visible");
    //       setLoading(false);
    //     } else {
    //       setViewRes("visible");
    //       setLoading(false);
    //       dispatch({
    //         type: actionTypes.SET_OPEN_MSG,
    //         payload: { msg: err, "Ledger not found": "info" },
    //       });
    //     }
    //   },
    //   (err) => {
    //     setAllLedgerAccount([]);
    //     dispatch({
    //       type: actionTypes.SET_OPEN_MSG,
    //       payload: { msg: err, msgType: "error" },
    //     });

    //   },
    //   addSearch
    // );

///ledger acc all
    getListLedgerAccount(
      (r) => {
        console.log(r, "r for led acc")
        setAllLedgerAccount(r);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      },
    );

    getAllLedgerGroup(
      (r) => {
        setAllLedgerGroup(r);
        setLoading(false);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
    getAllLedgerGroupUsingFilter(
      (r) => {
        setAllLedgerGroupFilter(r);
        setLoading(false);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [!refresh]);

  const onSetActive = (e) => {
    setAddLedgerAccount((prv) => ({
      ...prv,
      [e.target.name]: e.target.checked,
    }));
  };

  const headerData = [
    {
      id: "id",
      label: "#",
    },

    {
      id: "ledgerAccount",
      label: "Ledger Account",
    },

    {
      id: "alias",
      label: "Alias",
      align: "left",
    },

    {
      id: "ledgerGroupName",
      label: "Ledger Group",
      align: "left",
    },

    {
      id: "openingBalanceView",
      label: "Opening Balance",
      align: "right",
    },
    {
      id: "as_on_date",
      label: "Date",
      align: "left",
    },
    {
      id: "creditLimit",
      label: "Credit Limit",
      align: "right",
    },
    {
      id: "status",
      label: "Status",
      align: "right",
      statusBtn: (v) => (v === "Y" ? true : false),
    },

    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];


  // onSubmit called
  const onSubmitModel = (e) => {   
    e.preventDefault();
// if(ledgerAccount.find((v , i)=> v.txt_ledger_account === e.txt_ledger_account)){
//   console.log(e,"sankaccount") 

//   dispatch({
//     type: actionTypes.SET_OPEN_MSG,
//     payload: { msg: "ledger already added", msgType: "info" },
//   });
// }else{
  
    if (!addLedgerAccount.txt_ledger_account) {
      setError({
        txt_ledger_account: addLedgerAccount.txt_ledger_account ? false : true,
      });
    } else {
      if (addLedgerAccount.edit) {
        updateLedgerAccount(
          addLedgerAccount,
          (r) => {
            onCloseModel();
            setRefresh(!refresh);
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Ledger Account Updated Successfully",
                msgType: "success",
              },
            });
            // setRefresh(!refresh);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          },
          user_id
        );
      } else {
        postLedgerAccount(
          addLedgerAccount,
          (r) => {
            // setRefresh(!refresh);
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Ledger Account added Successfully",
                msgType: "success",
              },
            });
            onCloseModel();
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
      setButtonDisabled(true);
     

          }
        );
      }
    }
  // }
  };
  // on Delete called
  const onDeleteLedgerAccount = (row, next) => {
    deleteLedgerAccount(
      row.ledger_account_id,
      (r) => {
        next();
        setRefresh(!refresh);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  // on Edit called
  const onEditLedgerAccount = (row) => {
    console.log(row, "sank0006")
    setClassicModal(true);
    setAddLedgerAccount({
      ...addLedgerAccount,
      edit: true,
      switch_active_status: row.status === "Y" ? true : false,
      ledger_account_id: row.ledger_account_id,
      txt_ledger_account: row.ledgerAccount,
      txt_alias: row.alias,
      txt_credit_limit: row.creditLimit,
      ddl_ledger_group: row.ledgerGroup,
      ddl_ledger_group_label: row.ledgerGroupName,
      txt_opening_balance: row.openingBalance,
      ddl_drcr: row.dr_cr_status,
      ddl_drcr_label: row.dr_cr_status,
      dtp_date: dateFormateField(row.as_on_date),
    });
  };
  // const onChange = (e) => {
  //   const { value, name } = e.target;
  //   setAddLedgerAccount({ ...addLedgerAccount, [name]: value });
  //   // getAllLedgerAccountChecking(
  //   //   console.log(value,"checkingledger"),
  //   //   value,
  //   //   (r) => {
  //   //   console.log(r[0],"checkingledger1")
        
  //   //     if(r[0]?.ledgerAccount === value){
  //   //       console.log("25253")
  //   //       dispatch({
  //   //         type: actionTypes.SET_OPEN_MSG,
  //   //         payload: { msg: "Ledger Already Present", msgType: "error" },
  //   //       });
  //   //     }
  //   //   }
  //   // )
  // };

  // const onBlur = (e) => {
  //   const { value, name } = e.target;
  //   setAddLedgerAccount({ ...addLedgerAccount, [name]: value });
  //   getAllLedgerAccountChecking(
  //     console.log(value,"checkingledger"),
  //     value,
  //     (r) => {
    
  
  //       if (r[0]?.ledgerAccount === value) {
  //         console.log("25253")
  //         dispatch({
  //           type: actionTypes.SET_OPEN_MSG,
  //           payload: { msg: "Ledger Already Present", msgType: "error" },
  //         });
  //       }
  //     }
  //   );
  // };


  const onBlur = (e) => {
    
    const { value, name } = e.target;
    console.log(value, "sanp", name)
    setAddLedgerAccount({ ...addLedgerAccount, [name]: value });
    getAllLedgerAccountChecking(      
      value,
      (r) => {  
        setButtonDisabled(false);

        if(r[0]?.ledgerAccount === value){
          console.log("25253")
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: "Ledger Already Present", msgType: "error" },
          });
          setButtonDisabled(true);
          console.log(buttonDisabled,"sankhatrue")

        }
      }
    )
  };
  
  const onChange = (e) => {
    const { name, value } = e.target;
    setAddLedgerAccount({ ...addLedgerAccount, [name]: value });
  };
  
  
  

  // on Close Model
  const onCloseModel = () => {
    setClassicModal(false);
    setError({});
    setAddLedgerAccount({
      edit: false,
      ledger_account_id: null,
      switch_active_status: false,
      txt_ledger_account: "",
      txt_alias: "",
      txt_credit_limit: 0,
      ddl_ledger_group: 0,
      ddl_ledger_group_label: "Select Ledger Group",
      txt_opening_balance: 0,
      dtp_date: currentDate(),
      ddl_drcr: "",
      ddl_drcr_label: "Dr/Cr",
    });
  };

  const onSearchLedgerAccountPage = (e) => {
    e.preventDefault();


    setLoading(true);

    getsearchAllLedgerAccount
      (
        (enquery) => {
          if (enquery.length) {
            setAllLedgerAccount(enquery);
            setViewRes("visible");
            setLoading(false);
          } else {
            setViewRes("visible");
            setLoading(false);
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, "Ledger not found": "info" },
            });
          }
        },
        (err) => {
          setAllLedgerAccount([]);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });

        },
        addSearch
      );

  };

  const onReset = () => {
     setAddSearch({
      ddl_ledger_account: "",
      ddl_ledger_group: "",

     })
     setViewRes("hidden")
    //  setAllLedgerGroup([])
    //  setAllLedgerAccount([])
  }

 // export to excel
 const onhandleExportToExcel = () => {
  const ledgerAccountTable = ledgerAccount.map(ledgerAccount => {
    return {
      id: ledgerAccount.id,
      ledgerAccount: ledgerAccount.ledgerAccount,
      alias: ledgerAccount.alias,
      ledgerGroupName: ledgerAccount.ledgerGroupName,
      openingBalance: ledgerAccount.openingBalanceView,
      as_on_date: ledgerAccount.as_on_date,
      creditLimit: ledgerAccount.creditLimit,
      status: "",
      action: ""
    }
  })

 
  
  const fileName= 'ledger Account'
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(ledgerAccountTable);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
}

const onClickCollaps = () => {
  collapsible ?
    setCollapsible(false)
    :
    setCollapsible(true)
}



  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Master > Account > Ledger Account"
        btnToolTip="Add Ledger Account"
        onClickAddBtn={() => setClassicModal(true)}
      />
      <GridContainer>
        <GridItem xs="12">
          <CustomCard cdTitle="Search Ledger List" 
          

            btnToolTip={collapsible?"Collaps":"Expand"}
            onClickCollapsible={onClickCollaps}
            buttonAction={collapsible}
          filterIcon>
            {
              collapsible ?
            <Paper elevation="0" className={classes.searchBar}>
              <GridContainer justifyContent="flex-start" alignItems="center">
                <GridItem xs="6">
                  <InputLabel id="label">Group</InputLabel>
                  <ReactSelect
                    options={ledgerGroup}

                    name="ddl_ledger_group"
                    // getOptionLabel={(option) => option.label}
                    placeholder="Select"
                    formatGroupLabel={(d) => d.label}
                    menuPortalTarget={document.body}
                    className={classes.customSelect}
                    styles={reactSelectStyles}
                    onChange={(v, info) => onSelect(info, v)}
                    value={addSearch.ddl_ledger_group}
                  />
                </GridItem>

                <GridItem xs="6">
                  <InputLabel id="label">Ledger Account</InputLabel>
                  <ReactSelect
                    options={ledgerAccount.filter(
                      (account) =>
                        account.ledger_group_id ===
                        addSearch.ddl_ledger_group.value
                    )}
                    // options={ledgerAccount}
                    placeholder="Select"
                    formatGroupLabel={(d) => d.label}
                    menuPortalTarget={document.body}
                    className={classes.customSelect}
                    styles={reactSelectStyles}
                    name="ddl_ledger_account"
                    onChange={(v, info) => onSelect(info, v)}
                    value={addSearch.ddl_ledger_account}
                  // value={ledgerAccount.filter(
                  //   (account) =>
                  //     account.ledger_group_id ===
                  //       addSearch.ddl_ledger_group.value
                  // )}
                  />
                </GridItem>





                {console.log("opening balance", openingBalance)}

                <GridItem xs="12">
                  <div
                    style={{
                      float: "right",
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <CustomButton
                      style={{ marginRight: "10px" }}
                      onClick={onSearchLedgerAccountPage}
                    >
                      <SearchIcon />
                    </CustomButton>
                    <CustomButton>
                      <RotateLeftIcon 
                      onClick={onReset} 
                      />
                    </CustomButton>
                  </div>
                </GridItem>
              </GridContainer>
            </Paper>
            :''}
          </CustomCard>
        </GridItem>
      </GridContainer>


      <GridContainer className={classes.root} style={{ "visibility": viewRes }}>
        <GridItem xs="12">
          <GridContainer justify="space-between" alignItems="center">
            <GridItem>
              <MasterModel
                classicModal={classicModal}
                onCloseModel={onCloseModel}
                width={480}
                height="auto"
                modelName="Ledger Account"
                okBtnText="Submit"
                model Name={
                  addLedgerAccount.edit
                    ? "Edit Ledger Account"
                    : "Add Ledger Account"
                }
               
                onClickOk={onSubmitModel}
                btnDisabled={buttonDisabled}

               


              >
                <div style={{ padding: "20px 10px", width: "100%" }}>
                  <GridContainer>
                    {formData.fields.map((item, key) => {
                      return (
                        <>
                          <GridItem
                            xs={item.xs}
                            md={item.md}
                            lg={item.lg}
                            key={key}
                          >
                            {item.html_element === "select" && (
                              <>
                                <InputLabel id="label">{item.label}</InputLabel>
                                <Select
                                  options={item.options}
                                  formatGroupLabel={(d) => d.label}
                                  // menuPortalTarget={document.body}
                                  className={classes.customSelect}
                                  onChange={(v) => onSelect(item.name, v)}
                                  value={{
                                    value: addLedgerAccount[item.name],
                                    label:
                                      addLedgerAccount[`${item.name}_label`],
                                  }}
                                />
                              </>
                            )}
                            {item.html_element === "TextField" && (
                              <>
                                <InputLabel required={item.required} id="label">
                                  {item.label}
                                </InputLabel>
                                <TextField
                                  size="small"
                                  required={item.required}
                                  placeholder={item.label}
                                  name={item.name}
                                  onChange={onChange}
                                  onBlur={onBlur}

                                  type={item.data_type}
                                  inputProps={{
                                    style: { textAlign: item.align },
                                  }}
                                  id="outlined-basic"
                                  error={error[item.name]}
                                  FormHelperTextProps={{
                                    className: classes.helperText,
                                  }}
                                  helperText={
                                    error[item.name]
                                      ? item.label + " is required"
                                      : ""
                                  }
                                  fullWidth={true}
                                  value={addLedgerAccount[item.name]}
                                  variant="outlined"
                                />
                              </>
                            )}
                            {item.html_element === "drcr" && (
                              <>
                                <InputLabel required={item.required} id="label">
                                  {item.label}
                                </InputLabel>
                                <Select
                                  options={item.options}
                                  formatGroupLabel={(d) => d.label}
                                  // menuPortalTarget={document.body}
                                  className={classes.customSelect}
                                  onChange={(v) => onSelect(item.name, v)}
                                  value={{
                                    value: addLedgerAccount[item.name],
                                    label:
                                      addLedgerAccount[`${item.name}_label`],
                                  }}
                                />
                              </>
                            )}
                            {item.html_element === "date" && (
                              <>
                                <InputLabel id="Date">{item.label}</InputLabel>
                                <TextField
                                  size="small"
                                  id="date"
                                  variant="outlined"
                                  type="date"
                                  name={item.name}
                                  defaultValue={item.defaultValue}
                                  FormHelperTextProps={{
                                    className: classes.helperText,
                                  }}
                                  helperText={
                                    error[item.name]
                                      ? item.label + " is required"
                                      : ""
                                  }
                                  fullWidth={true}
                                  value={addLedgerAccount[item.name]}
                                  onChange={onChange}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </>
                            )}

                            {item.html_element === "TextArea" && (
                              <>
                                <InputLabel id="label">{item.label}</InputLabel>
                                <TextField
                                  placeholder={item.label}
                                  name={item.name}
                                  onChange={onChange}
                                  multiline
                                  rows={5}
                                  FormHelperTextProps={{
                                    className: classes.helperText,
                                  }}
                                  helperText={
                                    error[item.name]
                                      ? item.label + " is required"
                                      : ""
                                  }
                                  id="outlined-basic"
                                  fullWidth={true}
                                  value={addLedgerAccount[item.name]}
                                  variant="outlined"
                                />
                              </>
                            )}
                            {item.html_element === "switch" && (
                              <>
                                <span className={classes.activeText}>
                                  {item.label}
                                </span>
                                <Switch
                                  onClick={onSetActive}
                                  checked={addLedgerAccount[item.name]}
                                  name={item.name}
                                  fullWidth={true}
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                  color="primary"
                                />
                              </>
                            )}
                          </GridItem>
                        </>
                      );
                    })}
                  </GridContainer>
                </div>
              </MasterModel>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs="12">
          {loading ? (
            <Box mt={10} width="100%" textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Card className={classes1.headerCard}>
            <CardHeader className={classes1.TbheaderCdhd} style={{ height: 60 }}>
                <GridContainer justifyContent="space-between" alignItems="center">
                  <GridItem>
                    <h4 className={classes1.headerCdTitle}>ledgerAccount</h4>
                  </GridItem>
                  <GridItem style={{ cursor: "pointer" }}>
                    <IconButton variant="text" onClick={() => onhandleExportToExcel(ledgerAccount)} >
                      <Tooltip title="Export to Excel">
                        <img src={require("../../../assets/img/excel.png").default} />
                      </Tooltip>
                    </IconButton>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody
                style={{ height: "auto", maxHeight: 480, padding: 10 }}
                className={clxs(classes.customScroolBar)}
              >
            
              <MuiTable
                onClickDelete={onDeleteLedgerAccount}
                onClickEdit={onEditLedgerAccount}
                columns={headerData}
                rows={ledgerAccount}
                userRole={userRole}

              />
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
};

export default LedgerAccountPage;
