

import { timestamp } from "./Utils/utils";
const moment = require("moment-timezone");

import { dateFormate, currentDate } from "views/Pages/HelperComponent/utils";
import webApi from "./webApi/webApi";
import { blackColor } from "assets/jss/material-dashboard-pro-react";




export const getExpiryDocument = async (q, onSuccess, onFailure) => {
    console.log(q, "sank040423")
    try {
      const res = await webApi.post("/reports/expiryDate/list", {
    
      });
  
      if (res.status === 200) {
        const r = res.data
        let expiryDocument = []
        console.log(Math.floor(Date.now()/1000 ) ,"sankcurrentDate1")


        r.map((r, i) => {
        console.log(r  ,"sankcurrentDate")

            expiryDocument.push({
                id: i + 1,
            vehicle_id: r._id,
            Expiry_vehicle_no: r.vehicle_no,
            document_expirations:r.document_expirations,
            
            Expiry_Document_name: `${Math.floor((r.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Insurance Number" : 
            `${Math.floor((r.pollution_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Polution Number" :
            `${Math.floor((r.permit_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Permit Number":
            `${Math.floor((r.fitness_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Fitness Number":
            `${Math.floor((r.loan_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Loan Number":
            `${Math.floor((r.bank_noc_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ?" Bank NOC Number": "-",

            Expiry_Document_no: `${Math.floor((r.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? r.insurance_number : 
            `${Math.floor((r.pollution_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? r.pollution_number :
            `${Math.floor((r.permit_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? r.permit_number:
            `${Math.floor((r.fitness_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? r.fitness_number:
            `${Math.floor((r.loan_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? r.loan_number:
            `${Math.floor((r.bank_noc_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? r.bank_noc_number: "-",


          
            Expiry_Document_date: `${Math.floor((r.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? dateFormate(r.insurance_expire_date) : 
            `${Math.floor((r.pollution_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? dateFormate(r.pollution_expiry_date) :
            `${Math.floor((r.permit_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? dateFormate(r.permit_expiry_date):
            `${Math.floor((r.fitness_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? dateFormate(r.fitness_expiry_date):
            `${Math.floor((r.loan_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? dateFormate(r.loan_date):
            `${Math.floor((r.bank_noc_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? dateFormate(r.bank_noc_date): "-",

            // Expiry_Document_date: dateFormate(r.insurance_expire_date),

            // Expiry_Document_days: `${Math.floor((r.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired" :  `${Math.floor((r.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400)} days`
            // `${Math.floor((r.pollution_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired" :  `${Math.floor((r.pollution_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days`
            // `${Math.floor((r.permit_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired":  `${Math.floor((r.permit_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days`
            // `${Math.floor((r.fitness_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired":  `${Math.floor((r.fitness_expiry_date - Math.floor(Date.now() / 1000)) / 86400)} days`
            // `${Math.floor((r.loan_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired":  `${Math.floor((r.loan_date - Math.floor(Date.now() / 1000)) / 86400)} days`
            // `${Math.floor((r.bank_noc_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired" :  `${Math.floor((r.bank_noc_date - Math.floor(Date.now() / 1000)) / 86400)} days`,
          
            Expiry_Document_days: `${Math.floor((r.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired"  : `${Math.floor((r.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400)} days`,
            // hours: Math.floor(((r.insurance_expire_date - Math.floor(Date.now() / 1000)) / 3600) % 24),
            // minutes: Math.floor(((r.insurance_expire_date - Math.floor(Date.now() / 1000)) / 60) % 60),
            // seconds: Math.floor((r.insurance_expire_date - Math.floor(Date.now() / 1000)) % 60),



    
          })
        })
  
        onSuccess(expiryDocument)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }