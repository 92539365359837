
import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate, timeToUnix, IstTime } from "../views/Pages/HelperComponent/utils";






export const getAllVehicleForDashboard = async (onSuccess, onFailure) => {
    try {
  
      const res = await webApi.post("/master/dashBoard/vehicle", {
        // vehicle_id:vehicle_id,
        // inTransit: false,
      });
      if
        (res.status === 200) {
        const r = res.data;
       
  
        let all_vehicles = [];
        r.map((r, i) => {
          all_vehicles.push({
  
            //for ddl
            value: r.vehicle_id,
            label: r.vehicle_no,
        
          });
        });
        return onSuccess(all_vehicles);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };


  export const getAllDashboardTracking = async (vehicle_id,onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/dashBoard/tracking", {
        vehicle_id:vehicle_id,
        // inTransit: false,
      });
      if
        (res.status === 200) {
        const r = res.data;
       
        console.log(res,"sen20122022")
  
        let all_Status = [];

        r.map((r, i) => {
            
            all_Status.push({
             
                value:r.vehicle_id,
                label: r.vehicle_no,
                inTransit:r.inTransit,
                oil_Status:r.oil_Status,
                trip_no:r.trip_no,
                transaction_no:r.transaction_no,
                Advance_Status:r.Advance_Status,
                loading_Status:r.loading_Status,
                Sales_cum_dispatches_Status:r.Sales_cum_dispatches_Status
        
          });
        });
        return onSuccess(all_Status[0]);

      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };


  export const getLoading = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashboardloading",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let loading = []
        r.map((r,i)=>{
          console.log(r,"sank2502")
          loading.push({
            totalTripCount:r.totalTripCount,
          
          })
        })
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }

  export const getLoadingTotal = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashboardloadingTotal",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let loading = []
        r.map((r,i)=>{
          console.log(r,"sank2502")
          loading.push({
            totalTripCount:r.totalTripCount,
          
          })
        })
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }

  export const getUnLoadingTotal = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashboardunloading",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let Unloading = []
        r.map((r,i)=>{
          console.log(r,"sank2502")
          Unloading.push({
            totalTripCount:r.totalTripCount,
          
          })
        })
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }


  export const getReceivedTotal = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashboardReceived",{
  
      });
  
      if(res.status === 200){
        const r = res.data
      
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }


  export const getReceivedToday = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashboardReceivedToday",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }

  
  export const getExpensesToday = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashBoardExpesesToday",{
  
      });
  
      if(res.status === 200){
        const r = res.data
       
       
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }

  export const getExpensesTotal = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashBoardExpesesTotal",{
  
      });
  
      if(res.status === 200){
        const r = res.data
       
       
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }


  export const totalOiled = async (onSuccess, onFailure) => {

    try {
      const res = await webApi.post("/master/oil/totalOiled", {});
  
      if (res.status === 200) {
        const r = res.data
        onSuccess(r[0])
      }
      else {
        onFailure("Something Wrong! Please Try again later");
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  }



  export const getTotalCashInHand = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/cashInHand",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let received = []
        r.map((r,i)=>{
          console.log(r,"sank2502")
          received.push({
            closing_balance:r.closing_balance,
           
          
          })
        })
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }

  export const getTotalBank = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/bank",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let received = []
        r.map((r,i)=>{
          console.log(r,"sank2502")
          received.push({
            
            closing_balance:r.closing_balance,
          
          })
        })
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }



  export const getInTransit = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashBoardReportIntransit",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let inTransit = []
        r.map((r,i)=>{
          console.log(r,"sank2502")
          inTransit.push({
            totalTripCount:r.totalTripCount,
          
          })
        })
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }


  export const getMaintanceToday = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashBoardReportMaintanceToday",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let received = []
        r.map((r,i)=>{
          console.log(r,"sank2502")
          received.push({
            amount_today:r.amount_today,
            Labour_today:r.Labour_today,
         
          
          })
        })
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }

  export const getMaintanceTotal = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashBoardReportMaintanceTotal",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let received = []
        r.map((r,i)=>{
          console.log(r,"sank2502")
          received.push({
            // amount_total:r.amount_total,
            // Labour_total:r.Labour_total,

            closing_balance:r.closing_balance,
            
          })
        })
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }


  export const getBreakDown = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashBoardReportBreakDown",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let inTransit = []
        r.map((r,i)=>{
         
          inTransit.push({
            totalTripCount:r.totalTripCount,
          
          })
        })
       
        onSuccess(inTransit)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }


  export const getTodayExtraPayment = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashBoardTodayExtraPayment",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let received = []
        r.map((r,i)=>{
          console.log(r,"sank2502")
          received.push({
            
            closing_balance:r.closing_balance,
          
          })
        })
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }

  export const getTotalExtraPayment = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashBoardTotalExtraPayment",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let received = []
        r.map((r,i)=>{
          console.log(r,"sank2502")
          received.push({
            
            closing_balance:r.closing_balance,
          
          })
        })
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }


  export const getOutStanding = async(onSuccess,onFailure)=>{
    try {
      const res = await webApi.post("/master/dashboardOutstanding",{
  
      });
  
      if(res.status === 200){
        const r = res.data
        let outstanding = []
        r.map((r,i)=>{
          console.log(r,"sank2502")
          outstanding.push({
            outstanding:r.outstanding,
          
          })
        })
       
        onSuccess(res.data)
      }
    } catch (error) {
      onFailure("Something Went Wrong!!!!" + error)
    }
  }