import React, { useEffect, useState } from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/addIemPageStyle.js";
import TextField from "@material-ui/core/TextField";
import { Input, Box, Paper } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import Select from "react-select";
// import React from "react";
import { useStateValue } from "../../../../context/context";
import { actionTypes } from "../../../../context/reducer";
import { useHistory, useLocation } from "react-router-dom";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import PageTitle from "../../HelperComponent/PageTitle";
import { CustomCard } from "../../../Components/CustomCard";
import MasterModel from "../../../Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";

import theme from "../../../../theme/theme";
import {
  currentDate,
  currentDate1,
  IstTime,
} from "views/Pages/HelperComponent/utils";

import { Grid } from "@material-ui/core";
import { ViewAdvanceDetails } from "../../../../services/advanceService";
import CustomButton from "views/Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import MuiTable from "../../../Components/MuITable";
import { number } from "fx/config";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { currentTime } from "views/Pages/HelperComponent/utils";
import { ColoseButton } from "views/Components/CustomButton";
import { dateFormate } from "views/Pages/HelperComponent/utils";

const useStyles = makeStyles(styles);

const useStyles1 = makeStyles((styles) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const AdvanceView = () => {
  const [globalState, dispatch] = useStateValue();
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();
  const classs = useStyles1();
  const [classicModal, setClassicModal] = useState(false);

  const [advanceLoaction, setAdvanceLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [classicModel, setClassicModel] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [vehicle, setAllVehicle] = useState([]);
  const [trip, setTrip] = useState(1);
  const [addSearch, setAddSearch] = useState({
    txt_vehicle_no: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });
  const [refresh, setRefresh] = useState(false);
  const [advanceDetails, setAllAdvanceDetails] = useState([]);
  const [advance, setAdvance] = useState({
    edit: false,
    txt_Extra_exp: 0,
    ddl_office_id: "",
    ddl_office_label: "select",
    txt_actual_expense: 0,
    txt_trip_no: 0,
    ddl_office_id: "",
    ddl_office_label: "select",
    txt_Expense: 0,
    ddl_vehicle_no_id: "",
    ddl_vehicle_no_label: "",
    ddl_office_id: "",
    ddl_office_label: "select",
    txt_Remarks: "",
    switch_active_status: true,
    advance_order_no: "",
    transaction_no: "",
    oil_order_no: "",
    oil_id: "",
    txt_advance_time: currentTime(),
    ddl_paymode_id: "",
    ddl_paymode_label: "",
    ddl_account_id: "",
    ddl_account_label: "",
    txt_Excess_Pay_Oil: "",
    txt_Actual_Pay: "",
  });
  const [error, setError] = useState({
    txt_code: false,
    ddl_office_id: false,
    ddl_location_id: false,
    ddl_vehicle_no_id: false,
    txt_Extra_exp: false,
    ddl_expenses_id: false,
  });
  const [expenseExtra, setExpensesExtra] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState([]);
  const [collapsible, setCollapsible] = useState(true);
  const [collapsibleAdvance, setCollapsibleAdvance] = useState(true);
  const [collapsiblePay, setCollapsiblePay] = useState(true);

  const onClickCollaps = () => {
    console.log("Reached23");
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };

  const onClickCollapsAdvance = () => {
    console.log("Reached23");
    collapsibleAdvance
      ? setCollapsibleAdvance(false)
      : setCollapsibleAdvance(true);
  };

  const onClickCollapsPay = () => {
    console.log("Reached23");
    collapsiblePay ? setCollapsiblePay(false) : setCollapsiblePay(true);
  };

  const onCloseModel = () => {
    setClassicModal(false);
    setError({ txt_code: false });
    setAdvance({
      ...advance,
      switch_active_status: false,
      txt_code: "",
    });
    history.push({
      pathname: "/admin/tranportation/Advance",
    });
  };

  const onChange = (e) => {
    console.log(e, "sank18064");
    const { value, name } = e.target;
    setAdvance({ ...advance, [name]: value });
  };

  const onSelectLocationAdvance = (v) => {
    setAdvance({
      ...advance,
      ddl_office_id: v.value,
      ddl_office_label: v.label,
    });
  };

  const onSelectPayMode = (v) => {
    setAdvance({
      ...advance,
      ddl_paymode_id: v.value,
      ddl_paymode_label: v.label,
    });
  };
  const onSelectAccount = (v) => {
    setAdvance({
      ...advance,
      ddl_account_id: v.value,
      ddl_account_label: v.label,
    });
  };
  const onSelectVehicle = (v) => {
    console.log(v, "sen1709");

    setAdvance({
      ...advance,
      ddl_vehicle_no_id: v.value,
      ddl_vehicle_no_label: v.label,
      transaction_no: v.transaction_no,
      oil_order_no: v.oil_order_no,
      oil_id: v.oil_id,
      txt_trip_no: v.trip_no,
      routes: v.routes,
      routes_name: v.routes_name,
      txt_Expense: v.route_amount,
    });
  };

  //   const onSearchAdvance = (e) => {
  //     e.preventDefault();
  //     setRefresh(false);
  //     setLoading(true);

  //     getAllAdvance(
  //       addSearch,
  //       (advanceDetails) => {
  //         console.log(advanceDetails, "sank/19081")
  //         if (advanceDetails.length) {
  //           setAllAdvanceDetails(advanceDetails);
  //         }
  //         else {
  //           dispatch({
  //             type: actionTypes.SET_OPEN_MSG,
  //             payload: { msg: "Advance Details Not Found", msgType: "error" },
  //           });
  //         }
  //       },
  //       (err) => {
  //         dispatch({
  //           type: actionTypes.SET_OPEN_MSG,
  //           payload: { msg: err, msgType: "error" },
  //         });
  //       }
  //     );

  //   };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddSearch({
      txt_vehicle_no: "",
      txt_from_date: currentDate1(),
      txt_to_date: currentDate(),
    });
  };

  React.useEffect(() => {
    ViewAdvanceDetails(
      location.advance_id,
      (r) => {
        console.log(r, "sankadvvuei");
        setAdvance(r);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Transportation   >  Advance "
        // btnToolTip="Add New Advance"
        // onClickAddBtn={onClickAddBtn}
      />
      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <CustomCard cdTitle="Advance " filterIcon onClickFilter={() => {}}>
            <GridContainer
              style={{ padding: "10px" }}
              justify="space-between"
              alignItems="center"
            >
              <CustomCard
                cdTitle=" Truck Details"
                btnToolTip={collapsible ? "Collaps" : "Expand"}
                onClickCollapsible={onClickCollaps}
                buttonAction={collapsible}
                filterIcon
              >
                {collapsible ? (
                  <GridContainer justify="space-between" alignItems="center">
                    <GridItem xs="2">
                      <InputLabel
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Truck No
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={advance.vehicle_no}
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                          },
                          fontWeight: "bold",
                        }}
                      />
                    </GridItem>
                    <GridItem xs="2">
                      <InputLabel
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Trip No
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={advance.trip_no}
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xs="3">
                      <InputLabel
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Routes
                      </InputLabel>
                      <TextField
                        size="small"
                        value={advance.route}
                        fullWidth={true}
                        inputProps={{
                          style: {
                            textAlign: "center",
                            color: "green",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="5">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Routes Name
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={advance.route_name}
                        inputProps={{
                          style: {
                            textAlign: "center",
                            color: "green",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
              </CustomCard>
              <CustomCard
                cdTitle="Advance Details"
                btnToolTip={collapsibleAdvance ? "Collaps" : "Expand"}
                onClickCollapsible={onClickCollapsAdvance}
                buttonAction={collapsibleAdvance}
                filterIcon
              >
                {collapsibleAdvance ? (
                  <GridContainer justify="space-between" alignItems="center">
                    <GridItem xs={2}>
                      <InputLabel
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Expense
                      </InputLabel>
                      <TextField
                        size="small"
                        inputProps={{
                          style: {
                            textAlign: "right",
                            color: "green",
                            fontWeight: "bold",
                          },
                        }}
                        fullWidth={true}
                        value={advance.expense}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <InputLabel id="label">Excess Pay Oil</InputLabel>
                      <TextField
                        size="small"
                        placeholder="oil Extra"
                        name="txt_Excess_Pay_Oil"
                      
                        inputProps={{
                          style: { textAlign: "right", color: "green" ,fontWeight: "bold",},
                        }}
                        id="outlined-basic"
                        fullWidth={true}
                        value={advance.Excess_Pay_Oil}
                        // variant="outlined"
                        disabled
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <InputLabel id="label">Actual Pay</InputLabel>
                      <TextField
                        size="small"
                        placeholder="Actual amount"
                        name="txt_Actual_Pay"
                     
                        inputProps={{
                          style: { textAlign: "right", color: "green",fontWeight: "bold", },
                        }}
                        id="outlined-basic"
                        fullWidth={true}
                        value={advance.Actual_Pay}
                        // variant="outlined"
                        disabled
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <InputLabel
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {" "}
                        Approved Amount
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={advance.actual_expense}
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <InputLabel
                        required={true}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Advance from Office
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={advance.advance_office_label}
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <InputLabel
                        required={true}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Time
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={IstTime(advance.advance_time)}
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <InputLabel
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Remarks
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={advance.remarks}
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xs={4}></GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
              </CustomCard>
              <CustomCard
                cdTitle="Payment Details"
                btnToolTip={collapsiblePay ? "Collaps" : "Expand"}
                onClickCollapsible={onClickCollapsPay}
                buttonAction={collapsiblePay}
                filterIcon
              >
                {collapsiblePay ? (
                  <GridContainer justify="space-between" alignItems="center">
                    <GridItem xs={2}>
                      <InputLabel
                        required={true}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Driver
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        // value={advance.paymode}
                        value="need to be fetched"
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <InputLabel
                        required={true}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Payment Mode
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={advance.paymode}
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <InputLabel
                        required={true}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Cheque No
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={advance.cheque_no}
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </GridItem>

                    <GridItem xs={3}>
                      <InputLabel
                        required={true}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Account Name
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={advance.account_name}
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <InputLabel
                        required={true}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Advance Date
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        // value={advance.paymode}
                        value={dateFormate(advance.advance_date)}
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xs={4}></GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
              </CustomCard>
              <div style={{ width: "100%" }}>
                <ColoseButton
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    float: "right",
                    width: "10%",
                    marginRight: "10px",
                    border: "none",
                  }}
                  onClick={onCloseModel}
                >
                  close
                </ColoseButton>
              </div>
            </GridContainer>
          </CustomCard>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
};

export default AdvanceView;
