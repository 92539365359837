import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import {
  dateFormate,
  timeToUnix,
  IstTime,
} from "../views/Pages/HelperComponent/utils";

export const postOil = async (
  oilDetails,
  journal_details,
  inserted_by_id,
  onSuccess,
  onFailure
) => {
  try {
    console.log(oilDetails, "sen19102022");
    const res = await webApi.post("master/oil/insert", {
      vehicle_id: oilDetails.ddl_vehicle_no_id,
      vehicle_no: oilDetails.ddl_vehicle_no_label,
      // trip_no:oilDetails.trip_no,
      distance_cover: oilDetails.traveled_distance,
      km_start_reading: oilDetails.km_start_reading,
      km_end_reading: oilDetails.km_end_reading,
      approved_oil: Number(oilDetails.actual_qty),
      purchase_oil: oilDetails.actual_qty,
      rate: oilDetails.rate,
      total_amount: oilDetails.total_amount,
      oil_date: timestamp(oilDetails.oil_date),
      petrol_pump_id: oilDetails.ddl_petrol_pump_id,
      petrol_pump: oilDetails.ddl_petrol_pump_label,
      routes_id: oilDetails.ddl_routes_id,
      routes: oilDetails.ddl_routes_label,
      routes_name: oilDetails.routes_name,
      fule_bill_no: oilDetails.fule_bill_no,
      fule_bill_date: timestamp(oilDetails.fule_bill_date),
      fule_bill_time: timeToUnix(oilDetails.fule_bill_time),
      cheque_no: oilDetails.cheque_no,
      meter_image_path: oilDetails.meter_image_path,
      meter_image_name: oilDetails.meter_image_name,
      bill_attachment_path: oilDetails.bill_attachment_path,
      bill_attachment_name: oilDetails.bill_attachment_name,
      pump_meter_image_path: oilDetails.pump_meter_image_path,
      pump_meter_image_name: oilDetails.pump_meter_image_name,
      inserted_by_id: inserted_by_id,
      payMode_id: oilDetails.ddl_paymode_id,
      payMode: oilDetails.ddl_paymode_label,
      account_id: oilDetails.ddl_account_id,
      account_name: oilDetails.ddl_account_label,
      actual_Amount: oilDetails.actual_Amount,
      difference_Amount: oilDetails.difference_Amount,
      txt_remarks: oilDetails.txt_remarks,
    });

    const Vehicleres = await webApi.post("/master/vehicle/update", {
      vehicle_id: oilDetails.ddl_vehicle_no_id,
      inTransit: true,
      max_traveled_distance: Number(oilDetails.max_traveled_distance),
      km_start: Number(oilDetails.max_traveled_distance),
      trip_no: Number(oilDetails.total_trip_no) + 1,
    });

    const ledgerUpdate = await webApi.post(
      "/master/ledger_account/updateLedgerClosing",
      {
        ledger_account_id: oilDetails.ddl_account_id,
        amount: -Number(oilDetails.total_amount),
      }
    );
    if (res.status === 200) {
      const r = res.data;

      const journalData = await webApi.post("/master/journal/insert", {
        journal_type: "J",
        transaction_id: r?.transaction_no,
        transaction_type: "Oil",
        transaction_type_id: r?.oil_order_no,
        narration: `Being Oil Purchase From M/S ${
          journal_details[0].ledger
        } as on Date ${dateFormate(timestamp(oilDetails.fule_bill_date))}`,
        voucher_amount: Number(oilDetails.total_amount),
        voucher_date: timestamp(oilDetails.fule_bill_date),
        journal_details: journal_details,
        // active_status: journal.switch_active_btn ? "Y" : "N",
      });

      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updateOil = async (
  oilDetails,
  updatedJournalDetails,
  inserted_by_id,
  journal,
  onSuccess,
  onFailure
) => {
  try {
    console.log(oilDetails, "sen19102022");
    const res = await webApi.post("master/oil/update", {
      oil_id: oilDetails.oil_id,
      vehicle_id: oilDetails.ddl_vehicle_no_id,
      vehicle_no: oilDetails.ddl_vehicle_no_label,
      // trip_no:oilDetails.trip_no,
      distance_cover: oilDetails.traveled_distance,
      km_start_reading: oilDetails.km_start_reading,
      km_end_reading: oilDetails.km_end_reading,
      approved_oil: Number(oilDetails.actual_qty),
      purchase_oil: oilDetails.actual_qty,
      rate: oilDetails.rate,
      total_amount: oilDetails.total_amount,
      oil_date: timestamp(oilDetails.oil_date),
      petrol_pump_id: oilDetails.ddl_petrol_pump_id,
      petrol_pump: oilDetails.ddl_petrol_pump_label,
      routes_id: oilDetails.ddl_routes_id,
      routes: oilDetails.ddl_routes_label,
      routes_name: oilDetails.routes_name,
      fule_bill_no: oilDetails.fule_bill_no,
      fule_bill_date: timestamp(oilDetails.fule_bill_date),
      fule_bill_time: timeToUnix(oilDetails.fule_bill_time),
      cheque_no: oilDetails.cheque_no,
      meter_image_path: oilDetails.meter_image_path,
      meter_image_name: oilDetails.meter_image_name,
      bill_attachment_path: oilDetails.bill_attachment_path,
      bill_attachment_name: oilDetails.bill_attachment_name,
      pump_meter_image_path: oilDetails.pump_meter_image_path,
      pump_meter_image_name: oilDetails.pump_meter_image_name,
      edited_by_id: inserted_by_id,
      payMode_id: oilDetails.ddl_paymode_id,
      payMode: oilDetails.ddl_paymode_label,
      account_id: oilDetails.ddl_account_id,
      account_name: oilDetails.ddl_account_label,
      actual_Amount: oilDetails.actual_Amount,
      difference_Amount: oilDetails.difference_Amount,
      txt_remarks: oilDetails.txt_remarks,
    });

    // const Vehicleres = await webApi.post("/master/vehicle/update", {
    //   vehicle_id: oilDetails.ddl_vehicle_no_id,
    //   inTransit: true,
    //   max_traveled_distance: Number(oilDetails.max_traveled_distance),
    //   km_start: Number(oilDetails.max_traveled_distance),
    //   // trip_no: Number(oilDetails.total_trip_no) + 1,
    // });

    // const ledgerUpdate = await webApi.post("/master/ledger_account/updateLedgerClosing", {
    //   ledger_account_id: oilDetails.ddl_account_id,
    //   amount: -Number(oilDetails.total_amount)
    // })
    if (res.status === 200) {
      const r = res.data;
      console.log(journal, "sankhajournal");
      const journalData = await webApi.post("/master/journal/update", {
        journal_id: journal[0]?.journal_id,
        journal_type: "J",
        transaction_id: r?.transaction_no,
        transaction_type: "Oil",
        transaction_type_id: r?.oil_order_no,
        narration: `Being Oil Purchase From M/S ${
          updatedJournalDetails[0]?.ledger
        } as on Date ${dateFormate(timestamp(oilDetails.fule_bill_date))}`,
        voucher_amount: Number(oilDetails.actual_Amount),
        voucher_date: timestamp(oilDetails.fule_bill_date),
        journal_details: updatedJournalDetails,
        // active_status: journal.switch_active_btn ? "Y" : "N",
      });

      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllOil = async (addSearch, onSuccess, onFailure) => {
  console.log(addSearch, "sen20102022");

  try {
    const res = await webApi.post("/master/oil/list", {
      keyword: addSearch.txt_keyword,
      vehicle_no: addSearch.txt_vehicle_no,
      fromDate: timestamp(addSearch.txt_from_date),
      toDate: timestamp(addSearch.txt_to_date),
    });
    if (res.status === 200) {
      const r = res.data;

      let all_oil = [];
      r.map((r, i) => {
        console.log(r, "sen20102022");
        all_oil.push({
          id: i + 1,
          oil_id: r.oil_id,
          oil_order_no: r.oil_order_no,
          vehicle_no: r.vehicle_no,
          petrol_pump: r.petrol_pump,
          fule_bill_no: r.fule_bill_no,
          fule_bill_date: dateFormate(r.fule_bill_date),
          km_start_reading: r.km_start_reading,
          km_end: r.km_end_reading,
          purchase_oil: r.purchase_oil,
          total_amount: r.actual_Amount,
          rate: r.rate.toFixed(2),
          action_items: r.action_items,
          distance_cover: r.distance_cover,
          transaction_no: r.transaction_no,
          oil_order_no: r.oil_order_no,
          action: "action",
        });
      });

      return onSuccess(all_oil);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getOil = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/oil/viewDetails", {
      //  vehicle_id:q.txt_vehicle_no,
      oil_id: id,
    });
    if (res.status === 200) {
      const r = res.data;

      // let all_oil = [];
      // r.map((r, i) => {
      //   console.log(r, "sen20102022")
      //   all_oil.push({
      //     id: i + 1,
      //     oil_id: r.oil_id,
      //     oil_order_no: r.oil_order_no,
      //     vehicle_no: r.vehicle_no,
      //     petrol_pump: r.petrol_pump,
      //     fule_bill_no: r.fule_bill_no,
      //     fule_bill_date: dateFormate(r.fule_bill_date),
      //     km_start_reading: r.km_start_reading,
      //     purchase_oil: r.purchase_oil,
      //     rate: (r.rate).toFixed(2),
      //     action_items: r.action_items,
      //     action: "action",

      //   });

      // });

      return onSuccess(r[0]);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllVehicleById = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/oil/vehicle/list", {
      inTransit: false,
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "sen19102022/list");

      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({
          //for ddl
          value: r.vehicle_id,
          label: r.vehicle_no,
          total_trip_no: r.trip_no,
          km_start: r.km_start,
          km_end: r.km_end,
          mileage: r.mileage,
          no_of_wheels: r.no_of_wheels,
          vehicle_type: r.vehicle_type,
          insurance_expire_date: r.insurance_expire_date,
          pollution_expiry_date: r.pollution_expiry_date,
          permit_expiry_date: r.permit_expiry_date,
          fitness_expiry_date: r.fitness_expiry_date,
          loan_date: r.loan_date,
          bank_noc_date: r.bank_noc_date,
        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postFileUpload = async (file, onSuccess, onFailure) => {
  console.log("sen20102022/post", file);

  let formData = new FormData();
  formData.set("file", file);
  try {
    const res = await webApi.post(
      "https://api.gstspl.com/files/upload?uploadpath=image",
      formData
    );
    console.log("sen20102022/image", res);
    if (res.status === 200) {
      const r = res.data;

      if (r.success) {
        onSuccess(r);
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllPetrolPumpById = async (onSuccess, onFailure) => {
  // console.log(routeId,"sen1236")
  try {
    const res = await webApi.post("/master/oil/petrolPump/list", {
      // routes_id: Number(routeId),
    });
    if (res.status === 200) {
      const r = res.data;
      let all_petrol_pump = [];
      r.map((r, i) => {
        all_petrol_pump.push({
          id: i + 1,
          value: r.petrol_pump_id,
          label: r.petrol_pump,
          oil_rate: r.rate,
        });
      });
      return onSuccess(all_petrol_pump);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllOilView = async (id, oil_order_no, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/oil/list", {
      oil_id: id,
      oil_order_no: oil_order_no,
    });
    if (res.status === 200) {
      const r = res.data;

      let all_oil = [];
      r.map((r, i) => {
        all_oil.push({
          id: i + 1,
          oil_id: r.oil_id,
          oil_order_no: r.oil_order_no,
          vehicle_no: r.vehicle_no,
          petrol_pump: r.petrol_pump,
          fule_bill_no: r.fule_bill_no,
          fule_bill_date: dateFormate(r.fule_bill_date),
          km_start_reading: r.km_start_reading,
          actual_Quantity: r.actual_qty,
          rate: r.rate,

          action_items: r.action_items,
          action: "view-action",
        });
      });

      return onSuccess(all_oil);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const totalOiled = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/oil/totalOiled", {});

    if (res.status === 200) {
      const r = res.data;
      onSuccess(r[0]);
    } else {
      onFailure("Something Wrong! Please Try again later");
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getRoutes = async (wheels, vehicle_type, onSuccess, onFailure) => {
  // console.log(wheels,"sen20122022",vehicle_type)
  try {
    const res = await webApi.post("/master/routes/list", {
      wheels: Number(wheels),
      vehicle_type: vehicle_type,
    });

    if (res.status === 200) {
      const r = res.data;
      console.log(r, "sen11102022/r");

      let data = [];
      r.map((a, b) => {
        data.push({
          id: b + 1,
          value: a.route_id,
          label: a.route_no,
          routes_name:
            a.loading_loaction +
            " ==> " +
            a.delivery_location +
            "," +
            a.wheel +
            "Wheels",
        });
      });
      return onSuccess(data);
    } else {
      onFailure("Something Wrong! Please Try again later");
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const ViewOilDetails = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/oil/viewDetails", {
      oil_id: id,
    });

    if (res.status === 200) {
      const r = res.data;
      return onSuccess(r[0]);
    } else {
      onFailure("Something Wrong! Please Try again later");
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getBankAccount = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post(
      "/master/ledger_account/viewBankLedgerAccount",
      {}
    );

    if (res.status === 200) {
      const r = res.data;
      let allData = [];
      r.map((r, i) => {
        allData.push({
          label: r.ledger_account,
          value: r.ledger_account_id,
        });
      });

      return onSuccess(allData);
    }
    console.log(res.data, "sen2911");
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getLedgerAccount = async (type_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/ledger_account/search", {
      typeId: type_id,
    });

    if (res.status === 200) {
      const r = res.data;

      console.log(r, "sen0112022");

      onSuccess(r[0]);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getVehicleByIdExpiry = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/oil/expiryDate_List", {
      inTransit: false,
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "sen19102022/list");

      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({
          //for ddl
          value: r.vehicle_id,
          label: r.vehicle_no,
          document_expirations: r.document_expirations,
        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getJournalByOil = async (q, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/journal_id/oil/list", {
      oil_order_no: q,
    });
    if (res.status === 200) {
      const r = res.data;

      return onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

// export const getAllVehicleById = async (onSuccess, onFailure) => {
//   try {
//     const res = await webApi.post("/master/oil/vehicle/list", {
//       inTransit: false,
//     });
//     if (res.status === 200) {
//       const r = res.data;
//       console.log(r, "sen19102022/list")

//       let all_vehicles = [];
//       // const currentDate = new Date();
//       const twoDaysFromNow = Math.floor(new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).getTime() / 1000);

//       r.map((r, i) => {

//         const expiryDate = new Date(r.insurance_expire_date || r.pollution_expiry_date || r.permit_expiry_date || r.fitness_expiry_date
//                                      || r.loan_date || r.bank_noc_date);
//         if (expiryDate < twoDaysFromNow) {

//           console.log(`Vehicle ${r.vehicle_no} has an expired expiry_date.`,"sankTest");
//         }

//         all_vehicles.push({
//           //for ddl
//           value: r.vehicle_id,
//           label: r.vehicle_no,
//           total_trip_no: r.trip_no,
//           km_start: r.km_start,
//           km_end: r.km_end,
//           mileage: r.mileage,
//           no_of_wheels: r.no_of_wheels,
//           vehicle_type: r.vehicle_type,
//         });
//       });

//       return onSuccess(all_vehicles);
//     } else {
//       onFailure("Something Wrong! Please Try again later" + res.data);
//     }
//   } catch (error) {
//     onFailure("Something Wrong! Please Try again later" + error);
//   }
// };

// test for vehicle expiry date condition for checking

// export const getAllVehicleById1 = async (onSuccess, onFailure) => {
//   try {
//     const res = await webApi.post("/master/oil/vehicle/list", {
//       inTransit: false,
//     });
//     if (res.status === 200) {
//       const r = res.data;
//       console.log(r, "sen19102022/list");

//       let all_vehicles = [];
//       let hasExpiredDocuments = false; // Flag to track if any vehicle has expired documents

//       const twoDaysFromNow = Math.floor(new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).getTime() / 1000);

//       r.forEach((r) => {
//         const expiryDate = new Date(
//           r.insurance_expire_date ||
//             r.pollution_expiry_date ||
//             r.permit_expiry_date ||
//             r.fitness_expiry_date ||
//             r.loan_date ||
//             r.bank_noc_date
//         );
//         if (expiryDate < twoDaysFromNow) {
//           console.log(`Vehicle ${r.vehicle_no} has an expired expiry_date.`, "sankTest");
//           hasExpiredDocuments = true; // Set the flag to true if any vehicle has expired documents
//         }

//         all_vehicles.push({
//           //for ddl
//           value: r.vehicle_id,
//           label: r.vehicle_no,
//           total_trip_no: r.trip_no,
//           km_start: r.km_start,
//           km_end: r.km_end,
//           mileage: r.mileage,
//           no_of_wheels: r.no_of_wheels,
//           vehicle_type: r.vehicle_type,
//         });
//       });

//       return onSuccess(all_vehicles, hasExpiredDocuments); // Pass both the vehicle list and the flag
//     } else {
//       onFailure("Something Wrong! Please Try again later" + res.data);
//     }
//   } catch (error) {
//     onFailure("Something Wrong! Please Try again later" + error);
//   }
// };
