import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import Popover from "@material-ui/core/Popover";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import MessageIcon from "@material-ui/icons/Message";
// import NotificationsIcon from "@material-ui/icons/NotificationsOff";
import IconButton from "@material-ui/core/IconButton";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Avatar from "@material-ui/core/Avatar";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { useHistory } from "react-router-dom";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import ChangePasswordPage from "../../views/Pages/ChangePasswordPage";
import { UserLogOut } from "../../services/AuthService";
import { useStateValue } from "../../context/context";
import { actionTypes } from "../../context/reducer";
import { decodeToken, useJwt, isExpired } from "react-jwt";
import {
  currentDate,
  currentDate1,
  currentDate1Pdf,
  currentDatePdf,
} from "../../views/Pages/HelperComponent/utils";

import { useCookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { getExpiryDocument } from "services/vehicleExpiryDocService";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getAllVehicleView } from "services/vehicleService";
import MasterModelView from "../../views/Components/MasterModelView";
import VehicleViewPage from "../../views/Pages/SettingsPages/VehicleViewPage";
import { Snackbar } from "@mui/material";
import hoverCardStyle from "../../assets/jss/material-dashboard-pro-react/hoverCardStyle.js";


const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(hoverCardStyle);
const useStyles1 = makeStyles((theme) => ({
  menuItem: {
    // border: '1px dashed green',
    borderRadius: '9px 80px 9px 80px',
    padding: '2px 13px',
    textAlign: 'center',
  color:"red"
    
  },
}));

export default function HeaderLinks(props) {
  const [globalState, dispatch] = useStateValue();
  const [cookies, setCookie, removeCookie] = useCookies(["localhost"]);
  console.log(cookies, "cookies");
  const history = useHistory();
  const [openNotification, setOpenNotification] = React.useState(null);

  const [classicModal, setClassicModal] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [viewModel, setViewModel] = useState(false);
  const [addedItems, setAddedItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [addSearch, setAddSearch] = React.useState({
    ddl_customer: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });
  const [expiryDate, setExpiryDate] = useState([]);

  React.useEffect(() => {
    getExpiryDocument(
      addSearch,
      (r) => {
        setExpiryDate(r);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "success" },
        });
      }
    );
  }, [refresh]);

  const viewData1 = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "attachment_name",
      label: "Attachment No",
    },
    {
      id: "expiry_date",
      label: "Expiry Date",
    },
    {
      id: "days",
      label: "Days",
    },
  ];

  const viewData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "vehicle_no",
      label: "Vehicle No",
    },
    {
      id: "vehicle_ownership",
      label: "Ownership",
    },
    {
      id: "vehicle_type",
      label: "Type",
    },
    {
      id: "vehicle_brand",
      label: "Brand",
    },

    {
      id: "vehicle_specification",
      label: "Specification",
    },
    {
      id: "no_of_wheels",
      label: "Wheels",
    },
    // {
    //   id: "vehicle_purchase_date",
    //   label: "Vehicle Date",
    // },

    {
      id: "status",
      label: "Status",
      statusBtn: (v) => (v === "Y" ? true : false),
    },
  ];

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const OnCellClicked = (e, r) => {
    console.log(r, "sankr0506");
    setViewModel(true);

    getAllVehicleView(
      r.vehicle_id,
      r.vehicle_no,
      (r) => {
        console.log(r, "sank3008");

        setAddedItems(r);

        setLoading(false);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "success" },
        });
      }
    );
  };

  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const onLogOut = (props) => {
    UserLogOut(() => {
      console.log("logged cookies", removeCookie);
      sessionStorage.removeItem("user_token");
      history.push("/");
      removeCookie("localhost");
      // const { cookies } = props;
      // cookies.remove('Token');
      // window.location.href = '/';
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    });
    setOpenProfile(null);
  };
  const changePassword = () => {
    history.push("/");
    setOpenProfile(null);
  };

  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();


  const { rtlActive } = props;
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive,
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickChangePassword = () => {
    setClassicModal(true);
    setOpenProfile(null);
  };
  const onCloseModel = () => {
    setClassicModal(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    if (localStorage.getItem("user_token")) {
      const myDecodeToken = decodeToken(localStorage.getItem("user_token"));
      dispatch({ type: actionTypes.SET_USER, payload: myDecodeToken });
    }
    if (!localStorage.getItem("user_location")) {
      onLogOut();
    } // if not use remove it
  }, []);

  return (
    <div className={wrapper}>
      <Button
        color="transparent"
        simple
        aria-label="Dashboard"
        justIcon
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : "",
        }}
      >
        <MessageIcon
          className={
            classes.headerLinksSvg +
            " " +
            (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
          }
        />
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>
            {rtlActive ? "لوحة القيادة" : "Message"}
          </span>
        </Hidden>
      </Button>
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <NotificationsIcon
            style={{ color: "#3699ff" }}
            className={
              classes.headerLinksSvg +
              " " +
              (searchButton
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          {/* {  console.log(expiryDate.length,"sanklength")} */}
          <span className={classes.notifications}>{expiryDate.length}</span>
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              {searchButton ? "إعلام" : "Notification"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  {/* <MenuList role="menu">
                    {expiryDate.map((r, i) => (
                    <MenuItem
                     
                      className={dropdownItem}
                      key={i}
                      style={{borderBottom:"1px solid"}}
                      onClick={(e) => OnCellClicked(e, r)}
                     
                    >
                      {
                        
                        `${i+1} > ${(r.Expiry_vehicle_no )} Vehicle Document Expired ? `
                       }
                       
                    </MenuItem>
                    ))}
                  
                  </MenuList> */}

                  <MenuList role="menu" className={classes2.cardHeaderHover}>
                    {expiryDate.map((r, i) => (
                      <MenuItem
                        key={i}
                        onClick={(e) => OnCellClicked(e, r)}
                        // className={classes.notificationItem}
                      >
                        <div style={{borderBottom:"1px solid",borderBottomColor:"blue",}}>
                          <div>
                            {/* <NotificationsIcon style={{ color: "#3699ff" }} /> */}
                          </div>
                          <div
                           
                          >
                            <span >
                              {`${i + 1}. ${r.Expiry_vehicle_no} Expired`}
                              </span>
                            {/* <span>{r.Expiry_vehicle_no}</span> */}
                            <div className={classes1.menuItem}>
                            {r.document_expirations && Array.isArray(r.document_expirations) && r.document_expirations.map((a, index) => (
  <div key={index}>
    {Array.isArray(a) && a.slice(0, 6).map((document, docIndex) => (
      <div key={docIndex}>
        <span>{document?.document_name}</span>
      </div>
    ))}
  </div>
))}
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <MasterModelView
          classicModal={viewModel}
          viewHeader="View Vehicle"
          // onCloseModel={onCloseModel}
          onCloseModel={(e) => {
            e.preventDefault();
            setViewModel(false);
          }}
        >
          <VehicleViewPage
            title="View Vehicle"
            title1="Vehicle Attachment"
            viewData={viewData}
            viewData1={viewData1}
            addedItems={addedItems}
            // itemDetails={itemDetails}
            // allStatus={allStatus}
          />
        </MasterModelView>
      </div>

      {/* Search Bar */}
      {/* <Button
        color="transparent"
        simple
        aria-label="Dashboard"
        justIcon
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : "",
        }}
        className={searchButton}
        onClick={handleClick}
      >
        <SearchIcon
          className={classes.headerLinksSvg + " " + classes.searchIcon}
        />
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>
            {rtlActive ? "لوحة القيادة" : "Search"}
          </span>
        </Hidden>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>

        <InputBase
          className={classes.input}
          placeholder="Search "
          inputProps={{ "aria-label": "search google maps" }}
        />
      </Popover> */}

      <span className={classes.userName} onClick={handleClickProfile}>
        {globalState?.user?.user_name}
      </span>

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Avatar variant="rounded" src="#" className={classes.userAvatar} />
          <Hidden mdUp implementation="css">
            <span style={{ marginLeft: "16px" }}>
              <span onClick={handleClickProfile} className={classes.linkText}>
                {rtlActive ? "الملف الشخصي" : " Hi,John"}
              </span>
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الملف الشخصي" : "Profile"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الإعدادات" : "Settings"}
                    </MenuItem>
                    <MenuItem
                      onClick={onClickChangePassword}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الإعدادات" : "Change Password"}
                    </MenuItem>
                    <Divider light />
                    <MenuItem onClick={onLogOut} className={dropdownItem}>
                      {rtlActive ? "الخروج" : "Log out"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <ChangePasswordPage
        openModel={classicModal}
        onCloseModel={onCloseModel}
      />
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
