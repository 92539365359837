import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";
import { currentDate } from "../views/Pages/HelperComponent/utils";
//Vehicle List
export const getAllVehicle = async (onSuccess, onFailure,q) => {
    try {
  
      const res = await webApi.post("/master/vehicle/list", {
        // keyword_pharse: q?.txt_keyword_pharse,
  
      });
      if 
      (res.status === 200)
       {
        const r = res.data;
         console.log(r,"list")
  
        let all_vehicles = [];
        r.map((r, i) => {
          all_vehicles.push({
            
            //for ddl
            value:r.vehicle_id,
            label:r.vehicle_no,
            vehicle_ownership:r.vehicle_ownership,
            no_of_wheels:r.no_of_wheels,
            vehicle_type:r.vehicle_type,
            vehicle_purchase_date:r.vehicle_purchase_date
          });
        });
        return onSuccess(all_vehicles);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };
  
//Expense List
export const getAllExpenses = async (onSuccess, onFailure,q) => {
  try {

    const res = await webApi.post("/master/expenses/list", {
      keyword_pharse: q?.txt_keyword_phrase,

    });
    if 
    (res.status === 200)
     {
      const r = res.data;
       

      let all_expenses = [];
      r.map((r, i) => {
          all_expenses.push({
          // id: i + 1,
          value:r.expenses_id,
          label:r.expenses,
          expenses_type:r.expenses_type,
          details: r.details,
          // status: r.active_status,
          // action_items: r.action_items,
          // action: "action",
        });
      });
      return onSuccess(all_expenses);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//Post
export const postMaintenanceExpenses = async(MaintenanceExpensesDetails,ExpensesList,inserted_by_id,onSuccess,onFailure)=>{

  try {
    const res = await webApi.post("/master/maintenanceexpenses/insert",{
    vehicle_id:MaintenanceExpensesDetails.ddl_vehicle_no_id,
    vehicle:MaintenanceExpensesDetails.ddl_vehicle,
    expenses:ExpensesList,
    inserted_by_id:inserted_by_id,
    maintenance_expense_date:timestamp(currentDate()),
    })
    if (res.status === 200) {
      
      const r = res.data;
      onSuccess(r);
      console.log(r,"SEN27/NO.")
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
    
  }
}

//List
export const getMaintenanceExpensesDetails = async(addSearch,onSuccess,onFailure)=>{

  try {
    const res = await webApi.post("/master/maintenanceexpenses/list",{
      vehicle:addSearch.txt_vehicle_no,
      fromDate:timestamp(addSearch.txt_from_date),
      toDate:timestamp(addSearch.txt_to_date),
      maintenance_expenses_no:addSearch.txt_maintenance_expenses_no,
    })

    
    if (res.status === 200) {
      const r = res.data;
      let allData =[];
      r.map((r,i)=>{
        allData.push({
          id:i+1,
          date:dateFormate(r.maintenance_expense_date),
          vehicle_no: r.vehicle,
          maintenance_order_no:r.maintenance_order_no,
          total_expenses:r.expenses.reduce((sum,li)=>Number(sum) + Number(li.amount),0),
          action_items: r.action_items,
          action: "action",

      })})

      onSuccess(allData);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
    
  }
}