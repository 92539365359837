import React, { useEffect, useState } from "react";
import Select from "react-select";

//reducers
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { InputLabel, TextField, ThemeProvider } from "@material-ui/core";
import PageTitle from "../HelperComponent/PageTitle";
import {
  currentDate,
  currentDate1,
  currentTime,
} from "views/Pages/HelperComponent/utils";
import {
  appDefaultColor,
  appSecondColor,
  whiteColor,
  reactSelectStyles,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import MuiTable from "../../Components/MuITable";
import { activeText } from "assets/jss/material-dashboard-pro-react";

//Model window
import MasterModel from "views/Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";
//Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CircleAddBtn } from "views/Components/CustomButton";
import Button from "components/CustomButtons/Button.js";
import { CustomCard } from "views/Components/CustomCard";
import { Paper } from "@mui/material";
import CustomButton from "views/Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Grid } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { getMaintenanceExpenses } from "../../../services/tyreFittingService";
import { getRejectedDetails } from "services/RejectedTyreService";
import { IconButton, OutlinedInput } from "@material-ui/core";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { useHistory } from "react-router-dom";

import { getBreakDownList } from "services/breakDownService";
import { getAllBreakDownList } from "services/breakDownService";
// import * as ExcelJS from 'exceljs';
import ExcelJS from 'exceljs';
import { getVehicleServiceList } from "services/ServicesService";
import { getNotificationList } from "services/ServicesService";
import { appDefaultFamily } from "assets/jss/material-dashboard-pro-react";
import {
  appFontWeightThin,

} from "assets/jss/material-dashboard-pro-react";
const useStyles2 = makeStyles(styles);
// const ExcelJS = require("exceljs");

//service
// import { getAllExpenses, getAllAdvance } from "services/trackingService";
// import { postTracking, getAllDestination, getSearchDestination ,getSearchTracking} from "services/trackingService";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "6px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "0px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  customSelect: {
    marginBottom: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
    "&:hover": {
      backgroundColor: "rgba(43,43, 43, 0.03)",
    },
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  floatAddBtn: {
    position: "fixed",
    top: 90,
    right: 40,
    zIndex: 1000,
  },
  pageTitleBox: {
    color: appDefaultColor,
    // color: appSecondColor,
    fontSize: "14px",
    fontWeight: appFontWeight,
    paddingLeft: "20px",
  },
  topHeaderTitle: {
    backgroundColor: whiteColor,
    height: "auto",
    padding: "5px 20px",
    margin: "-20px -30px 10px -30px",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },

  highlightedRow :{
    backgroundColor: '#f0f8ff'
  },

  blink: {
    animationName: '$blink', // Reference the keyframes defined below
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },

  '@keyframes blink': {
    '0%': { backgroundColor: 'inherit' }, // Initial state
    '50%': { backgroundColor: '#ffff00' }, // Mid state (blinking color)
    '100%': { backgroundColor: 'inherit' }, // Final state (back to initial)
  },
}));
const useStyles1 = makeStyles((styles) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const ServicesPage = () => {
  const classes1 = useStyles2();
  const history = useHistory();
  const classes = useStyles();
  const [globalState, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);

  const [collapsible, setCollapsible] = useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [addSearch, setAddSearch] = useState({
    vehicle_no: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });

  const [vehicleServiceList, setVehicleServiceList] = useState([]);
  
  const [notificationList, setNotificationList] = useState([]);
  // list header data
  const headerData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "vehicle_no",
      label: "Vehicle No",
      align: "center",
    },
    {
      id: "service_type",
      label: "Service Type",
      align: "center",
    },
    {
      id: "qty",
      label: "Qty",
      align: "left",
    },
    {
      id: "uom",
      label: "Uom",
      align: "left",
    },

    {
      id: "service_date",
      label: "Service Date",
      align: "left",
    },
    {
      id: "next_service_date",
      label: "Next Service Date",
      align: "left",
    },

    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];

  React.useEffect(() => {
   
  }, [refresh]);



 


  const onAddSearch = (event) => {
    const { name, value } = event.target;
    setAddSearch((prev) => ({ ...prev, [name]: value }));
  };
  const onClickCollaps = () => {
    console.log("Reached23");
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };
  const onSearch = (e) => {
    e.preventDefault();

    getVehicleServiceList(
      addSearch,
      (res) => {
        console.log(res, "sankres=>");
        if (res.length) {
          setVehicleServiceList(res);
        } else {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: {
              msg: "Vehicle Service Details Not Found",
              msgType: "error",
            },
          });
        }
      },
      (err) => {
        setVehicleServiceList([]);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddSearch({
      txt_vehicle_no: "",
      txt_to_date: currentDate(),
      txt_from_date: currentDate1(),
    });
  };

  const onUpdateRejectedTyre = (row) => {
    console.log("sankedit", row);
    history.push({
      pathname: "/admin/maintence/breakDownListEdit",
      // search: '?query=abc',
      state: {
        edit: true,
        row: row,
        vehicleServices_id: row.vehicleServices_id,
      },
    });
  };

  const onClickView = (row) => {
    console.log("sankView", row);
    history.push({
      pathname: "/admin/maintence/breakDownListView",
      view: true,
      row: row,
      vehicleServices_id: row.vehicleServices_id,
    });
  };


  const vehicleCountMap = vehicleServiceList.reduce((acc, row) => {
    if (!acc[row.vehicle_id]) {
      acc[row.vehicle_id] = [];
    }
    acc[row.vehicle_id].push(row.inserted_by_date);
    return acc;
  }, {});



  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Maintenance > Service"
        btnToolTip="Add  Vehicle Service"
        // onClickAddBtn={onClickAddButton}
        addBtnLink="/admin/maintence/serviceForm"
      />

      {/* search section */}
      <GridContainer>
        <GridItem xs="12">
          <CustomCard
            cdTitle="Search Service Details"
            btnToolTip={collapsible ? "Collaps" : "Expand"}
            onClickCollapsible={onClickCollaps}
            buttonAction={collapsible}
            filterIcon
          >
            {collapsible ? (
              <Paper elevation="0" className={classes.searchBar}>
                <GridContainer
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <GridItem xs="3" textAlign="left">
                    <InputLabel id="label">Vehicle</InputLabel>
                    <TextField
                      size="small"
                      placeholder="Vehicle"
                      name="vehicle_no"
                      onChange={onAddSearch}
                      type="search"
                      id="outlined-basic"
                      fullWidth={true}
                      value={addSearch.vehicle_no}
                      variant="outlined"
                      onClick={(e) => {
                        e.target.select();
                      }}
                      inputProps={{ style: { textAlign: "left" } }}
                      FormHelperTextProps={{ style: { textAlign: "left" } }}
                    />
                  </GridItem>

               
                  <GridItem>
                    <div className={classes.searchbtnRight}>
                      <CustomButton
                        type="submit"
                        onClick={onSearch}
                        name="btn_refresh"
                        style={{ marginRight: "10px" }}
                      >
                        <SearchIcon />
                      </CustomButton>
                      <CustomButton onClick={onClickRefresh}>
                        <RotateLeftIcon />
                      </CustomButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </Paper>
            ) : (
              ""
            )}
          </CustomCard>
        </GridItem>
      </GridContainer>
      {/* all list display */}
    
        <GridContainer>
          <GridItem xs="12">
            <Card className={classes1.headerCard}>
              <CardHeader
                className={classes1.TbheaderCdhd}
                style={{ height: 60 }}
              >
                <GridContainer
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <GridItem>
                    <h4 className={classes1.headerCdTitle}>
                      Service Details List
                    </h4>
                  </GridItem>
               
                </GridContainer>
              </CardHeader>
              <CardBody
                style={{ height: "auto", maxHeight: 480, padding: 10 }}
                className={clxs(classes.customScroolBar)}
              >
 <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">#</StyledTableCell>
                   
                      <StyledTableCell align="left">
                      Vehicle No
                      </StyledTableCell>

                      <StyledTableCell align="left">Service Type</StyledTableCell>
                      <StyledTableCell align="center">Qty</StyledTableCell>
                      <StyledTableCell align="left">Uom</StyledTableCell>                
                      <StyledTableCell align="left">Service Date</StyledTableCell>
                      <StyledTableCell align="left">Next Service Date</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vehicleServiceList.map((row, i) => (
                        console.log(row,"sankdroe"),
                      <StyledTableRow key={i} className={row.next_service ? classes.blink : ''}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={classes.id}
                        >
                          {i+1}
                        </StyledTableCell>
                      
                        <StyledTableCell
                          align="left"
                          className={classes.itemDetails}
                        >
                         {row.vehicle_no}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          className={classes.itemDetails}
                        >
                         {row.service_type}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          className={classes.itemDetails}
                        >
                         {row.qty}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          className={classes.itemDetails}
                        >
                         {row.uom}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          className={classes.itemDetails}
                        >
                         {row.service_date}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          className={classes.itemDetails}
                        >
                         {row.next_service_date}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          className={classes.itemDetails}
                        >
                         {/* {row.action} */}
                        </StyledTableCell>

          
                     
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
               
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
     
    </ThemeProvider>
  );
};

export default ServicesPage;
