import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import {
  currentDate,
  dateFormate,
  currentTime,
  timeToUnix,
  IstTime,
} from "../views/Pages/HelperComponent/utils";

export const postLoading = async (
  loadingDetails,
  inserted_by_id,
  onSuccess,
  onFailure
) => {
  try {
    console.log(loadingDetails, "sen22102022");

    const res = await webApi.post("master/loading/insert", {
      vehicle_id: loadingDetails.ddl_vehicle_no_id,
      vehicle_no: loadingDetails.ddl_vehicle_no,
      driver_id: loadingDetails.ddl_driver_id,
      driver_name: loadingDetails.ddl_driver_name,
      driver_no: loadingDetails.whatsapp_no,
      measurement: loadingDetails.ddl_measurement,
      measurement_id: loadingDetails.ddl_measurement_id,
      material_type_rate: loadingDetails.material_type_rate,
      loading_location: loadingDetails.ddl_loading_location,
      loading_location_id: loadingDetails.ddl_loading_location_id,
      loading_quantity: loadingDetails.loading_qty,
      loading_start_time: timeToUnix(loadingDetails.loading_start_time),
      // loading_start_time: Number((loadingDetails.loading_start_time).split(':').join('')),
      loading_end_time: timeToUnix(loadingDetails.loading_end_time),
      // loading_end_time:Number((loadingDetails.loading_end_time).split(':').join('')),
      challan_no: loadingDetails.challan_no,
      challan_date: timestamp(loadingDetails.challan_date),
      challan_time: timeToUnix(loadingDetails.challan_time),
      // challan_time:Number((loadingDetails.challan_time).split(':').join('')),

      challan_validity_time: timeToUnix(loadingDetails.challan_validity_time),
      // challan_validity_time: Number((loadingDetails.challan_validity_time).split(':').join('')),
      challan_cft: loadingDetails.challan_cft,
      challan_image_path: loadingDetails.challan_image_path,
      challan_image_name: loadingDetails.challan_image_name,
      ghat_in_time: timeToUnix(loadingDetails.ghat_in_time),
      ghat_exit_time: timeToUnix(loadingDetails.ghat_exit_time),
      // ghat_in_time:Number((loadingDetails.ghat_in_time).split(':').join('')),
      // ghat_exit_time:Number((loadingDetails.ghat_exit_time).split(':').join('')),
      weight_measurement: Number(loadingDetails.weight_measurment),
      remarks: loadingDetails.remarks,
      loading_date: timestamp(currentDate()),
      inserted_by_id: inserted_by_id,
      material: loadingDetails.ddl_Material,
      material_id: loadingDetails.ddl_Material_id,
      material_type: loadingDetails.ddl_Material_type,
      material_type_id: loadingDetails.ddl_Material_type_id,
      gst: loadingDetails.gst,
      transaction_no: loadingDetails.transaction_no,
      advance_order_no: loadingDetails.advance_order_no,
    });
    const res2 = await webApi.post("master/daily/truck", {
      vehicle_id: loadingDetails.ddl_vehicle_no_id,
      vehicle_no: loadingDetails.ddl_vehicle_no,    
      challan_no: loadingDetails.challan_no,
      challan_date: timestamp(loadingDetails.challan_date),     
      loading_date: timestamp(currentDate()),     
      transaction_no: loadingDetails.transaction_no,
     
    });

    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
      const res2 = await webApi.post("/master/advance/update", {
        advance_id: loadingDetails.advance_id,
        loading_status: true,
      });
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllVehicleByOil = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/vehicle/list", {
      keyword_pharse: q?.txt_keyword_pharse,
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "list");

      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({
          //for ddl
          value: r.vehicle_id,
          label: r.vehicle_no,
        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllLoading = async (addSearch, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/loading/list", {
      keyword: addSearch.txt_KeyWord,
      from_date: timestamp(addSearch.txt_from_date),
      to_date: timestamp(addSearch.txt_to_date) + 86399,
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "ddrk");

      let all_advance = [];
      r.map((r, i) => {
        all_advance.push({
          id: i + 1,
          loading_id: r.loading_id,
          vehicle_id: r.vehicle_id,
          driver_id: r.driver_name,
          vehicle_no: r.vehicle_no,
          loading_quantity: r.loading_quantity,
          loading_start_time: IstTime(r.loading_start_time),
          loading_completion_time: IstTime(r.loading_end_time),
          challan_no: r.challan_no,
          challan_date: dateFormate(r.challan_date),
          challan_time: IstTime(r.challan_time),
          transaction_no: r.transaction_no,
          action_items: r.action_items,
          action: "action",
        });
      });

      return onSuccess(all_advance);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getLoadingDetails = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/loading/loadingDetails", {
      id: id,
    });
    if (res.status === 200) {
      const r = res.data;

      return onSuccess(r[0]);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllLocation = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/location/list", {
      short_data: true,
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "sank24082");

      let all_location = [];
      r.map((r, i) => {
        all_location.push({
          value: r.location_id,
          label: r.location,
          location_type_id: r.location_type_id,
        });
      });
      return onSuccess(all_location);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllMaterial = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/material/list", {
      // short_data: true,
      // material_id:2
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "sen29");

      let all_material = [];
      r.map((r, i) => {
        all_material.push({
          value: r.material_id,
          label: r.material_name,
          gst: r.gst,
        });
      });
      return onSuccess(all_material);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getMaterialType = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("master/material_type/list", {
      material_id: id,
    });

    if (res.status === 200) {
      const r = res.data;
      let type = [];
      console.log(r, "22102022");
      r.map((r) => {
        type.push({
          value: r.material_type_id,
          label: r.material_type_name,
          material_type_rate: r.material_type_rate,
        });
      });
      onSuccess(type);
    } else {
      onFailure("Something Wrong! Please Try again later");
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//Advanced Vehicles
export const getAdvanceVehicle = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/loading/advanced_vehicle_list", {
      loading_status: false,
    });
    if (res.status === 200) {
      const r = res.data;
      let vehicle_list = [];
      r.map((r, i) => {
        console.log(r, "sen3011");

        vehicle_list.push({
          value: r.vehicle_id,
          label: r.vehicle_no,
          transaction_no: r.transaction_no,
          advance_order_no: r.advance_order_no,
          advance_id: r.advance_id,
          driver_name: r.driver_name,
          driver_no: r.driver_no,
        });
      });
      onSuccess(vehicle_list);
    } else {
      onFailure("Something Wrong! Please Try again later");
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllEmployee = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/employee/list", {
      post: "3",
    });

    if (res.status === 200) {
      const r = res.data;
      let allEmployee = [];
      r.map((v, i) => {
        allEmployee.push({
          id: i + 1,
          value: v.employee_id,
          label: v.first_name,
          post: v.post,
          whatsapp_no: v.whatsapp_no,
        });
      });

      if (allEmployee.length) {
        return onSuccess(allEmployee);
      } else {
        onFailure("Employee not found ");
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const postFileUpload = async (file, onSuccess, onFailure) => {
  console.log("sen20102022/post", file);

  let formData = new FormData();
  formData.set("file", file);
  try {
    const res = await webApi.post(
      "https://api.gstspl.com/files/upload?uploadpath=image",
      formData
    );
    console.log("sen20102022/image", res);
    if (res.status === 200) {
      const r = res.data;

      if (r.success) {
        onSuccess(r);
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
