import webApi from "./webApi/webApi";
import { currencyFormate, currentDate, timestamp } from "../views/Pages/HelperComponent/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";

function toTitleCase(str) {
  if(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  else {
    return "";
  }
}



// export const getSearchVehicleReceipt = async (onSuccess, onFailure,searchCriteria) => {
//     console.log(searchCriteria,"111")
//     try {

//       const res = await webApi.post("/master/receipt/sales/list", {
        
//         customer_id:searchCriteria?.ddl_customer_id,
//       });
     
  
//       if (res.status === 200) {
//         const r = res.data;
//         let arr = [];     
    
//         r.map((r, i) => {
      

//             arr.push({
//                 _id: i+1,
//               customer_id: r?.data[0]?.customer_id,
//               transaction_no: r?.data[0]?.transaction_no,
//               sales_date: dateFormate(r?.data[0]?.sales_date),
//               customer: r?.data[0]?.customer,
//               sales_value: r?.data[0]?.total_amount,
//               ledger_account_id:r?.ledger_account_id,
//               vehicle_id: r?.data[0]?.vehicle_id,
//               vehicle_no: r?.data[0]?.vehicle, 
//               balence_left: 
//               r?.data[0]?.receiptsales_details[0]?.payment_details[0]?.sales_value -
//               r?.data[0]?.payment_sum
//               ? 
//               r?.data[0]?.receiptsales_details[0]?.payment_details[0]?.sales_value -
//               r?.data[0]?.payment_sum 
//               : 0 ,
//               already_payment_received:
//                r?.data[0]?.payment_sum
//                ? 
//                r?.data[0]?.payment_sum
//                : 0  ,

//                sales_value1:r?.data[0]?.total_amount,
//                payment:r.payment,

//               recAction: "action",
//             });
          
//         })  
    
//         if(arr.length){
//         return onSuccess(arr);
//       } else {
//         return onFailure("receipt not found");
//       }
//       } else 
//       {
//         onFailure("Something Wrong! Please Try again later " + res.data);
//       }
//     } catch (error) {
//       onFailure("Something Wrong! Please Try again later " + error);
//     }
//   };

export const getSearchVehicleReceipt = async (onSuccess, onFailure, searchCriteria) => {
  console.log(searchCriteria, "111");
  try {
    const res = await webApi.post("/master/receipt/sales/list", {
      customer_id: searchCriteria?.ddl_customer_id,
    });

    if (res.status === 200) {
      const r = res.data;
      let arr = [];

      r.forEach((item, i) => {
        const data = item?.data[0];
        if (data) {
          const payment = item.payment;
          const sales_value1 = data.total_amount;

          if (payment !== sales_value1) {
            arr.push({
              _id: i + 1,
              customer_id: data.customer_id,
              transaction_no: data.transaction_no,
              sales_date: dateFormate(data.sales_date),
              customer: data.customer,
              sales_value: data.total_amount,
              ledger_account_id: item.ledger_account_id,
              vehicle_id: data.vehicle_id,
              vehicle_no: data.vehicle,
              balence_left: (data.receiptsales_details[0]?.payment_details[0]?.sales_value - data.payment_sum) || 0,
              already_payment_received: data.payment_sum || 0,
              sales_value1: data.total_amount,
              payment: item.payment,
              recAction: "action",
            });
          }
        }
      });

      if (arr.length) {
        return onSuccess(arr);
      } else {
        return onFailure("receipt not found");
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

  export const postSalesFormPage = async (payment_details, searchCriteria,journal_details, inserted_by_id, onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/receipt_Sales/insert", {
        
        customer_id: searchCriteria.ddl_customer_id,
        customer:searchCriteria.ddl_customer_label,
        transaction_no:payment_details.transaction_no,
        Party_Name:searchCriteria.Party_Name,
        payment_date: timestamp(searchCriteria.payment_date),
        mode_id: searchCriteria.ddl_mode_id,
        mode_name: searchCriteria.ddl_mode_label,
        bank_id: searchCriteria.bank_id,
        bank_name: searchCriteria.bank_name,
        payment_details:payment_details,
        inserted_by_id: inserted_by_id,
      });
      if (res.status === 200) {
        const r = res.data;
        const journalData = await webApi.post("/master/journal/insert", {
          journal_type: "J",
          transaction_id: r?.transaction_no,
          transaction_type: "Receipt",
          transaction_type_id: r?.transaction_no,         
          voucher_amount: Number(payment_details.sales_value),
          voucher_date: timestamp(searchCriteria.payment_date),
          journal_details: journal_details,
        });
        onSuccess(r);
      } else {
        onFailure("Something went wrong. Please try again later.");
      }
    } catch (error) {
      onFailure("Something went wrong. Please try again later. Error: " + error);
    }
  };




  export const getListLedgerAccount = async (onSuccess, onFailure,) => {

    
    try {
      const res = await webApi.post("/master/ledger_account/list", {
        // short_data: true,
        // ledger_group_id:ledger_group_id,
      });
      console.log("sen20042allLedAcc", res);
      if (res.status === 200) {
        const r = res.data;
        let allLedgerAccount = [];
        r.map((r, i) => {
          allLedgerAccount.push({
            value: r.ledger_account_id,
            label: toTitleCase(r.ledger_account),
            // ledger_group_id: r.ledger_group_id,
            // opening_balance: r.opening_balance,
            // closingBalance:r.closingBalance
          });
        });
        console.log("sen20042allLedAcc", allLedgerAccount);
        return onSuccess(allLedgerAccount);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };



  export const getCustomerById = async (onSuccess, onFailure, id) => {
    try {
      const res = await webApi.post("/master/customer/list", {
        // source_id: q?.ddl_source.value,
        customer_id: id,
        // keyword_pharse: q.txt_keyword_pharse,
      });
      if (res.status === 200) {
        const r = res.data;
        let allCusts = [];
        r.map((c, i) => {
          allCusts.push({
            id: i + 1,
            value: c.customer_id,
            label: c.company_name,
           
          });
        });
        if (allCusts.length) {
          return onSuccess(allCusts);
        } else {
          onFailure("Customer not Found ");
        }
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };


  export const getReceiptSalesVehicleList = async (addSearch,onSuccess, onFailure) => {
    console.log("sankh130524", addSearch);
  
    try {
      const res = await webApi.post("/master/receiptSalesVehicle/list", {
        txt_transaction_no: addSearch?.txt_transaction_no,
        txt_Vehicle_no: addSearch?.txt_Vehicle_no,
        txt_Party_no: addSearch?.txt_Party_no,
        txt_from_date: timestamp(addSearch?.txt_from_date),
        txt_to_date: timestamp(addSearch?.txt_to_date),
      });
  
      if (res.status === 200) {
        const data = res.data;
  
        if (data && data.length > 0) {
          const SalesVehicleList = data.map((item, index) => ({
            recID: index + 1,
            recTransaction: item.transaction_no,
            recVehicle: item.vehicle_no,
            recSalesDate: item.sales_date,
            recSalesValue: item.sales_value,
            recReceivedAmount: item.amount,
            recPaymentDate: dateFormate(item.payment_date),
            recPartyName: item.customer,
          }));
  
          onSuccess(SalesVehicleList);
        } else {
          onFailure("No data found.");
        }
      } else {
        onFailure("Something went wrong. Please try again later.");
      }
    } catch (error) {
      onFailure("Something went wrong. Please try again later: " + error.message);
    }
  };
  


//   export const getReceiptSalesVehicleList = async (onSuccess, onFailure,addSearch) => {

//     console.log("sankh130524", addSearch);
    
//     try {
//       const res = await webApi.post("/master/receiptSalesVehicle/list", {
//         transaction_no:addSearch?.txt_transaction_no,
//         Vehicle_no:addSearch?.txt_Vehicle_no,
//         Party_no:addSearch?.txt_Party_no,
//         from_date:addSearch?.txt_from_date,
//         to_date:addSearch?.txt_to_date,

//       });
//       // console.log("sen20042allLedAcc", res);
//       if (res.status === 200) {
//         const r = res.data;
//         let SalesVehicleList = [];
//         r.map((r, i) => {
//           console.log(r,"sankha002")
//           SalesVehicleList.push({
//             recID: i+1,
//             recTransaction:r.Transction,
//             recVehicle:r.vehicle_no,
//             recSalesDate:r.sales_date,
//             recSalesValue:r.sales_value,
//             recReceivedAmount:r.amount,
//             recPaymentDate:dateFormate(r.payment_date),
//             recPartyName:r.customer

//           });
//         });
       
// if(SalesVehicleList.length){
//         return onSuccess(SalesVehicleList);
//       } else {
//         onFailure("Something Wrong! Please Try again later " + res.data);
//       }
//     }else {
//       onFailure("Something Wrong! Please Try again laterwww " + res.data);
//     }
//     } catch (error) {
//       onFailure("Something Wrong! Please Try again later " + error);
//     }
//   };