import React, { useEffect, useState } from "react";
import Select from "react-select";

//reducers
import { useStateValue } from "../../../../context/context";
import { actionTypes } from "../../../../context/reducer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { InputLabel, TextField, ThemeProvider } from "@material-ui/core";
import PageTitle from "../../HelperComponent/PageTitle";
import { currentDate, currentDate1 } from "views/Pages/HelperComponent/utils";
import {
  appDefaultColor,
  appSecondColor,
  whiteColor,
  reactSelectStyles,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import MuiTable from "../../../Components/MuITable";

//Model window
import MasterModel from "views/Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";
//Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CircleAddBtn } from "views/Components/CustomButton";
import Button from "components/CustomButtons/Button.js";
import { CustomCard } from "views/Components/CustomCard";
import { Paper } from "@mui/material";
import CustomButton from "views/Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Grid } from "@material-ui/core";
import MasterModelView from "../../../Components/MasterModelForView";

//Services
import {
  postFileUpload,
  postOil,
  getAllOilView,
  getAllOil,
  getAllVehicleById,
  getAllPetrolPumpById,
} from "services/oilService";
import { getRoutes, totalOiled } from "services/oilService";
import { currentTime } from "views/Pages/HelperComponent/utils";
import { useHistory, useLocation } from "react-router-dom";
import { IconButton, OutlinedInput } from "@material-ui/core";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import ExcelJS from 'exceljs';

const useStyles1 = makeStyles(styles);



const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  floatAddBtn: {
    position: "fixed",
    top: 90,
    right: 40,
    zIndex: 1000,
  },
  pageTitleBox: {
    color: appDefaultColor,
    // color: appSecondColor,
    fontSize: "14px",
    fontWeight: appFontWeight,
    paddingLeft: "20px",
  },
  topHeaderTitle: {
    backgroundColor: whiteColor,
    height: "auto",
    padding: "5px 20px",
    margin: "-20px -30px 10px -30px",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },
}));

const Oil = () => {
  const classes1 = useStyles1();

  const classes = useStyles();
  const [globalState, dispatch] = useStateValue();
  const history = useHistory();

  const [collapsible, setCollapsible] = useState(true);
  const [addSearch, setAddSearch] = useState({
    txt_vehicle_no: "",
    txt_keyword: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });
  const [vehicle, setAllVehicle] = React.useState([]);
  const [viewModel, setViewModel] = useState(false);

  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [classicModal, setClassicModal] = React.useState(false);
  const [allpump, setallpump] = React.useState(false);
  const [classicModel, setClassicModel] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [allOil, setAllOil] = useState(false);
  const [oilDetails, setOilDetails] = useState({
    edit: false,
    inflow: false,
    ddl_vehicle_no_id: "",
    ddl_vehicle_no_label: "",
    km_start_reading: 0,
    actual_qty: 0,
    rate: 0,
    oil_date: currentDate(),
    ddl_petrol_pump_id: "",
    ddl_petrol_pump_label: "",
    fule_bill_no: "",
    fule_bill_date: currentDate(),
    fule_bill_time: currentTime(),
    meter_image_path: "",
    meter_image_name: "",
    bill_attachment_path: "",
    bill_attachment_name: "",
    oil_order_no: "",
    ddl_paymod_id: "",
    ddl_paymode_label: "",
    ddl_account_id: "",
    ddl_account_label: "",
    mileage: "",
    total_trip_no: "",
    trip_no: "",
  });
  const [addedItems, setAddedItems] = React.useState([]);

  const [fileLoading, setFileLoading] = useState(false);
  const [error, setError] = React.useState({
    ddl_vehicle_no_id: false,
    ddl_petrol_pump_id: false,
    fule_bill_no: false,
    actual_qty: false,
    rate: false,
  });
  const [flName, setFlName] = React.useState("");

  const [routes, setRoutes] = React.useState([]);
  const userRole = globalState?.user?.user_role;
  { console.log(globalState?.user?.user_role
    ,"sankhaglobalstate")}

  
  const headerData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "fule_bill_date",
      label: "Bill Date",
      align: "left",
    },
    {
      id: "transaction_no",
      label: "TN",
      align: "left",
    },
    {
      id: "vehicle_no",
      label: "Vehicle No",
      align: "center",
    },
    {
      id: "km_end",
      label: "Km End",
      align: "right",
    },
    {
      id: "distance_cover",
      label: "D. Cover",
      align: "right",
    },
    {
      id: "purchase_oil",
      label: "Oil(ltr)",
      align: "right",
    },
    {
      id: "rate",
      label: "Rate",
      align: "right",
    },
    {
      id: "total_amount",
      label: "Amount",
      align: "right",
    },
    {
      id: "petrol_pump",
      label: "Pump",
      align: "center",
    },
    {
      id: "fule_bill_no",
      label: "Bill No",
      align: "right",
    },
    {
      id: "action",
      label: "Action",
      align: "right",
      // viewMenu: (v) => v,
    },
  ];

  const onClickCollaps = () => {
    //console.log("Reached23")
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };

  const onAddSearch = (e) => {
    // console.log(e, "sank19082")
    const { name, value } = e.target;
    setAddSearch({ ...addSearch, [name]: value });
  };

  const onSearchOil = (e) => {
    e.preventDefault();
    setRefresh(false);
    setLoading(true);
    getAllOil(
      addSearch,
      (oil) => {
        //console.log(oil, "sank/19081")
        if (oil.length) {
          setAllOil(oil);
        } else {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: "Oil Details Not Found", msgType: "error" },
          });
        }
      },
      (err) => {
        setAllOil([]);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddSearch({
      txt_vehicle_no: "",
      txt_from_date: currentDate1(),
      txt_to_date: currentDate(),
    });
  };

  const onViewOil = (event) => {
    console.log("sen20102022=>",event)
    history.push({
      pathname: "/admin/tranportation/oilView",
      oil_id: event.oil_id,
    });
  };

  const onClickEdit = (row) => {
    // console.log("sen08122022=>", row);
    history.push({
      pathname: "/admin/tranportation/oilForm",
      oil_id: row.oil_id,
      oil_order_no:row.oil_order_no,
      edit:true
    });
  };


  //New Excel
  const onhandleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('data');
  
    const titleRow = worksheet.addRow(['Oil Report']);
    const periodRow = worksheet.addRow(['Period:']);
    worksheet.addRow([]);
  
    const titleCell = titleRow.getCell(1);
    const periodCell = periodRow.getCell(1);
    titleCell.alignment = { vertical: 'middle', horizontal: 'center', bold: true };
    periodCell.alignment = { vertical: 'middle', horizontal: 'center', bold: true };
  
    worksheet.mergeCells(`A${titleRow.number}:K${titleRow.number}`);
    worksheet.mergeCells(`A${periodRow.number}:K${periodRow.number}`);
  
    const headers = [
      "Sl No",
      "Bill Date",
      "Transaction No",
      "Vehicle No",
      "Km End",
      "Distance Cover",
      "Oil",
      "Rate",
      "Amount",
      "Pump",
      "Bill No"
    ];
  
    // Add headers as a row
    worksheet.addRow(headers);
  
    const OilTable = allOil.map((oil) => {
      return {
        Sl_No: oil.id,
        Bill_Date:oil.fule_bill_date,
        Transaction_No:oil.transaction_no,
        Vehicle_no:oil.vehicle_no,
        Km_End:oil.km_end,
        Distance_cover : oil.distance_cover,
        Oil: oil.purchase_oil,
        rate:oil.rate,
        Amount:oil.total_amount,
        pump:oil.petrol_pump,
        Bill_no:oil.fule_bill_no
      };
    });
  
    const dataStartRow = 4;
  
    const headerRow = worksheet.getRow(dataStartRow);
    headerRow.height = 20;
  
    headers.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1);
      column.width = header.length + 5;
    });
  
    OilTable.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });
  
    headerRow.font = { bold: true };
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '6dc8da' } };
    });
  
    worksheet.eachRow({ startingRow: dataStartRow + 1 }, (row, rowNumber) => {});
  
    worksheet.autoFilter = {
      from: {
        row: dataStartRow,
        column: 1,
      },
      to: {
        row: dataStartRow,
        column: headers.length,
      },
    };
    
  
    const fromDate = addSearch?.txt_from_date;
    const toDate = addSearch?.txt_to_date;
    const chosenPeriod = ` ${fromDate}  To  ${toDate}`;
    periodCell.value = `Period:  ${chosenPeriod}`;
  
    const buffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(data, 'OilReport.xlsx');
  };

  // export to excel
  // const onhandleExportToExcel = () => {
  //   const OilTable = allOil.map(oil => {
  //     return {
  //       Sl_No: oil.id,
  //       Bill_Date:oil.fule_bill_date,
  //       Transaction_No:oil.transaction_no,
  //       Vehicle_no:oil.vehicle_no,
  //       Km_End:oil.km_end,
  //       Distance_cover : oil.distance_cover,
  //       Oil: oil.purchase_oil,
  //       rate:oil.rate,
  //       Amount:oil.total_amount,
  //       pump:oil.petrol_pump,
  //       Bill_no:oil.fule_bill_no
  //     }
  //   })
  //   const fileName = 'Oil'
  //   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //   const fileExtension = '.xlsx';
  //   const ws = XLSX.utils.json_to_sheet(OilTable);
  //   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // }

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Transportation > Oil"
        btnToolTip="Add Oil"
        // onClickAddBtn={onClickAddBtn}
        addBtnLink="/admin/tranportation/oilForm"
      />
      <GridContainer>
        <CustomCard
          cdTitle="Search Oil"
          btnToolTip={collapsible ? "Collaps" : "Expand"}
          onClickCollapsible={onClickCollaps}
          buttonAction={collapsible}
          filterIcon
        >
          {collapsible ? (
            <Paper elevation="0" className={classes.searchBar}>
              <GridContainer justifyContent="flex-start" alignItems="flex-end">
                <GridItem xs="3">
                  <InputLabel id="label">Vehicle No</InputLabel>
                  <TextField
                    size="small"
                    placeholder="vehicle No"
                    name="txt_vehicle_no"
                    onChange={onAddSearch}
                    type="search"
                    id="outlined-basic"
                    fullWidth={true}
                    value={addSearch.txt_vehicle_no}
                    variant="outlined"
                    // onClick={(e) => { e.target.select() }}
                  />
                </GridItem>
                <GridItem xs="3">
                  <InputLabel id="label">Keyword</InputLabel>
                  <TextField
                    size="small"
                    placeholder="Search"
                    name="txt_keyword"
                    onChange={onAddSearch}
                    type="search"
                    id="outlined-basic"
                    fullWidth={true}
                    value={addSearch.txt_keyword}
                    variant="outlined"
                    // onClick={(e) => { e.target.select() }}
                  />
                </GridItem>
                <GridItem xs="2">
                  <InputLabel id="label">Date Between</InputLabel>
                  <TextField
                    size="small"
                    name="txt_from_date"
                    id="date"
                    variant="outlined"
                    type="date"
                    fullWidth={true}
                    value={addSearch.txt_from_date}
                    onChange={onAddSearch}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={(e) => {
                      e.target.select();
                    }}
                  />
                </GridItem>
                <GridItem xs="2">
                  <TextField
                    name="txt_to_date"
                    size="small"
                    id="date"
                    variant="outlined"
                    type="date"
                    fullWidth={true}
                    value={addSearch.txt_to_date}
                    onChange={onAddSearch}
                    className={classes.dateField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs="12">
                  <div className={classes.searchbtnRight}>
                    <CustomButton
                      type="submit"
                      onClick={onSearchOil}
                      name="btn_refresh"
                      style={{ marginRight: "10px" }}
                    >
                      <SearchIcon />
                    </CustomButton>
                    <CustomButton onClick={onClickRefresh}>
                      <RotateLeftIcon />
                    </CustomButton>
                  </div>
                </GridItem>
              </GridContainer>
            </Paper>
          ) : (
            ""
          )}
        </CustomCard>
      </GridContainer>
      {/* all list display */}
      {allOil ? (
        <GridContainer>
          <GridItem xs="12">
            <Card className={classes1.headerCard}>
              <CardHeader
                className={classes1.TbheaderCdhd}
                style={{ height: 60 }}
              >
                <GridContainer
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <GridItem>
                    <h4 className={classes1.headerCdTitle}>
                    Oil Details
                    </h4>
                  </GridItem>
                  <GridItem style={{ cursor: "pointer" }}>
                   

                    <IconButton
                      variant="text"
                      onClick={() => onhandleExportToExcel(allOil)}
                    >
                      <Tooltip title="Export to Excel">
                        <img
                          src={require("../../../../assets/img/excel.png").default}
                        />
                      </Tooltip>
                    </IconButton>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody
                style={{ height: "auto", maxHeight: 480, padding: 10 }}
                className={clxs(classes.customScroolBar)}
              >
                {/* <CustomCard cdTitle="Oil Details"> */}
                  {!refresh ? (
                    <MuiTable
                      onClickEdit={onClickEdit}
                      onClickViewOne={onViewOil}
                      columns={headerData}
                      rows={allOil}
                      userRole={userRole}
                    />
                  ) : (
                    ""
                  )}
                {/* </CustomCard> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
    </ThemeProvider>
  );
};

export default Oil;
