import { currentDate, timestamp } from "views/Pages/HelperComponent/utils";
import webApi from "./webApi/webApi";

 export const postPetrolPump= async (info,inserted_by_id, onSuccess, onFailure) => {

    try {
        
      const res = await webApi.post("/master/petrol_pump/insert", {
        petrol_pump_id: info.petrol_pump_id,
        petrol_pump: info.txt_petrol_pump,
        rate:info.rate,
        incharge:info.txt_in_charge,
        phone_no: info.txt_phone_no,
        address: info.txt_address,
        active_status: info.switch_active_status ? "Y" : "N",
        inserted_by_id:inserted_by_id,
        // route_id:info.ddl_in_routes_id,
        // routes:info.ddl_in_routes_label
      });
  
      if (res.status === 200) {
        const r = res.data;
        const ledgerRes = await webApi.post("/master/ledger_account/insert", {
          ledger_group_id: 1,
          type: "P",
          type_id: r.petrol_pump_id,
          ledger_account: info.txt_petrol_pump,
          inserted_by_id: inserted_by_id,
          // opening_balance: 0,
          // dr_cr_status: "Dr",
          as_on_date: timestamp(currentDate()),
        });
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      // onFailure("Something Wrong! Please Try again later " + error);
    }
  };




export const getAllPetrolPump = async (onSuccess, onFailure,q) => {
    try {
  
      const res = await webApi.post("/master/petrol_pump/list", {
        keyword_pharse: q?.txt_keyword_phrase,
  
      });
      if 
      (res.status === 200)
       {
        const r = res.data;
         
  
        let all_petrol_pump = [];
        r.map((r, i) => {
          console.log(r,"20122022")
            all_petrol_pump.push({
            id: i + 1,
            petrol_pump_id:r.petrol_pump_id,
            petrol_pump:r.petrol_pump,
            incharge:r.incharge,
              rate:r.rate,
            address: r.address,
            phone_no:r.phone_no,
            status: r.active_status,
            action_items: r.action_items,
            action: "action",
          });
        });
        return onSuccess(all_petrol_pump);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };



  //dispatch Vehicle by id
  export const getVehicleById = async (onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/vehicle/list", {
        group_id: 26
      });
      if (res.status === 200) {
        const r = res.data;
        let allVehicle = [];
        r.map((r, i) => {
          allVehicle.push({
  
            value: r.vehicle_id,
            label: r.vehicle_no,
          });
        });
        
        return onSuccess(allVehicle);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };



  export const deleteVehicle = async (id, onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/vehicle/delete", {
        vehicle_id: id,
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };



  export const updatePetrolPump = async (info, onSuccess, onFailure) => {
    
    try {
      const res = await webApi.post("/master/petrol_pump/update", {
        petrol_pump_id: info.petrol_pump_id,
        petrol_pump: info.txt_petrol_pump,
        address: info.txt_address,
        rate:info.rate,
        phone_no: info.txt_phone_no,
        active_status: info.switch_active_status ? "Y" : "N",
        route_id:info.ddl_in_routes_id,
        routes:info.ddl_in_routes_label
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };



  export const getAllEmployee = async (onSuccess, onFailure, q) => {
    try {
     
      const res = await webApi.post("/master/employee/list", {
        
        
        // group_id:q?.ddl_group_id,
        // keyword_pharse:q?.txt_keyword_pharse,
  
      });
     
      if (res.status === 200) {
        const r = res.data;
        let allEmployee = [];
        r.map((v, i) => {    
  
          allEmployee.push({
            id: i + 1,
            value: v.employee_id, 
            label: v.first_name, 
            post:v.post,      
          
          });
        });
        
        if(allEmployee.length){
        return onSuccess(allEmployee);
      } 
      else {
        onFailure("Employee not found " );
      }
    } 
       else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };


  export const getAllRoute = async (onSuccess, onFailure, q) => {
    try {
  
      const res = await webApi.post("/master/routes/list", {
   
  
      });
      if (res.status === 200) {
        const r = res.data;
  console.log(r,"sank1212")
  
        let all_location = [];
        r.map((r, i) => {
          all_location.push({
           
            value: r.route_id,
            label: r.route_no,
            
          });
        });
        if (all_location.length > 0) {
          return onSuccess(all_location)
        } else {
          onFailure("Location Not Found" + res.data);
        }
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };