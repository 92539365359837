import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";





function toTitleCase(str) {
  if (str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  else {
    return "";
  }

}

export const itemRowData = [];

export const getAllItem = async (onSuccess, onFailure, menu = true) => {
  try {
    const res = await webApi.post("/master/item/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allItems = [];
      r.map((r, i) => {
        allItems.push({
          id: i + 1,
          item_id: r.item_id,
          mrp: r.mrp,
          category: r.category_name,
          category_id: r.category_id,
          brand: r.brand_name,
          brand_id: r.brand_id,
          item: r.item,
          item_own_code: r.item_own_code,
          item_company_code: r.item_company_code,
          details: r.details,
          uom_id: r.uom_id,
          alt_uom_id: r.alt_uom_id,
          qty: r.qty,
          uom_name: r.uom_name,
          alt_uom_name: r.alt_uom_name,
          hsn_code: r.hsn_code,

         
          status: r.active_status,
          action: "view-action",

        
        });
      });
      return onSuccess(allItems);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
//qr code service
export const getSearchItem = async (onSuccess, onFailure, q) => {
  try {
    console.log("CAtegory", q.txt_item)
    const res = await webApi.post("/master/item/list", {
      query: q.txt_item,
      category_id: q.ddl_category?.value,
      brand_id: q.ddl_brand?.value,
    });
    if (res.status === 200) {
      const r = res.data;
      let allItems = [];
      r.map((r, i) => {
        allItems.push({
          id: i + 1,
          item_id: r.item_id,
          category: r.category_name,
          category_id: r.category_id,
          brand: r.brand_name,
          qty:r.qty,
          uom:r.uom,
          brand_id: r.brand_id,
          item: r.item,
          item_company_code: r.item_company_code,
          details: r.details,
          hsn_code: r.hsn_code,
          status: r.active_status,
          action: "view-action",
        

          menu: [
            {
              label: "View",
              link: "/admin/master/item-view",
            },
            {
              label: "Edit",
              link: "/admin/master/add-items",
            },
            // {
            //   label: "QR Code",
            //   clickBtn: true,
            //   modelName: "qrCodeModel",
            // },
            // {
            //   label: "Stock Adj.",
            //   clickBtn: true,
            //   modelName: "stockAdjustment",
            // },
            // {
            //   label: "Set MRP",
            //   clickBtn: true,
            //   modelName: "setMrp",
            // },
            {
              label: "Set Selling Price",
              clickBtn: true,
              modelName: "setSellingPrice",
            },
            // {
            //   label: "Delete",
            //   clickBtn: true,
            //   fnName: "delete",
            // },
          ],
        });
      });
      if (allItems.length) {
        return onSuccess(allItems);
      } else {
        return onFailure("Item not Found");
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const postItem = async (info, inserted_by_id, onSuccess, onFailure) => {


  try {
    const res = await webApi.post("/master/item/insert", {
      brand_id: info.ddl_brand,
      category_id: info.ddl_category,
      item_company_code: info.txt_company_code,
      item: info.txt_item_name,
      // qty:info.txt_qty,
      uom:info.txt_uom,
      size: info.txt_size,
      details: info.txt_details,  
      hsn_code: info.txt_hsn_code,
      inserted_by_id: inserted_by_id,
      active_status: info.switch_active_status ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const deleteItem = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/item/delete", {
      item_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

// For dropdown list

export const getAllBrands = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/brand/list", {
      short_data: true,
    });
    if (res.status === 200) {
      const r = res.data;
      let allPaCats = [];
      r.map((r) => {
        allPaCats.push({
          value: r.brand_id,
          label: toTitleCase(r.brand),
        });
      });
      onSuccess(allPaCats);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updateItem = async (info, onSuccess, onFailure) => {
  console.log(info, "info/1")
  try {
    const res = await webApi.post("/master/item/update", {
      item_id: info.item_id,
      brand_id: info.ddl_brand_id,
      category_id: info.ddl_category_id,     
      item_company_code: info.txt_company_code,
      item: info.txt_item_name,
      details: info.txt_details,      
      size: info.txt_size,
      hsn_code: info.txt_hsn_code,
     
      ledger_account_id: info.ddl_ledger_account_id,
      active_status: info.switch_active_status ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllLedgerAccount = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/ledger_account/list", {
      short_data: true,
    });
    if (res.status === 200) {
      const r = res.data;
      let allLedgerAcc = [];
      r.map((r, i) => {
        allLedgerAcc.push({
          value: r.ledger_account_id,
          label: r.ledger_account,
        });
      });
      return onSuccess(allLedgerAcc);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllCategory = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/category/list", {

    });
    if (res.status === 200) {

      const r = res.data;
      let allCats = [];
      let hsnarr = [];
      r.map((r) => {
        allCats.push({
          value: r.category_id,
          label: toTitleCase(r.category),
          hsn: r.hsn,
          gst: r.gst,
        });
      });
      onSuccess(allCats);

    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
//get hsn code Lists
export const getAllHSN = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/category/list", {
      short_data: true,
    });
    if (res.status === 200) {
      const r = res.data;
      let arr = [];

      r.map((r) => {
        arr.push({
          value: r.category_id,
          label: r.hsn,
        });
      });
      onSuccess(arr);

    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
export const postFileUpload = async (file, onSuccess, onFailure) => {
  let formData = new FormData();
  formData.set("file", file);
  try {
    const res = await webApi.post(
      "https://api.Transport.app/files/upload?uploadpath=item",
      formData
    );
    if (res.status === 200) {
      const r = res.data;
      if (r.success) {
        onSuccess(r);
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


//item price update

export const updateItemPrice = async (items, onSuccess, onFailure) => {
  console.log("excel data 1=>",Array.isArray(items))

  try {
    const res = await webApi.post('/master/itemPriceUpdate/update', {
      items: items
    })

    console.log("excel data res=>",res)
    if (res.status === 200){
      onSuccess(res.data)
    }
    else{
      onFailure("Failed")
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error)

  }
}