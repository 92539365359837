import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  
  getAllMaterial,
  postMaterialType,
  getAllMaterialType,
  updateMaterialType


} from "../../../services/materialTypeService";


import { getAllModule } from "../../../services/modulesService";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Box, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import theme from "../../../theme/theme";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";

import React from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import IconButton from "@material-ui/core/Button";
import { currentDate } from "../HelperComponent/utils";
// import { getAllLocation } from "services/locationService";

const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const toTitleCase = (str) => {
  if(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  else {
    return "";
  }
  
}

const materialTypePage = () => {
  const classes1 = useStyles1();
  const history = useHistory();
  const [modules, setAllModules] = React.useState([]);
  const [classicModal, setClassicModal] = React.useState(false);
  const [material, setAllMaterial] = React.useState([]);
  const [allType, setAllType] = React.useState([]);
  const [allBrand, setAllBrand] = React.useState([]);
  const [tyre, setAllTyreBrand] = React.useState([]);
  const [location,setAllLocation] = React.useState([]);
  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [materialType,setAllMaterialType] = React.useState([]);
  const userRole = globalState?.user?.user_role;

//   const [allMaterial,setAllMaterial] = React.useState([]);
  const [value, setValue] = React.useState();
  const [addMaterial, setAddMaterial] = React.useState({
    edit: false,
    txt_keyword_pharse: "",
    material_type_rate:'',
    txt_material_type: "",
    txt_details: "",
    txt_gst: "",
    txt_hsn: "",
    ddl_material_id: "",
    ddl_material_label: "",
    txt_from_date: currentDate(),    
    txt_to_date:currentDate(),    
    switch_active_status: true,
  });
  const [error, setError] = React.useState({
    ddl_location: false,
    txt_material: false,
  });


const fontStyle={
    fontFamily: "Sans-serif"
  };



 React.useEffect(() => {
    // setLoading(true);


    getAllMaterial(
        (material) => {
          setAllMaterial(material);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
        }
      );

    //   getAllMaterialType(
    //   (type) => {
    //     setAllMaterialType(type);
    //     setLoading(false);
    //   },
    //   (err) => {
    //     setLoading(false);
    //     dispatch({
    //       type: actionTypes.SET_OPEN_MSG,
    //       payload: { msg: err, msgType: "error" },
    //     });
    //   }
    // );

    // getAllTyreBrand(
    //   (tyre) => {
    //     setAllTyreBrand(tyre);
    //     setLoading(false);
    //   },
    //   (err) => {
    //     setLoading(false);
    //     dispatch({
    //       type: actionTypes.SET_OPEN_MSG,
    //       payload: { msg: err, msgType: "error" },
    //     });
    //   }
    // );


  }, [refresh]);

//options of no of wheels 


  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddMaterial((prv) => ({ ...prv, [name]: value }));
   
  };
  const formData = {
    formName: "Add Material Type",
    fields: [
    
   
      {
        name: "ddl_material",
        value: addMaterial,
        label: "Material",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "select",
        error: false,
        options: material,
        xs: 6,
        md: 6,
        lg: 6,
      }, 
      {
        name: "txt_material",
        label: "Material Type",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
    },  
        
      {
        name: "txt_hsn",
        label: "HSN",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
      },
      {
        name: "txt_material_type_rate",
        label: "Rate",
        hidden: false,
        required: false,
        data_type: "number",
        html_element: "TextField",
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
      },
      {
        name: "txt_gst",
        label: "GST%",
        hidden: false,
        required: false,
        data_type: "number",
        html_element: "TextField",
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
      },
      {
        name: "txt_from_date",
        label: "Effective From Date",
        hidden: false,
        // required: true,
        data_type: "Date",
        html_element: "Date",
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
      },
      // {
      //   name: "txt_to_date",
      //   label: "Effective To Date",
      //   hidden: false,
      //   // required: true,
      //   data_type: "Date",
      //   html_element: "Date",
      //   error: false,
      //   xs: 6,
      //   md: 6,
      //   lg: 6,
      // },
   
      {
        name: "txt_details",
        label: "Details",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextArea",
        error: false,
        xs: 12,
        md: 12,
        lg: 12,
      },
  
      {
        name: "switch_active_status",
        label: "Active Status",
        defaultValue: false,
        required: false,
        data_type: "string",
        html_element: "switch",
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
        
      },
    ],
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };


  const onSetActive = (e) => {
    setAddMaterial((prv) => ({ ...prv, [e.target.name]: e.target.checked }));
  };

  const headerData = [
    {
      id: "id",
      label: "#",
    },
    {
      id:"material_name",
      label:"Material Name"
    },
    {
      id: "material_type_name",
      label: "Material Type",
    },
    {
      id: "hsn",
      label: "HSN",
    },
  
    {
      id: "gst",
      label: "GST%",
    },

  

    {
      id: "details",
      label: "Details",
    },
    {
      id: "status",
      label: "Status",
      statusBtn: (v) => (v === "Y" ? true : false),
    },
    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];
 
  const onSubmitModel = (e) => {
    e.preventDefault();
    if (!addMaterial.txt_material) {
      setError({
        ddl_material:!addMaterial.ddl_material,
        txt_material: !addMaterial.txt_material,
      });
    } else {
      if (addMaterial.edit) {
        updateMaterialType(
          addMaterial,
          (r) => {
            setClassicModal(false);
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Material Updated Successfully",
                msgType: "success",
              },
            });
            setRefresh(!refresh);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
      } else {
        postMaterialType(
          addMaterial,
          globalState?.user?.serial_id,
          (r) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Material added Successfully",
                msgType: "success",
              },
            });
            setClassicModal();
            setRefresh(!refresh);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setButtonDisabled(true);
          }
        );
      }
    }
  };
  // on Delete called
  const onDeleteVehicle = (row, next) => {
    deleteVehicle(
      row.vehicle_id,
      (r) => {
        next();
        setRefresh(!refresh);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  // on Edit called
  const onUpdateMaterial = (row) => {
    setClassicModal(true);
    setAddMaterial({
      ...addMaterial,
      edit: true,
      switch_active_status: row.status === "Y" ? true : false,
      material_type_id:row.material_type_id,
    
     
      txt_hsn: row.hsn,
      txt_gst: row.gst,
      txt_material_type_rate:row.material_type_rate,
      ddl_material_id:row.material_id,
      ddl_material_label:row.material_name,
      txt_material: row.material_type_name,

      ddl_location: row.location,
      location_id: row.location_id,
      txt_details: row.details,
      txt_from_date: row.effective_from_date,
      txt_to_date: row.effective_to_date,

    });
  };

  const onSelectModule = (event, value) => {
    

    switch (value.name) {
      case "ddl_material":
      
        setAddMaterial({
          ...addMaterial,
          ddl_material_id: event.value,
          ddl_material_label: event.label,
        });
        break;        
       }
  };
  const onChange = (e) => {
    const { value, name } = e.target;
    console.log(e.target.checked,"sen23",name,value)
    if (name === "txt_material"  ) {
      setAddMaterial((prv) => ({
        ...prv,
        ["txt_material"]: toTitleCase(value),
       
      }));
    }else{
    setAddMaterial({ ...addMaterial, [name]: value });
    }
  };
  const classes = useStyles();

  // on Close Model
  const onCloseModel = () => {
    setClassicModal(false);
    setError({ txt_vehicle: false });
    setAddMaterial({
      edit: false,
      material_id: "",
      txt_keyword_pharse: "",
      txt_material: "",
      txt_details: "",
      txt_hsn: "",
      txt_gst: "",
      ddl_location_id: "",
      ddl_location_label: "",      
      txt_from_date: currentDate(),
      txt_to_date: currentDate(),
      switch_active_status: true,
    });
  };
  const onSearchMaterial = (e) => {
    e.preventDefault();
    setLoading(true);

    getAllMaterialType(
      (type) => {
        setAllMaterialType(type);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
    // if (addMaterial.txt_keyword_pharse) {
    //   getAllMaterial(
    //     (material) => {
    //       if (material.length) {
    //         setAllMaterial(material);
    //         setLoading(false);
    //       } else {
    //         setLoading(false);
    //         dispatch({
    //           type: actionTypes.SET_OPEN_MSG,
    //           payload: { msg: "Material not found",msgType: "info" },
    //         });
    //       }
    //     },
    //     (err) => {
    //       // change by sankha
    //       setAllMaterial([]);
    //       dispatch({
    //         type: actionTypes.SET_OPEN_MSG,
    //         payload: { msg: err, msgType: "error" },
    //       });
    //       setLoading(false);
    //     },
    //     addMaterial
    //   );
    
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddMaterial({
      txt_keyword_pharse: "",
      ddl_group_id: "",
    });
  };

  // export to excel

  const onhandleExportToExcel = () => {
    const VehicleTable = material.map((acc) => {
      return {
        ID: acc.id,
        Vehicle_type: acc.vehicle_type,
        Vehicle_no: acc.vehicle_no,
        Contact_person: acc.contact_person,
        Contact_no: acc.contact_no,
        Details: acc.details,

        Status: acc.status,
        Action: "",
      };
    });

    const fileName = "Vehicle ";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(VehicleTable);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Master > Material Type"
        btnToolTip="Add New Material Type"
        onClickAddBtn={() => setClassicModal(true)}
      />
      <>
        <GridContainer className={classes.root}>
          <GridItem xs="12">
            <CustomCard cdTitle="Search Material Type">
              <Paper elevation="0" className={classes.searchBar}>
                <GridContainer
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <GridItem xs="6">
                    <InputLabel id="label">Keyword / Phrase</InputLabel>
                    <TextField
                      size="small"
                      placeholder="Keyword / Phrase"
                      style={{ marginBottom: -15 }}
                      type="search"
                      name="txt_keyword_pharse"
                      onChange={onAddSearch}
                      id="outlined-basic"
                      fullWidth={true}
                      value={addMaterial.txt_keyword_pharse}
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs="6">
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomButton
                        name="btn_refres"
                        style={{ marginRight: "10px" }}
                        onClick={onSearchMaterial}
                      >
                        <SearchIcon />
                      </CustomButton>
                      <CustomButton name="btn_refres" onClick={onClickRefresh}>
                        <RotateLeftIcon />
                      </CustomButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </Paper>
            </CustomCard>
          </GridItem>

          <MasterModel
            classicModal={classicModal}
            onCloseModel={onCloseModel}
            width={450}
            height="auto"
            okBtnText="Submit"
            modelName={addMaterial.edit ? "Edit Material Type" : "Add Material Type"}
            onClickOk={onSubmitModel}
            disabled={buttonDisabled}
          >
              <div style={{ padding: "20px 10px", width: "100%" }}>
          
            <GridContainer>
                {formData.fields.map((item, key) => {
                  return (
                    <>
                      <GridItem
                       xs={item.xs} 
                       key={key}
                       lg={item.lg} 
                       md={item.md} 
                       style={{ padding:5}}>
                        {item.html_element === "Date" && (
                          <>
                            <InputLabel required={item.required} id="label">
                              {item.label}
                            </InputLabel>
                            <TextField
                              size="small"
                              type="date"
                              required={item.required}
                              placeholder={item.label}
                              name={item.name}
                              onChange={onChange}
                              id="outlined-basic"
                              // error={error[item.name]}
                              // FormHelperTextProps={{
                              //     style: { textAlign: "right" },
                              // }}
                              // helperText={
                              //     error[item.name]
                              //         ? item.label + "  is required"
                              //         : ""
                              // }

                              fullWidth={true}
                              value={addMaterial[item.name]}
                              variant="outlined"
                            />
                          </>
                        )}
                        {item.html_element === "select" && (
                          <>
                          
                            <InputLabel required={item.required} id="label">
                              {item.label}
                            </InputLabel>
                       
                            <Select
                              options={item.options}
                              placeholder="Select"
                              formatGroupLabel={(d) => d.label}
                              // menuPortalTarget={document.body}
                              className={classes.customSelect}
                              onChange={onSelectModule}
                              name={item.name}
                              value={{
                                value: addMaterial.ddl_material,
                                label: addMaterial.ddl_material_label,
                              }}
                            />
                            
                          </>
                        )}
                        {item.html_element === "radio_button" && (
                          <>
                            <InputLabel required={item.required} id="label">
                              {item.label}
                            </InputLabel>
  
                            <RadioGroup
                                row
                                name="vehicle_ownership"
                                onChange={onChange}
                              >
                            <FormControlLabel style={fontStyle} value="rented" control={<Radio />} label="Rented" />
                            <FormControlLabel value="own" control={<Radio />} label="Own" />
                           </RadioGroup>
                          </>
                        )}
                        {item.html_element === "TextField" && (
                          <>
                            <InputLabel required={item.required} id="label">
                              {item.label}
                            </InputLabel>
                            <TextField
                              size="small"
                              required={item.required}
                              placeholder={item.label}
                              name={item.name}
                              onChange={onChange}
                              id="outlined-basic"
                              error={error[item.name]}
                              FormHelperTextProps={{
                                style: { textAlign: "right" },
                              }}
                              helperText={
                                error[item.name]
                                  ? item.label + "  is required"
                                  : ""
                              }
                              fullWidth={true}
                              value={addMaterial[item.name]}
                              variant="outlined"
                            />
                          </>
                        )}
                        {item.html_element === "TextArea" && (
                          <>
                            <InputLabel id="label">{item.label}</InputLabel>

                            <TextField
                              placeholder={item.label}
                              name={item.name}
                              onChange={onChange}
                              multiline
                              style={{ marginBottom: -10 }}
                              rows={5}
                              id="outlined-basic"
                              fullWidth={true}
                              value={addMaterial[item.name]}
                              variant="outlined"
                            />
                          </>
                        )}
                        {item.html_element === "switch" && (
                          <>
                            <span className={classes.activeText}>
                              {item.label}
                            </span>
                            <Switch
                              onClick={onSetActive}
                              checked={addMaterial[item.name]}
                              name={item.name}
                              fullWidth={true}
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              color="primary"
                            />
                          </>
                        )}
                      </GridItem>
                    </>
                  );
                })}
            </GridContainer>
            </div>

          </MasterModel>

          <GridItem xs="12">
            {loading ? (
              <Box mt={10} width="100%" textAlign="center">
                <CircularProgress />
              </Box>
            ) : (
              <Card className={classes1.headerCard}>
                <CardHeader
                  className={classes1.TbheaderCdhd}
                  style={{ height: 60 }}
                >
                  <GridContainer
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <GridItem>
                      <h4 className={classes1.headerCdTitle}>Material Type</h4>
                    </GridItem>
                    <GridItem style={{ cursor: "pointer" }}>
                      <IconButton
                        variant="text"
                        onClick={() => onhandleExportToExcel(material)}
                      >
                        <Tooltip title="Export to Excel">
                          <img
                            src={
                              require("../../../assets/img/excel.png").default
                            }
                          />
                        </Tooltip>
                      </IconButton>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody
                  style={{ height: "auto", maxHeight: 480, padding: 10 }}
                  className={clxs(classes.customScroolBar)}
                >
                  <MuiTable
                    onClickEdit={onUpdateMaterial}
                    onClickDelete={onDeleteVehicle}
                    columns={headerData}
                    rows={materialType}
                userRole={userRole}

                  />
                </CardBody>
              </Card>
            )}
          </GridItem>
        </GridContainer>
      </>
    </ThemeProvider>
  );
};

export default materialTypePage;
