import React, { useEffect, useState } from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/addIemPageStyle.js";
import TextField from "@material-ui/core/TextField";
import { Input, Box, Paper } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import Select from "react-select";
// import React from "react";
import { useStateValue } from "../../../../context/context";
import { actionTypes } from "../../../../context/reducer";
import { useHistory } from "react-router-dom";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import PageTitle from "../../HelperComponent/PageTitle";
import { CustomCard } from "../../../Components/CustomCard";
import MasterModel from "../../../Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";

import theme from "../../../../theme/theme";
import { currentDate, currentDate1 } from "views/Pages/HelperComponent/utils";

import { Grid } from "@material-ui/core";
import {
  postAdvance,
  getAllUnloadingLocation,
  getAllAdvance,
  getOiledVehicle,
  getAllExpenses,
  getAllAdvanceLocation,
} from "../../../../services/advanceService";
import CustomButton from "views/Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import MuiTable from "../../../Components/MuITable";
import { number } from "fx/config";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { currentTime } from "views/Pages/HelperComponent/utils";
import { IconButton, OutlinedInput } from "@material-ui/core";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import ExcelJS from 'exceljs';

const useStyles2 = makeStyles(styles);

const useStyles = makeStyles(styles);


const useStyles1 = makeStyles((styles) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },

}));

const Advance = () => {
  const classes1 = useStyles2();

  const [globalState, dispatch] = useStateValue();
  const history = useHistory();

  const classes = useStyles();
  const classs = useStyles1();
  const [loading, setLoading] = useState(false);
  const [addSearch, setAddSearch] = useState({
    txt_vehicle_no: '',
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });
  const [refresh, setRefresh] = useState(false);
  const [advanceDetails, setAllAdvanceDetails] = useState([]);

  const [collapsible, setCollapsible] = useState(true)
  const userRole = globalState?.user?.user_role;

  const headerData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "advance_time",
      label: "Date",
      align: "left",
    },
    {
      id: "vehicle_no",
      label: "Vehicle No",
      align: "left",
    },
    {
      id: "transaction_no",
      label: "Transaction",
      align: "left",
    },
    {
      id: "vehicle_trip_no",
      label: "Trip No",
      align: "right",
    },
    // {
    //   id: "location",
    //   label: "Location",
    //   align: "left",
    // },

    {
      id: "Expence",
      label: "Expense",
      align: "right",

    },
    {
      id: "actual_expense",
      label: "Approve Amount",
      align: "right",

    },
    {
      id: "adv_office",
      label: "Adv From Office",
      align: "right",
    },
    {
      id: "action",
      label: "Action",
      align: "right",
  },
  ];

  const onClickCollaps = () => {
    console.log("Reached23")
    collapsible ?
      setCollapsible(false)
      :
      setCollapsible(true)
  }

  const onAddSearch = (e) => {

    const { name, value } = e.target;
    setAddSearch({ ...addSearch, [name]: value })
  }

  const onSearchAdvance = (e) => {
    e.preventDefault();
    setRefresh(false);
    setLoading(true);
    console.log(addSearch, "sank/19081")

    getAllAdvance(
      addSearch,
      (advanceDetails) => {
        if (advanceDetails.length) {
          setAllAdvanceDetails(advanceDetails);
        }
        else {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: "Advance Details Not Found", msgType: "error" },
          });
        }
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );

  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddSearch({
      txt_vehicle_no: '',
      txt_from_date: currentDate1(),
      txt_to_date: currentDate(),
    });
  };

    const onViewAdvance = (event)=>{
      history.push({
        pathname: "/admin/tranportation/AdvanceView",
        advance_id:event.advance_id,
    });
    }

    const onClickEdit = (row) => {
      console.log("san=>", row);
      history.push({
        pathname: "/admin/tranportation/AdvanceForm",
        advance_id: row.advance_id,
        advance_order_no:row.advance_order_no,
        // transaction_no:row.transaction_no,
        edit:true
      });
    };


    //New Excel
    const onhandleExportToExcel = async () => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('data');
    
      const titleRow = worksheet.addRow(['Advance Report']);
      const periodRow = worksheet.addRow(['Period:']);
      worksheet.addRow([]);
    
      const titleCell = titleRow.getCell(1);
      const periodCell = periodRow.getCell(1);
      titleCell.alignment = { vertical: 'middle', horizontal: 'center', bold: true };
      periodCell.alignment = { vertical: 'middle', horizontal: 'center', bold: true };
    
      worksheet.mergeCells(`A${titleRow.number}:H${titleRow.number}`);
      worksheet.mergeCells(`A${periodRow.number}:H${periodRow.number}`);
    
      const headers = [
        "Sl No",     
        "Vehicle No",
        "Transaction No",
        "Trip No",
        "Location",
        "Expence",
        "Approve Amount",
        "Adv From Office",
       
      ];
    
      // Add headers as a row
      worksheet.addRow(headers);
    
      const advanceTable = advanceDetails.map((advance) => {
        return {
          Sl_No: advance.id,
          Vehicle_no:advance.vehicle_no,        
          Transaction_No:advance.transaction_no,
          Trip_no:advance.vehicle_trip_no,
          Location:advance.location,
          Expence:advance.Expence,
          Approve_Amount : advance.actual_expense,
          Adv_From_Office: advance.adv_office,
        };
      });
    
      const dataStartRow = 4;
    
      const headerRow = worksheet.getRow(dataStartRow);
      headerRow.height = 20;
    
      headers.forEach((header, index) => {
        const column = worksheet.getColumn(index + 1);
        column.width = header.length + 5;
      });
    
      advanceTable.forEach((data) => {
        worksheet.addRow(Object.values(data));
      });
    
      headerRow.font = { bold: true };
      headerRow.eachCell((cell, colNumber) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '6dc8da' } };
      });
    
      worksheet.eachRow({ startingRow: dataStartRow + 1 }, (row, rowNumber) => {});
    
      worksheet.autoFilter = {
        from: {
          row: dataStartRow,
          column: 1,
        },
        to: {
          row: dataStartRow,
          column: headers.length,
        },
      };
      
    
      const fromDate = addSearch?.txt_from_date;
      const toDate = addSearch?.txt_to_date;
      const chosenPeriod = ` ${fromDate}  To  ${toDate}`;
      periodCell.value = `Period:  ${chosenPeriod}`;
    
      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });
      saveAs(data, 'AdvanceTable.xlsx');
    };




    // export to excel
    // const onhandleExportToExcel = () => {
    //   const advanceTable = advanceDetails.map(advance => {
    //     return {
    //       Sl_No: advance.id,
    //       Vehicle_no:advance.vehicle_no,        
    //       Transaction_No:advance.transaction_no,
    //       Trip_no:advance.vehicle_trip_no,
    //       Location:advance.location,
    //       Expence:advance.Expence,
    //       Approve_Amount : advance.actual_expense,
    //       Adv_From_Office: advance.adv_office,
         
    //     }
    //   })
    //   const fileName = 'AdvanceDetails'
    //   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //   const fileExtension = '.xlsx';
    //   const ws = XLSX.utils.json_to_sheet(advanceTable);
    //   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //   const data = new Blob([excelBuffer], { type: fileType });
    //   FileSaver.saveAs(data, fileName + fileExtension);
    // }

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Transportation   >  Advance"
        btnToolTip="Add New Advance"
        addBtnLink="/admin/tranportation/AdvanceForm"
      />
      <GridContainer>
        <CustomCard
          cdTitle="Search Advance"
          btnToolTip={collapsible ? "Collaps" : "Expand"}
          onClickCollapsible={onClickCollaps}
          buttonAction={collapsible}
          filterIcon
        >
          {
            collapsible ?
              <Paper elevation="0" className={classes.searchBar} >
                <GridContainer justifyContent="flex-start" alignItems="flex-end">
                  <GridItem xs="3">
                    <InputLabel id="label">Vehicle No</InputLabel>
                    <TextField

                      size="small"
                      placeholder="vehicle No"
                      name="txt_vehicle_no"
                      onChange={onAddSearch}
                      type="search"
                      id="outlined-basic"
                      fullWidth={true}
                      value={addSearch.txt_vehicle_no}
                      variant="outlined"
                    // onClick={(e) => { e.target.select() }}
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel id="label">Date Between</InputLabel>
                    <TextField
                      size="small"
                      name="txt_from_date"
                      id="date"
                      variant="outlined"
                      type="date"
                      fullWidth={true}
                      value={addSearch.txt_from_date}
                      onChange={onAddSearch}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={(e) => { e.target.select() }}
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <TextField
                      name="txt_to_date"
                      size="small"
                      id="date"
                      variant="outlined"
                      type="date"
                      fullWidth={true}
                      value={addSearch.txt_to_date}
                      onChange={onAddSearch}
                      className={classes.dateField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs="9">
                    <div className={classs.searchbtnRight}>
                      <CustomButton
                        type="submit"
                        onClick={onSearchAdvance}
                        name="btn_refresh"
                        style={{ marginRight: "10px" }}
                      >

                        <SearchIcon />
                      </CustomButton>
                      <CustomButton
                        onClick={onClickRefresh}
                      >
                        <RotateLeftIcon />
                      </CustomButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </Paper>
              : ""
          }
        </CustomCard>
      </GridContainer>
      {advanceDetails.length > 0 ?
        <GridContainer>
          <GridItem xs="12">
          <Card className={classes1.headerCard}>
              <CardHeader
                className={classes1.TbheaderCdhd}
                style={{ height: 60 }}
              >
                <GridContainer
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <GridItem>
                    <h4 className={classes1.headerCdTitle}>
                    Advance Details
                    </h4>
                  </GridItem>
                  <GridItem style={{ cursor: "pointer" }}>
                   

                    <IconButton
                      variant="text"
                      onClick={() => onhandleExportToExcel(advanceDetails)}
                    >
                      <Tooltip title="Export to Excel">
                        <img
                          src={require("../../../../assets/img/excel.png").default}
                        />
                      </Tooltip>
                    </IconButton>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody
                style={{ height: "auto", maxHeight: 480, padding: 10 }}
                className={clxs(classes.customScroolBar)}
              >
            {/* <CustomCard cdTitle="Advance Details"> */}
              {!refresh ?
                <MuiTable
                onClickViewOne={onViewAdvance}
                onClickEdit={onClickEdit}

                  columns={headerData}
                  rows={advanceDetails}
                  userRole={userRole}
                />
                : ""}
            {/* </CustomCard> */}
            </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        : ''
      }


    </ThemeProvider>
  );
};

export default Advance;
