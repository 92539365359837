import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import {
  dateFormate,
  timeToUnix,
  IstTime,
} from "../views/Pages/HelperComponent/utils";

export const getAllVehicle = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/vehicle/list", {});
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "list");

      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({
          value: r.vehicle_id,
          label: r.vehicle_no,
          vehicle_brand: r.vehicle_brand,
          no_of_wheels: r.no_of_wheels,
          engine_number: r.engine_number,
          chassis_number: r.chassis_number,
          vehicle_ownership: r.vehicle_ownership,
        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllUomById = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/uom/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allUoms = [];
      r.map((r, i) => {
        allUoms.push({
          id: i + 1,
          value: r.uom_id,
          label: r.unit,
        });
      });
      return onSuccess(allUoms);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllVehicleDetails = async (
  vehicle_id,
  onSuccess,
  onFailure
) => {
  try {
    const res = await webApi.post("/master/maintance/list", {
      vehicle_id: vehicle_id,
    });
    if (res.status === 200) {
      const r = res.data;

      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({
          id: r.vehicle_id,
          vehicle_no: r.vehicle_no,
          vehicle_brand: r.vehicle_brand,
          no_of_wheels: r.no_of_wheels,
          engine_number: r.engine_number,
          chassis_number: r.chassis_number,
          vehicle_ownership: r.vehicle_ownership,
        });
      });

      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllCategory = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/category/list", {});
    if (res.status === 200) {
      const r = res.data;
      // console.log(res.data);
      let allCats = [];
      r.map((r, i) => {
        allCats.push({
          id: i + 1,
          value: r.category_id,
          label: r.category,
        });
      });
      return onSuccess(allCats);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllItemById = async (q, onSuccess, onFailure) => {
  try {
    console.log(q, "sankq");
    const res = await webApi.post("/master/item/list", {
      category_id: q,
    });
    if (res.status === 200) {
      const r = res.data;
      let allItems = [];
      r.map((r, i) => {
        allItems.push({
          value: r.item_id,
          label: r.item,
          uom: r.uom,
        });
      });
      return onSuccess(allItems);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const postMaintance = async (
  maintanceDetailes,
  spareList,
  serviceList,
  journal_details,
  grandTotal,
  inserted_by_id,
  onSuccess,
  onFailure
) => {
  try {
    const res = await webApi.post("/master/maintance/insert", {
      vehicle_id: maintanceDetailes.vehicle_id,
      vehicle: maintanceDetailes.vehicle_no,
      maintainance_date: timestamp(maintanceDetailes.maintainance_date),
      vehicle_details: maintanceDetailes,
      spare_Parts: spareList,
      service_List: serviceList,
      maintance_amount: Number(grandTotal),
      active_status: maintanceDetailes.switch_active_btn ? "Y" : "N",
      inserted_by_id: inserted_by_id,
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "sanhagr");
      const journalData = await webApi.post("/master/journal/insert", {
        journal_type: "J",
        transaction_id: r.maintenance_order_no,
        transaction_type: "Maintance",
        transaction_type_id: r.maintenance_order_no,
        voucher_amount: Number(grandTotal),
        voucher_date: timestamp(maintanceDetailes.maintainance_date),
        journal_details: journal_details,
      });
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllSpareParts = async (q,onSuccess, onFailure) => {
  console.log( timestamp(q?.txt_from_date), "sankq",timestamp(q?.txt_to_date));

  try {
    const res = await webApi.post("/master/maintance/spare/list", {
      vehicle_no: q.txt_vehicle_no,
      txt_from_date: timestamp(q?.txt_from_date),
      txt_to_date: timestamp(q?.txt_to_date),
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "list");

      let spare = [];
      r.map((r, i) => {
        spare.push({
          id: i + 1,
          maintenance_id: r.maintenance_id,
          vehicle_id: r.vehicle_id,
          vehicle_no: r.vehicle,
          category: r.spare_Parts?.ddl_category_label,
          item: r.spare_Parts?.ddl_item_label,
          qty: r.spare_Parts?.txt_qty,
          rate: r.spare_Parts?.txt_rate,
          amount: r.spare_Parts?.txt_amount,
          maintance_amount: r.maintance_amount,
          maintainance_date: dateFormate(r.maintainance_date),
          labour_charges: r.spare_Parts?.txt_labour_charges,
          traansaction_no: r.maintenance_order_no,
          service_List:r.service_List,
          action_items: r.action_items,
          action: "action",
        });
      });
      return onSuccess(spare);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllItemChecking = async (
  q,
  onSuccess,
  onFailure,
  menu = true
) => {
  try {
    const res = await webApi.post("/master/item/list", {
      keyword_pharse: q,
    });
    if (res.status === 200) {
      const r = res.data;
      let allItems = [];
      r.map((r, i) => {
        allItems.push({
          id: i + 1,
          item_id: r.item_id,
          category: r.category_name,
          category_id: r.category_id,
          brand: r.brand_name,
          brand_id: r.brand_id,
          item: r.item,
          item_own_code: r.item_own_code,
          item_company_code: r.item_company_code,
          details: r.details,
          uom_name: r.uom_name,
        });
      });
      if (allItems.length) {
        return onSuccess(allItems);
      } else {
        onFailure("item not found ");
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const ViewMaintanceDetails = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/maintance/viewDetails", {
      maintenance_id: id,
    });

    if (res.status === 200) {
      const r = res.data;
      return onSuccess(r[0]);
    } else {
      onFailure("Something Wrong! Please Try again later");
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};


export const getMaintanceReport = async (
  q,
  onSuccess,
  onFailure
) => {
  console.log(q,"sankhaq")
  try {
    const res = await webApi.post("/master/maintanceReport/list", {
      vehicle_id: q?.ddl_vehicle_id?.value,
      txt_from_date: timestamp(q?.txt_from_date),
      txt_to_date: timestamp(q?.txt_to_date),

    });
    if (res.status === 200) {
      const r = res.data;

      let all_vehicles = [];
      r.map((r, i) => {
        console.log(r,"sankhacheck:")
        all_vehicles.push({
          id: (i + 1),
          vehicle_id:r.vehicle_id,
          vehicle_no: r.vehicle,
          maintenance_order_no:r.maintenance_order_no,          
          maintance_amount:r.maintance_amount,          
          maintainance_date : dateFormate(r.maintainance_date)
         
        });
      });

      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};