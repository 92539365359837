import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";

import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Paper } from "@material-ui/core";

import { ThemeProvider, Box } from "@material-ui/core";

import ReactSelect from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Autocomplete } from "@material-ui/lab";
import { IconButton, OutlinedInput } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { currencyFormate } from "views/Pages/HelperComponent/utils";

//Tables
import {
  appFontWeightThin,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableRow";



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "6px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "0px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  customSelect: {
    marginBottom: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
    "&:hover": {
      backgroundColor: "rgba(43,43, 43, 0.03)",
    },
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);
//Theme
import theme from "../../../theme/theme";
//Services

// import React from "react";
import {
  appDefaultColor,
  appSecondColor,
  reactSelectStyles,
} from "assets/jss/material-dashboard-pro-react";
import { appDefaultFamily } from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import React, { useState } from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  currentDate,
  currentDate1,
  currentDate1Pdf,
  currentDatePdf,
} from "../HelperComponent/utils";

import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import {
  getAllVehicles,
  getSearchVehicle,
} from "services/totalDistanceCoveredService";
import { getDailyTruckReport } from "services/dailyTruckReportService";
import { getMaintanceReport } from "services/maintanceService";

const useStyles1 = makeStyles(styles);

//PDF
// import pdfIcon from "../../../assets/img/pdf-icon.png"
// import { jsPDF } from "jspdf";
const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  narraction: {
    width: 155,
  },
  date: {
    width: 88,
  },
  voucher: {
    width: 190,
  },
  particular: {
    width: 110,
  },

  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const onChange = (e) => {
  const { value, name } = e.target;
  setAddBrand({ ...allUnits, [name]: value });
};

const MaintanceReport = () => {
  const classes1 = useStyles1();

  const history = useHistory();
  const [globalState, dispatch] = useStateValue();
  const [vehicleNumber, setvehicleNumber] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);

  const [addSearch, setAddSearch] = React.useState({
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
    ddl_vehicle_id: "",
  });

  const [distanceList, setDistanceList] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const [collapsible, setCollapsible] = React.useState(true);
  const totalMaintenanceAmount = distanceList.reduce((sum, row) => sum + row.maintance_amount, 0);
  const fetchData = () => {
    getAllVehicles(
      (r) => {
        console.log("R:", r);
        setvehicleNumber(r);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddSearch((prv) => ({ ...prv, [name]: value }));
  };

  const onSelect = (info, v) => {
    setAddSearch({ ...addSearch, [info.name]: v });
    console.log(addSearch, "check1");
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddSearch({
      txt_from_date: currentDate(),
      ddl_vehicle_id: "",
    });
  };

  const onSearchDistance = (e) => {
    e.preventDefault();
    setLoading(true);
    getMaintanceReport(
      addSearch,
      (list) => {
        setDistanceList(list);

        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const classes = useStyles();

  const onClickCollaps = () => {
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };

  const onhandleExportToExcel = () => {
    const MaintanceReportTable = distanceList.map((Payment) => {
      return {
        SL_No: Payment.id,
        Truck_No: Payment.vehicle_no,
        Maintance_Date: Payment.maintainance_date,
        Maintenance_Order_No: Payment.maintenance_order_no,
        Maintance_Amount: Payment.maintance_amount,
      };
    });

    const fileName = "Maintance Report List";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(MaintanceReportTable);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitle title="MIS Report > Daily Maintance Report" />

      <GridContainer>
        <GridItem xs="12">
          <CustomCard
            cdTitle="Search Daily Maintance Report"
            btnToolTip={collapsible ? "Collaps" : "Expand"}
            onClickCollapsible={onClickCollaps}
            buttonAction={collapsible}
            filterIcon
          >
            {collapsible ? (
              <Paper elevation="0" className={classes.searchBar}>
                <GridContainer justifyContent="flex-start" alignItems="center">
                  <GridItem xs="4">
                    <InputLabel id="label">Vehicle No.</InputLabel>
                    <ReactSelect
                      options={vehicleNumber}
                      getOptionLabel={(option) => option.label}
                      placeholder="Select"
                      formatGroupLabel={(d) => d.label}
                      menuPortalTarget={document.body}
                      className={classes.customSelect}
                      styles={reactSelectStyles}
                      name="ddl_vehicle_id"
                      onChange={(v, info) => onSelect(info, v)}
                      value={addSearch.ddl_vehicle_id}
                    />
                  </GridItem>

                  <GridItem xs="2">
                    <InputLabel id="label">Date Between</InputLabel>
                    <TextField
                      name="txt_from_date"
                      size="small"
                      id="date"
                      variant="outlined"
                      // style={{ marginBottom: -10 }}
                      type="date"
                      fullWidth={true}
                      defaultValue={currentDate1()}
                      value={addSearch.txt_from_date}
                      onChange={onAddSearch}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs="2">
                  <TextField
                    name="txt_to_date"
                    size="small"
                    id="date"
                    variant="outlined"
                    type="date"
                    fullWidth={true}
                    value={addSearch.txt_to_date}
                    onChange={onAddSearch}
                    className={classes.dateField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>

                  <GridItem xs="12">
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <CustomButton
                        style={{ marginRight: "10px" }}
                        onClick={onSearchDistance}
                      >
                        <SearchIcon />
                      </CustomButton>
                      <CustomButton onClick={onClickRefresh}>
                        <RotateLeftIcon />
                      </CustomButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </Paper>
            ) : (
              ""
            )}
          </CustomCard>
        </GridItem>
      </GridContainer>

      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <Card className={classes1.headerCard}>
            <CardHeader
              className={classes1.TbheaderCdhd}
              style={{ height: 60 }}
            >
              <GridContainer justifyContent="space-between" alignItems="center">
                <GridItem>
                  <h4 className={classes1.headerCdTitle}>
                    Maintance Report List
                  </h4>
                </GridItem>
                <GridItem style={{ cursor: "pointer" }}>
                  <IconButton
                    variant="text"
                    onClick={() => onhandleExportToExcel(distanceList)}
                  >
                    <Tooltip title="Export to Excel">
                      <img
                        src={require("../../../assets/img/excel.png").default}
                      />
                    </Tooltip>
                  </IconButton>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody
              style={{ height: "auto", maxHeight: 480, padding: 10 }}
              className={clxs(classes.customScroolBar)}
            >
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-label="customized table"
                  id="table_total_distance_covered"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">#</StyledTableCell>
                      {/* <StyledTableCell align="left">Date</StyledTableCell> */}
                      {/* <StyledTableCell align="center">Vehicle No</StyledTableCell>                       */}
                      <StyledTableCell align="center">Truck No</StyledTableCell>
                      <StyledTableCell align="center">
                        Maintance Date
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Maintenance Order No
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">Today Lorry No </StyledTableCell> */}
                      <StyledTableCell align="center">
                        Maintance Amount
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        Extra Labour Charges
                      </StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  {/* table Body start */}
                  <TableBody>
                    {distanceList.map((row, i) => (
                      <StyledTableRow key={row._id}>
                        <StyledTableCell align="left">{i + 1}</StyledTableCell>
                        <StyledTableCell align="center">
                          {row.vehicle_no}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.maintainance_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.maintenance_order_no}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.maintance_amount}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                   
                      <StyledTableCell align="right" colSpan={4}>
                       <b> Total Maintenance Amount </b>
                      </StyledTableCell>
                     
                      <StyledTableCell align="center">
                      <b> {totalMaintenanceAmount} </b>
                      </StyledTableCell>
                    
                  </TableFooter>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
};

export default MaintanceReport;
