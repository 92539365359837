import React from "react";
import { ThemeProvider, Box } from "@material-ui/core";
import PageTitle from "../HelperComponent/PageTitle";
import theme from "../../../theme/theme";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import { CustomCard } from "../../Components/CustomCard";
import { useHistory, useLocation } from "react-router-dom";
import FormComponent from "../HelperComponent/FormComponent";
import { ColoseButton, ColoseButton1 } from "../../Components/CustomButton";


import ViewCard, {
  AddressCard,
  ViewBankCard,
} from "../HelperComponent/ViewCard";

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  cardPadding: {
    padding: "10px  15px 0 15px",
  },
  catCards: {
    marginLeft: 5,
  },
}));

const headerData = [
  {
    id: "group_name",
    label: "Group",
    align: "left",
  },
  {
    id: "company",
    label: "Company",
    align: "left",
  },
  {
    id: "name",
    label: "Name",
    align: "left",
  },

  {
    id: "mobile",
    label: "Mobile",
    align: "left",
  },
  {
    id: "whatsapp",
    label: "WhatsApp",
    align: "left",
  },
  {
    id: "email",
    label: "Email",
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    align: "left",
    statusBtn: (v) => (v === "Y" ? true : false),
  },
];
const option = [
  { value: 1, label: "Dr" },
  { value: 2, label: "Cr" },
];

const formData = [
  {
    formName: "fistForm",
    fields: [
      // {
      //   name: "ddl_vendor_group",
      //   label: "Group",
      //   hidden: false,
      //   required: true,
      //   data_type: "string",
      //   html_element: "select",
      //   xs: 12,
      //   md: 6,
      //   lg: 4,
      //   options: allGroup,
      // },
      {
        name: "txt_company_name",
        label: "Company Name",
        hidden: false,
        required: true,
        align: "left",
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 12,
        md: 6,
        lg: 6,
      },
      {
        name: "txt_mobile",
        label: "Mobile/Whatsapp",
        hidden: false,
        required: false,
        data_type: "number",
        html_element: "TextField",
        xs: 12,
        md: 6,
        lg: 3,

      },
      {
        name: "txt_gst_no",
        label: "GST No",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 12,
        md: 6,
        lg: 3,
      },
      {
        name: "txt_website",
        label: "Website",
        hidden: false,
        required: false,
        align: "left",
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 12,
        md: 6,
        lg: 6,
      },

      // {
      //   name: "ddl_references",
      //   label: "References",
      //   hidden: false,
      //   required: false,
      //   data_type: "string",
      //   html_element: "select",
      //   xs: 12,
      //   md: 6,
      //   lg: 4,
      //   options: allReference,
      // },
      {
        name: "txt_openingBalance",
        label: "Opening Balance",
        hidden: false,
        required: false,
        data_type: "number",
        html_element: "TextField",
        xs: 12,
        md: 6,
        lg: 2,

      },
      {
        name: "ddl_dr_cr",
        label: "Debit/Credit",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "select",
        xs: 12,
        md: 6,
        lg: 2,
        options: option,
      },
      {
        name: "switch_active_status",
        label: " Active Status",
        defaultValue: false,         
        required: false,
        data_type: "string",          
        html_element: "switch",
        error: false,
        xs: 12,
        md: 12,
        lg: 1,
      
       
       
      },
    ],
  },

  // {
  //   formName: "addressForm",
  //   fields: [
  //     {
  //       name: "txt_name",
  //       label: "Name",
  //       hidden: false,
  //       required: true,
  //       align: "left",
  //       data_type: "string",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 4,
  //     },
  //     {
  //       name: "txt_mobile",
  //       label: "Mobile",
  //       hidden: false,
  //       required: true,
  //       align: "left",
  //       data_type: "number",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 2,
  //     },
  //     // {
  //     //   name: "txt_email",
  //     //   label: "Email",
  //     //   hidden: false,
  //     //   required: false,
  //     //   align: "left",
  //     //   data_type: "string",
  //     //   html_element: "TextField",
  //     //   error: false,
  //     //   xs: 12,
  //     //   md: 6,
  //     //   lg: 6,
  //     // },
  //     {
  //       name: "txt_villege_premeses",
  //       label: "Village/Premises",
  //       hidden: false,
  //       required: true,
  //       align: "left",
  //       data_type: "string",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 5,
  //     },

  //     {
  //       name: "txt_street",
  //       label: "Street",
  //       hidden: false,
  //       required: false,
  //       data_type: "string",
  //       align: "left",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 7,
  //     },
  //     {
  //       name: "txt_police_station",
  //       label: "Police Station",
  //       hidden: false,
  //       required: false,
  //       align: "left",
  //       data_type: "string",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 3,
  //     },
  //     {
  //       name: "txt_pin",
  //       label: "Pin Code",
  //       hidden: false,
  //       required: true,
  //       align: "left",
  //       data_type: "number",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 2,
  //     },
  //     {
  //       name: "txt_city",
  //       label: "City",
  //       hidden: false,
  //       required: true,
  //       align: "left",
  //       data_type: "string",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 3,
  //     },
  //     {
  //       name: "ddl_state",
  //       label: "State",
  //       hidden: false,
  //       required: true,
  //       data_type: "string",
  //       html_element: "select",
  //       xs: 12,
  //       md: 6,
  //       lg: 4,
  //       options: allStates,
  //     },
  //     {
  //       name: "add_btn",
  //       label: "Add",
  //       html_element: "addBtn",
  //       xs: 12,
  //       md: 6,
  //       lg: 12,
  //     },
  //   ],
  // },
  // {
  //   formName: "contactForm",
  //   fields: [
  //     {
  //       name: "txt_name",
  //       label: "Name",
  //       hidden: false,
  //       required: true,
  //       align: "left",
  //       data_type: "string",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 4,
  //     },
  //     {
  //       name: "txt_designation",
  //       label: "Designation",
  //       hidden: false,
  //       required: false,
  //       align: "left",
  //       data_type: "string",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 8,
  //     },
  //     {
  //       name: "txt_mobile",
  //       label: "Mobile",
  //       hidden: false,
  //       required: true,
  //       align: "left",
  //       data_type: "tel",
  //       maxLength: 10,
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 4,
  //     },
  //     {
  //       name: "txt_whatsapp",
  //       label: "WhatsApp",
  //       hidden: false,
  //       required: FormatListNumberedRtlTwoTone,
  //       align: "left",
  //       data_type: "tel",
  //       maxLength: 10,
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 4,
  //     },
  //     {
  //       name: "txt_email",
  //       label: "Email",
  //       hidden: false,
  //       required: false,
  //       align: "left",
  //       data_type: "email",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 4,
  //     },
  //     {
  //       name: "btnAdd",
  //       label: "Add",
  //       html_element: "addBtn",
  //       xs: 12,
  //       md: 6,
  //       lg: 12,
  //     },
  //   ],
  // },

  // {
  //   formName: "bankDetails",
  //   fields: [
  //     {
  //       name: "ddl_bank",
  //       label: "Bank",
  //       hidden: false,
  //       required: true,
  //       data_type: "string",
  //       html_element: "select",
  //       xs: 12,
  //       md: 6,
  //       lg: 8,
  //       options: listBank,
  //     },
  //     {
  //       name: "txt_branch",
  //       label: "Branch",
  //       hidden: false,
  //       required: true,
  //       align: "left",
  //       data_type: "string",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 4,
  //     },
  //     {
  //       name: "txt_account_no",
  //       label: "Account No",
  //       hidden: false,
  //       required: true,
  //       align: "left",
  //       data_type: "string",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 6,
  //     },
  //     {
  //       name: "txt_Ifsc_code",
  //       label: "IFSC Code",
  //       hidden: false,
  //       required: true,
  //       align: "left",
  //       data_type: "string",
  //       html_element: "TextField",
  //       error: false,
  //       xs: 12,
  //       md: 6,
  //       lg: 6,
  //     },
  //     {
  //       name: "btnAdd",
  //       label: "Add",
  //       html_element: "addBtn",
  //       xs: 12,
  //       md: 6,
  //       lg: 12,
  //     },
  //   ],
  // },
];

const VendorViewPage = ({ menuPortal = true, directPurchase = false }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [vendorInfo, setVendorInfo] = React.useState({
    vendor_id: "",
    company: "",
    group_name: "",
    reference_name: "",
    name: "",
    mobile: "",
    whatsapp: "",
    email: "",
    status: "",
    // allContacts: [],
    // allAddress: [],
    // allBanks: [],
  });
  const [vendorForm, setVendorForm] = React.useState({
    vendor_id: "",
    ddl_vendor_group: "",
    ddl_vendor_group_label: "Select",
    txt_mobile: "",
    txt_gst_no: "",
    txt_company_name: "",
    txt_website: "",
    ddl_references: "",
    ddl_references_label: "Select",
    ddl_dr_cr: "",
    ddl_dr_cr_label: "Select",
    txt_openingBalance: "",
    switch_active_status: true,

  });

  
console.log(location.state?.row?.dr_cr,"sank3101")
  React.useEffect(() => {
    setVendorForm({
      vendor_id: location.state?.row?.vendor_id,
      txt_company_name: location.state?.row?.company,  
      txt_gst_no: location.state?.row?.gst,
      txt_mobile: location.state?.row?.mobile,
      switch_active_status: location.state?.row?.status,      
      txt_website: location.state?.row?.website,
      txt_openingBalance:location.state?.row?.opening_balance,
      ddl_dr_cr_label:location.state?.row?.dr_cr
    
    });
  }, []);

  const onBackToVendorList = () => {
    history.push("/admin/master/vendor");
  };

  return (
    <ThemeProvider theme={theme}>
    <PageTitle title="Vendor > view" />
    <div className={classes.root}>
      <GridContainer flexWarp="reverse">
        <GridItem xs={12} md={12} lg={12} className={classes.vendorForm}>
          <GridContainer>
            <GridItem xs="12">
              <CustomCard
                width="100%"
                height="100%"
               
              >
                <from>
                  <GridContainer style={{ padding: 20 }}>
                    <GridItem xs={12}>
                      {formData.map((form, fkey) => {
                        return (
                          <GridContainer alignItems="flex-end" key={fkey}>
                            {form.formName === "fistForm" &&
                              form.fields.map((item, key) => {
                                return (
                                  <FormComponent
                                    item={item}
                                    key={key}
                                    menuPortal={menuPortal}                                   
                                    state={vendorForm}
                                 
                                                                     
                                   
                                    
                                  />
                                );
                              })}
                          </GridContainer>
                        );
                      })}
                    </GridItem>
                  </GridContainer>
                </from>
                <GridContainer className={classes.actionFooter}>
              <GridItem xs={12}>
                <div className={classes.actionbtns}>
                  <ColoseButton1 height={39} style={{float:"right"}} onClick={onBackToVendorList}>
                   Close
                  </ColoseButton1>
                
                </div>
              </GridItem>
            </GridContainer>
              </CustomCard>
            </GridItem>

            {/* {state.showAddressForm && (
              <GridItem xs={12}>
                <CustomCard
                  cdTitle={addressForm.edit ? "Edit Address" : "Add Address"}
                  width="100%"
                  close
                  onClose={onCloseAddAddress}
                  height="100%"
                  style={{ marginTop: 0 }}
                >
                  {formData.map((form, fkey) => {
                    return (
                      <GridContainer
                        key={fkey}
                        alignItems="center"
                        style={{ padding: "5px 20px" }}
                      >
                        {form.formName === "addressForm" &&
                          form.fields.map((item, key) => {
                            return (
                              <>
                                <FormComponent
                                  item={item}
                                  key={key}
                                  menuPortal={menuPortal}
                                  onClickAdd={onClickAddAddress}
                                  onSelect={onSelect}
                                  state={addressForm}
                                  onChange={onChangeAddress}
                                  error={addError}
                                />
                              </>
                            );
                          })}
                      </GridContainer>
                    );
                  })}
                </CustomCard>
              </GridItem>
            )}

            {allAddress.length > 0 && (
              <GridItem xs="12">
                <CustomCard cdTitle="Addresses">
                  <GridItem xs={12} style={{ margin: "20px 0px" }}>
                    <GridContainer>
                      <AddInfoCard
                        title="Add a New Address"
                        onClickShow={onAddAddressForm}
                      />

                      {allAddress.map((a, id) => (
                        <AddressCard
                          a={a}
                          id={id}
                          key={id}
                          onClickEdit={onClickEditAddress}
                          onRemove={onRemoveAddress}
                          onSetAddres={onSetAddres}
                          state={addressForm}
                        />
                      ))}
                    </GridContainer>
                  </GridItem>
                </CustomCard>
              </GridItem>
            )}

            {state.showContactForm && (
              <GridItem xs={12}>
                <CustomCard
                  cdTitle={
                    contactPerson.edit ? "Edit Contact" : "Add Contact"
                  }
                  width="100%"
                  height="100%"
                  close
                  onClose={onCloseAddContact}
                  style={{ marginTop: 0 }}
                >
                  {formData.map((form, fkey) => {
                    return (
                      <GridContainer
                        key={fkey}
                        style={{ padding: "5px 20px" }}
                      >
                        {form.formName === "contactForm" &&
                          form.fields.map((item, key) => {
                            return (
                              <>
                                <FormComponent
                                  item={item}
                                  key={key}
                                  menuPortal={menuPortal}
                                  onClickAdd={onClickAddContact}
                                  onSelect={onSelect}
                                  state={contactPerson}
                                  onChange={onChangeContact}
                                  error={contError}
                                />
                              </>
                            );
                          })}
                      </GridContainer>
                    );
                  })}
                </CustomCard>
              </GridItem>
            )}
            {console.log(allContacts.length, "allshow")}

            {allContacts.length > 0 && (
              <GridItem xs="12">
                <CustomCard cdTitle="Contacts">
                  <GridItem xs={12} style={{ margin: "20px 0px" }}>
                    <GridContainer>
                      <AddInfoCard
                        title="Add a New Contact"
                        onClickShow={onAddContactForm}
                      />

                      {allContacts.map((a, id) => (
                        <ViewCard
                          id={id}
                          key={id}
                          a={a}
                          onClickEdit={onClickEditContact}
                          onRemove={onRemoveContact}
                        />
                      ))}
                    </GridContainer>
                  </GridItem>
                </CustomCard>
              </GridItem>
            )}
            {console.log(state.showAddBankForm, "bankshow")}

            {state.showAddBankForm && (
              <GridItem xs={12}>
                <CustomCard
                  cdTitle={bankForm.edit ? "Edit Bank" : "Add Bank"}
                  width="100%"
                  height="100%"
                  close
                  onClose={onCloseAddBank}
                  style={{ marginTop: 0 }}
                >
                  {formData.map((form, fkey) => {
                    return (
                      <GridContainer
                        key={fkey}
                        style={{ padding: "5px 20px" }}
                      >
                        {form.formName === "bankDetails" &&
                          form.fields.map((item, key) => {
                            return (
                              <>
                                <FormComponent
                                  item={item}
                                  key={key}
                                  menuPortal={menuPortal}
                                  onClickAdd={onClickAddBank}
                                  onSelect={onSelect}
                                  state={bankForm}
                                  onChange={onChangeBankForm}
                                  error={bnkError}
                                />
                              </>
                            );
                          })}
                      </GridContainer>
                    );
                  })}
                </CustomCard>
              </GridItem>
            )}
            {allBanks && allBanks.length > 0 && (
              <GridItem xs="12">
                <CustomCard cdTitle="Banks">
                  <GridItem xs={12} style={{ margin: "20px 0px" }}>
                    <GridContainer>
                      <AddInfoCard
                        title="Add a New Bank"
                        onClickShow={onAddBankForm}
                      />

                      {allBanks.map((a, id) => (
                        <ViewBankCard
                          a={a}
                          id={id}
                          onClickEdit={onClickEditBank}
                          onRemove={onRemoveBank}
                        />
                      ))}
                    </GridContainer>
                  </GridItem>
                </CustomCard>
              </GridItem>
            )} */}
          
          </GridContainer>
        </GridItem>

        {/* <GridItem xs={3}>
          <CustomCard cdTitle="Advance Setting">
            <Grid container alignItems="center" justify="center">
              <Grid item xs="8">
                <Button
                  size="small"
                  onClick={onAddAddressForm}
                  className={classes.addFormbtn}
                  startIcon={<AddIcon />}
                  variant="outlined"
                  color="primary"
                >
                  Add Address
                </Button>
              </Grid>
              <Grid item xs="8">
                <Button
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={onAddContactForm}
                  className={classes.addFormbtn}
                  variant="outlined"
                  color="primary"
                >
                  Add Contact
                </Button>
              </Grid>
              <Grid item xs="8">
                <Button
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={onAddBankForm}
                  className={classes.addFormbtn}
                  variant="outlined"
                  color="primary"
                >
                  Add Bank
                </Button>
              </Grid>
            </Grid>
          </CustomCard>
        </GridItem> */}

      </GridContainer>

    </div>
  </ThemeProvider>
  );
};

export default VendorViewPage;
