//kata
import webApi from "./webApi/webApi";

export const getAllkata = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/kata/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allkatas = [];
      r.map((r, i) => {
        allkatas.push({
        
          katae_id: i + 1,
          kata_id:r.kata_id,
          kata_kata: r.kata,
          kata_details: r.details,
          action_items: r.action_items,
          kata_status: r.active_status,
          kata_action: "action",
        });
      });
      return onSuccess(allkatas);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postkata = async (kata,inserted_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/kata/insert", {
      kata: kata.txt_kata,
      details: kata.txt_details,
      active_status: kata.switch_active_btn ? "Y" : "N",
      inserted_by_id:inserted_by_id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  }
  
  catch (error) 
  {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updatekata = async (kata, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/kata/update", {
      kata_id: kata.kata_id,
      kata: kata.txt_kata,
      details: kata.txt_details,
      active_status: kata.switch_active_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r,"sank1008=>");
      onSuccess(r);
    } 
   
    else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
export const deletekata = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/kata/delete", {
        kata_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


export const getListkata = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/kata/list", {
   
    });
    if (res.status === 200) {
      const r = res.data;
      
      let arr = [];
      r.map((r, i) => {
        arr.push({
          value: r.kata_id,
          katael: r.kata,
        });
      });
      return onSuccess(arr);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }

  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};