import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import { getAllLocation, postRoute, getAllRouteLocation, deleteroutes } from "../../../services/routesPageService";

import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Box, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import theme from "../../../theme/theme";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";

import React from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from 'file-saver';
import XLSX from 'xlsx'
import IconButton from '@material-ui/core/Button';
import { currentDate } from "../HelperComponent/utils";

const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },

}));


const toTitleCase = (str) => {
  if (str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  else {
    return "";
  }

}
const locationPage = () => {
  const classes1 = useStyles1()
  const history = useHistory();
  const [modules, setAllModules] = React.useState([]);
  const [classicModal, setClassicModal] = React.useState(false);
  const [location, setAllLocation] = React.useState([]);
  const [routeLocation, setAllRouteLocation] = React.useState([]);
  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const userRole = globalState?.user?.user_role;

  const [routes, setRoutes] = React.useState({
    edit: false,
    route_id: "",
    ddl_delivery_location_id: "",
    ddl_delivery_location: "Select",
    ddl_loading_loaction_id: "",
    ddl_loading_loaction: "",
    switch_active_status: true,
    txt_amount: 0,
  });




  const [error, setError] = React.useState({
    txt_location: false,
    ddl_location_type: false,

  });

  //Location type options as
  const locationType = [
    { value: 1, label: "Loading" },
    { value: 2, label: "Unloading" },
    { value: 3, label: "Advance" },

  ];


  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setRoutes((prv) => ({ ...prv, [name]: value }));
  };
  const formData = {
    formName: "Add a Routes",
    fields: [

      {
        name: "ddl_delivery_location",
        value: routes,
        label: "Delivery Location",
        placeholder: "Select Location",
        hidden: false,
        required: true,
        data_type: "select",
        html_element: "select",
        error: false,
        options: location.filter((o) => o.location_type === "Unloading"),
        xs: 6,

      },
      {
        name: "ddl_loading_loaction",
        value: routes,
        label: "Loading Location",
        placeholder: "Select Location",
        hidden: false,
        required: true,
        data_type: "select",
        html_element: "select",
        error: false,
        options: location.filter((o) => o.location_type === "Loading"),
        xs: 6,
      },
      {
        name: "txt_amount",
        label: "Amount",
        hidden: false,
        required: false,
        align: "right",
        data_type: "number",
        html_element: "TextField",
        error: false,
        xs: 6,

      },
      {
        name: "switch_active_status",
        label: "Active Status",
        defaultValue: false,
        required: false,
        data_type: "string",
        html_element: "switch",
        error: false,
      },
    ],
  };

  React.useEffect(() => {
    setLoading(true);
    // get All Module


    // get All routelocation
    getAllRouteLocation(
      (location) => {
        console.log(location, "sank1017")
        setAllRouteLocation(location);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [refresh]);

  const onSetActive = (e) => {
    setRoutes((prv) => ({ ...prv, [e.target.name]: e.target.checked }));
  };

  const headerData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "route_no",
      label: "Route",

    },
    {
      id: "delivery_location",
      label: "Delivery Location",

    },
    {
      id: "loading_loaction",
      label: "Loading Location",
    },
    {
      id: "amount",
      label: "Amount",
    },
    // {
    //   id: "details",
    //   label: "Details",
    // },
    {
      id: "status",
      label: "Status",
      statusBtn: (v) => (v === "Y" ? true : false),
    },
    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];



  // on Edit called
  const onUpdateVehicle = (row) => {
    console.log(row,"sen08112022/l")
    history.push(
      { 
        pathname: "/admin/master/routeFormEdit",
        route_id:row.route_id,
        row:row
      })
  };

    // on Delete called
    const onDeleteRoute = (row, next) => {
      deleteroutes(
        row.route_id,
        (r) => {
          next();
          setRefresh(!refresh);
        },
        (err) => {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
        }
      );
    };

  const onSelectModule = (event, value) => {
    console.log(value.name, "sen11102022/1", event)
    switch (value.name) {
      case "ddl_delivery_location":
        setRoutes({
          ...routes,
          ddl_delivery_location_id: event.value,
          ddl_delivery_location: event.label,
        });
        break;
      case "ddl_loading_loaction":
        setRoutes({
          ...routes,
          ddl_loading_loaction_id: event.value,
          ddl_loading_loaction: event.label,
        });
        break;

    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;

    setRoutes({ ...routes, [name]: value });

  };
  const classes = useStyles();

  // on Close Model
  const onCloseModel = () => {
    setClassicModal(false);

    // setError({ txt_location: false });
    // setAddLocation({
    //   ...addLocation,
    //   edit: false,
    //   location_id: "",
    //   switch_active_status: false,
    //   txt_location: "",
    //   ddl_location_type_label: "",
    //   txt_details: "",
    //   txt_amount: "0",
    //   txt_from_date: currentDate(),
    //   txt_to_date: currentDate(),

    //   // ddl_modules_id: 0,
    //   // ddl_modules_label: "Select Module",
    // });
  };

  const onSearchLocation = (e) => {
    e.preventDefault();
    setLoading(true);
    if (routes.txt_keyword_phrase) {

      getAllRouteLocation(
        (location) => {
          if (location.length) {
            setAllRouteLocation(location);
            setLoading(false);
          } else {
            setLoading(false);
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, "Customer not found": "info" },
            });
          }
        },
        (err) => {
          // change by sankha
          setAllRouteLocation([])
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
          setLoading(false);
        },
        routes
      );
    } else {
      getAllRouteLocation(
        (location) => {
          setAllRouteLocation(location);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
        }
      );
    }
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddLocation({
      txt_keyword_phrase: "",

    });
  };


  // export to excel

  const onhandleExportToExcel = () => {
    const VehicleTable = vehicle.map(acc => {
      return {
        ID: acc.id,
        Vehicle_type: acc.vehicle_type,
        Vehicle_no: acc.vehicle_no,
        Contact_person: acc.contact_person,
        Contact_no: acc.contact_no,
        Details: acc.details,

        Status: acc.status,
        Action: "",

      }
    })



    const fileName = 'Vehicle '
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(VehicleTable);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  // const onClickAddBtn =()=>{
  //   setClassicModal(true)
  //   getAllLocation(
  //       (location) => {
  //           console.log(location,"sen11102022")
  //         setAllLocation(location);
  //         setLoading(false);
  //       },
  //       (err) => {
  //         setLoading(false);
  //         dispatch({
  //           type: actionTypes.SET_OPEN_MSG,
  //           payload: { msg: err, msgType: "error" },
  //         });
  //       }
  //     );
  // }

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Master > Routes"
        btnToolTip="Add New Routes"
        // onClickAddBtn={onClickAddBtn}
        addBtnLink="/admin/master/routeForm"
      />
      <>
        <GridContainer className={classes.root}>
          <GridItem xs="12">
            <CustomCard cdTitle="Search Routes">
              <Paper elevation="0" className={classes.searchBar}>
                <GridContainer justifyContent="flex-start" alignItems="flex-end">

                  <GridItem xs="6">
                    <InputLabel id="label">Keyword / Phrase</InputLabel>
                    <TextField
                      size="small"
                      placeholder="Keyword / Phrase"
                      style={{ marginBottom: -15 }}
                      type="search"
                      name="txt_keyword_phrase"
                      onChange={onAddSearch}
                      id="outlined-basic"
                      fullWidth={true}
                      value={routes.txt_keyword_phrase}
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs="6">
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomButton
                        name="btn_refres"
                        style={{ marginRight: "10px" }}

                        onClick={onSearchLocation}

                      >
                        <SearchIcon />
                      </CustomButton>
                      <CustomButton name="btn_refres"
                        onClick={onClickRefresh} >
                        <RotateLeftIcon />
                      </CustomButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </Paper>
            </CustomCard>
          </GridItem>

          {/* <MasterModel
            classicModal={classicModal}
            onCloseModel={onCloseModel}
            width={450}
            height="auto"
            okBtnText="Submit"
            modelName={routes.edit ? "Edit Routes" : "Add Routes"}
            onClickOk={onSubmitModel}
            disabled={buttonDisabled}
          >
            <div style={{ padding: "10px 10px", width: "100%" }}>
              <GridContainer>

                {formData.fields.map((item, key) => {
                  return (
                    <>
                      <GridItem
                        xs={item.xs}
                        key={key}
                        lg={item.lg}
                        md={item.md}
                        style={{ padding: 5 }}>
                        {item.html_element === "select" && (
                          <>
                            <InputLabel id="label">{item.label}</InputLabel>
                            <Select
                              options={item.options}
                              placeholder={item.placeholder}
                              formatGroupLabel={(d) => d.label}
                              className={classes.customSelect}
                              onChange={onSelectModule}
                              name={item.name}
                              fullWidth={true}
                              variant="outlined"
                            //   value={{
                            //     value: addLocation.ddl_location_type,
                            //     label: addLocation.ddl_location_type_label,
                            //   }}
                            />
                          </>
                        )}
                        {item.html_element === "TextField" && (
                          <>
                            <InputLabel required={item.required} id="label">
                              {item.label}
                            </InputLabel>
                            <TextField
                              size="small"
                              required={item.required}
                              placeholder={item.label}
                              name={item.name}
                              onChange={onChange}
                              id="outlined-basic"
                              error={error[item.name]}
                              FormHelperTextProps={{
                                style: { textAlign: "right" },
                              }}
                              helperText={
                                error[item.name]
                                  ? item.label + "  is required"
                                  : ""
                              }
                              fullWidth={true}
                              value={routes[item.name]}
                              variant="outlined"
                            />

                          </>
                        )}
                        {item.html_element === "switch" && (
                          <>
                            <span className={classes.activeText}>
                              {item.label}
                            </span>
                            <Switch
                              onClick={onSetActive}
                              checked={routes[item.name]}
                              name={item.name}
                              fullWidth={true}
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              color="primary"
                            />
                          </>
                        )}
                      </GridItem>
                    </>
                  );
                })}

              </GridContainer>
            </div>
          </MasterModel> */}

          <GridItem xs="12">
            {loading ? (
              <Box mt={10} width="100%" textAlign="center">
                <CircularProgress />
              </Box>
            ) : (
              <Card className={classes1.headerCard}>
                <CardHeader className={classes1.TbheaderCdhd} style={{ height: 60 }}>
                  <GridContainer justifyContent="space-between" alignItems="center">
                    <GridItem>
                      <h4 className={classes1.headerCdTitle}>Location</h4>
                    </GridItem>
                    <GridItem style={{ cursor: "pointer" }}>
                      <IconButton variant="text" onClick={() => onhandleExportToExcel(vehicle)} >
                        <Tooltip title="Export to Excel">
                          <img src={require("../../../assets/img/excel.png").default} />
                        </Tooltip>
                      </IconButton>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody
                  style={{ height: "auto", maxHeight: 480, padding: 10 }}
                  className={clxs(classes.customScroolBar)}
                >
                  <MuiTable
                    onClickEdit={onUpdateVehicle}
                    onClickDelete={onDeleteRoute}
                    columns={headerData}
                    rows={routeLocation}
                userRole={userRole}

                  />
                </CardBody>
              </Card>
            )}

          </GridItem>
        </GridContainer>
      </>
    </ThemeProvider>
  );
};

export default locationPage;
