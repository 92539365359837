import webApi from "./webApi/webApi";

 export const postTyreBrand = async (info,inserted_by_id, onSuccess, onFailure) => {
    try {
        
      const res = await webApi.post("/master/tyre_brand/insert", {
            
        tyre_brand: info.txt_tyre_brand,
        
        details: info.txt_details,
        active_status: info.switch_active_status ? "Y" : "N",
        inserted_by_id:inserted_by_id,
      });
  
      if (res.status === 200) {
        const r = res.data;
      
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      // onFailure("Something Wrong! Please Try again later " + error);
    }
  };



  export const getAllTyreBrand = async (onSuccess, onFailure,q) => {
    try {
  
      const res = await webApi.post("/master/tyre_brand/list", {
        keyword_pharse: q?.txt_keyword_pharse,
  
      });
      if 
      (res.status === 200)
       {
        const r = res.data;
        let all_tyreBrand = [];
        r.map((r, i) => {
          all_tyreBrand.push({
            id: i + 1,
            tyre_brand_id:r.tyre_brand_id,
            tyre_brand:r.tyre_brand,
            details: r.details,
            status: r.active_status,
            action_items: r.action_items,
            action: "action",
          });
        });
        return onSuccess(all_tyreBrand);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };


  export const getAllTyreBrandId = async (onSuccess, onFailure,q) => {
    try {
  
      const res = await webApi.post("/master/tyre_brand/list", {
        // keyword_pharse: q?.txt_keyword_pharse,
  
      });
      if 
      (res.status === 200)
       {
        const r = res.data;
        console.log(r,"sen17122022")

        let all_tyreBrand = [];
        r.map((r, i) => {
          all_tyreBrand.push({
            value: r.tyre_brand_id,
            label: r.tyre_brand,
          });
        });
        return onSuccess(all_tyreBrand);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };

  export const getAllTyreModelId = async (onSuccess, onFailure,q) => {
    try {
  
      const res = await webApi.post("/master/tyreModel/list", {
        // keyword_pharse: q?.txt_keyword_pharse,
  
      });
      if 
      (res.status === 200)
       {
        const r = res.data;
        let all_tyreBrand = [];
        r.map((r, i) => {
          all_tyreBrand.push({
            value: r.tyre_model_id,
            label: r.tyre_model,
          });
        });
        return onSuccess(all_tyreBrand);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };



  export const updateTyreBrand = async (info, onSuccess, onFailure) => {
    console.log(info,"from v service")
    try {
      const res = await webApi.post("/master/tyre_brand/update", {
        tyre_brand_id: info.tyre_brand_id,
        tyre_brand: info.txt_tyre_brand,
        details: info.txt_details,
        active_status: info.switch_active_status ? "Y" : "N",
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };