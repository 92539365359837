import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import MasterModelView from "../../Components/MasterModelView";


import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";
import { ColoseButton } from "views/Components/CustomButton";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import VehicleViewPage from "./VehicleViewPage";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  updateVehicle,
  postVehicles,
  getAllVehicle,
  deleteVehicle,
  getAllVehicleTypeId,
  getAllVehicleBrandId,
  getAllTyreBrand,
  getAllVehicleView,
  postFileUpload,
  getVehicle
} from "../../../services/vehicleService";
import { getAllVehicleType } from "../../../services/vehicleTypeService";
import { Grid } from "@material-ui/core";

import { getAllModule } from "../../../services/modulesService";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Box, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import theme from "../../../theme/theme";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";


import React, { useState } from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory, useLocation } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import IconButton from "@material-ui/core/Button";
import { currentDate, dateFormate, dateFormateField } from "../HelperComponent/utils";
import { IMG_API } from "../../../services/webApi/webApi";
import moment from "moment";

//mui icon
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },
}));

const VehicleForm = () => {
  const classes1 = useStyles1();
  const history = useHistory();
  const location = useLocation();
  const [classicModal, setClassicModal] = React.useState(false);
  const [vehicle, setAllVehicle] = React.useState([]);
  const [allType, setAllType] = React.useState([]);
  const [allBrand, setAllBrand] = React.useState([]);
  const [collapsibleimage, setCollapsibleimage] = React.useState([]);
  const [collapsible, setCollapsible] = React.useState([]);

  const [flName, setFlName] = React.useState("");
  const [items, setItems] = React.useState([]);
  const [addedItems, setAddedItems] = React.useState([]);
  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [addVehicle, setAddVehicle] = React.useState({
    edit: false,
    vehicle_id: "",
    txt_keyword_pharse: "",
    txt_vehicle: "",
    txt_vehicle_specification: "",
    txt_contact_no: "",
    ddl_vehicle_type_id: "",
    ddl_vehicle_type_label: "",
    ddl_tyre_brand_id: "",
    ddl_tyre_brand_label: "",
    ddl_vehicle_brand_id: "",
    ddl_vehicle_brand_label: "",
    ddl_no_of_wheels_id: "",
    ddl_no_of_wheels_label: "",
    txt_type: "",
    txt_km_start: "",
    txt_mileage: "",
    vehicle_charges:'',
    txt_engine_number: "",
    txt_chassis_number: "",

    txt_purchase_date: currentDate(),


    txt_insurance_company: "",
    txt_insurance_file: "",
    insurance_image_path: "",
    insurance_image_name: "",

    txt_insurance_date: currentDate(),
    txt_pollution_file: "",
    pollution_image_path: "",
    pollution_image_name: "",
    txt_pollution_date: currentDate(),
    txt_permit_file: "",
    permit_image_path: "",
    permit_image_name: "",
    txt_permit_date: currentDate(),
    txt_tax_file: "",
    tax_image_path: "",
    tax_image_name: "",
    txt_tax_date: currentDate(),
    txt_registration_file: "",
    txt_registration_date: currentDate(),
    txt_bluebook_file: "",
    bluebook_image_path: "",
    bluebook_image_name: '',
    // txt_bluebook_date: currentDate(),
    txt_fitness_number: '',
    fitness_image_path: "",
    fitness_image_name: "",
    txt_fitness_date: currentDate(),
    txt_loan_file: "",
    loan_image_path: "",
    loan_image_name: "",
    // txt_loan_date: currentDate(),
    txt_bank_noc_file: "",
    bank_image_path: "",
    bank_image_name: "",
    // txt_bank_noc_date: currentDate(),
    gps_image_path:"",
    gps_image_name:"",
    txt_gps_date:currentDate(),

    switch_active_status: true,
  });
  const [error, setError] = React.useState({
    ddl_vehicle_type_id: false,
    ddl_tyre_brand_id: false,
    ddl_vehicle_brand_id: false,
    txt_vehicle: false,
    ddl_no_of_wheels_id: false,
    txt_km_start: false,
    txt_mileage: false,
    txt_insurance_company: false,
    txt_engine_number: false,
    txt_chassis_number: false,
    txt_Owners: false,
  });
  const [checkedOwn, setCheckedOwn] = React.useState(false)
  const [checkedRented, setCheckedRented] = React.useState(false)
  const fontStyle = {
    fontFamily: "Sans-serif",
  };
  const [viewModel, setViewModel] = useState(false);
  const [fileLoading, setFileLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);


    console.log(allType, "123type");
    getAllVehicleTypeId(
      (allType) => {
        setAllType(allType);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
    getAllVehicleBrandId(
      (allBrand) => {
        setAllBrand(allBrand);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [refresh]);

  React.useEffect(() => {
    console.log(location, "sen06122022")
    location.vehicle_id ?
      getVehicle(
        location.vehicle_id,
        (res) => {
          console.log(res, "sen06122022/l")
          if (res.vehicle_ownership === "Own") {
            setCheckedOwn(true)
            setCheckedRented(false)
          }
          else if (res.vehicle_ownership === "Rented") {
            setCheckedRented(true)
            setCheckedOwn(false)

          }
          setAddVehicle({
            edit: true,
            vehicle_id: res.vehicle_id,
            txt_vehicle: res.vehicle_no,
            ddl_vehicle_type_id: res.vehicle_type_id,
            ddl_vehicle_type_label: res.vehicle_type,
            ddl_vehicle_brand_id: res.vehicle_brand_id,
            ddl_vehicle_brand_label: res.vehicle_brand,
            ddl_no_of_wheels_id: res.no_of_wheels_id,
            ddl_no_of_wheels_label: res.no_of_wheels,
            txt_km_start: res.km_start,
            txt_mileage: res.mileage,
            txt_engine_number: res.engine_number ? res.engine_number : '' ,
            txt_chassis_number: res.chassis_number ? res.chassis_number :'',
            txt_purchase_date: res.vehicle_purchase_date,
            txt_Owners: res.ownerName,
            vehicle_charges:res?.vehicle_charges,
            txt_insurance_company: res.insurance_company,
            tyre_details_status:res.tyre_details_status?res.tyre_details_status:false,
            txt_insurance_file: res.insurance_number ? res.insurance_number : '',
            insurance_image_path: res.insurance_image_path,
            insurance_image_name: res.insurance_image_name,
            txt_insurance_date: res.insurance_expire_date ? dateFormateField(dateFormate(res.insurance_expire_date)) : currentDate(),

            txt_pollution_file: res.pollution_number ? res.pollution_number : '',
            pollution_image_path: res.pollution_image_path,
            pollution_image_name: res.pollution_image_name,
            txt_pollution_date: dateFormateField(dateFormate(res.pollution_expiry_date)),

            txt_permit_file: res.permit_number ? res.permit_number : '',
            permit_image_path: res.permit_image_path,
            permit_image_name: res.permit_image_name,
            txt_permit_date: dateFormateField(dateFormate(res.permit_expiry_date)),

            txt_tax_file: res.tax_number ? res.tax_number : '',
            tax_image_path: res.tax_image_path,
            tax_image_name: res.tax_image_name,
            txt_tax_date:  dateFormateField(dateFormate(res.tax_expire_date)),

            // txt_registration_file: "",
            // txt_registration_date: currentDate(),
            txt_bluebook_file: res.bluebook_number ? res.bluebook_number : '',
            bluebook_image_path: res.bluebook_image_path,
            bluebook_image_name: res.bluebook_image_name,
            // txt_bluebook_date: res.bluebook_expiry_date ? dateFormateField(dateFormate(res.bluebook_expiry_date)) : currentDate() ,
            txt_fitness_number: res.fitness_number ? res.fitness_number : '',
            fitness_image_path: res.fitness_image_path,
            fitness_image_name: res.fitness_image_name,
            txt_fitness_date: res.fitness_expiry_date ? dateFormateField(dateFormate(res.fitness_expiry_date)) : currentDate(),
            txt_loan_file: res.loan_number ? res.loan_number : '',
            loan_image_path: res.loan_image_path,
            loan_image_name: res.loan_image_name,
            // txt_loan_date: dateFormateField(dateFormate(res.loan_date)),
            txt_bank_noc_file: res.bank_noc_number ? res.bank_noc_number : '',
            bank_image_path: res.bank_image_path,
            bank_image_name: res.bank_image_name,
            gps_image_path:res.gps_image_path,
            gps_image_name:res.gps_image_name,
            txt_gps_date:res.gps_date ? dateFormateField(dateFormate(res.gps_date)):currentDate(),
            // txt_bank_noc_date: dateFormateField(dateFormate(res.bank_noc_date)),
            switch_active_status: true,
          })
        },
        (err) => {

        })
      : ''
  }, [refresh]);

  //options of no of wheels
  const vehicleWheels = [
    { value: 6, label: "6" },
    { value: 10, label: "10" },
    { value: 12, label: "12" },
    { value: 14, label: "14" },
    { value: 16, label: "16" },
  ];

  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddVehicle((prv) => ({ ...prv, [name]: value }));
  };
  const viewData1 = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "attachment_name",
      label: "Attachment No",
    },
    {
      id: "expiry_date",
      label: "Expiry Date",
    },

  ];

  const viewData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "vehicle_no",
      label: "Vehicle No",
    },
    {
      id: "vehicle_ownership",
      label: "Ownership",
    },
    {
      id: "vehicle_type",
      label: "Type",
    },
    {
      id: "vehicle_brand",
      label: "Brand",
    },


    {
      id: "vehicle_specification",
      label: "Specification",
    },
    {
      id: "no_of_wheels",
      label: "Wheels",
    },
    // {
    //   id: "vehicle_purchase_date",
    //   label: "Vehicle Date",
    // },

    {
      id: "status",
      label: "Status",
      statusBtn: (v) => (v === "Y" ? true : false),
    },

  ];


  const onSetActive = (e) => {
    setAddVehicle((prv) => ({ ...prv, [e.target.name]: e.target.checked }));
  };



  const onSubmitModel = (e) => {
    e.preventDefault();
    console.log(addVehicle, "sen1010")
    if (!addVehicle.txt_vehicle || !addVehicle.ddl_vehicle_type_id
      || !addVehicle.ddl_vehicle_brand_id || !addVehicle.ddl_no_of_wheels_id || !addVehicle.txt_km_start
      || !addVehicle.txt_mileage || !addVehicle.txt_insurance_company 
       || !addVehicle.txt_Owners
    ) {
      setError({

        ddl_vehicle_type_id: !addVehicle.ddl_vehicle_type_id,
        ddl_vehicle_brand_id: !addVehicle.ddl_vehicle_brand_id,
        txt_vehicle: !addVehicle.txt_vehicle,
        ddl_no_of_wheels_id: !addVehicle.ddl_no_of_wheels_id,
        txt_km_start: !addVehicle.txt_km_start,
        txt_mileage: !addVehicle.txt_mileage,
        txt_insurance_company: !addVehicle.txt_insurance_company,
       
        txt_Owners: !addVehicle.txt_Owners
      });
    } else {
      if (addVehicle.edit) {
        updateVehicle(
          addVehicle,
          globalState?.user?.serial_id,
          (r) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Vehicle Updated Successfully",
                msgType: "success",
              },
            });

            setRefresh(!refresh);
            setAddVehicle({
              edit: false,
              vehicle_id: "",
              txt_keyword_pharse: "",
              txt_vehicle: "",
              txt_vehicle_specification: "",
              txt_contact_no: "",
              ddl_vehicle_type_id: "",
              ddl_vehicle_type_label: "",
              ddl_tyre_brand_id: "",
              ddl_tyre_brand_label: "",
              ddl_vehicle_brand_id: "",
              ddl_vehicle_brand_label: "",
              ddl_no_of_wheels_id: "",
              ddl_no_of_wheels_label: "",
              txt_type: "",
              txt_km_start: "",
              txt_mileage: "",

              txt_engine_number: "",
              txt_chassis_number: "",

              txt_purchase_date: currentDate(),


              txt_insurance_company: "",
              txt_insurance_file: "",
              insurance_image_path: "",
              insurance_image_name: "",

              txt_insurance_date: currentDate(),
              txt_pollution_file: "",
              pollution_image_path: "",
              pollution_image_name: "",
              txt_pollution_date: currentDate(),
              txt_permit_file: "",
              permit_image_path: "",
              permit_image_name: "",
              txt_permit_date: currentDate(),
              txt_tax_file: "",
              tax_image_path: "",
              tax_image_name: "",
              txt_tax_date: currentDate(),
              txt_registration_file: "",
              txt_registration_date: currentDate(),
              txt_bluebook_file: "",
              bluebook_image_path: "",
              bluebook_image_name: '',
              // txt_bluebook_date: currentDate(),
              txt_fitness_number: '',
              fitness_image_path: "",
              fitness_image_name: "",
              txt_fitness_date: currentDate(),
              txt_loan_file: "",
              loan_image_path: "",
              loan_image_name: "",
              // txt_loan_date: currentDate(),
              txt_bank_noc_file: "",
              bank_image_path: "",
              bank_image_name: "",
              // txt_bank_noc_date: currentDate(),

              gps_image_path:"",
              gps_image_name:"",
              txt_gps_date:currentDate(),

              switch_active_status: true,
            });
            setCheckedRented(false)
            setCheckedOwn(false)
            history.push({
              pathname: "/admin/master/vehicle",
            })
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
      } else {
        postVehicles(
          addVehicle,
          globalState?.user?.serial_id,
          (r) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Vehicle added Successfully",
                msgType: "success",
              },
            });
            history.push({
              pathname: "/admin/master/vehicle",
            })
            setClassicModal();
            setRefresh(!refresh);
            setAddVehicle({
              edit: false,
              vehicle_id: "",
              txt_keyword_pharse: "",
              txt_vehicle: "",
              txt_vehicle_specification: "",
              txt_contact_no: "",
              ddl_vehicle_type_id: "",
              ddl_vehicle_type_label: "",
              ddl_tyre_brand_id: "",
              ddl_tyre_brand_label: "",
              ddl_vehicle_brand_id: "",
              ddl_vehicle_brand_label: "",
              ddl_no_of_wheels_id: "",
              ddl_no_of_wheels_label: "",
              txt_type: "",
              txt_km_start: "",
              txt_mileage: "",

              txt_engine_number: "",
              txt_chassis_number: "",

              txt_purchase_date: currentDate(),


              txt_insurance_company: "",
              txt_insurance_file: "",
              insurance_image_path: "",
              insurance_image_name: "",

              txt_insurance_date: currentDate(),
              txt_pollution_file: "",
              pollution_image_path: "",
              pollution_image_name: "",
              txt_pollution_date: currentDate(),
              txt_permit_file: "",
              permit_image_path: "",
              permit_image_name: "",
              txt_permit_date: currentDate(),
              txt_tax_file: "",
              tax_image_path: "",
              tax_image_name: "",
              txt_tax_date: currentDate(),
              txt_registration_file: "",
              txt_registration_date: currentDate(),
              txt_bluebook_file: "",
              bluebook_image_path: "",
              bluebook_image_name: '',
              // txt_bluebook_date: currentDate(),
              txt_fitness_number: '',
              fitness_image_path: "",
              fitness_image_name: "",
              txt_fitness_date: currentDate(),
              txt_loan_file: "",
              loan_image_path: "",
              loan_image_name: "",
              // txt_loan_date: currentDate(),
              txt_bank_noc_file: "",
              bank_image_path: "",
              bank_image_name: "",
              // txt_bank_noc_date: currentDate(),
              gps_image_path:"",
              gps_image_name:"",
              txt_gps_date:currentDate(),

              switch_active_status: true,
            });
            setCheckedRented(false)
            setCheckedOwn(false)
            onClickClose();
            setRefresh(!refresh);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setButtonDisabled(true);
          }
        );
      }
    }
  };

  // on Delete called
  const onDeleteVehicle = (row, next) => {
    deleteVehicle(
      row.vehicle_id,
      (r) => {
        next();
        setRefresh(!refresh);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };



  // // on Edit called
  // const onUpdateVehicle = (row) => {
  //   console.log(row.vehicle_ownership, "sen2609")
  //   if (row.vehicle_ownership == "Own") {
  //     setCheckedOwn(true)
  //   } else if (row.vehicle_ownership === "Rented") {
  //     setCheckedRented(true)
  //   }
  //   setClassicModal(true);
  //   setAddVehicle({
  //     ...addVehicle,
  //     edit: true,
  //     switch_active_status: row.status === "Y" ? true : false,
  //     vehicle_id: row.vehicle_id,
  //     txt_vehicle_specification: row.vehicle_specification,
  //     txt_vehicle: row.vehicle_no,
  //     txt_details: row.details,
  //     ddl_vehicle_type_label: row.vehicle_type,
  //     ddl_tyre_brand_label: row.tyre_brand,
  //     ddl_vehicle_brand_label: row.vehicle_brand,
  //     ddl_no_of_wheels_label: row.no_of_wheels,
  //     txt_type: row.vehicle_ownership,
  //     txt_purchase_date: row.vehicle_purchase_date,

  //   });
  // };

  const onSelectModule = (event, value) => {
    console.log(event, "sen19", value.name);

    switch (value.name) {
      case "ddl_vehicle_type":
        console.log("reached type");
        setAddVehicle({
          ...addVehicle,
          ddl_vehicle_type_id: event.value,
          ddl_vehicle_type_label: event.label,
        });
        break;

      case "ddl_tyre_brand":
        setAddVehicle({
          ...addVehicle,
          ddl_tyre_brand_id: event.value,
          ddl_tyre_brand_label: event.label,
        });
        break;

      case "ddl_vehicle_brand":
        setAddVehicle({
          ...addVehicle,
          ddl_vehicle_brand_id: event.value,
          ddl_vehicle_brand_label: event.label,
        });
        break;

      case "ddl_no_of_wheels":
        setAddVehicle({
          ...addVehicle,
          ddl_no_of_wheels_id: event.value,
          ddl_no_of_wheels_label: event.label,
        });
    }
  };

  // const onChange = (e) => {
  //   const { value, name } = e.target;
  
  //   // if (name.endsWith("_image_path")) {
  //     const file = e.target.files[0];
  //   //   if (file.size > 1048576) {
  //   //     alert("File size exceeds 1MB, please upload a smaller file.");
  //   //     return;
  //   //   }
  
  //     setFileLoading(true);
  
  //     postFileUpload(
  //       file,
  //       (r) => {
  //         setAddVehicle({
  //           ...addVehicle,
  //           [`${name}`]: r.fileName,
  //           [`${name}_name`]: r.originalFileName,
  //         });
  //         setFileLoading(false);
  //       },
  //       (err) => {
  //         dispatch({
  //           type: actionTypes.SET_OPEN_MSG,
  //           payload: { msg: err, msgType: "error" },
  //         });
  //         setFileLoading(false);
  //       }
  //     );
  //   // } else {
  //     setAddVehicle({ ...addVehicle, [name]: value });
  //   // }
  // };
  

  const onChange = (e) => {
    const { value, name } = e.target;

    console.log(e.target.checked, "sen23", name, value);

    if (name === "vehicle_ownership" && value === "Own") {
      setCheckedOwn(true)
      setCheckedRented(false)
    }
    else if (name === "vehicle_ownership" && value === "Rented") {
      setCheckedRented(true)
      setCheckedOwn(false)

    }
    else if (name === "insurance_image_path") {
      setFileLoading(true);
      const file = e.target.files[0];
      console.log("sen20102022", file)
      if (file.size < 1000000) {
        // const reader = new FileReader();
        // var url = reader.readAsDataURL(file);
        postFileUpload(
          file,
          (r) => {
            setAddVehicle({
              ...addVehicle,
              insurance_image_path: r.fileName,
              insurance_image_name: r.originalFileName,


            });
            setFileLoading(false);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setFileLoading(false);

          }
        );
      } else {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "File should be less then 1MB",
            msgType: "warning",
          },
        });
      }
    }
    else if (name === "pollution_image_path") {
      setFileLoading(true);
      const file = e.target.files[0];
      console.log("sen20102022", file)
      if (file.size < 1000000) {
        // const reader = new FileReader();
        // var url = reader.readAsDataURL(file);
        postFileUpload(
          file,
          (r) => {
            setAddVehicle({
              ...addVehicle,

              pollution_image_path: r.fileName,
              pollution_image_name: r.originalFileName,

            });
            setFileLoading(false);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setFileLoading(false);

          }
        );
      } else {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "File should be less then 1MB",
            msgType: "warning",
          },
        });
      }
    }
    else if (name === "permit_image_path") {
      setFileLoading(true);
      const file = e.target.files[0];
      console.log("sen20102022", file)
      if (file.size < 1000000) {
        // const reader = new FileReader();
        // var url = reader.readAsDataURL(file);
        postFileUpload(
          file,
          (r) => {
            setAddVehicle({
              ...addVehicle,

              permit_image_path: r.fileName,
              permit_image_name: r.originalFileName,


            });
            setFileLoading(false);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setFileLoading(false);

          }
        );
      } else {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "File should be less then 1MB",
            msgType: "warning",
          },
        });
      }
    }
    else if (name === "tax_image_path") {
      setFileLoading(true);
      const file = e.target.files[0];
      console.log("sen20102022", file)
      if (file.size < 1000000) {
        // const reader = new FileReader();
        // var url = reader.readAsDataURL(file);
        postFileUpload(
          file,
          (r) => {
            setAddVehicle({
              ...addVehicle,

              tax_image_path: r.fileName,
              tax_image_name: r.originalFileName,


            });
            setFileLoading(false);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setFileLoading(false);

          }
        );
      } else {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "File should be less then 1MB",
            msgType: "warning",
          },
        });
      }
    }
    else if (name === "bluebook_image_path") {
      setFileLoading(true);
      const file = e.target.files[0];
      console.log("sen20102022", file)
      if (file.size < 1000000) {
        // const reader = new FileReader();
        // var url = reader.readAsDataURL(file);
        postFileUpload(
          file,
          (r) => {
            setAddVehicle({
              ...addVehicle,

              bluebook_image_path: r.fileName,
              bluebook_image_name: r.originalFileName,


            });
            setFileLoading(false);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setFileLoading(false);

          }
        );
      } else {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "File should be less then 1MB",
            msgType: "warning",
          },
        });
      }
    }
    else if (name === "fitness_image_path") {
      setFileLoading(true);
      const file = e.target.files[0];
      console.log("sen20102022", file)
      if (file.size < 1000000) {
        // const reader = new FileReader();
        // var url = reader.readAsDataURL(file);
        postFileUpload(
          file,
          (r) => {
            setAddVehicle({
              ...addVehicle,

              fitness_image_path: r.fileName,
              fitness_image_name: r.originalFileName,


            });
            setFileLoading(false);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setFileLoading(false);

          }
        );
      } else {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "File should be less then 1MB",
            msgType: "warning",
          },
        });
      }
    }
    else if (name === "loan_image_path") {
      setFileLoading(true);
      const file = e.target.files[0];
      console.log("sen20102022", file)
      if (file.size < 1000000) {
        // const reader = new FileReader();
        // var url = reader.readAsDataURL(file);
        postFileUpload(
          file,
          (r) => {
            setAddVehicle({
              ...addVehicle,

              loan_image_path: r.fileName,
              loan_image_name: r.originalFileName,


            });
            setFileLoading(false);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setFileLoading(false);

          }
        );
      } else {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "File should be less then 1MB",
            msgType: "warning",
          },
        });
      }
    }
    else if (name === "bank_image_path") {
      setFileLoading(true);
      const file = e.target.files[0];
      console.log("sen20102022", file)
      if (file.size < 1000000) {
        // const reader = new FileReader();
        // var url = reader.readAsDataURL(file);
        postFileUpload(
          file,
          (r) => {
            setAddVehicle({
              ...addVehicle,

              bank_image_path: r.fileName,
              bank_image_name: r.originalFileName,

            });
            setFileLoading(false);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setFileLoading(false);

          }
        );
      } else {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "File should be less then 1MB",
            msgType: "warning",
          },
        });
      }
    }
    else if (name === "gps_image_path") {
      setFileLoading(true);
      const file = e.target.files[0];
      console.log("sen20102022", file)
      if (file.size < 1000000) {
        // const reader = new FileReader();
        // var url = reader.readAsDataURL(file);
        postFileUpload(
          file,
          (r) => {
            setAddVehicle({
              ...addVehicle,

              gps_image_path: r.fileName,
              gps_image_name: r.originalFileName,

            });
            setFileLoading(false);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setFileLoading(false);

          }
        );
      } else {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "File should be less then 1MB",
            msgType: "warning",
          },
        });
      }
    }

    setAddVehicle({ ...addVehicle, [name]: value });
  };

  const classes = useStyles();

  // on Close Model
  const onClickClose = () => {
    setRefresh(!refresh);

    setAddVehicle({
      edit: false,
      vehicle_id: "",
      txt_keyword_pharse: "",
      txt_vehicle: "",
      txt_vehicle_specification: "",
      txt_contact_no: "",
      ddl_vehicle_type_id: "",
      ddl_vehicle_type_label: "",
      ddl_tyre_brand_id: "",
      ddl_tyre_brand_label: "",
      ddl_vehicle_brand_id: "",
      ddl_vehicle_brand_label: "",
      ddl_no_of_wheels_id: "",
      ddl_no_of_wheels_label: "",
      txt_type: "",
      txt_km_start: "",
      txt_mileage: "",

      txt_engine_number: "",
      txt_chassis_number: "",

      txt_purchase_date: currentDate(),


      txt_insurance_company: "",
      txt_insurance_file: "",
      insurance_image_path: "",
      insurance_image_name: "",

      txt_insurance_date: currentDate(),
      txt_pollution_file: "",
      pollution_image_path: "",
      pollution_image_name: "",
      txt_pollution_date: currentDate(),
      txt_permit_file: "",
      permit_image_path: "",
      permit_image_name: "",
      txt_permit_date: currentDate(),
      txt_tax_file: "",
      tax_image_path: "",
      tax_image_name: "",
      txt_tax_date: currentDate(),
      txt_registration_file: "",
      txt_registration_date: currentDate(),
      txt_bluebook_file: "",
      bluebook_image_path: "",
      bluebook_image_name: '',
      // txt_bluebook_date: currentDate(),
      txt_fitness_number: '',
      fitness_image_path: "",
      fitness_image_name: "",
      txt_fitness_date: currentDate(),
      txt_loan_file: "",
      loan_image_path: "",
      loan_image_name: "",
      // txt_loan_date: currentDate(),
      txt_bank_noc_file: "",
      bank_image_path: "",
      bank_image_name: "",
      // txt_bank_noc_date: currentDate(),

      switch_active_status: true,
    });
    history.push({
      pathname: "/admin/master/vehicle",
    });
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddVehicle({
      txt_vehicle: "",
      txt_vehicle_specification: "",
      txt_contact_no: "",
      ddl_vehicle_type_id: "",
      ddl_vehicle_type_label: "",
      ddl_tyre_brand_id: "",
      ddl_tyre_brand_label: "",
      ddl_vehicle_brand_id: "",
      ddl_vehicle_brand_label: "",
      ddl_no_of_wheels_id: "",
      ddl_no_of_wheels_label: "",
      txt_type: "",
      txt_km_start: "",
      txt_mileage: "",

      txt_engine_number: "",
      txt_chassis_number: "",

      txt_purchase_date: currentDate(),


      txt_insurance_company: "",
      txt_insurance_file: "",
      insurance_image_path: "",
      insurance_image_name: "",

      txt_insurance_date: currentDate(),
      txt_pollution_file: "",
      pollution_image_path: "",
      pollution_image_name: "",
      txt_pollution_date: currentDate(),
      txt_permit_file: "",
      permit_image_path: "",
      permit_image_name: "",
      txt_permit_date: currentDate(),
      txt_tax_file: "",
      tax_image_path: "",
      tax_image_name: "",
      txt_tax_date: currentDate(),
      txt_registration_file: "",
      txt_registration_date: currentDate(),
      txt_bluebook_file: "",
      bluebook_image_path: "",
      bluebook_image_name: '',
      // txt_bluebook_date: currentDate(),
      txt_fitness_number: '',
      fitness_image_path: "",
      fitness_image_name: "",
      txt_fitness_date: currentDate(),
      txt_loan_file: "",
      loan_image_path: "",
      loan_image_name: "",
      // txt_loan_date: currentDate(),
      txt_bank_noc_file: "",
      bank_image_path: "",
      bank_image_name: "",
      // txt_bank_noc_date: currentDate(),

    });
  };

  const onClickCollapsimage = () => {

    collapsibleimage ?
      setCollapsibleimage(false)
      :
      setCollapsibleimage(true)
  }

  const onClickCollaps = () => {

    collapsible ?
      setCollapsible(false)
      :
      setCollapsible(true)
  }


  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Master > Vehicle"
        btnToolTip="Add New Vehicle"
      // onClickAddBtn={() => setClassicModal(true)}
      />



      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <CustomCard
            cdTitle="Vehicle"
            filterIcon
            onClickFilter={() => { }}
          >
            <GridContainer
              style={{ padding: "10px" }}
              justify="space-between"
              alignItems="center"
            >
              <CustomCard
                cdTitle="Truck Details"
                btnToolTip={collapsible ? "Collaps" : "Expand"}
                onClickCollapsible={onClickCollaps}
                buttonAction={collapsible}
                filterIcon
              >
                {collapsible ?
                  <GridContainer
                    justify="space-between"
                    alignItems="center">


                    <GridItem xs={2}>
                      <InputLabel required={true} id="label">Vehicle Type</InputLabel>
                      <Select
                        options={allType}
                        placeholder="Select vehicle type"
                        name="ddl_vehicle_type"
                        formatGroupLabel={(d) => d.label}
                        className={classes.customSelect}
                        value={{
                          value: addVehicle.ddl_vehicle_type_id,
                          label: addVehicle.ddl_vehicle_type_label,
                        }}
                        onChange={onSelectModule}

                      />
                      {error.ddl_vehicle_type_id && (
                        <div className={classes.ddlError}>Type is Required</div>
                      )}

                    </GridItem>
                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">Vehicle Brand</InputLabel>
                      <Select
                        options={allBrand}
                        placeholder="Select vehicle brand"
                        name="ddl_vehicle_brand"
                        formatGroupLabel={(d) => d.label}
                        className={classes.customSelect}
                        value={{
                          value: addVehicle.ddl_vehicle_brand_id,
                          label: addVehicle.ddl_vehicle_brand_label,
                        }}
                        onChange={onSelectModule}

                      />
                      {error.ddl_vehicle_brand_id && (
                        <div className={classes.ddlError}>
                          Brand is Required
                        </div>
                      )}
                    </GridItem>
                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">Vehicle No</InputLabel>
                      <TextField

                        size="small"
                        type="string"
                        placeholder="Vehicle no"
                        name="txt_vehicle"
                        fullWidth={true}
                        onChange={onChange}
                        value={addVehicle.txt_vehicle}
                        id="outlined-basic"

                        FormHelperTextProps={{
                          style: { textAlign: "right" },
                        }}
                        variant="outlined"


                      />
                      {error.txt_vehicle && (
                        <div className={classes.ddlError}>
                          Vehicle is Required
                        </div>
                      )}

                    </GridItem>
                    <GridItem xs={2}>
                      <InputLabel required={true} id="label">Wheels</InputLabel>
                      <Select
                        options={vehicleWheels}
                        name="ddl_no_of_wheels"
                        formatGroupLabel={(d) => d.label}
                        className={classes.customSelect}
                        value={
                          {
                            value: addVehicle.ddl_no_of_wheels_id,
                            label: addVehicle.ddl_no_of_wheels_label
                          }
                        }
                        onChange={onSelectModule}

                      />
                      {error.ddl_no_of_wheels_id && (
                        <div className={classes.ddlError}>
                          Wheels is Required
                        </div>
                      )}

                    </GridItem>

                    <GridItem xs={2}>
                      <InputLabel required={true} id="label">Km Start</InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        name="txt_km_start"
                        onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={addVehicle.txt_km_start}
                        variant="outlined"
                        inputProps={{ style: { textAlign: "right" } }}
                      />
                      {error.txt_km_start && (
                        <div className={classes.ddlError}>
                          KM is Required
                        </div>
                      )}

                    </GridItem>

                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">Mileage</InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        name="txt_mileage"
                        onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={addVehicle.txt_mileage}
                        variant="outlined"
                        inputProps={{ style: { textAlign: "right" } }}
                      />
                      {error.txt_mileage && (
                        <div className={classes.ddlError}>
                          Mileage is Required
                        </div>
                      )}

                    </GridItem>
                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">Purchase Date</InputLabel>
                      <TextField
                        size="small"
                        type="Date"
                        name="txt_purchase_date"
                        onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={addVehicle.txt_purchase_date}
                        variant="outlined"
                      />

                    </GridItem>

                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">Insurance Company</InputLabel>
                      <TextField
                        size="small"
                        type="string"
                        name="txt_insurance_company"
                        onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={addVehicle.txt_insurance_company}
                        variant="outlined"
                      />

                      {error.txt_insurance_company && (
                        <div className={classes.ddlError}>
                          Insurance is Required
                        </div>
                      )}
                    </GridItem>
                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">Engine Number</InputLabel>
                      <TextField
                        size="small"
                        type="string"
                        name="txt_engine_number"
                        onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={addVehicle.txt_engine_number}
                        variant="outlined"
                        disabled={checkedRented ? true : false}

                      />
                     

                    </GridItem>
                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">Chassis Number</InputLabel>
                      <TextField
                        size="small"
                        type="string"
                        name="txt_chassis_number"
                        onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={addVehicle.txt_chassis_number}
                        variant="outlined"
                        disabled={checkedRented ? true : false}

                      />
                  

                    </GridItem>
                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">Owners Name</InputLabel>
                      <TextField
                        size="small"
                        type="string"
                        name="txt_Owners"
                        onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={addVehicle.txt_Owners}
                        variant="outlined"
                      />
                      {error.txt_Owners && (
                        <div className={classes.ddlError}>
                          Owners is Required
                        </div>
                      )}

                    </GridItem>
                    <GridItem xs={3}>
                      {/* <InputLabel required={true} id="label">Purchase Date</InputLabel> */}
                      <InputLabel
                        // required={item.required}
                        style={{ marginTop: -12 }}
                        id="label"
                      >
                        Ownership
                      </InputLabel>

                      <RadioGroup
                        row
                        name="vehicle_ownership"
                        onChange={onChange}
                      >
                        <FormControlLabel
                          style={fontStyle}
                          value="Rented"
                          control={<Radio />}
                          label="Rented"
                          checked={checkedRented}

                        />
                        <FormControlLabel
                          value="Own"
                          control={<Radio />}
                          label="Own"
                          checked={checkedOwn}

                        />
                      </RadioGroup>
                    </GridItem>
                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">Vehicle Charges</InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        name="vehicle_charges"
                        onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={addVehicle.vehicle_charges}
                        variant="outlined"
                        inputProps={{ style: { textAlign: "right" } }}
                      />
                      {error.txt_chassis_number && (
                        <div className={classes.ddlError}>
                          Vehicle Charges is Required
                        </div>
                      )}

                    </GridItem>

                  </GridContainer>
                  : ""}


              </CustomCard>
              <CustomCard
                cdTitle=" Truck Document Details"
                btnToolTip={collapsibleimage ? "Collaps" : "Expand"}
                onClickCollapsible={onClickCollapsimage}
                buttonAction={collapsibleimage}
                filterIcon
              >
                {collapsibleimage ?
                  <GridContainer
                    style={{ padding: 25 }}
                    justify="space-between"
                    alignItems="center">

                    <div style={{ width: "100%", display: "flex", borderBottom: "1px solid", borderColor: "#3699ff", alignItems: "center" }}>
                      <GridItem xs={2} >
                        <InputLabel id="label">Insurance No.</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Insurance no"
                          name="txt_insurance_file"
                          type="text"
                          onChange={onChange}
                          value={addVehicle.txt_insurance_file}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                      <GridItem xs={8} >
                        <GridContainer>
                          <GridItem xs="6">
                            <Paper elevation={0}>
                              {fileLoading ? (
                                <CircularProgress />
                              ) : (
                                <GridItem style={{ margin: "4px 50px" }}>
                                  <IconButton variant="text">
                                    <Tooltip title="Download">
                                      {addVehicle.insurance_image_path ?
                                        (<a href={IMG_API + addVehicle.insurance_image_path}>
                                          <CloudDownloadOutlinedIcon
                                            style={addVehicle.insurance_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                        </a>)
                                        :
                                        <CloudDownloadOutlinedIcon
                                          style={addVehicle.insurance_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                      }
                                    </Tooltip>
                                  </IconButton>
                                </GridItem>
                              )}
                            </Paper>
                          </GridItem>
                          <GridItem xs="3">
                            <input
                              accept="application/pdf,image/*"
                              className={classes.input}
                              style={{ display: "none" }}
                              name="insurance_image_path"
                              onChange={onChange}
                              id="insurance_image"
                              type="file"
                              disabled={checkedRented ? true : false}

                            />
                            <label htmlFor="insurance_image">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth={true}
                                className={classes.browseBtn}
                                disabled={checkedRented ? true : false}
                              >
                                Upload Img
                              </Button>
                            </label>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={2} >
                        <InputLabel id="label">Expiry Date</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Insurance no"
                          name="txt_insurance_date"
                          type="Date"
                          onChange={onChange}
                          value={addVehicle.txt_insurance_date}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                    </div>
                    <div style={{ width: "100%", display: "flex", borderBottom: "1px solid", borderColor: "#3699ff", alignItems: "center" }}>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        <InputLabel id="label">Pollution No.</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Pollution no"
                          name="txt_pollution_file"
                          type="text"
                          onChange={onChange}
                          value={addVehicle.txt_pollution_file}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                      <GridItem xs={8} style={{ marginTop: 25 }}>
                        <GridContainer>
                          <GridItem xs="6">
                            <Paper elevation={0}>
                              {fileLoading ? (
                                <CircularProgress />
                              ) : (
                                <GridItem style={{ margin: "4px 50px" }}>
                                  <IconButton variant="text">
                                    <Tooltip title="Download">
                                      {addVehicle.pollution_image_path ?
                                        (<a href={IMG_API + addVehicle.pollution_image_path}>
                                          <CloudDownloadOutlinedIcon
                                            style={addVehicle.pollution_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                        </a>)
                                        :
                                        <CloudDownloadOutlinedIcon
                                          style={addVehicle.pollution_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                      }
                                    </Tooltip>
                                  </IconButton>
                                </GridItem>

                              )}
                            </Paper>
                          </GridItem>
                          <GridItem xs="3" style={{ marginTop: 25 }}>
                            <input
                              accept="application/pdf,image/*"
                              className={classes.input}
                              style={{ display: "none" }}
                              name="pollution_image_path"
                              onChange={onChange}
                              id="pollution_image"
                              type="file"
                              disabled={checkedRented ? true : false}
                            />
                            <label htmlFor="pollution_image">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth={true}
                                className={classes.browseBtn}
                                disabled={checkedRented ? true : false}
                              >
                                Upload Img
                              </Button>
                            </label>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        <InputLabel id="label">Expiry Date</InputLabel>
                        <TextField

                          size="small"

                          name="txt_pollution_date"
                          type="Date"
                          onChange={onChange}
                          value={addVehicle.txt_pollution_date}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                    </div>
                    <div style={{ width: "100%", display: "flex", borderBottom: "1px solid", borderColor: "#3699ff", alignItems: "center" }}>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        <InputLabel id="label">Permit No.</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Insurance no"
                          name="txt_permit_file"
                          type="text"
                          onChange={onChange}
                          value={addVehicle.txt_permit_file}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                      <GridItem xs={8} style={{ marginTop: 25 }}>
                        <GridContainer>
                          <GridItem xs="6">
                            <Paper elevation={0}>
                              {fileLoading ? (
                                <CircularProgress />
                              ) : (
                                <GridItem style={{ margin: "4px 50px" }}>
                                  <IconButton variant="text">
                                    <Tooltip title="Download">
                                      {addVehicle.permit_image_path ?
                                        (<a href={IMG_API + addVehicle.permit_image_path}>
                                          <CloudDownloadOutlinedIcon
                                            style={addVehicle.permit_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                        </a>)
                                        :
                                        <CloudDownloadOutlinedIcon
                                          style={addVehicle.permit_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                      }
                                    </Tooltip>
                                  </IconButton>
                                </GridItem>

                              )}
                            </Paper>
                          </GridItem>
                          <GridItem xs="3" style={{ marginTop: 25 }}>
                            <input
                              accept="application/pdf,image/*"
                              className={classes.input}
                              style={{ display: "none" }}
                              name="permit_image_path"
                              onChange={onChange}
                              id="permit_image"
                              type="file"
                              disabled={checkedRented ? true : false}

                            />
                            <label htmlFor="permit_image">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth={true}
                                className={classes.browseBtn}
                                disabled={checkedRented ? true : false}

                              >
                                Upload Img
                              </Button>
                            </label>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        <InputLabel id="label">Expiry Date</InputLabel>
                        <TextField

                          size="small"
                          name="txt_permit_date"
                          type="Date"
                          onChange={onChange}
                          value={addVehicle.txt_permit_date}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                    </div>
                    <div style={{ width: "100%", display: "flex", borderBottom: "1px solid", borderColor: "#3699ff", alignItems: "center" }}>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        <InputLabel id="label">Tax No.(GRIPS No.)</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Tax No"
                          name="txt_tax_file"
                          type="text"
                          onChange={onChange}
                          value={addVehicle.txt_tax_file}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                      <GridItem xs={8} style={{ marginTop: 25 }}>
                        <GridContainer>
                          <GridItem xs="6">
                            <Paper elevation={0}>
                              {fileLoading ? (
                                <CircularProgress />
                              ) : (
                                <GridItem style={{ margin: "4px 50px" }}>
                                  <IconButton variant="text">
                                    <Tooltip title="Download">
                                      {addVehicle.tax_image_path ?
                                        (<a href={IMG_API + addVehicle.tax_image_path}>
                                          <CloudDownloadOutlinedIcon
                                            style={addVehicle.tax_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                        </a>)
                                        :
                                        <CloudDownloadOutlinedIcon
                                          style={addVehicle.tax_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                      }
                                    </Tooltip>
                                  </IconButton>
                                </GridItem>
                              )}
                            </Paper>
                          </GridItem>
                          <GridItem xs="3" style={{ marginTop: 25 }}>
                            <input
                              accept="application/pdf,image/*"
                              className={classes.input}
                              style={{ display: "none" }}
                              name="tax_image_path"
                              onChange={onChange}
                              id="tax_image"
                              type="file"
                              disabled={checkedRented ? true : false}

                            />
                            <label htmlFor="tax_image">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth={true}
                                className={classes.browseBtn}
                                disabled={checkedRented ? true : false}

                              >
                                Upload Img
                              </Button>
                            </label>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        <InputLabel id="label">Expiry Date</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Expiry Date"
                          name="txt_tax_date"
                          type="Date"
                          onChange={onChange}
                          value={addVehicle.txt_tax_date}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                    </div>
                    <div style={{ width: "100%", display: "flex", borderBottom: "1px solid", borderColor: "#3699ff", alignItems: "center" }}>

                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        <InputLabel id="label">Bluebook No.</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Bluebook no"
                          name="txt_bluebook_file"
                          type="text"
                          onChange={onChange}
                          value={addVehicle.txt_bluebook_file}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                      <GridItem xs={8} style={{ marginTop: 25 }}>
                        <GridContainer>
                          <GridItem xs="6">
                            <Paper elevation={0}>
                              {fileLoading ? (
                                <CircularProgress />
                              ) : (
                                <GridItem style={{ margin: "4px 50px" }}>
                                  <IconButton variant="text">
                                    <Tooltip title="Download">
                                      {addVehicle.bluebook_image_path ?
                                        (<a href={IMG_API + addVehicle.bluebook_image_path}>
                                          <CloudDownloadOutlinedIcon
                                            style={addVehicle.bluebook_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                        </a>)
                                        :
                                        <CloudDownloadOutlinedIcon
                                          style={addVehicle.bluebook_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                      }
                                    </Tooltip>
                                  </IconButton>
                                </GridItem>
                              )}
                            </Paper>
                          </GridItem>
                          <GridItem xs="3" style={{ marginTop: 25 }}>
                            <input
                              accept="application/pdf,image/*"
                              className={classes.input}
                              style={{ display: "none" }}
                              name="bluebook_image_path"
                              onChange={onChange}
                              id="bluebook_image"
                              type="file"
                              disabled={checkedRented ? true : false}

                            />
                            <label htmlFor="bluebook_image">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth={true}
                                className={classes.browseBtn}
                                disabled={checkedRented ? true : false}

                              >
                                Upload Img
                              </Button>
                            </label>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        {/* <InputLabel id="label">Expiry Date</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Insurance no"
                          name="txt_bluebook_date"
                          type="Date"
                          onChange={onChange}
                          value={addVehicle.txt_bluebook_date}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                        /> */}

                      </GridItem>
                    </div>
                    <div style={{ width: "100%", display: "flex", borderBottom: "1px solid", borderColor: "#3699ff", alignItems: "center" }}>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        <InputLabel id="label">Fitness No.</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Fitness No."
                          name="txt_fitness_number"
                          type="text"
                          onChange={onChange}
                          value={addVehicle.txt_fitness_number}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                      <GridItem xs={8} style={{ marginTop: 25 }}>
                        <GridContainer>
                          <GridItem xs="6">
                            <Paper elevation={0}>
                              {fileLoading ? (
                                <CircularProgress />
                              ) : (
                                <GridItem style={{ margin: "4px 50px" }}>
                                  <IconButton variant="text">
                                    <Tooltip title="Download">
                                      {addVehicle.fitness_image_path ?
                                        (<a href={IMG_API + addVehicle.fitness_image_path}>
                                          <CloudDownloadOutlinedIcon
                                            style={addVehicle.fitness_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                        </a>)
                                        :
                                        <CloudDownloadOutlinedIcon
                                          style={addVehicle.fitness_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                      }
                                    </Tooltip>
                                  </IconButton>
                                </GridItem>
                                
                              )}
                            </Paper>
                          </GridItem>
                          <GridItem xs="3" style={{ marginTop: 25 }}>
                            <input
                              accept="application/pdf,image/*"
                              className={classes.input}
                              style={{ display: "none" }}
                              name="fitness_image_path"
                              onChange={onChange}
                              id="fitness_image"
                              type="file"
                              disabled={checkedRented ? true : false}

                            />
                            <label htmlFor="fitness_image">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth={true}
                                className={classes.browseBtn}
                                disabled={checkedRented ? true : false}

                              >
                                Upload Img
                              </Button>
                            </label>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        <InputLabel id="label">Expiry Date</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Insurance no"
                          name="txt_fitness_date"
                          type="Date"
                          onChange={onChange}
                          value={addVehicle.txt_fitness_date}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                    </div>
                    <div style={{ width: "100%", display: "flex", borderBottom: "1px solid", borderColor: "#3699ff", alignItems: "center" }}>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        <InputLabel id="label">Loan Letter No.</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Loan Letter No."
                          name="txt_loan_file"
                          type="text"
                          onChange={onChange}
                          value={addVehicle.txt_loan_file}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                      <GridItem xs={8} style={{ marginTop: 25 }} >
                        <GridContainer>
                          <GridItem xs="6">
                            <Paper elevation={0}>
                              {fileLoading ? (
                                <CircularProgress />
                              ) : (
                                <GridItem style={{ margin: "4px 50px" }}>
                                  <IconButton variant="text">
                                    <Tooltip title="Download">
                                      {addVehicle.loan_image_path ?
                                        (<a href={IMG_API + addVehicle.loan_image_path}>
                                          <CloudDownloadOutlinedIcon
                                            style={addVehicle.loan_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                        </a>)
                                        :
                                        <CloudDownloadOutlinedIcon
                                          style={addVehicle.loan_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                      }
                                    </Tooltip>
                                  </IconButton>
                                </GridItem>
                              )}
                            </Paper>
                          </GridItem>
                          <GridItem xs="3" style={{ marginTop: 25 }}>
                            <input
                              accept="application/pdf,image/*"
                              className={classes.input}
                              style={{ display: "none" }}
                              name="loan_image_path"
                              onChange={onChange}
                              id="loan_image"
                              type="file"
                              disabled={checkedRented ? true : false}

                            />
                            <label htmlFor="loan_image">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth={true}
                                className={classes.browseBtn}
                                disabled={checkedRented ? true : false}

                              >
                                Upload Img
                              </Button>
                            </label>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        {/* <InputLabel id="label">Expiry Date</InputLabel> */}
                        {/* <TextField

                          size="small"

                          name="txt_loan_date"
                          type="Date"
                          onChange={onChange}
                          value={addVehicle.txt_loan_date}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled
                        /> */}

                      </GridItem>
                    </div>
                    <div style={{ width: "100%", display: "flex", borderBottom: "1px solid", borderColor: "#3699ff", alignItems: "center" }}>

                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        <InputLabel id="label">Bank NOC No.</InputLabel>
                        <TextField

                          size="small"
                          placeholder="Bank NOC No."
                          name="txt_bank_noc_file"
                          type="text"
                          onChange={onChange}
                          value={addVehicle.txt_bank_noc_file}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        />

                      </GridItem>
                      <GridItem xs={8} style={{ marginTop: 25 }}>
                        <GridContainer>
                          <GridItem xs="6">
                            <Paper elevation={0}>
                              {fileLoading ? (
                                <CircularProgress />
                              ) : (

                                <GridItem style={{ margin: "4px 50px" }}>
                                  <IconButton variant="text">
                                    <Tooltip title="Download">
                                      {addVehicle.bank_image_path ?
                                        (<a href={IMG_API + addVehicle.bank_image_path}>
                                          <CloudDownloadOutlinedIcon
                                            style={addVehicle.bank_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                        </a>)
                                        :
                                        <CloudDownloadOutlinedIcon
                                          style={addVehicle.bank_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                      }
                                    </Tooltip>
                                  </IconButton>
                                </GridItem>
                                // <img
                                //   id="bank_image_path"
                                //   // className={classes.itemImg}
                                //   style={{ width: "30%" }}
                                //   src={
                                //     addVehicle.bank_image_path
                                //       ? IMG_API + addVehicle.bank_image_path
                                //       : "https://via.placeholder.com/160"
                                //   }
                                //   height="100%"
                                //   alt="image"
                                // />
                              )}
                            </Paper>
                          </GridItem>
                          <GridItem xs="3" style={{ marginTop: 25 }}>
                            <input
                              accept="application/pdf,image/*"
                              className={classes.input}
                              style={{ display: "none" }}
                              name="bank_image_path"
                              onChange={onChange}
                              id="bank_image"
                              type="file"
                              disabled={checkedRented ? true : false}

                            />
                            <label htmlFor="bank_image">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth={true}
                                className={classes.browseBtn}
                                disabled={checkedRented ? true : false}

                              >
                                Upload Img
                              </Button>
                            </label>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={2} style={{ marginTop: 25 }}>
                        {/* <InputLabel id="label">Expiry Date</InputLabel>
                        <TextField

                          size="small"
                          // placeholder="Insurance no"
                          name="txt_bank_noc_date"
                          type="Date"
                          onChange={onChange}
                          value={addVehicle.txt_bank_noc_date}
                          id="outlined-basic"
                          FormHelperTextProps={{
                            style: { textAlign: "right" },
                          }}
                          variant="outlined"
                          disabled={checkedRented ? true : false}
                        /> */}

                      </GridItem>
                    </div>


                    <div style={{ width: "100%", display: "flex", borderBottom: "1px solid", borderColor: "#3699ff", alignItems: "center" }}>

<GridItem xs={2} style={{ marginTop: 25 }}>
  <InputLabel id="label">GPS</InputLabel>
  {/* <TextField

    size="small"
    placeholder="Bank NOC No."
    name="txt_bank_noc_file"
    type="text"
    onChange={onChange}
    value={addVehicle.txt_bank_noc_file}
    id="outlined-basic"
    FormHelperTextProps={{
      style: { textAlign: "right" },
    }}
    variant="outlined"
    disabled={checkedRented ? true : false}
  /> */}

</GridItem>
<GridItem xs={8} style={{ marginTop: 25 }}>
  <GridContainer>
    <GridItem xs="6">
      <Paper elevation={0}>
        {fileLoading ? (
          <CircularProgress />
        ) : (

          <GridItem style={{ margin: "4px 50px" }}>
            <IconButton variant="text">
              <Tooltip title="Download">
                {addVehicle.gps_image_path ?
                  (<a href={IMG_API + addVehicle.gps_image_path}>
                    <CloudDownloadOutlinedIcon
                      style={addVehicle.gps_image_path ? { color: "#3699ff" } : { color: "black" }} />
                  </a>)
                  :
                  <CloudDownloadOutlinedIcon
                    style={addVehicle.gps_image_path ? { color: "#3699ff" } : { color: "black" }} />
                }
              </Tooltip>
            </IconButton>
          </GridItem>
          // <img
          //   id="bank_image_path"
          //   // className={classes.itemImg}
          //   style={{ width: "30%" }}
          //   src={
          //     addVehicle.bank_image_path
          //       ? IMG_API + addVehicle.bank_image_path
          //       : "https://via.placeholder.com/160"
          //   }
          //   height="100%"
          //   alt="image"
          // />
        )}
      </Paper>
    </GridItem>
    <GridItem xs="3" style={{ marginTop: 25 }}>
      <input
        accept="application/pdf,image/*"
        className={classes.input}
        style={{ display: "none" }}
        name="gps_image_path"
        onChange={onChange}
        id="gps_image"
        type="file"
        disabled={checkedRented ? true : false}

      />
      <label htmlFor="gps_image">
        <Button
          variant="contained"
          color="primary"
          component="span"
          fullWidth={true}
          className={classes.browseBtn}
          disabled={checkedRented ? true : false}

        >
          Upload Img
        </Button>
      </label>
    </GridItem>
  </GridContainer>
</GridItem>
<GridItem xs={2} style={{ marginTop: 25 }}>
  <InputLabel id="label">Expiry Date</InputLabel>
  <TextField

    size="small"
    // placeholder="Insurance no"
    name="txt_gps_date"
    type="Date"
    onChange={onChange}
    value={addVehicle.txt_gps_date}
    id="outlined-basic"
    FormHelperTextProps={{
      style: { textAlign: "right" },
    }}
    variant="outlined"
    disabled={checkedRented ? true : false}
  />

</GridItem>
</div>




                  </GridContainer>
                  : ""}

              </CustomCard>


              <div style={{ width: "100%" }} className={classes.actionbtns}>
                <CustomButton
                  style={{ float: "right", width: "10%", marginRight: "10px", border: "none" }}
                  onClick={onSubmitModel}
                >
                  {addVehicle.edit ? "Update" : "Submit"}
                </CustomButton>
                <CustomButton
                  style={{ float: "right", width: "10%", marginRight: "10px", background: 'red', border: "none" }}
                  onClick={onClickRefresh}
                >
                  <RotateLeftIcon />
                </CustomButton>
                <ColoseButton
                  style={{ backgroundColor: "red", color: "white", float: "right", width: "10%", marginRight: "10px", border: "none" }}
                  onClick={onClickClose}>
                  Close
                </ColoseButton>
              </div>
            </GridContainer>
          </CustomCard>
        </GridItem>
      </GridContainer>



      <MasterModelView
        classicModal={viewModel}
        viewHeader="View Vehicle"
        // onCloseModel={onCloseModel}
        onCloseModel={(e) => {
          e.preventDefault();
          setViewModel(false);
        }}
      >
        <VehicleViewPage
          title="View Vehicle"
          title1="Vehicle Attachment"
          viewData={viewData}
          viewData1={viewData1}
          addedItems={addedItems}
        // itemDetails={itemDetails}
        // allStatus={allStatus}

        />

      </MasterModelView>
    </ThemeProvider>
  );
};

export default VehicleForm;