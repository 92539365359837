import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";
import {
  ledgerRowData,
  getSearchAllJournalList,
  getLedgerAccount,
} from "../../../services/accountLedgerService";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";


import ReactSelect from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Autocomplete } from "@material-ui/lab";
import { IconButton, OutlinedInput } from "@material-ui/core";
import { currencyFormate } from "views/Pages/HelperComponent/utils";

//Tables
import {
  appFontWeightThin,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// import InvoiceViewForLedger from "./InvoiceViewForLedger";
// import ItemReceivedViewPageForLedger from './ItemReceivedViewPageForLedger'
// import DirectPurchaseViewForLedger from "./DirectPurchaseViewForLedger";
// import MasterModelForLedgerInvoiceView from "../../Components/MasterModelForLedgerInvoiceView";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "6px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "0px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  customSelect: {
    marginBottom: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
    "&:hover": {
      backgroundColor: "rgba(43,43, 43, 0.03)",
    },
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);
//Theme
import theme from "../../../theme/theme";
//Services

// import React from "react";
import {
  appDefaultColor,
  appSecondColor,
  reactSelectStyles,
} from "assets/jss/material-dashboard-pro-react";
import { appDefaultFamily } from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import React, { useState } from "react";
import PageTitle from "../HelperComponent/PageTitle";
import { currentDate, currentDate1, currentDate1Pdf, currentDatePdf } from "../HelperComponent/utils";
//SERVICE
import { getAllLedgerGroup } from "../../../services/LedgerAccountService";

import { getSearchLedgerVouchers, getLedgerClosingBalance } from "../../../services/accountLedgerService";

import { getListLedgerAccount } from "../../../services/LedgerAccountService";
import { getListGroup } from "../../../services/settingGroupService";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { getAllCashBook } from "services/cashBookService";
const useStyles1 = makeStyles(styles);

//PDF
// import pdfIcon from "../../../assets/img/pdf-icon.png"
// import { jsPDF } from "jspdf";
const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  narraction: {
    width: 155,
  },
  date: {
    width: 88
  },
  voucher: {
    width: 190
  },
  particular: {
    width: 110
  },

  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },

}));

const formData = {
  formName: "Add Category",
  fields: [
    {
      name: "txtBrand",
      label: "Voucher Name & Date",
      hidden: false,
      required: true,
      data_type: "string",
      html_element: "TextField",
      error: false,
      xs: 6,
      md: 6,
      lg: 7,
    },
    {
      name: "txtDate",

      hidden: false,
      required: true,
      data_type: "date",
      html_element: "TextField",
      xs: 6,
      md: 6,
      lg: 5,
    },

    {
      name: "sw_type",
      label: "Ledger",
      hidden: false,
      required: true,
      data_type: "string",
      html_element: "select",
      options: [
        {
          label: "Ledger",
          value: "Ledger",
        },
      ],
      xs: 6,
      md: 6,
      lg: 12,
    },

    {
      name: "sw_type",
      label: "Mode & References",
      hidden: false,
      required: true,
      data_type: "string",
      html_element: "select",
      options: [
        {
          label: "Bank",
          value: "Bank",
        },
      ],
      xs: 6,
      md: 6,
      lg: 6,
    },
    {
      name: "txtBrand",
      // label: "Mode & References",
      hidden: false,
      required: true,
      data_type: "string",
      html_element: "TextField",
      error: false,
      xs: 6,
      md: 6,
      lg: 6,
    },
    {
      name: "txtBrand",
      label: "Amount",
      hidden: false,
      required: true,
      data_type: "string",
      html_element: "TextField",
      error: false,
      xs: 6,
      md: 6,
      lg: 5,
    },
    {
      name: "txtBrand",
      label: "Narration",
      hidden: false,
      required: true,
      data_type: "string",
      html_element: "TextArea",
      error: false,
      xs: 6,
      md: 6,
      lg: 12,
    },
  ],
};
const onClickSubmit = () => {
  setClassicModal(false);
};
const onChange = (e) => {
  const { value, name } = e.target;
  setAddBrand({ ...allUnits, [name]: value });
};
const CashBookPage = () => {
  const classes1 = useStyles1();

  const history = useHistory();
  const [classicModal, setClassicModal] = React.useState(false);
  const [allUnits, setAllUnits] = React.useState([]);
  const [globalState, dispatch] = useStateValue();
  const [allGroup, setAllGroup] = React.useState([]);
  const [ledgerAccount, setAllLedgerAccount] = React.useState([]);
  const [addSearch, setAddSearch] = React.useState({
    ddl_ledger_account: "",
    ddl_ledger_group: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });

  console.log(currentDate(1), "data increse")

  const [openingBalance, setOpeningBalance] = React.useState({});
  const [ledgerGroup, setAllLedgerGroup] = React.useState([]);
  const [ledgerList, setAllledgerList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [viewRes, setViewRes] = React.useState("hidden");
  const [billNo, setBillNo] = React.useState('');
  const [transaction_type, set_transaction_type] = React.useState();
  const [collapsible, setCollapsible] = React.useState(true)
  let closingBalance = 0;
  let closing_balance_dr_cr = "";
  let total = 0;
  let totalDr = 0;
  let totalCr = 0;
let opening_balance = 0;
  const balanceSheet = () => {
    ledgerList.map((row, i) => {

      console.log("xx", openingBalance.opening_balance, totalDr, row.dr_cr);
      console.log("sen2505/row", row);

      if (row.dr_cr === 1) {
        totalDr = parseFloat(row.amount) + parseFloat(totalDr);
      } else {
        totalCr = parseFloat(row.amount) + parseFloat(totalCr);
      }
    });


    console.log("xx", openingBalance.opening_balance, totalDr);

    if (openingBalance.dr_cr_status === "Dr") {
      totalDr = openingBalance.opening_balance + Number(totalDr);
      // total = closingBalance + (totalCr - totalDr);
      // if(closingBalance > 0) closing_balance_dr_cr = "Dr";
      // else {
      //   closing_balance_dr_cr = "Cr";
      //   closingBalance = -closingBalance;
      // }
      console.log(totalDr, "xxx");
    }
    else {

      totalCr = openingBalance.opening_balance + (totalCr);


    }

    console.log(totalDr, "dr total");
    console.log(totalCr, "cr total");
  };



  const fetchData = () => {

    // getListLedgerAccount(
    //   (r) => {
    //     setAllLedgerAccount(r);
    //   },
    //   (err) => {
    //     dispatch({
    //       type: actionTypes.SET_OPEN_MSG,
    //       payload: { msg: err, msgType: "error" },
    //     });
    //   }
    // );

  };

  React.useEffect(() => {
    fetchData();
    setAllUnits(ledgerRowData);
  }, []);

  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddSearch((prv) => ({ ...prv, [name]: value }));
  };

  const onSelect = (info, v) => {
    console.log(info, "ifo");
    console.log(v, "ifov");

    setAddSearch({
      ...addSearch,
      [info.name]: v,
      ...(info.name === "ddl_ledger_group" ? { ddl_ledger_account: "" } : {}),
    });

    getLedgerAccount(
      v,
      (res) => {
        setOpeningBalance(res)
      },
      (err) => {

      })
  };

  balanceSheet();

  const onSearchLedgerMisPage = () => {
    // if (addSearch.ddl_ledger_account) {
    //   setLoading(true);

      getAllCashBook(

        
        (enquery) => {
          if (enquery.length) {
console.log(enquery,"sankh121222")

            setAllledgerList(enquery);
            setViewRes("visible");
            setLoading(false);
          } else {
            setViewRes("visible");
            setLoading(false);
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, "Receipt Payment not found": "info" },
            });
          }
        },
        (err) => {
          setAllledgerList([]);
          setViewRes("visible");
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
          setLoading(false);
        },
        addSearch
      );

    // } else {
    //   dispatch({
    //     type: actionTypes.SET_OPEN_MSG,
    //     payload: { msg: "Select Ledger Account", msgType: "info" },
    //   });
    // }

  };
  const classes = useStyles();

  const getVType = (voucherType) => {
    if (voucherType === 'R') {
      return 'Receipt';
    }
    else if (voucherType === 'P') {
      return 'Payment';
    }
    else return voucherType;
  };

  const getAmount = (row, r_dr_cr) => {

    console.log(row, "hkhkhk")
    ///console.log( r_dr_cr,"sen3004/amt")


    if ((row.particular && r_dr_cr.includes(row.dr_cr)) || row.bank_id === addSearch.ddl_ledger_account.value && !r_dr_cr.includes(row.ledVoucherType) ||
      row.bank_id !== addSearch.ddl_ledger_account.value && r_dr_cr.includes(row.ledVoucherType)
      // || row.ddl_ledger_id == addSearch.ddl_ledger.value && !r_dr_cr.includes(row.transaction_type)

    ) {
      return currencyFormate(row.amount);
    }

    return "";
  };
  const getAmountexcel = (row, r_dr_cr) => {

    console.log(row, "hkhkhk")
    ///console.log( r_dr_cr,"sen3004/amt")


    if ((row.particular && r_dr_cr.includes(row.dr_cr)) || row.bank_id === addSearch.ddl_ledger_account.value && !r_dr_cr.includes(row.ledVoucherType) ||
      row.bank_id !== addSearch.ddl_ledger_account.value && r_dr_cr.includes(row.ledVoucherType)
      // || row.ddl_ledger_id == addSearch.ddl_ledger.value && !r_dr_cr.includes(row.transaction_type)

    ) {
      return (row.amount).toFixed(2);
    }

    return "";
  };

  const OnCellClicked = (e, row) => {
    setBillNo(e.target.innerHTML)

    set_transaction_type(row.transaction_type)
    // console.log(BillNo, row.transaction_type, "cell clicked")

    if (row.transaction_type === "Sales") {
      setClassicModal(true);
    }
    else if (row.transaction_type === "Direct Purchase") {
      setClassicModal(true);
    }
    else if (row.transaction_type === "Purchase") {
      setClassicModal(true);
    }

  }

  const onCloseModel = () => {
    setClassicModal(false);
    // setBillNo('')
  };

  const onClickCollaps = () => {
    collapsible ?
      setCollapsible(false)
      :
      setCollapsible(true)
  }




  //pdf
  const onClickPdf = (e) => {
    e.preventDefault();

    let doc = new jsPDF("landscape", 'pt', 'A4');
    doc.html(document.getElementById('pdf-view'), {
      callback: () => {
        doc.save(`ledgerList ${currentDate()}.pdf`);
      }
    });




    // setClassicModal(true);
  };


  return (
    <ThemeProvider theme={theme}>
      <PageTitle title="Accounts > CashBook" />

      <GridContainer>
        <GridItem xs="12">
          <CustomCard cdTitle="Search Cash Book List"
            btnToolTip={collapsible ? "Collaps" : "Expand"}
            onClickCollapsible={onClickCollaps}
            buttonAction={collapsible}
            filterIcon>
            {
              collapsible ?
                <Paper elevation="0" className={classes.searchBar}>
                  <GridContainer justifyContent="flex-start" alignItems="center">
                    {/* <GridItem xs="6">
                  <InputLabel id="label">Group</InputLabel>
                  <ReactSelect
                    options={ledgerGroup}
                    name="ddl_ledger_group"
                    //getOptionLabel={(option) => option.label}
                    placeholder="Select"
                    formatGroupLabel={(d) => d.label}
                    menuPortalTarget={document.body}
                    className={classes.customSelect}
                    styles={reactSelectStyles}
                    onChange={(v, info) => onSelect(info, v)}
                    value={addSearch.ddl_ledger_group}
                  />
                </GridItem> */}

                    {/* <GridItem xs="8">
                      <InputLabel id="label">Ledger Account</InputLabel>
                      <ReactSelect
                        options={ledgerAccount}
                        getOptionLabel={(option) => option.label}
                        placeholder="Select"
                        formatGroupLabel={(d) => d.label}
                        menuPortalTarget={document.body}
                        className={classes.customSelect}
                        styles={reactSelectStyles}
                        name="ddl_ledger_account"
                        onChange={(v, info) => onSelect(info, v)}
                        value={addSearch.ddl_ledger_account}
                      />
                    </GridItem> */}

                    <GridItem xs="2">
                      <InputLabel id="label">Date Between</InputLabel>
                      <TextField
                        name="txt_from_date"
                        size="small"
                        id="date"
                        variant="outlined"
                        // style={{ marginBottom: -10 }}
                        type="date"
                        fullWidth={true}
                        value={addSearch.txt_from_date}
                        onChange={onAddSearch}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>

                    <GridItem xs="2">
                      {/* <InputLabel id="label">Date</InputLabel> */}
                      <TextField
                        name="txt_to_date"
                        size="small"
                        id="date"
                        variant="outlined"
                        // style={{ marginBottom: -20 }}
                        type="date"
                        fullWidth={true}
                        defaultValue={currentDate()}
                        onChange={onAddSearch}
                        value={addSearch.txt_to_date}
                        //onChange={onAddSearch}
                        className={classes.dateField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>

                    {console.log("opening balance", openingBalance)}

                    <GridItem xs="12">
                      <div
                        style={{
                          float: "right",
                          display: "flex",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <CustomButton
                          style={{ marginRight: "10px" }}
                          onClick={onSearchLedgerMisPage}
                        >
                          <SearchIcon />
                        </CustomButton>
                        <CustomButton>
                          <RotateLeftIcon />
                        </CustomButton>
                      </div>
                    </GridItem>
                  </GridContainer>
                </Paper>
                : ''}
          </CustomCard>
        </GridItem>
      </GridContainer>

     
      {/* {loading ? (
        <Box mt={10} width="100%" textAlign="center">
          <CircularProgress />
        </Box>
      ) : ( */}
        <GridContainer className={classes.root} 
        // style={{ "visibility": viewRes }}
        >
          <GridItem xs="12">
            <Card className={classes1.headerCard}>
              <CardHeader
                className={classes1.TbheaderCdhd}
                style={{ height: 60 }}
              >
                <GridContainer justifyContent="space-between" alignItems="center">
                  <GridItem>
                    <h4 className={classes1.headerCdTitle}>Cash Book</h4>
                  </GridItem>
                  <IconButton variant="text" onClick={() => onhandleExportToExcel(ledgerList)} >
                    <Tooltip title="Export to Excel">
                      <img src={require("../../../assets/img/excel.png").default} />
                    </Tooltip>
                  </IconButton>
                </GridContainer>
              </CardHeader>
              <CardBody
                style={{ height: "auto", maxHeight: 480, padding: 10 }}
                className={clxs(classes.customScroolBar)}
              >

             

                <TableContainer>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">#</StyledTableCell>
                        <StyledTableCell align="center">Date</StyledTableCell>
                        <StyledTableCell align="center">Received</StyledTableCell>
                        <StyledTableCell align="center">Credit</StyledTableCell>
                        <StyledTableCell align="center">Payment</StyledTableCell>
                        <StyledTableCell align="right">Debit</StyledTableCell>
                        
                        <StyledTableCell align="right">Balance</StyledTableCell>


                        <StyledTableCell align="right"></StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                        {/* <StyledTableCell align="right">Action</StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    {/* table Body start */}
                    <TableBody>
                    <StyledTableRow className={classes.openingBalances}>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell
                          className={classes.credit}
                        ></StyledTableCell>
                      
                        <StyledTableCell className={classes.voucher}>
                          <b>Opening Balance</b>
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.credit}
                        >

                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.credit}
                        ></StyledTableCell>
                        <StyledTableCell
                          className={classes.credit}
                        ></StyledTableCell>
                        
                        <StyledTableCell
                          className={classes.credit}
                          align="right"
                        >
                          {/* <b>{openingBalance ===0?'':openingBalance}</b> */}
                          <b>{opening_balance}</b>

                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                        >
                          {/* <b>{openingBalance.dr_cr_status === "Dr" ? currencyFormate(openingBalance.opening_balance) : ""}</b> */}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                       
                        </StyledTableCell>
                      </StyledTableRow>

                      {console.log(ledgerList, "ledgerList")}

                      {ledgerList.map((row, i) => (

                        <StyledTableRow key={i}>
                          <StyledTableCell align="left" className={classes.id}>
                            {row.length != 0 ? i + 1 : ""}
                          </StyledTableCell>

                          <StyledTableCell align="center" className={classes.date}>
                            {row.ledDate}
                          </StyledTableCell>

                          <StyledTableCell align="center" className={classes.voucher}>
                           {row.ledVoucherType === "R" ?  row.narraction:""}
                          </StyledTableCell>

                          <StyledTableCell align="center" className={classes.ledger}>
                           {row.ledVoucherType === "R" ?  row.amount:""}

                          
                          </StyledTableCell>

                          <StyledTableCell align="center" className={classes.particular}>
                          {row.ledVoucherType === "P" ? row.narraction:""}
                          </StyledTableCell>

                          <StyledTableCell
                            align="center" className={classes.ledger}

                            >
                          {row.ledVoucherType === "P" ? row.amount:""}
                        
                          </StyledTableCell>
                          
                          <StyledTableCell align="Right" className={classes.narraction}>
                          {
                                  row.ledVoucherType === "R" || row.ledVoucherType === "P"  ?
                                    (opening_balance = Number(opening_balance)
                                      + (row.ledVoucherType === "R" ? row.amount:"") - (row.ledVoucherType === "P" ? row.amount:""))
                                    :
                                    opening_balance = Number(row.amount) - Number(opening_balance)

                                }
                          </StyledTableCell>

                          <StyledTableCell align="right" className={classes.ledger}>
                     
                          </StyledTableCell>

                          <StyledTableCell align="right" className={classes.ledger}>
                          
                          </StyledTableCell>
                        </StyledTableRow>
                   ))} 
                      
                     
                    </TableBody>
                  </Table>
                </TableContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      {/* )} */}

      
    </ThemeProvider>
  );
};

export default CashBookPage;
