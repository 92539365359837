import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../../Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";

import { CustomCard } from "../../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../../Components/CustomButton";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  postSalesCumDispatch,
  getAllCustomers,
  getAllSalesCumDispatch,
  getLoadedVehicle,
  getSalesDispatchEdit,
  getSalesCumDispatchPrint,
} from "../../../../services/salesSumDispatchService";
import {
  updateVehicle,
  getAllVehicle,
  deleteVehicle,
  getVehicleById,
} from "../../../../services/vehicleService";
// import { getAllVehicleType } from "../../../services/vehicleTypeService";

// import { getAllModule } from "../../../services/modulesService";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Box, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../../context/context";
import { actionTypes } from "../../../../context/reducer";
import theme from "../../../../theme/theme";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";

import React, { useState } from "react";
import PageTitle from "../../HelperComponent/PageTitle";
import {
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory,useLocation } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import IconButton from "@material-ui/core/Button";
import { currentDate, currentDate1 } from "../../HelperComponent/utils";
import MasterModelForPrint from "../../../../views/Components/MasterModelForPrint";
import SalesDispatchPrint from "./salesDispatchPrint";
import ExcelJS from 'exceljs';



const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const salesCumDispatchPage = () => {
  const classes1 = useStyles1();
  const history = useHistory();
  const location = useLocation();

  const [vehicle, setAllVehicle] = React.useState([]);
  const [salesCumDispatch, setAllSalesCumDispatch] = React.useState([]);
  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [classicModal, setClassicModal] = React.useState(false);
  const [addSalesSumDispatch, setAddSalesSumDispatch] = React.useState({
    edit: false,
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
    ddl_loaded_vehicle_id: "",
    ddl_loaded_vehicle_label: "",
    ddl_customer_id: "",
    ddl_customer_label: "",
    ddl_qty_type_id: "",
    ddl_qty_type_label: "",
    txt_qty: 0,
    txt_rate: 0,
    txt_total: 0,
    check_gst: false,
    gst: 0,
    txt_date: currentDate(),
    transaction_no: "",
    switch_active_status: true,
    salesCumDispatch_no: "",
    loading_id: "",
  });
  const [error, setError] = React.useState({
    // ddl_loaded_vehicle: false,
    // ddl_customer: false,
    // ddl_qty_type: false,
    txt_rate: false,
  });
  const [collapsible, setCollapsible] = useState(true);
  const userRole = globalState?.user?.user_role;


  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddSalesSumDispatch((prv) => ({ ...prv, [name]: value }));
  };



  const viewData = [
   
  ];

  const headerData = [
    {
      id: "id",
      label: "#",
      align: "left",
    },
    {
      id: "sales_date",
      label: "Sales Date",
      align: "left",
    },
    {
      id: "order_date",
      label: "Order Date",
      align: "left",
    },

    {
      id: "challan_date",
      label: "Challan Date",
      align: "left",
    },
    {
      id: "transaction_no",
      label: "Transaction No.",
      align: "left",
    },
    {
      id: "sales_cum_loaded_vehicle",
      label: "Vehicle",
      align: "left",
    },
    {
      id: "sales_cum_customer_name",
      label: "Customer",
      align: "left",
    },
    {
      id: "salesman_name",
      label: "Salesman",
      align: "center",
    },
    {
      id: "sales_cum_rate",
      label: "Rate",
      align: "right",
    },
    {
      id: "sales_cum_quantity_type",
      label: "Q.T.",
      align: "left",
    },
    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];

  const classes = useStyles();

  React.useEffect(() => {
 
  }, [refresh]);

  const onClickCollaps = () => {
    console.log("Reached23");
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };

  const onSearchSales = (e) => {
    e.preventDefault();
    setLoading(false);

    getAllSalesCumDispatch(
      (salesCumDispatch) => {
        if (salesCumDispatch.length) {
          setAllSalesCumDispatch(salesCumDispatch);
          setLoading(false);
        } else {
          setLoading(false);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: "Sales/Dispatch not found", msgType: "error" },
          });
        }
      },
      (err) => {
        setAllSalesCumDispatch([]);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
        setLoading(false);
      },
      addSalesSumDispatch
    );
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddSalesSumDispatch({
      txt_keyword_pharse: "",
      txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
    });
  };


     //New Excel
     const onhandleExportToExcel = async () => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('data');
    
      const titleRow = worksheet.addRow(['Sales Cum Dispatch Report']);
      const periodRow = worksheet.addRow(['Period:']);
      worksheet.addRow([]);
    
      const titleCell = titleRow.getCell(1);
      const periodCell = periodRow.getCell(1);
      titleCell.alignment = { vertical: 'middle', horizontal: 'center', bold: true };
      periodCell.alignment = { vertical: 'middle', horizontal: 'center', bold: true };
    
      worksheet.mergeCells(`A${titleRow.number}:K${titleRow.number}`);
      worksheet.mergeCells(`A${periodRow.number}:K${periodRow.number}`);
    
      const headers = [
        "Sl No",  
        "Sales Date",
        "Order Date",
        "Challan Date",     
        "Transaction No",     
        "Vehicle No",
        "Customer",
        "Rate",
        "Quantity",
        "Salesman",
        "Remarks"
       
      ];
    
      // Add headers as a row
      worksheet.addRow(headers);
    
      const SalesCumDispatchTable = salesCumDispatch.map((acc) => {
        return {
          ID: acc.id,
          Sales_Date: acc.sales_date,
          Order_Date: acc.order_date,
          Challan_Date: acc.challan_date,
          Transaction_No: acc.transaction_no,
          Vehicle: acc.sales_cum_loaded_vehicle,
          Customer: acc.sales_cum_customer_name,
          Rate: acc.sales_cum_rate,
          Quantity: acc.sales_cum_quantity_type,
          salesman: acc.salesman_name,
          Remarks: acc.remarks,
        };
      });
    
      const dataStartRow = 4;
    
      const headerRow = worksheet.getRow(dataStartRow);
      headerRow.height = 20;
    
      headers.forEach((header, index) => {
        const column = worksheet.getColumn(index + 1);
        column.width = header.length + 5;
      });
    
      SalesCumDispatchTable.forEach((data) => {
        worksheet.addRow(Object.values(data));
      });
    
      headerRow.font = { bold: true };
      headerRow.eachCell((cell, colNumber) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '6dc8da' } };
      });
    
      worksheet.eachRow({ startingRow: dataStartRow + 1 }, (row, rowNumber) => {});
    
      worksheet.autoFilter = {
        from: {
          row: dataStartRow,
          column: 1,
        },
        to: {
          row: dataStartRow,
          column: headers.length,
        },
      };
      
    
      const fromDate = addSalesSumDispatch?.txt_from_date;
      const toDate = addSalesSumDispatch?.txt_to_date;
      const chosenPeriod = ` ${fromDate}  To  ${toDate}`;
      periodCell.value = `Period:  ${chosenPeriod}`;
    
      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });
      saveAs(data, 'SalesCumDispatchTable.xlsx');
    };

  // export to excel
  // const onhandleExportToExcel = () => {
  //   const SalesCumDispatchTable = salesCumDispatch.map((acc) => {
  //     return {
  //       ID: acc.id,
  //       Sales_Date: acc.sales_date,
  //       Order_Date: acc.order_date,
  //       Challan_Date: acc.challan_date,
  //       Transaction_No: acc.transaction_no,
  //       Vehicle: acc.sales_cum_loaded_vehicle,
  //       Customer: acc.sales_cum_customer_name,
  //       Rate: acc.sales_cum_rate,
  //       Quantity: acc.sales_cum_quantity_type,
  //       salesman: acc.salesman_name,
  //       Remarks: acc.remarks,
  //     };
  //   });

  //   const fileName = "SalesCumDispatch";
  //   const fileType =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //   const fileExtension = ".xlsx";
  //   const ws = XLSX.utils.json_to_sheet(SalesCumDispatchTable);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };

  const onClickViewSalesDispatch = (event) => {
    history.push({
      pathname: "/admin/tranportation/salesDispatchView",
      salse_dispatch_id: event.salse_dispatch_id,
    });
  };

  const onUpdateSales = (row) => {
    console.log(row, "sankha1007");
    history.push({
      pathname: "/admin/tranportation/sales-cum-dispatchFrom",
      salse_dispatch_id: row.salse_dispatch_id,
      edit: true,
    });
  };

  const onClickPrint = (row) => {
   
    
    // dispatch({
    //   type: actionTypes.SET_LOADING,
    //   payload: {},
    // });
    getSalesCumDispatchPrint(
      row.salse_dispatch_id,
      (details) => {
        setAllSalesCumDispatch(details);
       
        
       
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
     
        dispatch({
          type: actionTypes.SET_LOADING,
          payload: {},
        });
      }
    );
    setClassicModal(true);
  };


//   const onClickPrint = (e) => {
    

//     dispatch({
//       type: actionTypes.SET_LOADING,
//       payload: {},
//     });

//     getSalesCumDispatchPrint(
//       (salesCumDispatch) => {
// console.log(salesCumDispatch,"sankhanow")

       
     
          
//           setAllSalesCumDispatch(salesCumDispatch);
//           setLoading(false);
       
//       },
//       (err) => {
//         setAllSalesCumDispatch([]);
//         dispatch({
//           type: actionTypes.SET_OPEN_MSG,
//           payload: { msg: err, msgType: "error" },
//         });
//         setLoading(false);
//       },
//       addSalesSumDispatch,
//       salesCumDispatch.salse_dispatch_id,
//     );
//     dispatch({
//       type: actionTypes.SET_LOADING,
//       payload: {},
//     });

//     setClassicModal(true)
 
    

//   }


  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Transportation > Sales/Dispatch"
        btnToolTip="Add New Sales/Dispatch"
        addBtnLink="/admin/tranportation/sales-cum-dispatchFrom"
      />
      <>
        <GridContainer className={classes.root}>
          <GridItem xs="12">
            <CustomCard
              cdTitle="Search Sales/Dispatch"
              btnToolTip={collapsible ? "Collaps" : "Expand"}
              onClickCollapsible={onClickCollaps}
              buttonAction={collapsible}
              filterIcon
            >
              {collapsible ? (
                <Paper elevation="0" className={classes.searchBar}>
                  <GridContainer
                    justifyContent="flex-start"
                    alignItems="flex-end"
                  >
                    <GridItem xs="4">
                      <InputLabel id="label">Keyword / Phrase</InputLabel>
                      <TextField
                        size="small"
                        placeholder="Keyword / Phrase"
                        // style={{ marginBottom: -15 }}
                        type="search"
                        name="txt_keyword_pharse"
                        onChange={onAddSearch}
                        id="outlined-basic"
                        fullWidth={true}
                        value={addSalesSumDispatch.txt_keyword_pharse}
                        variant="outlined"
                      />
                    </GridItem>
                    <GridItem xs="2">
                      <InputLabel id="label">Date Between</InputLabel>
                      <TextField
                        size="small"
                        name="txt_from_date"
                        id="date"
                        variant="outlined"
                        type="date"
                        fullWidth={true}
                        value={addSalesSumDispatch.txt_from_date}
                        onChange={onAddSearch}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onClick={(e) => {
                          e.target.select();
                        }}
                      />
                    </GridItem>
                    <GridItem xs="2">
                      <TextField
                        name="txt_to_date"
                        size="small"
                        id="date"
                        variant="outlined"
                        type="date"
                        fullWidth={true}
                        value={addSalesSumDispatch.txt_to_date}
                        onChange={onAddSearch}
                        className={classes.dateField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs="4">
                      <div
                        style={{
                          float: "right",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CustomButton
                          name="btn_refres"
                          style={{ marginRight: "10px" }}
                          onClick={onSearchSales}
                        >
                          <SearchIcon />
                        </CustomButton>
                        <CustomButton
                          name="btn_refres"
                          onClick={onClickRefresh}
                        >
                          <RotateLeftIcon />
                        </CustomButton>
                      </div>
                    </GridItem>
                  </GridContainer>
                </Paper>
              ) : (
                ""
              )}
            </CustomCard>
          </GridItem>

          {salesCumDispatch.length > 0 ? (
            <GridItem xs="12">
              {loading ? (
                <Box mt={10} width="100%" textAlign="center">
                  <CircularProgress />
                </Box>
              ) : (
                <Card className={classes1.headerCard}>
                  <CardHeader
                    className={classes1.TbheaderCdhd}
                    style={{ height: 60 }}
                  >
                    <GridContainer
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <GridItem>
                        <h4 className={classes1.headerCdTitle}>
                          Sales/Dispatch
                        </h4>
                      </GridItem>
                      <GridItem style={{ cursor: "pointer" }}>
                        <IconButton
                          variant="text"
                          onClick={() =>
                            onhandleExportToExcel(salesCumDispatch)
                          }
                        >
                          <Tooltip title="Export to Excel">
                            <img
                              src={
                                require("../../../../assets/img/excel.png")
                                  .default
                              }
                            />
                          </Tooltip>
                        </IconButton>
                      </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody
                    style={{ height: "auto", maxHeight: 480, padding: 10 }}
                    className={clxs(classes.customScroolBar)}
                  >
                    <MuiTable
                      onClickEdit={onUpdateSales}
                      onClickViewOne={onClickViewSalesDispatch}
                      onClickPrintOne={onClickPrint}
                      columns={headerData}
                      rows={salesCumDispatch}
                      userRole={userRole}
                    />
                  </CardBody>
                </Card>
              )}
            </GridItem>
          ) : (
            ""
          )}
        </GridContainer>
      </>
      <MasterModelForPrint
        classicModal={classicModal}
        viewHeader="Sales & Dispatch"
        onCloseModel={(e) => {
          e.preventDefault();
          setClassicModal(false);
        }}
        height="auto"
        okBtnText="Print"
        onClickOk={(e) => {
          e.preventDefault();
          window.print()
        }}
      >
        {salesCumDispatch && (
        <SalesDispatchPrint
        salesCumDispatch={salesCumDispatch}
        />
        )} 
      </MasterModelForPrint>
    </ThemeProvider>
  );
};

export default salesCumDispatchPage;
