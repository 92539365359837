import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import MasterModelView from "../../Components/MasterModelView";


import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import VehicleViewPage from "./VehicleViewPage";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  updateVehicle,
  postVehicles,
  getAllVehicle,
  deleteVehicle,
  getAllVehicleTypeId,
  getAllVehicleBrandId,
  getAllTyreBrand,
  getAllVehicleView
} from "../../../services/vehicleService";
import { getAllVehicleType } from "../../../services/vehicleTypeService";
import { Grid } from "@material-ui/core";

import { getAllModule } from "../../../services/modulesService";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Box, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import theme from "../../../theme/theme";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";

import React, { useState } from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory,useLocation } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import IconButton from "@material-ui/core/Button";
import { currentDate } from "../HelperComponent/utils";
import VehicleAllViewPage from "./VehicleAllViewPage";

const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const VehiclePage = () => {
  const classes1 = useStyles1();
  const history = useHistory();
  const [classicModal, setClassicModal] = React.useState(false);
  const [vehicle, setAllVehicle] = React.useState([]);
  const [allType, setAllType] = React.useState([]);
  const [allBrand, setAllBrand] = React.useState([]);

  const [flName, setFlName] = React.useState("");
  const [items, setItems] = React.useState([]);
  const [addedItems, setAddedItems] = React.useState([]);
  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [addVehicle, setAddVehicle] = React.useState({
    edit: false,
    vehicle_id: "",
    txt_keyword_pharse: "",
    txt_vehicle: "",
    txt_vehicle_specification: "",
    txt_contact_no: "",
    ddl_vehicle_type_id: "",
    ddl_vehicle_type_label: "",
    ddl_tyre_brand_id: "",
    ddl_tyre_brand_label: "",
    ddl_vehicle_brand_id: "",
    ddl_vehicle_brand_label: "",
    ddl_no_of_wheels_id: "",
    ddl_no_of_wheels_label: "",
    txt_type: "",
    txt_engine_number:"",
    txt_chassis_number:"",
    txt_purchase_date: currentDate(),
    txt_insurance_file: "",
    txt_insurance_date: currentDate(),
    txt_pollution_file: "",
    txt_pollution_date: currentDate(),
    txt_permit_file: "",
    txt_permit_date: currentDate(),
    txt_tax_file: "",
    txt_tax_date: currentDate(),
    txt_registration_file: "",
    txt_registration_date: currentDate(),
    txt_bluebook_file: "",
    txt_bluebook_date: currentDate(),
    txt_fitness_date: currentDate(),
    txt_loan_file:"",
    txt_loan_date: currentDate(),
    txt_bank_noc_file:"",
    txt_bank_noc_date: currentDate(),

    switch_active_status: true,
  });
  const [error, setError] = React.useState({
    ddl_vehicle_type: false,
    ddl_tyre_brand: false,
    ddl_vehicle_brand: false,
    txt_vehicle: false,
  });
  const [checkedOwn, setCheckedOwn] = React.useState(false)
  const [checkedRented, setCheckedRented] = React.useState(false)
  const fontStyle = {
    fontFamily: "Sans-serif",
  };
  const [viewModel, setViewModel] = useState(false);
  const userRole = globalState?.user?.user_role;

  React.useEffect(() => {
    setLoading(true);

    getAllVehicle(
      (vehicle) => {
        setAllVehicle(vehicle);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );

    // getAllTyreBrand(
    //   (tyre) => {
    //     setAllTyreBrand(tyre);
    //     setLoading(false);
    //   },
    //   (err) => {
    //     setLoading(false);
    //     dispatch({
    //       type: actionTypes.SET_OPEN_MSG,
    //       payload: { msg: err, msgType: "error" },
    //     });
    //   }
    // );

    console.log(allType, "123type");
    getAllVehicleTypeId(
      (allType) => {
        setAllType(allType);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
    getAllVehicleBrandId(
      (allBrand) => {
        setAllBrand(allBrand);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [refresh]);

  //options of no of wheels
  const vehicleWheels = [
    { value: 6, label: "6" },
    { value: 10, label: "10" },
    { value: 12, label: "12" },
    { value: 14, label: "14" },
    { value: 16, label: "16" },
  ];

  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddVehicle((prv) => ({ ...prv, [name]: value }));
  };
  const viewData1 = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "attachment_name",
      label: "Attachment No",
    },
    {
      id: "expiry_date",
      label: "Expiry Date",
    },

  ];

  const viewData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "vehicle_no",
      label: "Vehicle No",
    },
    {
      id: "vehicle_ownership",
      label: "Ownership",
    },
    {
      id: "vehicle_type",
      label: "Type",
    },
    {
      id: "vehicle_brand",
      label: "Brand",
    },


    {
      id: "vehicle_specification",
      label: "Specification",
    },
    {
      id: "no_of_wheels",
      label: "Wheels",
    },
    // {
    //   id: "vehicle_purchase_date",
    //   label: "Vehicle Date",
    // },

    {
      id: "status",
      label: "Status",
      statusBtn: (v) => (v === "Y" ? true : false),
    },

  ];
  const formData = {
    formName: "Add a Vehicle",
    fields: [
      {
        name: "ddl_vehicle_type",
        value: addVehicle,
        label: "Vehicle Type",
        placeholder: "Select Vehicle",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "select",
        error: false,
        options: allType,
        xs: 3,
        // md: 4,
        // lg: 4,
      },
      {
        name: "ddl_vehicle_brand",
        value: addVehicle,
        label: "Vehicle Brand",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "select",
        error: false,
        options: allBrand,
        xs: 3,
        // md: 4,
        // lg: 4,
      },
      {
        name: "txt_vehicle",
        label: "Vehicle No",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 3,
        // md: 4,
        // lg: 4,
      },
      {
        name: "ddl_no_of_wheels",
        label: "No. of Wheels",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "select",
        error: false,
        value: addVehicle,
        options: vehicleWheels,
        xs: 3,
        // md: 3,
        // lg: 3,
      },
      {
        name: "txt_km_start",
        label: "KM Start",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 3,
        // md: 5,
        // lg: 5,
      },
      {
        name: "txt_mileage",
        label: "Mileage",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 3,
        // md: 5,
        // lg: 5,
      },
      {
        name: "txt_purchase_date",
        label: "Purchase Date",
        hidden: false,
        // required: true,
        data_type: "Date",
        html_element: "Date1",
        error: false,
        xs: 3,
        // md: 4,
        // lg: 4,
      },
      {
        name: "txt_insurance_company",
        label: "Insurance Company",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        error: false,
        disabled:checkedRented? true:false,
        xs: 3,
        // md: 5,
        // lg: 5,
      },
      {
        name: "txt_engine_number ",
        label: "Engine Number ",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        error: false,
       
        xs: 6,
        // md: 5,
        // lg: 5,
      },
      {
        name: "txt_chassis_number",
        label: "Chassis Number",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        error: false,
      
        xs: 6,
        // md: 5,
        // lg: 5,
      },
      {
        name: "txt_type",
        // label: "Vehicle Type",
        hidden: false,
        // required: true,
        data_type: "string",
        html_element: "radio_button",
        error: false,
        xs: 12,
        md: 12,
        lg: 12,
      },
      {
        name: "txt_insurance_number",
        label: "Insurance No.",
        hidden: false,
        // required: true,
        data_type: "number",
        html_element: "Number",
        disabled:checkedRented? true:false,
        error: false,
        xs: 3,
      },
      {
        name: "txt_insurance_file",
        // label: "Vehicle Type",
        hidden: false,
        // required: true,
        data_type: "string",
        html_element: "button1",
        error: false,
        disabled:checkedRented? true:false,
        xs: 6,
        md: 6,
        lg: 6,
      },

      {
        name: "txt_insurance_date",
        label: "Expiry Date",
        hidden: false,
        // required: true,
        // marginTop:15,
        data_type: "Date",
        html_element: "Date",
        error: false,
        disabled:checkedRented? true:false,
        // marginBottom: 12,
        xs: 3,
        md: 3,
        lg: 3,
      },
      {
        name: "txt_pollution_number",
        label: "Pollution No.",
        hidden: false,
        // required: true,
        data_type: "number",
        html_element: "Number",
        error: false,
        disabled:checkedRented? true:false,
        xs: 3,
        md: 3,
        lg: 3,
      },
      {
        name: "txt_pollution_file",
        // label: "Vehicle Type",
        hidden: false,
        // required: true,
        data_type: "string",
        html_element: "button2",
        error: false,
        disabled:checkedRented? true:false,
        xs: 6,
        md: 6,
        lg: 6,
      },
      {
        name: "txt_pollution_date",
        label: "Expiry Date",
        hidden: false,
        // required: true,
        data_type: "Date",
        html_element: "Date",
        error: false,
        disabled:checkedRented? true:false,
        // marginBottom: 12,
        xs: 3,
        md: 3,
        lg: 3,
      },
      {
        name: "txt_permit_number",
        label: "Permit No.",
        hidden: false,
        // required: true,
        data_type: "number",
        html_element: "Number",
        error: false,
        disabled:checkedRented? true:false,
        xs: 3,
        md: 3,
        lg: 3,
      },
      {
        name: "txt_permit_file",
        // label: "Vehicle Type",
        hidden: false,
        // required: true,
        data_type: "string",
        html_element: "button3",
        error: false,
        disabled:checkedRented? true:false,
        xs: 6,
        md: 6,
        lg: 6,
      },
      {
        name: "txt_permit_date",
        label: "Expiry Date",
        hidden: false,
        // required: true,
        data_type: "Date",
        html_element: "Date",
        error: false,
        disabled:checkedRented? true:false,
        // marginBottom: 12,
        xs: 3,
        md: 3,
        lg: 3,
      },
      {
        name: "txt_tax_number",
        label: "Tax No.(GRIPS No.)",
        hidden: false,
        // required: true,
        data_type: "number",
        html_element: "Number",
        error: false,
        disabled:checkedRented? true:false,
        xs: 3,
      },
      {
        name: "txt_tax_file",
        // label: "Vehicle Type",
        hidden: false,
        // required: true,
        data_type: "string",
        html_element: "button4",
        error: false,
        disabled:checkedRented? true:false,
        xs: 6,
        md: 6,
        lg: 6,
      },
      {
        name: "txt_tax_date",
        label: "Expiry Date",
        hidden: false,
        // required: true,
        data_type: "Date",
        html_element: "Date",
        error: false,
        marginBottom: 12,
        disabled:checkedRented? true:false,
        xs: 3,
        md: 3,
        lg: 3,
      },
      {
        name: "txt_bluebook_number",
        label: "Bluebook No.",
        hidden: false,
        // required: true,
        data_type: "number",
        html_element: "Number",
        error: false,
        disabled: true,
        xs: 3,
      },
      {
        name: "txt_bluebook_file",
        // label: "Vehicle Type",
        hidden: false,
        // required: true,
        data_type: "string",
        html_element: "button6",
        error: false,
        disabled:checkedRented? true:false,
        xs: 6,
        md: 6,
        lg: 6,
      },
      {
        name: "txt_bluebook_date",
        label: "Expiry Date",
        hidden: false,
        // required: true,
        data_type: "Date",
        html_element: "Date",
        error: false,
        disabled: true,
        xs: 3,
        md: 3,
        lg: 3,
      },
      {
        name: "txt_fitness_number",
        label: "Fitness No.",
        hidden: false,
        // required: true,
        data_type: "number",
        html_element: "Number",
        disabled:checkedRented? true:false,
        error: false,
        xs: 3,
      },
      {
        name: "txt_fitness_file",
        // label: "Vehicle Type",
        hidden: false,
        // required: true,
        data_type: "string",
        html_element: "button7",
        disabled:checkedRented? true:false,
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
      },
      {
        name: "txt_fitness_date",
        label: "Expiry Date",
        hidden: false,
        // required: true,
        data_type: "Date",
        html_element: "Date",
        disabled:checkedRented? true:false,
        error: false,
        xs: 3,
        md: 3,
        lg: 3,
      },
      {
        name: "txt_loan_letter",
        label: "Loan Letter No.",
        hidden: false,
        // required: true,
        data_type: "number",
        html_element: "Number",
        disabled:checkedRented? true:false,
        error: false,
        xs: 3,
      },
      {
        name: "txt_loan_file",
        // label: "Vehicle Type",
        hidden: false,
        // required: true,
        data_type: "string",
        html_element: "button8",
        disabled:checkedRented? true:false,
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
      },
      {
        name: "txt_loan_date",
        label: "Expiry Date",
        hidden: false,
        // required: true,
        data_type: "Date",
        html_element: "Date",
        disabled:checkedRented? true:false,
        error: false,
        xs: 3,
        md: 3,
        lg: 3,
      },
      {
        name: "txt_bank_noc",
        label: "Bank NOC No.",
        hidden: false,
        // required: true,
        data_type: "number",
        html_element: "Number",
        disabled:checkedRented? true:false,
        error: false,
        xs: 3,
      },
      {
        name: "txt_bank_noc_file",
        // label: "Vehicle Type",
        hidden: false,
        // required: true,
        data_type: "string",
        html_element: "button9",
        disabled:checkedRented? true:false,
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
      },
      {
        name: "txt_bank_noc_date",
        label: "Expiry Date",
        hidden: false,
        // required: true,
        data_type: "Date",
        html_element: "Date",
        disabled:checkedRented? true:false,
        error: false,
        xs: 3,
        md: 3,
        lg: 3,
      },
      
      {
        name: "switch_active_status",
        label: "Active Status",
        defaultValue: false,
        required: false,
        data_type: "string",
        html_element: "switch",
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
      },
    ],
  };

  const onSetActive = (e) => {
    setAddVehicle((prv) => ({ ...prv, [e.target.name]: e.target.checked }));
  };

  const headerData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "vehicle_ownership",
      label: "Ownership",
    },
    {
      id: "vehicle_type",
      label: "Type",
    },
    {
      id: "vehicle_brand",
      label: "Brand",
    },

    {
      id: "vehicle_no",
      label: "Vehicle No",
    },
    {
      id: "ownerName",
      label: "Owner Name",
    },
    {
      id: "no_of_wheels",
      label: "Wheels",
      align: "Center"
    },
    {
      id: "insurance_expire_date",
      label: "Insurance ",
      align: "Center"
    },
    {
      id: "permit_expiry_date",
      label: "Permit",
      align: "Center"
    },
    {
      id: "pollution_expiry_date",
      label: "Pollution",
      align: "Center"
    },
    {
      id: "tax_expiry_date",
      label: "Tax",
      align: "Center"
    },
    {
      id: "fitness_expiry_date",
      label: "Fitness",
      align: "Center"
    },
    {
      id: "gps_date",
      label: "Gps",
      align: "Center"
    },

    {
      id: "status",
      label: "Status",
      statusBtn: (v) => (v === "Y" ? true : false),
    },
    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];

  const onSubmitModel = (e) => {
    e.preventDefault();
    console.log(addVehicle,"sen1010")
    if (!addVehicle.txt_vehicle) {
      setError({
        ddl_tyre_brand: !addVehicle.ddl_tyre_brand,
        ddl_vehicle_type: !addVehicle.ddl_vehicle_type,
        ddl_vehicle_brand: !addVehicle.ddl_vehicle_brand,
        txt_vehicle: !addVehicle.txt_vehicle,
      });
    } else {
      if (addVehicle.edit) {
        updateVehicle(
          addVehicle,
          (r) => {
            setClassicModal(false);
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Vehicle Updated Successfully",
                msgType: "success",
              },
            });
            setRefresh(!refresh);
            setAddVehicle({
              edit: false,
              vehicle_id: "",
              txt_keyword_pharse: "",
              txt_vehicle: "",
              txt_details: "",
              txt_vehicle_specification: "",
              txt_contact_no: "",
              ddl_vehicle_type_id: "",
              ddl_vehicle_type_label: "",
              ddl_tyre_brand_id: "",
              ddl_tyre_brand_label: "",
              ddl_vehicle_brand_id: "",
              ddl_vehicle_brand_label: "",
              ddl_no_of_wheels_id: "",
              ddl_no_of_wheels_label: "",
              vehicle_ownership: "",
              txt_type: "",
              txt_engine_number:"",
              txt_chassis_number:"",
              txt_purchase_date: currentDate(),
              txt_insurance_file: "",
              txt_insurance_date: currentDate(),
              txt_pollution_file: "",
              txt_pollution_date: currentDate(),
              txt_permit_file: "",
              txt_permit_date: currentDate(),
              txt_tax_file: "",
              txt_tax_date: currentDate(),
              txt_registration_file: "",
              txt_registration_date: currentDate(),
              txt_bluebook_file: "",
              txt_bluebook_date: currentDate(),
              txt_fitness_date: currentDate(),
              txt_loan_file:"",
              txt_loan_date: currentDate(),
              txt_bank_noc_file:"",
              txt_bank_noc_date: currentDate(),
              switch_active_status: true,
            });
            setCheckedRented(false)
            setCheckedOwn(false)
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
      } else {
        postVehicles(
          addVehicle,
          globalState?.user?.serial_id,
          (r) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Vehicle added Successfully",
                msgType: "success",
              },
            });
            setClassicModal();
            setRefresh(!refresh);
            setAddVehicle({
              edit: false,
              vehicle_id: "",
              txt_keyword_pharse: "",
              txt_vehicle: "",
              txt_details: "",
              txt_vehicle_specification: "",
              txt_contact_no: "",
              ddl_vehicle_type_id: "",
              ddl_vehicle_type_label: "",
              ddl_tyre_brand_id: "",
              ddl_tyre_brand_label: "",
              ddl_vehicle_brand_id: "",
              ddl_vehicle_brand_label: "",
              ddl_no_of_wheels_id: "",
              ddl_no_of_wheels_label: "",
              vehicle_ownership: "",
              txt_engine_number:"",
              txt_chassis_number:"",
              txt_type: "",
              txt_purchase_date: currentDate(),
              txt_insurance_file: "",
              txt_insurance_date: currentDate(),
              txt_pollution_file: "",
              txt_pollution_date: currentDate(),
              txt_permit_file: "",
              txt_permit_date: currentDate(),
              txt_tax_file: "",
              txt_tax_date: currentDate(),
              txt_registration_file: "",
              txt_registration_date: currentDate(),
              txt_bluebook_file: "",
              txt_bluebook_date: currentDate(),
              txt_fitness_date: currentDate(),
              txt_loan_file:"",
              txt_loan_date: currentDate(),
              txt_bank_noc_file:"",
              txt_bank_noc_date: currentDate(),
              switch_active_status: true,
            });
            setCheckedRented(false)
            setCheckedOwn(false)
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setButtonDisabled(true);
          }
        );
      }
    }
  };
  // on Delete called
  const onDeleteVehicle = (row, next) => {
    deleteVehicle(
      row.vehicle_id,
      (r) => {
        next();
        setRefresh(!refresh);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const onViewVehicle = (e) => {
    setViewModel(true)

    getAllVehicleView(
      e.vehicle_id,
      e.vehicle_no,
      (r) => {
        console.log(r, "sank3008")

        setAddedItems(r);


        setLoading(false);
      },

    );
  };

  // on Edit called
  const onUpdateVehicle = (row) => {
    history.push({
      pathname:"/admin/master/vehicleForm",
      vehicle_id:row.vehicle_id,
      edit:true
  })
    // console.log(row.vehicle_ownership, "sen2609")
    // if (row.vehicle_ownership == "Own") {
    //   setCheckedOwn(true)
    // } else if (row.vehicle_ownership === "Rented") {
    //   setCheckedRented(true)
    // }
    // setClassicModal(true);
    // setAddVehicle({
    //   ...addVehicle,
    //   edit: true,
    //   switch_active_status: row.status === "Y" ? true : false,
    //   vehicle_id: row.vehicle_id,
    //   txt_vehicle_specification: row.vehicle_specification,
    //   txt_vehicle: row.vehicle_no,
    //   txt_details: row.details,
    //   ddl_vehicle_type_label: row.vehicle_type,
    //   ddl_tyre_brand_label: row.tyre_brand,
    //   ddl_vehicle_brand_label: row.vehicle_brand,
    //   ddl_no_of_wheels_label: row.no_of_wheels,
    //   txt_type: row.vehicle_ownership,
    //   txt_purchase_date: row.vehicle_purchase_date,

    // });
  };

  const onSelectModule = (event, value) => {
    console.log(event, "sen19", value.name);

    switch (value.name) {
      case "ddl_vehicle_type":
        console.log("reached type");
        setAddVehicle({
          ...addVehicle,
          ddl_vehicle_type_id: event.value,
          ddl_vehicle_type_label: event.label,
        });
        break;

      case "ddl_tyre_brand":
        setAddVehicle({
          ...addVehicle,
          ddl_tyre_brand_id: event.value,
          ddl_tyre_brand_label: event.label,
        });
        break;

      case "ddl_vehicle_brand":
        setAddVehicle({
          ...addVehicle,
          ddl_vehicle_brand_id: event.value,
          ddl_vehicle_brand_label: event.label,
        });
        break;

      case "ddl_no_of_wheels":
        setAddVehicle({
          ...addVehicle,
          ddl_no_of_wheels_id: event.value,
          ddl_no_of_wheels_label: event.label,
        });
    }
  };

  const insurance_excel = (file) => {
    var fileName = "";

    if (document.getElementById("real-file").value) {
      setAddVehicle({
        ...addVehicle,
        txt_insurance_file: file.name,
      });
      var filevalue = document.getElementById("real-file").value;
      fileName = filevalue.split("\\");
      document.getElementById("insurance-file-name").innerHTML =
        fileName[fileName.length - 1];
    } else {
      document.getElementById("insurance-file-name").innerHTML =
        "No file chosen";
    }
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve([fileName, data]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      const [fileName, d] = data;
      console.log("fln", d);
      console.table(d.slice(0, 10));
      setFlName(fileName[fileName.length - 1].split(".")[0]);
      setItems(d);
    });
  };
  const pollution_excel = (file) => {
    var fileName = "";

    if (document.getElementById("pollution-file").value) {
      setAddVehicle({
        ...addVehicle,
        txt_pollution_file: file.name,
      });
      var filevalue = document.getElementById("pollution-file").value;
      fileName = filevalue.split("\\");
      document.getElementById("pollution-file-name").innerHTML =
        fileName[fileName.length - 1];
    } else {
      document.getElementById("pollution-file-name").innerHTML =
        "No file chosen";
    }
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve([fileName, data]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      const [fileName, d] = data;
      console.log("fln", d);
      console.table(d.slice(0, 10));
      setFlName(fileName[fileName.length - 1].split(".")[0]);
      setItems(d);
    });
  };
  const permit_excel = (file) => {
    var fileName = "";

    if (document.getElementById("permit-file").value) {
      setAddVehicle({
        ...addVehicle,
        txt_permit_file: file.name,
      });
      var filevalue = document.getElementById("permit-file").value;
      fileName = filevalue.split("\\");
      document.getElementById("permit-file-name").innerHTML =
        fileName[fileName.length - 1];
    } else {
      document.getElementById("permit-file-name").innerHTML = "No file chosen";
    }
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve([fileName, data]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      const [fileName, d] = data;
      console.log("fln", d);
      console.table(d.slice(0, 10));
      setFlName(fileName[fileName.length - 1].split(".")[0]);
      setItems(d);
    });
  };

  const tax_excel = (file) => {
    var fileName = "";

    if (document.getElementById("tax-file").value) {
      setAddVehicle({
        ...addVehicle,
        txt_tax_file: file.name,
      });
      var filevalue = document.getElementById("tax-file").value;
      fileName = filevalue.split("\\");
      document.getElementById("tax-file-name").innerHTML =
        fileName[fileName.length - 1];
    } else {
      document.getElementById("tax-file-name").innerHTML = "No file chosen";
    }
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve([fileName, data]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      const [fileName, d] = data;
      console.log("fln", d);
      console.table(d.slice(0, 10));
      setFlName(fileName[fileName.length - 1].split(".")[0]);
      setItems(d);
    });
  };

  const registration_excel = (file) => {
    var fileName = "";

    if (document.getElementById("registration-file").value) {
      setAddVehicle({
        ...addVehicle,
        txt_registration_file: file.name,
      });
      var filevalue = document.getElementById("registration-file").value;
      fileName = filevalue.split("\\");
      document.getElementById("registration-file-name").innerHTML =
        fileName[fileName.length - 1];
    } else {
      document.getElementById("registration-file-name").innerHTML =
        "No file chosen";
    }
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve([fileName, data]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      const [fileName, d] = data;
      console.log("fln", d);
      console.table(d.slice(0, 10));
      setFlName(fileName[fileName.length - 1].split(".")[0]);
      setItems(d);
    });
  };

  const bluebook_excel = (file) => {
    var fileName = "";

    if (document.getElementById("bluebook-file").value) {
      setAddVehicle({
        ...addVehicle,
        txt_bluebook_file: file.name,
      });
      var filevalue = document.getElementById("bluebook-file").value;
      fileName = filevalue.split("\\");
      document.getElementById("bluebook-file-name").innerHTML =
        fileName[fileName.length - 1];
    } else {
      document.getElementById("bluebook-file-name").innerHTML =
        "No file chosen";
    }
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve([fileName, data]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      const [fileName, d] = data;
      console.log("fln", d);
      console.table(d.slice(0, 10));
      setFlName(fileName[fileName.length - 1].split(".")[0]);
      setItems(d);
    });
  };

  const fitness_excel = (file) => {
    var fileName = "";

    if (document.getElementById("fitness-file").value) {
      setAddVehicle({
        ...addVehicle,
        txt_bluebook_file: file.name,
      });
      var filevalue = document.getElementById("fitness-file").value;
      fileName = filevalue.split("\\");
      document.getElementById("fitness-file-name").innerHTML =
        fileName[fileName.length - 1];
    } else {
      document.getElementById("fitness-file-name").innerHTML =
        "No file chosen";
    }
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve([fileName, data]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      const [fileName, d] = data;
      console.log("fln", d);
      console.table(d.slice(0, 10));
      setFlName(fileName[fileName.length - 1].split(".")[0]);
      setItems(d);
    });
  };

  const bank_noc_excel = (file) => {
    var fileName = "";

    if (document.getElementById("banknoc-file").value) {
      setAddVehicle({
        ...addVehicle,
        txt_bluebook_file: file.name,
      });
      var filevalue = document.getElementById("banknoc-file").value;
      fileName = filevalue.split("\\");
      document.getElementById("banknoc-file-name").innerHTML =
        fileName[fileName.length - 1];
    } else {
      document.getElementById("banknoc-file-name").innerHTML =
        "No file chosen";
    }
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve([fileName, data]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      const [fileName, d] = data;
      console.log("fln", d);
      console.table(d.slice(0, 10));
      setFlName(fileName[fileName.length - 1].split(".")[0]);
      setItems(d);
    });
  };

  const loan_excel = (file) => {
    var fileName = "";

    if (document.getElementById("loan-file").value) {
      setAddVehicle({
        ...addVehicle,
        txt_bluebook_file: file.name,
      });
      var filevalue = document.getElementById("loan-file").value;
      fileName = filevalue.split("\\");
      document.getElementById("loan-file-name").innerHTML =
        fileName[fileName.length - 1];
    } else {
      document.getElementById("loan-file-name").innerHTML =
        "No file chosen";
    }
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve([fileName, data]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      const [fileName, d] = data;
      console.log("fln", d);
      console.table(d.slice(0, 10));
      setFlName(fileName[fileName.length - 1].split(".")[0]);
      setItems(d);
    });
  };
  const onChange = (e) => {
    const { value, name } = e.target;

    console.log(e.target.checked, "sen23", name, value);

    if (name === "vehicle_ownership" && value === "Own") {
      setCheckedOwn(true)
      setCheckedRented(false)
    } else if (name === "vehicle_ownership" && value === "Rented") {
      setCheckedRented(true)
      setCheckedOwn(false)

    }
    setAddVehicle({ ...addVehicle, [name]: value });
  };

  const classes = useStyles();

  // on Close Model
  const onCloseModel = () => {
    setViewModel(false)
    setClassicModal(false);
    setError({ txt_vehicle: false });
    setAddVehicle({
      edit: false,
      vehicle_id: "",
      txt_keyword_pharse: "",
      txt_vehicle: "",
      txt_details: "",
      txt_vehicle_specification: "",
      txt_contact_no: "",
      ddl_vehicle_type_id: "",
      ddl_vehicle_type_label: "",
      ddl_tyre_brand_id: "",
      ddl_tyre_brand_label: "",
      ddl_vehicle_brand_id: "",
      ddl_vehicle_brand_label: "",
      ddl_no_of_wheels_id: "",
      ddl_no_of_wheels_label: "",
      vehicle_ownership: "",
      txt_type: "",
      txt_purchase_date: currentDate(),
      txt_insurance_file: "",
      txt_insurance_date: currentDate(),
      txt_pollution_file: "",
      txt_pollution_date: currentDate(),
      txt_permit_file: "",
      txt_permit_date: currentDate(),
      txt_tax_file: "",
      txt_tax_date: currentDate(),
      txt_registration_file: "",
      txt_registration_date: currentDate(),
      txt_bluebook_file: "",
      txt_bluebook_date: currentDate(),
      switch_active_status: true,
    });
    setCheckedRented(false)
    setCheckedOwn(false)
  };
  const onSearchVehicle = (e) => {
    e.preventDefault();
    setLoading(true);
    if (addVehicle.txt_keyword_pharse) {
      getAllVehicle(
        (vehicle) => {
          if (vehicle.length) {
            setAllVehicle(vehicle);
            setLoading(false);
          } else {
            setLoading(false);
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: "Vehicle not found", msgType: "error" },
            });
          }
        },
        (err) => {
          // change by sankha
          setAllVehicle([]);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
          setLoading(false);
        },
        addVehicle
      );
    } else {
      getAllVehicle(
        (vehicle) => {
          if (vehicle.length) {
            setAllVehicle(vehicle);
            setLoading(false);
          } else {
            setLoading(false);
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: "Vehicle not found", msgType: "error" },
            });
          }
        },
        (err) => {
          // change by sankha
          setAllVehicle([]);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
          setLoading(false);
        },
        // addVehicle
      );
    }
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddVehicle({
      txt_keyword_pharse: "",
      ddl_group_id: "",
    });
  };

  // export to excel

  const onhandleExportToExcel = () => {
    const VehicleTable = vehicle.map((acc) => {
      return {
        Sl_No: acc.id,
        Vehicle_type: acc.vehicle_type,
        Vehicle_no: acc.vehicle_no,
        Ownership:acc.vehicle_ownership,
        Owner_Name: acc.ownerName,
        Wheels: acc.no_of_wheels,
        Insurance: acc.insurance_expire_date,
        Permit: acc.permit_expiry_date,
        Pollution:acc.pollution_expiry_date,
        Tax:acc.tax_expiry_date,
        Fitness:acc.fitness_expiry_date,
        Gps:acc.gps_date,
        

      };
    });

    const fileName = "Vehicle ";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(VehicleTable);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Master > Vehicle"
        btnToolTip="Add New Vehicle"
        // onClickAddBtn={() => setClassicModal(true)}
        addBtnLink="/admin/master/vehicleForm"
      />
      <>
        <GridContainer className={classes.root}>
          <GridItem xs="12">
            <CustomCard cdTitle="Search Vehicle">
              <Paper elevation="0" className={classes.searchBar}>
                <GridContainer
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <GridItem xs="6">
                    <InputLabel id="label">Keyword / Phrase</InputLabel>
                    <TextField
                      size="small"
                      placeholder="Keyword / Phrase"
                      style={{ marginBottom: -15 }}
                      type="search"
                      name="txt_keyword_pharse"
                      onChange={onAddSearch}
                      id="outlined-basic"
                      fullWidth={true}
                      value={addVehicle.txt_keyword_pharse}
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs="6">
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomButton
                        name="btn_refres"
                        style={{ marginRight: "10px" }}
                        onClick={onSearchVehicle}
                      >
                        <SearchIcon />
                      </CustomButton>
                      <CustomButton name="btn_refres" onClick={onClickRefresh}>
                        <RotateLeftIcon />
                      </CustomButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </Paper>
            </CustomCard>
          </GridItem>



          <GridItem xs="12">
            {loading ? (
              <Box mt={10} width="100%" textAlign="center">
                <CircularProgress />
              </Box>
            ) : (
              <Card className={classes1.headerCard}>
                <CardHeader
                  className={classes1.TbheaderCdhd}
                  style={{ height: 60 }}
                >
                  <GridContainer
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <GridItem>
                      <h4 className={classes1.headerCdTitle}>Vehicles</h4>
                    </GridItem>
                    <GridItem style={{ cursor: "pointer" }}>
                      <IconButton
                        variant="text"
                        onClick={() => onhandleExportToExcel(vehicle)}
                      >
                        <Tooltip title="Export to Excel">
                          <img
                            src={
                              require("../../../assets/img/excel.png").default
                            }
                          />
                        </Tooltip>
                      </IconButton>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody
                  style={{ height: "auto", maxHeight: 480, padding: 10 }}
                  className={clxs(classes.customScroolBar)}
                >
                  <MuiTable
                    onClickViewOne={onViewVehicle}
                    onClickEdit={onUpdateVehicle}
                    onClickDelete={onDeleteVehicle}
                    columns={headerData}
                    rows={vehicle}
                    userRole={userRole}

                  />
                </CardBody>
              </Card>
            )}
          </GridItem>
        </GridContainer>
      </>
      <MasterModelView
        classicModal={viewModel}
        viewHeader="View Vehicle"
        // onCloseModel={onCloseModel}
        onCloseModel={(e) => {
          e.preventDefault();
          setViewModel(false);
        }}
      >
        <VehicleAllViewPage
          title="View Vehicle"
          title1="Vehicle Attachment"
          viewData={viewData}
          viewData1={viewData1}
          addedItems={addedItems}
        // itemDetails={itemDetails}
        // allStatus={allStatus}

        />

      </MasterModelView>
    </ThemeProvider>
  );
};

export default VehiclePage;
