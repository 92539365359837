import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";


function toTitleCase(str) {
    if(str) {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    }
  
    else {
      return "";
    }
    
  }



  export const postDirectPurchase = async (
    info,
    item_details,
    inserted_by_id,
    mode,
    onSuccess,
    onFailure
  ) => {
    //console.log(inserted_by_id,"560656")
    try {
      const res = await webApi.post("/master/purchase/insert", {
        module: info.module,
        vendor_id: info.ddl_vendor_group?.value,
        approve_id:0,
        // grn_no: r.grn_no,
        // grn_date: dateFormate(r.grn_details[0].grn_date),
        po_number: info.txt_po_no,
        po_date: timestamp(info.txt_po_date),
        reference_no: info.txt_reference_no,
        reference_date: timestamp(info.txt_reference_date),
        item_details: item_details,
        po_note: info.txt_note,
        // showroom_warehouse_id: Number(info.ddl_showroom_warehouse?.value),
        po_value: Number(info.txt_po_value),
  
        grn_details:
          mode === "DIRECT PURCHASE"
            ? [
                {
                  bill_no: info.txt_bill_no,
                  bill_date: timestamp(info.txt_bill_date),
                  bill_value: info.txt_bill_value,
  
                  challan_no: info.txt_challan_no,
                  challan_date: timestamp(info.txt_challan_date),
                //   grn_no: info.txt_grn_no,
                  vehicle_no: info.txt_vehicle_no,
                  waybill_no: info.txt_waybill_no,
                  grn_date: timestamp(info.txt_grn_date),
                  note: info.txt_note,
                },
              ]
            : [
                {
                  bill_no: info.txt_bill_no,
                  bill_date: timestamp(info.txt_bill_date),
                  bill_value: info.txt_bill_value,
  
                  challan_no: info.txt_challan_no,
                  challan_date: timestamp(info.txt_challan_date),
  
                  vehicle_no: info.txt_vehicle_no,
                  waybill_no: info.txt_waybill_no,
                  grn_date: timestamp(info.txt_grn_date),
                  note: info.txt_note,
                },
              ],
  
        active_status: info.switch_active_status ? "Y" : "N",
        inserted_by_id:inserted_by_id,
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
        // if (info.stock_update) {
        //   r.item_details.map((item) => {
        //     const movRes = webApi.post("/master/stock_movement/insert", {
        //       transaction_type: "DR",
        //       transaction_id: r.purchase_id,
        //       transaction_date: timestamp(currentDate()),
        //       showroom_warehouse_id: r.showroom_warehouse_id,
        //       item_id: item.item_id,
        //       plus_qty: Number(item.quantity),
        //       minus_qty: 0,
        //       unit_id: item.uom_id,
        //     });
        //   });
        // }
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };
  
  export const updateDirectPurchase = async (
    info,
    item_details,
    onSuccess,
    onFailure
  ) => {
    try {
      const res = await webApi.post("/master/purchase/update", {
        purchase_id: info.direct_purchase_id
          ? info.direct_purchase_id
          : info.purchase_order_id,
        vendor_id: info.ddl_vendor_group?.value,
        po_number: info.txt_po_no,
        // grn_no: r.grn_no,
        // grn_date: dateFormate(r.grn_details[0].grn_date),
        po_date: timestamp(info.txt_po_date),
        item_details: item_details,
        po_value: Number(info.txt_po_value),
        po_note: info.txt_note,
        grn_details: [
          {
            bill_no: info.txt_bill_no,
            bill_date: timestamp(info.txt_bill_date),
            bill_value: info.txt_bill_value,
  
            challan_no: info.txt_challan_no,
            challan_date: timestamp(info.txt_challan_date),
  
            vehicle_no: info.txt_vehicle_no,
            waybill_no: info.txt_waybill_no,
  
            grn_no: info.txt_grn_no,
            grn_date: timestamp(info.txt_grn_date),
            note: info.txt_note,
          },
        ],
      });
      // item_details.map((item) => {
      //   const movRes = webApi.post("/master/stock_movement/insert", {
      //     transaction_type: "DR",
      //     transaction_id: r.purchase_id,
      //     transaction_date: timestamp(currentDate()),
      //     showroom_warehouse_id: r.showroom_warehouse_id,
      //     item_id: item.item_id,
      //     plus_qty: Number(item.quantity),
      //     minus_qty: 0,
      //     unit_id: item.uom_id,
      //   });
      // });
      if (res.status === 200) {
        const r = res.data;
        //console.log(res,"000")
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };


export const getItemDetailById = async (itemId,onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/item/list", {
        item_id: itemId,

      });
      if (res.status === 200) {
        const r = res.data;
        console.log(r, "rarara")
  
  
        let allItems = [];
        r.map((r, i) => {
  
          allItems.push({
            // id: i + 1,
            id: r.item_id,
            item_id: r.item_id,
            item: r.item,
            brand_name: r.brand_name,
            stock: r.current_over_stock,
            mrp: r.mrp,
            selling_price: r.selling_price,
            cgst_percentage: r.cgst_percentage,
            igst_percentage: r.igst_percentage,
            sgst_percentage: r.sgst_percentage,
            details: r.details,
            hsn_code: r.hsn_code,
            uom_name: r.uom_name,
            uom_id: r.uom_id,
            alt_uom_id: r.alt_uom_id,
            alt_uom: r.alt_uom,
            lower_unit_value: r.lower_unit_value,
            higher_unit_value: r.higher_unit_value,
            lower_caption: r.alt_uom_name,
          });
        });
  
        if (allItems.length) {
          return onSuccess(allItems);
        } else {
          return onFailure("Item not Found");
        }
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };

// Searching Items function for every place
export const getSearchItem = async (onSuccess, onFailure, q) => {
  console.log(q, "rarara")
    try {
      const res = await webApi.post("/master/item/list", {
        query: q,
      });
      if (res.status === 200) {
        const r = res.data;
       
  
  
        let allItems = [];
        r.map((r, i) => {
  
          allItems.push({
            // id: i + 1,
            id: r.item_id,
            item_id: r.item_id,
            item: r.item,
            brand_name: r.brand_name,
            stock: r.current_over_stock,
            mrp: r.mrp,
            selling_price: r.selling_price,
            cgst_percentage: r.cgst_percentage,
            igst_percentage: r.igst_percentage,
            sgst_percentage: r.sgst_percentage,
            details: r.details,
            hsn_code: r.hsn_code,
            uom_name: r.uom,
            uom_id: r.uom_id,
            alt_uom_id: r.alt_uom_id,
            alt_uom: r.alt_uom,
            lower_unit_value: r.lower_unit_value,
            higher_unit_value: r.higher_unit_value,
            lower_caption: r.alt_uom_name,
          });
        });
  
        if (allItems.length) {
          return onSuccess(allItems);
        } else {
          return onFailure("Item not Found");
        }
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };



  export const getListVendor = async (onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/vendor/list", {
  
  
  
      });
      if (res.status === 200) {
        const r = res.data;
        let allvendor = [];
        r.map((r, i) => {
          if (r.company_name && r.vendor_id > 0 && r.company_name) {
            allvendor.push({
              value: r.vendor_id,
              label: toTitleCase(r.company_name),
            });
          }
        });
        return onSuccess(allvendor);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };



  export const getDirectPurchaseById = async (
    purchase_id,
    grnNo,
  
    onSuccess,
    onFailure
  ) => {
    try {
      const res = await webApi.post("/master/purchase/list", {
        purchase_id: purchase_id,
        grn_no: grnNo
  
        // ddl_status: ddl_status
      });
      if (res.status === 200) {
        const r = res.data;
        let allItems = [];
        r.map((r, i) => {
          var result = r.item_details.map(function (el) {
            var o = Object.assign({}, el);
            o.id = o.item_id;
            return o;
          });
  
          r.item_details = result;
  
          // //console.log(
          //   "in directPurchaseFormService, r is:\n" + JSON.stringify(r, null, 2)
          // );
  
          // console.table(r)
          allItems.push({
            //dplID: i + 1,
            id: i + 1,
            purchase_id: r.purchase_id,
            dplBillDate: dateFormate(
              get(r, "grn_details.0.bill_date") ||
                get(r, "grn_details.1.bill_date")
            ),
            dplGrnNo:r.grn_no,
            dplNote:r.po_note,
            dplBillNo:get(r, "grn_details.0.bill_no") || get(r, "grn_details.1.bill_no"),
            dplVendor: r.vendor_name,
            vendor_id: r.vendor_id,
            ddl_vendor_group: {
              value: r.vendor_id,
              label: r.vendor_name,
            },
            dplGrossAmount: "150200",
            dplTaxes: "27036",
            dplOtherCharges: "500",
            dplNetAmount: "177736",
            item_details: r.item_details,
            bill_no:
              get(r, "grn_details.0.bill_no") || get(r, "grn_details.1.bill_no"),
            bill_date: dateFormate(
              get(r, "grn_details.0.bill_date") ||
                get(r, "grn_details.1.bill_date")
            ),
            bill_value:
              get(r, "grn_details.0.bill_value") ||
              get(r, "grn_details.1.bill_value"),
            challan_no:
              get(r, "grn_details.0.challan_no") ||
              get(r, "grn_details.1.challan_no"),
            challan_date: dateFormate(
              get(r, "grn_details.0.challan_date") ||
                get(r, "grn_details.1.challan_date")
            ),
            vehicle_no:
              get(r, "grn_details.0.vehicle_no") ||
              get(r, "grn_details.1.vehicle_no"),
            waybill_no:
              get(r, "grn_details.0.waybill_no") ||
              get(r, "grn_details.1.waybill_no"),
            grn_no:
              get(r, "grn_details.0.grn_no") || get(r, "grn_details.1.grn_no"),
            grn_date: dateFormate(
              get(r, "grn_details.0.grn_date") || get(r, "grn_details.1.grn_date")
            ),
            note: get(r, "grn_details.0.note") || get(r, "grn_details.1.note"),
            direact_purchase_status: r.direact_purchase_status,
            purchase_order_status: r.purchase_order_status,
            showroom_warehouse_id: r.showroom_warehouse_id,
            po_number: r.po_number,
            po_date: dateFormate(r.po_date),
            po_note: r.po_note,
            po_value: currencyFormate(r?.item_details[0].net_value),
            received_item_details: r?.received_item_details,
          });
        });
        // //console.log(allItems,"sen2323")
        return onSuccess(allItems[0]);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };


