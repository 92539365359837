import axios from "axios";

const prodApi = "https://api.gstspl.com";

// const betaApi = "https://api.transportsystems.app/betaAPI";

//FOR BETA SERVER
// const devApi = "https://api.Transport.app/betaAPI";

//FOR LOCAL SERVER
const devApi = "http://localhost:3000";
// const devApi = "http://15.206.180.46:3000";


export const fileUploadApi =
  "https://api.gstspl.com/files/upload?uploadpath=";
export const IMG_API =
  "https://s3.ap-south-1.amazonaws.com/document.transport.app/";

export let baseURL;
const subdomain=window.location.host.split(".")[0];

console.log(process.env.NODE_ENV,"sen0110",prodApi)
if (process.env.NODE_ENV === "production") {
  baseURL = prodApi;
}
// if (process.env.NODE_ENV === "production" && subdomain==="beta") {
//   baseURL = betaApi;
// } 
else {
  baseURL = prodApi; //prodApi //devApi
}


//console.log(process.env.NODE_ENV);

let instance = axios.create({
  baseURL: baseURL,
  // baseURL:betaApi,
  responseType: "json",
});

instance.defaults.headers.common["Authorization"] = localStorage.getItem(
  "user_token"
);

export default instance;
