import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";
import { currentDate } from "../views/Pages/HelperComponent/utils";


export const postBreakDown = async (breakDown, insertedById, onSuccess, onFailure) => {
 
    
    try {
      const res = await webApi.post("/master/breakDown/insert", {
     
        vehicle_id:breakDown.ddl_vehicle_id,
        vehicle_no:breakDown.ddl_vehicle_no,
        driver_name:breakDown.txt_Driver,
        Place:breakDown.txt_place,  
        breakDownTime: breakDown.txt_time,
        breakDownDate: timestamp(breakDown.brakDown_date),       
        desecription:breakDown.txt_dec,
        image_path:breakDown.image_path,  
        image_name:breakDown.image_name,  
        break_down:true,                   
        inserted_by_id: insertedById,
      })
      console.log(res,"sank0911=>")
      if (res.status === 200) {
        const r = res.data;
       
        onSuccess(r);
  
      } else {
        onFailure("1Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("2Something Wrong! Please Try again later " + error);
    }
  }

export const postFileUpload = async (file, onSuccess, onFailure) => {
    let formData = new FormData();
    formData.set("file", file);
    try {
      const res = await webApi.post(
        // "../assets/img",
        "https://api.gstspl.com/files/upload?uploadpath=image",
        formData
      );
      if (res.status === 200) {
        const r = res.data;
        if (r.success) {
          onSuccess(r);
        }
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };



  export const getAllVehicleById = async (onSuccess, onFailure) => {
    try {
  
      const res = await webApi.post("/master/vehicle/list", {
       
      });
      if
        (res.status === 200) {
        const r = res.data;
        console.log(r, "sen19102022/list")
  
        let all_vehicles = [];
        r.map((r, i) => {
          all_vehicles.push({
  
            //for ddl
            value: r.vehicle_id,
            label: r.vehicle_no,
           
          });
        });
        return onSuccess(all_vehicles);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };


  export const getBreakDownList = async (addSearch, onSuccess, onFailure) => {
    
    try {
  
      const res = await webApi.post("/master/breakDown/list", {
        vehicle_no: addSearch.vehicle_no,
        // fromDate: timestamp(addSearch.txt_from_date),
        // toDate: timestamp(addSearch.txt_to_date),
  
      });
      if
        (res.status === 200) {
        const r = res.data;
  
        let breakDown = [];
        r.map((r, i) => {
  
            breakDown.push({
              id: i + 1,
              breakDown_id:r.breakDown_id,
              vehicle_id: r.vehicle_id,
              vehicle_no: r.vehicle_no,
              breakDownTime:r.breakDownTime,
              breakDownDate: dateFormate(r.breakDownDate),
              driver_name: r.driver_name,
              Place: r.Place ,
              desecription: r.desecription,
              image_path: r.image_path ,
              image_name:r.image_name,
              action_items:r.action_items,
              action: "action",
            })
  
  
        });
  
        return onSuccess(breakDown);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };



  export const getBreakDownForView = async (id, onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/breakDown/view", {
        breakDown_id: id,
      });
      console.log(res, "sankres");
  
      if (res.status === 200) {
        const r = res.data;
  
        return onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };



  export const getAllBreakDownList = async ( onSuccess, onFailure) => {
    
    try {
  
      const res = await webApi.post("/master/breakDown/list", {
      
  
      });
      if
        (res.status === 200) {
        const r = res.data;
  
        let breakDown = [];
        r.map((r, i) => {
  
            breakDown.push({
              id: i + 1,
              breakDown_id:r.breakDown_id,
              vehicle_id: r.vehicle_id,
              vehicle_no: r.vehicle_no,
              breakDownTime:r.breakDownTime,
              breakDownDate: dateFormate(r.breakDownDate),
              driver_name: r.driver_name,
              Place: r.Place ,
              desecription: r.desecription,
              image_path: r.image_path ,
              image_name:r.image_name,
              action_items:r.action_items,
              action: "action",
            })
  
  
        });
  
        return onSuccess(breakDown);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };