import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { ColoseButton } from "views/Components/CustomButton";

import { getAllLocation, postRoute, getAllRouteLocation, getInBetweenRoutes } from "../../../services/routesPageService";

import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Box, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import theme from "../../../theme/theme";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";

import React from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory, useLocation } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from 'file-saver';
import XLSX from 'xlsx'
import IconButton from '@material-ui/core/Button';
import { currentDate } from "../HelperComponent/utils";
import { getVehicleType } from "services/vehicleTypeService";
import { useState } from "react";

const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },

}));


const toTitleCase = (str) => {
  if (str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  else {
    return "";
  }

}
const RouteForm = () => {
  const classes1 = useStyles1()
  const history = useHistory();
  const locations = useLocation()
  const [modules, setAllModules] = React.useState([]);
  const [classicModal, setClassicModal] = React.useState(false);
  const [location, setAllLocation] = React.useState([]);
  const [routeLocation, setAllRouteLocation] = React.useState([]);
  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [vehicleType, setVehicleType] = useState([])
  const [routes, setRoutes] = React.useState({
    edit: false,
    route_id: "",
    ddl_delivery_location_id: "",
    ddl_delivery_location: "Select",
    ddl_loading_loaction_id: "",
    ddl_loading_loaction: "",
    ddl_truck_type_id: "",
    ddl_truck_type: "",
    wheels: "",
    wheels_id: "",
    switch_active_status: true,
    txt_amount: 0,
  });

  const [existingRoutes, setExistingRoutes] = React.useState()

  //options of no of wheels
  const vehicleWheels = [
    { value: 6, label: "6" },
    { value: 10, label: "10" },
    { value: 12, label: "12" },
    { value: 14, label: "14" },
    { value: 16, label: "16" },
    { value: 18, label: "18" },
    { value: 20, label: "20" },

  ];

  const [error, setError] = React.useState({
    txt_location: false,
    ddl_location_type: false,

  });

  const formData = {
    formName: "Add a Routes",
    fields: [
      {
        name: "ddl_delivery_location",
        value: routes,
        label: "Delivery Location",
        placeholder: "Select Location",
        hidden: false,
        required: true,
        data_type: "select",
        html_element: "select",
        error: false,
        options: location.filter((o) => o.location_type === "Unloading"),
        xs: 3,

      },
      {
        name: "ddl_loading_loaction",
        value: routes,
        label: "Loading Location",
        placeholder: "Select Location",
        hidden: false,
        required: true,
        data_type: "select",
        html_element: "select",
        error: false,
        options: location.filter((o) => o.location_type === "Loading"),
        xs: 3,
      },
      {
        name: "ddl_truck_type",
        options: vehicleType,
        label: "Truck Type",
        placeholder: "Select Type",
        hidden: false,
        required: true,
        data_type: "select",
        html_element: "select",
        error: false,
        xs: 3,
      },
      {
        name: "ddl_wheels",
        value: routes,
        label: "Wheels",
        placeholder: "No.",
        hidden: false,
        required: true,
        data_type: "select",
        html_element: "select",
        error: false,
        options: vehicleWheels,
        xs: 3,
      },
      {
        name: "txt_amount",
        label: "Amount",
        hidden: false,
        required: false,
        align: "right",
        data_type: "number",
        html_element: "TextField",
        error: false,
        xs: 2,

      },
      {
        name: "switch_active_status",
        label: "Active Status",
        defaultValue: false,
        required: false,
        data_type: "string",
        html_element: "switch",
        error: false,
      },
    ],
  };

  React.useEffect(() => {
    setLoading(true);

    getAllLocation(
      (location) => {
        // console.log(location, "sen11102022")
        setAllLocation(location);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );

    getVehicleType(
      (res) => {
        setVehicleType(res)
        // console.log(res, "sen1108/r")
      },
      (err) => {

      })
    if (routes.ddl_delivery_location_id && routes.ddl_loading_loaction_id) {
      getInBetweenRoutes(
        routes.ddl_delivery_location_id,
        routes.ddl_loading_loaction_id,
        (res) => {
          setExistingRoutes(res)
        },
        (err) => {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
        }
      )
    }
  }, [refresh, routes,locations]);

  const onSetActive = (e) => {
    setRoutes((prv) => ({ ...prv, [e.target.name]: e.target.checked }));
  };

  const headerData = [
    {
      id: "id",
      label: "#",
      align: "left",
    },
    {
      id: "route_no",
      label: "Route No.",
      align: "left",
    },
    {
      id: "delivery_location",
      label: "Delivery Location",
      align: "left",
    },
    {
      id: "loading_loaction",
      label: "Loading Location",
      align: "center",
    },
    {
      id: "wheel",
      label: "Wheel",
      align: "right",
    },
    {
      id: "amount",
      label: "Amount",
      align: "right",
    },

  ];
  // onSubmit called
  const onSubmitModel = (e) => {

    e.preventDefault();

    // console.log(routes, "sen12223")
    // if (!addLocation.txt_location) {
    //   setError({
    //     txt_location: !addLocation.txt_location,
    //     ddl_location_type: !addLocation.ddl_location_type

    //   });
    // } else {
    //   if (addLocation.edit) {
    //     updateLocation(
    //       addLocation,
    //       (r) => {
    //         onCloseModel();
    //         dispatch({
    //           type: actionTypes.SET_OPEN_MSG,
    //           payload: {
    //             msg: "Location Updated Successfully",
    //             msgType: "success",
    //           },
    //         });
    //         setRefresh(!refresh);
    //       },
    //       (err) => {
    //         dispatch({
    //           type: actionTypes.SET_OPEN_MSG,
    //           payload: { msg: err, msgType: "error" },
    //         });
    //       }
    //     );
    //   } else {

    postRoute(
      routes,
      globalState?.user?.serial_id,
      (r) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "Route Added Successfully",
            msgType: "success",
          },
        });
        onCloseModel();
        setRefresh(!refresh);
        history.push({ pathname: "/admin/master/routePage" })
        // setRoutes(
        //   {
        //     route_id: "",
        //     ddl_delivery_location_id: "",
        //     ddl_delivery_location: "Select",
        //     ddl_loading_loaction_id: "",
        //     ddl_loading_loaction: "",
        //     wheels: "",
        //     wheels_id: "",
        //     switch_active_status: true,
        //     txt_amount: 0,
        //   }
        // )
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
        setButtonDisabled(true)
      }
    );

    //   }
    // }
  };
  // // on Delete called
  // const onDeleteVehicle = (row, next) => {
  //   deleteVehicle(
  //     row.location_id,
  //     (r) => {
  //       next();
  //       setRefresh(!refresh);
  //     },
  //     (err) => {
  //       dispatch({
  //         type: actionTypes.SET_OPEN_MSG,
  //         payload: { msg: err, msgType: "error" },
  //       });
  //     }
  //   );
  // };


  const onSelectModule = (event, value) => {
    // console.log(value.name, "sen11102022/1", event)

    switch (value.name) {
      case "ddl_delivery_location":
        setRoutes({
          ...routes,
          ddl_delivery_location_id: event.value,
          ddl_delivery_location: event.label,
        });


        break;
      case "ddl_loading_loaction":
        setRoutes({
          ...routes,
          ddl_loading_loaction_id: event.value,
          ddl_loading_loaction: event.label,
        });
        // console.log("working", routes.ddl_delivery_location_id)
        break;
      case "ddl_wheels":
        setRoutes({
          ...routes,
          wheels_id: event.value,
          wheels: event.label,
        });
        break;
      case "ddl_truck_type":
        setRoutes({
          ...routes,
          ddl_truck_type_id: event.value,
          ddl_truck_type: event.label,
        });
        break;
    }

  };


  const onChange = (e) => {
    const { value, name } = e.target;

    setRoutes({ ...routes, [name]: value });

  };
  const classes = useStyles();

  // on Close Model
  const onCloseModel = () => {
    setClassicModal(false);

  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setRoutes([]);
  };

  const onClickClose = () => {
    setRefresh(!refresh);
    setRoutes([]);
    history.push("/admin/master/routePage")
  };
  // export to excel

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Master > Setting > Routes"
      // btnToolTip="Add New Routes"
      // onClickAddBtn={onClickAddBtn}
      />
      <CustomCard
        cdTitle="Routes Details"
      >
        <GridContainer className={classes.root}>

          {formData.fields.map((item, key) => {
            return (
              <>
                <GridItem
                  xs={item.xs}
                  key={key}
                  lg={item.lg}
                  md={item.md}
                  style={{ padding: 5 }}>
                  {item.html_element === "select" && (
                    <>
                      <InputLabel id="label">{item.label}</InputLabel>
                      <Select
                        options={item.options}
                        placeholder={item.placeholder}
                        formatGroupLabel={(d) => d.label}
                        className={classes.customSelect}
                        onChange={onSelectModule}
                        name={item.name}
                        fullWidth={true}
                        variant="outlined"
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}

                      />
                    </>
                  )}
                  {item.html_element === "TextField" && (
                    <>
                      <InputLabel required={item.required} id="label">
                        {item.label}
                      </InputLabel>
                      <TextField
                        size="small"
                        required={item.required}
                        placeholder={item.label}
                        name={item.name}
                        onChange={onChange}
                        id="outlined-basic"
                        error={error[item.name]}
                        FormHelperTextProps={{
                          style: { textAlign: "right" },
                        }}
                        helperText={
                          error[item.name]
                            ? item.label + "  is required"
                            : ""
                        }
                        fullWidth={true}
                        value={routes[item.name]}
                        variant="outlined"
                      />

                    </>
                  )}
                  {item.html_element === "switch" && (
                    <div style={{ marginTop: 20 }}>
                      <span className={classes.activeText}>
                        {item.label}
                      </span>
                      <Switch
                        onClick={onSetActive}
                        checked={routes[item.name]}
                        name={item.name}
                        fullWidth={true}
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                        color="primary"
                      />
                    </div>
                  )}
                </GridItem>
              </>
            );
          })}
          <div style={{ width: "100%" }} className={classes.actionbtns}>
            <CustomButton
              style={{ float: "right", width: "10%", marginRight: "10px", border: "none" }}
              onClick={onSubmitModel}
            >
              Submit
            </CustomButton>
            <CustomButton
              style={{ float: "right", width: "10%", marginRight: "10px", background: 'red', border: "none" }}
              onClick={onClickRefresh}
            >
              <RotateLeftIcon />
            </CustomButton>
            <ColoseButton
              style={{ backgroundColor: "red", color: "white", float: "right", width: "10%", marginRight: "10px", border: "none" }}
              onClick={onClickClose}
            >
              Close
            </ColoseButton>
          </div>
        </GridContainer>
      </CustomCard>

      {existingRoutes &&
        <CustomCard
          cdTitle="Existing Routes"
        >
          <MuiTable
            columns={headerData}
            rows={existingRoutes}
          />

        </CustomCard>
      }
    </ThemeProvider>
  );
};

export default RouteForm;
