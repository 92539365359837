import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";
import { currencyFormate } from "../HelperComponent/utils";
//SERVICE
import {
  getSearchReceiptList,
  deleteReceipt,
  getAllReceipt,
  postReceipt,
  postAdjReceipt,
  updateReceipt,
  getLedgerClosingBalanceReceipt,
  getListLedgerAccount,
  getListLedgerAccountByGroupId,
} from "../../../services/receiptListService";
//SERVICE
// import { getListLedgerAccount, getListLedgerAccountByGroupId } from "../../../services/LedgerAccountService";
// IMPORT Service Function
// import {
//   getAllGroup,
//   getAllReference,
//   postCustomerForm,
//   updateCustomer,
// } from "../../../services/customerListService";
import StepProceedModel from "../HelperComponent/StepProceedModel";

import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import ReactSelect from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Autocomplete } from "@material-ui/lab";
import { IconButton, OutlinedInput } from "@material-ui/core";
//SERVICE
import FormComponent from "../HelperComponent/FormComponent";

import theme from "../../../theme/theme";

// import React from "react";
import {
  appDefaultColor,
  appSecondColor,
  reactSelectStyles,
} from "assets/jss/material-dashboard-pro-react";
import { appDefaultFamily } from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import React, { useState } from "react";
import PageTitle from "../HelperComponent/PageTitle";
import { Grid } from "@material-ui/core";
import {
  currentDate,
  currentDate1,
  dateFormate,
  dateFormateField,
} from "../HelperComponent/utils";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from 'file-saver';
import XLSX from 'xlsx'
import { getAllVehicleForDistance } from "services/distanceCoveredManualService";
import { postDistanceCovered } from "services/distanceCoveredManualService";
import { getSearchDistanceCoveredtList } from "services/distanceCoveredManualService";
// import { WhatsappShareButton } from 'react-share';
const useStyles1 = makeStyles(styles);

let option = [
  { value: "1", label: "Bank" },
  { value: "2", label: "Cash" },

];

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const month = [
    
    { label:'January', value:1 },
    { label:'February', value:2 },
    { label:'March', value:3 },
    { label:'April', value:4 },
    { label:'May', value:5 },
    { label:'June', value:6 },
    { label:'July', value:7 },
    { label:'August', value:8 },
    { label:'September', value:9 },
    { label:'October', value:10 },
    { label:'November', value:11 },
    { label:'December', value:12 }
  ];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 2 }, (_, index) => (currentYear - index).toString());

const DistanceCoveredManualPage = () => {
  const classes1 = useStyles1()

  const history = useHistory();
  const [classicModal, setClassicModal] = React.useState(false);
  const classes = useStyles();
  const [globalState, dispatch] = useStateValue();
  const [loading, setLoading] = React.useState(false);  
  const [allVehicle, setAllVehicle] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [allDistance, setAllDistance] = React.useState([]); 
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [collapsible, setCollapsible] = React.useState(true)
  const user_id = globalState?.user?.serial_id;
  const [receipt, setReceipt] = React.useState({
    edit: false,
    ddl_Vehicle_id: "",
    ddl_Vehicle_label:"",  
    ddl_month_id: "",
    ddl_month_label: "Select",  
    ddl_year_id: "",
    ddl_year_label: "Select",  
    txt_distance: "",
    txt_nar: "",
    
  });


  const [addSearch, setAddSearch] = React.useState({
    txt_voucher_no: "",
    ddl_ledger: "",
    category: "",
    brand: "",
    item: "",
    bank: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });
  // for Error handler state
  const [error, setError] = React.useState({
    ddl_mode: false,
    ddl_ledger: false,
  });

  const onSelectDetails = (name, v) => {

    switch (name) {
      case "ddl_Vehicle":

        setReceipt({
          ...receipt,
          ddl_Vehicle_id: v.value,
          ddl_Vehicle_label: v.label,
        });

       

        break;
      case "ddl_month":

        setReceipt({
          ...receipt,
          ddl_month_id: v.value,
          ddl_month_label: v.label,
        });
       
        break;
        case "ddl_year":

        setReceipt({
          ...receipt,
          ddl_year_id: v.value,
          ddl_year_label: v.label,
        });
       
        break;

      default:
        break;
    }
  };


  const onSearchReceiptListPage = (e) => {
  
setRefresh(!refresh)
    e.preventDefault();
    setLoading(true);

    getSearchDistanceCoveredtList(

      (list) => {
console.log(list,"sankhalist")
          setAllDistance(list);
         
        }  ,  
 
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
        setLoading(false);
      },
    //   addSearch


    );

  };

  // onSubmit called

  const onSubmitModel = (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    postDistanceCovered(
            receipt,
            user_id,
            (r) => {
              dispatch({
                type: actionTypes.SET_OPEN_MSG,
                payload: {
                  msg: "Distance Covered added Successfully",
                  msgType: "success",
                },
              });
              onCloseModel();             
             
            },
            (err) => {
              dispatch({
                type: actionTypes.SET_OPEN_MSG,
                payload: { msg: err, msgType: "error" },
              });
              setButtonDisabled(true);
            }
          )
    
  };

  // on Delete called
//   const onDeleteReceipt = (row, next) => {
//     deleteReceipt(
//       row.receipt_payment_id,
//       (r) => {
//         next();
//         setRefresh(!refresh);
//       },
//       (err) => {
//         dispatch({
//           type: actionTypes.SET_OPEN_MSG,
//           payload: { msg: err, msgType: "error" },
//         });
//       }
//     );
//   };

  // on Edit called
//   const onUpdateReceipt = (row) => {
//     console.log(user_id, "sen05059")
//     console.log(row, "sen0505/row")

//     if (row.recMode === 'Bank')
//       setBankLedgerEnabled(true);
//     else {
//       setBankLedgerEnabled(false);
//     }
//     setClassicModal(true);

//     setReceipt({
//       ...receipt,
//       edit: true,
//       receipt_payment_id: row.receipt_payment_id,
//       txt_voucher_no: row.recVoucherNo,
//       txt_voucher_date: dateFormateField(row.recDate),
//       ddl_ledger: row.recLedger,
//       ddl_ledger_label: row.ledger_account_name,
//       ddl_mode: row.recMode,
//       ddl_mode_label: row.recMode,
//       ddl_bank: bankAccount.filter(o => o.value === row.bank_id)[0]?.value,
//       ddl_bank_label: bankAccount.filter(o => o.value === row.bank_id)[0]?.label,
//       txt_amount: row.recAmount,
//       txt_reference: row.recReference,
//       txt_nar: row.recNarration,
//     });

//   };

  const onChange = (e) => {
    const { value, name } = e.target;
    setReceipt({ ...receipt, [name]: value });

  };

  const onCloseModel = () => {
    setButtonDisabled(false);
    setClassicModal(false);
    setRefresh(!refresh);
    
    setReceipt({
        edit: false,
        ddl_Vehicle_id: "",
        ddl_Vehicle_label:"",  
        ddl_month_id: "",
        ddl_month_label: "Select",  
        txt_distance: "",
        txt_nar: "",
    });
  
  };

  const fetchData = () => { 
    getAllVehicleForDistance(
        (r) => {
          setAllVehicle(r);
  
        },
  
        (err) => {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
        },
      );

  };

  React.useEffect(() => {
    fetchData();
  }, [refresh]);

  const headerData = [
    {
      id: "dis_ID",
      label: "#",
      align: "left",
    },
    {
      id: "Vehicle_no",
      label: "Vehicle NO",
      align: "left",
    },
    {
      id: "dis_year",
      label: "Year",
      align: "left",
    },
    {
      id: "dis_jan",
      label: "January",
      align: "left",
    },
    {
      id: "dis_feb",
      label: "February",
      align: "left",
    },
    {
      id: "dis_mar",
      label: "March",
      align: "left",
    },
    {
      id: "dis_apr",
      label: "April",
      align: "left",
    },
    {
      id: "dis_may",
      label: "May",
      align: "left",
    },
    {
        id: "dis_june",
        label: "June",
        align: "left",
      },
      {
        id: "dis_july",
        label: "July",
        align: "left",
      },
      {
        id: "dis_aug",
        label: "August",
        align: "left",
      },
      {
        id: "dis_sep",
        label: "September",
        align: "left",
      },
      {
        id: "dis_oct",
        label: "October",
        align: "left",
      },
      {
        id: "dis_nov",
        label: "November",
        align: "left",
      },
      {
        id: "dis_dec",
        label: "December",
        align: "left",
      },

      {
        id: "dis_total",
        label: "Total",
        align: "left",
      },

    {
      id: "dis_Narration",
      label: "Narration",
      align: "left",
    },   

    // {
    //   id: "recAction",
    //   label: "Action",
    //   align: "right",
    // },
  ];

  const onAddSearch = (e) => {

    const { name, value } = e.target;
    console.log("add search", value);
    setAddSearch((prv) => ({ ...prv, [name]: value }));
  };

  const onSelect = (info, v) => {
    setAddSearch({ ...addSearch, [info.name]: v });
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddSearch({

      txt_voucher_no: "",
      ddl_ledger: "",
      category: "",
      brand: "",
      item: "",
      txt_to_date: currentDate(),
      txt_from_date: currentDate1(),

    });
  };

  const formData = {
    formName: "Add Distance Covered",
    fields: [
      
        {
            name: "ddl_Vehicle",
            label: "Vehicle No",
            hidden: false,
            required: true,
            data_type: "string",
            html_element: "select",
            align: "right",
            // disabled: !bankLedgerEnabled,
            error: false,
            options: allVehicle,
            xs: 6,
            md: 5,
            lg: 6
        },
        {
            name: "ddl_month",
            label: "Month",
            hidden: false,
            required: true,
            data_type: "string",
            html_element: "select",
            align: "right",
            // disabled: !bankLedgerEnabled,
            error: false,
            options: month,
            xs: 6,
            md: 5,
            lg: 6
        },

        {
          name: "ddl_year",
          label: "Year",
          hidden: false,
          required: true,
          data_type: "string",
          html_element: "select",
          align: "right",          
          error: false,
          options: years.map(year => ({ label: year, value: year })),
          xs: 6,
          md: 5,
          lg: 6
      },

      
     

      {
        name: "txt_distance",
        label: "Distance Put",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        align: "right",
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
      },
    //   {
    //     name: "txt_covered",
    //     label: "Distance Covered",
    //     hidden: false,
    //     required: false,
    //     data_type: "number",
    //     html_element: "TextField",
    //     align: "right",
    //     error: false,
    //     xs: 6,
    //     md: 6,
    //     lg: 6,
    //   },
    //   {
    //     name: "txt_oil",
    //     label: "Oil(ltr.)",
    //     hidden: false,
    //     required: false,
    //     data_type: "number",
    //     html_element: "TextField",
    //     align: "right",
    //     error: false,
    //     xs: 6,
    //     md: 6,
    //     lg: 6,
    //   },
    //   {
    //     name: "txt_price",
    //     label: "Price",
    //     hidden: false,
    //     required: false,
    //     data_type: "number",
    //     html_element: "TextField",
    //     align: "right",
    //     error: false,
    //     xs: 6,
    //     md: 6,
    //     lg: 6,
    //   },
      {
        name: "txt_nar",
        label: "Narration",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
      },
    ],
  };


  // const onhandleExportToExcel = () => {
  //   const DistanceCoveredTable = allDistance.map((receipt) => {
  //     return {
  //       Sl_No: receipt.dis_ID,
  //       Vehicle_no: receipt.Vehicle_no,
  //       January: receipt.dis_jan,
  //       February: receipt.dis_feb,
  //       March: receipt.dis_mar,
  //       April: receipt.dis_apr,
  //       May: receipt.dis_may,
  //       June: receipt.dis_june,
  //       July: receipt.dis_july,
  //       August: receipt.dis_aug,
  //       September: receipt.dis_sep,
  //       October: receipt.dis_oct,
  //       November: receipt.dis_nov,
  //       December: receipt.dis_dec,
  //       dis_Narration: receipt.dis_Narration,
  //     };
  //   });
  
  //   const fileName = 'Distance Covered List';
  //   const fileType =
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //   const fileExtension = '.xlsx';
  //   const ws = XLSX.utils.json_to_sheet(DistanceCoveredTable);
  //   const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  
  //   // Share the file via WhatsApp
  //   const shareUrl = window.URL.createObjectURL(data);
  //   const shareTitle = 'Check out my Excel file';
  //   const whatsappMessage = 'Hey, I just created an Excel file. Check it out!';
  //   const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
  //     `${shareTitle}\n${whatsappMessage}\n${shareUrl}`
  //   )}`;
  
  //   // Open the WhatsApp link in a new tab
  //   window.open(whatsappUrl, '_blank');
  // };
  

  // export to excel
  const onhandleExportToExcel = () => {
    const DistanceCoveredTable = allDistance.map(receipt => {
      return {
        Sl_No: receipt.dis_ID,       
        Vehicle_no: receipt.Vehicle_no,
        January:receipt.dis_jan,
        February: receipt.dis_feb,
        March: receipt.dis_mar,
        April: receipt.dis_apr,
        May: receipt.dis_may,
        June: receipt.dis_june,
        July:receipt.dis_july,
        August: receipt.dis_aug,
        September: receipt.dis_sep,
        October: receipt.dis_oct,
        November: receipt.dis_nov,
        December: receipt.dis_dec,
        dis_Narration: receipt.dis_Narration,
     
      }
    })

    const fileName = 'Distance Covered  List'
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(DistanceCoveredTable);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const onClickCollaps = () => {
    collapsible ?
      setCollapsible(false)
      :
      setCollapsible(true)
  }


  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Accounts > Distance Covered Manual"
        btnToolTip="Add New Distance"
        // addBtnLink="/admin/account/receiptSalesForm"
        onClickAddBtn={() => setClassicModal(true)}
      />

      <GridContainer>
        <GridItem xs="12">
          <CustomCard cdTitle="Search Distance Covered List"
            btnToolTip={collapsible ? "Collaps" : "Expand"}
            onClickCollapsible={onClickCollaps}
            buttonAction={collapsible}
            filterIcon>
            {
              collapsible ?
                <Paper elevation="0" className={classes.searchBar}>
                  <GridContainer justifyContent="flex-start" alignItems="flex-end">
                    <GridItem xs="3">
                      <InputLabel id="label">Vehicle No</InputLabel>
                      <TextField
                        size="small"
                        type="search"
                        placeholder="Vehicle No"
                        name="txt_voucher_no"
                        onChange={onAddSearch}
                        id="outlined-basic"
                        fullWidth={true}
                        value={addSearch.txt_voucher_no}
                        variant="outlined"
                      />
                    </GridItem>

                    <GridItem xs="2">
                      <InputLabel id="label">Date Between</InputLabel>
                      <TextField
                        size="small"
                        id="date"
                        name="txt_from_date"
                        variant="outlined"
                        type="date"
                        fullWidth={true}
                        defaultValue={currentDate()}
                        value={addSearch.txt_from_date}
                        onChange={onAddSearch}
                        // onChange={(v) => console.log(v.target.value)}
                        // className={classes.dateField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs="2">
                      {/* <InputLabel id="label">Date</InputLabel> */}
                      <TextField
                        size="small"
                        id="date"
                        name="txt_to_date"
                        variant="outlined"
                        type="date"
                        fullWidth={true}
                        defaultValue={currentDate1()}
                        value={addSearch.txt_to_date}
                        onChange={onAddSearch}
                        // onChange={(v) => console.log(v.target.value)}
                        className={classes.dateField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                 

                    <GridItem xs="12">
                      <div
                        style={{
                          float: "right",
                          display: "flex",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <CustomButton style={{ marginRight: "10px" }}

                          type="submit"
                          onClick={onSearchReceiptListPage}
                        >
                          <SearchIcon />
                        </CustomButton>
                        <CustomButton onClick={onClickRefresh}>
                          <RotateLeftIcon />
                        </CustomButton>
                      </div>
                    </GridItem>
                  </GridContainer>
                </Paper>
                : ''}
          </CustomCard>
        </GridItem>
      </GridContainer>

      <MasterModel
        classicModal={classicModal}
        onCloseModel={() => onCloseModel(false)}
        width={450}
        okBtnText="Submit"
        height="auto"
        modelName={receipt.edit ? "Edit Distance Covered" : "Add New Distance Covered"}
        onClickOk={onSubmitModel}
        btnDisabled={buttonDisabled}

      >
        <form style={{ padding: "20px 10px", width: "100%" }}>
          <GridContainer>
            {formData.fields.map((item, key) => {
              return (
                <>
                  <FormComponent
                    menuPortal={false}
                    item={item}
                    key={key}
                    onSelect={onSelectDetails}
                    state={receipt}
                    onChange={onChange}
                    error={error}
                  />
                </>
              );
            })}
          </GridContainer>
        </form>
      </MasterModel>

    
      {/* {loading ? (
        <Box mt={10} width="100%" textAlign="center">
          <CircularProgress />
        </Box>
      ) : ( */}
        <GridContainer className={classes.root} >
          <GridItem xs="12">
            <Card className={classes1.headerCard}>
              <CardHeader className={classes1.TbheaderCdhd} style={{ height: 60 }}>
                <GridContainer justifyContent="space-between" alignItems="center">
                  <GridItem>
                    <h4 className={classes1.headerCdTitle}>Distance Covered List</h4>
                  </GridItem>
                  <GridItem style={{ cursor: "pointer" }}>
                    <IconButton variant="text" onClick={() => onhandleExportToExcel(allDistance)} >
                      <Tooltip title="Export to Excel">
                        <img src={require("../../../assets/img/excel.png").default} />
                      </Tooltip>
                    </IconButton>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody
                style={{ height: "auto", maxHeight: 480, padding: 10 }}
                className={clxs(classes.customScroolBar)}
              >
               { console.log(allDistance,"sankooo")}
                {!refresh ?
                  <MuiTable
                    // onClickDelete={onDeleteReceipt}
                    // onClickEdit={onUpdateReceipt}
                    columns={headerData}
                    rows={allDistance}
                    // footer={currencyFormate(totalReceiptAmount)}
                    // footerColIndex={2}
                  /> : ""}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      {/* )} */}
    </ThemeProvider>
  );
};

export default DistanceCoveredManualPage;
