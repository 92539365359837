import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";

import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Paper } from "@material-ui/core";

import { ThemeProvider, Box } from "@material-ui/core";

import ReactSelect from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Autocomplete } from "@material-ui/lab";
import { IconButton, OutlinedInput } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { currencyFormate } from "views/Pages/HelperComponent/utils";

//Tables
import {
  appFontWeightThin,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "6px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "0px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  customSelect: {
    marginBottom: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
    "&:hover": {
      backgroundColor: "rgba(43,43, 43, 0.03)",
    },
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);
//Theme
import theme from "../../../theme/theme";
//Services

// import React from "react";
import {
  appDefaultColor,
  appSecondColor,
  reactSelectStyles,
} from "assets/jss/material-dashboard-pro-react";
import { appDefaultFamily } from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import React, { useState } from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  currentDate,
  currentDate1,
  currentDate1Pdf,
  currentDatePdf,
} from "../HelperComponent/utils";

import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import {
  getAllVehicles,
  getSearchVehicle,
} from "services/totalDistanceCoveredService";
import { getDailyTruckReport } from "services/dailyTruckReportService";
import { getIntransit } from "services/inTransitService";

const useStyles1 = makeStyles(styles);

//PDF
// import pdfIcon from "../../../assets/img/pdf-icon.png"
// import { jsPDF } from "jspdf";
const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  narraction: {
    width: 155,
  },
  date: {
    width: 88,
  },
  voucher: {
    width: 190,
  },
  particular: {
    width: 110,
  },

  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const onChange = (e) => {
  const { value, name } = e.target;
  setAddBrand({ ...allUnits, [name]: value });
};

const InTransitReport = () => {
  const classes1 = useStyles1();

  const history = useHistory();
  const [globalState, dispatch] = useStateValue();
  const [inTransit, setInTransit] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);

  const [addSearch, setAddSearch] = React.useState({
    txt_from_date: currentDate(),
  });

  const [distanceList, setDistanceList] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const [collapsible, setCollapsible] = React.useState(true);

    const fetchData = () => {
        getIntransit(
        (r) => {
          console.log("R:",r);
          setInTransit(r);
        },
        (err) => {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
        }
      );
    };



  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);


  const onhandleExportToExcel = () => {
    const InTranTable = inTransit.map(inTransit => {
      console.log(inTransit,"sankhaitem")

      return {
        ID: inTransit.id,
        Advance_Taken_On: inTransit.advance_date,
        Transaction_No: inTransit.transaction_no,
        Truck_No: inTransit.vehicle_no,
        Driver: inTransit.driver_name,       
        Driver_No: inTransit.driver_no,
       
     
       

      }
    })
    const fileName = 'InTransit List'
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(InTranTable);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }




  const classes = useStyles();

  const onClickCollaps = () => {
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };

 

  return (
    <ThemeProvider theme={theme}>
      <PageTitle title="MIS Report > InTransit Report" />

   

      <GridContainer className={classes.root} justifyContent="flex-start">
        <GridItem xs="12">
          <Card className={classes1.headerCard}>
            <CardHeader
              className={classes1.TbheaderCdhd}
              style={{ height: 60 }}
            >
              <GridContainer justifyContent="space-between" alignItems="center">
                <GridItem>
                  <h4 className={classes1.headerCdTitle}>
                  InTransit Report List
                  </h4>
                </GridItem>
                <GridItem style={{ cursor: "pointer" }}>
                   

                   <IconButton
                     variant="text"
                     onClick={() => onhandleExportToExcel(inTransit)}
                   >
                     <Tooltip title="Export to Excel">
                       <img
                         src={require("../../../assets/img/excel.png").default}
                       />
                     </Tooltip>
                   </IconButton>
                 </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody
              style={{ height: "auto", maxHeight: 480, padding: 10 }}
              className={clxs(classes.customScroolBar)}
            >
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-label="customized table"
                  id="table_total_distance_covered"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">#</StyledTableCell>
                      <StyledTableCell align="center">Advance Taken On</StyledTableCell>
                      <StyledTableCell align="center">Transaction No</StyledTableCell>                      
                      <StyledTableCell align="center">
                        Truck No
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Driver
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">Today Lorry No </StyledTableCell> */}
                      <StyledTableCell align="center">
                       Driver No
                      </StyledTableCell>
                     
                    </TableRow>
                  </TableHead>
                  {/* table Body start */}
                  <TableBody>
                    {inTransit.map((row, i) => (
                        console.log(row,"sankharow112"),
                     
                      
                          <StyledTableRow >
                            <StyledTableCell
                              align="left"
                              className={classes.id}
                            >
                              {i + 1}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.advance_date}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.transaction_no}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.vehicle_no}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.driver_name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.driver_no}
                            </StyledTableCell>
                          </StyledTableRow>
                      
                      
                     
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
};

export default InTransitReport;
