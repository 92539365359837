import webApi from "./webApi/webApi";

 export const postVehicleBrand = async (info,inserted_by_id, onSuccess, onFailure) => {
    try {
        
      const res = await webApi.post("/master/vehicle_brand/insert", {
            
        vehicle_brand: info.txt_vehicle_brand,
        
        details: info.txt_details,
        active_status: info.switch_active_status ? "Y" : "N",
        inserted_by_id:inserted_by_id,
      });
  
      if (res.status === 200) {
        const r = res.data;
      
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      // onFailure("Something Wrong! Please Try again later " + error);
    }
  };




export const getAllVehicleBrand = async (onSuccess, onFailure,q) => {
    try {
  
      const res = await webApi.post("/master/vehicle_brand/list", {
        keyword_pharse: q?.txt_keyword_pharse,
  
      });
      if 
      (res.status === 200)
       {
        const r = res.data;
         
  console.log(r,"sen1108/r")
        let all_vehiclesBrand = [];
        r.map((r, i) => {
            all_vehiclesBrand.push({
            id: i + 1,
            vehicle_brand_id:r.vehicle_brand_id,
            vehicle_brand:r.vehicle_brand,
            details: r.details,
            status: r.active_status,
            action_items: r.action_items,
            action: "action",
          });
        });
        return onSuccess(all_vehiclesBrand);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };



  //dispatch Vehicle by id
  export const getVehicleById = async (onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/vehicle/list", {
        group_id: 26
      });
      if (res.status === 200) {
        const r = res.data;
        let allVehicle = [];
        r.map((r, i) => {
          allVehicle.push({
  
            value: r.vehicle_id,
            label: r.vehicle_no,
          });
        });
        
        return onSuccess(allVehicle);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };



  export const deleteVehicle = async (id, onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/vehicle_brand/delete", {
        vehicle_brand_id: id,
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };



  export const updateVehicleBrand = async (info, onSuccess, onFailure) => {
    console.log(info,"from v service")
    try {
      const res = await webApi.post("/master/vehicle_brand/update", {
        vehicle_brand_id: info.vehicle_brand_id,
        vehicle_brand: info.txt_vehicle_brand,
        details: info.txt_details,
        active_status: info.switch_active_status ? "Y" : "N",
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };