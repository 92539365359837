import webApi from "./webApi/webApi";
import { currentDate } from "../../src/views/Pages/HelperComponent/utils";
import {
  dateFormateField,
  dateFormate,
} from "../../src/views/Pages/HelperComponent/utils";
import { timestamp } from "../services/Utils/utils";

export const getDailyTruckReport = async (addSearch, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/dailyTruck/list", {
      txt_from_date: timestamp(addSearch.txt_from_date),
    });

    if (res.status === 200) {
      const data = res.data;
      const { today, yesterday } = data;

      let allCusts = [];
      if (today || yesterday) {
        allCusts.push({
          today,
          yesterday,
        });
      }

      if (allCusts.length) {
        console.log(allCusts, "allCusts");
        return onSuccess(allCusts);
      } else {
        return onFailure("Data not found");
      }
    } else {
      return onFailure(
        "Something went wrong! Please try again later. " + res.data
      );
    }
  } catch (error) {
    return onFailure(
      "Something went wrong! Please try again later. " + error.message
    );
  }
};

//   export const getDailyTruckReport = async(onSuccess,onFailure)=>{
//     try {
//       const res = await webApi.post("/master/dailyTruck/list",{
//         txt_from_date:timestamp(addSearch.txt_from_date),
//       });

//       if(res.status === 200){
//         const r = res.data
//         let loading = []
//         r.map((r,i)=>{
//           console.log(r,"sank2502")
//           loading.push({

//           })
//         })

//         onSuccess(res.data)
//       }
//     } catch (error) {
//       onFailure("Something Went Wrong!!!!" + error)
//     }
//   }
