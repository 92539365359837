import React, { useRef, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from "react-router-dom";

const IdleTimerContainer = () => {
  const history = useHistory();
  const timeout = 60000 * 15;  //ms * min
  const [isIdle, setIsIdle] = useState(false)

  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => {

    console.log("user is Idle")
    setIsIdle(true)
    localStorage.removeItem("user_token")
    localStorage.removeItem("user_location")
    sessionStorage.removeItem("user_token")
    sessionStorage.removeItem("user_location")
    const token = sessionStorage.getItem("user_token")
    if (token === null) {
      history.push("/auth/login-page")
    }
  };

  const { getRemainingTime,
    getLastActiveTime,
    getElapsedTime } = useIdleTimer({
      timeout,
      onActive: handleOnActive,
      onIdle: handleOnIdle
    })

  return useIdleTimer
}

export default IdleTimerContainer;


// import React, { useRef } from 'react'
// import IdleTimer from 'react-idle-timer'
// import { useHistory } from "react-router-dom";

// const IdleTimerContainer = () => {
//     const idleTimerRef = useRef(null)
//     const history = useHistory();

//     const onIdle = () => {
//         console.log("user is idle")
//         localStorage.removeItem("user_token")
//         localStorage.removeItem("user_location")
//         sessionStorage.removeItem("user_token")
//         sessionStorage.removeItem("user_location")
//         const token = sessionStorage.getItem("user_token")
//         if(token === null){
//             history.push("/auth")
//         }
//     }

//   return (
//     <div>
//         <IdleTimer ref={idleTimerRef} timeout={900 * 1000} onIdle={onIdle}></IdleTimer>
//     </div>
//   )
// }

// export default IdleTimerContainer;