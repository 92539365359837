import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../../Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";

import { CustomCard } from "../../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../../Components/CustomButton";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ColoseButton } from "views/Components/CustomButton";

import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {

  postSalesCumDispatch,
  getAllCustomers,
  getAllSalesCumDispatch,
  getLoadedVehicle,
  viewSalesDispatch,
} from "../../../../services/salesSumDispatchService";

import {
  updateVehicle,

  getAllVehicle,
  deleteVehicle,
  getVehicleById,

} from "../../../../services/vehicleService";
// import { getAllVehicleType } from "../../../services/vehicleTypeService";

// import { getAllModule } from "../../../services/modulesService";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Box, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../../context/context";
import { actionTypes } from "../../../../context/reducer";
import theme from "../../../../theme/theme";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";

import React, { useState } from "react";
import PageTitle from "../../HelperComponent/PageTitle";
import {
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory,useLocation } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import IconButton from "@material-ui/core/Button";
import { currentDate, currentTime, dateFormate, IstTime } from "../../HelperComponent/utils";

const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const salesDispatchView = () => {
  const classes1 = useStyles1();
  const history = useHistory();
  const location = useLocation();

  const [modules, setAllModules] = React.useState([]);
  const [classicModal, setClassicModal] = React.useState(false);
  const [vehicle, setAllVehicle] = React.useState([]);
  const [salesCumDispatch, setAllSalesCumDispatch] = React.useState([]);

  const [loadedVehicle, setLoadedVehicle] = React.useState([]);
  const [allCustomer, setAllCustomer] = React.useState([]);

  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [value, setValue] = React.useState();
  const [addSalesSumDispatch, setAddSalesSumDispatch] = React.useState(
    {
      edit: false,
      ddl_loaded_vehicle_id: "",
      ddl_loaded_vehicle_label: "",
      ddl_customer_id: "",
      ddl_customer_label: "",
      check_gst: false,
      gst: '',
      gst_value: '',
      order_date: currentDate(),
      sale_date: currentDate(),
      dispatch_time: currentTime(),
      unloading_time: currentTime(),
      transaction_no: '',
      switch_active_status: true,
      salesCumDispatch_no: '',
      loading_id: '',
      ddl_qty_type_id: '',
      ddl_qty_type_label: '',
    }
  );
  const [error, setError] = React.useState({
    // ddl_loaded_vehicle: false,
    // ddl_customer: false,
    // ddl_qty_type: false,
    txt_rate: false,
  });
  
  const [successModal, setSuccessModal] = useState(false)
  const [collapsibleSalesman, setCollapsibleSalesman] = useState(true)
  const [collapsibleCustomer, setCollapsibleCustomer] = useState(true)
  const [collapsibleKm, setCollapsibleKm] = useState(true)
  const [collapsibleMaterial, setCollapsibleMaterial] = useState(true)

  const onClickCollapsSalesman = () => {
    collapsibleSalesman?
    setCollapsibleSalesman(false)
    :
    setCollapsibleSalesman(true)
  }

  const onClickCollapsCustomer = () => {
    collapsibleCustomer?
    setCollapsibleCustomer(false)
    :
    setCollapsibleCustomer(true)
  }

  const onClickCollapsKm = () => {
    collapsibleKm?
    setCollapsibleKm(false)
    :
    setCollapsibleKm(true)
  }

  const onClickCollapsMaterial = () => {
    collapsibleMaterial?
    setCollapsibleMaterial(false)
    :
    setCollapsibleMaterial(true)
  }




  const fontStyle = {
    fontFamily: "Sans-serif",
  };

  React.useEffect(() => {
    setLoading(true);
    viewSalesDispatch(
        location.salse_dispatch_id,
        (res)=>{
            console.log(res,"sen29102022")
            setAddSalesSumDispatch(res)
        },
        (err)=>{

        }
    )
  }, [location]);

  const onClickClose = () => {
    setRefresh(!refresh);
    // setOilDetails([]);
    history.push({
      pathname: "/admin/tranportation/sales-cum-dispatch",
      salse_dispatch_id:''
    });
  };

  
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Transportation > Sales/Dispatch"
      />
      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <CustomCard
            cdTitle="Sales/Dispatch"
            filterIcon
            onClickFilter={() => { }}
          >
            <GridContainer
              style={{ padding: "10px" }}
              justify="space-between"
              alignItems="center">
              <CustomCard
                cdTitle="Truck & Salesman Details"
               btnToolTip={collapsibleSalesman ? "Collaps" : "Expand"}
               onClickCollapsible={onClickCollapsSalesman}
               buttonAction={collapsibleSalesman}
               filterIcon
              >
                {collapsibleSalesman ? 
                <GridContainer
                  // style={{ padding: "10px" }}
                  justify="space-between"
                  alignItems="center">

                  <GridItem xs="3">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                      Loaded Vehicle
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="vehicle"
                      fullWidth={true}
                      value={addSalesSumDispatch.vehicle}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2" >
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                      Loaded Material
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="loaded_material"
                      fullWidth={true}
                      value={addSalesSumDispatch.loaded_material}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                    
                  </GridItem>
                  <GridItem xs="2" >
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                      Loaded Material Type
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="loaded_material_type"
                      fullWidth={true}
                      value={addSalesSumDispatch.loaded_material_type}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                      Order Date
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="loaded_material"
                      fullWidth={true}
                      value={ dateFormate(addSalesSumDispatch.order_date)}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="3">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Salesman
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="vehicle"
                      fullWidth={true}
                       value={addSalesSumDispatch.salesman_name ? addSalesSumDispatch.salesman_name : "-"}
                      // value={"needed to be fetched"}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                </GridContainer>
                : ""
              }
              </CustomCard>

              <CustomCard
               cdTitle="Customer Details"
               btnToolTip={collapsibleCustomer? "Collaps" : "Expand"} 
               onClickCollapsible={onClickCollapsCustomer}
               buttonAction={collapsibleCustomer}
               filterIcon
              >
                {collapsibleCustomer?
                
                <GridContainer
                  justify="space-between"
                  alignItems="center">
                  <GridItem xs="3">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                      Sales Date
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="sales_date"
                      fullWidth={true}
                      value={dateFormate(addSalesSumDispatch.sales_date)}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="3">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                      Customer Name
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="customer"
                      fullWidth={true}
                      value={addSalesSumDispatch.customer}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="3">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center",fontWeight: "bold" }}>
                      Customer Mobile
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="mobile_no"
                      fullWidth={true}
                      value={addSalesSumDispatch.mobile_no}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="3">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center",fontWeight: "bold" }}>
                      Credit Limit
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.credit_limit}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                </GridContainer>
                :""
                }
              </CustomCard>

              <CustomCard
              cdTitle="KM Details"
              btnToolTip={collapsibleKm? "Collaps" : "Expand"}
              onClickCollapsible={onClickCollapsKm}
              buttonAction={collapsibleKm}
              filterIcon
               >
                {collapsibleKm?
                
                <GridContainer
                  justify="space-between"
                  alignItems="center">
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center",fontWeight: "bold" }}>
                    KM End
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="sales_date"
                      fullWidth={true}
                      value={addSalesSumDispatch.km}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Customer Distance
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="customer"
                      fullWidth={true}
                      value={addSalesSumDispatch.customer_km}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Distance Covered
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="mobile_no"
                      fullWidth={true}
                      value={addSalesSumDispatch.distance_covered}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Dispatch Time *
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={IstTime(addSalesSumDispatch.dispatch_time)}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Unloading Time *
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={IstTime(addSalesSumDispatch.unloading_time)}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Remarks
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.remarks ? addSalesSumDispatch.remarks : "-"}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="6"></GridItem>
                </GridContainer>
                : " "
              }
              </CustomCard>


              <CustomCard
               cdTitle="Material Details"
               btnToolTip={collapsibleMaterial? "Collaps" : "Expand"}
               onClickCollapsible={onClickCollapsMaterial}
               buttonAction={collapsibleMaterial}
               filterIcon
                >
                  {collapsibleMaterial?
                
                <GridContainer
                  justify="space-between"
                  alignItems="center">
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Quantity Type
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="sales_date"
                      fullWidth={true}
                      value={addSalesSumDispatch.quantity_type}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                    {/* <Select
                    options={quantityType}
                    onChange={onChange}
                    autoFocus={true}
                    size="small"
                    formatGroupLabel={(d) => d.label}
                    placeholder="Select"
                    fullWidth={true}
                    variant="outlined"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    /> */}
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Approved Rate
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="customer"
                      fullWidth={true}
                      value={addSalesSumDispatch.actual_amount ? addSalesSumDispatch.actual_amount : "-"}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Discount %
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="mobile_no"
                      fullWidth={true}
                      value={addSalesSumDispatch.discount_per}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="3">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Discount Value
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.discount?(addSalesSumDispatch.discount).toFixed(2):""}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="3">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Discounted Rate
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.discountedRate}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                </GridContainer>
                : " "
                }
              </CustomCard>


               {addSalesSumDispatch.quantity_type === "LUMPSUM" ? 
              <CustomCard cdTitle="LUMPSUM" >
                
                <GridContainer
                  justify="space-between"
                  alignItems="center">
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Quantity
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="sales_date"
                      fullWidth={true}
                      value={addSalesSumDispatch.quantity}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Rate
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="customer"
                      fullWidth={true}
                      value={addSalesSumDispatch.rate}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Sale Amount
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="mobile_no"
                      fullWidth={true}
                      value={addSalesSumDispatch.sale_amount ? sale_amount : "-"}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    GST %
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.gst}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    GST Amount
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.gst_value}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Total
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.total_amount}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="6"></GridItem>
                </GridContainer>
              </CustomCard>
                : ""}

                {addSalesSumDispatch.quantity_type === "TON" ?
              <CustomCard cdTitle="TON" >
                
                <GridContainer
                  justify="space-between"
                  alignItems="center">
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    GROSS WEIGHT
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="sales_date"
                      fullWidth={true}
                      value={addSalesSumDispatch.grossWeight}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    LORRY WEIGHT	
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="customer"
                      fullWidth={true}
                      value={addSalesSumDispatch.lorryWeight}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    WATER LESS
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="mobile_no"
                      fullWidth={true}
                      value={addSalesSumDispatch.waterLess}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    NET WEIGHT
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.netWeight}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Rate
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.rate}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Sale Amount
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.sale_amount ? addSalesSumDispatch.sale_amount : "-"}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    GST %
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.gst ? addSalesSumDispatch.gst : "-"}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    GST
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.gst_value}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Total
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.total ? total : "-"}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="6"></GridItem>

                </GridContainer>
              </CustomCard>
              :""}

                {addSalesSumDispatch.quantity_type === "CFT" ? 
              <CustomCard cdTitle="CFT" >
                
                <GridContainer
                  justify="space-between"
                  alignItems="center">
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Width
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="sales_date"
                      fullWidth={true}
                      value={addSalesSumDispatch.width}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Length	
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="customer"
                      fullWidth={true}
                      value={addSalesSumDispatch.length}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "center",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Height
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="mobile_no"
                      fullWidth={true}
                      value={addSalesSumDispatch.height}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Total CFT
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.totalCft}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Rate
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.rate}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Sale Amount
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.sale_amount ? addSalesSumDispatch.sale_amount : "-"}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    GST %
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.gst_persent ? gst_persent : "-"}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    GST Amount
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.gst_amount ? addSalesSumDispatch.gst_amount : "-"}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="2">
                    <InputLabel required={false} id="label"
                     style={{ fontWeight: "bold", textAlign: "center" }}>
                    Total
                    </InputLabel>
                    <TextField
                      size="small"
                      // type="number"
                      name="credit_limit"
                      fullWidth={true}
                      value={addSalesSumDispatch.total ? total : "-"}
                      inputProps={{ style: { color: "Green", border: "none", textAlign: "right",fontWeight: "bold" } }}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs="6"></GridItem>

                </GridContainer>
              </CustomCard>
              : ""}


              <div style={{ width: "100%" }}>
                <ColoseButton
                  style={{ backgroundColor: "red", color: "white", float: "right", width: "10%", marginRight: "10px", border: "none" }}
                  onClick={onClickClose}>
                  Close
                </ColoseButton>
              </div>
            </GridContainer>
          </CustomCard>
        </GridItem >

      </GridContainer>

    
    </ThemeProvider>
  );
};

export default salesDispatchView;
