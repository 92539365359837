import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  updateMaterial,
  postMaterial,
  getAllMaterial,
  deleteVehicle,
} from "../../../services/materialService";
import { getVehicleById } from "../../../services/vehicleService";
import { getAllTyreBrandId } from "../../../services/tyreBrandService";

import { getAllModule } from "../../../services/modulesService";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Box, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import theme from "../../../theme/theme";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";

import React from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import IconButton from "@material-ui/core/Button";
import { currentDate } from "../HelperComponent/utils";
import { getTyreDetails } from "services/tyreDetailsService";
// import { getAllLocation } from "services/locationService";

const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const tyreDetailsPage = () => {
  const classes1 = useStyles1();
  const history = useHistory();
  const [modules, setAllModules] = React.useState([]);
  const [classicModal, setClassicModal] = React.useState(false);
  const [material, setAllMaterial] = React.useState([]);
  const [allType, setAllType] = React.useState([]);
  const [tyre, setAllTyreBrand] = React.useState([]);
  const [vehicle, setAllVehicle] = React.useState([]);
  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [value, setValue] = React.useState();
  const [addTyreDetails, setAddTyreDetails] = React.useState({
    edit: false,
    txt_keyword_pharse: "",
    tyre_details_id: "",
    ddl_vehicle_no_id: "",
    ddl_vehicle_no_label: "",
    txt_barcode: "",
    ddl_brand_id: "",
    ddl_brand_label: "",
    ddl_tube_id: "",
    ddl_tube_label: "",
    switch_active_status: true,
  });
  const [error, setError] = React.useState({
    ddl_vehicle_no: false,
    txt_barcode: false,
    ddl_brand: false,
    ddl_tube: false,
  });
  const [allTyreDetails, setAllTyreDetails] = React.useState([]);
  const userRole = globalState?.user?.user_role;


  const fontStyle = {
    fontFamily: "Sans-serif",
  };

  React.useEffect(() => {
    setLoading(true);

    getVehicleById(
      (vehicle) => {
        setAllVehicle(vehicle);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );

    getAllTyreBrandId(
      (tyre) => {
        setAllTyreBrand(tyre);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [refresh]);

  //options of no of wheels

  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddTyreDetails((prv) => ({ ...prv, [name]: value }));
  };
  const formData = {
    formName: "Add a Tyre Details",
    fields: [
      {
        name: "ddl_vehicle_no",
        value: addTyreDetails,
        label: "Vehicle No",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "select",
        error: false,
        options: vehicle,
        xs: 12,
        md: 12,
        lg: 12,
      },

      {
        name: "txt_barcode",
        label: "Barcode",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 4,
        md: 4,
        lg: 4,
      },
      {
        name: "ddl_brand",
        value: addTyreDetails,
        label: "Brand",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "select",
        error: false,
        options: tyre,
        xs: 4,
        md: 4,
        lg: 4,
      },
      {
        name: "ddl_tube",
        value: addTyreDetails,
        label: "Tube",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "select",
        error: false,
        options: tyre,
        xs: 4,
        md: 4,
        lg: 4,
      },

      {
        name: "switch_active_status",
        label: "Active Status",
        defaultValue: false,
        required: false,
        data_type: "string",
        html_element: "switch",
        error: false,
        xs: 6,
        md: 6,
        lg: 6,
      },
    ],
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onSetActive = (e) => {
    setAddTyreDetails((prv) => ({ ...prv, [e.target.name]: e.target.checked }));
  };

  const headerData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "vehicle_no",
      label: "Vehicle No",
    },
    {
      id: "wheel_details",
      label: "Wheel Details Avilable",
      align: "center",

    },
    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];

  const onSubmitModel = (e) => {
    e.preventDefault();
    if (!addTyreDetails.txt_material) {
      setError({
        ddl_vehicle_no: !addTyreDetails.ddl_vehicle_no,
        txt_material: !addTyreDetails.txt_material,
      });
    } else {
      if (addTyreDetails.edit) {
        updateMaterial(
          addTyreDetails,
          (r) => {
            setClassicModal(false);
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Material Updated Successfully",
                msgType: "success",
              },
            });
            setRefresh(!refresh);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
      } else {
        postMaterial(
          addTyreDetails,
          globalState?.user?.serial_id,
          (r) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Material added Successfully",
                msgType: "success",
              },
            });
            setClassicModal();
            setRefresh(!refresh);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setButtonDisabled(true);
          }
        );
      }
    }
  };
  // on Delete called
  const onDeleteVehicle = (row, next) => {
    deleteVehicle(
      row.vehicle_id,
      (r) => {
        next();
        setRefresh(!refresh);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  // on Edit called
  const onUpdate = (row) => {
    console.log( "2112202211",row)

    // setClassicModal(true);
    history.push({
      pathname:"/admin/master/TyreDetailsform",
      row:row,
      tyre_details_id:row.tyre_details_id,
      edit:true
  })
    // setAddTyreDetails({
    //   ...addTyreDetails,
    //   edit: true,
    //   switch_active_status: row.status === "Y" ? true : false,
    //   material_id: row.material_id,
    //   txt_material: row.material_name,
    //   txt_hsn: row.hsn,
    //   txt_gst: row.gst,
    //   ddl_vehicle_no: row.vehicle_no,
    //   location_id: row.location_id,
    //   txt_details: row.details,
    //   txt_from_date: row.effective_from_date,
    //   txt_to_date: row.effective_to_date,
    // });
  };

  const onClickView = (row)=>{
    console.log(row,"sankhagrow")
    history.push({
      pathname: "/admin/master/TyreDetailsView",
      row:row,
      tyre_details_id:row.tyre_details_id,
      view:true
    });
  }

  const onSelectModule = (event, value) => {
    console.log(event, "onselect", value.name);

    switch (value.name) {
      case "ddl_vehicle_no":
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_vehicle_no_id: event.value,
          ddl_vehicle_no_label: event.label,
          no_of_wheels: event.no_of_wheels,
        });
        break;
      case "ddl_brand":
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_brand_id: event.value,
          ddl_brand_label: event.label,
        });
        break;
      case "ddl_tube":
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_tube_id: event.value,
          ddl_tube_label: event.label,
        });
        break;
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    console.log(e.target.checked, "sen23", name, value);

    setAddTyreDetails({ ...addTyreDetails, [name]: value });
  };
  const classes = useStyles();

  // on Close Model
  const onCloseModel = () => {
    setClassicModal(false);
    setError({ txt_vehicle: false });
    setAddTyreDetails({
      edit: false,
      material_id: "",
      txt_keyword_pharse: "",
      txt_material: "",
      txt_details: "",
      txt_hsn: "",
      txt_gst: "",
      ddl_location_id: "",
      ddl_location_label: "",
      txt_from_date: currentDate(),
      txt_to_date: currentDate(),
      switch_active_status: true,
    });
  };
  const onSearchMaterial = (e) => {
    e.preventDefault();
    setLoading(true);

    getTyreDetails(
      (res)=>{
        console.log(res,"21122022")
        setAllTyreDetails(res)
        setLoading(false);

      },
      (err)=>{
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    )
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddTyreDetails({
      txt_keyword_pharse: "",

    });
  };

  // export to excel
  const onhandleExportToExcel = () => {
    const VehicleTable = material.map((acc) => {
      return {
        ID: acc.id,
        Vehicle_type: acc.vehicle_type,
        Vehicle_no: acc.vehicle_no,
        Contact_person: acc.contact_person,
        Contact_no: acc.contact_no,
        Details: acc.details,

        Status: acc.status,
        Action: "",
      };
    });

    const fileName = "Vehicle ";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(VehicleTable);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const onSelect = (event) => {
    console.log(event.target.name, "sen02", event.target.value)
    const { name, value } = event.target
    setAllTyreDetails({ ...allTyreDetails, [name]: value })

  }
  const onSelectTyreBrand = (event) => {

    console.log(event, "sen02", event)

    // setAllTyreDetails({...allTyreDetails,[`ddl_brand_`]:event.label,[`ddl_brand_id_`]:event.value})

  }
  const onSelectTyreTube = (event) => {
    console.log(event, "sen02", event)

    // setAllTyreDetails({...allTyreDetails,[`ddl_tube_`]:event.label,[`ddl_tube_id_`]:event.value})

  }
  // let getList = (no_of_wheels) => {
  //   if (no_of_wheels) {
  //     let tyreArray = [];

  //     for (var i = 1; i <= no_of_wheels; i++) {
  //       tyreArray.push(
  //         <>
  //           <GridItem xs="4" style={{ marginTop: 5 }}>
  //             <InputLabel id="label">
  //               Barcode
  //             </InputLabel>

  //             <TextField
  //               placeholder="BarCode"
  //               size="small"
  //               variant="outlined"
  //               id="outlined-basic"
  //               FormHelperTextProps={{
  //                 style: { textAlign: "right" },
  //               }}
  //               formatGroupLabel={(d) => d.label}
  //               // menuPortalTarget={document.body}
  //               className={classes.customSelect}
  //               onChange={onSelect}
  //               name={`txt_barcode_${i}`}
  //               value={allTyreDetails[`txt_barcode_${i}`]}
  //             />
  //           </GridItem>


  //           <GridItem xs="4">
  //             <InputLabel id="label">
  //               Brand
  //             </InputLabel>

  //             <Select
  //               options={tyre}
  //               placeholder="Select"
  //               formatGroupLabel={(d) => d.label}
  //               // menuPortalTarget={document.body}
  //               className={classes.customSelect}
  //               onChange={(event) => setAllTyreDetails({ ...allTyreDetails, [`ddl_brand_${i}`]: event.label, [`ddl_brand_id_${i}`]: event.value })}
  //               name={`ddl_brand_${i}`}
  //               value={allTyreDetails[`ddl_brand_${i}`]}
  //             />
  //           </GridItem>
  //           <GridItem xs="4">
  //             <InputLabel id="label">
  //               Tube
  //             </InputLabel>

  //             <Select
  //               options={tyre}
  //               placeholder="Select"
  //               formatGroupLabel={(d) => d.label}
  //               // menuPortalTarget={document.body}
  //               className={classes.customSelect}
  //               onChange={onSelectTyreTube}
  //               name={`ddl_tube_${i}`}
  //               value={allTyreDetails[`ddl_tube_${i}`]}
  //             />
  //           </GridItem>
  //         </>

  //       );
  //     }

  //     return (
  //       <GridContainer
  //         justifyContent="flex-start"
  //         alignItems="flex-end"
  //       >
  //         {
  //           tyreArray
  //         }
  //       </GridContainer>
  //     );
  //   }
  // }
  console.log(allTyreDetails, "sen0209")
  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Master > Tyre Details"
        btnToolTip="Add New Tyre Details"
        // onClickAddBtn={() => setClassicModal(true)}
        addBtnLink="/admin/master/TyreDetailsform"
      />
      <>
        <GridContainer className={classes.root}>
          <GridItem xs="12">
            <CustomCard cdTitle="Search Tyre Details">
              <Paper elevation="0" className={classes.searchBar}>
                <GridContainer
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <GridItem xs="6">
                    <InputLabel id="label">Keyword / Phrase</InputLabel>
                    <TextField
                      size="small"
                      placeholder="Keyword / Phrase"
                      style={{ marginBottom: -15 }}
                      type="search"
                      name="txt_keyword_pharse"
                      onChange={onAddSearch}
                      id="outlined-basic"
                      fullWidth={true}
                      value={addTyreDetails.txt_keyword_pharse}
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs="6">
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomButton
                        name="btn_refres"
                        style={{ marginRight: "10px" }}
                        onClick={onSearchMaterial}
                      >
                        <SearchIcon />
                      </CustomButton>
                      <CustomButton name="btn_refres" onClick={onClickRefresh}>
                        <RotateLeftIcon />
                      </CustomButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </Paper>
            </CustomCard>
          </GridItem>


{/* game */}
          <GridItem xs="12">
            {loading ? (
              <Box mt={10} width="100%" textAlign="center">
                <CircularProgress />
              </Box>
            ) : (
              <Card className={classes1.headerCard}>
                <CardHeader
                  className={classes1.TbheaderCdhd}
                  style={{ height: 60 }}
                >
                  <GridContainer
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <GridItem>
                      <h4 className={classes1.headerCdTitle}>Tyre Details</h4>
                    </GridItem>
                    <GridItem style={{ cursor: "pointer" }}>
                      <IconButton
                        variant="text"
                        onClick={() => onhandleExportToExcel(material)}
                      >
                        <Tooltip title="Export to Excel">
                          <img
                            src={
                              require("../../../assets/img/excel.png").default
                            }
                          />
                        </Tooltip>
                      </IconButton>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody
                  style={{ height: "auto", maxHeight: 480, padding: 10 }}
                  className={clxs(classes.customScroolBar)}
                >
                  <MuiTable
                    onClickEdit={onUpdate}
                    onClickDelete={onDeleteVehicle}
                    onClickViewOne={onClickView}
                    columns={headerData}
                    rows={allTyreDetails}
                userRole={userRole}

                  />
                </CardBody>
              </Card>
            )}
          </GridItem>
        </GridContainer>
      </>
    </ThemeProvider>
  );
};

export default tyreDetailsPage;
