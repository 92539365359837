import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";

export const postTyreFitting = async (
  TrackingDetails,
  inserted_by_id,
  onSuccess,
  onFailure
) => {
  try {
    console.log(TrackingDetails, "info");
    const res = await webApi.post("/master/tyreFitting/insert", {
      position_no_id: TrackingDetails.ddl_position_no_id,
      rejected_tyre:TrackingDetails.rejected_tyre,
      position_no_label: TrackingDetails.ddl_position_no_label,
      tyrecondition_no_id: TrackingDetails.ddl_tyrecondition_no_id,
      tyrecondition_no_label: TrackingDetails.ddl_tyrecondition_no_label,
      amount: TrackingDetails.txt_amount,
      barcode: TrackingDetails.txt_barcode,
      fitting_km: TrackingDetails.txt_fitting_km,
      model_name: TrackingDetails.txt_model_name,
      remarks: TrackingDetails.txt_remarks,
      tracking_date: TrackingDetails.txt_tracking_date,
      vehicle_brand_id: TrackingDetails.vehicle_brand_id,
      vehicle_brand_label: TrackingDetails.vehicle_brand_label,
      vehicle_id: TrackingDetails.vehicle_id,
      vehicle_no: TrackingDetails.vehicle_no,
      inserted_by_id: inserted_by_id,
    });

    if (res.status === 200) {
      const r = res.data;
      console.log(r, "r form service");
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    // onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const postRejectedTyre = async (
  rejectedDetailes,
  inserted_by_id,
  onSuccess,
  onFailure
) => {
  console.log(rejectedDetailes, "sen22122022");
  try {
    const res = await webApi.post("/master/rejectedTyre/insert", {
      vehicle_id: rejectedDetailes.vehicle_id,
      vehicle_no: rejectedDetailes.vehicle_no,
      remove_km: rejectedDetailes.txt_remove_km,
      barcode: rejectedDetailes.ddl_barcode_label,
      fitting_km: rejectedDetailes.txt_fitting_km,
      total_use_km: rejectedDetailes.txt_total_use,
      amount: rejectedDetailes.txt_amount,
      perKm_cost: rejectedDetailes.perKm_cost,
      company_name: rejectedDetailes.company_name,
      model_name: rejectedDetailes.txt_model_name,
      position: rejectedDetailes.position,
      tyrecondition: rejectedDetailes.txt_tyrecondition,
      remarks: rejectedDetailes.txt_remarks,
      inserted_by_id: inserted_by_id,
      rejected_date: timestamp(rejectedDetailes.txt_rejected_date),
    });
    const rejectRes = await webApi.post(
      "/master/rejectedTyre/rejectedTyreDetailsUpdate",
      {
        vehicle_id: rejectedDetailes.vehicle_id,
        barcode: rejectedDetailes.ddl_barcode_label,
        rejected_no: res.data.rejected_tyre_no,
        rejected_id: res.data.rejected_tyre_id,
      }
    );
    const rejectRes2 = await webApi.post(
      "/master/rejectedTyre/vehicleRejectedTyreUpdate",
      {
        vehicle_id: rejectedDetailes.vehicle_id,
      }
    );
    if (res.status === 200) {
      onSuccess(res.data);
     
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllVehicle = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/vehicleDropDownrejectedTyre/list", {});
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "list");

      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({
          value: r.vehicle_id,
          label: r.vehicle_no,
          km_start: r.km_start,
        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllVehicleBrand = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/vehicle_brand/list", {});
    if (res.status === 200) {
      const r = res.data;

      let all_vehiclesBrand = [];
      r.map((r, i) => {
        all_vehiclesBrand.push({
          value: r.vehicle_brand_id,
          label: r.vehicle_brand,
        });
      });
      return onSuccess(all_vehiclesBrand);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getMaintenanceExpenses = async (
  addSearch,
  onSuccess,
  onFailure
) => {
  try {
    const res = await webApi.post("/master/tyreFitting/list", {
      // vehicle:addSearch.txt_vehicle_no,
      // fromDate:timestamp(addSearch.txt_from_date),
      // toDate:timestamp(addSearch.txt_to_date),
      // maintenance_expenses_no:addSearch.txt_maintenance_expenses_no,
    });

    if (res.status === 200) {
      const r = res.data;
      let allData = [];
      r.map((r, i) => {
        allData.push({
          id: i + 1,
          date: r.tracking_date,
          vehicle_no: r.vehicle_no,
          vehicle_brand: r.vehicle_brand_label,
          txt_fitting_km: r.fitting_km,
          txt_model_name: r.model_name,
          ddl_position_no_label: r.position_no_label,
          ddl_tyrecondition_no_label: r.tyrecondition_no_label,
          txt_barcode: r.barcode,
          txt_remarks: r.remarks,
          txt_amount: r.amount,
        });
      });

      onSuccess(allData);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllBarcode = async (q, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/drop_down_barcode/list", {
      vehicle_id: q,
    });
    if (res.status === 200) {
      const r = res.data;
      let all_barcode = [];
      r.map((r, i) => {
        console.log(r, "sank2012");

        all_barcode.push({
          value: i,
          label: r.barcode,
          TyreFitting: r.TyreFitting_km,
          amount: r.amount,
          company_name: r.company_name,
          condition: r.condition,
          model: r.model,
          position: r.position,
        });
      });
      return onSuccess(all_barcode);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getRejectedDetails = async (addSearch,onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/rejectedTyre/list", {
      vehicle_no: addSearch.txt_vehicle_no,
      from_date: timestamp(addSearch.txt_from_date),
      to_date: timestamp(addSearch.txt_to_date) + 86399,

    });
    if (res.status === 200) {
      const r = res.data;
      let allData = [];
      r.map((r, i) => {
        allData.push({
          id: i + 1,
          rejected_tyre_id:r.rejected_tyre_id,
          date: dateFormate(r.rejected_date),
          vehicle_no: r.vehicle_no,
          barcode: r.barcode,
          tyrecondition: r.tyrecondition,
          action_items: r.action_items,
          remove_km: r.remove_km,
          fitting_km: r.fitting_km,
          total_use_km: r.total_use_km,
          amount: r.amount,
          perKm_cost: r.perKm_cost,
          company_name: r.company_name,
          model_name: r.model_name,
          position: r.position,
          remarks: r.remarks,

          action: "action",
        });
      });
      onSuccess(allData);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllVendorsById = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/vendor/list", {
      group_id: q?.ddl_group_id,
      keyword_pharse: q?.txt_keyword_pharse,
    });

    if (res.status === 200) {
      const r = res.data;
      let allVendors = [];
      r.map((v, i) => {
        console.log(r, "sen0909/search");
        allVendors.push({
          id: i + 1,
          value: v.vendor_id,
          label: v.company_name,
        });
      });

      if (allVendors.length) {
        return onSuccess(allVendors);
      } else {
        onFailure("vendor not found ");
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updateRejectedTyre = async (info, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/rejectedTyre/update", {
      rejected_tyre_id: info.rejected_tyre_id,

      vehicle_id: info.vehicle_id,
      vehicle_no: info.vehicle_no,
      remove_km: info.txt_remove_km,
      barcode: info.ddl_barcode_label,
      fitting_km: info.txt_fitting_km,
      total_use_km: info.txt_total_use,
      amount: info.txt_amount,
      perKm_cost: info.perKm_cost,
      company_name: info.company_name,
      model_name: info.model_name,
      position: info.position,
      tyrecondition: info.tyrecondition,
      remarks: info.txt_remarks,
      rejected_date: timestamp(info.txt_rejected_date),
    });

    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getRejectedTyre = async (id, onSuccess, onFailure) => {
  try {

    const res = await webApi.post("/master/rejectedTyre/details", {
      
      rejected_tyre_id: id,
      
    });
    console.log(res,"sankres")

    if
      (res.status === 200) {
      const r = res.data;

     

      return onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};


export const ViewRejectedTyre = async (id, onSuccess, onFailure) => {

  try {
    const res = await webApi.post("/master/rejectedTyre/viewDetails", {
      id: id
    })

    if (res.status === 200) {
      const r = res.data
      return onSuccess(r[0])

    }
    else {
      onFailure("Something Wrong! Please Try again later");

    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);

  }
}

