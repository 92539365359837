import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";
import { currentDate } from "../views/Pages/HelperComponent/utils";


export const getDispatchedVehicle = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/destination/dispatched_vehicle_list", {
      destination_status: false,

    });
    if
      (res.status === 200) {
      const r = res.data;

      console.log(r,"sen01")
      let all_vehicles = [];
      r.map((r, i) => {
        all_vehicles.push({

          //for ddl
          value: r.vehicle_id,
          label: r.vehicle_no,
          transaction_no:r.transaction_no,
          salesCumDispatch_no:r.salesCumDispatch_no,
          salse_dispatch_id:r.salse_dispatch_id,
          trip_no:r.trip_no,
        });
      });
      return onSuccess(all_vehicles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postExtraCharges = async (ExtraChargesDetails,extraChargesList, insertedById, onSuccess, onFailure) => {
  console.log(extraChargesList,"sank0911=")
  
  try {
    const res = await webApi.post("/master/extraCharges/insert", {
   
      vehicle_id:ExtraChargesDetails.ddl_vehicle_id,
      vehicle_no:ExtraChargesDetails.ddl_vehicle_no,
      transaction_no:ExtraChargesDetails.ddl_transaction_no,
      routes_name:ExtraChargesDetails.routes_name,
      
        
      extraCharges: extraChargesList,      
       inserted_by_id: insertedById,
    })
    console.log(res,"sank0911=>")
    if (res.status === 200) {
      const r = res.data;
     
      onSuccess(r);

    } else {
      onFailure("1Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("2Something Wrong! Please Try again later " + error);
  }
}

export const getAllExtraCharges = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/extraCharges/list", {
    });
    if
      (res.status === 200) {
      const r = res.data;


      let all_advance = [];
      let count = 0;
      r.map((r, i) => {
        r._id ?
          all_advance.push({
            id: count + 1,
            extraCharges_id:r.extraCharges_id,
            vehicle_id: r.vehicle_id,
            vehicle_no: r.vehicle_no,
            transaction_no:r.transaction_no,
            extraCharges_type:r.extraCharges?.ddl_extra_charges_type_name,
            action_items:r.action_items,
            action: "action",
          })

          : ''
      
  
        });

return onSuccess(all_advance);
      } else {
  onFailure("Something Wrong! Please Try again later" + res.data);
}
    } catch (error) {
  onFailure("Something Wrong! Please Try again later" + error);
}
  };

export const getSearchExtraCharges = async (addSearch, onSuccess, onFailure) => {
  console.log(addSearch, "sankh25081")
  try {

    const res = await webApi.post("/master/ExtraCharges/list", {
      vehicle_no: addSearch.txt_vehicle_no,
      // txt_from_date: timestamp(addSearch.txt_from_date),
      // txt_to_date: timestamp(addSearch.txt_to_date),

    });
    if
      (res.status === 200) {
      const r = res.data;

      let all_advance = [];
      r.map((r, i) => {
        console.log(r, "sen25")

        r._id ?
          all_advance.push({
            id: i + 1,
            vehicle_id: r.vehicle_id,
            vehicle_no: r.vehicle_no,
            received_time: r.received_time,
            received_date: r.received_date,
            unloading_time: r.unloading_time,
            action: "action",
          })
          : ''


      });

      return onSuccess(all_advance);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllAdvance = async (transaction_no,onSuccess, onFailure) => {
  try {

    const res = await webApi.post("/master/advance/list", {
      transaction_no:transaction_no,
    });
    if 
    (res.status === 200)
     {
      const r = res.data;
      let all_advance = [];
      r.map((r, i) => {
        all_advance.push({
          extra_expense_amount:r.extra_expense_amount,
        });
      });
      
      return onSuccess(all_advance[0]);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//expenses
export const getAllExpenses = async (onSuccess, onFailure,q) => {
  try {

    const res = await webApi.post("/master/expenses/list", {
      expenses_type_id: "E",

    });
    if 
    (res.status === 200)
     {
      const r = res.data;
       

      let all_expenses = [];
      r.map((r, i) => {
          all_expenses.push({
          value:r.expenses_id,
          label:r.expenses,
        });
      });
      return onSuccess(all_expenses);
      console.log(all_expenses,"sen1609")
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};




export const getListpolice = async (onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/police/list", {
     
      });
      if (res.status === 200) {
        const r = res.data;
        
        let arr = [];
        r.map((r, i) => {
          arr.push({
            value: r.policeStation_id,
            label: r.policeStation_name,
          });
        });
        return onSuccess(arr);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
  
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };


  export const getAllEmployee = async (onSuccess, onFailure, q) => {
    try {
     
      const res = await webApi.post("/master/employee/list", {
        // post:"4"
      });
     
      if (res.status === 200) {
        const r = res.data;
        let allEmployee = [];
        r.map((v, i) => {    
  
          allEmployee.push({
            id: i + 1,
            value: v.employee_id, 
            label: v.first_name, 
            post:v.post,
               
          
          });
        });
        
        if(allEmployee.length){
        return onSuccess(allEmployee);
      } 
      else {
        onFailure("Employee not found " );
      }
    } 
       else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  
  };

  export const getListParking = async (onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/Parking/list", {
     
      });
      if (res.status === 200) {
        const r = res.data;
        
        let arr = [];
        r.map((r, i) => {
          arr.push({
            value: r.Parking_id,
            label: r.Parking_name,
          });
        });
        return onSuccess(arr);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
  
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };


  export const getListMvl = async (onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/mvl/list", {
     
      });
      if (res.status === 200) {
        const r = res.data;
        
        let arr = [];
        r.map((r, i) => {
          arr.push({
            value: r.motor_vehicle_legislation_id,
            label: r.motor_vehicle_legislation_name,
          });
        });
        return onSuccess(arr);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
  
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };


  export const getListlab = async (onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/lab/list", {
     
      });
      if (res.status === 200) {
        const r = res.data;
        
        let arr = [];
        r.map((r, i) => {
          arr.push({
            value: r.lab_id,
            label: r.lab,
          });
        });
        return onSuccess(arr);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
  
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };


  export const getListCustomers = async (onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/customer/list", {});
      if (res.status === 200) {
        const r = res.data;
        let allCusts = [];
        r.map((c, i) => {
          console.log(c, "sen0507");
          if (c.contact_person[0]) {
            allCusts.push({
              value: c.customer_id,
              // label: toTitleCase(c.contact_person[0].txt_name),
              label: c.company_name,
            });
          }
        });
        // allCusts.sort((a, b) => a.label > b.label ? 1 : -1);
        return onSuccess(allCusts);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };

  export const getListkata = async (onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/kata/list", {
     
      });
      if (res.status === 200) {
        const r = res.data;
        
        let arr = [];
        r.map((r, i) => {
          arr.push({
            value: r.kata_id,
            label: r.kata,
          });
        });
        return onSuccess(arr);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
  
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };


  export const postFileUpload = async (file, onSuccess, onFailure) => {
    let formData = new FormData();
    formData.set("file", file);
    try {
      const res = await webApi.post(
        // "../assets/img",
        "https://api.gstspl.com/files/upload?uploadpath=image",
        formData
      );
      if (res.status === 200) {
        const r = res.data;
        if (r.success) {
          onSuccess(r);
        }
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };


  export const getAllVehicleById = async (onSuccess, onFailure) => {
    try {
  
      const res = await webApi.post("/master/vehicle/list", {
       
      });
      if
        (res.status === 200) {
        const r = res.data;
        console.log(r, "sen19102022/list")
  
        let all_vehicles = [];
        r.map((r, i) => {
          all_vehicles.push({
  
            //for ddl
            value: r.vehicle_id,
            label: r.vehicle_no,
           
          });
        });
        return onSuccess(all_vehicles);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };


  export const getAllTransaction = async (vehicle_id,onSuccess, onFailure) => {
    try {
  
      const res = await webApi.post("/master/oil/transaction", {

        vehicle_id:vehicle_id

  
      });
      if
        (res.status === 200) {
        const r = res.data;
  
        let all_oil = [];
        r.map((r, i) => { 
          all_oil.push({
             value:i,
             label:r.transaction_no,                                         
             routes_name: r.routes_name
           
  
          });
  
  
        });
  
        return onSuccess(all_oil);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };

  export const getAllRoutes = async (transaction_no,onSuccess, onFailure) => {
    try {
  
      const res = await webApi.post("/master/oil/transaction", {

 

  
      });
      if
        (res.status === 200) {
        const r = res.data;
  
        let all_oil = [];
        r.map((r, i) => { 
          all_oil.push({
             value:routes_id,
             label:r.routes_name,                                         
            transaction_no:r.transaction_no,            
  
          });
  
  
        });
  
        return onSuccess(all_oil);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };

  export const viewextraChargesDetails = async (id, onSuccess, onFailure) => {
    try {
      
      const res = await webApi.post("/master/extraCharges/viewextraChargesDetails", {
        extraCharges_id: id
      })
  
      if (res.status === 200) {
        const r = res.data
      console.log(r, "sen2110/r")

        return onSuccess(r[0])
  
      }
      else {
        onFailure("Something Wrong! Please Try again later");
  
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
  
    }
  }



  export const getExtraCharges = async (id, onSuccess, onFailure) => {
    try {
      
      const res = await webApi.post("/master/extraCharges/viewextraChargesDetails", {
        extraCharges_id: id
      })
  
      if (res.status === 200) {
        const r = res.data
      console.log(r, "sen2110/r")

        return onSuccess(r[0])
  
      }
      else {
        onFailure("Something Wrong! Please Try again later");
  
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
  
    }
  }



  export const updateExtraCharges = async (info,extraChargesList, onSuccess, onFailure) => {
    console.log(info,"sank0702")
    try {
      const res = await webApi.post("/master/extraCharges/update", {
        extraCharges_id: info.extraCharges_id,  
        vehicle_id:info.ddl_vehicle_id,
        vehicle_no:info.ddl_vehicle_no,
        transaction_no:info.ddl_transaction_no,
        routes_name:info.routes_name,       
          
        extraCharges: extraChargesList, 
     
      });
  
      if (res.status === 200) {

        const r = res.data;

        onSuccess(r);
        console.log(r,"sank06info")

      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };