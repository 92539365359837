import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";

import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  updateLocation,
  postExpenses,
  getAllExpenses,
  deleteVehicle
} from "../../../services/expensesService";

import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input,Box,Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import theme from "../../../theme/theme";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";

import React from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import  FileSaver from 'file-saver';
import XLSX from 'xlsx'
import IconButton from '@material-ui/core/Button';

const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
  
}));


const toTitleCase = (str) => {
  if(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  else {
    return "";
  }
  
}
const expensesPage = () => {
  const classes1 = useStyles1()
  const history = useHistory();
  const [modules, setAllModules] = React.useState([]);
  const [classicModal, setClassicModal] = React.useState(false);
  const [expenses, setAllExpenses] = React.useState([]);
  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const userRole = globalState?.user?.user_role;

  const [addExpenses, setAddExpenses] = React.useState({
    edit: false,
    expenses_id:"",
    txt_keyword_phrase:"",
    txt_expenses: "",
    txt_details: "", 
    ddl_expenses_type: "", 
    ddl_expenses_type_label:"Select",
    switch_active_status: true,

  });



  const [error, setError] = React.useState({
    txt_expenses: false,
    ddl_expenses_type: false,
    
  });

//Location type options as
  const expensesType = [
    { value: "E", label: "Extra" },
    { value: "A", label: "Actual" }
    

  ];


  const onAddSearch = (e) => {
    const { name, value } = e.target;
    if (name === "txt_expenses"  ) {
      setAddExpenses((prv) => ({
        ...prv,
        ["txt_expenses"]: toTitleCase(value),
       
      }));
    }else{
    setAddExpenses((prv) => ({ ...prv, [name]: value }));
    }
  };
  const formData = {
    formName: "Add a Expenses",
    fields: [
    
     
     
      {
        name: "ddl_expenses_type",
        value: addExpenses,
        label: "Expenses Type",
        placeholder: "Select Expenses Type",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "select",
        error: false,
        options: expensesType,
        xs: 6,
      },
      {
        name: "txt_expenses",
        label: "Expenses",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "TextField",
        error: false,
      },
      
     
      {
        name: "txt_details",
        label: "Details",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextArea",
        error: false,
      },
      {
        name: "switch_active_status",
        label: "Active Status",
        defaultValue: false,
        required: false,
        data_type: "string",
        html_element: "switch",
        error: false,
      },
    ],
  };

  React.useEffect(() => {
    setLoading(true);
    // get All Module
  

    // get All terms
    getAllExpenses(
      (expenses) => {
        setAllExpenses(expenses);
    setLoading(false);
      },
      (err) => {
    setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [refresh]);

  const onSetActive = (e) => {
    setAddExpenses((prv) => ({ ...prv, [e.target.name]: e.target.checked }));
  };

  const headerData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "expenses",
      label: "Expenses",

    },
    {
      id:"expenses_type",
      label: "Expenses Type",
    },
  
     
    {
      id: "details",
      label: "Details",
    },
    {
      id: "status",
      label: "Status",
      statusBtn: (v) => (v === "Y" ? true : false),
    },
    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];
  // onSubmit called
  const onSubmitModel = (e) => {

    e.preventDefault();
    if (!addExpenses.txt_expenses) {
      setError({
        txt_expenses: !addExpenses.txt_expenses ,
        ddl_expenses_type: !addExpenses.ddl_expenses_type

      });
    } else {
      if (addExpenses.edit) {
        updateLocation(
          addExpenses,
          (r) => {
            onCloseModel();
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Expenses Updated Successfully",
                msgType: "success",
              },
            });
            setRefresh(!refresh);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
      } else {
        postExpenses(
          addExpenses,
          globalState?.user?.serial_id,
          (r) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Expenses added Successfully",
                msgType: "success",
              },
            });
            onCloseModel();
            setRefresh(!refresh);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setButtonDisabled(true)
          }
        );
      }
    }
  };
  // on Delete called
  const onDeleteVehicle = (row, next) => {
    deleteVehicle(
      row.expenses_id,
      (r) => {
        next();
        setRefresh(!refresh);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  // on Edit called
  const onUpdateVehicle = (row) => {
    setClassicModal(true);
    setAddExpenses({
      ...addExpenses,
      
      edit: true,
      switch_active_status: row.status === "Y" ? true : false,
      expenses_id: row.expenses_id,
      txt_expenses: row.expenses,
      ddl_expenses_type: row.expenses_type,
      txt_details: row.details,
    
    });
  };

  const onSelectModule = (v) => {
    console.log(v,"sen23")
    if (v !== null) {
      setAddExpenses({
        ...addExpenses,
        ddl_expenses_type: v.value,
        ddl_expenses_type_label: v.label,
      });
    }
  };

  // const onSelectModule= (name, v) => {
  //   console.log(name,"7")
  //   console.log(v,"71")

  //   switch (name) {
 
  //       case "ddl_expenses_type":
  //         setAddExpenses({
  //           ...addExpenses,
  //           ddl_expenses_type: v.value,
  //           ddl_expenses_type_label: v.label,
  //         });
  //         break;


  //     default:
  //       break;
  //   }
  // };
  const onChange = (e) => {
    const { value, name } = e.target;
    setAddExpenses({ ...addExpenses, [name]: value });
  };
  const classes = useStyles();
  // on Close Model
  const onCloseModel = () => {
    setClassicModal(false);
    setError({ txt_expenses: false });
    setAddExpenses({
      ...addExpenses,
      edit: false,
      expenses_id: "",
      switch_active_status: false,
      txt_expenses: "",
      ddl_expenses_type_label:"",
      txt_details: "",
      // ddl_modules_id: 0,
      // ddl_modules_label: "Select Module",
    });
  };
  const onSearchLocation = (e) => {
    e.preventDefault();
    setLoading(true);
    if(addExpenses.txt_keyword_phrase)
      {
        
    getAllExpenses(
      (expenses) => {
        if (expenses.length) {     
          setAllExpenses(expenses);
          setLoading(false);
        } else {
          setLoading(false);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, "Customer not found": "info" },
          });
        }
      },
      (err) => {
        // change by sankha
        setAllExpenses([])
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
        setLoading(false);
      },
      addExpenses
    );
      }
      else{
        getAllExpenses(
          (expenses) => {
            if (expenses.length) {     
              setAllExpenses(expenses);
              setLoading(false);
            } else {
              setLoading(false);
              dispatch({
                type: actionTypes.SET_OPEN_MSG,
                payload: { msg: err, "Customer not found": "info" },
              });
            }
          },
          (err) => {
            // change by sankha
            setAllExpenses([])
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setLoading(false);
          },
          // addExpenses
        );
      }
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddExpenses({
      txt_keyword_phrase: "",
     
    });
  };


 // export to excel

const onhandleExportToExcel = () => {
  const VehicleTable = vehicle.map(acc => {
    return {
      ID: acc.id,
      Vehicle_type: acc.vehicle_type,
      Vehicle_no: acc.vehicle_no,
      Contact_person:acc.contact_person,
      Contact_no:acc.contact_no,
      Details:acc.details,
      
      Status: acc.status,
      Action:"",
      
    }
  })
  
 
  
  const fileName= 'Vehicle '
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(VehicleTable);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
}
  

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Master > Expenses"
        btnToolTip="Add New Expenses"
        onClickAddBtn={() => setClassicModal(true)}
      />
      <>
        <GridContainer className={classes.root}>
        <GridItem xs="12">
          <CustomCard cdTitle="Search Expenses">
            <Paper elevation="0" className={classes.searchBar}>
              <GridContainer justifyContent="flex-start" alignItems="flex-end">
             
                <GridItem xs="6">
                  <InputLabel id="label">Keyword / Phrase</InputLabel>
                  <TextField
                    size="small"
                    placeholder="Keyword / Phrase"
                    style={{ marginBottom: -15 }}
                    type="search"
                    name="txt_keyword_phrase"
                    onChange={onAddSearch}
                    id="outlined-basic"
                    fullWidth={true}
                    value={addExpenses.txt_keyword_phrase}
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs="6">
                  <div
                    style={{
                      float: "right",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomButton
                      name="btn_refres"
                      style={{ marginRight: "10px" }}
             
                      onClick={onSearchLocation}  

                    >
                      <SearchIcon />
                    </CustomButton>
                    <CustomButton name="btn_refres"
                    onClick={onClickRefresh} >
                      <RotateLeftIcon />
                    </CustomButton>
                  </div>
                </GridItem>
              </GridContainer>
            </Paper>
          </CustomCard>
        </GridItem>
        
          <MasterModel
            classicModal={classicModal}
            onCloseModel={onCloseModel}
            width={450}
            height="auto"
            okBtnText="Submit"
            modelName={addExpenses.edit ? "Edit Expenses" : "Add Expenses"}
            onClickOk={onSubmitModel}
            disabled={buttonDisabled}
          >
            <GridContainer>
              <div style={{ padding: "20px 10px", width: "100%" }}>
                {formData.fields.map((item, key) => {
                  return (
                    <>
                      <GridItem xs={12} key={key}>
                        {item.html_element === "select" && (
                          <>
                            <InputLabel id="label">{item.label}</InputLabel>
                            <Select
                              options={item.options}
                              placeholder="Select Module"
                              formatGroupLabel={(d) => d.label}
                              // menuPortalTarget={document.body}
                              className={classes.customSelect}
                              onChange={onSelectModule}
                              value={{
                                value: addExpenses.ddl_expenses_type,
                                label: addExpenses.ddl_expenses_type_label,
                              }}
                            />
                          </>
                        )}
                        {item.html_element === "TextField" && (
                          <>
                            <InputLabel required={item.required} id="label">
                              {item.label}
                            </InputLabel>
                            <TextField
                              size="small"
                              required={item.required}
                              placeholder={item.label}
                              name={item.name}
                              onChange={onChange}
                              id="outlined-basic"
                              error={error[item.name]}
                              FormHelperTextProps={{
                                style: { textAlign: "right" },
                              }}
                              helperText={
                                error[item.name]
                                  ? item.label + "  is required"
                                  : ""
                              }
                              fullWidth={true}
                              value={addExpenses[item.name]}
                              variant="outlined"
                            />
                         
                          </>
                        )}
                        {item.html_element === "TextArea" && (
                          <>
                            <InputLabel id="label">{item.label}</InputLabel>

                            <TextField
                              placeholder={item.label}
                              name={item.name}
                              onChange={onChange}
                              multiline
                              style={{ marginBottom: -10 }}
                              rows={5}
                              id="outlined-basic"
                              fullWidth={true}
                              value={addExpenses[item.name]}
                              variant="outlined"
                            />
                          </>
                        )}
                        {item.html_element === "switch" && (
                          <>
                            <span className={classes.activeText}>
                              {item.label}
                            </span>
                            <Switch
                              onClick={onSetActive}
                              checked={addExpenses[item.name]}
                              name={item.name}
                              fullWidth={true}
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              color="primary"
                            />
                          </>
                        )}
                      </GridItem>
                    </>
                  );
                })}
              </div>
            </GridContainer>
          </MasterModel>

          <GridItem xs="12">
          {loading ? (
            <Box mt={10} width="100%" textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Card className={classes1.headerCard}>
            <CardHeader className={classes1.TbheaderCdhd} style={{ height: 60 }}>
              <GridContainer justifyContent="space-between" alignItems="center">
                <GridItem>
                  <h4 className={classes1.headerCdTitle}>Expenses</h4>
                </GridItem>
                <GridItem style={{ cursor: "pointer" }}>
                  <IconButton variant="text" onClick={() => onhandleExportToExcel(vehicle)} >
                    <Tooltip title="Export to Excel">
                      <img src={require("../../../assets/img/excel.png").default} />
                    </Tooltip>
                  </IconButton>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody
              style={{ height: "auto", maxHeight: 480, padding: 10 }}
              className={clxs(classes.customScroolBar)}
            >
              <MuiTable
                onClickEdit={onUpdateVehicle}
                onClickDelete={onDeleteVehicle}
                columns={headerData}
                rows={expenses}
                userRole={userRole}

              />
           </CardBody>
              </Card>
          )}
           
          </GridItem>
        </GridContainer>
      </>
    </ThemeProvider>
  );
};

export default expensesPage;
