import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton from "../../Components/CustomButton";

import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import { Paper } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { currencyFormate } from "views/Pages/HelperComponent/utils";
//Tables
import {
  appFontWeightThin,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "6px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "0px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  customSelect: {
    marginBottom: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
    "&:hover": {
      backgroundColor: "rgba(43,43, 43, 0.03)",
    },
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);
//Theme
import theme from "../../../theme/theme";
//Services

// import React from "react";
import {
  appSecondColor,
  reactSelectStyles
} from "assets/jss/material-dashboard-pro-react";
import { appDefaultFamily } from "assets/jss/material-dashboard-pro-react";
import React, { useState } from "react";
import PageTitle from "../HelperComponent/PageTitle";
import { currencyFormateNew, currentDate } from "../HelperComponent/utils";
//SERVICE

import { getSalesStat } from "services/totalStats";
import { getReceivedToday } from "services/DashboardService";
import { getReceivedTotal } from "services/DashboardService";

import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import CardBody from "components/Card/CardBody.js";
import clxs from "classnames";
import { getAllVehicles} from "services/totalDistanceCoveredService";

import ReactSelect from "react-select";
import { getDailySummary } from "../../../services/dailyReportSummaryService";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { IconButton, OutlinedInput } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles1 = makeStyles(styles);


const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  narraction: {
    width: 155,
  },
  date: {
    width: 88,
  },
  voucher: {
    width: 190,
  },
  particular: {
    width: 110,
  },

  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));


const DailyReportSummary = () => {
  const [globalState, dispatch] = useStateValue();

  const [addSearch, setAddSearch] = React.useState({
   
    txt_from_date: currentDate(),
  });
  const [refresh, setRefresh] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [collapsible, setCollapsible] = React.useState(true);
  const [vehicleNumber, setvehicleNumber] = React.useState([]);
  const [dailyReport, setDailyReport] = React.useState([]);

  

  const [salesStats, setSalesStats] = useState(0);
  const [expensesToday, setExpensesToday] = useState(0);

  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddSearch((prv) => ({ ...prv, [name]: value }));
  };


  const onSearch = () => {
    setLoading(true);
  
    getDailySummary(
      addSearch,
      (res) => {
      console.log(res,"sankhares")

        setDailyReport(res)
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "success" },
        });
      }
    )
  };


  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddSearch({
     
      txt_from_date: currentDate(),
     
    });
  };
 

  const classes = useStyles();
  const classes1 = useStyles1();


  const onClickCollaps = () => {
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };


   // export to excel
   const onhandleExportToExcel = () => {
    const DailyReportTable = dailyReport.map(Payment => {
      return {
        // ID: Payment.payID,
        Today_Expences: Payment.totalExpenseAndAmount,
        Today_Sales: Payment.salesData[0]?.totalAmount,
        Unloading_Pending:Payment.unlodingdata[0]?.totalTripCount,      
        Yesterday_Unloading:Payment.yesterdayunlodingdata?.totalTripCount,        
        Previous_Unloading: Payment.previousdayUnlodingData[0]?.totalTripCount,
        Today_Received_Amount:Payment.totalRecAmount,
        Today_Due:Payment.todayOutstanding     
        
      }
    })      
    
    const fileName= 'Daily Report List'
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(DailyReportTable);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <ThemeProvider theme={theme}>
      <PageTitle title="MIS Report > Daily Report summary" />

      <GridContainer>
        <GridItem xs="12">
          <CustomCard
            cdTitle="Search Daily Report summary"
            btnToolTip={collapsible ? "Collaps" : "Expand"}
            onClickCollapsible={onClickCollaps}
            buttonAction={collapsible}
            filterIcon
          >
            {collapsible ? (
              <Paper elevation="0" className={classes.searchBar}>
                <GridContainer justifyContent="flex-start" alignItems="center">
                    {/* <GridItem xs="4">
                      <InputLabel id="label">Vehicle No.</InputLabel>
                      <ReactSelect
                        options={vehicleNumber}
                        getOptionLabel={(option) => option.label}
                        placeholder="Select"
                        formatGroupLabel={(d) => d.label}
                        menuPortalTarget={document.body}
                        className={classes.customSelect}
                        styles={reactSelectStyles}
                        name="ddl_vehicle_id"
                        onChange={(v, info) => onSelect(info, v)}
                      />
                    </GridItem> */}

                  <GridItem xs="2">
                    <InputLabel id="label">Date</InputLabel>
                    <TextField
                      name="txt_from_date"
                      size="small"
                      id="date"
                      variant="outlined"
                      type="date"
                      fullWidth={true}
                      value={addSearch.txt_from_date}
                      onChange={onAddSearch}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </GridItem>

                  

                  <GridItem xs="12">
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <CustomButton
                        style={{ marginRight: "10px" }}
                        onClick={onSearch}
                      >
                        <SearchIcon />
                      </CustomButton>
                      <CustomButton  onClick={onClickRefresh}>
                        <RotateLeftIcon />
                      </CustomButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </Paper>
            ) : (
              ""
            )}
          </CustomCard>
        </GridItem>

        <GridItem xs="12">
        <Card className={classes1.headerCard}>
              <CardHeader
                className={classes1.TbheaderCdhd}
                style={{ height: 60 }}
              >
                <GridContainer justifyContent="space-between" alignItems="center">
                  <GridItem>
                    <h4 className={classes1.headerCdTitle}>Daily Report List</h4>
                  </GridItem>
                  <GridItem style={{ cursor: "pointer" }}>
                   

                   <IconButton
                     variant="text"
                     onClick={() => onhandleExportToExcel(dailyReport)}
                   >
                     <Tooltip title="Export to Excel">
                       <img
                         src={require("../../../assets/img/excel.png").default}
                       />
                     </Tooltip>
                   </IconButton>
                 </GridItem>
              
                </GridContainer>
              </CardHeader>
          {/* <CustomCard cdTitle="Daily Report List">/ */}
            <CardBody
              style={{ height: "auto", maxHeight: 480, padding: 10 }}
              className={clxs(classes.customScroolBar)}
            >
              <TableContainer className={classes.container}>
                <Table
                  className={classes.table}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">#</StyledTableCell>
                      <StyledTableCell align="center">
                        Today Expences
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Today Extra Expences
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Today Sales
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Unloading Pending
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Yesterday Unloading
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Previous Unloading
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Today Received Amount
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Today Due
                      </StyledTableCell>
                      {/* <StyledTableCell align="left">
                        Today lorry expense
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Extra expense
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Today total received
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Today due amount
                      </StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {/* { console.log(dailyReport,"roesankha") } */}
                  
                  {dailyReport &&  dailyReport.map ((row,i)=>(

                    
                    <StyledTableRow key={i}>
                      <StyledTableCell align="center">
                      {row.length != 0 ? i + 1 : ""}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                    
                        {row.totalExpenseAndAmount ? currencyFormate(row.totalExpenseAndAmount) : 0}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                     
                    {row.ledgerData[0]?.totalAmount ?  row.ledgerData[0]?.totalAmount : 0}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                      {row.salesData[0]?.totalAmount ? currencyFormate(row.salesData[0]?.totalAmount): 0}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                      {row.unlodingdata[0]?.totalTripCount ? row.unlodingdata[0]?.totalTripCount: 0}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                      {row.yesterdayunlodingdata?.totalTripCount ? row.yesterdayunlodingdata?.totalTripCount: 0}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                      {row.previousdayUnlodingData[0]?.totalTripCount ? row.previousdayUnlodingData[0]?.totalTripCount: 0}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {row.totalRecAmount ? currencyFormate(row.totalRecAmount):0}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.todayOutstanding ? currencyFormate(row.todayOutstanding): 0}
                      </StyledTableCell>
                     
                      </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardBody>
          {/* </CustomCard> */}
          </Card>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
};

export default DailyReportSummary;
