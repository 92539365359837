import React, { useEffect, useState } from "react";
import Select from "react-select";

//reducers
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { InputLabel, TextField, ThemeProvider } from "@material-ui/core";
import PageTitle from "../HelperComponent/PageTitle";
import {
  currentDate,
  currentDate1,
  currentTime,
} from "views/Pages/HelperComponent/utils";
import {
  appDefaultColor,
  appSecondColor,
  whiteColor,
  reactSelectStyles,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import MuiTable from "../../Components/MuITable";
import { activeText } from "assets/jss/material-dashboard-pro-react";

//Model window
import MasterModel from "views/Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";
//Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CircleAddBtn } from "views/Components/CustomButton";
import Button from "components/CustomButtons/Button.js";
import { CustomCard } from "views/Components/CustomCard";
import { Paper } from "@mui/material";
import CustomButton from "views/Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

//service
// import { getDispatchedVehicle, getAllExpenses, getAllAdvance } from "services/destinationService";
// import { postDestination, getAllDestination, getSearchDestination } from "services/destinationService";
import { postTracking, getTrackingVehicle } from "services/trackingService";
import { ColoseButton } from "views/Components/CustomButton";
import { vehicleTracking } from "services/trackingService";
import { dateFormate, time, timeDiff } from "views/Pages/HelperComponent/utils";
import { timeToUnix } from "views/Pages/HelperComponent/utils";
import { IstTime } from "views/Pages/HelperComponent/utils";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import {
  excessTime,
  timeChevronCheck,
} from "views/Pages/HelperComponent/utils";
import { getAllVehicle } from "services/tyreFittingService";
import {
  getAllVehicleBrand,
  postTyreFitting,
} from "services/RejectedTyreService";
import { getAllBarcode } from "services/RejectedTyreService";
import { postRejectedTyre } from "services/RejectedTyreService";
import { useLocation } from "react-router-dom";
import { getRejectedTyre } from "services/RejectedTyreService";
import { updateRejectedTyre } from "services/RejectedTyreService";
import { ViewRejectedTyre } from "services/RejectedTyreService";

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  floatAddBtn: {
    position: "fixed",
    top: 90,
    right: 40,
    zIndex: 1000,
  },
  pageTitleBox: {
    color: appDefaultColor,
    // color: appSecondColor,
    fontSize: "14px",
    fontWeight: appFontWeight,
    paddingLeft: "20px",
  },
  topHeaderTitle: {
    backgroundColor: whiteColor,
    height: "auto",
    padding: "5px 20px",
    margin: "-20px -30px 10px -30px",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },
  ddlError1: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -12,
    fontWeight: 300,
  },
}));
const useStyles1 = makeStyles((styles) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const options = [
  {
    label: "FORNT-LEFT",
    value: 1,
  },
  {
    label: "FORNT-RIGHT",
    value: 2,
  },
  {
    label: "BACK-LEFT",
    value: 3,
  },
  {
    label: "BACK-RIGHT",
    value: 4,
  },
];

const condition = [
  {
    label: "10%",
    value: 1,
  },
  {
    label: "20%",
    value: 2,
  },
  {
    label: "30%",
    value: 3,
  },
  {
    label: "40%",
    value: 4,
  },
  {
    label: "50%",
    value: 5,
  },
  {
    label: "60%",
    value: 6,
  },
  {
    label: "70%",
    value: 7,
  },
  {
    label: "80%",
    value: 8,
  },
  {
    label: "90%",
    value: 9,
  },
  {
    label: "100%",
    value: 10,
  },
];

const RejectedTyreView = () => {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();
  const classs = useStyles1();
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [globalState, dispatch] = useStateValue();
  const [successModal, setSuccessModal] = useState(false);
  const [classicModel, setClassicModel] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [RejectedDetailes, setRejectedDetailes] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [addSearch, setAddSearch] = useState({
    txt_vehicle_no: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });
  const [vehicle, setVehicle] = useState([]);
  const [vehicleBrand, setVehicleBrand] = useState([]);

  const [error, setError] = React.useState({
    vehicle_id: false,
    ddl_barcode_id: false,
    txt_amount: false,
    txt_model_name: false,
  });
  const [collapsible, setCollapsible] = useState(true);
  const [collapsibleunloading, setCollapsibleunloading] = useState(true);
  const [selectedExpense, setSelectedExpense] = useState([]);
  const [transactionData, setTransactionData] = useState();
  const onClickCollaps = () => {
    console.log("Reached23");
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };
  const [barcode, setAllbarcode] = useState([]);

  React.useEffect(() => {
    console.log(location, "sanklocation");
    const id = location?.oil_id;
    ViewRejectedTyre(
      id,
      (r) => {
        console.log(r, "sanklocation");

        setRejectedDetailes(r);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [refresh]);

  const onCloseModel = () => {
    setClassicModel(false);
    setRejectedDetailes({
      edit: false,
      inFlow: false,
      vehicle_no: "",

      txt_remarks: "",
    });
    history.push({
      pathname: "/admin/maintence/rejectedTyre",
    });
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setRejectedDetailes({
      edit: false,
      inFlow: false,
      vehicle_no: "",
    });
  };

  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddSearch((prv) => ({ ...prv, [name]: value }));
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Transportation > Rejected Tyre "
        btnToolTip="Add TyreFitting"
        // onClickAddBtn={onClickAddDestination}
      />

      {/* On Click Model Window Open */}
      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <CustomCard cdTitle="" filterIcon onClickFilter={() => {}}>
            <GridContainer
              style={{ padding: "10px" }}
              justify="space-between"
              alignItems="center"
            >
              <CustomCard
                cdTitle="Rejected Tyre "
                btnToolTip={collapsible ? "Collaps" : "Expand"}
                onClickCollapsible={onClickCollaps}
                buttonAction={collapsible}
                filterIcon
              >
                {collapsible ? (
                  <GridContainer justify="space-between" alignItems="center">
                    <GridItem xs={3}>
                      <InputLabel required={false} id="label">
                        Date
                      </InputLabel>

                      <TextField
                        size="small"
                        name="txt_rejected_date"
                        variant="outlined"
                        fullWidth={true}
                        inputProps={{ style: { textAlign: "left" } }}
                        FormHelperTextProps={{ style: { textAlign: "left" } }}
                        value={dateFormate(RejectedDetailes.rejected_date)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // onClick={(e) => {
                        //   e.target.select();
                        // }}
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">
                        Truck No.
                      </InputLabel>
                      <Select
                        options={vehicle}
                        placeholder="vehicle no"
                        formatGroupLabel={(d) => d.label}
                        className={classes.customSelect}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        name="ddl_vehicle_no"
                        value={{
                          value: RejectedDetailes.vehicle_id,
                          label: RejectedDetailes.vehicle_no,
                        }}
                      />
                      {error.vehicle_id && (
                        <div className={classes.ddlError1}>
                          Vehicle is Required
                        </div>
                      )}
                    </GridItem>
                    <GridItem xs={2}>
                      <InputLabel required={true} id="label">
                        Remove KM
                      </InputLabel>
                      <TextField
                        size="small"
                        type="String"
                        name="txt_remove_km"
                        // onChange={onChange}
                        placeholder="km"
                        id="outlined-basic"
                        fullWidth={true}
                        variant="outlined"
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                          },
                        }}
                        value={RejectedDetailes.remove_km}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">
                        Barcode No
                      </InputLabel>
                      <TextField
                        options={barcode}
                        placeholder="barcode"
                        size="small"
                        variant="outlined"
                        className={classes.customSelect}
                        name="ddl_barcode_no"
                        value={RejectedDetailes.barcode}
                      />
                    </GridItem>

                    <GridItem xs={2}>
                      <InputLabel required={true} id="label">
                        Fitting KM
                      </InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        name="txt_fitting_km"
                        // onChange={onChange}
                        placeholder="km"
                        id="outlined-basic"
                        fullWidth={true}
                        variant="outlined"
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                          },
                        }}
                        value={RejectedDetailes.fitting_km}
                        disabled
                      />
                    </GridItem>

                    <GridItem xs={2}>
                      <InputLabel required={true} id="label">
                        Total Use KM
                      </InputLabel>
                      <TextField
                        size="small"
                        type="String"
                        name="txt_total_use"
                        //  onChange={onChange}
                        placeholder="km"
                        id="outlined-basic"
                        fullWidth={true}
                        value={parseFloat(
                          RejectedDetailes.total_use_km
                        ).toFixed(2)}
                        variant="outlined"
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                          },
                        }}
                        disabled
                      />
                    </GridItem>

                    <GridItem xs={2}>
                      <InputLabel required={false} id="label">
                        Amount
                      </InputLabel>
                      <TextField
                        size="small"
                        type="Number"
                        name="txt_amount"
                        id="outlined-basic"
                        fullWidth={true}
                        value={RejectedDetailes.amount}
                        FormHelperTextProps={{
                          style: { textAlign: "right" },
                        }}
                        variant="outlined"
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                          },
                        }}
                        disabled
                      />
                      {error.txt_amount && (
                        <div className={classes.ddlError1}>
                          Amount is Required
                        </div>
                      )}
                    </GridItem>
                    <GridItem xs={2}>
                      <InputLabel required={false} id="label">
                        Per Km Cost
                      </InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        name="perKm_cost"
                        id="outlined-basic"
                        fullWidth={true}
                        // onChange={onChange}
                        placeholder="Per Km Cost"
                        value={parseFloat(RejectedDetailes.perKm_cost).toFixed(
                          2
                        )}
                        FormHelperTextProps={{
                          style: { textAlign: "right" },
                        }}
                        variant="outlined"
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                          },
                        }}
                        disabled
                      />
                    </GridItem>

                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">
                        Company Name
                      </InputLabel>
                      <TextField
                        size="small"
                        name="company_name"
                        id="outlined-basic"
                        fullWidth={true}
                        // onChange={onChange}
                        placeholder="company name"
                        value={RejectedDetailes.company_name}
                        FormHelperTextProps={{
                          style: { textAlign: "right" },
                        }}
                        variant="outlined"
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                          },
                        }}
                        disabled
                      />
                    </GridItem>

                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">
                        Model Name
                      </InputLabel>
                      <TextField
                        size="small"
                        type="String"
                        name="txt_model_name"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={RejectedDetailes.model_name}
                        variant="outlined"
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                          },
                        }}
                        disabled
                      />
                    </GridItem>

                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">
                        Position
                      </InputLabel>
                      <TextField
                        size="small"
                        type="String"
                        name="position"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={RejectedDetailes.position}
                        variant="outlined"
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                          },
                        }}
                        disabled
                      />
                    </GridItem>

                    <GridItem xs={2}>
                      <InputLabel required={true} id="label">
                        Tyre Condition
                      </InputLabel>
                      <TextField
                        size="small"
                        type="String"
                        name="txt_tyrecondition"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={RejectedDetailes.tyrecondition}
                        variant="outlined"
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <InputLabel required={true} id="label">
                        Remarks
                      </InputLabel>
                      <TextField
                        size="small"
                        type="String"
                        name="txt_remarks"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        value={RejectedDetailes.remarks}
                        variant="outlined"
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
              </CustomCard>

              <div style={{ width: "100%" }} className={classes.actionbtns}>
                <ColoseButton
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    float: "right",
                    width: "10%",
                    marginRight: "10px",
                    border: "none",
                  }}
                  onClick={onCloseModel}
                >
                  close
                </ColoseButton>
              </div>
            </GridContainer>
          </CustomCard>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
};

export default RejectedTyreView;
