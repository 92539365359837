import webApi from "./webApi/webApi";
import { currentDate } from "../../src/views/Pages/HelperComponent/utils";
import {
  dateFormateField,
  dateFormate,
} from "../../src/views/Pages/HelperComponent/utils";
import {timestamp} from '../services/Utils/utils';





// export const getDailySummary = async (onSuccess, onFailure) => {
//   try {
//     const res = await webApi.post("/reports/dailyReportList/list", {});
//     if (res.status === 200) {
//       const r = res.data;
     
//       let allSummary = [];
//       r.map((r, i) => {
//       console.log(r,"Daily1")

    
//       });
//       console.log(allSummary,"allSummary")
//       return onSuccess(allSummary);
//     } else {
//       onFailure("Something Wrong! Please Try again later" + res.data);
//     }
//   } catch (error) {
//     // onFailure("Something Wrong! Please Try again later" + error);
//   }
// };



// export const getDailySummary = async ( onSuccess, onFailure) => {
//   try {
//     const res = await webApi.post("/reports/dailyReportList/list", {
     
//     });
//     if (res.status === 200) {
//       const r = res.data;
//       let allCusts = [];
//       r.map((c, i) => {
//         console.log(c,"sankhacom")
//         allCusts.push({
          

         
//         });
//       });
//       if (allCusts.length) {
//         return onSuccess(allCusts);
//       } else {
//         onFailure("Customer not Found ");
//       }
//     } else {
//       onFailure("Something Wrong! Please Try again later " + res.data);
//     }
//   } catch (error) {
//     onFailure("Something Wrong! Please Try again later " + error);
//   }
// };


export const getDailySummary = async ( addSearch,onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/reports/dailyReportList/list", {
      txt_from_date:timestamp(addSearch.txt_from_date),
    });

    if (res.status === 200) {
      const data = res.data;
      const {
        salesData,
        advancedata,
        total_Received,
        total_ReceiptSalesVehicle,
        totalRecAmount,
        todayOutstanding,
        unlodingdata,
        yesterdayunlodingdata,
        previousdayUnlodingData,
        totalExpenseAndAmount,
        ledgerData
      } = data;

      
      let allCusts = [];
      if (salesData) {
        allCusts.push({
          salesData,
          advancedata,
          total_Received,
          total_ReceiptSalesVehicle,
          totalRecAmount,
          todayOutstanding,
          unlodingdata,
          yesterdayunlodingdata,
          previousdayUnlodingData,
          totalExpenseAndAmount,
          ledgerData
        });
      }

      if (allCusts.length) {
    console.log(allCusts,"allCusts")

        return onSuccess(allCusts);
      } else {
        return onFailure("Data not found");
      }
    } else {
      return onFailure("Something went wrong! Please try again later. " + res.data);
    }
  } catch (error) {
    return onFailure("Something went wrong! Please try again later. " + error);
  }
};





