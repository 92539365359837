import React from "react";
import { ThemeProvider, Box } from "@material-ui/core";
import PageTitle from "../HelperComponent/PageTitle";
import theme from "../../../theme/theme";
import { currentDate } from "../HelperComponent/utils";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import { CustomCard } from "../../Components/CustomCard";
import { useHistory, useLocation } from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ViewCard, {
  AddressCard,
  ViewBankCard,
} from "../HelperComponent/ViewCard";
import {
  dateFormateField,
  dateFormate,
} from "../../Pages/HelperComponent/utils";

import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import {
  appFontWeightThin,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
  appFontWeight,
  tblBodyHoverColor,
  appScrollBar,
} from "assets/jss/material-dashboard-pro-react";

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "5px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "10px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {},
    "&:hover": {
      backgroundColor: tblBodyHoverColor,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  cardPadding: {
    padding: "10px  15px 0 15px",
  },
  catCards: {
    marginLeft: 5,
  },
  expiredRow: {
    backgroundColor: "#ff6666", 
    color: "white !important", 
  },
}));

const VehicleAllViewPage = ({
  title,
  title1,
  viewData,
  viewData1,
  addedItems,
  itemDetails,
  // allStatus,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  return (
    <ThemeProvider theme={theme}>
      <GridContainer>
        <GridItem xs="12">
          <CustomCard cdTitle={title}>
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      {viewData[0]?.label}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {viewData[1]?.label}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {viewData[2]?.label}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {viewData[3]?.label}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {viewData[4]?.label}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {viewData[5]?.label}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {viewData[6]?.label}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {viewData[7]?.label}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {viewData[8]?.label}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="center" className={classes.endate}>
                      {console.log("san3008/view", addedItems)}
                      {title === "View Vehicle" ? addedItems[0]?.id : ""}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.engNo}>
                      {title === "View Vehicle"
                        ? addedItems[0]?.vehicle_no
                        : // ? addedItems[0]?.vehicle_ownership
                          ""}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.enCustomerName}
                    >
                      {title === "View Vehicle"
                        ? addedItems[0]?.vehicle_ownership
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.enqCustomer}
                    >
                      {title === "View Vehicle"
                        ? addedItems[0]?.vehicle_type
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.enqEmail}
                    >
                      {title === "View Vehicle"
                        ? addedItems[0]?.vehicle_brand
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.enqEmail}
                    >
                      {title === "View Vehicle"
                        ? addedItems[0]?.vehicle_specification
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.enqEmail}
                    >
                      {title === "View Vehicle"
                        ? addedItems[0]?.no_of_wheels
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.enqEmail}
                    >
                      {title === "View Vehicle" ? addedItems[0]?.status : ""}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CustomCard>
        </GridItem>
      </GridContainer>
      {console.log("sankhaAttatch", addedItems)}
      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <CustomCard cdTitle="Attachment Details" maxHeight={380}>
            <TableContainer className={classes.container}>
              <Table
                className={classes.table}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">
                      Attachment Name{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Attachment No
                    </StyledTableCell>
                    <StyledTableCell align="left">Expiry Date</StyledTableCell>
                    <StyledTableCell align="left">Days</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.id}
                    >
                      Bluebook 
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.itemImg}>
                      {addedItems[0]?.bluebook_number }
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={classes.itemDetails}
                    >
                      {"-"}
                     
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={classes.itemDetails}
                      
                    >
                      {"-"}
                  
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow 
                  className={
                    Math.floor((addedItems[0]?.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400) < 15
                      ? classes.expiredRow 
                      : ''
                  }
                  >

                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      Insurance 
                    </StyledTableCell>
                    <StyledTableCell align="left"  className={`${classes.id} ${Math.floor((addedItems[0]?.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}>
                      {addedItems[0]?.insurance_number }
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      {dateFormate(
                        addedItems[0]?.insurance_expire_date 
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.insurance_expire_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                   
                   { `${Math.floor((addedItems[0]?.insurance_expire_date  - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired"  : `${Math.floor((addedItems[0]?.insurance_expire_date  - Math.floor(Date.now() / 1000)) / 86400)} days`}    
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow
                    className={
                        Math.floor((addedItems[0]?.permit_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15
                          ? classes.expiredRow 
                          : ''
                      }
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.permit_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      Permit 
                    </StyledTableCell>
                    <StyledTableCell align="left"  className={`${classes.id} ${Math.floor((addedItems[0]?.permit_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}>
                      {addedItems[0]?.permit_number }
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.permit_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      {dateFormate(
                        addedItems[0]?.permit_expiry_date 
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.permit_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                     
                   { `${Math.floor((addedItems[0]?.permit_expiry_date  - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired"  : `${Math.floor((addedItems[0]?.permit_expiry_date  - Math.floor(Date.now() / 1000)) / 86400)} days`}    
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow
                   className={
                    Math.floor((addedItems[0]?.pollution_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15
                      ? classes.expiredRow 
                      : ''
                  }
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.pollution_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      Pollution 
                    </StyledTableCell>
                    <StyledTableCell align="left"  className={`${classes.id} ${Math.floor((addedItems[0]?.pollution_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}>
                      {addedItems[0]?.pollution_number }
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.pollution_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      {dateFormate(
                        addedItems[0]?.pollution_expiry_date 
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.pollution_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                    
                   { `${Math.floor((addedItems[0]?.pollution_expiry_date  - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired"  : `${Math.floor((addedItems[0]?.pollution_expiry_date  - Math.floor(Date.now() / 1000)) / 86400)} days`}    
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow
                    className={
                        Math.floor((addedItems[0]?.tax_expire_date - Math.floor(Date.now() / 1000)) / 86400) < 15
                          ? classes.expiredRow 
                          : ''
                      }
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.tax_expire_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      Tax
                    </StyledTableCell>
                    <StyledTableCell align="left"  className={`${classes.id} ${Math.floor((addedItems[0]?.tax_expire_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}>
                      {addedItems[0]?.tax_number }
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.tax_expire_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                       {dateFormate(
                        addedItems[0]?.tax_expire_date 
                      )}
                     
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.tax_expire_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      { `${Math.floor((addedItems[0]?.tax_expire_date  - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired"  : `${Math.floor((addedItems[0]?.tax_expire_date  - Math.floor(Date.now() / 1000)) / 86400)} days`}
                  
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow
                     className={
                        Math.floor((addedItems[0]?.fitness_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15
                          ? classes.expiredRow 
                          : ''
                      }
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.fitness_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      Fitness
                    </StyledTableCell>
                    <StyledTableCell align="left"  className={`${classes.id} ${Math.floor((addedItems[0]?.fitness_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}>
                      {addedItems[0]?.fitness_number }
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.fitness_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      {dateFormate(
                        addedItems[0]?.fitness_expiry_date
 
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.fitness_expiry_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                    
                   { `${Math.floor((addedItems[0]?.fitness_expiry_date  - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired"  : `${Math.floor((addedItems[0]?.fitness_expiry_date  - Math.floor(Date.now() / 1000)) / 86400)} days`}    
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.id}
                    >
                      Loan
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.itemImg}>
                      {addedItems[0]?.loan_number }
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={classes.itemDetails}
                    >
                     
                      {"-"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={classes.itemDetails}
                    >
                     {"-"}
                 
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.id}
                    >
                      Bank NOC
                    </StyledTableCell>
                    <StyledTableCell align="left " className={classes.itemImg}>
                      {addedItems[0]?.bank_noc_number }
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={classes.itemDetails}
                    >
                  
                      {"-"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={classes.itemDetails}
                    >
                    {"-"}
                  
                 
                    </StyledTableCell>
                  </StyledTableRow>
                 { console.log(addedItems,"sankhaadded")}
                  <StyledTableRow
                    className={
                        Math.floor((addedItems[0]?.gps_date - Math.floor(Date.now() / 1000)) / 86400) < 15
                          ? classes.expiredRow 
                          : ''
                      }
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.gps_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      Gps
                    </StyledTableCell>
                    <StyledTableCell align="left "  className={`${classes.id} ${Math.floor((addedItems[0]?.gps_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}>
                     
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.gps_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                      {dateFormate(
                        addedItems[0]?.gps_date
 
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${classes.id} ${Math.floor((addedItems[0]?.gps_date - Math.floor(Date.now() / 1000)) / 86400) < 15 ? classes.expiredRow : ''}`}
                    >
                     
                   { `${Math.floor((addedItems[0]?.gps_date  - Math.floor(Date.now() / 1000)) / 86400)} days` < `0 days` ? "Already Expired"  : `${Math.floor((addedItems[0]?.gps_date  - Math.floor(Date.now() / 1000)) / 86400)} days`}    
                    </StyledTableCell>
                  </StyledTableRow>

              
                </TableBody>

              </Table>
            </TableContainer>
          </CustomCard>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
};

export default VehicleAllViewPage;
