import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";
import {getAllTyreBrandId,getAllTyreModelId,} from "../../../services/tyreBrandService";

import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import { Input, Box, Paper, Badge } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import theme from "../../../theme/theme";

import Select from "react-select";
import { ColoseButton } from "views/Components/CustomButton";

import React from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  appFontWeightThin,
  tblBodyHoverColor,
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory, useLocation } from "react-router-dom";
import { activeText } from "assets/jss/material-dashboard-pro-react";

import IconButton from "@material-ui/core/Button";
import { currentDate } from "../HelperComponent/utils";
import { posttyreDetails } from "services/tyreDetailsService";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import { styled } from '@mui/material/styles';
import { updatetyreDetails } from "services/tyreDetailsService";
import { getVehicleById,getVehicle } from "services/tyreDetailsService";
import { boolean } from "fx/config";
const useStyles1 = makeStyles(styles);
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "5px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "10px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    border: "1px soild",
    // borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {},
    "&:hover": {
      backgroundColor: tblBodyHoverColor,
    },
  },
}))(TableRow);

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  ErrorClass: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "10.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 200,
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
  searchBar: {
    padding: "10px",
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },
  floatAddBtn: {
    position: "fixed",
    top: 90,
    right: 40,
    zIndex: 1000,
  },
  redRow: {
    backgroundColor: "#efa955"
  }
}
));

const condition = [
  {
    label: "10%",
    value: 1,
  },
  {
    label: "20%",
    value: 2,
  },
  {
    label: "30%",
    value: 3,
  },
  {
    label: "40%",
    value: 4,
  },
  {
    label: "50%",
    value: 5,
  },
  {
    label: "60%",
    value: 6,
  },
  {
    label: "70%",
    value: 7,
  },
  {
    label: "80%",
    value: 8,
  },
  {
    label: "90%",
    value: 9,
  },
  {
    label: "100%",
    value: 10,
  },
];

const TyreDetailsView = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [globalState, dispatch] = useStateValue();
  const [vehicle, setAllVehicle] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [vehicleStatus, setVehicleStatus] = React.useState(true);
  const [allTyreDetails, setAllTyreDetails] = React.useState([]);
  const [tyreModel, setAllTyreModel] = React.useState([]);
  const [brand, setAllTyreBrand] = React.useState([]);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const [addTyreDetails, setAddTyreDetails] = React.useState({
    edit: false,
    tyre_details_id: "",
    chk_vehicle_status: "Y",
    ddl_vehicle_no_id: "",
    ddl_vehicle_no_label: "",
    ddl_tyrecondition_no_id: "",
    ddl_tyrecondition_no_label: "",
    txt_barcode: "",
    txt_fitting_km: 0,
    tyre_date: currentDate(),
    ddl_brand_id: "",
    ddl_brand_label: "",
    ddl_model_id: "",
    ddl_model_label: "",
    txt_barcode: '',
    switch_active_status: true,
  });

  const [tyreDetails, setTyreDetails] = React.useState([]);
  // let A = [];
  const options = [
    {
      label: "FORNT-LEFT",
      value: 1,
    },
    {
      label: "FORNT-RIGHT",
      value: 2,
    },
    {
      label: "BACK-LEFT",
      value: 3,
    },
    {
      label: "BACK-RIGHT",
      value: 4,
    },
    {
      label: "Extra",
      value: 5,
    },
  ];

  React.useEffect(() => {
    setLoading(true);

    getVehicleById(
      (vehicle) => {
        
        setAllVehicle(vehicle);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );

    getAllTyreBrandId(
      (brand) => {
        setAllTyreBrand(brand);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );

    getAllTyreModelId(
      (tyre) => {
        setAllTyreModel(tyre);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );

    // View For Tyre Details
    console.log( "211220221=>0",location?.row)
    if (location?.tyre_details_id)
    {

      if(location?.row?.vehicle_id)
      {
          getVehicle((vehicle)=>{
            
              const data = vehicle.filter(v => v.value === location?.row?.vehicle_id)[0]
              console.log( "21122022",data)
              setAddTyreDetails({
                ...addTyreDetails,
                edit: true,
                
                tyre_details_id: location?.row?.tyre_details_id,
                ddl_vehicle_no_id: data.value,
                chk_vehicle_status: location?.row?.vehicle_status,
                ddl_vehicle_no_label: data.label,
                no_of_wheels: Number(data.no_of_wheels) + 1,
                txt_fitting_km: data.km_start ? data.km_start : 0,
        
              });
          
          })
          location?.row?.vehicle_status && location?.row?.vehicle_status==='Y'?setVehicleStatus(true) : setVehicleStatus(false);
          setTyreDetails(location?.row?.tyre_details)
      }
      else 
      {
        setAddTyreDetails({
          ...addTyreDetails,
          edit: true,
          chk_vehicle_status: location?.row?.vehicle_status,
          tyre_details_id: location?.row?.tyre_details_id,
        });

        location?.row?.vehicle_status && location?.row?.vehicle_status==='Y'?setVehicleStatus(true) : setVehicleStatus(false);

        setTyreDetails(location?.row?.tyre_details);
      }
      console.log(vehicleStatus,'ehicleStatus52');

    }
  }, [refresh]);


  const onSelectModule = (event, value) => {
    console.log(event, "onselect", value.name);
    

    switch (value.name) {
      case "ddl_vehicle_no":

        setAddTyreDetails({
          ...addTyreDetails,
          ddl_vehicle_no_id: event.value,
          ddl_vehicle_no_label: event.label,
          no_of_wheels: Number(event.no_of_wheels) + 1,
          txt_fitting_km: event.km_start ? event.km_start : 0,
        });
        break;
      case "ddl_brand":
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_brand_id: event.value,
          ddl_brand_label: event.label,
        });
        if (value === 0 || !value) {
          setError((prevError) => ({ ...prevError, ddl_brand_id: true }));
        } else {
          setError((prevError) => ({ ...prevError, ddl_brand_id: false }));
        }
        break;
      case "ddl_model":
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_model_id: event.value,
          ddl_model_label: event.label,
        });
        if (value === 0 || !value) {
          setError((prevError) => ({ ...prevError, ddl_model_id: true }));
        } else {
          setError((prevError) => ({ ...prevError, ddl_model_id: false }));
        }
        break;
      case "ddl_tyrecondition_no":
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_tyrecondition_no_id: event.value,
          ddl_tyrecondition_no_label: event.label,
        });
        break;
      case "ddl_position_no":
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_position_id: event.value,
          ddl_position_label: event.label,
        });
        break;
     
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    console.log(name, "onselect52", value);
    setAddTyreDetails({ ...addTyreDetails, [name]: value })

    if (value === "" || !value) {
      setError((prevError) => ({ ...prevError, [name]: true }));
    } else {
      setError((prevError) => ({ ...prevError, [name]: false }));
    }

  };

  const onDeleteService = (row, id) => {
 
    let deleteref = tyreDetails;
    deleteref.splice(id, 1);

    dispatch({
      type: actionTypes.SET_OPEN_MSG,
      payload: { msg: "Deleted !!", msgType: "error" },
    });
  };

  const [error, setError] = React.useState({
    txt_fitting_km: false,
    txt_barcode: false,
    ddl_brand_id: false,
    ddl_model_id: false,
    txt_amount: false,
  });
  console.log("sen16122022", tyreDetails);

  const onAdd = (e) => {
    // console.log("sen16122022", tyreDetails);
      if (!addTyreDetails.txt_fitting_km){
        setError({
          txt_fitting_km: !addTyreDetails.txt_fitting_km,
        });
        return false;
      }

      if (!addTyreDetails.ddl_brand_id){
        setError({
          ddl_brand_id:!addTyreDetails.ddl_brand_id
        });
        return false;
      }

      if (!addTyreDetails.ddl_model_id){
        setError({
          ddl_model_id:!addTyreDetails.ddl_model_id
        });
        return false;
      }

      if (!addTyreDetails.txt_barcode){
        setError({
          txt_barcode: !addTyreDetails.txt_barcode,
        });
        return false;
      }

      if(!addTyreDetails.txt_amount ){
        setError({
          txt_amount:!addTyreDetails.txt_amount
        });
        return false;
      }
          

    tyreDetails.length < addTyreDetails.no_of_wheels || vehicleStatus==false  ?
      tyreDetails.filter(t => t.txt_barcode.includes(addTyreDetails.txt_barcode)).length ? 
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "Barcode Exists !!",
            msgType: "error",
          },
        })
        :
        setTyreDetails([...tyreDetails,
          {
            _id: tyreDetails.length + 1,
            ddl_vehicle_no_id: addTyreDetails.ddl_vehicle_no_id,
            ddl_vehicle_no_label: addTyreDetails.ddl_vehicle_no_label,
            ddl_tyrecondition_no_id: addTyreDetails.ddl_tyrecondition_no_id,
            ddl_tyrecondition_no_label: addTyreDetails.ddl_tyrecondition_no_label,
            txt_barcode: addTyreDetails.txt_barcode,
            tyre_date: addTyreDetails.tyre_date,
            ddl_brand_id: addTyreDetails.ddl_brand_id,
            ddl_brand_label: addTyreDetails.ddl_brand_label,
            ddl_model_id: addTyreDetails.ddl_model_id,
            ddl_model_label: addTyreDetails.ddl_model_label,
            ddl_position_id: addTyreDetails.ddl_position_id,
            ddl_position_label: addTyreDetails.ddl_position_label,
            txt_amount: addTyreDetails.txt_amount,
            txt_fitting_km: addTyreDetails.txt_fitting_km,
            rejected:false,
            action: "action",
            action_items: { can_delete: true },
          }

        ])
      : 
      addTyreDetails.no_of_wheels?
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "Tyre can't Be More Than " + addTyreDetails.no_of_wheels,
            msgType: "error",
          },
        }): dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: {
              msg: "Select Vehicle ",
              msgType: "error",
            },
          })
  };

  const onClickClose = () => {
    history.push({
      pathname: "/admin/master/tyreDetails",
    })
  }

//   const onSubmitModel = (e) => {

//     e.preventDefault();
//     if(!addTyreDetails.ddl_vehicle_no_id && vehicleStatus===true)
//     {
//       dispatch({
//         type: actionTypes.SET_OPEN_MSG,
//         payload: { msg: "Vehicle Required !!", msgType: "error" },
//       });
//       return false;
//     }

//     if(tyreDetails.length<=0)
//     {
//       dispatch({
//         type: actionTypes.SET_OPEN_MSG,
//         payload: { msg: "Tyre Details Required !!", msgType: "error" },
//       });
//       return false;
//     }

//     setButtonDisabled(true);

//     if (addTyreDetails.edit) {
//       console.log(tyreDetails, addTyreDetails)
//       updatetyreDetails(
//         addTyreDetails,
//         tyreDetails,
//         globalState?.user?.serial_id,
//         (r) => {
//           dispatch({
//             type: actionTypes.SET_OPEN_MSG,
//             payload: {
//               msg: "Tyre Details Updated Successfully",
//               msgType: "success",
//             },
//           });
//           setRefresh(!refresh);

//           history.push({
//             pathname: "/admin/master/tyreDetails",
//           })
//         },
//         (err) => {
//           dispatch({
//             type: actionTypes.SET_OPEN_MSG,
//             payload: { msg: err, msgType: "error" },
//           });
//         }
//       );
//       history.push({
//         pathname: "/admin/master/tyreDetails",
//       })
//     } else {
//       posttyreDetails(
//         addTyreDetails,
//         tyreDetails,
//         globalState?.user?.serial_id,
//         (r) => {
//           dispatch({
//             type: actionTypes.SET_OPEN_MSG,
//             payload: {
//               msg: "Tyre Details added Successfully",
//               msgType: "success",
//             },
//           });
//           setClassicModal();
//           setRefresh(!refresh);

//           history.push({
//             pathname: "/admin/master/tyreDetails",
//           })
//         },
//         (err) => {
//           dispatch({
//             type: actionTypes.SET_OPEN_MSG,
//             payload: { msg: err, msgType: "error" },
//           });
//         }
//       );
//       history.push({
//         pathname: "/admin/master/tyreDetails",
//       })
//     }
//   };

  const headerData = [
    {
      id: "_id",
      label: "# ",
    },
    {
      id: "txt_barcode",
      label: "Barcode ",
    },
    {
      id: "tyre_date",
      label: "Date",
    },
    {
      id: "ddl_vehicle_no_label",
      label: "Vehicle No",
    },
    {
      id: "txt_fitting_km",
      label: "Fit KM",

    },
    {
      id: "ddl_brand_label",
      label: "Brand",

    },
    {
      id: "ddl_model_label",
      label: "Model",

    },
    {
      id: "ddl_position_label",
      label: "Position",

    },
    {
      id: "ddl_tyrecondition_no_label",
      label: "Condition",

    },
    {
      id:"rejected_status",
      label:"Rejected Status"
    },

    {
      id: "txt_amount",
      label: "Amount",

    },
    {
      id: "action",
      label: "Action",
      align: "right",
      viewMenu: (v) => v,
    },
  ];


  const onSetActive = () => {
    vehicleStatus?setVehicleStatus(false) : setVehicleStatus(true);
    setAddTyreDetails({
      ...addTyreDetails,
      chk_vehicle_status: vehicleStatus?'N':'Y',
    });
  };


  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Tyre > Tyre Details"
      />
      <CustomCard cdTitle="Tyre Details" filterIcon>
        <GridContainer justify="space-between" alignItems="center">
          <GridItem xs="2">
            <InputLabel id="label">Vehicle Status</InputLabel>
            <Switch
              onChange={onSetActive}
              checked={vehicleStatus}
              name="chk_vehicle_status"
              fullWidth={true}
              inputProps={{
                "aria-label": "primary checkbox",
              }}
              color="primary"
            />
          </GridItem>
          <GridItem xs="7">
            <InputLabel id="label">Vehicle No</InputLabel>
            <Select
              options={vehicle}
              placeholder="Select"
              formatGroupLabel={(d) => d.label}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              className={classes.customSelect}
              onChange={onSelectModule}
              name="ddl_vehicle_no"
              value={{ label: addTyreDetails.ddl_vehicle_no_label }}
              isDisabled = {vehicleStatus?false:true}
            />
          </GridItem>
          
          <GridItem xs="3">
            <InputLabel id="label">Wheels</InputLabel>
            <TextField
              size="small"
              type="String"
              name="wheels"
              placeholder=""
              id="outlined-basic"
              fullWidth={true}
              value={addTyreDetails.no_of_wheels}
              variant="outlined"
              disabled = {vehicleStatus?false:true}
            // inputProps={{ style: { color: "green", border: "none", textAlign: "center" } }}
            // disabled
            />
          </GridItem>
          
        </GridContainer>
        <CustomCard
          filterIcon
        >
          <GridContainer justify="space-between" alignItems="center">
            <GridItem xs={2}>
              <InputLabel required={false} id="label">
                Date
              </InputLabel>
              <TextField
                size="small"
                name="tyre_date"
                id="date"
                variant="outlined"
                type="date"
                fullWidth={true}
                inputProps={{ style: { textAlign: "left" } }}
                FormHelperTextProps={{ style: { textAlign: "left" } }}
                value={addTyreDetails.tyre_date}
                onChange={onChange}
                InputLabelProps={{
                  shrink: true,
                }}
              // onClick={(e) => {
              //   e.target.select();
              // }}
              />
            </GridItem>
            
            <GridItem xs={2}>
              <InputLabel required={true} id="label">
                Fitting KM
              </InputLabel>
              <TextField
                size="small"
                type="Number"
                name="txt_fitting_km"
                onChange={onChange}
                placeholder="km"
                id="outlined-basic"
                fullWidth={true}
                value={addTyreDetails.txt_fitting_km}
                variant="outlined"
                // helperText={error.txt_fitting_km?"Fitting Km is required":""}
                inputProps={{ style: { textAlign: "right" } }}
              // disabled
              />
            
                 {error.txt_fitting_km && (
                    <div className={classes.ErrorClass}>
                       KM is required
                    </div>
                  )}
            </GridItem>

            <GridItem xs={3}>
              <InputLabel required={true} id="label">
                Brand
              </InputLabel>
              <Select
                options={brand}
                placeholder="company name"
                formatGroupLabel={(d) => d.label}
                className={classes.customSelect}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                name="ddl_brand"
                onChange={onSelectModule}
              />
              {error.ddl_brand_id && (
                  <div className={classes.ErrorClass}>
                      Brand is required
                  </div>
                )}
            </GridItem>

            <GridItem xs={3}>
              <InputLabel required={true} id="label">
                Model Name
              </InputLabel>
              <Select
                options={tyreModel}
                placeholder="Model"
                formatGroupLabel={(d) => d.label}
                className={classes.customSelect}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                name="ddl_model"
                onChange={onSelectModule}
              />
              {error.ddl_model_id && (
                  <div className={classes.ErrorClass}>
                      Model is required
                  </div>
                )}
            </GridItem>

            <GridItem xs={2}>
              <InputLabel required={true} id="label">
                Barcode No
              </InputLabel>
              <TextField
                size="small"
                type="String"
                name="txt_barcode"
                onChange={onChange}
                id="outlined-basic"
                fullWidth={true}
                value={addTyreDetails.txt_barcode}
                variant="outlined"
                // helperText={error.txt_barcode?"Barcode is required":""}
              />
              {error.txt_barcode && (
                  <div className={classes.ErrorClass}>
                      Barcode is required
                  </div>
                )}
            </GridItem>

            <GridItem xs={3}>
              <InputLabel required={true} id="label">
                Position
              </InputLabel>
              <Select
                options={options}
                placeholder="position"
                formatGroupLabel={(d) => d.label}
                className={classes.customSelect}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                name="ddl_position_no"
                onChange={onSelectModule}
              />
              {error.ddl_position_id && (
                  <div className={classes.ErrorClass}>
                      Position is required
                  </div>
                )}
            </GridItem>

            <GridItem xs={2}>
              <InputLabel required={true} id="label">
                Tyre Condition
              </InputLabel>
              <Select
                options={condition}
                placeholder="%"
                formatGroupLabel={(d) => d.label}
                className={classes.customSelect}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                name="ddl_tyrecondition_no"
                onChange={onSelectModule}
              />
              {error.ddl_tyrecondition_no_id && (
                  <div className={classes.ErrorClass}>
                      Tyre Condition is required
                  </div>
                )}
            </GridItem>

            <GridItem xs={2}>
              <InputLabel required={false} id="label">
                Amount
              </InputLabel>
              <TextField
                size="small"
                type="Number"
                name="txt_amount"
                id="outlined-basic"
                onChange={onChange}

                fullWidth={true}
                value={addTyreDetails.txt_amount}
                inputProps={{ style: { textAlign: "right" } }}
                variant="outlined"
              // inputProps={{ style: { color: "green", border: "none", textAlign: "center" } }}
              />
              {error.txt_amount && (
                  <div className={classes.ErrorClass}>
                      Amount is required
                  </div>
                )}
            </GridItem>

            <GridItem xs={3}>
              <StyledBadge
                // badgeContent=
                // {tyreDetails.length > 0 && vehicleStatus===true && addTyreDetails.no_of_wheels ?
                //   Number(addTyreDetails.no_of_wheels) - tyreDetails.length
                //   :
                //   addTyreDetails.no_of_wheels}
                color="error">

                <CustomButton
                  // onClick={onAdd}
                >
                  Add
                </CustomButton>
              </StyledBadge>

            </GridItem>

          </GridContainer>
        </CustomCard>

      
      </CustomCard>
        {tyreDetails && tyreDetails.length>0 ?
          <CustomCard cdTitle="Added Tyres">
            {/* <MuiTable
              columns={headerData}
              rows={tyreDetails}
            /> */}

            <TableContainer>
              <Table className={classes.table} aria-label="customized table" >
                <TableHead>
                  <TableRow>
                    {headerData && headerData.map((tableHeadData, headindex)=>{
                        return(
                          <StyledTableCell align="center" key={tableHeadData.id}>
                            {tableHeadData.label}
                          </StyledTableCell>
                        )
                      })

                    }
                  </TableRow>
                </TableHead>
                <TableBody
                >
                  { tyreDetails &&
                    tyreDetails.map((row,i)=>
                
                  <StyledTableRow key={i}
                  style={row.rejected ? { backgroundColor: "#efa955" } : {}}
                  >
                

                    <StyledTableCell align="center" >
                      {row.length != 0 ? i + 1 : ""}
                    </StyledTableCell>

                    <StyledTableCell align="left" >
                      {row.txt_barcode}
                    </StyledTableCell>

                    <StyledTableCell align="center" >
                      {row.tyre_date.split('-').reverse().join('-')}
                    </StyledTableCell>

                    <StyledTableCell align="center" >
                      {row.ddl_vehicle_no_label}
                    </StyledTableCell>

                    <StyledTableCell align="right" >
                      {row.txt_fitting_km}
                    </StyledTableCell>

                    <StyledTableCell align="left" >
                      {row.ddl_brand_label}
                    </StyledTableCell>

                    <StyledTableCell align="left" >
                      {row.ddl_model_label}
                    </StyledTableCell>
                    <StyledTableCell align="left" >
                      {row.ddl_position_label
}
                    </StyledTableCell>

                    <StyledTableCell align="right" >
                      {row.ddl_tyrecondition_no_label}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {boolean(row.rejected)}
                    </StyledTableCell>

                    <StyledTableCell align="right" >
                      {row.txt_amount}
                    </StyledTableCell>

                    <StyledTableCell align="right" >
                      <IconButton
                    //    onClick={(e) => onDeleteService(row, i)}
                        aria-label="delete" >
                        <DeleteIcon fontSize="small" style={{ color: "#f44336" }}                   
                        />
                      </IconButton>
                    </StyledTableCell>

                  </StyledTableRow>
                
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>

          </CustomCard>
          :""
        }
        <div style={{ width: "100%" }} className={classes.actionbtns}>
          {/* <CustomButton
            style={{
              float: "right",
              width: "10%",
              marginRight: "10px",
              border: "none",
            }}
            onClick={onSubmitModel}
            disabled={buttonDisabled}
          >
            Submit
          </CustomButton> */}
   

          <ColoseButton
            style={{
              backgroundColor: "red",
              color: "white",
              float: "right",
              width: "10%",
              marginRight: "10px",
              border: "none",
            }}
            onClick={onClickClose}
          >
            Close
          </ColoseButton>
        </div>
    </ThemeProvider>
  );
};
export default TyreDetailsView;
