//lab
import webApi from "./webApi/webApi";
export const getAlllab = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/lab/list", {});
    if (res.status === 200) {
      const r = res.data;
      let alllabs = [];
      r.map((r, i) => {
        alllabs.push({
        
          labe_id: i + 1,
          lab_id:r.lab_id,
          lab_lab: r.lab,
          lab_details: r.details,
          action_items: r.action_items,
          lab_status: r.active_status,
          lab_action: "action",
        });
      });
      return onSuccess(alllabs);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postlab = async (lab,inserted_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/lab/insert", {
      lab: lab.txt_lab,
      details: lab.txt_details,
      active_status: lab.switch_active_btn ? "Y" : "N",
      inserted_by_id:inserted_by_id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  }
  
  catch (error) 
  {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updatelab = async (lab, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/lab/update", {
      lab_id: lab.lab_id,
      lab: lab.txt_lab,
      details: lab.txt_details,
      active_status: lab.switch_active_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r,"sank1008=>");
      onSuccess(r);
    } 
   
    else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
export const deletelab = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/lab/delete", {
        lab_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


export const getListlab = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/lab/list", {
   
    });
    if (res.status === 200) {
      const r = res.data;
      
      let arr = [];
      r.map((r, i) => {
        arr.push({
          value: r.lab_id,
          label: r.lab,
        });
      });
      return onSuccess(arr);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }

  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};