import webApi from "./webApi/webApi";
import { dateFormate, currencyFormate,currentDate } from "../views/Pages/HelperComponent/utils";
import { timestamp } from "../views/Pages/HelperComponent/utils";

function toTitleCase(str) {
  if(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  else {
    return "";
  }
}

export const getSearchPayment = async (onSuccess, onFailure,addSearch) => {
  try {
    const res = await webApi.post("/master/receipt_payment/list", {
      voucher_no:addSearch.txt_voucher_no,
      ledger_id:addSearch.ddl_ledger?.value,
      from_date: timestamp(addSearch.txt_from_date),
      to_date: timestamp(addSearch.txt_to_date),
    });
    if (res.status === 200) {
      const r = res.data;
      let arr = [];
      let inc = 0;
      let sumPayAmounts = 0;
      console.log("sen=>>",r)
      r.sort((a,b)=>b.voucher_date - a.voucher_date).map((r, i) => {
        if (r.receipt_payment_type === "P" || r.receipt_payment_type === "BP") {
          inc++;
          arr.push({
            payID: inc,
            bank_id: r.bank_id,
            receipt_payment_id: r.receipt_payment_id,
            payDate: dateFormate(r.voucher_date),
            payVoucherNo: r.voucher_no,
            payLedger: r.ledger_account_id,
            ledger_account_name: r.ledger_account_name,
            payMode: r.mode,
            payReference: r.reference_number,
            payNarration: r.narration,
            payAmount: r.amount,
            action_items: r.action_items,
            payAction: "action",
          });
          sumPayAmounts += r.amount;
        }
      });
      if(arr.length){
      return onSuccess(arr, sumPayAmounts);
    } else {
      return onFailure("receipt not found");
    }
  }
     else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }

};

export const getAllPayment = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/receipt_payment/list", {});
    if (res.status === 200) {
      const r = res.data;
      let arr = [];
      let inc = 0;
      let sumPayAmounts = 0;
      r.map((r, i) => {
        if (r.receipt_payment_type === "P") {
          inc++;
          arr.push({
            payID: inc,
            receipt_payment_id: r.receipt_payment_id,
            payDate: dateFormate(r.voucher_date),
            payVoucherNo: r.voucher_no,
            payLedger: r.ledger_account_id,
            ledger_account_name: r.ledger_account_name,
            payMode: r.mode,
            payReference: r.reference_number,
            payNarration: r.narration,
            payAmount: currencyFormate(r.amount),
            action_items: r.action_items,
            payAction: "action",
          });
          sumPayAmounts += r.amount;
        }
      });
      return onSuccess(arr, sumPayAmounts);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const postPayment = async (info,inserted_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/receipt_payment/insert", {
      receipt_payment_type: "P",
      voucher_date: timestamp(info.txt_voucher_date),
      ledger_account_id: Number(info.ddl_ledger),
      mode: info.ddl_mode_label,
      bank_id:info.ddl_mode_label === "Bank" ? info.bank_id : 2 ,
      reference_number: info.txt_reference,
      narration: info.txt_nar,
      amount: info.txt_amount,
      adjustment_amount: 0,
      inserted_by_id:inserted_by_id,
      active_status: info.switch_status_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const postPaymentAdj = async (info,inserted_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/receipt_payment/insert", {
      receipt_payment_type: "BP",
      voucher_date: timestamp(info.txt_voucher_date),
      ledger_account_id: Number(info.ddl_ledger),
      mode: info.ddl_mode.label,
      bank_id:2696,
      reference_number: info.txt_reference,
      narration: info.txt_nar,
      amount: info.txt_adjamount,
      inserted_by_id:inserted_by_id,
      // adjustment_amount: info.txt_adjamount,
      active_status: info.switch_status_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updatePayment = async (info,edited_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/receipt_payment/update", {
      receipt_payment_id: info.receipt_payment_id,
      voucher_date: timestamp(info.txt_voucher_date),
      voucher_no: info.txt_voucher_no,
      ledger_account_id: Number(info.ddl_ledger),
      mode: info.ddl_mode,
      reference_number: info.txt_reference,
      amount: info.txt_amount,
      // adjustment_amount: info.txt_adjamount,
      narration: info.txt_nar,
      edited_by_id:edited_by_id,
      bank_id: info.ddl_mode_label === "Bank" ? info.bank_id : 2 ,
      active_status: info.switch_status_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const deletePayment = async (
  receipt_paymentId,
  onSuccess,
  onFailure
) => {
  try {
    const res = await webApi.post("/master/receipt_payment/delete", {
      receipt_payment_id: receipt_paymentId,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getLedgerClosingBalance = async (onSuccess, onFailure, q) => {
  console.log(q,"abc")
  
  try {

    console.log(q.ddl_ledger_account.value,"dina ti")

    const res = await webApi.post("/master/ledger_account/balance", {
      from_date: timestamp(q.txt_from_date),
      to_date: timestamp(currentDate(1)),
      ledger_account_id: q.ddl_ledger_account?.value,
    });

    if (res.status === 200) {
      let r = res.data;
      let allItems = [];
      let inc = 0;
      
      if (r) {
        console.log("AII", r);
        let res = [{
          ...r[0], 
          closing_balance: (r[0]?.closing_balance >= 0 ? r[0]?.closing_balance : -r[0]?.closing_balance),
          initial_dr_cr: r[0]?.dr_cr_status,
          //dr_cr_status: r[0]?.closing_balance > 0 ? "Dr" : "Cr" ,
          dr_cr_status:r[0]?.currrent_dr_cr
        }];

        return onSuccess(res);
        } else {
          return onFailure("Journal Not Found");
        }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

//Ledger account
export const getListLedgerAccount = async (onSuccess, onFailure,ledger_group_id,) => {

  try {
    const res = await webApi.post("/master/ledger_account/list", {
      short_data: true,
      // ledger_group_id:4,
    });
    console.log("sen20042allLedAcc", res);
    if (res.status === 200) {
      const r = res.data;
      let allLedgerAccount = [];
      r.map((r, i) => {
        allLedgerAccount.push({
          value: r.ledger_account_id,
          label: toTitleCase(r.ledger_account),
          ledger_group_id: r.ledger_group_id,
          opening_balance: r.opening_balance,
        });
      });
      console.log("sen20042allLedAcc", allLedgerAccount);
      return onSuccess(allLedgerAccount);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

//bank
export const getListLedgerAccountByGroupId = async (onSuccess, onFailure,ledger_group_id) => {
  try {
    const res = await webApi.post("/master/ledger_account/list", {
      short_data: true,
      ledger_group_id:3,
      
    });
    if (res.status === 200) {
      const r = res.data;
      let allLedgerAccount = [];
      r.map((r, i) => {
        allLedgerAccount.push({
          value: r.ledger_account_id,
          label: toTitleCase(r.ledger_account),
          ledger_group_id: r.ledger_group_id,
          opening_balance: r.opening_balance,
         
        });
      });
      console.log("allLedAcc", allLedgerAccount);
      return onSuccess(allLedgerAccount);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};