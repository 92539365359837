//lab
import webApi from "./webApi/webApi";
// export const getAlltyre_Type = async (onSuccess, onFailure) => {
//   try {
//     const res = await webApi.post("/master/tyreType/list", {});
//     if (res.status === 200) {
//       const r = res.data;
//       let alllabs = [];
//       r.map((r, i) => {
//         alllabs.push({
        
//          tyre_Type_id: i + 1,
//           tyre_Type_id:r.tyre_Type_id,
//           tyre_Type: r.tyre_Type,
//           details: r.details,
//           action_items: r.action_items,
//           tyre_Type_status: r.active_status,
//           tyre_Type_action: "action",
//         });
//       });
//       return onSuccess(alllabs);
//     } else {
//       onFailure("Something Wrong! Please Try again later" + res.data);
//     }
//   } catch (error) {
//     onFailure("Something Wrong! Please Try again later" + error);
//   }
// };

export const posttyre_Type = async (tyre_Type,inserted_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/tyreType/insert", {
        tyre_Type: tyre_Type.tyre_Type,
      details: tyre_Type.details,
      active_status: tyre_Type.switch_active_btn ? "Y" : "N",
      inserted_by_id:inserted_by_id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  }
  
  catch (error) 
  {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updatetyre_Type = async (tyre_Type, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/tyreType/update", {
        tyre_Type_id: tyre_Type.tyre_Type_id,
        tyre_Type: tyre_Type.txt_tyre_Type,
      details: tyre_Type.txt_details,
      active_status: tyre_Type.switch_active_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r,"sank1008=>");
      onSuccess(r);
    } 
   
    else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
export const deletetyre_Type = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/tyreType/delete", {
        tyreType_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


export const getListtyre_Type = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/tyreType/list", {
   
    });
    if (res.status === 200) {
      const r = res.data;
      
      let arr = [];
      r.map((r, i) => {
        arr.push({
          value: r.tyreType_id,
          label: r.view_tyreType,
        });
      });
      return onSuccess(arr);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }

  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};