import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";
import {
    getAllService,
    postService,
    deleteService,
    updateService
} from "../../../services/serviceService";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Box } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import PageTitle from "../HelperComponent/PageTitle";
import theme from "../../../theme/theme";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import React from "react";
import {
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import  FileSaver from 'file-saver';
import XLSX from 'xlsx'
import IconButton from '@material-ui/core/Button';

const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
    root: {
        switchBtn: {
            width: 180,
            height: "100%",
        },
    },
    catCards: {
        marginLeft: 5,
        marginBottom: 20,
    },
    activeText: {
        ...activeText,
    },
    input: {
        height: 40,
        lineLight: 40,
        padding: "0 10px",
        marginBottom: "20px",
    },
}));

const toTitleCase = (str) => {
    if(str) {
        return str.replace(
            /\w\S*/g,
            function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }
    else {
        return "";
    }
}

const formData = {
    formName: "Add Service",
    fields: [
        {
            name: "txt_service",
            label: "Service",
            hidden: false,
            required: true,
            data_type: "string",
            html_element: "TextField",
            error: false,
        },
        {
            name: "txt_details",
            label: "Details",
            hidden: false,
            required: false,
            data_type: "string",
            html_element: "TextArea",
            error: false,
        },
        {
            name: "switch_active_btn",
            label: "active",
            defaultValue: false,
            required: false,
            data_type: "string",
            html_element: "switch",
            error: false,
        },
    ],
};

const ServicePage = () => {
    const classes1 = useStyles1()

    const history = useHistory();
    const [classicModal, setClassicModal] = React.useState(false);
    const [services, setAllServices] = React.useState([]);
    const [globalState, dispatch] = useStateValue();
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [buttonDisabled, setButtonDisabled] = React.useState(false);

    const [addService, setAddService] = React.useState({
        edit: false,
        service_id: "",
        switch_active_btn: false,
        txt_service: "",
        txt_details: "",
    });

    const [error, setError] = React.useState({
        txt_service: false,
    });

    React.useEffect(() => {
        //setLoading(true);
        getAllService(
            (services) => {
                setAllServices(services);
                setLoading(false);
            },
            (err) => {
                setLoading(false);
                dispatch({
                    type: actionTypes.SET_CLOSE_MSG,
                    payload: { msg: err, msgType: "error" },
                });
            }
        );
    },[refresh]);

    const onSetActive = (e) => {
        setAddService((prv) => ({ ...prv, [e.target.name] : e.target.checked }));
    };

    //Service
    const headerData = [
        {
            id: "servicee_id",
            label: "#",
        },
        {
            id: "service_service",
            label: "Services",
        },
        {
            id: "service_details",
            label: "Details",
        },
        {
          id: "service_status",
          label: "Status",
          statusBtn: (v) => (v === "Y" ? true : false),
        },
        {
          id: "action",
          label: "Action",
          align: "right",
        },
    ];

    //onSubmit Called
    const onSubmitModel = (e) => {
        console.log(onSubmitModel, "Aritra1234");
        e.preventDefault();
        if(!addService.txt_service) {
            setError({
                txt_service: !addService.txt_service,
            });
        }else {
            if(addService.edit) {
                updateService(
                    addService,
                    (r) => {
                        onCloseModel();
                        setRefresh(!refresh);
                        dispatch({
                            type: actionTypes.SET_OPEN_MSG,
                            payload: {
                              msg: "Service Updated Successfully",
                              msgType: "success",
                            },
                        });
                    },
                    (err) => {
                        dispatch({
                            type: actionTypes.SET_OPEN_MSG,
                            payload: { msg: err, msgType: "error" },
                        });
                    }
                );
            }else{
                postService(
                    addService,
                    globalState?.user?.serial_id,
                    (r) => {
                        dispatch({
                            type: actionTypes.SET_OPEN_MSG,
                            payload: {
                              msg: "Service added Successfully",
                              msgType: "success",
                            },
                        });
                        onCloseModel();
                        setRefresh(!refresh);
                    },
                    (err) => {
                        dispatch({
                            type: actionTypes.SET_OPEN_MSG,
                            payload: { msg: err, msgType: "error" },
                        });
                        setButtonDisabled(true)
                    }
                );
            }
        }
    };

    //On Delete Called
    const onDeleteService = (row, next) => {
        deleteService(
            row.service_id,
            (r) => {
                next();
                setRefresh(!refresh);
            },
            (err) => {
                dispatch({
                    type: actionTypes.SET_OPEN_MSG,
                    payload: { msg: err, msgType: "error" },
                });
            }
        );
    };
    const onChange = (e) => {
        const { value, name } = e.target;
        if (name === "txt_service"  ) {
        setAddService((prv) => ({
            ...prv,
            ["txt_service"]: toTitleCase(value),
        
        }));
        }else{
        setAddService({ ...addService, [name]: value });
        }
    };

    //On Edit Called
    const onUpdateService = (row) => {
        setClassicModal(true);
        setAddService({
            ...addService,
            edit: true,
            switch_active_btn: row.service_status === "Y" ? true : false,
            service_id: row.service_id,
            txt_service: row.service_service,
            txt_details: row.service_details,
        });
    };

    const classes = useStyles();
    //On Close Model
    const onCloseModel = () => {
        setClassicModal(false);
        setError({ txt_service: false });
        setAddService({
            edit: false,
            switch_active_btn: false,
            txt_service: "",
            txt_details: "",
        });
    };

    // export to excel

    const onhandleExportToExcel = () => {
        const ServiceTable = services.map(acc => {
            return {
                ID: acc.servicee_id,
                Services: acc.service_service,

                Details: acc.service_details,

                Status: acc.service_status,
                Action: "",

            }
        })
        
        const fileName = 'Services'
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(ServiceTable);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    
    return(
        <ThemeProvider theme={theme}>
            <PageTitle
                title = "Master > Setting > Services"
                btnToolTip="Add New Service"
                onClickAddBtn={() => setClassicModal(true)}
            />

            <GridContainer className={classes.root}>
                <GridItem xs="12">
                    <GridContainer justify="space-between" alignItems="center">
                        <GridItem>
                            <MasterModel
                                classicModal={classicModal}
                                onCloseModel={onCloseModel}
                                width={450}
                                // btnDisabled={!addBrand.brand}
                                height="auto"
                                modelName="Add a Service"
                                onClickOk={onSubmitModel}
                                disabled={buttonDisabled}
                            >
                                <div style={{ padding: "20px 10px", width: "100%" }}>
                                    <GridContainer>
                                        {formData.fields.map((item, key) => {
                                            return (
                                                <>
                                                    <GridItem xs={12} key={key}>
                                                        {item.html_element === "select" && (
                                                            <>
                                                                <InputLabel id="label">{item.label}</InputLabel>
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    options={item.options}
                                                                    getOptionLabel={(option) => option.label}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                        onChange={onChange}
                                                                        // value={addCategory.parentCategory}
                                                                        placeholder={item.label}
                                                                        fullWidth={true}
                                                                        {...params}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        />
                                                                    )}
                                                                />
                                                            </>
                                                        )}
                                                        {item.html_element === "TextField" && (
                                                            <>
                                                                <InputLabel required={item.required} id="label">
                                                                    {item.label}
                                                                </InputLabel>
                                                                <TextField
                                                                    size="small"
                                                                    required={item.required}
                                                                    placeholder={item.label}
                                                                    name={item.name}
                                                                    onChange={onChange}
                                                                    error={error.txt_service}
                                                                    FormHelperTextProps={{
                                                                        style: { textAlign: "right" },
                                                                    }}
                                                                    helperText={
                                                                        error.txt_service ? "Service is required" : ""
                                                                    }
                                                                    id="outlined-basic"
                                                                    fullWidth={true}
                                                                    value={addService[item.name]}
                                                                    variant="outlined"
                                                                />
                                                            </>
                                                        )}
                                                        {item.html_element === "TextArea" && (
                                                            <>
                                                                <InputLabel id="label">{item.label}</InputLabel>

                                                                <TextField
                                                                    placeholder={item.label}
                                                                    name={item.name}
                                                                    onChange={onChange}
                                                                    multiline
                                                                    rows={5}
                                                                    style={{ marginBottom: -10 }}
                                                                    id="outlined-basic"
                                                                    fullWidth={true}
                                                                    value={addService[item.name]}
                                                                    variant="outlined"
                                                                />
                                                            </>
                                                        )}
                                                        {item.html_element === "switch" && (
                                                            <>
                                                                <span className={classes.activeText}>
                                                                    Active Status
                                                                </span>
                                                                <Switch
                                                                    onClick={onSetActive}
                                                                    checked={addService[item.name]}
                                                                    name={item.name}
                                                                    fullWidth={true}
                                                                    inputProps={{
                                                                        "aria-label": "primary checkbox",
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            </>
                                                        )}
                                                    </GridItem>
                                                </>
                                            );
                                        })}
                                    </GridContainer>
                                </div>
                            </MasterModel>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs="12">
                    {loading ? (
                        <Box mt={10} width="100%" textAlign="center">
                            <CircularProgress />
                        </Box>
                    ):(
                        <Card className={classes1.headerCard}>
                            <CardHeader className={classes1.TbheaderCdhd} style={{ height: 60 }}>
                                <GridContainer justifyContent="space-between" alignItems="center">
                                    <GridItem>
                                        <h4 className={classes1.headerCdTitle}>Services</h4>
                                    </GridItem>
                                    <GridItem style={{ cursor: "pointer" }}>
                                        <IconButton variant="text" onClick={() => onhandleExportToExcel(services)} >
                                        <Tooltip title="Export to Excel">
                                            <img src={require("../../../assets/img/excel.png").default} />
                                        </Tooltip>
                                        </IconButton>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody
                                style={{ height: "auto", maxHeight: 480, padding: 10 }}
                                className={clxs(classes.customScroolBar)}
                            >
                                <MuiTable
                                    onClickEdit={onUpdateService}
                                    onClickDelete={onDeleteService}
                                    columns={headerData}
                                    rows={services}
                                />
                            </CardBody>
                        </Card>
                    )}
                </GridItem>
            </GridContainer>
        </ThemeProvider>
        
    );
}


export default ServicePage;