import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate, timeToUnix, IstTime } from "../views/Pages/HelperComponent/utils";


export const getIntransit = async (onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/report/inTransitReport", {
       
      });
      if (res.status === 200) {
        const r = res.data;
        let inTransit_list = [];
        r.map((r, i) => {
          console.log(r, "sen3011");
  
          inTransit_list.push({
            id: i + 1,
            vehicle_no: r.vehicle_no,            
            transaction_no: r.transaction_no,
            driver_name: r.driver_name,
            driver_no: r.driver_no,
            advance_date:dateFormate(r.advance_date)
         
          });
        });
        onSuccess(inTransit_list);
      } else {
        onFailure("Something Wrong! Please Try again later");
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };