//Parking
import webApi from "./webApi/webApi";
export const getAllParking = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/parking/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allParkings = [];
      r.map((r, i) => {
        allParkings.push({
        
            Parking_id: i + 1,
            Parking_id:r.Parking_id,
            Parking: r.Parking_name,
            Parking_details: r.details,
          action_items: r.action_items,
          Parking_status: r.active_status,
          Parking_action: "action",
        });
      });
      return onSuccess(allParkings);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postParking = async (Parking,inserted_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/parking/insert", {
        Parking_name: Parking.txt_Parking,
      details: Parking.txt_details,
      active_status: Parking.switch_active_btn ? "Y" : "N",
      inserted_by_id:inserted_by_id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  }
  
  catch (error) 
  {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updateParking = async (Parking, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/parking/update", {
        Parking_id: Parking.Parking_id,
        Parking_name: Parking.txt_Parking,
      details: Parking.txt_details,
      active_status: Parking.switch_active_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r,"sank1008=>");
      onSuccess(r);
    } 
   
    else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
export const deleteParking = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/parking/delete", {
        Parking_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


export const getListParking = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/Parking/list", {
   
    });
    if (res.status === 200) {
      const r = res.data;
      
      let arr = [];
      r.map((r, i) => {
        arr.push({
          value: r.Parking_id,
          label: r.Parking,
        });
      });
      return onSuccess(arr);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }

  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};