import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";

export const postMaterial = async (info, inserted_by_id, onSuccess, onFailure) => {
  try {
    console.log(info, "info")
    const res = await webApi.post("/master/material/insert", {
      material_id: info.material_id,
      material_name: info.txt_material,
      location: info.ddl_location_label,
      location_id: info.ddl_location_id,
      hsn: info.txt_hsn,
      gst: info.txt_gst,
      effective_from_date: timestamp(info.txt_from_date),
      effective_to_date: timestamp(info.txt_to_date),
      details: info.txt_details,
      active_status: info.switch_active_status ? "Y" : "N",
      inserted_by_id: inserted_by_id,
    });

    if (res.status === 200) {
      const r = res.data;

      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    // onFailure("Something Wrong! Please Try again later " + error);
  }
};





export const getAllMaterial = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/material/list", {
      keyword_pharse: q?.txt_keyword_pharse,

    });
    if
      (res.status === 200) {
      const r = res.data;
      console.log(r, "list")

      let all_material = [];
      r.map((r, i) => {
        all_material.push({
          id: i + 1,
          material_id: r.material_id,
          material_name: r.material_name,
          location: r.location,
          hsn: r.hsn,
          gst: r.gst,
          vehicle_purchase_date: r.vehicle_purchase_date,
          vehicle_no: r.vehicle_no,
          effective_to_date: r.effective_to_date,
          effective_from_date: r.effective_from_date,
          details: r.details,
          status: r.active_status,
          action_items: r.action_items,
          action: "action",
        });
      });
      return onSuccess(all_material);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};


export const deleteVehicle = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vehicle/delete", {
      vehicle_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};



export const updateMaterial = async (info, onSuccess, onFailure) => {

  try {
    const res = await webApi.post("/master/material/update", {
      material_id: info.material_id,
      material_name: info.txt_material,
      location: info.ddl_location_label,
      location_id: info.ddl_location_id,
      hsn: info.txt_hsn,
      gst: info.txt_gst,
      effective_from_date: timestamp(info.txt_from_date),
      effective_to_date: timestamp(info.txt_to_date),
      details: info.txt_details,
      active_status: info.switch_active_status ? "Y" : "N",

    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};




export const getAllLocation = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/location/list", {
      keyword_pharse: q?.txt_keyword_phrase,

    });
    if
      (res.status === 200) {
      const r = res.data;


      let all_location = [];
      r.map((r, i) => {
        all_location.push({
          location_type_id: r.location_type_id,
          value: r.location_id,
          label: r.location,

        });
      });
      return onSuccess(all_location);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};
