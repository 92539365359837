import webApi from "./webApi/webApi";
import { dateFormate } from "views/Pages/HelperComponent/utils";
import { timestamp } from "./Utils/utils";
import { currencyFormate } from "views/Pages/HelperComponent/utils";

export const getAllJournal = async (onSuccess, onFailure,q) => {
  try {
    console.log(q?.ddl_ledger.label,"s1s")
    const res = await webApi.post("/master/journal/list", {
      ledger_name: q?.ddl_ledger.label,
      voucher_no:q.txt_voucher_no,

      from_date: timestamp(q.txt_from_date),
      to_date: timestamp(q.txt_to_date),
    });
    if (res.status === 200) {
      const r = res.data;
      let allJournal = [];
      let sumJournalAmt =0;
      r.map((r, i) => {
        allJournal.push({
          jorID: i + 1,
          journal_id: r.journal_id,
          jorDate: dateFormate(r.voucher_date),
          jorNo: r.voucher_no,
          
          // jorMode: r.mode,
          // jorReference: r.reference_number,
          jorNarration: r.narration,

          jorAmount: r.voucher_amount,
          jorAction: "view-action",
          menu: [
            {
              label: "View",
              link: "#",
            },
            {
              label: "Edit",
              itemEdit: true,
              link: "/admin/account/edit-journal",
            },
          ]
        });
        sumJournalAmt +=r.voucher_amount;
      });
      return onSuccess(allJournal,sumJournalAmt);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updateJournal = async (journal,journalDetails,narration,DrTotal,edited_by_id,onSuccess, onFailure) => {
  try {
    // console.log(,"sen1234fff")
    // console.log(journal,"sen1234fff")

    const res = await webApi.post("/master/journal/update", {
      journal_id:journal.journal_id,
      narration: narration,
      journal_details: journalDetails,
      voucher_amount: journalDetails[0]?.amount,
      edited_by_id:edited_by_id,
      active_status: narration.switch_status_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const postJournal = async (info, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/journal/insert", {
      receipt_payment_type: "J",
      voucher_date: info.txt_voucher_date,
      voucher_no: info.txt_voucher_no,
      ledger_account_id: info.ddl_ledger,
      mode: info.ddl_mode,
      reference_number: info.txt_reference,
      narration: info.txt_narration,
      amount: info.txt_amount,
      active_status: info.switch_status_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

//For getting journal
export const getJournalByJournalId = async (id,edited_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/journal/list", {
      journal_id:id,
    });
   console.log("Journal",id);
    if (res.status === 200) {
      const r = res.data;
      let IdJournal = [];
      r.map((r,i)=>
        IdJournal.push({
          journal_id:r.journal_id,
          voucher_no :r.voucher_no,
          narration:r.narration,
          journal_details:r.journal_details,
          edited_by_id:edited_by_id,
          
        })
      )
      return onSuccess(IdJournal[0]);
    }
    else{
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

// For Search Journal

export const getSearchJournal = async(onSuccess,onFailure,q) => {

  let customMenu;
  customMenu=[
    
      {
        label: "View",
        clickBtn: true
      },
      {
        label: "Edit",       
        link: "/admin/account/edit-journal",
        itemEdit: true,
        
      },
    
  ]
  try {
    console.log(q.ddl_ledger?.label,"ss")

    const res = await webApi.post("/master/journal/list", {
      voucher_no:q.txt_voucher_no,
      from_date: timestamp(q.txt_from_date),
      to_date: timestamp(q.txt_to_date),
      ledger_name: q.ddl_ledger?.label,
      transaction_id:q.transaction_id,
      
      // voucher_type:,
      // group_id:q.group_id,

    });
    if (res.status === 200) {
      const r = res.data
      let allItems = [];
      let inc = 0;
      let sumJournalAmt = 0;
           
      r.map((r, i) => {
        console.log(r,"transaction_id")
        allItems.push({
          ledID: i + 1,
          // voucher_type: r.ddl_ledger,
          journal_id:r.journal_id,
          ledVoucherNo:r.voucher_no,
          ledDate: dateFormate(r.voucher_date),
          ledVoucherType:"JOURNAL",
          ledJournal:r.journal_details[0].ddl_ledger,
          ledNarration:r.narration,
          ledAmount:(r.voucher_amount).toFixed(2),
          ledAction: "view-action",
          transaction_id:r.transaction_id,
          menu: customMenu,
          journal_details:r.journal_details,
        });
        
        sumJournalAmt += r.voucher_amount;
      });
      
      if (allItems.length) {

        return onSuccess(allItems, sumJournalAmt);
        } else {
          return onFailure("Journal Not Found");
        }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
