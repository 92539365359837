import React, { useEffect, useState } from "react";
import Select from "react-select";
//reducers
import { useStateValue } from "../../../../context/context";
import { actionTypes } from "../../../../context/reducer";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, OutlinedInput } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
// core components
import { InputLabel, TextField, ThemeProvider } from "@material-ui/core";
import { CircleAddBtnAnim } from "../../../Components/CustomButton";
import PageTitle from "../../HelperComponent/PageTitle";
import { currentDate, currentDate1 } from "views/Pages/HelperComponent/utils";
import {
  appDefaultColor,
  appSecondColor,
  whiteColor,
  reactSelectStyles,
  appFontWeight,
  tblBodyHoverColor,
  appFontWeightThin,
} from "assets/jss/material-dashboard-pro-react";
import MuiTable from "../../../Components/MuITable";

//Table
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { appDefaultFamily } from "assets/jss/material-dashboard-pro-react";

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "5px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "10px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {},
    "&:hover": {
      backgroundColor: tblBodyHoverColor,
    },
  },
}))(TableRow);

//Model window
import MasterModel from "views/Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";
//Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CircleAddBtn } from "views/Components/CustomButton";
import Button from "components/CustomButtons/Button.js";
import { CustomCard } from "views/Components/CustomCard";
import { Paper } from "@mui/material";
import CustomButton from "views/Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

//Service
import {
  getAllVehicle,
  getAllExpenses,
  postMaintenanceExpenses,
  getMaintenanceExpensesDetails,
} from "services/MaintenanceExpensesService";
// import { } from "services/MaintenanceExpensesService";

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  floatAddBtn: {
    position: "fixed",
    top: 90,
    right: 40,
    zIndex: 1000,
  },
  pageTitleBox: {
    color: appDefaultColor,
    // color: appSecondColor,
    fontSize: "14px",
    fontWeight: appFontWeight,
    paddingLeft: "20px",
  },
  topHeaderTitle: {
    backgroundColor: whiteColor,
    height: "auto",
    padding: "5px 20px",
    margin: "-20px -30px 10px -30px",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },
}));

const MaintenanceExpensesView = () => {
  const classes = useStyles();
  const location = useLocation();

  const [globalState, dispatch] = useStateValue();
  const [classicModel, setClassicModel] = useState(false);

  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleView, setVehicleView] = useState([]);
  const [check, setCheck] = useState(false);
  const [AllExpenses, setAllExpenses] = useState([]);

  const [AllMaintenanceExpenses, setMaintenanceExpenses] = useState([]);
  const [MaintenanceExpensesDetails, setMaintenanceExpensesDetails] = useState({
    edit: false,
    inflow: false,
    ddl_vehicle_no_id: "",
    ddl_vehicle: "",
    ddl_expenses_id: "",
    ddl_expenses: "",
    txt_amount: "",
    maintenance_order_no: "",
  });
  const [addSearch, setAddSearch] = useState({
    txt_vehicle_no: "",
    txt_expenses: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });

  const [Expenses, setExpenses] = useState([]);
  const [ExpensesAmount, setExpensesAmount] = useState([]);
  const [ExpensesList, setExpensesList] = useState([]);
  const [buttonDisable, setButtonDisabled] = useState(false);
  const [anim, setAnim] = React.useState(false);

  console.log(location.state.row, "sankview01");

  React.useEffect(() => {
    getMaintenanceExpensesDetails(
        addSearch,
        (r) => {
          r.length > 0
            ? setMaintenanceExpenses(r)
            : dispatch({
                type: actionTypes.SET_OPEN_MSG,
                payload: {
                  msg: "Maintence Expenses Details Not Found",
                  msgType: "error",
                },
              });
        },
        (error) => {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: error, msgType: "error" },
          });
        }
      );
    if (location?.state?.row?.maintenance_id) {
      setMaintenanceExpensesDetails({
        maintenance_order_no: location?.row?.maintenance_order_no,
        maintenance_id: location.state?.row?.id,
        ddl_vehicle: location.state?.row?.vehicle_no,
        ddl_vehicle_no_id: location.state?.row?.ddl_vehicle_no_id,
        ddl_expenses_id: location.state?.row?.expense_id,
        ddl_expenses: location.state?.row?.expense,
        txt_amount: location.state?.row?.amount,
      });
    }

    // setVendorForm({
    //   vendor_id: location.state?.row?.vendor_id,
    //   txt_company_name: location.state?.row?.company,
    //   txt_gst_no: location.state?.row?.gst,
    //   txt_mobile: location.state?.row?.mobile,
    //   switch_active_status: location.state?.row?.status,
    //   txt_website: location.state?.row?.website,
    //   txt_openingBalance:location.state?.row?.opening_balance,
    //   ddl_dr_cr_label:location.state?.row?.dr_cr

    // });
  }, []);

  const onSearchExpenses = () => {
    console.log(addSearch, "sen29/setAddSearch");
    getMaintenanceExpensesDetails(
      addSearch,
      (r) => {
        r.length > 0
          ? setMaintenanceExpenses(r)
          : dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Maintence Expenses Details Not Found",
                msgType: "error",
              },
            });
      },
      (error) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: error, msgType: "error" },
        });
      }
    );
  };
  const onCloseModel = () => {
    setClassicModel(false);
    setMaintenanceExpensesDetails({
      edit: false,
      inflow: false,
      ddl_vehicle_no_id: "",
      ddl_vehicle: "",
      maintenance_order_no: "",
    });
    setCheck(false);
    // setExpenses({
    //     expense:'',
    //     expense_id:''
    // });
    // setExpensesAmount({
    //     amount:''
    // });
    setExpensesList([]);
    setVehicleList([]);
    setAllExpenses([]);
    setAnim(false);
  };
  const onSelectExpenses = (e) => {
    console.log(e);
    setAnim(false);
    setExpenses({ expense: e.label, expense_id: e.value });
  };
  //vehicle
  const onSelect = (e) => {
    console.log(e, "sen27");
    setVehicleView(e);
    setCheck(true);
    setMaintenanceExpensesDetails({
      ...MaintenanceExpensesDetails,
      ddl_vehicle_no_id: e.value,
      ddl_vehicle: e.label,
    });
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    name === "txt_amount" ? setExpensesAmount({ amount: value }) : "";
  };
  const onAddExpense = () => {
    setAnim(true);
    setExpensesList([...ExpensesList, { ...Expenses, ...ExpensesAmount }]);
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Tranportation > view Daily Vehicle Maintenance Expenses"
        btnToolTip="Add Maintenance Expenses"
      />
      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <GridContainer justify="space-between" alignItems="center">
            <GridItem className={classes.table} aria-label="customized table">
              <GridContainer>
                <GridContainer>
                  <GridItem xs="5">
                    <InputLabel id="label">Vehicle No.</InputLabel>
                    <Select
                      placeholder="Vehicle No"
                      name="ddl_vehicle_no"
                      onChange={onSelect}
                      id="outlined-basic"
                      fullWidth={true}
                      //   value={MaintenanceExpensesDetails.txt_vehicle_no}
                      variant="outlined"
                      onClick={(e) => {
                        e.target.select();
                      }}
                      value={{
                        value: MaintenanceExpensesDetails.ddl_vehicle_no_id,
                        label: MaintenanceExpensesDetails.ddl_vehicle,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {/* vehicle View */}
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Vehicle No</StyledTableCell>
                      <StyledTableCell align="center">
                        Vehicle Ownership{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        Wheels No.
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Vehicle Type
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Purchase Date
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="left">
                        {vehicleView.label}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {vehicleView.vehicle_ownership}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {vehicleView.no_of_wheels}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {vehicleView.vehicle_type}
                      </StyledTableCell>
                      <StyledTableCell align="center" className={classes.id}>
                        {vehicleView.vehicle_purchase_date}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
                {/* Expenses Add */}
                <GridContainer>
                  <GridItem xs="5">
                    <InputLabel id="label">Expenses</InputLabel>
                    <Select
                      placeholder="Expenses"
                      name="expense"
                      onChange={onSelectExpenses}
                      id="outlined-basic"
                      fullWidth={true}
                      options={AllExpenses}
                      variant="outlined"
                      onClick={(e) => {
                        e.target.select();
                      }}
                      value={{
                        value: MaintenanceExpensesDetails.ddl_expenses_id,
                        label: MaintenanceExpensesDetails.ddl_expenses,
                      }}
                    />
                  </GridItem>
                  <GridItem xs="5">
                    <InputLabel id="label" align="center">
                      Amount
                    </InputLabel>
                    <TextField
                      size="small"
                      name="txt_amount"
                      placeholder="Amount"
                      variant="outlined"
                      onChange={onChange}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={MaintenanceExpensesDetails.txt_amount}
                    />
                  </GridItem>
                </GridContainer>

                {/* Expense List */}

                <TableContainer style={{ alignItem: "center" }}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{ marginLeft: 15 }}
                        ></StyledTableCell>
                        <StyledTableCell align="left">
                          {" "}
                          Expenses{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          Amount{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          Action{" "}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {AllMaintenanceExpenses.length>0 &&
                     AllMaintenanceExpenses.map((r, i) => {
                    {console.log(r,"sank0202")}

                      return (
                        <TableRow>
                          <StyledTableCell align="left">{i + 1}</StyledTableCell>
                          <StyledTableCell align="left">{ r.expense}</StyledTableCell>
                          <StyledTableCell align="center">{r.amount}</StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </Table>
                </TableContainer>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
};

export default MaintenanceExpensesView;
