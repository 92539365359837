import webApi from "./webApi/webApi";
import { currencyFormate, currentDate, timestamp } from "../views/Pages/HelperComponent/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";

function toTitleCase(str) {
  if(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  else {
    return "";
  }
}

export const getSearchReceiptList = async (onSuccess, onFailure,addSearch) => {
  console.log(addSearch,"111")
  try {
    
    const res = await webApi.post("/master/receipt_payment/list", {
      voucher_no:addSearch.txt_voucher_no,
      ledger_id:addSearch.ddl_ledger?.value,
      from_date: timestamp(addSearch.txt_from_date),
      to_date: timestamp(addSearch.txt_to_date),
    });

    if (res.status === 200) {
      const r = res.data;
      let arr = [];
      let inc = 0;
      let sumReciptAmounts = 0;
      console.log(r,"sen0505/resarch")
      r.sort((a,b)=>b.voucher_date - a.voucher_date).map((r, i) => {
        if (r.receipt_payment_type === "R" || r.receipt_payment_type === "BR") {
          arr.push({
            recID: i+1,
            receipt_payment_id: r.receipt_payment_id,
            bank_id: r.bank_id,
            recDate: dateFormate(r.voucher_date),
            recVoucherNo: r.voucher_no,
            recLedger: r.ledger_account_id,
            ledger_account_name: r.ledger_account_name,
            recMode: r.mode,
            recReference: r.reference_number,
            recNarration: r.narration,
            recAmount: r.amount.toFixed(2),
            action_items: r.action_items,
            recAction: "action",
          });
          sumReciptAmounts += r.amount;
        }
        
      });
      if(arr.length){
      return onSuccess(arr,sumReciptAmounts);
    } else {
      return onFailure("receipt not found");
    }
    } else 
    {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllReceipt = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/receipt_payment/list", {});
    if (res.status === 200) {
      const r = res.data;
      let arr = [];
      let inc = 0;
      let sumRecAmounts = 0;
      r.map((r, i) => {
        if (r.receipt_payment_type === "R") {
          inc++;
          arr.push({
            recID: inc,
            receipt_payment_id: r.receipt_payment_id,
            recDate: dateFormate(r.voucher_date),
            recVoucherNo: r.voucher_no,
            recLedger: r.ledger_account_id,
            ledger_account_name: r.ledger_account_name,
            recMode: r.mode,
            recReference: r.reference_number,
            recNarration: r.narration,
            recAmount: r.amount,
            recAdjAmount: currencyFormate(r.adjustment_amount),
            action_items: r.action_items,
            recAction: "action",
          });
          sumRecAmounts += r.amount;
        }
      });
      return onSuccess(arr, sumRecAmounts);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const postReceipt = async (info,inserted_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/receipt_payment/insert", {
      receipt_payment_type: "R",
      voucher_date: timestamp(info.txt_voucher_date),
      ledger_account_id: Number(info.ddl_ledger),
      mode: info.ddl_mode_label,
      bank_id:info.ddl_mode_label ==="Bank" ? info.bank_id : 2 ,
      reference_number: info.txt_reference,
      narration: info.txt_nar,
      amount: Number(info.txt_amount),
      adjustment_amount: 0,
      active_status: info.switch_status_btn ? "Y" : "N",
      inserted_by_id:inserted_by_id,
    });

    const ledgerUpdateParty = await webApi.post("/master/ledger_account/updateLedgerClosing",{
      ledger_account_id:Number(info.ddl_ledger),
      amount:-Number(info.txt_amount)
    })
    
    const ledgerUpdateBank = await webApi.post("/master/ledger_account/updateLedgerClosing",{
      ledger_account_id:info.ddl_mode_label ==="Bank" ? info.bank_id : 2,
      amount:Number(info.txt_amount)
    })

    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const postAdjReceipt = async (info,inserted_by_id, onSuccess, onFailure) => {
  
  console.log(inserted_by_id,"sen0505")
  try {
    const res = await webApi.post("/master/receipt_payment/insert", {
      receipt_payment_type: "BR",
      voucher_date: timestamp(info.txt_voucher_date),
      ledger_account_id:  Number(info.ddl_ledger),
      mode: info.ddl_mode_label,
      bank_id:2696,
      reference_number: info.txt_reference,
      narration: info.txt_nar,
      amount: Number(info.txt_amount),
      inserted_by_id:inserted_by_id,
      active_status: info.switch_status_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updateReceipt = async (info,edited_by_id, onSuccess, onFailure) => {
  console.log(info,"sen050593")
  try {
    const res = await webApi.post("/master/receipt_payment/update", {
      receipt_payment_id: info.receipt_payment_id,
      voucher_no: info.txt_voucher_no,
      voucher_date: timestamp(info.txt_voucher_date),
      ledger_account_id: Number(info.ddl_ledger),
      bank_id: info.ddl_mode_label === "Bank" ? info.bank_id : 2 ,
      mode: info.ddl_mode_label,
      reference_number: info.txt_reference,
      amount: info.txt_amount,
      // adjustment_amount: info.txt_adjamount,
      narration: info.txt_nar,
      edited_by_id:edited_by_id,
      active_status: info.switch_status_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updateReceiptAdj = async (info, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/receipt_payment/update", {
      receipt_payment_id: info.receipt_payment_id,
      voucher_no: info.txt_voucher_no,
      voucher_date: timestamp(info.txt_voucher_date),
      ledger_account_id: Number(info.ddl_ledger),
      mode: info.ddl_mode,
      reference_number: info.txt_reference,
      amount: info.txt_amount,
      // adjustment_amount: info.txt_adjamount,
      narration: info.txt_nar,
      active_status: info.switch_status_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const deleteReceipt = async (
  receipt_paymentId,
  onSuccess,
  onFailure
) => {
  try {
    const res = await webApi.post("/master/receipt_payment/delete", {
      receipt_payment_id: receipt_paymentId,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


export const getListLedgerAccount = async (onSuccess, onFailure,ledger_group_id,) => {

  try {
    const res = await webApi.post("/master/ledger_account/list", {
      short_data: true,
      ledger_group_id:ledger_group_id,
    });
    console.log("sen20042allLedAcc", res);
    if (res.status === 200) {
      const r = res.data;
      let allLedgerAccount = [];
      r.map((r, i) => {
        allLedgerAccount.push({
          value: r.ledger_account_id,
          label: toTitleCase(r.ledger_account),
          ledger_group_id: r.ledger_group_id,
          opening_balance: r.opening_balance,
        });
      });
      console.log("sen20042allLedAcc", allLedgerAccount);
      return onSuccess(allLedgerAccount);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export  const getListLedgerAccountwithOutSundayDebtors = async (onSuccess, onFailure, ledger_group_id) => {
  try {
    const res = await webApi.post("/master/ledger_account/list", {
      short_data: true,
      ledger_group_id: ledger_group_id,
    });
    console.log("sen20042allLedAcc", res);
    if (res.status === 200) {
      const r = res.data;
      let allLedgerAccount = [];
      r.map((r, i) => {
        if ( r.ledger_group_id !== 4) {
          allLedgerAccount.push({
            value: r.ledger_account_id,
            label: toTitleCase(r.ledger_account),
            ledger_group_id: r.ledger_group_id,
            opening_balance: r.opening_balance,
          });
        }
      });
      console.log("sen20042allLedAcc", allLedgerAccount);
      return onSuccess(allLedgerAccount);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

//bank
export const getListLedgerAccountByGroupId = async (onSuccess, onFailure,ledger_group_id) => {
  try {
    const res = await webApi.post("/master/ledger_account/list", {
      short_data: true,
      ledger_group_id:3,
      
    });
    if (res.status === 200) {
      const r = res.data;
      let allLedgerAccount = [];
      r.map((r, i) => {
        allLedgerAccount.push({
          value: r.ledger_account_id,
          label: toTitleCase(r.ledger_account),
          ledger_group_id: r.ledger_group_id,
          opening_balance: r.opening_balance,
         
        });
      });
      console.log("allLedAcc", allLedgerAccount);
      return onSuccess(allLedgerAccount);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};