import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";
import { currentDate } from "../views/Pages/HelperComponent/utils";


export const postDistanceCovered = async (info,inserted_by_id, onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/distance_covered/insert", {
       
       
        vehicle_id: info.ddl_Vehicle_id,
        vehicle_no: info.ddl_Vehicle_label,
        month_id: info.ddl_month_id,
        month_name: info.ddl_month_label,   
        year_id: info.ddl_year_id,
        year_name: info.ddl_year_label,  

        distance: info.txt_distance,
        narration: info.txt_nar,     
        inserted_by_id:inserted_by_id,
      });
  
    
  
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };


export const getAllVehicleForDistance = async (onSuccess, onFailure) => {
    try {
  
      const res = await webApi.post("/master/vehicle/distance/list", {

  
      });
      if 
      (res.status === 200)
       {
        const r = res.data;
         
  
        let all_vehicle = [];
        r.map((r, i) => {
            all_vehicle.push({
            value:r.vehicle_id,
            label:r.vehicle_no,
          });
        });
        return onSuccess(all_vehicle);
        
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };





//   export const getSearchDistanceCoveredtList = async (onSuccess, onFailure) => {
//     try {
//       const res = await webApi.post("/master/distance_covered/list", {
//         // You can uncomment and add your request parameters here if needed
//         // voucher_no: addSearch.txt_voucher_no,
//         // ledger_id: addSearch.ddl_ledger?.value,
//         // from_date: timestamp(addSearch.txt_from_date),
//         // to_date: timestamp(addSearch.txt_to_date),
//       });

//    function deriveYearFromMonthId(monthId) {
    
//     if (typeof monthId !== 'string') {
      
//         return null; 
//     }

//     const parts = monthId.split('-');

//     if (parts.length === 2 && parts[0].length === 4 && parts[1].length === 2) {
//         return parseInt(parts[0]);
//     } else {
        
//         return null; 
//     }
// }
  
  
//       if (res.status === 200) {
//         const data = res.data;
//         let groupedData = {};
    
//         data.forEach((item) => {
//             const vehicleId = item.vehicle_id;
//             const monthId = item.month_id;
    
//             // Assuming there is a 'year' property in the data, or you can derive it from the month_id
//             const year = item.year || deriveYearFromMonthId(monthId);
    
//             if (!groupedData[vehicleId]) {
//                 groupedData[vehicleId] = {
//                     dis_ID: Object.keys(groupedData).length + 1,
//                     vehicle_id: vehicleId,
//                     Vehicle_no: item.vehicle_no,
//                     dis_jan: 0,
//                     dis_feb: 0,
//                     dis_mar: 0,
//                     dis_apr: 0,
//                     dis_may: 0,
//                     dis_june: 0,
//                     dis_july: 0,
//                     dis_aug: 0,
//                     dis_sep: 0,
//                     dis_oct: 0,
//                     dis_nov: 0,
//                     dis_dec: 0,
//                     dis_Narration: item.narration,
//                     action_items: item.action_items,
//                     recAction: "action",
//                     dis_total: 0,
//                     years: {}, // Initialize years object to store total distance per year
//                 };
//             }
    
//             // Update the distance for the specific month
//             groupedData[vehicleId][`dis_${monthIdToMonthName(monthId)}`] += item.distance;
    
//             // Update the total distance for the specific year
//             if (!groupedData[vehicleId].years[year]) {
//                 groupedData[vehicleId].years[year] = 0;
//             }
//             groupedData[vehicleId].years[year] += item.distance;
    
//             // Update the total distance across all months
//             groupedData[vehicleId].dis_total += item.distance;
//         });
    
//         const result = Object.values(groupedData);
    
//         return onSuccess(result);
//     } else {
//         return onFailure("Distance covered not found");
//     }
    
//     } catch (error) {
//       onFailure("Something went wrong! Please try again later. " + error.message);
//     }
//   };
  
  
  function monthIdToMonthName(monthId) {
    const monthNames = [
      "jan", "feb", "mar", "apr", "may", "june", "july", "aug", "sep", "oct", "nov", "dec"
    ];
    return monthNames[monthId - 1] || "--";
  }

  export const getSearchDistanceCoveredtList = async (onSuccess, onFailure) => {
    try {
        const res = await webApi.post("/master/distance_covered/list", {
            // parameters here
        });

        if (res.status === 200) {
            const data = res.data;
            let groupedData = {};

            data.forEach((item) => {
                const vehicleId = item.vehicle_id;
                const year = item.year_name;
                const monthId = item.month_id;

                const yearKey = `${vehicleId}_${year}`;

                if (!groupedData[yearKey]) {
                    groupedData[yearKey] = {
                        dis_ID: Object.keys(groupedData).length + 1,
                        vehicle_id: vehicleId,
                        Vehicle_no: item.vehicle_no,
                        dis_year: year,
                        dis_jan: 0,
                        dis_feb: 0,                      
                        dis_mar: 0,
                        dis_apr: 0,
                        dis_may: 0,
                        dis_june: 0,
                        dis_july: 0,
                        dis_aug: 0,
                        dis_sep: 0,
                        dis_oct: 0,
                        dis_nov: 0,
                        dis_dec: 0,
                        dis_Narration: item.narration,
                        action_items: item.action_items,
                        recAction: "action",
                        dis_total: 0,
                    };
                }

                groupedData[yearKey][`dis_${monthIdToMonthName(monthId)}`] += item.distance;
                groupedData[yearKey].dis_total += item.distance;
            });

            const result = Object.values(groupedData);

            return onSuccess(result);
        } else {
            return onFailure("Distance covered not found");
        }
    } catch (error) {
        onFailure("Something went wrong! Please try again later. " + error.message);
    }
};



//   export const getSearchDistanceCoveredtList = async (onSuccess, onFailure) => {
//     try {
//         const res = await webApi.post("/master/distance_covered/list", {
//             // parameters here
//         });

       

    

//         if (res.status === 200) {
//             const data = res.data;
//             let groupedData = {};

//             data.forEach((item) => {
//                 const vehicleId = item.vehicle_id;
                
//                 const monthId = item.month_id;

              
                

//                 if (!groupedData[vehicleId]) {
//                     groupedData[vehicleId] = {
//                         dis_ID: Object.keys(groupedData).length + 1,
//                         vehicle_id: vehicleId,
//                         Vehicle_no: item.vehicle_no,
//                         dis_year:item.year_name,
//                         dis_jan: 0,
//                         dis_feb: 0,
//                         dis_mar: 0,
//                         dis_apr: 0,
//                         dis_may: 0,
//                         dis_june: 0,
//                         dis_july: 0,
//                         dis_aug: 0,
//                         dis_sep: 0,
//                         dis_oct: 0,
//                         dis_nov: 0,
//                         dis_dec: 0,
//                         dis_Narration: item.narration,
//                         action_items: item.action_items,
//                         recAction: "action",
//                         dis_total: 0,
                        
//                     };
//                 }

               
//                 groupedData[vehicleId][`dis_${monthIdToMonthName(monthId)}`] += item.distance;

               
              

                
//                 groupedData[vehicleId].dis_total += item.distance;
//                 // groupedData[vehicleId].dis_year = year;
//             });

//             const result = Object.values(groupedData);

//             return onSuccess(result);
//         } else {
//             return onFailure("Distance covered not found");
//         }

//     } catch (error) {
//         onFailure("Something went wrong! Please try again later. " + error.message);
//     }
// };


  

//   export const getSearchDistanceCoveredtList = async (onSuccess, onFailure) => {
//     // console.log(addSearch,"111")
//     try {
      
//       const res = await webApi.post("/master/distance_covered/list", {
//         // voucher_no:addSearch.txt_voucher_no,
//         // ledger_id:addSearch.ddl_ledger?.value,
//         // from_date: timestamp(addSearch.txt_from_date),
//         // to_date: timestamp(addSearch.txt_to_date),
//       });
  
//       if (res.status === 200) {
//         const r = res.data;
//         let arr = [];
       
      
//         console.log(r,"sen0505/resarch")
//         r.map((r, i) => {
          
//             arr.push({
//                 dis_ID: i+1,
//                 vehicle_id: r.vehicle_id,
//                 Vehicle_no: r.vehicle_no,
//                 dis_jan: r.month_id === 1 ? r.distance : "--",
//                 dis_feb: r.month_id === 2 ? r.distance : "--",
//                 dis_mar: r.month_id === 3 ? r.distance : "--",
//                 dis_apr: r.month_id === 4 ? r.distance : "--",
//                 dis_may: r.month_id === 5 ? r.distance : "--",
//                 dis_june: r.month_id === 6 ? r.distance : "--",
//                 dis_july: r.month_id === 7 ? r.distance : "--",
//                 dis_aug: r.month_id === 8 ? r.distance : "--",
//                 dis_sep: r.month_id === 9 ? r.distance : "--",
//                 dis_oct: r.month_id === 10 ? r.distance : "--",
//                 dis_nov: r.month_id === 11 ? r.distance : "--",
//                 dis_dec: r.month_id === 12 ? r.distance : "--",

             
//               dis_Narration: r.narration,            
//               action_items: r.action_items,
//               recAction: "action",
//             });
           
          
          
//         });
       
//         return onSuccess(arr);
//       } else {
//         return onFailure("distance covered not found");
//       }
      
//     } catch (error) {
//       onFailure("Something Wrong! Please Try again later " + error);
//     }
//   };
