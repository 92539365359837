import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import { dateFormate } from "../views/Pages/HelperComponent/utils";

export const postpurchaseReturnTyre = async (
  purchaseReturnTyre,
  newAddTyreDetails,
  details,
  inserted_by_id,
  onSuccess,
  onFailure
) => {
  console.log(purchaseReturnTyre, "sen22122022");
  try {
    const res = await webApi.post("/master/purchaseRepairTyre/insert", {
      tyre_details: details,
      new_tyre:newAddTyreDetails,
      bill_image_path: purchaseReturnTyre.bill_image_path,
      bill_image_name: purchaseReturnTyre.bill_image_name,      
      vendor_id: purchaseReturnTyre.ddl_vendor_id,
      vendor_label: purchaseReturnTyre.ddl_vendor_label,
      bill_no: purchaseReturnTyre.txt_bill_no,
      bill_date: timestamp(purchaseReturnTyre.bill_date),
      bill_amount: purchaseReturnTyre.txt_bill_amount,
      remarks:purchaseReturnTyre.txt_remarks,
      inserted_by_id: inserted_by_id,
      tyre_date: timestamp(purchaseReturnTyre.tyre_date),
    });

    if (res.status === 200) {
      onSuccess(res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getPurchaseReturnTyreList = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/purchaseRepairTyre/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allData = [];
      r.map((r, i) => {
        allData.push({
          id: i + 1,
          purchaseReturnTyre_id: r.purchaseReturnTyre_id,
          vendor_id:r.vendor_id,
          vendor_label:r.vendor_label,
          bill_no:r.bill_no,
          date:dateFormate(r.bill_date),
          bill_amount:r.bill_amount,
          tyre_date:r.tyre_date,
          tyre_details:r.tyre_details,
          new_tyre:r.new_tyre,
          action_items: r.action_items,
          action: "action",
        });
      });
      onSuccess(allData);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getRepairBarcodeById = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/repairTyre/list", {});

    if (res.status === 200) {
      const r = res.data;
      let allrepair = [];
      r.map((v, i) => {
        console.log(r, "sen0909/search");
        allrepair.push({
          value: v.barcode_id,
          label: v.barcode,
          company_name: v.company_name,
          model_name: v.model_name,
          position: v.position,
          vendoramount: v.vendoramount,
        });
      });

      return onSuccess(allrepair);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getEditPurchaseReturnTyre = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/repairTyre/details", {
      purchaseReturnTyre_id: id,
    });
    console.log(res, "sankres");

    if (res.status === 200) {
      const r = res.data;

      return onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const updatePurchaseReturnTyre = async (
  id,
  purchaseReturnTyre,
  inserted_by_id,
  onSuccess,
  onFailure
) => {
  try {
    const res = await webApi.post("/master/repairTyre/update", {
      purchaseReturnTyre_id:id,
      tyre_details: details,
      bill_image_path: purchaseReturnTyre.bill_image_path,
      bill_image_name: purchaseReturnTyre.bill_image_name,      
      vendor_id: purchaseReturnTyre.ddl_vendor_id,
      vendor_label: purchaseReturnTyre.ddl_vendor_label,
      bill_no: purchaseReturnTyre.txt_bill_no,
      bill_date: timestamp(purchaseReturnTyre.bill_date),
      bill_amount: purchaseReturnTyre.txt_bill_amount,
      inserted_by_id: inserted_by_id,
      tyre_date: timestamp(purchaseReturnTyre.tyre_date),
      inserted_by_id: inserted_by_id,
    });

    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
