import webApi from "./webApi/webApi";

export const getAllUom = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/uom/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allUoms = [];
      r.map((r, i) => {
        allUoms.push({
          id: i + 1,
          uom_id: r.uom_id,
          Unit: r.unit,
         
          action_items: r.action_items,
          status: r.active_status,
          action: "action",
        });
      });
      return onSuccess(allUoms);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postUOM = async (uom,inserted_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/uom/insert", {
      
      unit: uom.txt_unit,
     
      active_status: uom.switch_active_btn ? "Y" : "N",
      inserted_by_id:inserted_by_id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updateUOM = async (uom, onSuccess, onFailure) => {
  //console.log(uom,"303012")
  try {
    const res = await webApi.post("/master/uom/update", {
      uom_id: uom.uom_id,
      unit: uom.txt_unit,
    
      active_status: uom.switch_active_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      //console.log(r,"303013")
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
export const deleteUOM = async (uomId, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/uom/delete", {
      uom_id: uomId,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getListUom = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/uom/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allUom = [];
      
      let allAltUom = [];
      r.map((r) => {
        allUom.push({
          value: r.uom_id,
          label: r.unit,
        

        });
      });
      onSuccess(allUom,allAltUom);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
