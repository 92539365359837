import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";


export const getAllLocation = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/location/list", {
      keyword_pharse: q?.txt_keyword_phrase,

    });
    if
      (res.status === 200) {
      const r = res.data;


      let all_location = [];
      r.map((r, i) => {
        all_location.push({
          id: i + 1,
          value: r.location_id,
          label: r.location,
          location_type: r.location_type,
        });
      });
      if (all_location.length > 0) {
        return onSuccess(all_location)
      } else {
        onFailure("Location Not Found" + res.data);
      }
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postRoute = async (routes, id, onSuccess, onFailure) => {
  console.log(routes, "sen0711")
  try {
    const res = await webApi.post("master/routes/insert", {
      inserted_by_id: id,
      delivery_location: routes.ddl_delivery_location,
      delivery_location_id: routes.ddl_delivery_location_id,
      loading_loaction: routes.ddl_loading_loaction,
      loading_loaction_id: routes.ddl_loading_loaction_id,
      truck_type_id: routes.ddl_truck_type_id,
      truck_type: routes.ddl_truck_type,
      wheel: routes.wheels,
      wheel_id: routes.wheels_id,
      amount: routes.txt_amount,
    })

    if (res.data) {
      onSuccess(res.data)
    }
    else {
      onFailure("Something Wrong! Please Try again later")
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error)
  }
}


export const updateRoute = async (routes, id, onSuccess, onFailure) => {
  console.log(routes, "sen0711")
  try {
    const res = await webApi.post("master/routes/update", {
      edited_by_id: id,
      route_id:routes.route_id,
      delivery_location: routes.ddl_delivery_location,
      delivery_location_id: routes.ddl_delivery_location_id,
      loading_loaction: routes.ddl_loading_loaction,
      loading_loaction_id: routes.ddl_loading_loaction_id,
      truck_type_id: routes.ddl_truck_type_id,
      truck_type: routes.ddl_truck_type,
      wheel: routes.wheels,
      wheel_id: routes.wheels_id,
      amount: routes.txt_amount,
    })

    if (res.data) {
      onSuccess(res.data)
    }
    else {
      onFailure("Something Wrong! Please Try again later")
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error)
  }
}

export const getAllRouteLocation = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/routes/list", {
      // keyword_pharse: q?.txt_keyword_phrase,

    });
    if (res.status === 200) {
      const r = res.data;


      let all_location = [];
      r.map((r, i) => {
        all_location.push({
          id: i + 1,
          route_id: r.route_id,
          route_no: r.route_no,
          delivery_location_id: r.delivery_location_id,
          delivery_location: r.delivery_location,
          loading_loaction_id: r.loading_loaction_id,
          loading_loaction: r.loading_loaction,
          status: r.active_status,
          amount: r.amount,
          wheel: r.wheel,
          wheel_id: r.wheel_id,
          truck_type:r.truck_type,
          truck_type_id:r.truck_type_id,
          action_items: r.action_items,
          action: "action",
        });
      });
      if (all_location.length > 0) {
        return onSuccess(all_location)
      } else {
        onFailure("Location Not Found" + res.data);
      }
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getInBetweenRoutes = async (delivery_location_id, loading_loaction_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/getInBetweenRoutes/list", {
      delivery_location_id: delivery_location_id,
      loading_loaction_id: loading_loaction_id,
    });

    let allRoutes = [];

    if (res.status === 200) {
      const r = res.data
      r.map((r, i) => {
        allRoutes.push({
          id: i + 1,
          route_no: r.route_no,
          delivery_location: r.delivery_location,
          loading_loaction: r.loading_loaction,
          amount: r.amount,
          wheel: r.wheel
        });
      });
      onSuccess(allRoutes)
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }


  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
}


export const deleteroutes = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/route/delete", {
      route_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};