//police
import webApi from "./webApi/webApi";
export const getAllpolice = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/police/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allpolices = [];
      r.map((r, i) => {
        allpolices.push({
        
            policeStation_id: i + 1,
            policeStation_id:r.policeStation_id,
            Police: r.policeStation_name,
            Police_details: r.details,
          action_items: r.action_items,
          Police_status: r.active_status,
          Police_action: "action",
        });
      });
      return onSuccess(allpolices);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postPolice = async (Police,inserted_by_id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/police/insert", {
        policeStation_name: Police.txt_police,
      details: Police.txt_details,
      active_status: Police.switch_active_btn ? "Y" : "N",
      inserted_by_id:inserted_by_id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  }
  
  catch (error) 
  {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updatepolice = async (police, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/police/update", {
        policeStation_id: police.policeStation_id,
        policeStation_name: police.txt_police,
      details: police.txt_details,
      active_status: police.switch_active_btn ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      console.log(r,"sank1008=>");
      onSuccess(r);
    } 
   
    else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};
export const deletepolice = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/police/delete", {
        police_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


export const getListpolice = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/police/list", {
   
    });
    if (res.status === 200) {
      const r = res.data;
      
      let arr = [];
      r.map((r, i) => {
        arr.push({
          value: r.police_id,
          label: r.police,
        });
      });
      return onSuccess(arr);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }

  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};