import { timestamp } from "./Utils/utils";
const moment = require("moment-timezone");

import { dateFormate, currentDate } from "views/Pages/HelperComponent/utils";
import webApi from "./webApi/webApi";

export const getOutstanding = async (q, onSuccess, onFailure) => {
  console.log(q, "sank040423")
  try {
    const res = await webApi.post("/reports/outstandingByCustomer/list", {
      txt_from_date: timestamp(q.txt_from_date),
      txt_to_date: timestamp(q.txt_to_date),
      customer_id: q.ddl_customer.value
    });

    if (res.status === 200) {
      const r = res.data
      let outstanding = []
      r.map((r, i) => {
        outstanding.push({
          id: i + 1,
          company_name: r.company_name,
          sumSalesAmount: Number((r.sumSalesAmount)),
          opening_balance: Number((r.opening_balance)),
          dr_cr_status: r.dr_cr_status,
          current_dr_cr: r.current_dr_cr, 
          closing_balance: Number((r.sumSalesAmount)) - Number(r.receipt_paymentAmount),
          receipt_paymentAmount: Number(r.receipt_paymentAmount)
        })
      })

      onSuccess(outstanding)
    }
  } catch (error) {
    onFailure("Something Went Wrong!!!!" + error)
  }
}



export const getOutstandingDetails = async (q, onSuccess, onFailure) => {
  console.log(q, "sank040423")
  try {
    const res = await webApi.post("/reports/outstandingByCustomerDetails/list", {
      // txt_from_date: timestamp(q.txt_from_date),
      // txt_to_date: timestamp(q.txt_to_date),
      customer_id: q.ddl_customer.value
    });

    if (res.status === 200) {
      const r = res.data
      let outstanding = []
      r.map((r, i) => {
        outstanding.push({
          id: i + 1,
          company_name: r.data[0]?.customer,
          sumSalesAmount: Number((r.data[0]?.total_amount)),
          opening_balance: Number((r.opening_balance)),
          dr_cr_status: r.dr_cr_status,
          current_dr_cr: r.current_dr_cr, 
          closing_balance: Number((r.closing_balance)),
          receipt_paymentAmount: Number(r.payment),
          salesAmount:r.data[0]?.total_amount,
          transaction_id:r.data[0]?.transaction_no,
          receivedAmount:r.payment || 0,
          sales_date:dateFormate(r.data[0]?.sales_date),
          balance : Number((r.data[0]?.total_amount)) - r.payment || 0

        })
      })
      outstanding.sort((a, b) => a.balance === 0 ? -1 : b.balance === 0 ? 1 : 0);

      onSuccess(outstanding)
    }
  } catch (error) {
    onFailure("Something Went Wrong!!!!" + error)
  }
}
