import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { TextField, Checkbox } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { Badge } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import theme from "../../../theme/theme";
import Select from "react-select";
import { ColoseButton } from "views/Components/CustomButton";

import React from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  appFontWeightThin,
  tblBodyHoverColor,
  appFontWeight,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory, useLocation } from "react-router-dom";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CardBody from "components/Card/CardBody.js";
import clxs from "classnames";
import { currentDate } from "../HelperComponent/utils";
import { posttyreDetails } from "services/tyreDetailsService";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { styled } from "@mui/material/styles";
import { updatetyreDetails } from "services/tyreDetailsService";
import { getVehicle } from "services/tyreDetailsService";
// import { getListLedgerAccount } from "services/LedgerAccountService";
import { getSearchVehicleReceipt } from "services/receiptSalesVehicleService";
import {
  postSalesFormPage,
  getListLedgerAccount,
} from "services/receiptSalesVehicleService";
import { getListLedgerAccountByGroupId } from "services/LedgerAccountService";
import { getCustomerById } from "services/receiptSalesVehicleService";
const useStyles1 = makeStyles(styles);
export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "5px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "10px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    border: "1px soild",
    // borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {},
    "&:hover": {
      backgroundColor: tblBodyHoverColor,
    },
  },
}))(TableRow);

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError1: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -3,
    fontWeight: 300,
  },
}));

const ReceiptSalesFormPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [globalState, dispatch] = useStateValue();
  const [bankAccount, setAllBankAccount] = React.useState([]);
  const [bankLedgerEnabled, setBankLedgerEnabled] = React.useState(false);

  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [allTyreDetails, setAllTyreDetails] = React.useState([]);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [ledgerAccount, setAllLedgerAccount] = React.useState([]);
  const [receptVehicleList, setReceptVehicleList] = React.useState([]);
  const [rowIsChecked, setRowIsChecked] = React.useState(false);
  const [searchCriteria, setSearchCriteria] = React.useState({
    edit: false,
    ddl_customer_id: "",
    ddl_customer_label: "",
    Party_Name: "",
    payment_date: currentDate(),
    ddl_mode_id: "",
    ddl_mode_label: "",
    ddl_party_id: "",
    ddl_party_name: "",
    bank_id: "",
    bank_name: "",
    transaction_no: "",
  });

  const [error, setError] = React.useState({
    ddl_mode_id: false,
    ddl_mode_label: false,
  });

  let option = [
    { value: "1", label: "Bank" },
    { value: "2", label: "Cash" },
  ];

  const updateRow = (index, receivedPayment) => {
    setReceptVehicleList((prevList) => {
      const updatedList = [...prevList];
      const row = updatedList[index];
      const salesValue = row.sales_value;
      const balanceLeft = row.balance_left;
      const amount = row.amount;

      // row.already_payment_received = receivedPayment;
      row.balance_left = balanceLeft;
      row.amount = Number(receivedPayment);
      console.log(row, "sankhaupdate");

      return updatedList;
    });
  };

  console.log(globalState?.user, "sankhappp");
  React.useEffect(() => {
    setLoading(true);

    getCustomerById(
      (r) => {
        setAllLedgerAccount(r);
      },

      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );

    getListLedgerAccountByGroupId(
      (r) => {
        setAllBankAccount(r);
      },

      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [refresh]);

  const onSelectModule = (event, value) => {
    console.log(event, "onselect", value.name);

    switch (value.name) {
      case "ddl_customer":
        setSearchCriteria({
          ...searchCriteria,
          ddl_customer_id: event.value,
          ddl_customer_label: event.label,
          ddl_party_id: event.value,
          ddl_party_name: event.label,
        });
        break;
      case "ddl_party":
        setSearchCriteria({
          ...searchCriteria,
          ddl_party_id: event.value,
          ddl_party_name: event.label,
        });
        break;
      default:
        break;
    }
  };

  const onSelectDetails = (name, v) => {
    console.log(name, "sankhamode", v.name);
    switch (v.name) {
      case "ddl_mode":
        // console.log(name, "sankhamode", name.label);
        setSearchCriteria({
          ...searchCriteria,
          ddl_mode_id: name.value,
          ddl_mode_label: name.label,
        });

        if (name.label === "Bank") setBankLedgerEnabled(true);
        else setBankLedgerEnabled(false);
        break;

      case "ddl_bank":
        console.log("bankok", v);
        setSearchCriteria({
          ...searchCriteria,
          bank_id: name.value,
          bank_name: name.label,
        });
        break;

      default:
        break;
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setSearchCriteria({ ...searchCriteria, [name]: value });
  };

  const onClickClose = () => {
    history.push({
      pathname: "/admin/account/receiptSalesVehicle",
    });
  };

  const onClick = (row) => {
    setRowIsChecked((prev) => !prev);
    row.isChecked = !row.isChecked;
  };

  const headerData = [
    {
      id: "_id",
      label: "# ",
      // checkboxField:true,
    },
    {
      id: "_id",
      label: "# ",
      // checkboxField:true,
    },

    {
      id: "transaction_no",
      label: "Trans. No ",
    },
    {
      id: "vehicle_no",
      label: "Vehicle No",
    },
    {
      id: "sales_date",
      label: "Bill Date",
    },
    {
      id: "sales_value",
      label: "Sales Value",
    },
    // {
    //   id: "payment_date",
    //   label: "Payment Date",
    // },

    {
      id: "payment_made",
      label: "Already Payment Received",
      isTextField: true,
    },
    {
      id: "balance",
      label: "Balance Left",
      isTextField: true,
    },
    {
      id: "amount",
      label: "Amount",
      isTextField: true,
    },
  ];

  const onSubmitModel = async (e) => {
    e.preventDefault();
    let journal_details = [];

    try {
      const checkedRows = receptVehicleList.filter((row) => row.isChecked);
      console.log(searchCriteria, "sankhasearchCriteria");

      if (checkedRows.length === 0) {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: "No rows selected", msgType: "error" },
        });
        return;
      }

      for (const row of checkedRows) {
        const payment_details = {
          transaction_no: row.transaction_no,
          vehicle_no: row.vehicle_no,
          sales_date: row.sales_date,
          sales_value: row.sales_value,
          already_payment_received: row.already_payment_received,
          balance_left: row.balance_left, // Corrected from 'balence_left'
          amount: row.amount,
        };

        journal_details = [
          {
            amount: row.amount,
            dr_cr: "Dr",
            dr_cr_id: 1,
            ledger:
              searchCriteria.ddl_mode_label === "Cash"
                ? "Cash"
                : searchCriteria.ddl_customer_label,
            ledger_account_id:
              searchCriteria.ddl_mode_label === "Cash"
                ? 2
                : row.ledger_account_id, // Use row.ledger_account_id instead of receptVehicleList[0]?.ledger_account_id
          },
          {
            amount: row.amount,
            dr_cr: "Cr",
            dr_cr_id: 2,
            ledger: searchCriteria.ddl_customer_label,
            ledger_account_id: row.ledger_account_id, // Use row.ledger_account_id instead of receptVehicleList[0]?.ledger_account_id
          },
        ];

        if (!searchCriteria.ddl_mode_id) {
          setError({
            ddl_mode_id: !searchCriteria.ddl_mode_id,
          });
          return; // Exit if ddl_mode_id is not selected
        } else {
          const res = await postSalesFormPage(
            payment_details,
            searchCriteria,
            journal_details,
            globalState?.user?.serial_id,
            (r) => {
              dispatch({
                type: actionTypes.SET_OPEN_MSG,
                payload: {
                  msg: "ReceiptSales Vehicle added Successfully",
                  msgType: "success",
                },
              });
            },
            (err) => {
              dispatch({
                type: actionTypes.SET_OPEN_MSG,
                payload: { msg: err, msgType: "error" },
              });
              setButtonDisabled(true);
            }
          );
          onClickClose();
        }
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
    }
  };

  // const onSubmitModel = async (e) => {
  //   e.preventDefault();

  //   try {
  //     for (const row of receptVehicleList) {
  //       const formData = {
  //         transaction_no: row.transaction_no,
  //         vehicle_no: row.vehicle_no,
  //         sales_date: row.sales_date,
  //         sales_value: row.sales_value,
  //         already_payment_received: 0,
  //         balence_left: 0,
  //         amount: 0,
  //       };

  //       const res = await postSalesFormPage(
  //         formData,
  //         searchCriteria,
  //         globalState?.user?.serial_id,
  //         (r) => {
  //           dispatch({
  //             type: actionTypes.SET_OPEN_MSG,
  //             payload: {
  //               msg: "ReceiptSales Vehicle added Successfully",
  //               msgType: "success",
  //             },
  //           });
  //           // Handle success here if needed
  //         },
  //         (err) => {
  //           dispatch({
  //             type: actionTypes.SET_OPEN_MSG,
  //             payload: { msg: err, msgType: "error" },
  //           });
  //           setButtonDisabled(true);
  //         }
  //       );

  //       // Handle the response here if needed
  //     }
  //   } catch (error) {
  //     console.error("Error submitting form: ", error);
  //   }
  // };

  //   const onSubmitModel = (e) => {
  //     e.preventDefault();

  //     postSalesFormPage({
  //       transaction_no: headerData.find((item) => item.id === "transaction_no"),
  //       vehicle_no: headerData.find((item) => item.id === "vehicle_no"),
  //       sales_date: headerData.find((item) => item.id === "sales_date"),
  //       sales_value: headerData.find((item) => item.id === "sales_value"),
  //       payment_made: headerData.find((item) => item.id === "payment_made"),
  //       balance: headerData.find((item) => item.id === "balance"),
  //       amount: headerData.find((item) => item.id === "amount"),
  //     },
  //     searchCriteria,
  //      globalState?.user?.serial_id,

  //       (r) => {
  //         dispatch({
  //           type: actionTypes.SET_OPEN_MSG,
  //           payload: {
  //             msg: "ReceiptSales Vehicle added Successfully",
  //             msgType: "success",
  //           },
  //         });

  //         // onCloseModel();

  //         // setSuccessModal(true);
  //       },
  //       (err) => {
  //         dispatch({
  //           type: actionTypes.SET_OPEN_MSG,
  //           payload: { msg: err, msgType: "error" },
  //         });
  // setButtonDisabled(true);

  //       }
  //     );
  //   };

  const onSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    getSearchVehicleReceipt(
      (list, sum) => {
        if (list.length) {
          console.log(list, "sabkhans252");
          setReceptVehicleList(list);

          setLoading(false);
        } else {
          setLoading(false);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, "Journal not found": "info" },
          });
        }
      },
      (err) => {
        setReceptVehicleList([]);

        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
        setLoading(false);
      },
      searchCriteria
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitle title="Account > Receipt Sales Vehicle" />
      <CustomCard cdTitle="Receipt Sales Vehicle Details" filterIcon>
        <GridContainer justify="space-between" alignItems="center">
          <GridItem xs="9">
            <InputLabel id="label">Ledger Account</InputLabel>
            <Select
              options={ledgerAccount}
              placeholder="Select"
              formatGroupLabel={(d) => d.label}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              className={classes.customSelect}
              onChange={onSelectModule}
              name="ddl_customer"
              value={{
                value: searchCriteria.ddl_customer_id,
                label: searchCriteria.ddl_customer_label,
              }}
            />
          </GridItem>
          <GridItem xs="3">
            <div
              style={{
                float: "right",
                display: "flex",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <CustomButton style={{ marginRight: "10px" }} onClick={onSearch}>
                <SearchIcon />
              </CustomButton>
              <CustomButton>
                <RotateLeftIcon />
              </CustomButton>
            </div>
          </GridItem>
        </GridContainer>
        <CustomCard filterIcon>
          <GridContainer className={classes.root}>
            <GridItem xs="12">
              <CardBody
                style={{ height: "auto", maxHeight: 480, padding: 10 }}
                className={clxs(classes.customScroolBar)}
              >
                {/* <MuiTable
                  selectableItemTable={false
                  }
                  onClickEdit={() => {}}
                  columns={headerData}
                  rows={receptVehicleList}
                /> */}

                {headerData.length > 0 ? (
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          {headerData.map((tableHeadData, headindex) => (
                            <StyledTableCell
                              align="center"
                              key={tableHeadData.id}
                            >
                              {tableHeadData.label}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* {receptVehicleList.map((row, i) => (
                          <StyledTableRow key={i}>
                            <StyledTableCell>
                              <Checkbox
                                inputProps={{ "aria-label": "select row" }}
                                onClick={() => onClick(row)}
                                checked={row.isChecked}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {i + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.transaction_no}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.vehicle_no}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.sales_date}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.sales_value}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <TextField
                                name="received_payment"
                                variant="outlined"
                                size="small"
                                                             
                                // defaultValue={0}
                                InputLabelProps={{ shrink: true }}
                                style={{ width: 100, textAlign: "right" }}
                               onChange={(e) => updateRow(i, e.target.value)}
                                value={row.already_payment_received ? row.already_payment_received : 0}
                                readOnly
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <TextField
                                name="balance_left"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                style={{ width: 100, textAlign: "right" }}
                                value={row.balence_left ? row.balence_left : 0}
                                readOnly
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <TextField
                                name="amount"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                style={{ width: 100, textAlign: "right" }}
                                onChange={(e) => {
                                  updateRow(i, e.target.value);
                                }}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))} */}

                        {/* {receptVehicleList
                          .filter(
                            (row) =>
                              row.already_payment_received !== row.sales_value
                          ) */}
                        {receptVehicleList.map(
                          (row, i) => (
                            console.log(row, "sankharow"),
                            (
                              <StyledTableRow key={i}>
                                <StyledTableCell>
                                  <Checkbox
                                    inputProps={{
                                      "aria-label": "select row",
                                    }}
                                    onClick={() => onClick(row)}
                                    checked={row.isChecked}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {i + 1}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.transaction_no}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.vehicle_no}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.sales_date}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row.sales_value}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <TextField
                                    name="received_payment"
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: 100, textAlign: "right" }}
                                    onChange={(e) =>
                                      updateRow(i, e.target.value)
                                    }
                                    value={
                                      row.already_payment_received
                                        ? row.already_payment_received
                                        : 0
                                    }
                                    readOnly
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <TextField
                                    name="balance_left"
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: 100, textAlign: "right" }}
                                    value={
                                      row.balence_left ? row.balence_left : row.sales_value
                                    }
                                    readOnly
                                  />
                                </StyledTableCell>
                                {/* <StyledTableCell align="center">
                                    <TextField
                                      name="amount"
                                      variant="outlined"
                                      size="small"
                                      InputLabelProps={{ shrink: true }}
                                      type="number"
                                      style={{ width: 100, textAlign: "right" }}
                                      onChange={(e) => {
                                        updateRow(i, e.target.value);
                                      }}
                                    />
                                  </StyledTableCell> */}
                                <StyledTableCell align="center">
                                  <TextField
                                    name="amount"
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    type="number"
                                    style={{ width: 100, textAlign: "right" }}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (parseFloat(value) > row.sales_value) {
                                        alert(
                                          "Entered amount is greater than the sales value"
                                        );
                                        
                                        e.target.value = "";
                                      } else {
                                        updateRow(i, value);
                                      }
                                    }}
                                  />
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  ""
                )}
              </CardBody>
            </GridItem>
          </GridContainer>
        </CustomCard>
        <CustomCard filterIcon>
          <GridContainer className={classes.root}>
            <GridItem xs="4" style={{ margin: "5px" }}>
              <InputLabel required={false} id="label">
                Party Name
              </InputLabel>
              <Select
                // options={option}
                size="small"
                formatGroupLabel={(d) => d.label}
                placeholder="Select Module"
                name="ddl_party"
                onChange={onSelectModule}
                id="outlined-basic"
                fullWidth={true}
                variant="outlined"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                // value={oilDetails.trip_no}
                value={{
                  value: searchCriteria.ddl_party_id,
                  label: searchCriteria.ddl_party_name,
                }}
                inputProps={{
                  style: { color: "blue", border: "none", textAlign: "right" },
                }}
              />
            </GridItem>
            <GridItem xs="2" style={{ margin: "5px" }}>
              <InputLabel required={false} id="label">
                Payment Date
              </InputLabel>
              <TextField
                size="small"
                formatGroupLabel={(d) => d.label}
                placeholder="Select Module"
                name="payment_date"
                type="date"
                onChange={onChange}
                id="outlined-basic"
                fullWidth={true}
                variant="outlined"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                value={searchCriteria.payment_date}
                // inputProps={{
                //   style: { color: "blue", border: "none", textAlign: "right" },
                // }}
              />
            </GridItem>

            <GridItem xs="2" style={{ margin: "5px" }}>
              <InputLabel required={false} id="label">
                Mode
              </InputLabel>
              <Select
                options={option}
                formatGroupLabel={(d) => d.label}
                placeholder="Select Module"
                name="ddl_mode"
                onChange={onSelectDetails}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {error.ddl_mode_id && (
                <div className={classes.ddlError1}>Mode is Required</div>
              )}
            </GridItem>

            <GridItem xs="3" style={{ margin: "5px" }}>
              <InputLabel required={false} id="label">
                Bank
              </InputLabel>
              <Select
                options={bankAccount}
                size="small"
                formatGroupLabel={(d) => d.label}
                placeholder="Select Module"
                name="ddl_bank"
                onChange={onSelectDetails}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                isDisabled={!bankLedgerEnabled}
              />
            </GridItem>
          </GridContainer>
        </CustomCard>

        <div style={{ width: "100%" }} className={classes.actionbtns}>
          <CustomButton
            style={{
              float: "right",
              width: "10%",
              marginRight: "10px",
              border: "none",
            }}
            onClick={onSubmitModel}
          >
            Submit
          </CustomButton>

          <ColoseButton
            style={{
              backgroundColor: "red",
              color: "white",
              float: "right",
              width: "10%",
              marginRight: "10px",
              border: "none",
            }}
            onClick={onClickClose}
          >
            Close
          </ColoseButton>
        </div>
      </CustomCard>
    </ThemeProvider>
  );
};
export default ReceiptSalesFormPage;
