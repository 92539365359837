import webApi from "./webApi/webApi";

export const getTotalStats = async (
  onSuccess = () => null,
  onFailure = () => null
) => {

  try {
    const res = await webApi.post("/master/dashboardStatsReport");
    if (res.status === 200) {
      console.log(res, "res==>")
      let data = res.data.data;
      console.log(data, "data==>")

      onSuccess && onSuccess(data);
      return data;
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (e) {
    onFailure("Something Wrong! Please Try again later " + e);
  }
};

export const getSalesStat = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/dashboardReport", {

    });

    if (res.status === 200) {
      console.log("sen2302=>>>", res.data)
      let data = [{
        totalAmount: 0
      }]
      onSuccess(res.data.length > 0 ? res.data : data)
    }
  } catch (error) {
    onFailure("Something Went Wrong!!!!" + error)
  }
}


export const getTotalSalesStat = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/dashBoardReportSalesTotal", {

    });

    if (res.status === 200) {
      console.log("sen2302=>>>", res.data)
      let data = [{
        totalAmount: 0
      }]
      onSuccess(res.data.length > 0 ? res.data : data)
    }
  } catch (error) {
    onFailure("Something Went Wrong!!!!" + error)
  }
}


export const getTotalCash = async(onSuccess,onFailure)=>{
  try {
    const res = await webApi.post("/master/dashboardStatsReportTotalCash",{

    });

    if(res.status === 200){
      console.log("sen2302=>>>",res.data)
      onSuccess(res.data)
    }
  } catch (error) {
    onFailure("Something Went Wrong!!!!" + error)
  }
}


export const getOutStanding = async(onSuccess,onFailure)=>{
  try {
    const res = await webApi.post("/master/dashboardOutstanding",{

    });

    if(res.status === 200){
      const r = res.data
      let outstanding = []
      r.map((r,i)=>{
        console.log(r,"sank2502")
        outstanding.push({
          outstanding:r.outstanding,
        
        })
      })
     
      onSuccess(res.data)
    }
  } catch (error) {
    onFailure("Something Went Wrong!!!!" + error)
  }
}



export const getTotalBank = async(onSuccess,onFailure)=>{
  try {
    const res = await webApi.post("/master/dashboardStatsReportBank",{

    });

    if(res.status === 200){
      console.log("sen2302=>>>",res.data)
      onSuccess(res.data)
    }
  } catch (error) {
    onFailure("Something Went Wrong!!!!" + error)
  }
}
