import webApi from "./webApi/webApi";
import { timestamp } from "../services/Utils/utils"
import { currentDate } from "../views/Pages/HelperComponent/utils";

function toTitleCase(str) {
  if (str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  else {
    return "";
  }

}

export const getAllEmployee = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/employee/list", {


      // group_id:q?.ddl_group_id,
      keyword_pharse: q?.txt_keyword_pharse,

    });

    if (res.status === 200) {
      const r = res.data;
      let allEmployee = [];
      r.map((v, i) => {
        console.log(r, "sank010967")

        allEmployee.push({
          id: i + 1,
          employee_id: v.employee_id,
          first_name: v.first_name,
          last_name: v.last_name,
          pin: v.pin,
          email: v.email,
          phone_no: v.phone_no,
          whatsapp_no: v.whatsapp_no,
          dob_date: v.dob_date,
          blood_group_name: v.blood_group_name,
          post: v.post,
          post_name: v.post_name,
          state: v.state,
          country: v.country,
          blood_group: v.blood_group,
          blood_group_name: v.blood_group_name,
          city: v.city,
          allAddress: v.address,
          allContacts: v.contact_person,
          allBanks: v.bank_details,
          gender_name: v.gender_name,
          gender: v.gender,
          action_items: v.action_items,
          status: v.active_status,
          action: "action",
        });
      });
      console.log(allEmployee, "sank01096")
      if (allEmployee.length) {
        return onSuccess(allEmployee);
      }
      else {
        onFailure("Employee not found ");
      }
    }
    else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllEmployeeChecking = async (q,onSuccess, onFailure) => {
  try {

    const res = await webApi.post("/master/employee/list", {


      // group_id:q?.ddl_group_id,
      keyword_pharse: q

    });

    if (res.status === 200) {
      const r = res.data;
      let allEmployee = [];
      r.map((v, i) => {
        console.log(r, "sank010967")

        allEmployee.push({
          id: i + 1,
          employee_id: v.employee_id,
          first_name: v.first_name,
          last_name: v.last_name,
          pin: v.pin,
          email: v.email,
          phone_no: v.phone_no,
          whatsapp_no: v.whatsapp_no,
          dob_date: v.dob_date,
          blood_group_name: v.blood_group_name,
          post: v.post,
          post_name: v.post_name,
          state: v.state,
          country: v.country,
          blood_group: v.blood_group,
          blood_group_name: v.blood_group_name,
          city: v.city,
          allAddress: v.address,
          allContacts: v.contact_person,
          allBanks: v.bank_details,
          gender_name: v.gender_name,
          gender: v.gender,
          action_items: v.action_items,
          status: v.active_status,
          action: "action",
        });
      });
      console.log(allEmployee, "sank01096")
      if (allEmployee.length) {
        return onSuccess(allEmployee);
      }
      else {
        onFailure("Employee not found ");
      }
    }
    else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllVendorsChecking = async (q,onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vendor/list", {
      
      keyword_pharse: q

    });

    if (res.status === 200) {
      const r = res.data;
      let allVendors = [];
      r.map((v, i) => {
        console.log(r, "sen0909/search")
        allVendors.push({
          id: i + 1,
          vendor_id: v.vendor_id,
          company: v.company_name,
          // group_name: v.group_name,
          // group_id: v.group_id,
          website: v.website,
          // reference_id: v.reference_id,
          // reference_name: v.reference_name,
          dr_cr: v.dr_cr,
          opening_balance: v.opening_balance,
          gst: v.gst_no,
          name: v.contact_person?.txt_name,
          email: v.contact_person?.txt_email,
          mobile: v.mobile_no,
          whatsapp: v.contact_person?.txt_whatsapp,
          allAddress: v.address,
          allContacts: v.contact_person,
          allBanks: v.bank_details,
          action_items: v.action_items,
          status: v.active_status,
          action: "action",
        });
      });

      if (allVendors.length) {
        return onSuccess(allVendors);
      }
      else {
        onFailure("vendor not found ");
      }
    }
    else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

//for ledger
export const postLedgerAccountFromVendor = async (vendorForm, inserted_by_id, onSuccess, onFailure) => {
  console.log(vendorForm, "from service")
  try {
    const res = await webApi.post("/master/ledger_account/insert", {
      ledger_group_id: 54,
      ledger_account: vendorForm.txt_company_name,
      inserted_by_id: inserted_by_id,
      // alias: customerForm.txt_company_name,
      opening_balance: vendorForm.txt_openingBalance,
      dr_cr_status: vendorForm.ddl_dr_cr,
      // credit_limit: 0,
      as_on_date: timestamp(currentDate()),
      // active_status: customerForm.switch_active_status ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;

      console.log(r, "service return check")

      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const postEmployeeForm = async (
  info,
  allBanks,
  allAddress,
  allStatutory,
  allContacts,
  inserted_by_id,
  onSuccess,
  onFailure,
) => {

  try {
    const res = await webApi.post("/master/employee/insert", {
      // employee_id: info.employee_id,
      first_name: info.txt_first_name,
      last_name: info.txt_last_name,
      phone_no: info.txt_phone_no,
      email: info.txt_email,
      whatsapp_no: info.txt_whatsapp_no,
      pin: info.txt_pin,
      city: info.ddl_city,
      state: info.ddl_state,
      country: info.ddl_country,
      post: info.ddl_post,
      dob_date: info.dob_date,
      blood_group: info.ddl_blood_group,

      blood_group_name: info.ddl_blood_group_label,
      gender: info.ddl_gender,
      gender_name: info.ddl_gender_label,

      address: allAddress,
      bank: allBanks,
      contact: allContacts,
      statutoryForm: allStatutory,

      active_status: info.switch_active_status ? "Y" : "N",
      inserted_by_id: inserted_by_id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updateEmployee = async (
  info,
  inserted_by_id,
  // allContacts,
  // allAddress,
  // allBanks,
  onSuccess,
  onFailure
) => {
  console.log(info, "sen19122022")
  try {
    const res = await webApi.post("/master/employee/update", {
      employee_id: info.employee_id,
      first_name: info.txt_first_name,
      last_name: info.txt_last_name,
      phone_no: info.txt_phone_no,
      email: info.txt_email,
      whatsapp_no: info.txt_whatsapp_no,
      pin: info.txt_pin,
      city: info.ddl_city,
      state: info.ddl_state,
      country: info.ddl_country,
      post: info.ddl_post,
      post_name: info.ddl_post_label,

      // dob_date: info.dob_date,
      blood_group: info.ddl_blood_group,

      blood_group_name: info.ddl_blood_group_label,
      gender: info.ddl_gender,
      gender_name: info.ddl_gender_label,

      //  address:allAddress,
      //  bank:allBanks, 
      //  contact:allContacts,       
      //  statutoryForm:allStatutory,

      active_status: info.switch_active_status ? "Y" : "N",
      edited_by_id: inserted_by_id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const deleteVendor = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vendor/delete", {
      vendor_id: id,
    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

// for Dropdwon list
export const getAllGroup = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/master_group/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allGroup = [];
      r.map((r, i) => {
        if (r.vendor_status === "Y") {
          allGroup.push({
            value: r.master_group_id,
            label: toTitleCase(r.group),
          });
        }
      });
      return onSuccess(allGroup);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllReference = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/reference/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allreferenceAcc = [];
      r.map((r, i) => {
        allreferenceAcc.push({
          value: r.reference_id,
          label: r.name,
        });
      });
      return onSuccess(allreferenceAcc);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getListVendor = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vendor/list", {



    });
    if (res.status === 200) {
      const r = res.data;
      let allvendor = [];
      r.map((r, i) => {
        if (r.company_name && r.vendor_id > 0 && r.company_name) {
          allvendor.push({
            value: r.vendor_id,
            label: toTitleCase(r.company_name),
          });
        }
      });
      return onSuccess(allvendor);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

//FOR Contractor
export const getListContractor = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/vendor/list", {
      group_id: 1986
    });
    if (res.status === 200) {
      console.log("reached555", res)
      const r = res.data;
      let allvendor = [];
      r.map((r, i) => {
        allvendor.push({

          value: r.vendor_id,
          label: r.company_name,
        });
      });

      return onSuccess(allvendor);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


// for post
export const getAllRoleId = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/role/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allRoles = [];
      r.map((r, i) => {
        allRoles.push({

          rolee_id: i + 1,
          value: r.role_id,
          label: r.role,

        });
      });
      return onSuccess(allRoles);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};



export const getListBankId = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/bank/list", {});
    if (res.status === 200) {
      const r = res.data;
      let allBanks = [];
      r.map((r, i) => {
        allBanks.push({

          value: r.bank_id,
          label: r.bank_name,

        });
      });
      return onSuccess(allBanks);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllVendors = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/vendor/list", {
      group_id: q?.ddl_group_id,
      keyword_pharse: q?.txt_keyword_pharse,

    });

    if (res.status === 200) {
      const r = res.data;
      let allVendors = [];
      r.map((v, i) => {
        console.log(r, "sen0909/search")
        allVendors.push({
          id: i + 1,
          vendor_id: v.vendor_id,
          company: v.company_name,
          // group_name: v.group_name,
          // group_id: v.group_id,
          website: v.website,
          // reference_id: v.reference_id,
          // reference_name: v.reference_name,
          dr_cr: v.dr_cr,
          opening_balance: v.opening_balance,
          gst: v.gst_no,
          name: v.contact_person?.txt_name,
          email: v.contact_person?.txt_email,
          mobile: v.mobile_no,
          whatsapp: v.contact_person?.txt_whatsapp,
          allAddress: v.address,
          allContacts: v.contact_person,
          allBanks: v.bank_details,
          action_items: v.action_items,
          status: v.active_status,
          action: "action",
        });
      });

      if (allVendors.length) {
        return onSuccess(allVendors);
      }
      else {
        onFailure("vendor not found ");
      }
    }
    else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


//ledger id of vendor
export const getLedgerByVendorName = async (
  vendor_name,
  onSuccess,
  onFailure
) => {
  // console.log(customer_name,"999d2")
  try {
    const res = await webApi.post("/master/ledger_account/list", {
      // source_id: q?.ddl_source.value,
      ledger_account: vendor_name,
      // keyword_pharse: q.txt_keyword_pharse,
    });

    if (res.status === 200) {
      const r = res.data;
      let allLedg = [];
      // console.log(r,"0001")
      r.map((c, i) => {
        allLedg.push({
          // id: i + 1,
          ledger_id: c.ledger_account_id,
        });
      });
      if (allLedg.length) {
        // console.log(allLedg,"999d3")
        return onSuccess(allLedg);
      } else {
        onFailure("Ledger not Found ");
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const postVendorForm = async (
  info,
  allContacts,
  allAddress,
  allBanks,
  inserted_by_id,
  onSuccess,
  onFailure,
) => {
  try {
    const res = await webApi.post("/master/vendor/insert", {
      // group_id: info.ddl_vendor_group,
      // reference_id: info.ddl_references,
      opening_balance: info.txt_openingBalance ? info.txt_openingBalance : 0,
      dr_cr: info.ddl_dr_cr ? info.ddl_dr_cr : 0,
      company_name: info.txt_company_name,
      mobile_no: info.txt_mobile,
      gst_no: info.txt_gst_no,
      website: info.txt_website,
      // address: allAddress,
      // contact_person: allContacts,
      // bank_details: allBanks,
      active_status: info.switch_active_status ? "Y" : "N",
      inserted_by_id: inserted_by_id,
    });
    if (res.status === 200) {
      const r = res.data;
      const res2 = await webApi.post("/master/ledger_account/insert", {
        ledger_group_id: 2,
        ledger_account: info.txt_company_name,
        inserted_by_id: inserted_by_id,
        // alias: customerForm.txt_company_name,
        opening_balance: info.txt_openingBalance ? info.txt_openingBalance : 0,
        dr_cr_status: info.ddl_dr_cr,
        // credit_limit: 0,
        as_on_date: timestamp(currentDate()),
        // active_status: customerForm.switch_active_status ? "Y" : "N",
        type: "V",
        type_id: r.vendor_id
      })
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const updateVendor = async (
  info,
  LedgerAccountId,
  inserted_by_id,
  // allContacts,
  // allAddress,
  // allBanks,
  onSuccess,
  onFailure
) => {
  console.log(info, "sen19122022")
  try {
    const res = await webApi.post("/master/vendor/update", {
      vendor_id: info.vendor_id,
      // group_id: info.ddl_group,
      // reference_id: info.ddl_references,
      company_name: info.txt_company_name,
      opening_balance: info.txt_openingBalance,
      mobile_no: info.txt_mobile,
      dr_cr: info.ddl_dr_cr,
      gst_no: info.txt_gst_no,
      website: info.txt_website,
      // address: allAddress,
      // contact_person: allContacts,
      // bank_details: allBanks,
      edited_by_id: inserted_by_id,
      active_status: info.switch_active_status ? "Y" : "N",
    });
    if (res.status === 200) {
      const r = res.data;
      const res2 = await webApi.post("/master/ledger_account/insert", {
        ledger_group_id: 2,
        ledger_account: info.txt_company_name,
        edited_by_id: inserted_by_id,
        // alias: customerForm.txt_company_name,
        opening_balance: info.txt_openingBalance ? info.txt_openingBalance : 0,
        dr_cr_status: info.ddl_dr_cr,
        // credit_limit: 0,
        as_on_date: timestamp(currentDate()),
        // active_status: customerForm.switch_active_status ? "Y" : "N",
        type: "V",
        type_id: r.vendor_id
      })
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};