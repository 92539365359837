import webApi from "./webApi/webApi";
import { dateFormate, currentDate } from "views/Pages/HelperComponent/utils";


export const getAllCashBook = async (onSuccess, onFailure, q) => {
    try {
      const res = await webApi.post("/master/cashBook/list", {
        // voucher_from_date: timestamp(q.txt_from_date),
        // voucher_to_date: timestamp(q.txt_to_date) + 86399,
        // ledger_name: q.ddl_ledger_account?.label,
        // ledger_account_id: q.ddl_ledger_account?.value,
      });
      if (res.status === 200) {
        let r = res.data
        let allItems = [];
        console.log(r, "sen3011")
  
        r.map((r, i) => {
          allItems.push({
            ledID: i + 1,
            ledVoucherType: (r.voucher_type ? r.voucher_type : r.receipt_payment_type),
            narraction: r.ledger_account,
            // amount: Math.abs(r.voucher_type === "Journal" ? r.journal_details.amount : r.amount),
            amount: r.amount,
            // bank_id: r.bank_id,
            // ledVoucherNo: r.voucher_no,
            // ledTransectionId: r.transaction_id,
            ledDate: dateFormate(parseInt(r.voucher_date)),
            // particular: r.journal_details ? r.journal_details.ledger : 0,
            // dr_cr: (r.journal_details?.dr_cr_id || r.dr_cr),
            mode: r.mode,
            // ledger_account_for_party: r.ledger_account_for_party,
            // transaction_type: r.transaction_type,
          });
  
        })
  
        console.log("sen3011=>", allItems)
  
        if (allItems.length) {
          return onSuccess(allItems);
        } else {
          return onFailure("Journal Not Found");
        }
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
      // if (res.status === 200) {
      //   let r = res.data
      //   let allItems = [];
      //   let inc = 0;
      //   console.log("bfl", r);
      //   //r.map((o) => { ...o, particular: });
  
      //   r = r.filter((o) => ( !o.journal_details || o.journal_details?.ddl_ledger_id !== q.ddl_ledger_account?.value));
  
  
      //   r.map((r, i) => {
      //     console.log(r,"sen3004/amt1")
      //       allItems.push({
      //         ledID: i + 1,
      //         ledVoucherType: (r.voucher_type ? r.voucher_type : r.receipt_payment_type),
      //         //voucher_no: r.voucher_no,
      //         amount: (r.amount ? r.amount : r.journal_details.amount),
      //         bank_id: r.bank_id,
      //         ledVoucherNo:r.voucher_no,
      //         ledTransectionId:r.transaction_id,
      //         ledDate: dateFormate(parseInt(r.voucher_date)),
      //         // ledVoucherType:"JOURNAL",
      //         particular: r.journal_details?  r.journal_details.ddl_ledger : 0,
      //         dr_cr: r.journal_details ? (r.journal_details?.dr_cr === 1 ? 2 : 1) : r.dr_cr,
      //         mode: r.mode,
      //         ledger_account_for_party:r.ledger_account_for_party,
      //         transaction_type:r.transaction_type,
  
      //         // ledDr_Cr:r.journal_details.dr_cr,
      //         // ledDebit:r.journal_details[0].dr_cr,
      //         // ledCredit:r.journal_details[0].dr_cr,
      //         // jorAmount:r.journal_details.amount,
      //         // jorAction: "view-action",
      //         // menu: customMenu,
  
      //       });
      //   });
  
      //   if (allItems.length) {
      //     console.log("AII1", allItems);
      //     return onSuccess(allItems);
      //     } else {
      //       return onFailure("Journal Not Found");
      //     }
      // } else {
      //   onFailure("Something Wrong! Please Try again later" + res.data);
      // }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };

// export const getSearchReceiptList = async (onSuccess, onFailure,addSearch) => {
//     console.log(addSearch,"111")
//     try {
      
//       const res = await webApi.post("/master/receipt_payment/list", {
//         voucher_no:addSearch.txt_voucher_no,
//         ledger_id:addSearch.ddl_ledger?.value,
//         from_date: timestamp(addSearch.txt_from_date),
//         to_date: timestamp(addSearch.txt_to_date),
//       });
  
//       if (res.status === 200) {
//         const r = res.data;
//         let arr = [];
//         let inc = 0;
//         console.log(r,"sen0505/resarch")
//         r.map((r, i) => {
//           if (r.receipt_payment_type === "R" || r.receipt_payment_type === "BR") {
//             arr.push({
//               recID: i+1,
//               receipt_payment_id: r.receipt_payment_id,
//               bank_id: r.bank_id,
//               recDate: dateFormate(r.voucher_date),
//               recVoucherNo: r.voucher_no,
//               recLedger: r.ledger_account_id,
//               ledger_account_name: r.ledger_account_name,
//               recMode: r.mode,
//               recReference: r.reference_number,
//               recNarration: r.narration,
//               recAmount: r.amount,
//               action_items: r.action_items,
//               recAction: "action",
//             });
//           }
          
//         });
//         if(arr.length){
//         return onSuccess(arr);
//       } else {
//         return onFailure("receipt not found");
//       }
//       } else 
//       {
//         onFailure("Something Wrong! Please Try again later " + res.data);
//       }
//     } catch (error) {
//       onFailure("Something Wrong! Please Try again later " + error);
//     }
//   };



//   export const getSearchPayment = async (onSuccess, onFailure,addSearch) => {
//     try {
//       const res = await webApi.post("/master/receipt_payment/list", {
//         voucher_no:addSearch.txt_voucher_no,
//         ledger_id:addSearch.ddl_ledger?.value,
//         from_date: timestamp(addSearch.txt_from_date),
//         to_date: timestamp(addSearch.txt_to_date),
//       });
//       if (res.status === 200) {
//         const r = res.data;
//         let arr = [];
//         let inc = 0;
//         let sumPayAmounts = 0;
//         console.log("sen=>>",r)
//         r.map((r, i) => {
//           if (r.receipt_payment_type === "P" || r.receipt_payment_type === "BP") {
//             inc++;
//             arr.push({
//               payID: inc,
//               bank_id: r.bank_id,
//               receipt_payment_id: r.receipt_payment_id,
//               payDate: dateFormate(r.voucher_date),
//               payVoucherNo: r.voucher_no,
//               payLedger: r.ledger_account_id,
//               ledger_account_name: r.ledger_account_name,
//               payMode: r.mode,
//               payReference: r.reference_number,
//               payNarration: r.narration,
//               payAmount: r.amount,
//               action_items: r.action_items,
//               payAction: "action",
//             });
//             sumPayAmounts += r.amount;
//           }
//         });
//         return onSuccess(arr, sumPayAmounts);
//       } else {
//         onFailure("Something Wrong! Please Try again later " + res.data);
//       }
//     } catch (error) {
//       onFailure("Something Wrong! Please Try again later " + error);
//     }
//   };