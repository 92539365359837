import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";

import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  updatePetrolPump,
  postPetrolPump,
  getAllPetrolPump,
  deleteVehicle,
  getAllEmployee,
  getAllRoute
} from "../../../services/petrolPumpService";

import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input,Box,Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/core";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import theme from "../../../theme/theme";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";

import React from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  appLabelFont,
  appFontWeight,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import  FileSaver from 'file-saver';
import XLSX from 'xlsx'
import IconButton from '@material-ui/core/Button';

const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
  
}));

const petrolPumpPage = () => {
  const classes1 = useStyles1()
  const history = useHistory();
  const [modules, setAllModules] = React.useState([]);
  const [classicModal, setClassicModal] = React.useState(false);
  const [PetrolPump, setAllPetrolPump] = React.useState([]);
  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [allEmployee,setAllEmployee] = React.useState([]);
  const [allRoute,setAllRoute] = React.useState([]);
  const userRole = globalState?.user?.user_role;
  

  const [addPetrolPump, setAddPetrolPump] = React.useState({
    edit: false,
    petrol_pump_id:"",
    txt_keyword_pharse:"",
    txt_petrol_pump: "",
    // ddl_in_routes_id:"",
    // ddl_in_routes_label:"",
    // ddl_in_charge:"",
    txt_in_charge:"",
    txt_address: "",
    txt_phone_no: "",
    switch_active_status: true,

  });
  const [error, setError] = React.useState({
    txt_petrol_pump: false,
    ddl_in_charge:false,
   
  });
  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddPetrolPump((prv) => ({ ...prv, [name]: value }));
  };
  const formData = {
    formName: "Add a Petrol-Pump",
    fields: [
   
     
      {
        name: "txt_petrol_pump",
        label: "Petrol-Pump name",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "TextField",
        error: false,
      },
      {
        name: "txt_phone_no",
        label: "Phone No",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        error: false,
      },
      {
        name: "rate",
        label: "Rate",
        hidden: false,
        required: false,
        data_type: "Number",
        html_element: "TextField",
        error: false,
      },
      // {
      //   name: "ddl_in_routes",
      //   label: "Routes",
      //   hidden: false,
      //   required: true,
      //   data_type: "string",
      //   html_element: "select",
      //   error: false,
      //   options:allRoute,
      // },
      {
        name: "txt_in_charge",
        label: "In charge name",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextField",
        error: false,
        // options: allEmployee.filter(o=> o.post === "4"),
      },
      
     
      {
        name: "txt_address",
        label: "Address",
        hidden: false,
        required: false,
        data_type: "string",
        html_element: "TextArea",
        error: false,
      },
      {
        name: "switch_active_status",
        label: "Active Status",
        defaultValue: false,
        required: false,
        data_type: "string",
        html_element: "switch",
        error: false,
      },
    ],
  };


  const toTitleCase = (str) => {
    if(str) {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    }
  
    else {
      return "";
    }
    
  }

  React.useEffect(() => {
    setLoading(true);
    // getAllRoute(
    //   (route) => {
          
    //       setAllRoute(route);
     
    //     },
    //     (err) => {
    //   setLoading(false);
    //       dispatch({
    //         type: actionTypes.SET_OPEN_MSG,
    //         payload: { msg: err, msgType: "error" },
    //       });
    //     }

    // )
    
    getAllEmployee(
      (employee) => {
          
          setAllEmployee(employee);
     
        },
        (err) => {
      setLoading(false);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
        }

    )

    // get All terms
    getAllPetrolPump(
      (PetrolPump) => {
        setAllPetrolPump(PetrolPump);
    setLoading(false);
      },
      (err) => {
    setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
    getAllPetrolPump(
      (PetrolPump) => {
        if (PetrolPump.length) {     
          setAllPetrolPump(PetrolPump);
          setLoading(false);
        } else {
          setLoading(false);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: "Petrol Pump not found", msgType:"error" },
          });
        }
      },
      (err) => {
        setAllPetrolPump([])
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
        setLoading(false);
      },
      addPetrolPump
    );
  }, [refresh]);

  const onSetActive = (e) => {
    setAddPetrolPump((prv) => ({ ...prv, [e.target.name]: e.target.checked }));
  };

  const headerData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "petrol_pump",
      label: "Petrol-Pump",

    },
    // {
    //   id: "routes",
    //   label: "Routes",

    // },
    {
      id: "incharge",
      label: "In-Charge",

    },
    {
      id: "phone_no",
      label: "Phone No",
    },
     
    {
      id: "address",
      label: "Address",
    },
    {
      id: "status",
      label: "Status",
      statusBtn: (v) => (v === "Y" ? true : false),
    },
    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];
  // onSubmit called
  const onSubmitModel = (e) => {
    console.log("yess")
    e.preventDefault();
    if (!addPetrolPump.txt_petrol_pump && !addPetrolPump.ddl_in_charge) {
      setError({
        txt_petrol_pump: !addPetrolPump.txt_petrol_pump,
        ddl_in_charge: !addPetrolPump.ddl_in_charge,

      });
    } else {
      if (addPetrolPump.edit) {
        updatePetrolPump(
          addPetrolPump,
          (r) => {
            onCloseModel();
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Petrol Pump Updated Successfully",
                msgType: "success",
              },
            });
            setRefresh(!refresh);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
      } else {
        postPetrolPump(
          addPetrolPump,
          globalState?.user?.serial_id,
          (r) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Petrol Pump added Successfully",
                msgType: "success",
              },
            });
            onCloseModel();
            setRefresh(!refresh);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setButtonDisabled(true)
          }
        );
      }
    }
  };
  // on Delete called
  const onDeleteVehicle = (row, next) => {
    deleteVehicle(
      row.petrol_pump_id,
      (r) => {
        next();
        setRefresh(!refresh);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  // on Edit called
  const onupdatePetrolPump = (row) => {
    setClassicModal(true);
    setAddPetrolPump({
      ...addPetrolPump,
      edit: true,
      switch_active_status: row.status === "Y" ? true : false,
      petrol_pump_id: row.petrol_pump_id,
      rate:row.rate,
      txt_petrol_pump: row.petrol_pump,
      ddl_in_charge: row.incharge,
      txt_address: row.address,
      txt_phone_no:row.phone_no,
      
    });
  };

  // const onSelectModule = (v) => {
  //   if (v !== null) {
  //     setAddPetrolPump({
  //       ...addPetrolPump,
  //       ddl_in_charge: v.value,
  //       ddl_in_charge_label: v.label,
  //     });
  //   }
  // };

  const onSelectModule = (event, value) => {
    console.log(event,value,"sen13122022")

    switch (value.name) {
      case "ddl_in_routes":
        setAddPetrolPump({
          ...addPetrolPump,
          ddl_in_routes_id: event.value,
          ddl_in_routes_label: event.label,       
        });
        break;
        
          case "ddl_in_charge":
            setAddPetrolPump({
              ...addPetrolPump,
              ddl_in_charge: event.value,
              ddl_in_charge_label: event.label,
           
            });
            break;
    }
  };


  const onChange = (e) => {
    const { value, name } = e.target;


    if (name === "txt_petrol_pump"  ) {
      setAddPetrolPump((prv) => ({
        ...prv,
        ["txt_petrol_pump"]: toTitleCase(value),
       
      }));
    }
    else if (name === "txt_address") {
      setAddPetrolPump((prv) => ({
        ...prv,
        ["txt_address"]: toTitleCase(value),
       
      }));
        
   
    }
    
    
    else{
    setAddPetrolPump({ ...addPetrolPump, [name]: value });
    }
  };
  const classes = useStyles();
  // on Close Model
  const onCloseModel = () => {
    setClassicModal(false);
    setError({ txt_vehicle: false });
    setAddPetrolPump({
      ...addPetrolPump,
      edit: false,
      petrol_pump_id: "",
      switch_active_status: false,
      txt_petrol_pump: "",
      ddl_in_charge:"",
      txt_phone_no: "",
      txt_address: "",
      // ddl_modules_id: 0,
      // ddl_modules_label: "Select Module",
    });
  };
  // const onSearchPetrolPump = (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   if(addPetrolPump.txt_keyword_pharse)
  //     {
  //   getAllPetrolPump(
  //     (PetrolPump) => {
  //       if (PetrolPump.length) {     
  //         setAllPetrolPump(PetrolPump);
  //         setLoading(false);
  //       } else {
  //         setLoading(false);
  //         dispatch({
  //           type: actionTypes.SET_OPEN_MSG,
  //           payload: { msg: err, "Petrol Pump not found": "info" },
  //         });
  //       }
  //     },
  //     (err) => {
  //       setAllPetrolPump([])
  //       dispatch({
  //         type: actionTypes.SET_OPEN_MSG,
  //         payload: { msg: err, msgType: "error" },
  //       });
  //       setLoading(false);
  //     },
  //     addPetrolPump
  //   );
  //     }
  // };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddPetrolPump({
      txt_keyword_pharse: "",
     
    });
  };


 // export to excel

const onhandleExportToExcel = () => {
  const VehicleTable = vehicle.map(acc => {
    return {
      ID: acc.id,
      Vehicle_type: acc.vehicle_type,
      Vehicle_no: acc.vehicle_no,
      Contact_person:acc.contact_person,
      Contact_no:acc.contact_no,
      Details:acc.details,
      
      Status: acc.status,
      Action:"",
      
    }
  })
  
 
  
  const fileName= 'Vehicle '
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(VehicleTable);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
}
  

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Master > Petrol-Pump"
        btnToolTip="Add New Petrol-Pump"
        onClickAddBtn={() => setClassicModal(true)}
      />
      <>
        <GridContainer className={classes.root}>
        {/* <GridItem xs="12">
          <CustomCard cdTitle="Search Petrol-Pump">
            <Paper elevation="0" className={classes.searchBar}>
              <GridContainer justifyContent="flex-start" alignItems="flex-end">
             
                <GridItem xs="6">
                  <InputLabel id="label">Keyword / Phrase</InputLabel>
                  <TextField
                    size="small"
                    placeholder="Keyword / Phrase"
                    style={{ marginBottom: -15 }}
                    type="search"
                    name="txt_keyword_pharse"
                    onChange={onAddSearch}
                    id="outlined-basic"
                    fullWidth={true}
                    value={addPetrolPump.txt_keyword_pharse}
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs="6">
                  <div
                    style={{
                      float: "right",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomButton
                      name="btn_refres"
                      style={{ marginRight: "10px" }}
             
                      onClick={onSearchPetrolPump}  

                    >
                      <SearchIcon />
                    </CustomButton>
                    <CustomButton name="btn_refres"
                    onClick={onClickRefresh} >
                      <RotateLeftIcon />
                    </CustomButton>
                  </div>
                </GridItem>
              </GridContainer>
            </Paper>
          </CustomCard>
        </GridItem> */}
        
          <MasterModel
            classicModal={classicModal}
            onCloseModel={onCloseModel}
            width={450}
            height="auto"
            okBtnText="Submit"
            modelName={addPetrolPump.edit ? "Edit Petrol Pump" : "Add Petrol-Pump"}
            onClickOk={onSubmitModel}
            disabled={buttonDisabled}
          >
            <GridContainer>
              <div style={{ padding: "20px 10px", width: "100%" }}>
                {formData.fields.map((item, key) => {
                  return (
                    <>
                      <GridItem xs={12} key={key}>
                        {item.html_element === "select" && (
                          <>
                            <InputLabel id="label">{item.label}</InputLabel>
                            <Select
                              options={item.options}
                              placeholder="Select Module"
                              name={item.name}
                              formatGroupLabel={(d) => d.label}
                              // menuPortalTarget={document.body}
                              className={classes.customSelect}
                              onChange={onSelectModule}
                              // value={
                              //   item.name === "ddl_routes"? {
                              //   value: addPetrolPump.ddl_in_routes_id,
                              //   label: addPetrolPump.ddl_in_routes_label,
                              // }: addPetrolPump}
                            />
                             {error.ddl_role_id && (
                              <div className={classes.ddlError}>
                                {item.label} is Required
                              </div>
                            )}
                          </>
                        )}
                        {item.html_element === "TextField" && (
                          <>
                            <InputLabel required={item.required} id="label">
                              {item.label}
                            </InputLabel>
                            <TextField
                              size="small"
                              required={item.required}
                              placeholder={item.label}
                              name={item.name}
                              onChange={onChange}
                              id="outlined-basic"
                              error={error[item.name]}
                              FormHelperTextProps={{
                                style: { textAlign: "right" },
                              }}
                              helperText={
                                error[item.name]
                                  ? item.label + "  is required"
                                  : ""
                              }
                              fullWidth={true}
                              value={addPetrolPump[item.name]}
                              variant="outlined"
                            />
                          </>
                        )}
                        {item.html_element === "TextArea" && (
                          <>
                            <InputLabel id="label">{item.label}</InputLabel>
                            <TextField
                              placeholder={item.label}
                              name={item.name}
                              onChange={onChange}
                              multiline
                              style={{ marginBottom: -10 }}
                              rows={5}
                              id="outlined-basic"
                              fullWidth={true}
                              value={addPetrolPump[item.name]}
                              variant="outlined"
                            />
                          </>
                        )}
                        {item.html_element === "switch" && (
                          <>
                            <span className={classes.activeText}>
                              {item.label}
                            </span>
                            <Switch
                              onClick={onSetActive}
                              checked={addPetrolPump[item.name]}
                              name={item.name}
                              fullWidth={true}
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              color="primary"
                            />
                          </>
                        )}
                      </GridItem>
                    </>
                  );
                })}
              </div>
            </GridContainer>
          </MasterModel>

          <GridItem xs="12">
          {loading ? (
            <Box mt={10} width="100%" textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Card className={classes1.headerCard}>
            <CardHeader className={classes1.TbheaderCdhd} style={{ height: 60 }}>
              <GridContainer justifyContent="space-between" alignItems="center">
                <GridItem>
                  <h4 className={classes1.headerCdTitle}>Petrol-Pump</h4>
                </GridItem>
                <GridItem style={{ cursor: "pointer" }}>
                  <IconButton variant="text" onClick={() => onhandleExportToExcel(vehicle)} >
                    <Tooltip title="Export to Excel">
                      <img src={require("../../../assets/img/excel.png").default} />
                    </Tooltip>
                  </IconButton>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody
              style={{ height: "auto", maxHeight: 480, padding: 10 }}
              className={clxs(classes.customScroolBar)}
            >
              <MuiTable
                onClickEdit={onupdatePetrolPump}
                onClickDelete={onDeleteVehicle}
                columns={headerData}
                rows={PetrolPump}
                userRole={userRole}

              />
           </CardBody>
              </Card>
          )}
           
          </GridItem>
        </GridContainer>
      </>
    </ThemeProvider>
  );
};

export default petrolPumpPage;
