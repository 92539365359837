import React, { useEffect, useState } from "react";
import Select from "react-select";

//reducers
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { InputLabel, TextField, ThemeProvider } from "@material-ui/core";
import PageTitle from "../HelperComponent/PageTitle";
import {
  currentDate,
  currentDate1,
  currentTime,
} from "views/Pages/HelperComponent/utils";

import MuiTable from "../../Components/MuITable";
import { activeText } from "assets/jss/material-dashboard-pro-react";

//Model window
import MasterModel from "views/Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";
//Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CircleAddBtn } from "views/Components/CustomButton";
import Button from "components/CustomButtons/Button.js";
import { CustomCard } from "views/Components/CustomCard";
import { Paper } from "@mui/material";
import CustomButton from "views/Components/CustomButton";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

//service
// import { getDispatchedVehicle, getAllExpenses, getAllAdvance } from "services/destinationService";
// import { postDestination, getAllDestination, getSearchDestination } from "services/destinationService";
import { postTracking, getTrackingVehicle } from "services/trackingService";
import { ColoseButton } from "views/Components/CustomButton";
import { vehicleTracking } from "services/trackingService";
import { dateFormate, time, timeDiff } from "views/Pages/HelperComponent/utils";
import { timeToUnix } from "views/Pages/HelperComponent/utils";
import { IstTime } from "views/Pages/HelperComponent/utils";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import {
  excessTime,
  timeChevronCheck,
} from "views/Pages/HelperComponent/utils";
import { getAllVehicle } from "services/RejectedTyreService";
import {
  getAllVehicleBrand,
  postTyreFitting,
} from "services/RejectedTyreService";
import { getAllBarcode } from "services/RejectedTyreService";
import { postRejectedTyre } from "services/RejectedTyreService";
import { useLocation } from "react-router-dom";
import { getRejectedTyre } from "services/RejectedTyreService";
import { updateRejectedTyre } from "services/RejectedTyreService";
import { styled } from '@mui/material/styles';
import {  Badge } from "@material-ui/core";
import { getAllTyreModelId } from "services/tyreBrandService";
import { getAllTyreBrandId } from "services/tyreBrandService";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

import { boolean } from "fx/config";
import IconButton from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import {
    appFontWeightThin,
    tblBodyHoverColor,
    appLabelFont,
    appFontWeight,
    appDefaultColor,
    appSecondColor,
    appDefaultFamily,
  } from "assets/jss/material-dashboard-pro-react";
import { postpurchaseReturnTyre } from "services/purchaseReturnTyreService";
import { getRepairBarcodeById } from "services/purchaseReturnTyreService";
import { getAllVendorsById } from "services/RejectedTyreService";
import { IMG_API } from "../../../services/webApi/webApi";


const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  floatAddBtn: {
    position: "fixed",
    top: 90,
    right: 40,
    zIndex: 1000,
  },
  pageTitleBox: {
    color: appDefaultColor,
    // color: appSecondColor,
    fontSize: "14px",
    fontWeight: appFontWeight,
    paddingLeft: "20px",
  },
  topHeaderTitle: {
    // backgroundColor: whiteColor,
    height: "auto",
    padding: "5px 20px",
    margin: "-20px -30px 10px -30px",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },
  ddlError1: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -12,
    fontWeight: 300,
  },
}));
const useStyles1 = makeStyles((styles) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#fff",
      color: appSecondColor,
      padding: "5px 5px",
      fontWeight: appFontWeight,
      fontFamily: appDefaultFamily,
      fontSize: "14px",
    },
    body: {
      color: appSecondColor,
      padding: "10px 5px",
      fontWeight: appFontWeightThin,
      fontFamily: appDefaultFamily,
      fontSize: "12.6px",
      border: "1px soild",
      // borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  }))(TableCell);
  
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {},
      "&:hover": {
        backgroundColor: tblBodyHoverColor,
      },
    },
  }))(TableRow);

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

const options = [
  {
    label: "New",
    value: 1,
  },
  {
    label: "Repair",
    value: 2,
  },
 
];

const options1 = [
    {
      label: "FORNT-LEFT",
      value: 1,
    },
    {
      label: "FORNT-RIGHT",
      value: 2,
    },
    {
      label: "BACK-LEFT",
      value: 3,
    },
    {
      label: "BACK-RIGHT",
      value: 4,
    },
    {
      label: "Extra",
      value: 5,
    },
  ];

const condition = [
  {
    label: "10%",
    value: 1,
  },
  {
    label: "20%",
    value: 2,
  },
  {
    label: "30%",
    value: 3,
  },
  {
    label: "40%",
    value: 4,
  },
  {
    label: "50%",
    value: 5,
  },
  {
    label: "60%",
    value: 6,
  },
  {
    label: "70%",
    value: 7,
  },
  {
    label: "80%",
    value: 8,
  },
  {
    label: "90%",
    value: 9,
  },
  {
    label: "100%",
    value: 10,
  },
];

const PurchaseReturnTyreForm = () => {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();
  const classs = useStyles1();
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [globalState, dispatch] = useStateValue();
  const [successModal, setSuccessModal] = useState(false);
  const [classicModel, setClassicModel] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [brand, setAllTyreBrand] = React.useState([]);
  const [tyreModel, setAllTyreModel] = React.useState([]);
  const [tyreDetails, setTyreDetails] = React.useState([]);
  const [newTyreDetails, setNewTyreDetails] = React.useState([]);

  const [repairBarcode,setRepairBarcode] = React.useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fileLoading, setFileLoading] = React.useState(false);

  const [addTyreDetails, setAddTyreDetails] = React.useState({
    edit: false, 
    tyre : false,  
    ddl_tyrecondition_no_id: "",
    ddl_tyrecondition_no_label: "",
    txt_barcode: "",
    // txt_fitting_km: 0,

    txt_amount:"",
    tyre_date: currentDate(),
    ddl_brand_id: "",
    ddl_brand_label: "",
    ddl_model_id: "",
    ddl_model_label: "",
    ddl_Tyre_Type_id:"",
    ddl_Tyre_Type_label:"",  
    ddl_position_id:"", 
    ddl_position_label:"",
    ddl_repair_barcode_id:"",
    ddl_repair_barcode_label:"",
    txt_Brand:"",
    txt_Model:"",
    txt_position:"",
    bill_image_path:"",
    bill_image_name:"",
    ddl_vendor_id:"",
    ddl_vendor_label:"",
    txt_bill_no:"",
    bill_date: currentDate(),
    txt_bill_amount:"",
    txt_remarks:"",
    switch_active_status: true,
  });

  const [newAddTyreDetails, setNewAddTyreDetails] = React.useState({
    edit: false, 
    tyre : false,  
    ddl_tyrecondition_no_id: "",
    ddl_tyrecondition_no_label: "",
    ddl_Tyre_Type_id:"",
    ddl_Tyre_Type_label:"",
    // txt_fitting_km: 0,
    txt_amount:"",
    tyre_date: currentDate(),
    ddl_brand_id: "",
    ddl_brand_label: "",
    ddl_model_id: "",
    ddl_model_label: "",
    txt_barcode: "",

  
    switch_active_status: true,
  });

  const [loading, setLoading] = React.useState(false);
  const [addSearch, setAddSearch] = useState({
    txt_vehicle_no: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });

//new State
const [showPurchaseTyre, setShowPurchaseTyre] = useState(false);
  const [showReturnTyre, setShowReturnTyre] = useState(false);
  const [vendor, setVendor] = useState([]);

  const [error, setError] = React.useState({
    vehicle_id: false,
    ddl_barcode_id: false,
    txt_amount: false,
    txt_model_name: false,
  });
  const [collapsible, setCollapsible] = useState(true);
  
  
  const onClickCollaps = () => {
    console.log("Reached23");
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };
 
  const [state, setState] = React.useState({
    edit: false,
  });


  const headerData = [
    {
      id: "_id",
      label: "# ",
    },
    {
      id: "txt_barcode",
      label: "Barcode ",
    },
   
    {
      id: "ddl_Tyre_Type_label",
      label: "Tyre Type",
    },
    
    {
      id: "ddl_brand_label",
      label: "Brand",

    },
    {
      id: "ddl_model_label",
      label: "Model",

    },
    // {
    //   id: "ddl_position_label",
    //   label: "Position",

    // },
    {
      id: "ddl_tyrecondition_no_label",
      label: "Condition",

    },
   
    {
      id: "action",
      label: "Action",
      align: "right",
      viewMenu: (v) => v,
    },
  ]; 


  const headerDataRepair = [
    {
      id: "_id",
      label: "# ",
    },
    {
      id: "txt_barcode",
      label: "Barcode ",
    },
   
    {
      id: "ddl_Tyre_Type_label",
      label: "Tyre Type",
    },
    // {
    //   id: "txt_fitting_km",
    //   label: "Fit KM",

    // },
    {
      id: "ddl_brand_label",
      label: "Brand",

    },
    {
      id: "ddl_model_label",
      label: "Model",

    },
    // {
    //   id: "ddl_position_label",
    //   label: "Position",

    // },
    {
      id: "ddl_tyrecondition_no_label",
      label: "Condition",

    },
   
    {
      id: "action",
      label: "Action",
      align: "right",
      viewMenu: (v) => v,
    },
  ]; 

 

  //onChange
  const onChange = (event) => {
    const { name, value } = event.target;
    console.log(name, "onselect52", value);
    setAddTyreDetails({ ...addTyreDetails, [name]: value })
    setNewAddTyreDetails({ ...newAddTyreDetails, [name]: value })


    if (value === "" || !value) {
      setError((prevError) => ({ ...prevError, [name]: true }));
    } else {
      setError((prevError) => ({ ...prevError, [name]: false }));
    }

  };

  //onchange for barcode

  const onChange1 = (e) => {
    setAddTyreDetails({
      ...addTyreDetails,
      [e.target.name]: e.target.value,
    });
    setNewAddTyreDetails({
      ...newAddTyreDetails,
      [e.target.name]: e.target.value,
    });
  };
 
  const onSelectModule = (event, value) => {
    
    console.log(event.value, "onselect", value);
    

    switch (value.name) {
      case "ddl_Tyre_Type":
        if (event.label === "New") {
          setShowPurchaseTyre(true);
          setShowReturnTyre(false);
          
        } else { 
          setShowPurchaseTyre(false);
          setShowReturnTyre(true);

         
        }
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_Tyre_Type_id: event.value,
          ddl_Tyre_Type_label: event.label,
          
        });
        setNewAddTyreDetails({
          ...newAddTyreDetails,
          ddl_Tyre_Type_id: event.value,
          ddl_Tyre_Type_label: event.label,
          
        });
        break;
      case "ddl_brand":
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_brand_id: event.value,
          ddl_brand_label: event.label,
        });
        setNewAddTyreDetails({
          ...newAddTyreDetails,
          ddl_brand_id: event.value,
          ddl_brand_label: event.label,
        });
        if (value === 0 || !value) {
          setError((prevError) => ({ ...prevError, ddl_brand_id: true }));
        } else {
          setError((prevError) => ({ ...prevError, ddl_brand_id: false }));
        }
        break;
      case "ddl_model":
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_model_id: event.value,
          ddl_model_label: event.label,
        });
        setNewAddTyreDetails({
          ...newAddTyreDetails,
          ddl_model_id: event.value,
          ddl_model_label: event.label,
          
        });
        if (value === 0 || !value) {
          setError((prevError) => ({ ...prevError, ddl_model_id: true }));
        } else {
          setError((prevError) => ({ ...prevError, ddl_model_id: false }));
        }
        break;
      case "ddl_tyrecondition_no":
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_tyrecondition_no_id: event.value,
          ddl_tyrecondition_no_label: event.label,
        });
        setNewAddTyreDetails({
          ...newAddTyreDetails,
          ddl_tyrecondition_no_id: event.value,
          ddl_tyrecondition_no_label: event.label,
          
        });
        break;
      case "ddl_position_no":
        setAddTyreDetails({
          ...addTyreDetails,
          ddl_position_id: event.value,
          ddl_position_label: event.label,
        });
        break;
        case "ddl_Repair_barcode":
          setAddTyreDetails({
            ...addTyreDetails,
            ddl_repair_barcode_id: event.value,
            ddl_repair_barcode_label: event.label,
            txt_Brand:event.company_name,
            txt_Model:event.model_name,
            txt_position:event.position,
            txt_amount:event.vendoramount
          });
          break;
          case "ddl_vendor":
            setAddTyreDetails({
              ...addTyreDetails,
              ddl_vendor_id: event.value,
              ddl_vendor_label: event.label,
             
            });
            break;
     
    }
  };


  const onAddNew = (e) => {
    // console.log("sen16122022", tyreDetails);
      // if (!addTyreDetails.txt_fitting_km){
      //   setError({
       
      //   });
      //   return false;
      // }

      

      // if(!addTyreDetails.txt_amount ){
      //   setError({
      //     txt_amount:!addTyreDetails.txt_amount
      //   });
      //   return false;
      // }
          

    
      newTyreDetails.filter(t => t.txt_barcode.includes(newAddTyreDetails.txt_barcode)).length ? 
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "Barcode Exists !!",
            msgType: "error",
          },
        })
        :
        setNewTyreDetails([...newTyreDetails,
          {
            _id: newTyreDetails.length + 1,
            ddl_Tyre_Type_id: newAddTyreDetails.ddl_Tyre_Type_id,
            ddl_Tyre_Type_label: newAddTyreDetails.ddl_Tyre_Type_label,
            ddl_tyrecondition_no_id: newAddTyreDetails.ddl_tyrecondition_no_id,
            ddl_tyrecondition_no_label: newAddTyreDetails.ddl_tyrecondition_no_label,
            txt_barcode: newAddTyreDetails.txt_barcode,
            tyre_date: newAddTyreDetails.tyre_date,
            ddl_brand_id: newAddTyreDetails.ddl_brand_id ,
            ddl_brand_label: newAddTyreDetails.ddl_brand_label ? newAddTyreDetails.ddl_brand_label : addTyreDetails.txt_Brand,
            ddl_model_id: newAddTyreDetails.ddl_model_id,
            ddl_model_label: newAddTyreDetails.ddl_model_label ? newAddTyreDetails.ddl_model_label :addTyreDetails.txt_Model,
            // ddl_position_id: addTyreDetails.ddl_position_id,
            // ddl_position_label: addTyreDetails.ddl_position_label ? addTyreDetails.ddl_position_label :addTyreDetails.txt_position,
            // txt_amount: addTyreDetails.txt_amount,
            // txt_fitting_km: addTyreDetails.txt_fitting_km,
            // ddl_repair_barcode_id: addTyreDetails.ddl_repair_barcode_id,
            // ddl_repair_barcode_label: addTyreDetails.ddl_repair_barcode_label,
            // rejected:false,
            action: "action",
            action_items: { can_delete: true },
          }

        ])
      
  };

  const onAdd = (e) => {
    // console.log("sen16122022", tyreDetails);
      // if (!addTyreDetails.txt_fitting_km){
      //   setError({
          
      //   });
      //   return false;
      // }

      

      // if(!addTyreDetails.txt_amount ){
      //   setError({
      //     txt_amount:!addTyreDetails.txt_amount
      //   });
      //   return false;
      // }
          

    
      tyreDetails.filter(t => t.txt_barcode.includes(addTyreDetails.txt_barcode)).length ? 
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "Barcode Exists !!",
            msgType: "error",
          },
        })
        :
        setTyreDetails([...tyreDetails,
          {
            _id: tyreDetails.length + 1,
            ddl_Tyre_Type_id: addTyreDetails.ddl_Tyre_Type_id,
            ddl_Tyre_Type_label: addTyreDetails.ddl_Tyre_Type_label,
            ddl_tyrecondition_no_id: addTyreDetails.ddl_tyrecondition_no_id,
            ddl_tyrecondition_no_label: addTyreDetails.ddl_tyrecondition_no_label,
            txt_barcode: addTyreDetails.ddl_repair_barcode_label,
            tyre_date: addTyreDetails.tyre_date,
            ddl_brand_id: addTyreDetails.ddl_brand_id ,
            ddl_brand_label: addTyreDetails.ddl_brand_label ? addTyreDetails.ddl_brand_label : addTyreDetails.txt_Brand,
            ddl_model_id: addTyreDetails.ddl_model_id,
            ddl_model_label: addTyreDetails.ddl_model_label ? addTyreDetails.ddl_model_label :addTyreDetails.txt_Model,
            // ddl_position_id: addTyreDetails.ddl_position_id,
            // ddl_position_label: addTyreDetails.ddl_position_label ? addTyreDetails.ddl_position_label :addTyreDetails.txt_position,
            txt_amount: addTyreDetails.txt_amount,
            // txt_fitting_km: addTyreDetails.txt_fitting_km,
            // ddl_repair_barcode_id: addTyreDetails.ddl_repair_barcode_id,
            // ddl_repair_barcode_label: addTyreDetails.ddl_repair_barcode_label,
            // rejected:false,
            action: "action",
            action_items: { can_delete: true },
          }

        ])
      
  };


  
  const onDeleteServiceNew = (row, id) => {
 
    let deleteref = newTyreDetails;
    deleteref.splice(id, 1);

    dispatch({
      type: actionTypes.SET_OPEN_MSG,
      payload: { msg: "Deleted !!", msgType: "error" },
    });
  };


  const onDeleteService = (row, id) => {
 
    let deleteref = tyreDetails;
    deleteref.splice(id, 1);

    dispatch({
      type: actionTypes.SET_OPEN_MSG,
      payload: { msg: "Deleted !!", msgType: "error" },
    });
  };

 

  React.useEffect(() => {
    console.log(location, "sanklocation");

    getAllVendorsById(
      (r) => {
        setVendor(r);
      },
      (error) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: error, msgType: "error" },
        });
      }
    );

    getRepairBarcodeById(
      (brand) => {
        setRepairBarcode(brand);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );

   
    getAllTyreBrandId(
        (brand) => {
          setAllTyreBrand(brand);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
        }
      );

    getAllTyreModelId(
        (tyre) => {
          setAllTyreModel(tyre);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
        }
      );

    if (location?.state?.edit) {
      setState({ ...state, edit: location.state?.edit });
      getRejectedTyre(
        location.state.rejected_tyre_id,
        (res) => {
          console.log("sankhalocation", res);
          setAddTyreDetails({
            edit: true,
            
          });
        },
        (err) => { }
      );

    }

    // if (location?.state?.edit) {
    //   console.log(location, "27012023");

    //   setRejectedDetailes({ ...RejectedDetailes, edit: location.state?.edit });
    // } else {
    //   setRejectedDetailes({
    //     switch_active_status: true,
    //   });
    // }
  }, [showPurchaseTyre, showReturnTyre]);

  // React.useEffect(() => {
  //   if (location?.state?.edit) {
  //   console.log(location,"27012023")

  //   setRejectedDetailes({ ...RejectedDetailes, edit: location.state?.edit });

  //   }else{
  //     setRejectedDetailes({

  //       switch_active_status:true,
  //     });
  //   }
  // }, []);

  //onSubmit
  const onSubmitModel = (event) => {
    event.preventDefault();
    setButtonDisabled(true);

    // if (
    //   !RejectedDetailes.vehicle_id ||

    //   !RejectedDetailes.txt_amount
    // ) {
    //   setError({
    //     vehicle_id: !RejectedDetailes.vehicle_id,

    //     txt_amount: !RejectedDetailes.txt_amount,
    //   });
    // } else {
      if (addTyreDetails.edit) {
        console.log(addTyreDetails, "sen01122022")
        updateRejectedTyre(
            addTyreDetails,
          globalState?.user?.serial_id,
          (r) => {
            // setAddTyreDetails({ rejected_tyre_no: r.rejected_tyre_no })
           
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "RejectedTyre updated Successfully",
                msgType: "success",
              },
            });
            onCloseModel();
          },
          (err) => {
            setSubmitButton(false)
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
      }
      else {
        postpurchaseReturnTyre(
            addTyreDetails,
            newAddTyreDetails,
            tyreDetails,
          globalState?.user?.serial_id,
          (res) => {
            
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Tyre  Successfully!!",
                msgType: "success",
              },
            });
            setAddTyreDetails({
              edit: false, 
              tyre : false,  
              ddl_tyrecondition_no_id: "",
              ddl_tyrecondition_no_label: "",
              txt_barcode: "",
              // txt_fitting_km: 0,          
              txt_amount:"",
              tyre_date: currentDate(),
              ddl_brand_id: "",
              ddl_brand_label: "",
              ddl_model_id: "",
              ddl_model_label: "",
              ddl_Tyre_Type_id:"",
              ddl_Tyre_Type_label:"",  
              ddl_position_id:"", 
              ddl_position_label:"",
              ddl_repair_barcode_id:"",
              ddl_repair_barcode_label:"",
              txt_Brand:"",
              txt_Model:"",
              txt_position:"",
              bill_image_path:"",
              bill_image_name:"",
              ddl_vendor_id:"",
              ddl_vendor_label:"",
              txt_bill_no:"",
              bill_date: currentDate(),
              txt_bill_amount:"",
              txt_remarks:"",
            });
            onCloseModel();
          },
        
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: err,
                msgType: "error",
              },
            });
            setButtonDisabled(false);
          }
        );
      }
    // }
  };


  const onChangeImage = (e) => {
    const { value, name } = e.target;

    console.log(e.target.checked, "sankhaImage", name, value);

   
  
     if (name === "bill_image_name") {
      setFileLoading(true);
      const file = e.target.files[0];
      
      if (file.size < 1000000) {
        // const reader = new FileReader();
        // var url = reader.readAsDataURL(file);
        postFileUpload(
          file,
          (r) => {
            setAddTyreDetails({
              ...addTyreDetails,
              bill_image_path: r.fileName,
              bill_image_name: r.originalFileName,


            });
            setFileLoading(false);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setFileLoading(false);

          }
        );
      } else {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "File should be less then 1MB",
            msgType: "warning",
          },
        });
      }
    }
   

    setAddTyreDetails({ ...addTyreDetails, [name]: value });
  };

  const onCloseModel = () => {
    setClassicModel(false);
    setAddTyreDetails({
      edit: false, 
      tyre : false,  
      ddl_tyrecondition_no_id: "",
      ddl_tyrecondition_no_label: "",
      txt_barcode: "",
      // txt_fitting_km: 0,  
      txt_amount:"",
      tyre_date: currentDate(),
      ddl_brand_id: "",
      ddl_brand_label: "",
      ddl_model_id: "",
      ddl_model_label: "",
      ddl_Tyre_Type_id:"",
      ddl_Tyre_Type_label:"",  
      ddl_position_id:"", 
      ddl_position_label:"",
      ddl_repair_barcode_id:"",
      ddl_repair_barcode_label:"",
      txt_Brand:"",
      txt_Model:"",
      txt_position:"",
      bill_image_path:"",
      bill_image_name:"",
      ddl_vendor_id:"",
      ddl_vendor_label:"",
      txt_bill_no:"",
      bill_date: currentDate(),
      txt_bill_amount:"",
      txt_remarks:"",
    });
    history.push({
      pathname: "/admin/maintence/purchaseReturnTyre",
    });
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddTyreDetails({
      edit: false, 
      tyre : false,  
      ddl_tyrecondition_no_id: "",
      ddl_tyrecondition_no_label: "",
      txt_barcode: "",
      // txt_fitting_km: 0,  
      txt_amount:"",
      tyre_date: currentDate(),
      ddl_brand_id: "",
      ddl_brand_label: "",
      ddl_model_id: "",
      ddl_model_label: "",
      ddl_Tyre_Type_id:"",
      ddl_Tyre_Type_label:"",  
      ddl_position_id:"", 
      ddl_position_label:"",
      ddl_repair_barcode_id:"",
      ddl_repair_barcode_label:"",
      txt_Brand:"",
      txt_Model:"",
      txt_position:"",
      bill_image_path:"",
      bill_image_name:"",
      ddl_vendor_id:"",
      ddl_vendor_label:"",
      txt_bill_no:"",
      bill_date: currentDate(),
      txt_bill_amount:"",
      txt_remarks:"",
    });
  };

  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddSearch((prv) => ({ ...prv, [name]: value }));
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title={
          state.edit ? "Transportation >  Edit" : "Add Purchase & Return Tyre"
        }

        btnToolTip="Add Purchase & Return Tyre"
      // onClickAddBtn={onClickAddDestination}
      />

      {/* On Click Model Window Open */}
      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <CustomCard cdTitle="Choose Type of Tyre" filterIcon onClickFilter={() => { }}>
          <GridContainer justify="space-between" alignItems="center">
          <GridItem xs={2}>
                    <InputLabel required={true} id="label">
                     Select Type Of Tyre
                    </InputLabel>
                    <Select
                      options={options}
                      placeholder="Type"
                      formatGroupLabel={(d) => d.label}
                      className={classes.customSelect}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      name="ddl_Tyre_Type"
                      onChange={onSelectModule}
                    //   value={{
                    //     value: RejectedDetailes.vehicle_id,
                    //     label: RejectedDetailes.vehicle_no,
                    //   }}
                    />
                    
                  </GridItem>

                  <GridItem xs={2}>
                   <InputLabel required={false} id="label">
                     Date
                   </InputLabel>
                   <TextField
                     size="small"
                     name="tyre_date"
                     id="date"
                     variant="outlined"
                     type="date"
                     fullWidth={true}
                     inputProps={{ style: { textAlign: "left" } }}
                     FormHelperTextProps={{ style: { textAlign: "left" } }}
                     value={addTyreDetails.tyre_date}
                     onChange={onChange}
                     InputLabelProps={{
                       shrink: true,
                     }}
                   // onClick={(e) => {
                   //   e.target.select();
                   // }}
                   />
                 </GridItem>
                 </GridContainer>
          </CustomCard>
          <GridContainer
            style={{ padding: "10px" }}
            justify="space-between"
            alignItems="center"
          >
            {showPurchaseTyre && (
            <CustomCard
              cdTitle="Purchase Tyre"
              // btnToolTip={collapsible ? "Collaps" : "Expand"}
              // onClickCollapsible={onClickCollaps}
              // buttonAction={collapsible}
              filterIcon
            >
              {/* {collapsible ? ( */}
                 <GridContainer justify="space-between" alignItems="center">
                   
               
              
                 {/* <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Fitting KM
                   </InputLabel>
                   <TextField
                     size="small"
                     type="Number"
                     name="txt_fitting_km"
                     onChange={onChange}
                     placeholder="km"
                     id="outlined-basic"
                     fullWidth={true}
                     value={addTyreDetails.txt_fitting_km}
                     variant="outlined"
                    
                     inputProps={{ style: { textAlign: "right" } }}
                 
                   />

                 </GridItem> */}
     
                 <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Brand
                   </InputLabel>
                   <Select
                     options={brand}
                     placeholder="company name"
                     formatGroupLabel={(d) => d.label}
                     className={classes.customSelect}
                     menuPortalTarget={document.body}
                     styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                     name="ddl_brand"
                     onChange={onSelectModule}
                   />
                   {/* {error.ddl_brand_id && (
                       <div className={classes.ErrorClass}>
                           Brand is required
                       </div>
                     )} */}
                 </GridItem>
     
                 <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Model Name
                   </InputLabel>
                   <Select
                     options={tyreModel}
                     placeholder="Model"
                     formatGroupLabel={(d) => d.label}
                     className={classes.customSelect}
                     menuPortalTarget={document.body}
                     styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                     name="ddl_model"
                     onChange={onSelectModule}
                   />
                   {error.ddl_model_id && (
                       <div className={classes.ErrorClass}>
                           Model is required
                       </div>
                     )}
                 </GridItem>
     
                 <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Barcode No
                   </InputLabel>
                   <TextField
                     size="small"
                     type="String"
                     name="txt_barcode"
                     onChange={onChange1}
                     id="outlined-basic"
                     fullWidth={true}
                     value={addTyreDetails.txt_barcode}
                     variant="outlined"
                     // helperText={error.txt_barcode?"Barcode is required":""}
                     disabled={addTyreDetails.ddl_Tyre_Type_label === "Repair"}
                   />
                   {/* {error.txt_barcode && (
                       <div className={classes.ErrorClass}>
                           Barcode is required
                       </div>
                     )} */}
                 </GridItem>
     
                 {/* <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Position
                   </InputLabel>
                   <Select
                     options={options1}
                     placeholder="position"
                     formatGroupLabel={(d) => d.label}
                     className={classes.customSelect}
                     menuPortalTarget={document.body}
                     styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                     name="ddl_position_no"
                     onChange={onSelectModule}
                   />
                   {error.ddl_position_id && (
                       <div className={classes.ErrorClass}>
                           Position is required
                       </div>
                     )}
                 </GridItem> */}
     
                 <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Tyre Condition
                   </InputLabel>
                   <Select
                     options={condition}
                     placeholder="%"
                     formatGroupLabel={(d) => d.label}
                     className={classes.customSelect}
                     menuPortalTarget={document.body}
                     styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                     name="ddl_tyrecondition_no"
                     onChange={onSelectModule}
                   />
                   {error.ddl_tyrecondition_no_id && (
                       <div className={classes.ErrorClass}>
                           Tyre Condition is required
                       </div>
                     )}
                 </GridItem>
     
                 {/* <GridItem xs={2}>
                   <InputLabel required={false} id="label">
                     Amount
                   </InputLabel>
                   <TextField
                     size="small"
                     type="Number"
                     name="txt_amount"
                     id="outlined-basic"
                     onChange={onChange}
     
                     fullWidth={true}
                     value={addTyreDetails.txt_amount}
                     inputProps={{ style: { textAlign: "right" } }}
                     variant="outlined"
                  
                   />
                   
                 </GridItem> */}
               
     
                 <GridItem xs={2}>
                   <StyledBadge
                    //  badgeContent=
                    //  {tyreDetails.length > 0 && vehicleStatus===true && addTyreDetails.no_of_wheels ?
                    //    Number(addTyreDetails.no_of_wheels) - tyreDetails.length
                    //    :
                    //    addTyreDetails.no_of_wheels}
                     color="error">
     
                     <CustomButton
                       onClick={onAddNew}
                     >
                       Add
                     </CustomButton>
                   </StyledBadge>
     
                 </GridItem>
     
               </GridContainer>
              {/* ) : (
                ""
              )} */}
    {newTyreDetails && newTyreDetails.length>0 ?
          <CustomCard cdTitle="Added Tyres">
            {/* <MuiTable
              columns={headerData}
              rows={tyreDetails}
            /> */}

            <TableContainer>
              <Table className={classes.table} aria-label="customized table" >
                <TableHead>
                  <TableRow>
                    {headerData && headerData.map((tableHeadData, headindex)=>{
                        return(
                          <StyledTableCell align="center" key={tableHeadData.id}>
                            {tableHeadData.label}
                          </StyledTableCell>
                        )
                      })

                    }
                  </TableRow>
                </TableHead>
                <TableBody
                >
                  { newTyreDetails &&
                    newTyreDetails.map((row,i)=>
                
                  <StyledTableRow key={i}
                 
                  >
                {console.log(row,"sankharow")}

                    <StyledTableCell align="center" >
                      {row.length != 0 ? i + 1 : ""}
                    </StyledTableCell>

                    <StyledTableCell align="center" >
                      {row.txt_barcode  }
                    </StyledTableCell>

                    {/* <StyledTableCell align="center" >
                      {row.tyre_date.split('-').reverse().join('-')}
                    </StyledTableCell> */}

                    <StyledTableCell align="center" >
                      {row.ddl_Tyre_Type_label}
                    </StyledTableCell>

                    {/* <StyledTableCell align="right" >
                      {row.txt_fitting_km}
                    </StyledTableCell> */}

                    <StyledTableCell align="center" >
                      {row.ddl_brand_label ? row.ddl_brand_label: row.txt_Brand}
                    </StyledTableCell>

                    <StyledTableCell align="center" >
                      {row.ddl_model_label ? row.ddl_model_label : row.txt_Model}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left" >
                      {row.ddl_position_label ? row.ddl_position_label:row.txt_position}
                    </StyledTableCell> */}

                    <StyledTableCell align="center" >
                      {row.ddl_tyrecondition_no_label}
                    </StyledTableCell>
                   

                    {/* <StyledTableCell align="right" >
                      {row.txt_amount}
                    </StyledTableCell> */}

                    <StyledTableCell align="center" >
                      <IconButton
                       onClick={(e) => onDeleteServiceNew(row, i)}
                        aria-label="delete" >
                        <DeleteIcon fontSize="small" style={{ color: "#f44336" }}                   
                        />
                      </IconButton>
                    </StyledTableCell>

                  </StyledTableRow>
                
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>

          </CustomCard>
          :""
        }

            </CustomCard>
            )
            }
            {showReturnTyre && (
            <CustomCard
              cdTitle="Return Tyre"
              // btnToolTip={collapsible ? "Collaps" : "Expand"}
              // onClickCollapsible={onClickCollaps}
              // buttonAction={collapsible}
              filterIcon
            >
              {/* {collapsible ? ( */}
                 <GridContainer justify="space-between" alignItems="center">
                   
                
                
              {/* {    console.log(selectedOption.name,"sankhselectedOption")} */}
                  <GridItem xs={2}>
                    <InputLabel required={true} id="label">
                      Repair Barcode
                    </InputLabel>
                    <Select
                      options={repairBarcode}
                      placeholder="Repair"
                      formatGroupLabel={(d) => d.label}
                      className={classes.customSelect}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      name="ddl_Repair_barcode"
                      onChange={onSelectModule}
                      // isDisabled={addTyreDetails.ddl_Tyre_Type_label === "New"}
                    //   value={{
                    //     value: RejectedDetailes.vehicle_id,
                    //     label: RejectedDetailes.vehicle_no,
                    //   }}
                    />
                    
                  </GridItem>
                 
                 {/* <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Fitting KM
                   </InputLabel>
                   <TextField
                     size="small"
                     type="Number"
                     name="txt_fitting_km"
                     onChange={onChange}
                     placeholder="km"
                     id="outlined-basic"
                     fullWidth={true}
                     inputProps={{ style: {  border: "none", textAlign: "right" } }}
                     value={addTyreDetails.txt_fitting_km}
                     variant="outlined"
                 
                   />

                 </GridItem> */}
     
                 <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Brand
                   </InputLabel>
                   <TextField
                      size="small"
                      type="string"
                      name="txt_Brand"
                      onChange={onChange1}
                      placeholder="Brand"
                      id="outlined-basic"
                      fullWidth={true}
                     value={addTyreDetails.txt_Brand}

                      inputProps={{ style: { color: "green", border: "none", textAlign: "Center" } }}
                     
                      disabled
                   />
                   
                 </GridItem>
     
                 <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Model Name
                   </InputLabel>
                   <TextField
                       size="small"
                       type="string"
                       name="txt_Model"
                       onChange={onChange1}
                       placeholder="Model"
                       id="outlined-basic"
                       fullWidth={true}
                     value={addTyreDetails.txt_Model}

                       inputProps={{ style: { color: "green", border: "none", textAlign: "Center" } }}
                       disabled
                   />
                   
                 </GridItem>
     
     
                 <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Position
                   </InputLabel>
                   <TextField
                      size="small"
                      type="string"
                      name="txt_position"
                      onChange={onChange1}
                      placeholder="Position"
                      id="outlined-basic"
                      fullWidth={true}
                     value={addTyreDetails.txt_position}

                      inputProps={{ style: { color: "green", border: "none", textAlign: "Center" } }}
                      disabled
                   />
                  
                 </GridItem>

                 {/* <GridItem xs={2}>
                   <InputLabel required={false} id="label">
                     Amount
                   </InputLabel>
                   <TextField
                     size="small"
                     type="Number"
                     name="txt_amount"
                     id="outlined-basic"
                     onChange={onChange1}
                     placeholder="Amount"
     
                     fullWidth={true}
                     value={addTyreDetails.txt_amount}
                   
                     variant="outlined"
                   inputProps={{ style: { color: "green", border: "none", textAlign: "Right" } }}
                   disabled
                   />
                  
                 </GridItem> */}
     
                 <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Tyre Condition
                   </InputLabel>
                   <Select
                     options={condition}
                     placeholder="%"
                     formatGroupLabel={(d) => d.label}
                     className={classes.customSelect}
                     menuPortalTarget={document.body}
                     styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                     name="ddl_tyrecondition_no"
                     onChange={onSelectModule}
                   />
                   {error.ddl_tyrecondition_no_id && (
                       <div className={classes.ErrorClass}>
                           Tyre Condition is required
                       </div>
                     )}
                 </GridItem>
     
                 <GridItem xs={5}></GridItem>
     
                 <GridItem xs={2}>
                   <StyledBadge
                    //  badgeContent=
                    //  {tyreDetails.length > 0 && vehicleStatus===true && addTyreDetails.no_of_wheels ?
                    //    Number(addTyreDetails.no_of_wheels) - tyreDetails.length
                    //    :
                    //    addTyreDetails.no_of_wheels}
                     color="error">
     
                     <CustomButton
                       onClick={onAdd}
                     >
                       Add
                     </CustomButton>
                   </StyledBadge>
     
                 </GridItem>
     
               </GridContainer>
              {/* ) : (
                ""
              )} */}


            </CustomCard>
            )}
            {tyreDetails && tyreDetails.length>0 ?
          <CustomCard cdTitle="Added Repair Tyres">
            {/* <MuiTable
              columns={headerData}
              rows={tyreDetails}
            /> */}

            <TableContainer>
              <Table className={classes.table} aria-label="customized table" >
                <TableHead>
                  <TableRow>
                    {headerDataRepair && headerDataRepair.map((tableHeadData, headindex)=>{
                        return(
                          <StyledTableCell align="center" key={tableHeadData.id}>
                            {tableHeadData.label}
                          </StyledTableCell>
                        )
                      })

                    }
                  </TableRow>
                </TableHead>
                <TableBody
                >
                  { tyreDetails &&
                    tyreDetails.map((row,i)=>
                
                  <StyledTableRow key={i}
                 
                  >
                {console.log(row,"sankharowtyreDetails")}

                    <StyledTableCell align="center" >
                      {row.length != 0 ? i + 1 : ""}
                    </StyledTableCell>

                    <StyledTableCell align="center" >
                      { row.txt_barcode }
                    </StyledTableCell>

                    {/* <StyledTableCell align="center" >
                      {row.tyre_date.split('-').reverse().join('-')}
                    </StyledTableCell> */}

                    <StyledTableCell align="center" >
                      {row.ddl_Tyre_Type_label}
                    </StyledTableCell>

                    {/* <StyledTableCell align="right" >
                      {row.txt_fitting_km}
                    </StyledTableCell> */}

                    <StyledTableCell align="center" >
                      {row.ddl_brand_label ? row.ddl_brand_label: row.txt_Brand}
                    </StyledTableCell>

                    <StyledTableCell align="center" >
                      {row.ddl_model_label ? row.ddl_model_label : row.txt_Model}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left" >
                      {row.ddl_position_label ? row.ddl_position_label:row.txt_position}
                    </StyledTableCell> */}

                    <StyledTableCell align="center" >
                      {row.ddl_tyrecondition_no_label}
                    </StyledTableCell>
                   

                    {/* <StyledTableCell align="right" >
                      {row.txt_amount}
                    </StyledTableCell> */}

                    <StyledTableCell align="center" >
                      <IconButton
                       onClick={(e) => onDeleteService(row, i)}
                        aria-label="delete" >
                        <DeleteIcon fontSize="small" style={{ color: "#f44336" }}                   
                        />
                      </IconButton>
                    </StyledTableCell>

                  </StyledTableRow>
                
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>

          </CustomCard>
          :""
        }

<CustomCard
              cdTitle="Vendor Details"             
              filterIcon
            >
              {/* {collapsible ? ( */}
                 <GridContainer justify="space-between" alignItems="center">
                   
                
                
              {/* {    console.log(selectedOption.name,"sankhselectedOption")} */}
                  <GridItem xs={3}>
                    <InputLabel required={true} id="label">
                     Vendor
                    </InputLabel>
                    <Select
                      options={vendor}
                      placeholder="select"
                      formatGroupLabel={(d) => d.label}
                      className={classes.customSelect}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      name="ddl_vendor"
                      onChange={onSelectModule}
                      // isDisabled={addTyreDetails.ddl_Tyre_Type_label === "New"}
                    //   value={{
                    //     value: RejectedDetailes.vehicle_id,
                    //     label: RejectedDetailes.vehicle_no,
                    //   }}
                    />
                    
                  </GridItem>
                 
                 <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                    Bill No
                   </InputLabel>
                   <TextField
                     size="small"
                     type="Number"
                     name="txt_bill_no"
                     onChange={onChange}
                    
                     id="outlined-basic"
                     fullWidth={true}
                     inputProps={{ style: {  border: "none", textAlign: "right" } }}
                     value={addTyreDetails.txt_bill_no}
                     variant="outlined"
                     // helperText={error.txt_fitting_km?"Fitting Km is required":""}
                    
                  //  disabled
                   />
{/*                  
                      {error.txt_fitting_km && (
                         <div className={classes.ErrorClass}>
                            KM is required
                         </div>
                       )} */}
                 </GridItem>
     
                 <GridItem xs={2}>
                   <InputLabel required={false} id="label">
                     Bill Date
                   </InputLabel>
                   <TextField
                     size="small"
                     name="bill_date"
                     id="date"
                     variant="outlined"
                     type="date"
                     fullWidth={true}
                     inputProps={{ style: { textAlign: "left" } }}
                     FormHelperTextProps={{ style: { textAlign: "left" } }}
                     value={addTyreDetails.bill_date}
                     onChange={onChange}
                     InputLabelProps={{
                       shrink: true,
                     }}
                   // onClick={(e) => {
                   //   e.target.select();
                   // }}
                   />
                 </GridItem>
     
                 <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                     Bill Amount
                   </InputLabel>
                   <TextField
                       size="small"
                       type="string"
                       name="txt_bill_amount"
                       variant="outlined"
                       onChange={onChange1}
                     
                       id="outlined-basic"
                       fullWidth={true}
                       value={addTyreDetails.txt_bill_amount}
                       inputProps={{ style: { color: "green", border: "none", textAlign: "Center" } }}
                       
                   />
                   
                 </GridItem>  

                 <GridItem xs={2}>
                   <InputLabel required={true} id="label">
                    Remarks
                   </InputLabel>
                   <TextField
                       size="small"
                       type="string"
                       name="txt_bill_amount"
                       variant="outlined"
                       onChange={onChange1}
                     
                       id="outlined-basic"
                       fullWidth={true}
                       value={addTyreDetails.txt_remarks}
                      //  inputProps={{ style: { color: "green", border: "none", textAlign: "Center" } }}
                       
                   />
                   
                 </GridItem> 

                 <div style={{ width: "100%", display: "flex", borderBottom: "1px solid", borderColor: "#3699ff", alignItems: "center" }}>
                  
                      <GridItem xs={8} style={{ marginTop: 25 }}>
                        <GridContainer>
                          <GridItem xs="6">
                            <Paper elevation={0}>
                               {fileLoading ? (
                                <CircularProgress />
                              ) : (
                                <GridItem style={{ margin: "4px 50px" }}>
                                  <IconButton variant="text">
                                    <Tooltip title="Download">
                                      {addTyreDetails.bill_image_path ?
                                        (<a href={IMG_API + addTyreDetails.bill_image_path}>
                                          <CloudDownloadOutlinedIcon
                                            style={addTyreDetails.bill_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                        </a>)
                                        :
                                        <CloudDownloadOutlinedIcon
                                          style={addTyreDetails.bill_image_path ? { color: "#3699ff" } : { color: "black" }} />
                                      }
                                    </Tooltip>
                                  </IconButton>
                                </GridItem>
                                
                              )} 
                            </Paper>
                          </GridItem>
                          <GridItem xs="3" style={{ marginTop: 25 }}>
                            <input
                              accept="application/pdf,image/*"
                              className={classes.input}
                              style={{ display: "none" }}
                              name="bill_image_path"
                              onChange={onChangeImage}
                              id="bill_image"
                              type="file"
                              // disabled={checkedRented ? true : false}

                            />
                            <label htmlFor="bill_image">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth={true}
                                className={classes.browseBtn}
                                // disabled={checkedRented ? true : false}

                              >
                                Upload Img
                              </Button>
                            </label>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      
                    </div>    
               </GridContainer>
              {/* ) : (
                ""
              )} */}


            </CustomCard>

              <div style={{ width: "100%", paddingTop: "10px" }} className={classes.actionbtns}>
                <CustomButton
                  style={{
                    float: "right",
                    width: "10%",
                    marginRight: "10px",
                    border: "none",
                  }}
                  onClick={onSubmitModel}
                  disabled={buttonDisabled}
                >
                  Submit
                </CustomButton>
                <CustomButton
                  style={{
                    float: "right",
                    width: "10%",
                    marginRight: "10px",
                    background: "red",
                    border: "none",
                  }}
                  onClick={onClickRefresh}
                >
                  <RotateLeftIcon />
                </CustomButton>
                <ColoseButton
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    float: "right",
                    width: "10%",
                    marginRight: "10px",
                    border: "none",
                  }}
                  onClick={onCloseModel}
                >
                  close
                </ColoseButton>
              </div>
           
          </GridContainer>
        </GridItem>
      </GridContainer>
      {/* after submit model window */}
     
    </ThemeProvider>
  );
};

export default PurchaseReturnTyreForm;
