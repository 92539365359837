import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Select from "@material-ui/core/Select";
import MuiTable from "../../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../../Components/MasterModel";
import { CustomCard } from "../../Components/CustomCard";
import CustomButton, { CircleAddBtn } from "../../Components/CustomButton";

import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Paper } from "@material-ui/core";

import { ThemeProvider, Box } from "@material-ui/core";

import ReactSelect from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Autocomplete } from "@material-ui/lab";
import { IconButton, OutlinedInput } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { currencyFormate } from "views/Pages/HelperComponent/utils";

//Tables
import {
  appFontWeightThin,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "6px 5px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "0px 5px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  customSelect: {
    marginBottom: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
    "&:hover": {
      backgroundColor: "rgba(43,43, 43, 0.03)",
    },
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);
//Theme
import theme from "../../../theme/theme";
//Services

// import React from "react";
import {
  appDefaultColor,
  appSecondColor,
  reactSelectStyles,
} from "assets/jss/material-dashboard-pro-react";
import { appDefaultFamily } from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import React, { useState } from "react";
import PageTitle from "../HelperComponent/PageTitle";
import {
  currentDate,
  currentDate1,
  currentDate1Pdf,
  currentDatePdf,
} from "../HelperComponent/utils";

import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import {
  getAllVehicles,
  getSearchVehicle,
} from "services/totalDistanceCoveredService";
import { getDailyTruckReport } from "services/dailyTruckReportService";

const useStyles1 = makeStyles(styles);

//PDF
// import pdfIcon from "../../../assets/img/pdf-icon.png"
// import { jsPDF } from "jspdf";
const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  narraction: {
    width: 155,
  },
  date: {
    width: 88,
  },
  voucher: {
    width: 190,
  },
  particular: {
    width: 110,
  },

  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  customSelect: {
    marginBottom: 15,
  },
}));

const onChange = (e) => {
  const { value, name } = e.target;
  setAddBrand({ ...allUnits, [name]: value });
};

const DailyTruckReport = () => {
  const classes1 = useStyles1();

  const history = useHistory();
  const [globalState, dispatch] = useStateValue();
  const [vehicleNumber, setvehicleNumber] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);

  const [addSearch, setAddSearch] = React.useState({
    txt_from_date: currentDate(),
  });

  const [distanceList, setDistanceList] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const [collapsible, setCollapsible] = React.useState(true);

  //   const fetchData = () => {
  //     getAllVehicles(
  //       (r) => {
  //         console.log("R:",r);
  //         setvehicleNumber(r);
  //       },
  //       (err) => {
  //         dispatch({
  //           type: actionTypes.SET_OPEN_MSG,
  //           payload: { msg: err, msgType: "error" },
  //         });
  //       }
  //     );
  //   };

  //   const month = [
  //     {},
  //     { label:'January', value:1 },
  //     { label:'February', value:2 },
  //     { label:'March', value:3 },
  //     { label:'April', value:4 },
  //     { label:'May', value:5 },
  //     { label:'June', value:6 },
  //     { label:'July', value:7 },
  //     { label:'August', value:8 },
  //     { label:'September', value:9 },
  //     { label:'October', value:10 },
  //     { label:'November', value:11 },
  //     { label:'December', value:12 }
  //   ];

  React.useEffect(() => {
    setLoading(true);
    // fetchData();
  }, []);

  const onAddSearch = (e) => {
    const { name, value } = e.target;
    setAddSearch((prv) => ({ ...prv, [name]: value }));
  };

  const onSelect = (info, v) => {
    setAddSearch({ ...addSearch, [info.name]: v });
    console.log(addSearch, "check1");
  };

  const onClickRefresh = () => {
    setRefresh(!refresh);
    setAddSearch({
      ddl_vehicle_id: "",
      ddl_vehicle: "",
      ddl_month_id: "",
      ddl_month: "",
    });
  };

  const onSearchDistance = (e) => {
    e.preventDefault();
    setLoading(true);
    getDailyTruckReport(
      addSearch,
      (list) => {
        setDistanceList(list);

        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };

  const classes = useStyles();

  const onClickCollaps = () => {
    collapsible ? setCollapsible(false) : setCollapsible(true);
  };

  //    // export to excel
  const onhandleExportToExcel = () => {
    const dailyTruckTable = [];
  
    distanceList.forEach((row, i) => {
      if (row.today.length === 0 && row.yesterday.length === 0) {
        dailyTruckTable.push({
          "Sl No": i + 1,
          "Vehicle No": "No data found",
          "Status": "",
        });
      } else {
        row.today.forEach((todayItem, todayIndex) => {
          dailyTruckTable.push({
            "Sl No": todayIndex + 1,
            "Vehicle No": todayItem.vehicle_no || "-",
            "Status": todayItem.status || "Sales Complete",
          });
        });
        row.yesterday.forEach((yesterdayItem, yesterdayIndex) => {
          dailyTruckTable.push({
            "Sl No": yesterdayIndex + 1,
            "Vehicle No": yesterdayItem.vehicle_no || "-",
            "Status": yesterdayItem.status || "Sales Incomplete",
          });
        });
      }
    });
  
    const fileName = "Daily Truck Report";
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(dailyTruckTable);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  

  return (
    <ThemeProvider theme={theme}>
      <PageTitle title="MIS Report > Daily Truck Report" />

      <GridContainer>
        <GridItem xs="12">
          <CustomCard
            cdTitle="Search Daily Truck Report"
            btnToolTip={collapsible ? "Collaps" : "Expand"}
            onClickCollapsible={onClickCollaps}
            buttonAction={collapsible}
            filterIcon
          >
            {collapsible ? (
              <Paper elevation="0" className={classes.searchBar}>
                <GridContainer justifyContent="flex-start" alignItems="center">
                  {/* <GridItem xs="4">
                      <InputLabel id="label">Vehicle No.</InputLabel>
                      <ReactSelect
                        options={vehicleNumber}
                        getOptionLabel={(option) => option.label}
                        placeholder="Select"
                        formatGroupLabel={(d) => d.label}
                        menuPortalTarget={document.body}
                        className={classes.customSelect}
                        styles={reactSelectStyles}
                        name="ddl_vehicle_id"
                        onChange={(v, info) => onSelect(info, v)}
                      />
                    </GridItem> */}

                  <GridItem xs="2">
                    <InputLabel id="label">Date</InputLabel>
                    <TextField
                      name="txt_from_date"
                      size="small"
                      id="date"
                      variant="outlined"
                      // style={{ marginBottom: -10 }}
                      type="date"
                      fullWidth={true}
                      defaultValue={currentDate()}
                      value={addSearch.txt_from_date}
                      onChange={onAddSearch}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs="12">
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <CustomButton
                        style={{ marginRight: "10px" }}
                        onClick={onSearchDistance}
                      >
                        <SearchIcon />
                      </CustomButton>
                      <CustomButton onClick={onClickRefresh}>
                        <RotateLeftIcon />
                      </CustomButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </Paper>
            ) : (
              ""
            )}
          </CustomCard>
        </GridItem>
      </GridContainer>

      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <Card className={classes1.headerCard}>
            <CardHeader
              className={classes1.TbheaderCdhd}
              style={{ height: 60 }}
            >
              <GridContainer justifyContent="space-between" alignItems="center">
                <GridItem>
                  <h4 className={classes1.headerCdTitle}>
                    Daily Truck Report List
                  </h4>
                </GridItem>
                <GridItem style={{ cursor: "pointer" }}>
                   

                   <IconButton
                     variant="text"
                     onClick={() => onhandleExportToExcel(distanceList)}
                   >
                     <Tooltip title="Export to Excel">
                       <img
                         src={require("../../../assets/img/excel.png").default}
                       />
                     </Tooltip>
                   </IconButton>
                 </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody
              style={{ height: "auto", maxHeight: 480, padding: 10 }}
              className={clxs(classes.customScroolBar)}
            >
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-label="customized table"
                  id="table_total_distance_covered"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">#</StyledTableCell>
                      {/* <StyledTableCell align="left">Date</StyledTableCell> */}
                      {/* <StyledTableCell align="center">Vehicle No</StyledTableCell>                       */}
                      <StyledTableCell align="center">
                        Today Lorry Arrived{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Previous Lorry{" "}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">Today Lorry No </StyledTableCell> */}
                      <StyledTableCell align="center">
                        Today Lorry Status{" "}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {/* table Body start */}
                  <TableBody>
                    {/* {distanceList.map((row, i) => (
                      console.log(row,"sankharow"),
                      <React.Fragment key={i}>
                        {row.today.map((todayItem, todayIndex) => (
                          <StyledTableRow key={`today-${i}-${todayIndex}`}>
                            <StyledTableCell
                              align="left"
                              className={classes.id}
                            >
                              {todayIndex + 1}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                            {todayItem.vehicle_no
                                ? todayItem.vehicle_no
                                : "-"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {todayItem.vehicle_no
                                ? "-"
                                :"" }
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {todayItem.vehicle ? "Sales Complete" : ""}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                        {row.yesterday.map((yesterdayItem, yesterdayIndex) => (
                          <StyledTableRow
                            key={`yesterday-${i}-${yesterdayIndex}`}
                          >
                            <StyledTableCell
                              align="left"
                              className={classes.id}
                            >
                              {yesterdayIndex + 1}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {yesterdayItem.vehicle}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {yesterdayItem.vehicle_no
                                ? yesterdayItem.vehicle_no
                                : "-"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {yesterdayItem.vehicle_no ? "Sales InComplete" : ""}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </React.Fragment>
                    ))} */}
                    {distanceList.map((row, i) => (
  <React.Fragment key={i}>
    {row.today.length === 0 && row.yesterday.length === 0 ? (
      <StyledTableRow>
        <StyledTableCell align="center" colSpan={4}>
          No data found
        </StyledTableCell>
      </StyledTableRow>
    ) : (
      <>
        {row.today.map((todayItem, todayIndex) => (
          console.log(todayItem,"santoday"),
          <StyledTableRow key={`today-${i}-${todayIndex}`}>
            <StyledTableCell align="left" className={classes.id}>
              {todayIndex + 1}
            </StyledTableCell>
            <StyledTableCell align="center">
              {todayItem.vehicle_no ? todayItem.vehicle_no : "-"}
            </StyledTableCell>
            <StyledTableCell align="center">
              {todayItem.vehicle_no ? "-" : ""}
            </StyledTableCell>
            <StyledTableCell align="center">
              {todayItem.status ? todayItem.status : ""}
            </StyledTableCell>
          </StyledTableRow>
        ))}
        {row.yesterday.map((yesterdayItem, yesterdayIndex) => (
          <StyledTableRow key={`yesterday-${i}-${yesterdayIndex}`}>
            <StyledTableCell align="left" className={classes.id}>
              {yesterdayIndex + 1}
            </StyledTableCell>
            <StyledTableCell align="center">
              {yesterdayItem.vehicle}
            </StyledTableCell>
            <StyledTableCell align="center">
              {yesterdayItem.vehicle_no ? yesterdayItem.vehicle_no : "-"}
            </StyledTableCell>
            <StyledTableCell align="center">
              {yesterdayItem.status ? yesterdayItem.status : ""}
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </>
    )}
  </React.Fragment>
))}

                  </TableBody>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
};

export default DailyTruckReport;
