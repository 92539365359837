import webApi from "./webApi/webApi";

 export const postExpenses= async (info,inserted_by_id, onSuccess, onFailure) => {

  console.log(info,"expenses_info");
    try {
        
      const res = await webApi.post("/master/expenses/insert", {
        // expenses_id: info.expenses_id,
        expenses: info.txt_expenses,
        details: info.txt_details,
        expenses_type_id: info.ddl_expenses_type,
        expenses_type:info.ddl_expenses_type_label,
        active_status: info.switch_active_status ? "Y" : "N",
        inserted_by_id:inserted_by_id,
      });
  
      if (res.status === 200) {
        const r = res.data;
      
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      // onFailure("Something Wrong! Please Try again later " + error);
    }
  };




export const getAllExpenses = async (onSuccess, onFailure,q) => {
    try {
  
      const res = await webApi.post("/master/expenses/list", {
        keyword_pharse: q?.txt_keyword_phrase,
  
      });
      if 
      (res.status === 200)
       {
        const r = res.data;
         
  
        let all_expenses = [];
        r.map((r, i) => {
            all_expenses.push({
            id: i + 1,
            expenses_id:r.expenses_id,
            expenses:r.expenses,
            expenses_type:r.expenses_type,
            details: r.details,
            status: r.active_status,
            action_items: r.action_items,
            action: "action",
          });
        });
        return onSuccess(all_expenses);
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later" + error);
    }
  };




  export const deleteVehicle = async (id, onSuccess, onFailure) => {
    try {
      const res = await webApi.post("/master/vehicle/delete", {
        vehicle_id: id,
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };

  

  export const updateLocation = async (info, onSuccess, onFailure) => {
    
    try {
      const res = await webApi.post("/master/expenses/update", {
        expenses_id: info.expenses_id,
        expenses: info.txt_expenses,
        expenses_type: info.ddl_expenses_type_label,
        details: info.txt_details,
        active_status: info.switch_active_status ? "Y" : "N",
      });
      if (res.status === 200) {
        const r = res.data;
        onSuccess(r);
      } else {
        onFailure("Something Wrong! Please Try again later " + res.data);
      }
    } catch (error) {
      onFailure("Something Wrong! Please Try again later " + error);
    }
  };