import webApi, { fileUploadApi } from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import {
  dateFormate,
  timeToUnix,
  IstTime,
} from "../views/Pages/HelperComponent/utils";

export const getServiceItemById = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/service/service_type", {});
    if (res.status === 200) {
      const r = res.data;
      let allUoms = [];
      r.map((r, i) => {
        allUoms.push({
          value: r.item_id,
          label: r.item,
          uom: r.uom,
        });
      });
      return onSuccess(allUoms);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const postServices = async (
  service,
  inserted_by_id,
  onSuccess,
  onFailure
) => {
  try {
    console.log(service, "sen19102022");
    const res = await webApi.post("master/service/insert", {
      vehicle_id: service.ddl_vehicle_id,
      vehicle_no: service.ddl_vehicle_no,
      qty: Number(service.txt_qty),
      uom: service.txt_uom,
      service_type_id: service.ddl_service_type_id,
      service_type_label: service.ddl_service_type_label,
      service_date: timestamp(service.service_date),
      next_service_date: timestamp(service.next_service_date),
      inserted_by_id: inserted_by_id,
    });

    if (res.status === 200) {
      const r = res.data;

      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};


export const getVehicleServiceList = async (addSearch, onSuccess, onFailure) => {
    
  try {

    const res = await webApi.post("/master/service/list", {
      vehicle_no: addSearch.vehicle_no,
      // fromDate: timestamp(addSearch.txt_from_date),
      // toDate: timestamp(addSearch.txt_to_date),

    });
    if
      (res.status === 200) {
      const r = res.data;
      const nextFiveDays = Math.floor(
        (Date.now() + 5 * 24 * 60 * 60 * 1000) / 1000
      );
      let vehicleService = [];
      r.map((r, i) => {

        vehicleService.push({
            id: i + 1,
            vehicleServices_id:r.vehicleServices_id,
            vehicle_id: r.vehicle_id,
            vehicle_no: r.vehicle_no,
            service_type_id:r.service_type_id,
            service_type:r.service_type_label,
            next_service_date: dateFormate(r.next_service_date),
            next_service: r.next_service_date <= nextFiveDays ,
            inserted_by_date:r.inserted_by_date,
            qty: r.qty,
            uom: r.uom ,
            service_date: dateFormate(r.service_date),           
            action_items:r.action_items,
            action: "action",
          })


      });

      return onSuccess(vehicleService);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};



export const getNotificationList = async ( onSuccess, onFailure) => {
    
  try {

    const res = await webApi.post("/master/service/notification", {
  

    });
    if
      (res.status === 200) {
      const r = res.data;

      let vehicleService = [];
      r.map((r, i) => {

        vehicleService.push({
            id: i + 1,
            vehicle_id: r._id,
            vehicle_no: r.vehicle_no,
            service_expirations:r.service_expirations
          })


      });

      return onSuccess(vehicleService);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};