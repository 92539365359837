import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";
import {
  dateFormate,
  dateFormateField,
} from "../views/Pages/HelperComponent/utils";

export const postRejectedTyreSales = async (
  info,
  inserted_by_id,
  onSuccess,
  onFailure
) => {
  try {
    const res = await webApi.post("/master/rejectedTyreSales/insert", {
      rejected_tyre_id:info.rejected_tyre_id,
      barcode_id: info.ddl_barcode_id,
      barcode: info.ddl_barcode_label,
      company_name: info.txt_company_name,
      model_name: info.txt_model_no,
      position: info.txt_position_no,
      amount: info.txt_amount_barcode,
      vendoramount: info.txt_amount,
      vendor_id: info.ddl_vendor_id,
      vendor_name: info.ddl_vendor_label,
      service_type_id: info.ddl_service_type_id,
      service_type_label: info.ddl_service_type_label,
      salesOffice_id: info.ddl_salesOffice_id,
      salesOffice_name: info.ddl_salesOffice_label,
      approve_id: info.ddl_employee_id,
      approve_name: info.ddl_employee_label,
      vehicle_id: info.vehicle_id,
      vehicle_no: info.vehicle_no,
      inserted_by_id: inserted_by_id,
      rejected_sales_date: timestamp(info.txt_tracking_date),
      rejected_tyre_return_date: timestamp(info.txt_return_date),
      remarks:info.txt_remarks,
    });
    // when rejected tyre sales insert then rejectedTyre_status true for dropdown
    const rejectTyre = await webApi.post(
      "/master/rejectedTyreSales/update/list",
      {
        vehicle_id: info.vehicle_id,
        rejected_tyre: true,
      }
    );

    if (res.status === 200) {
      console.log(res, "sank04021");

      const r = res.data;
      onSuccess(r);
      console.log(r, "sank0402");
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllBarcodeById = async (q, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/rejectedTyre/list", {
      vehicle_id: q,
    });
    if (res.status === 200) {
      const r = res.data;
      let all_barcode = [];
      r.map((r, i) => {
        console.log(r, "sank2012");

        all_barcode.push({
          value: i,
          label: r.barcode,
          company_name: r.company_name,
          model_name: r.model_name,
          position: r.position,
          amount: r.amount,
          vehicle_no: r.vehicle_no,
          rejected_date: dateFormate(r.rejected_date),
          rejected_count: r.rejected_count ? r.rejected_count : 0,
          fitting_km: r.fitting_km,
        });
      });
      return onSuccess(all_barcode);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllvehicleById = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/rejectedTyre/list", {});
    if (res.status === 200) {
      const r = res.data;
      let all_barcode = [];
      r.map((r, i) => {
        console.log(r, "sank2012");

        all_barcode.push({
          value: r.vehicle_id,
          label: r.vehicle_no,
        });
      });
      return onSuccess(all_barcode);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllRejectedVehicleById = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/rejectedTyre/list", {});
    if (res.status === 200) {
      const r = res.data;
      let all_barcode = [];
      r.map((r, i) => {
        console.log(r, "sank2012");

        all_barcode.push({
          value: r.rejected_tyre_id,
          label: r.vehicle_no,
          // company_name:r.company_name,
          // model_name:r.model_name,
          // position:r.position,
          // amount:r.amount,
        });
      });
      return onSuccess(all_barcode);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllEmployeeById = async (onSuccess, onFailure, q) => {
  try {
    const res = await webApi.post("/master/employee/list", {});

    if (res.status === 200) {
      const r = res.data;
      let allEmployee = [];
      r.map((v, i) => {
        console.log(v, "sank010967");

        allEmployee.push({
          id: i + 1,
          value: v.employee_id,
          label: v.first_name,
          post: v.post,
        });
      });
      console.log(allEmployee, "sank01096");
      if (allEmployee.length) {
        return onSuccess(allEmployee);
      } else {
        onFailure("Employee not found ");
      }
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getAllAdvanceLocation = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/location/list", {});
    if (res.status === 200) {
      const r = res.data;

      let all_location = [];
      r.map((r, i) => {
        all_location.push({
          value: r.location_id,
          label: r.location,
          location_type_id: r.location_type_id,
          location_type: r.location_type,
        });
      });
      return onSuccess(all_location);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllRejectedTyreSales = async (
  addSearch,
  onSuccess,
  onFailure
) => {
  try {
    const res = await webApi.post("/master/rejectedTyreSales/list", {
      vehicle_no: addSearch.txt_vehicle_no,
      from_date: timestamp(addSearch.txt_from_date),
      to_date: timestamp(addSearch.txt_to_date),
    });

    if (res.status === 200) {
      const r = res.data;
      let allData = [];
      r.map((r, i) => {
        allData.push({
          id: i + 1,
          rejected_tyre_Sales_id: r.rejected_tyre_Sales_id,
          txt_company_name: r.company_name,
          txt_fitting_km: r.fitting_km,
          txt_model_name: r.model_name,
          txt_position: r.position,
          txt_barcode: r.barcode,
          txt_remarks: r.remarks,
          txt_amount: r.amount,
          txt_vendor_amount: r.vendoramount,
          vendor: r.vendor_name,
          ddl_service_type_id: r.service_type_id,
          ddl_service_type_label: r.service_type_label,
          sales: r.salesOffice_name,
          approved_by: r.approve_name,
          date: dateFormate(r.rejected_sales_date),
          action_items: r.action_items,
          action: "action",
        });
      });

      onSuccess(allData);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

export const getRejectedTyreSales = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/rejectedTyreSales/details", {
      rejected_tyre_Sales_id: id,
    });
    console.log(res, "sankres");

    if (res.status === 200) {
      const r = res.data;

      return onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const onUpdateRejectedTyreSales = async (
  info,
  userId,
  onSuccess,
  onFailure
) => {
  try {
    const res = await webApi.post("/master/rejectedTyreSales/update", {
      rejected_tyre_Sales_id: info.rejected_tyre_Sales_id,

      barcode_id: info.ddl_barcode_id,
      barcode: info.ddl_barcode_label,
      company_name: info.txt_company_name,
      model_name: info.txt_model_no,
      position: info.txt_position_no,
      amount: Number(info.txt_amount_barcode),
      vendoramount: Number(info.txt_amount),
      vendor_id: info.ddl_vendor_id,
      vendor_name: info.ddl_vendor_label,
      service_type_id: info.ddl_service_type_id,
      service_type_label: info.ddl_service_type_label,
      salesOffice_id: info.ddl_salesOffice_id,
      salesOffice_name: info.ddl_salesOffice_label,
      approve_id: info.ddl_employee_id,
      approve_name: info.ddl_employee_label,

      edited_by_id: userId,
      // rejected_sales_date: timestamp(info.txt_tracking_date),
    });

    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};

// export const getAllTyreTypeById = async (onSuccess, onFailure) => {
//   try {
//     const res = await webApi.post("/master/tyreTypeById/list", {
//       tyreTypeGroupIdArray: [2,3],

//     });
//     if (res.status === 200) {
//       const r = res.data;
//       let all_barcode = [];
//       r.map((r, i) => {
//         console.log(r, "sank2012");

//         all_barcode.push({
//           value: r.tyre_Type_id,
//           label: r.tyre_Type,

//         });
//       });
//       return onSuccess(all_barcode);
//     } else {
//       onFailure("Something Wrong! Please Try again later" + res.data);
//     }
//   } catch (error) {
//     onFailure("Something Wrong! Please Try again later" + error);
//   }
// };



export const getRejectedTyreSalesForView = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/master/rejectedTyreSales/view", {
      rejected_tyre_Sales_id: id,
    });
    console.log(res, "sankres");

    if (res.status === 200) {
      const r = res.data;

      return onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};