import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";

//reducers
import { useStateValue } from "../../../../context/context";
import { actionTypes } from "../../../../context/reducer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { InputLabel, TextField, ThemeProvider } from "@material-ui/core";
import PageTitle from "../../HelperComponent/PageTitle";
import {
  currentDate,
  currentDate1,
  IstTime,
} from "views/Pages/HelperComponent/utils";
import {
  appDefaultColor,
  appSecondColor,
  whiteColor,
  reactSelectStyles,
  appFontWeight,
} from "assets/jss/material-dashboard-pro-react";
import MuiTable from "../../../Components/MuITable";

//Model window
import MasterModel from "views/Components/MasterModel";
import StepProceedModel from "views/Pages/HelperComponent/StepProceedModel";
//Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "react-calendar/dist/Calendar.css";
import theme from "theme/theme.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CircleAddBtn } from "views/Components/CustomButton";
import Button from "components/CustomButtons/Button.js";
import { CustomCard } from "views/Components/CustomCard";
import { Paper } from "@mui/material";
import CustomButton from "views/Components/CustomButton";
import { ColoseButton } from "views/Components/CustomButton";

import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Grid } from "@material-ui/core";
import MasterModelView from "../../../Components/MasterModelForView";

//Services
import {
  ViewOilDetails,
  postFileUpload,
  postOil,
  getAllOilView,
  getAllOil,
  getAllVehicleById,
  getAllPetrolPumpById,
} from "services/oilService";
import { getRoutes, totalOiled } from "services/oilService";
import { currentTime } from "views/Pages/HelperComponent/utils";
import { IMG_API } from "../../../../services/webApi/webApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import { dateFormate } from "views/Pages/HelperComponent/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },
  dateField: {
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },

  searchBar: {
    padding: "10px",
  },
  searchbtnRight: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "-54px",
  },
  activeText: {
    fontSize: "15px",
    color: appSecondColor,
    fontWeight: 400,
  },
  input: {
    height: 40,
    lineLight: 40,
    padding: "0 10px",
    marginBottom: "20px",
  },
  floatAddBtn: {
    position: "fixed",
    top: 90,
    right: 40,
    zIndex: 1000,
  },
  pageTitleBox: {
    color: appDefaultColor,
    // color: appSecondColor,
    fontSize: "14px",
    fontWeight: appFontWeight,
    paddingLeft: "20px",
  },
  topHeaderTitle: {
    backgroundColor: whiteColor,
    height: "auto",
    padding: "5px 20px",
    margin: "-20px -30px 10px -30px",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },
}));

const OilViewPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [globalState, dispatch] = useStateValue();

  const [collapsibleTruck, setCollapsibleTruck] = useState(true);
  const [collapsibleOil, setCollapsibleOil] = useState(true);
  const [collapsibleImage, setCollapsibleImage] = useState(true);
  const [fileLoading, setFileLoading] = React.useState(false);
  const [submitAction, setSubmitAction] = React.useState(false);

  const [addSearch, setAddSearch] = useState({
    txt_vehicle_no: "",
    txt_from_date: currentDate1(),
    txt_to_date: currentDate(),
  });
  const [vehicle, setAllVehicle] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [allpump, setallpump] = React.useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [oilDetails, setOilDetails] = useState([]);
  const [id, setId] = useState(location?.oil_id);
  const onClickCollapsTruck = () => {
    console.log("Reached23");
    collapsibleTruck ? setCollapsibleTruck(false) : setCollapsibleTruck(true);
  };

  const onClickCollapsOil = () => {
    console.log("Reached23");
    collapsibleOil ? setCollapsibleOil(false) : setCollapsibleOil(true);
  };

  const onClickCollapsImage = () => {
    console.log("Reached23");
    collapsibleImage ? setCollapsibleImage(false) : setCollapsibleImage(true);
  };

  const onClickClose = () => {
    setRefresh(!refresh);
    setOilDetails([]);
    history.push({
      pathname: "/admin/tranportation/oil",
      oil_id: "",
    });
  };

  const onClickImage = (event) => {
    console.log(event, "sen21102022");
  };

  React.useEffect(() => {
    console.log(location, "sanklocation");
    // const id = location?.oil_id

    ViewOilDetails(
      location?.oil_id,
      (r) => {
        console.log(r, "sanklocation1");

        setOilDetails(r);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <PageTitle title="Tranportation > Oil View" />
      <GridContainer className={classes.root}>
        <GridItem xs="12">
          <CustomCard
            cdTitle="Oil Form View"
            filterIcon
            onClickFilter={() => {}}
          >
            <GridContainer
              style={{ padding: "10px" }}
              justify="space-between"
              alignItems="center"
            >
              <CustomCard
                cdTitle="Truck Details View"
                btnToolTip={collapsibleTruck ? "Collaps" : "Expand"}
                onClickCollapsible={onClickCollapsTruck}
                buttonAction={collapsibleTruck}
                filterIcon
              >
                {collapsibleTruck ? (
                  <GridContainer
                    // style={{ padding: "10px" }}
                    justify="space-between"
                    alignItems="center"
                  >
                    <GridItem xs="2">
                      <InputLabel
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Truck No.
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.vehicle_no}
                        disabled
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                      />
                      {/* {oilDetails.vehicle_no} */}
                    </GridItem>
                    <GridItem xs="2">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Truck Trip No.
                      </InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        name="trip_no"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        // variant="outlined"
                        placeholder="Trip No."
                        value={oilDetails.trip_no}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="2">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Truck Total Trip No.
                      </InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        name="total_trip_no"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        // variant="outlined"
                        placeholder="Trip No."
                        value={oilDetails.total_trip_no}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="2">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        All Truck Trip No.
                      </InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        name="all_truck_trip_no"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        // variant="outlined"
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                            fontWeight: "bold",
                          },
                        }}
                        placeholder="Trip No."
                        value={oilDetails.all_truck_trip_no}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="1">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        KM Start
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                            fontWeight: "bold",
                          },
                        }}
                        value={oilDetails.km_start_reading}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="1">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        KM End
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.km_end_reading}
                        inputProps={{
                          style: {
                            color: "green",
                            textAlign: "right",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="2">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Distance Covered
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={
                          oilDetails.km_end_reading -
                          oilDetails.km_start_reading
                        }
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="2">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Max KM Covered Distance
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.km_end_reading}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
              </CustomCard>

              <CustomCard
                cdTitle="Oil Details"
                btnToolTip={collapsibleOil ? "Collaps" : "Expand"}
                onClickCollapsible={onClickCollapsOil}
                buttonAction={collapsibleOil}
                filterIcon
              >
                {collapsibleOil ? (
                  <GridContainer
                    // style={{ padding: "10px" }}
                    justify="space-between"
                    alignItems="center"
                  >
                    <GridItem xs="2">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Approved Oil (ltr)
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.approved_oil}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="1">
                      <InputLabel
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Routes
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.routes}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="4">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Routes Name
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.routes_name}
                        inputProps={{
                          style: {
                            textAlign: "center",
                            color: "green",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    {/* <GridItem xs="3">
                        <InputLabel required={false} id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}>
                          Purchase Oil(ltr)
                        </InputLabel>
                        <TextField
                          size="small"
                          fullWidth={true}
                          placeholder="Purchase Oil(ltr)"
                          value={oilDetails.purchase_oil}
                          inputProps={{ style: { textAlign: "right",color: "green",fontWeight: "bold" } }}
                          disabled

                        />
                      </GridItem> */}
                    <GridItem xs="1">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Rate
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.rate}
                        inputProps={{
                          style: {
                            textAlign: "right",
                            color: "green",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="1">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Total
                      </InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        name="total_amount"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        // variant="outlined"
                        placeholder="Total"
                        value={oilDetails.total_amount}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="1">
                      <InputLabel 
                      required={false} id="label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Actual Amount
                      </InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        name="actual_Amount"
                        // onChange={onChange}
                        id="outlined-basic"
                        fullWidth={true}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                            fontWeight: "bold",
                          },
                        }}
                        // variant="outlined"
                        placeholder="Actual Amount"
                        value={oilDetails.actual_Amount}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="1">
                      <InputLabel
                       required={false} id="label"
                       style={{ fontWeight: "bold", textAlign: "center" }}
                       >
                        Difference Amount
                      </InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        name="difference_Amount"
                        // onChange={onChange}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "right",
                            fontWeight: "bold",
                          },
                        }}
                        fullWidth={true}
                        // variant="outlined"
                        placeholder="Difference Amount"
                        value={Math.abs(oilDetails.difference_Amount)}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="1">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Bill No
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.fule_bill_no}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="2">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Bill Date
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={dateFormate(oilDetails.fule_bill_date)}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="1">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Bill Time
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={IstTime(oilDetails.fule_bill_time)}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="3">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Petrol Pump
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.petrol_pump}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>

                    <GridItem xs="2">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Payment Mode
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.payMode}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="3">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Cheque No.
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.cheque_no}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="3">
                      <InputLabel
                        required={false}
                        id="label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Account Name
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth={true}
                        value={oilDetails.account_name}
                        inputProps={{
                          style: {
                            color: "green",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs="9">
                                                <InputLabel required={false} id="label">
                                                   Remarks
                                                </InputLabel>
                                                <TextField
                                                    size="small"                                              
                                                    fullWidth={true}                                                 
                                                    value={oilDetails.txt_remarks}
                                                    inputProps={{
                                                      style: {
                                                        color: "green",
                                                        border: "none",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                      },
                                                    }}
                                                    disabled
                                                  
                                                />
                                            </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
              </CustomCard>
              <CustomCard
                cdTitle="Images"
                btnToolTip={collapsibleImage ? "Collaps" : "Expand"}
                onClickCollapsible={onClickCollapsImage}
                buttonAction={collapsibleImage}
                filterIcon
              >
                {collapsibleImage ? (
                  <GridContainer
                    style={{ padding: 25 }}
                    justify="space-between"
                    alignItems="center"
                  >
                    {/* Truck Meter image */}
                    <GridItem xs={4}>
                      <InputLabel id="label">
                        <h4>Truck Meter Image</h4>
                      </InputLabel>
                    </GridItem>
                    <GridItem xs={8}>
                      <GridContainer>
                        <GridItem xs="6">
                          <Paper elevation={0}>
                            {fileLoading ? (
                              <CircularProgress />
                            ) : (
                              <img
                                id="meter_image_path"
                                // className={classes.itemImg}
                                style={{ width: "50%" }}
                                src={
                                  oilDetails.meter_image_path
                                    ? IMG_API + oilDetails.meter_image_path
                                    : "https://via.placeholder.com/160"
                                }
                                height="100%"
                                alt="image"
                                // onClick={onClickImage}
                                onClickCapture={onClickImage}
                              />
                            )}
                          </Paper>
                        </GridItem>
                      </GridContainer>
                    </GridItem>

                    {/* petrol pump meter image */}
                    <GridItem xs={4} style={{ marginTop: 25 }}>
                      <InputLabel id="label">
                        <h4>Petrol Pump Meter Image</h4>
                      </InputLabel>
                    </GridItem>
                    <GridItem xs={8} style={{ marginTop: 25 }}>
                      <GridContainer>
                        <GridItem xs="6">
                          <Paper elevation={0}>
                            {fileLoading ? (
                              <CircularProgress />
                            ) : (
                              <img
                                id="pump_meter_image_path"
                                // className={classes.itemImg}
                                style={{ width: "50%" }}
                                src={
                                  oilDetails.pump_meter_image_path
                                    ? IMG_API + oilDetails.pump_meter_image_path
                                    : "https://via.placeholder.com/160"
                                }
                                height="100%"
                                alt="image"
                              />
                            )}
                          </Paper>
                        </GridItem>
                      </GridContainer>
                    </GridItem>

                    {/* Bill image */}
                    <GridItem xs={4} style={{ marginTop: 25 }}>
                      <InputLabel id="label">
                        <h4>Bill Image</h4>
                      </InputLabel>
                    </GridItem>
                    <GridItem xs={8} style={{ marginTop: 25 }}>
                      <GridContainer>
                        <GridItem xs="6">
                          <Paper elevation={0}>
                            {fileLoading ? (
                              <CircularProgress />
                            ) : (
                              <img
                                id="bill_attachment_path"
                                // className={classes.itemImg}
                                style={{ width: "50%" }}
                                src={
                                  oilDetails.bill_attachment_path
                                    ? IMG_API + oilDetails.bill_attachment_path
                                    : "https://via.placeholder.com/160"
                                }
                                height="100%"
                                alt="image"
                              />
                            )}
                          </Paper>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
              </CustomCard>
              <div style={{ width: "100%" }}>
                <ColoseButton
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    float: "right",
                    width: "10%",
                    marginRight: "10px",
                    border: "none",
                  }}
                  onClick={onClickClose}
                >
                  Close
                </ColoseButton>
              </div>
            </GridContainer>
          </CustomCard>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
};

export default OilViewPage;
