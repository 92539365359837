
import webApi from "./webApi/webApi";
import { timestamp } from "./Utils/utils";



export const getAllMaterial = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/material/list", {


    });
    if
      (res.status === 200) {
      const r = res.data;
      let all_material = [];
      r.map((r, i) => {
        all_material.push({

          value: r.material_id,
          label: r.material_name,

        });
      });
      return onSuccess(all_material);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};



export const postMaterialType = async (info, inserted_by_id, onSuccess, onFailure) => {
  try {
    console.log(info, "info")
    const res = await webApi.post("/master/material_type/insert", {
      material_type_id: info.material_type_id,
      material_type_name: info.txt_material,
      material_id: info.ddl_material_id,
      material_name: info.ddl_material_label,
      material_type_rate:info.material_type_rate,
      hsn: info.txt_hsn,
      gst: info.txt_gst,
      effective_from_date: timestamp(info.txt_from_date),
      // effective_to_date: timestamp(info.txt_to_date),
      details: info.txt_details,
      active_status: info.switch_active_status ? "Y" : "N",
      inserted_by_id: inserted_by_id,
    });

    if (res.status === 200) {
      const r = res.data;

      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    // onFailure("Something Wrong! Please Try again later " + error);
  }
};



export const getAllMaterialType = async (onSuccess, onFailure, q) => {
  try {

    const res = await webApi.post("/master/material_type/list", {
      keyword_pharse: q?.txt_keyword_pharse,

    });
    if
      (res.status === 200) {
      const r = res.data;
      console.log(r, "list")

      let all_material = [];
      r.map((r, i) => {
        all_material.push({
          id: i + 1,
          material_type_id: r.material_type_id,
          material_type_name: r.material_type_name,
          material_name: r.material_name,
      material_type_rate:r.material_type_rate,

          hsn: r.hsn,
          gst: r.gst,
          effective_to_date: r.effective_to_date,
          effective_from_date: r.effective_from_date,
          details: r.details,
          status: r.active_status,
          action_items: r.action_items,
          action: "action",
        });
      });
      return onSuccess(all_material);
    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};


export const updateMaterialType = async (info, onSuccess, onFailure) => {

  try {
    const res = await webApi.post("/master/material_type/update", {
      material_type_id: info.material_type_id,
      material_type_name: info.txt_material,
      material_id: info.ddl_material_id,
      material_name: info.ddl_material_label,
      material_type_rate:info.material_type_rate,
      hsn: info.txt_hsn,
      gst: info.txt_gst,
      effective_from_date: timestamp(info.txt_from_date),     
      details: info.txt_details,
      
     

    });
    if (res.status === 200) {
      const r = res.data;
      onSuccess(r);
    } else {
      onFailure("Something Wrong! Please Try again later " + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later " + error);
  }
};