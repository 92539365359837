import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// IMPORT SERVICES
// import {
//   getAllCategory,
//   getAllBrands,
//   postItem,
//   updateItem,
//   getAllLedgerAccount,
//   postFileUpload,
// } from "../../../services/itemsService";

// import { getListUom } from "../../../services/uomService";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import theme from "../../../theme/theme";
import Paper from "@material-ui/core/Paper";
import styles from "assets/jss/material-dashboard-pro-react/views/addIemPageStyle.js";
import { CustomCard } from "views/Components/CustomCard";
import { appDefaultColor } from "assets/jss/material-dashboard-pro-react";
import { appDefaultFamily } from "assets/jss/material-dashboard-pro-react";
import CustomButton, { ColoseButton } from "views/Components/CustomButton";
import { whiteColor } from "assets/jss/material-dashboard-pro-react";
import PageTitle from "../HelperComponent/PageTitle";
import Select from "react-select";
import { useStateValue } from "../../../context/context";
import { actionTypes } from "../../../context/reducer";
import { currentDate, dateFormateField } from "../HelperComponent/utils";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useLocation, useHistory } from "react-router-dom";
import { IMG_API } from "../../../services/webApi/webApi";

import localeCompare from "locale-compare"; //import for sorting of category
import { getAllBrands } from "services/itemService";
import { getAllCategory } from "services/itemService";
import { updateItem } from "services/itemService";
import { postItem } from "services/itemService";
import FormComponent from "../HelperComponent/FormComponent";
import { getAllItemById } from "services/maintanceService";
import { getAllItemChecking } from "services/maintanceService";
const compare = require("locale-compare")(); //import for sorting of category

const useStyles = makeStyles(styles);

const ItemPage = ({ menuPortal = true }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [globalState, dispatch] = useStateValue();
  const [fileLoading, setFileLoading] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [allAltUom, setAllAltUom] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [allCategory, setAllCategory] = React.useState([]);
  const [allBrands, setAllBrands] = React.useState([]);
  const [allUoms, setAllUoms] = React.useState([]);
  const [allLedgerAccount, setAllLedgerAccount] = React.useState([]);
  const [item, setAllItem] = React.useState([]);
  
  const [addItem, setAddItem] = React.useState({
    edit: false,
    item_id: "",
    ddl_category: "",
    ddl_category_label: "Select",
    ddl_brand: "",
    ddl_brand_label: "Select",
    txt_company_code: "",
    txt_item_name: "",
    txt_hsn_code: "",
    txt_details: "",
    txt_size:"",
    txt_uom:"",
    switch_active_status: false,
 
  });

  const toTitleCase = (str) => {
    if(str) {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    }
  
    else {
      return "";
    }
    
  }

  // for Error handler state
  const [error, setError] = React.useState({
    txt_item_name: false,    
    ddl_brand: false,   
    txt_company_code: false,
   
  });
  const onSetActive = (e) => {
    setAddItem((prv) => ({ ...prv, switch_active_status: e.target.checked }));
  };

  const fetchData = () => {
    getAllCategory(
      (r) => {
        console.log(r);
        setAllCategory(r);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );



    getAllBrands(
      (r) => {
        setAllBrands(r);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
    
  
   
  };
  const onSelect = (name, v) => {
    switch (name) {
      case "ddl_category":
        setAddItem((prevItem) => ({
          ...prevItem,
          ddl_category: v.value,
          ddl_category_label: v.label,
        }));
        break;
      case "ddl_brand":
        setAddItem((prevItem) => ({
          ...prevItem,
          ddl_brand: v.value,
          ddl_brand_label: v.label,
        }));
        break;
      
      default:
        break;
    }
  };

  const onChangeItemForm = (e) => {
    const { name, value } = e.target;
    console.log(value,"77")
    
    setAddItem({
      ...addItem,
      [name]:  toTitleCase(value),
    });

    getAllItemChecking(
      value,
      (r) => {
        // console.log(r,"checking11")
        if(r[0]?.item === value){
          // console.log("25253")
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: "Item Already Present", msgType: "error" },
          });
        
        }
   
      },
      (err) => {
  
      }
    );
    
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "imgPath") {
      setFileLoading(true);
      const file = e.target.files[0];
      if (file.size < 300000) {
        // const reader = new FileReader();
        // var url = reader.readAsDataURL(file);
        postFileUpload(
          file,
          (r) => {
            setAddItem({
              ...addItem,
              [name]: r.fileName,
              original_file_name: r.originalFileName,
            });
            setFileLoading(false);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
      } else {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "File should be less then 300kb",
            msgType: "warning",
          },
        });
      }
    }
    if (name === "txt_gst") {
      setAddItem((prv) => ({
        ...prv,
        ["txt_gst"]: value,
        ["txt_igst"]: value * 2,
      }));
    } else if (name === "txt_item_name") {
      setAddItem((prv) => ({
        ...prv,

        ["txt_item_name"]: value,
      
      }));
    } else if (name === "switch_active_status") {
      setAddItem((prv) => ({ ...prv, [name]: e.target.checked }));
    }
     else {
      setAddItem({ ...addItem, [name]: value });
    }
  };
  // onSubmit called
  const onSubmitItem = (e) => {
    e.preventDefault();
    if (
      !addItem.txt_item_name ||
      
      !addItem.ddl_brand
    
    ) {
      setError({
        txt_item_name: !addItem.txt_item_name,
       
        ddl_brand_id: !addItem.ddl_brand
       
      });
    } else {
      if (addItem.edit) {
        updateItem(
          addItem,
          (r) => {
            onClearState();
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Item Updated Successfully",
                msgType: "success",
              },
            });
            setRefresh(!refresh);
            onClearState();
            // history.push("/admin/master/item");
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
      } else {
        postItem(
          addItem,
          globalState?.user?.brand_id,

          (r) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "Item added Successfully",
                msgType: "success",
              },
            });
            onClearState();
            setRefresh(!refresh);
            
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
          }
        );
      }
      // history.push("/admin/master/item");
      // setButtonDisabled(true);
    }
  };

  // const onMenuOpen = () => {
   
  //   // getAllItemById(
  //   //   (r) => {
  //   //     setAllItem([
  //   //       { value: "Item", label: "Add New Item" },
  //   //       ...r,
  //   //     ]);
  //   //   },
  //   //   (err) => {
  //   //     dispatch({
  //   //       type: actionTypes.SET_OPEN_MSG,
  //   //       payload: { msg: err, msgType: "error" },
  //   //     });
  //   //   }
  //   // );
  // };

 

  const formData = [
    {
      formName: "fistForm",
      fields: [
        {
          name: "ddl_category",
          label: "Category",
          hidden: false,
          required: true,
          data_type: "string",
          html_element: "select",
          xs: 12,
          md: 6,
          lg: 3,
          options: allCategory,
        },
        {
          name: "ddl_brand",
          label: "Brand",
          hidden: false,
          required: true,
          data_type: "string",
          html_element: "select",
          xs: 12,
          md: 6,
          lg: 3,
          options: allBrands,
        },
        {
          name: "txt_item_name",
          label: "Item",
          hidden: false,
          required: true,
          align: "left",
          data_type: "string",
          html_element: "TextField",
          error: false,
          xs: 12,
          md: 6,
          lg: 3,
        },
        {
          name: "txt_uom",
          label: "uom",
          hidden: false,
          required: false,
          data_type: "string",
          html_element: "TextField",
          error: false,
          xs: 12,
          md: 6,
          lg: 1,
        },
        {
          name: "txt_company_code",
          label: "Company Code",
          hidden: false,
          required: false,
          align: "left",
          data_type: "string",
          html_element: "TextField",
          error: false,
          xs: 12,
          md: 6,
          lg: 2,
        },
        {
          name: "txt_size",
          label: "Size",
          hidden: false,
          required: true,
          align: "left",
          data_type: "string",
          html_element: "TextField",
          error: false,
          xs: 12,
          md: 6,
          lg: 2,
        },
        {
          name: "txt_hsn_code",
          label: "HSN",
          hidden: false,
          required: false,
          data_type: "string",
          html_element: "TextField",
          error: false,
          xs: 12,
          md: 6,
          lg: 2,
        },

        {
          name: "txt_details",
          label: "Details",
          hidden: false,
          align: "left",
          required: true,
          data_type: "string",
          maxLength: 10,
          html_element: "TextField",
          error: false,
          xs: 12,
          md: 6,
          lg: 8,
        },
    
      ],
    },

    
  ];

  React.useEffect(() => {
    fetchData();
  }, [refresh]);

  React.useEffect(() => {
    // console.log(location.state?.row,"checking")
    // if (location.state?.itemEdit) {
    //   setAddItem({
    //     edit: location.state?.itemEdit,
    //     item_id: location.state?.row?.item_id,

    //     txt_item_own_code: location.state?.row?.item_own_code,
    //     txt_company_code: location.state?.row?.item_company_code,
    //     txt_item_name: location.state?.row?.item,
    //     txt_hsn_code: location.state?.row?.hsn_code,

    //     ddl_category_id: location.state?.row?.category_id,
    //     ddl_category_label: location.state?.row?.category,

    //     ddl_brand_id: location.state?.row?.brand_id,
    //     ddl_brand_label: location.state?.row?.brand,

    //     ddl_uom_id: location.state?.row?.uom_id,
    //     ddl_uom_label: location.state?.row?.uom_name,

    //     ddl_alt_uom_id: location.state?.row?.alt_uom_id,
    //     ddl_alt_uom_label: location.state?.row?.alt_uom_name,

    //     txt_reorder_qty: location.state?.row?.reorder_quantity,
    //     ddl_reoder_qty_uom_id: location.state?.row?.reorder_quantity_uom_id,
    //     ddl_reoder_qty_uom_label:
    //       location.state?.row?.reorder_quantity_uom_name[0],

    //     txt_qty: location.state?.row?.qty,

    //     txt_reorder_level: location.state?.row?.reorder_level,
    //     ddl_reoder_level_uom_id: location.state?.row?.reorder_level_uom_id,
    //     ddl_reoder_level_uom_label: location.state?.row?.reorder_level_uom_name,

    //     ddl_ledger_account_id: location.state?.row?.ledger_account_id,
    //     ddl_ledger_account_id_label: location.state?.row?.ledger_account_name,

    //     txt_opening_stock: location.state?.row?.opening_stock,
    //     ddl_opening_stock_uom_id: location.state?.row?.opening_stock_uom_id,
    //     ddl_opening_stock_uom_label:
    //       location.state?.row?.opening_stock_uom_name,

    //     txt_MRP: location.state?.row?.mrp,
    //     mrp_date: dateFormateField(location.state?.row?.mrp_date),
    //     txt_size: location.state?.row?.size,

    //     txt_selling_price: location.state?.row?.selling_price,
    //     selling_date: dateFormateField(location.state?.row?.selling_date),

    //     txt_gst: location.state?.row?.gst,
    //     txt_igst: location.state?.row?.igst,
    //     txt_sgst: location.state?.row?.sgst,
    //     opening_stock_date: currentDate(),
    //     txt_details: location.state?.row?.details,
    //     switch_active_status:
    //       location.state?.row?.status === "Y" ? true : false,
    //     imgPath: location.state?.row?.picture_path,
    //     original_file_name: location.state?.row?.original_file_name,
    //   });
    // }
  }, []);

  // on Close Model
  const onClearState = () => {
    setError({ txt_item: false });
    setAddItem({
      edit: false,
      item_id: "",
      ddl_category: "",
      ddl_category_label: "Select",
      ddl_brand: "",
      ddl_brand_label: "Select",
      txt_company_code: "",
      txt_item_name: "",
      txt_hsn_code: "",
      txt_details: "",
      txt_size:"",
      txt_uom:"",
      switch_active_status: false,
     
    });
  };

  const onClickBack = () => {
    history.push("/admin/master/item");
  };
  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title={
          addItem.edit
            ? "Master > Item Details > Edit"
            : "Master > Item Details > Add"
        }
      />
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs="12">
            <CustomCard
              width="100%"
              height="100%"
              cdTitle={addItem.edit ? "Edit an Item " : "Add an Item"}
            >
              <GridContainer alignItems="center" style={{ padding: 20 }}>
                <GridItem xs={12}>
                  <from className={classes.root}>
                    <GridContainer>
                      {/* <GridItem xs={3}>
                        <InputLabel required={true} id="label">
                          Category
                        </InputLabel>
                        <Select
                          options={allCategory}
                          formatGroupLabel={(d) => d.label}
                        
                          className={classes.customSelect}
                          name="ddl_category_id"
                          onChange={(v) => onSelect("category", v)}
                          value={{
                            value: addItem.ddl_category_id,
                            label: addItem.ddl_category_label,
                          }}
                          menuPortalTarget={menuPortal ? document.body : null}
                        />
                       
                      </GridItem> */}
                      {/* <GridItem xs={3}>
                        <InputLabel required={true} id="label">
                          Brand
                        </InputLabel>
                        <Select
                          options={allBrands}
                          formatGroupLabel={(d) => d.label}
                          className={classes.customSelect}
                          name="ddl_brand_id"
                          onChange={(v) => onSelect("brand", v)}
                          value={{
                            value: addItem.ddl_brand_id,
                            label: addItem.ddl_brand_label,
                          }}
                          menuPortalTarget={menuPortal ? document.body : null}
                        />
                        {error.ddl_brand_id && (
                          <div className={classes.ddlError}>
                            Brand is Required
                          </div>
                        )}
                      </GridItem>
                      <GridItem xs={4}>
                        <InputLabel required={true} id="label">
                          Item
                        </InputLabel>
                        <TextField
                          size="small"
                          placeholder="Item"
                          name="txt_item_name"
                          onChange={onChange}
                          id="outlined-basic"
                          fullWidth={true}
                          value={addItem.txt_item_name}
                          variant="outlined"
                        />
                      </GridItem>
                      
                      <GridItem xs={2}>
                        <InputLabel required={true} id="label">
                          uom
                        </InputLabel>
                        <TextField
                          size="small"
                          placeholder="Item"
                          name="txt_uom"
                          onChange={onChange}
                          id="outlined-basic"
                          fullWidth={true}
                          value={addItem.txt_uom}
                          variant="outlined"
                        />
                      </GridItem>

                      <GridItem xs={3}>
                        <InputLabel required={true} id="label">
                          Company Code
                        </InputLabel>
                        <TextField
                          size="small"
                          placeholder="Company Code"
                          name="txt_company_code"
                          onChange={onChange}
                          id="outlined-basic"
                          fullWidth={true}
                          value={addItem.txt_company_code}
                          variant="outlined"
                        />
                      </GridItem>
                 
                          <GridItem xs={3}>
                            <InputLabel>Size</InputLabel>
                            <TextField
                              size="small"
                              placeholder="Size"
                              name="txt_size"
                              onChange={onChange}
                              id="outlined-basic"
                              fullWidth={true}
                              value={addItem.txt_size}
                              variant="outlined"
                            />
                          </GridItem>
                          <GridItem xs={2}>
                            <InputLabel id="label">HSN </InputLabel>
                            <TextField
                              size="small"
                              placeholder="HSN Code"
                              name="txt_hsn_code"
                              onChange={onChange}
                              id="outlined-basic"
                              fullWidth={true}
                              inputProps={{
                                style: { textAlign: "right" },
                              }}
                              type="number"
                              value={addItem.txt_hsn_code}
                              variant="outlined"
                            />
                          </GridItem>
                          <GridItem xs={7}>
                            <InputLabel id="label">Details</InputLabel>
                            <TextField
                              placeholder="Details"
                              name="txt_details"
                              onChange={onChange}
                          size="small"
                            
                              id="outlined-basic"
                              fullWidth={true}
                              value={addItem.txt_details}
                              variant="outlined"
                            />
                          </GridItem> */}

{formData.map((form, fkey) => {
                      return (
                        <GridContainer
                          key={fkey}
                          style={{ padding: "5px 20px" }}
                        >
                          {form.formName === "fistForm" &&
                            form.fields.map((item, key) => {
                              return (
                                <FormComponent
                                  item={item}
                                  key={key}
                                  // onClickAdd={onClickAddContact}
                                  onSelect={onSelect}
                                  state={addItem}
                                  menuPortal={menuPortal}
                                  onChange={onChangeItemForm}
                                  // onMenuOpen={onMenuOpen}
                                  error={error}
                                />
                              );
                            })}
                        </GridContainer>
                      );
                    })}


                      <GridItem xs="12">
                        <GridContainer>
                        <GridItem xs="3">
                            <div>
                              <span
                                className={classes.activeText}
                                style={{ marginTop: 20 }}
                              >
                                Active Status
                              </span>
                              <Switch
                                onClick={onSetActive}
                                checked={addItem.switch_active_status}
                                name="switch_active_status"
                                fullWidth={true}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                color="primary"
                              />
                            </div>
                          </GridItem>
                        
                       
                       
                          <GridItem xs="12">
                            <div className={classes.actionbtn}>
                              <ColoseButton
                                name="btn_close"
                                height={40}
                                onClick={onClickBack}
                              >
                                Cancel
                              </ColoseButton>
                              <Button
                                name="btn_save"
                                onClick={onSubmitItem}
                                style={{
                                  height: 40,
                                  width: "100px",
                                  color: whiteColor,
                                  borderColor: appDefaultColor,
                                  fontFamily: appDefaultFamily,
                                }}
                                variant="outlined"
                                color="primary"
                                disabled={buttonDisabled}
                              >
                                Submit
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      
                    </GridContainer>
                  </from>
                </GridItem>
              </GridContainer>
            </CustomCard>
          </GridItem>
        </GridContainer>
      </div>
    </ThemeProvider>
  );
};

export default ItemPage;
